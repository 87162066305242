import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyEditor = React.forwardRef(({ onKeyPress, onKeyUp }, ref) => (
  <Editor
    tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
    onInit={(event, editor) => (ref.current = editor)}
    onKeyPress={onKeyPress}
    onKeyUp={onKeyUp}
    init={{
      menubar: false,
      plugins: [
        'advlist',
        'autolink',
        'lists',
        'link',
        'image',
        'charmap',
        'anchor',
        'searchreplace',
        'visualblocks',
        'code',
        'fullscreen',
        'insertdatetime',
        'media',
        'table',
        'preview',
      ],
      toolbar:
        'undo redo' +
        '| blocks' +
        '| fullscreen' +
        '| bold italic forecolor' +
        '| image' +
        '| alignleft aligncenter alignright alignjustify' +
        '| bullist numlist outdent indent' +
        '| removeformat ' +
        '',
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      file_browser_callback_types: 'image',
      image_advtab: true,
      file_picker_callback(callback, value, meta) {
        if (meta.filetype === 'image') {
          let input = document.getElementById('my-file');
          if (!input) return;
          input.click();
          input.onchange = function () {
            let file = input?.files[0];
            let reader = new FileReader();
            reader.onload = function (e) {
              console.log('name', e.target.result);
              callback(e.target.result, {
                alt: file.name,
              });
            };
            reader.readAsDataURL(file);
          };
        }
      },
    }}
  />
));

export default TinyEditor;
