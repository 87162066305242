import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KhaiPContents from './KhaiPContents';

const KhaiPInterview = ({ iv }) => {
  const { t } = useTranslation();
  const [interview, setInterview] = useState();

  /** ==========
   * 자아, 업무, 사람 순으로 정렬
  ============== */
  useEffect(() => {
    if (!iv) return;

    const arr = Object.entries(iv).sort(([key1, value1], [key2, value2]) => {
      const order = ['self', 'work', 'people'];
      const idx1 = order.indexOf(key1);
      const idx2 = order.indexOf(key2);
      return idx1 - idx2;
    });

    const sortedKeys = Object.fromEntries(arr);

    const newData = [];
    Object.keys(sortedKeys)?.map((item) => {
      const weightSorting = Object.entries(sortedKeys[item]).sort(
        ([weightKey1, weightValue1], [weightKey2, weightValue2]) => {
          const weightOrder = ['strong', 'weak'];
          const weightIdx1 = weightOrder.indexOf(weightKey1);
          const weightIdx2 = weightOrder.indexOf(weightKey2);
          return weightIdx1 - weightIdx2;
        }
      );

      const sortedWeightkeys = Object.fromEntries(weightSorting);
      const processedData = { [item]: { ...sortedWeightkeys } };
      newData.push(processedData);
    });

    setInterview(newData);
  }, [iv]);

  return (
    <section className="interview-container">
      {interview &&
        (interview || []).map((question, index) => (
          <article className="interview-wrapper" key={`iv_${index}`}>
            <aside className="interview-category">
              <h4>
                {Object.keys(question)[0] === 'self' &&
                  t('title.personal-propensity')}
                {Object.keys(question)[0] === 'work' &&
                  t('title.task-propensity')}
                {Object.keys(question)[0] === 'people' &&
                  t('title.relationship-propensity')}
              </h4>
            </aside>
            <aside className="interview-contents">
              {(Object.values(question) || []).map((category) =>
                (Object.keys(category) || []).map((weight, i) => (
                  <KhaiPContents
                    key={`weight_${i}`}
                    tag={weight}
                    data={category[weight]}
                  />
                ))
              )}
            </aside>
          </article>
        ))}
    </section>
  );
};

export default KhaiPInterview;
