import React, { useEffect, useState } from 'react';
import {
  convertCareer,
  convertChannel,
  convertFinalEducation,
  convertGender,
  convertJobCenter,
  convertMajor,
  convertPositionInfo,
  convertSNS,
  convertSchool,
  convertSearchFirm,
} from 'utils/convertKey';
import { nameConversionLanguage } from 'utils/converter';

const ApplicantInformation = ({ t, user }) => {
  const channelFunction = (channel, path) => {
    if (channel === 'job') {
      return convertJobCenter(path);
    }
    if (channel === 'sns') {
      return convertSNS(path);
    }
    if (channel === 'school') {
      return convertSchool(path);
    }
    if (channel === 'friend') {
      return path;
    }
    if (channel === 'searchFirm') {
      return convertSearchFirm(path);
    }
    if (channel === 'others') {
      return path;
    }
  };

  return (
    <ul>
      <li>
        <span className="sub-title fs12">{t('column.korean-name')}</span>
        <p className="desc fs16">
          {nameConversionLanguage(user.firstName, user.lastName, t('lang'))}
        </p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.english-name')}</span>
        <p className="desc fs16">
          {user.engFirstName || user.engLastName
            ? user.engFirstName + user.engLastName
            : ''}
        </p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.email')}</span>
        <p className="desc fs16">{user.email}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('survey.mobile-number')}</span>
        <p className="desc fs16">{user.phone}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.gender')}</span>
        <p className="desc fs16">{t(convertGender(user.gender))}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.birth')}</span>
        <p className="desc fs16">{user.birth}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.check-career')}</span>
        <p className="desc fs16">{t(convertCareer(user.career))}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.career')}</span>
        <p className="desc fs16">{t(convertCareer(user.totalCareer))}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.final-company')}</span>
        <p className="desc fs16">{user.finalCompany}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.age')}</span>
        <p className="desc fs16">{user.age}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.channel')}</span>
        <p className="desc fs16">{t(convertChannel(user.channel))}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.channel-path')}</span>
        <p className="desc fs16">
          {t(channelFunction(user.channel, user.channelPath))}
        </p>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('text-field.label.employee-department')}
        </span>
        <p className="desc fs16">{user.recommenderStore}</p>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('text-field.label.recommended-employee-name')}
        </span>
        <p className="desc fs16">{user.recommender}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.applied-work')}</span>
        <p className="desc fs16">{user.applyPosition}</p>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('text-field.label.objective')}
        </span>
        <p className="desc fs16">{t(convertPositionInfo(user.applyField))}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.apply-date')}</span>
        <p className="desc fs16">{user.applyDate}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('title.position')}</span>
        <p className="desc fs16">{user.position}</p>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('menu.label.education-level')}
        </span>
        <p className="desc fs16">
          {t(convertFinalEducation(user.finalEducation))}
        </p>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.major')}</span>
        <p className="desc fs16">{t(convertMajor(user.major))}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.is-graduated')}</span>
        <p className="desc fs16">
          {t(convertFinalEducation(user.isGraduated))}
        </p>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('column.is-document-approval-status')}
        </span>
        <p className="desc fs16">{user.isDocumentApprovalStatus}</p>
      </li>
    </ul>
  );
};

export default ApplicantInformation;
