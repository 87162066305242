import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ApplyAgreeCheckbox from './ApplyAgreeCheckbox';
import { PersonalInformation } from '../../constants/PersonalInformation';

const ApplyAgreeContainer = ({ checkedAgree, setCheckedAgree }) => {
  const { t } = useTranslation();

  const language = t('lang');
  const checkedPrivacyAgree = useCallback(
    (boolean) => {
      setCheckedAgree({
        ...checkedAgree,
        privacy: boolean,
      });
    },
    [checkedAgree]
  );

  const checkedTermOfUseAgree = useCallback(
    (boolean) => {
      setCheckedAgree({
        ...checkedAgree,
        term: boolean,
      });
    },
    [checkedAgree]
  );

  return (
    <aside className="apply-agree-box">
      <span className="apply-agree-label fs14">
        {t('title.consent-to-information')}
      </span>
      <ApplyAgreeCheckbox
        checked={checkedAgree?.privacy}
        onChange={checkedPrivacyAgree}
        label={t('text-field.privacy-agree')}
        desc={PersonalInformation(language)}
        required
        isCheckbox
      />
    </aside>
  );
};

export default ApplyAgreeContainer;
