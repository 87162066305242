import React from 'react';
import { useTranslation } from 'react-i18next';

const KhaiPContentsLeft = ({ tag, categoryData }) => {
  const { t } = useTranslation();

  return (
    <aside className="interview-title-wrap">
      <span className={`interview-tag ${tag === 'strong' ? 'blue' : 'red'}`}>
        {tag && tag === 'strong' ? t('title.strengths') : t('title.weaknesses')}
      </span>
      <h5 className="interview-title">{categoryData && categoryData?.title}</h5>
      <div className="interview-desc">
        <p>{categoryData && categoryData?.desc}</p>
      </div>
    </aside>
  );
};

export default KhaiPContentsLeft;
