import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import { areaGucciList, channelGucciList } from 'constants/gucci/apply';
import {
  channelOptions, jobCenterOptions,
  schoolOptions,
  searchFirmOptions,
  snsOptions,
} from 'constants/recruitments/applySelectOptions';
import ApplyInputBox from './ApplyInputBox';

const ApplyFormOther = ({
  type,
  input,
  handleInput,
  channelGucci,
  areaGucci,
  setChannelGucci,
  setAreaGucci,
}) => {
  const { t } = useTranslation();
  const checkBoxArray = new Array([...channelGucciList], [...areaGucciList]);

  const checkedItemHandler = (value, isChecked, index, idx) => {
    if (isChecked) {
      switch (index) {
        case 0:
          setChannelGucci((prev) => [...prev, value]);
          break;
        default:
          setAreaGucci((prev) => [...prev, value]);
          break;
      }
      return;
    }

    if (!isChecked) {
      switch (index) {
        case 0:
          setChannelGucci(channelGucci.filter((item) => item !== value));
          break;
        default:
          setAreaGucci(areaGucci.filter((item) => item !== value));
          break;
      }
      return;
    }

    return;
  };

  const handleOnChange = (e, value, index, idx) => {
    checkedItemHandler(value, e.target.checked, index, idx);
  };

  return (
    <>
      {/* are you korean? */}
      <aside
        className={`apply-input-box ${
          type === 'add' && 'flex type-add-input-box'
        }`}
      >
        <h6 className="sub-title fs20">
          {t('title.are-you-korean')}
          <span style={{ color: 'red' }}> *</span>
          {type === 'add' && (
            <p className="fs12">{t('text-field.notify.are-you-korean')}</p>
          )}
        </h6>
        <ApplyInputBox
          isSelectBox
          name="isKorean"
          value={input.isKorean}
          handleInput={handleInput}
          menus={[
            { key: 'yes', i18nKey: 'menu.input.yes' },
            { key: 'no', i18nKey: 'menu.input.no' },
          ]}
          caution={`${
            type !== 'add' ? t('text-field.notify.are-you-korean') : ''
          }`}
        />
      </aside>
      {input.isKorean.key === 'no' && (
        <>
          <aside className="apply-input-box">
            <h6 className="sub-title fs20">{t('title.visa-information')}</h6>
            <div className="apply-input-box flex">
              <ApplyInputBox
                inputType="text"
                placeholder={t('text-field.label.passport-last-name')}
                label={t('text-field.label.passport-last-name')}
                name="passportLastName"
                value={input.passportLastName}
                handleInput={handleInput}
              />
              <ApplyInputBox
                inputType="text"
                placeholder={t('text-field.label.passport-first-name')}
                label={t('text-field.label.passport-first-name')}
                name="passportFirstName"
                value={input.passportFirstName}
                handleInput={handleInput}
              />
            </div>
          </aside>
          <aside className="apply-input-box flex">
            <ApplyInputBox
              label={t('text-field.label.do-you-have-a-work-visa')}
              isSelectBox
              name="isWorkVisa"
              value={input.isWorkVisa}
              handleInput={handleInput}
              menus={[
                { key: 'yes', i18nKey: 'menu.input.yes' },
                { key: 'no', i18nKey: 'menu.input.no' },
              ]}
            />
            <ApplyInputBox
              inputType="text"
              placeholder={t('text-field.label.visa-type')}
              label={t('text-field.label.visa-type')}
              name="visaType"
              value={input.visaType}
              handleInput={handleInput}
            />
          </aside>
        </>
      )}
      {/* channel */}
      <aside className="apply-input-box">
        <ApplyInputBox
          label={t('text-field.label.channel')}
          required
          isSelectBox
          name="channel"
          value={input.channel}
          handleInput={handleInput}
          menus={channelOptions}
        />
      </aside>
      {/* search firm */}
      {input.channel.key === 'searchFirm' && (
        <aside className="apply-input-box">
          <ApplyInputBox
            label="Search Firm"
            required
            isSelectBox
            name="searchFirm"
            value={input.searchFirm}
            handleInput={handleInput}
            menus={searchFirmOptions}
          />
        </aside>
      )}
      {/* sns */}
      {input.channel.key === 'sns' && (
        <aside className="apply-input-box">
          <ApplyInputBox
            label={t('text-field.label.sns')}
            required
            isSelectBox
            name="sns"
            value={input.sns}
            handleInput={handleInput}
            menus={snsOptions}
          />
        </aside>
      )}
      {/* sns other */}
      {input.channel.key === 'sns' && input.sns.key === 'other' && (
        <aside className="apply-input-box">
          <ApplyInputBox
            inputType="text"
            required
            label={t('title.other')}
            name="snsOther"
            value={input.snsOther}
            handleInput={handleInput}
          />
        </aside>
      )}
      {/* school */}
      {input.channel.key === 'school' && (
        <aside className="apply-input-box">
          <ApplyInputBox
            label={t('text-field.label.school-name')}
            required
            isSelectBox
            name="school"
            value={input.school}
            handleInput={handleInput}
            menus={schoolOptions}
          />
        </aside>
      )}
      {/* job center unit */}
      {input.channel.key === 'job' && (
        <aside className="apply-input-box">
          <ApplyInputBox
            label={t('menu.input.job-center-unit')}
            required
            isSelectBox
            name="jobCenter"
            value={input.jobCenter}
            handleInput={handleInput}
            menus={jobCenterOptions}
          />
        </aside>
      )}
      {/* job center unit other */}
      {input.channel.key === 'job' && input.jobCenter.key === 'others' && (
        <aside className="apply-input-box">
          <ApplyInputBox
            inputType="text"
            required
            label={t('title.other')}
            name="jobCenterOther"
            value={input.jobCenterOther}
            handleInput={handleInput}
          />
        </aside>
      )}
      {/* other form */}
      {input.channel.key === 'others' && (
        <aside className="apply-input-box">
          <ApplyInputBox
            inputType="text"
            required
            label={t('title.other')}
            name="otherChannel"
            value={input.otherChannel}
            handleInput={handleInput}
          />
        </aside>
      )}
      {/* employee */}
      <aside className="apply-input-box recommend-wrapper flex">
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.recommended-employee-name')}
          label={t('text-field.label.employee-referral')}
          name="nameOfEmployee"
          value={input.nameOfEmployee}
          handleInput={handleInput}
        />
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.employee-department')}
          name="nameOfStore"
          value={input.nameOfStore}
          handleInput={handleInput}
        />
      </aside>
      {type === 'add' && (
        <aside className="apply-input-box flex">
          <ApplyInputBox
            inputType="text"
            placeholder={t('text-field.label.memo')}
            label={t('text-field.label.memo')}
            name="comment"
            value={input.comment}
            handleInput={handleInput}
          />
        </aside>
      )}
      <aside className="apply-input-box flex-column">
        {checkBoxArray?.map((list, index) => (
          <div className="inputs-check" key={index}>
            <InputLabel className="fs14">
              {t(list[0].title)} <span className="required">*</span>
            </InputLabel>
            {list[0].section?.map((item, idx) => (
              <FormControlLabel
                key={idx}
                value={t(item.key)}
                label={t(item.i18nKey)}
                control={<Checkbox />}
                onChange={(e) => {
                  handleOnChange(e, t(item.key), index, idx);
                }}
                checked={
                  index === 0
                    ? channelGucci.includes(t(item.key))
                    : areaGucci.includes(t(item.key))
                }
              />
            ))}
          </div>
        ))}
      </aside>
    </>
  );
};

export default ApplyFormOther;
