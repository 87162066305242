/*eslint-disable */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { executeAction } from 'utils/redux';
import { useLocation } from 'react-router-dom';
import {
  sendEmailReview,
  sendEmailReject,
  sendEmailPass,
  getTestInfos,
  getAttributes,
  getCultureAttributes,
  getCandidatesOfAssessment,
  setOBPIBenchmarkToAssessment,
  getScoreResult,
  testsAverage,
} from 'store/slices/assessmentsSlice';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Box,
} from '@mui/material';
import {
  convertHtmlToText,
  encryptAES,
  decryptAES,
  dateToDateFormat,
  displayName,
  convertSkillTypeToI18nKey,
  convertHtmlForDisplay,
} from 'utils/string';
import { useModal } from 'utils/modal';
import { useStatus } from 'utils/status';
import { toast } from 'utils/notify';
import { cmp, compare } from 'semver';
import { ReactComponent as ProctoringSuccess } from 'assets/images/icon/chip-category-check.svg';
import { ReactComponent as ProctoringFailed } from 'assets/images/icon/chip-category.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icon/error_24px.svg';
import MaterialSelect from 'components/select/MaterialSelect';
import {
  getKHAI_P_Grade,
  getKHAIPColor,
  getKHAIPReliability,
  getKHAI_P_BigDataRank,
  getKhaiO_Reliability,
  getKhaiO_organization_color,
  ORGANIZATIONAL_LIST,
  PROPENSITY_LIST,
} from 'utils/khaifunc';
import { Trans } from 'react-i18next';
import { ReactComponent as GradeA } from 'assets/images/icon/GradeA.svg';
import { ReactComponent as GradeB } from 'assets/images/icon/GradeB.svg';
import { ReactComponent as GradeC } from 'assets/images/icon/GradeC.svg';
import { ReactComponent as GradeD } from 'assets/images/icon/GradeD.svg';
import { ReactComponent as GradeS } from 'assets/images/icon/GradeS.svg';
import { ReactComponent as CandidateScore } from 'assets/images/icon/candidate-score-position.svg';
import { ReactComponent as ScoreGraph } from 'assets/images/icon/score-distribution-graph.svg';
import { ReactComponent as KHAILogo } from 'assets/images/logo/customer-candidates-logo-khai.svg';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts';
import OBPIResult from './OBPIResult';
import KHAIPDetailGraph from './KHAIPDetailGraph';
import KhaiOInterview from 'components/assessment/khai/khai-o/KhaiOInterview';
import KhaiPInterview from 'components/assessment/khai/khai-p/KhaiPInterview';
import { useSelector } from 'react-redux';
import CustomBarChart from 'components/assessment/chart/CustomBarChart';
import TestResultToggleContainer from 'components/assessment/testResult/TestResultToggleContainer';
import MultipleToggleContainer from 'components/assessment/testResult/MultipleToggleContainer';
import { ProgressScore } from '../../../../components/progress/Score';
import CustomTestResultToggleContainerPrint from 'components/assessment/testResult/CustomTestResultToggleContainerPrint';

const __TABS__ = [
  {
    key: 'result',
    i18nKey: 'tab.assessment-result',
    onClick: ({ setCurTab }) => {
      setCurTab('result');
    },
  },
  {
    key: 'info',
    i18nKey: 'tab.candidate-info',
    onClick: ({ setCurTab }) => {
      setCurTab('info');
    },
  },
];

const _objectiveCats = {};

const OBPI_TESTS = [
  {
    category: 'fellowship',
    title: 'title.fellowship',
    left: 'desc.fellowship-left',
    right: 'desc.fellowship-right',
    disc: "유대는 성격 5요인(빅 파이브) 중 '외향성/내향성'을 평가합니다. 높은 점수를 받는 사람들은 보통 팀 혹은 다른 사람과 함께 일할 때 더 행복합니다. 낮은 점수를 받는 사람들은 일반적으로 어느 정도의 독립성을 요구하는 업무를 선호합니다.",
  },
  {
    category: 'authority',
    title: 'title.authority',
    left: 'desc.authority-left',
    right: 'desc.authority-right',
    disc: "권위는 강인함 대 부드러움이라는 성격 5요인(빅 파이브) 중 '우호성'을 평가합니다. 높은 점수를 받는 사람들은 보통 어려운 결정을 내릴 수 있습니다. 낮은 점수를 받는 사람들은 일반적으로 더 협력적인 접근을 선택합니다.",
  },
  {
    category: 'conformity',
    title: 'title.conformity',
    left: 'desc.conformity-left',
    right: 'desc.conformity-right',
    disc: "순응은 성격 5요인(빅 파이브) 중 '경험에 대한 개방성'을 평가합니다. 높은 점수를 받는 사람들은 일을 할 때 전통적인 방식을 선호하고 기존의 가치를 존중할 것입니다. 낮은 점수를 받는 사람들은 종종 다르게 일하기를 원하고 문제에 대한 대안적 해결책을 모색하기를 바랍니다.",
  },
  {
    category: 'emotion',
    title: 'title.emotion',
    left: 'desc.emotion-left',
    right: 'desc.emotion-right',
    disc: "정서는 성격 5요인(빅 파이브) 중 '신경증'을 평가합니다. 높은 점수를 받는 사람들은 긴장하는 경향이 있지만 다른 사람들의 감정에 민감할 수 있습니다. 낮은 점수를 받는 사람들은 스트레스가 많은 상황에서도 잘 수행할 수 있지만 조심성이 부족할 수 있습니다.",
  },
  {
    category: 'detail',
    title: 'title.detail',
    left: 'desc.detail-left',
    right: 'desc.detail-right',
    disc: "디테일은 성격 5요인(빅 파이브) 중 '성실성'을 평가합니다. 높은 점수를 받는 사람들은 세세한 부분까지 지나치게 관여할 수 있지만 특별한 주의가 필요한 일상적인 작업에 탁월할 수 있습니다. 낮은 점수를 받는 사람들은 일상적 과업에 대한 인내심이 부족하고 대국적 관점으로 보는 것을 선호합니다.",
  },
  {
    category: 'proficiency',
    title: 'title.proficiency',
    left: 'desc.proficiency-left',
    right: 'desc.proficiency-right',
    disc: '숙달은 시행착오 접근법과 비교하여 세부작업에서의 주의와 관심을 평가합니다.',
  },
  {
    category: 'work-orientation',
    title: 'title.work-orientation',
    left: 'desc.work-orientation-left',
    right: 'desc.work-orientation-right',
    disc: '업무지향성은 일-삶의 균형에 대한 신념과 비교하여 근면에 대한 신념을 평가합니다.',
  },
  {
    category: 'patience',
    title: 'title.patience',
    left: 'desc.patience-left',
    right: 'desc.patience-right',
    disc: '인내심은 경쟁적인 공격성과 비교하여 침착성과 인내심을 평가합니다.',
  },
  {
    category: 'fair-mindedness',
    title: 'title.fair-mindedness',
    left: 'desc.fair-mindedness-left',
    right: 'desc.fair-mindedness-right',
    disc: '공정성은 정서적 접근과 비교하여 합리적 접근을 평가합니다.',
  },
  {
    category: 'loyalty',
    title: 'title.loyalty',
    left: 'desc.loyalty-left',
    right: 'desc.loyalty-right',
    disc: '충성심은 이끌려는 욕구와 비교하여 추종하려는 욕구를 평가합니다.',
  },
  {
    category: 'disclosure',
    title: 'title.disclosure',
    left: 'desc.disclosure-left',
    right: 'desc.disclosure-right',
    disc: '개방성은 자신의 의도나 계획을 숨기려는 것과 비교하여 진정으로 열려 있는 것을 평가합니다.',
  },
  {
    category: 'initiative',
    title: 'title.initiative',
    left: 'desc.initiative-left',
    right: 'desc.initiative-right',
    disc: '주도성은 전통에 가치를 두는 것과 비교하여 빠른 변화를 즐기는 것을 평가합니다. ',
  },
];

const __OBPI_LIST_NUM__ = 12;
const __MAX_OBPI_MATCH_SCORE = 8;

const __OBPI_AUDIT__ = [
  { category: 'dissimulation', title: 'column.dissimulation' },
  { category: 'contradiction', title: 'column.contradiction' },
  { category: 'despondency', title: 'column.despondency' },
  { category: 'inattention', title: 'column.inattention' },
];

const useMountEffect = (fun) => React.useEffect(fun, []);

const NewAssessmentPrint = ({ cid, aid, idx }) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();
  const location = useLocation();
  const { setIsEntireLoading } = useStatus();
  const { testsAverageSuccess } = useSelector((state) => state.assessments);
  const [subjectiveTestsAverageScore, setSubjectiveTestsAverageScore] =
    React.useState(0);
  const [
    subjectiveTestsAveragePresetScore,
    setSubjectiveTestsAveragePresetScore,
  ] = React.useState(0);
  const [totalAverage, setTotalAverage] = useState(0);
  const [newTests, setNewTests] = useState([]);

  const {
    assessment,
    candidate,
    objectiveTests,
    subjectiveTests,
    objectiveScores,
    objectiveCats,
    libraryTests,
    libraryDetail,
    hasKhaiP,
    KHAIPResult,
    KHAIPRadarResult,
    hasKhaiO,
    KHAIOResult,
    rank,
    candidatecnt,
    KHAIOHashQuestion,
    KHAIPQuestion,
    KHAIOQuestion,
    isPersonality,
  } = location.state || {};

  const [curTab, setCurTab] = React.useState('result');
  const [showDetail, setShowDetail] = React.useState(false);
  const [maskedEmail, setMaskedEmail] = React.useState('');
  const [answer, setAnswer] = React.useState(null);
  const candidateAttributes = React.useRef(null);
  const [candidateCultureFitAttributes, setCandidateCultureFitAttributes] =
    React.useState([]);
  const candidateCultureAttributes = React.useRef(null);
  const [
    candidateCultureFitCultureAttributes,
    setCandidateCultureFitCultureAttributes,
  ] = React.useState([]);
  const [refreshing, setRefreshing] = React.useState(true);
  const lang = t('lang');
  const [obpiBenchmark, setOBPIBenchmark] = React.useState({});
  const [obpiTest, setOBPITest] = React.useState([]);
  const [obpiStanine, setOBPIStanine] = React.useState([]);
  const [obpimatchScore, setOBPIMatchScore] = React.useState([]);
  const [obpiAudit, setOBPIAudit] = React.useState([]);
  const [isRefreshing, setIsRefreshing] = React.useState(true);
  const [__candidates, __setCandidates] = React.useState([]);
  const [_candidates, _setCandidates] = React.useState([]);
  const [selectedCandidate, setSelectedCandidate] = React.useState(false);
  const [printed, setPrinted] = React.useState(false);
  const [obpiInterpretation, setobpiInterpretation] = React.useState([]);

  useEffect(() => {
    if (!testsAverageSuccess) return;

    const avgScore =
      testsAverageSuccess.reduce((acc, cur) => acc + cur.average, 0) /
      testsAverageSuccess.length;

    setTotalAverage(avgScore);
  }, [testsAverageSuccess]);

  useEffect(() => {
    if (Object.keys(assessment)?.length === 0) return;

    const languageTests = [];
    const { results } = candidate;

    assessment?.tests?.forEach((test) => {
      if (typeof test?.question === 'string') return;
      test?.question?.forEach((ques) => {
        if (ques?.tid === '601000000') {
          if (results?.english) {
            languageTests.push({
              category_skill: 'English Test',
              section: 'language',
              question: [
                {
                  name: 'english',
                  ...results.english,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.chinese) {
            languageTests.push({
              category_skill: 'Chinese Test',
              section: 'language',
              question: [
                {
                  name: 'chinese',
                  ...results.chinese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.japanese) {
            languageTests.push({
              category_skill: 'Japanese Test',
              section: 'language',
              question: [
                {
                  name: 'japanese',
                  ...results.japanese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.korean) {
            languageTests.push({
              category_skill: 'Korean Test',
              section: 'language',
              question: [
                {
                  name: 'korean',
                  ...results.korean,
                  section: 'language',
                },
              ],
            });
          }
        }
      });
    });

    const newTests = assessment?.tests;

    setNewTests([...languageTests, ...newTests]);
  }, [assessment, candidate]);

  useEffect(() => {
    executeAction(testsAverage({ tests: newTests, candidate }));
  }, [newTests, refreshing]);

  const convertDeviceCheck = (bool) => {
    if (bool) {
      return <ProctoringSuccess />;
    }

    return <ProctoringFailed />;
  };

  React.useState(() => {
    if (idx === 0) {
      setShowDetail(!showDetail);
    } else {
      setShowDetail(showDetail);
    }
  }, [idx, showDetail]);

  React.useEffect(() => {
    const obpistanine = new Array();
    const interpretations = new Array();
    const obpi_id = cid.split('@')[0];
    for (const obpis of OBPI_TESTS) {
      const score = obpiTest?.traits?.find((x) => x.scale === obpis.category);
      obpistanine.push(score?.stanine);

      const interpretation = obpiTest?.narrative?.find(
        (x) => x.id === `${obpis.category}-interpretation`
      );
      if (interpretation !== undefined) {
        interpretations.push(interpretation.text.replaceAll(obpi_id, '응시자'));
      } else {
        interpretations.push('');
      }
    }
    setOBPIStanine(obpistanine);
    setobpiInterpretation(interpretations);
    const audits = new Array();
    for (const audit of __OBPI_AUDIT__) {
      const score = obpiTest?.audits?.find(
        (x) => x.scale.toUpperCase() === audit.category.toUpperCase()
      );
      audits.push(score?.rank);
    }
    setOBPIAudit(audits);
  }, [obpiTest]);

  React.useEffect(() => {
    if (
      !obpiBenchmark?.obpiResult ||
      obpiBenchmark.id === assessment.obpiBenchmark.id
    ) {
      return;
    }

    executeAction(
      setOBPIBenchmarkToAssessment({
        aid,
        obpiBenchmark,
      })
    )
      .unwrap()
      .then((docs) => {
        // console.log(docs);
      })
      .catch((e) => {
        console.dir(e);
      });
  }, [obpiBenchmark]);

  React.useEffect(() => {
    if (!assessment || !Object.keys(assessment || {}).length) {
      return;
    }

    const { tests } = assessment || {};
    const newObjectiveTests = new Array();
    const newSubjectiveTests = new Array();
    (tests || []).forEach((test, idx) => {
      if (test?.testcnt > 0 || test?.skills?.length > 0) {
        (test?.skills || []).forEach((skill) => {
          if (candidate?.results?.[skill.uuid]) {
            newObjectiveTests.push(skill);
          }
        });
        return;
      }

      const { section } = test;
      if (
        section === 'video' ||
        section === 'essay' ||
        section === 'multiple-choice' ||
        section === 'file-upload' ||
        section === 'spreadsheets' ||
        section === 'code'
      ) {
        newSubjectiveTests.push(test);
      } else {
        // if(test?.title_en?.indexOf('인성/심리검사') >= 0) {
        // if(test?.title_en?.indexOf('OBPI') >= 0) {
        // tid가 1310이고 lang가 ko일 때만 OBPI이다.
        if (test?.id === '1310' && lang === 'ko') {
          setOBPITest(candidate?.results?.obpi);
          var newOBPIResult;
          var obpiMatchScore = 0;

          // if(test.title_en?.indexOf('인성/심리검사') >= 0) {
          // if(test.title_en?.indexOf('OBPI') >= 0) {
          // tid가 1310이고 lang가 ko일 때만 OBPI이다.
          if (test?.id === '1310' && lang === 'ko') {
            newOBPIResult = candidate?.results?.obpi;
            if (obpiBenchmark?.obpiResult) {
              var scores = 0;
              OBPI_TESTS.forEach((category) => {
                const benchmark = obpiBenchmark?.obpiResult?.traits?.find(
                  (x) => x.scale === category.category
                );
                const curOBPI = newOBPIResult?.traits?.find(
                  (x) => x.scale === category.category
                );
                scores +=
                  __MAX_OBPI_MATCH_SCORE -
                  Math.abs(benchmark?.stanine - curOBPI?.stanine);
              });
              obpiMatchScore = (
                scores /
                (__MAX_OBPI_MATCH_SCORE * __OBPI_LIST_NUM__)
              ).toFixed?.(2);
              setOBPIMatchScore(obpiMatchScore);
            }
            return;
          }
        } else {
          newObjectiveTests.push(test);
        }
      }
    });

    if (!printed) {
      setPrinted(true);
      setTimeout(() => window.print(), 5000);
    }
  }, [assessment, candidate, obpiBenchmark]);

  React.useEffect(() => {
    setOBPIBenchmark(assessment?.obpiBenchmark);
    setMaskedEmail(getMaskEmail(candidate?.email));
    var title =
      assessment?.name +
      displayName(candidate.firstName, candidate.lastName, t('lang'));
    document.title = title;
  }, [aid, cid, refreshing]);

  React.useEffect(() => {
    if (isRefreshing) {
      __setCandidates([]);
      return setIsRefreshing(false);
    }

    executeAction(
      getCandidatesOfAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ docs }) => {
        __setCandidates(docs || []);
      })
      .catch((e) => {
        __setCandidates([]);
        console.dir(e);
      });
  }, [aid, isRefreshing]);

  React.useEffect(() => {
    const candidates = new Array();
    __candidates.map((candidate) => {
      if (candidate?.results?.obpi === undefined) return;
      candidates.push({
        key: candidate.id,
        display: {
          en: `${candidate.firstName} ${candidate.lastName}`,
          ko: `${candidate.lastName} ${candidate.firstName}`,
        },
      });
    });
    _setCandidates(candidates);
  }, [__candidates]);

  React.useEffect(() => {
    if (!Object.keys(candidate || {}).length || !(subjectiveTests || []).length)
      return;

    const scores = new Array();
    const presetScores = new Array();

    (subjectiveTests || []).forEach((test) => {
      if (candidate?.results === undefined) return;

      if (test?.section === 'multiple-choice') {
        const answerCheck = Object.values(
          candidate?.results?.[test?.uuid || test?.id].checks
        )[0];
        const correctCheck = Object.values(test?.answers?.correct)[0];

        setAnswer(candidate?.results?.[test?.uuid]);

        if (answerCheck !== correctCheck) {
          /** ---------------------------------
                     * 응시자 초대 완료 일 때 클릭 시 오류 분기처리
                     -----------------------------------*/
          if (candidate.results !== undefined) {
            candidate.results[test.uuid || test.id].score = 0;
          } else {
            return;
          }

          executeAction(
            getScoreResult({
              aid,
              cid,
              score: 0,
              tuuid: test?.uuid || test?.id,
            })
          )
            .unwrap()
            .then(() => {
              dismissModal();
            })
            .catch((e) => {
              console.dir(e);
            });
        } else {
          if (candidate.results !== undefined) {
            candidate.results[test.uuid || test.id].score = 1;
          } else {
            return;
          }

          executeAction(
            getScoreResult({
              aid,
              cid,
              score: 1,
              tuuid: test?.uuid || test?.id,
            })
          )
            .unwrap()
            .then(() => {
              dismissModal();
            })
            .catch((e) => {
              console.dir(e);
            });
        }

        const score = candidate.results?.[test.uuid || test.id].score;
        const presetScore =
          candidate.results?.[test.uuid || test.id].preset_score;

        if (score) {
          scores.push(score);
        } else if (presetScore) {
          presetScores.push(presetScore);
        }
      } else {
        const score = candidate.results?.[test.uuid || test.id].score;
        const presetScore =
          candidate.results?.[test.uuid || test.id].preset_score;
        if (score) {
          scores.push(score);
        } else if (presetScore) {
          presetScores.push(presetScore);
        }

        return;
      }
    });

    const average = scores.reduce((a, b) => a + b, 0) / subjectiveTests.length;
    const presetAverage =
      presetScores.reduce((a, b) => a + b, 0) / subjectiveTests.length;

    setSubjectiveTestsAverageScore(average || 0);
    setSubjectiveTestsAveragePresetScore(presetAverage || 0);
  }, [candidate, subjectiveTests]);

  React.useEffect(() => {
    for (const [key, value] of Object.entries(candidate?.results || {})) {
      if (Object.values(value?.attributes || {}).length) {
        candidateAttributes.current = Object.values(value.attributes);
      }
      if (Object.values(value?.cultureAttributes || {}).length) {
        candidateCultureAttributes.current = Object.values(
          value.cultureAttributes
        );
      }
    }

    // TODO: check candidate have a culture fit test, because performance.
    executeAction(
      getAttributes({
        sid: '71',
      })
    )
      .unwrap()
      .then(({ docs }) => {
        const temp = new Array();
        if (candidateCultureAttributes.current) {
          for (const candidateAttribute of candidateAttributes.current || []) {
            const doc = (docs || []).find((x) => x.id === candidateAttribute);
            temp.push(doc);
          }
          setCandidateCultureFitAttributes(temp);
        }
      });

    executeAction(
      getCultureAttributes({
        sid: '71',
      })
    )
      .unwrap()
      .then(({ docs }) => {
        const temp = new Array();
        if (candidateCultureAttributes.current) {
          for (const candidateCultureAttribute of candidateCultureAttributes.current) {
            const doc = (docs || []).find(
              (x) => x.id === candidateCultureAttribute
            );
            temp.push(doc);
          }
          setCandidateCultureFitCultureAttributes(temp);
        }
      });
  }, [candidate]);

  const getHiringStageText = (step) => {
    step = Number(step);

    switch (step) {
      case 0:
        return 'text.hiring-stage.sended-email';
      case 1:
        return 'text.hiring-stage.wirte-done';
      case 2:
        return 'text.hiring-stage.under-investigation';
      case 3:
        return 'text.hiring-stage.reject';
      case 4:
        return 'text.hiring-stage.accept';
      default:
        return '';
    }
  };

  const getI18nKeyOfSection = (sectionCode) => {
    switch (sectionCode) {
      case 'video':
        return 'btn.video';
      case 'essay':
        return 'btn.essay';
      case 'multiple-choice':
        return 'btn.multiple-choice';
      case 'file-upload':
        return 'btn.file-upload';
      case 'spreadsheets':
        return 'btn.spreadsheet';
      case 'code':
        return 'btn.code';
      default:
        return '';
    }
  };

  const getScoreObjective = (test) => [
    objectiveScores?.[test?.uuid]?.[0]?.toFixed?.(2) || 0,
    objectiveScores?.[test?.uuid]?.[1] || 0,
    objectiveScores?.[test?.uuid]?.[2] || 0,
  ];

  const getCurrentLibraryAverageScore = (test) => {
    let testcnt = 0;
    let score = 0;
    test?.test.forEach((tmp) => {
      const result = candidate?.results?.[tmp];
      if (result) {
        score += result?.score || 0;
        testcnt++;
      }
    });
    return (score / testcnt).toFixed(2);
  };

  const getLibrayAverageScore = () => {
    let testcnt = 0;
    let score = 0;
    libraryTests?.forEach((lib) => {
      lib?.test?.forEach((tmp) => {
        const result = candidate?.results?.[tmp];
        if (result) {
          score += result?.score || 0;
          testcnt++;
        }
      });
    });

    if (score === 0) return 0;
    return (score / testcnt).toFixed(2);
  };

  const getAverageObjectiveScore = () => {
    let score = 0;
    let cnt = 0;
    Object.entries(objectiveScores || {}).forEach(([key, val], idx) => {
      const skill = (objectiveTests || []).find((x) => x.uuid === key);

      if (skill?.id !== '71') {
        if (val?.[0] >= 0 && val?.[0] <= 1) {
          score += val?.[0];
          cnt += 1;
        }
      }
    });

    if (cnt == 0) return 0;

    return (score / cnt)?.toFixed?.(2);
  };

  const getScore = (test) => {
    const tuuid = test?.uuid || test?.id;
    const result = candidate?.results?.[tuuid];
    return result?.score || 0;
  };

  const getAnswer = (test) => {
    const tuuid = test?.uuid || test?.id || test?.name;
    const result = candidate?.results?.[tuuid];
    const currentdate = new Date();
    let url = '';
    let html = '';
    switch (test?.section) {
      case 'video':
        url = `${currentdate.getTime()}|${result?.video}`;
        html = `${
          process.env.REACT_APP_ASSESSMENT_VIEW_VIDEO
        }?key=${encodeURIComponent(encryptAES(url))}`;
        break;
      case 'essay':
        html = convertHtmlToText(result?.text);
        break;
      case 'multiple-choice':
        break;
      case 'file-upload':
        url = `${currentdate.getTime()}|${result?.file}`;
        html = `${
          process.env.REACT_APP_ASSESSMENT_DOWNLOAD
        }?key=${encodeURIComponent(encryptAES(url))}`;
        break;
      case 'spreadsheets':
        break;
      case 'code':
        html = convertHtmlToText(result?.result || '');
        if (html.toUpperCase().indexOf('ERROR') >= 0) {
          html = t('code.result.error');
        }
        break;
      case 'language':
        url = `${currentdate.getTime()}|${result?.speaking[tuuid]}`;
        html = `${
          process.env.REACT_APP_ASSESSMENT_VIEW_VIDEO
        }?key=${encodeURIComponent(encryptAES(url))}`;
        break;
      default:
        break;
    }
    return html;
  };

  const onViewAnswerClickHandler = (test) => {
    switch (test?.section) {
      case 'video':
        displayModal(
          'assessments/viewAnswer/Video',
          {
            aid,
            cid,
            test,
            setRefreshing,
          },
          {
            frame: 'integration',
          }
        );
        break;
      case 'essay':
        displayModal(
          'assessments/viewAnswer/Essay',
          {
            aid,
            cid,
            test,
            setRefreshing,
          },
          {
            frame: 'integration',
          }
        );
        break;
      case 'multiple-choice':
        displayModal(
          'assessments/viewAnswer/MultipleChoice',
          {
            aid,
            cid,
            test,
            setRefreshing,
          },
          {
            frame: 'integration',
          }
        );
        break;
      case 'file-upload':
        displayModal(
          'assessments/viewAnswer/FileUpload',
          {
            aid,
            cid,
            test,
            setRefreshing,
          },
          {
            frame: 'integration',
          }
        );
        break;
      case 'spreadsheets':
        break;
      case 'code':
        break;
      default:
        break;
    }
  };
  const getMaskEmail = (myemailId) => {
    const maskid = myemailId?.replace(
      /^(.)(.*)(@.)(.*)\.(.*)$/,
      (_, a, b, c, d, e) =>
        `${a + b.replace(/./g, '*') + c + d.replace(/./g, '*')}.${e}`
    );
    return maskid;
  };

  const onSendEmailPassHandler = () => {
    const eventHandler = (event) => {
      if (event.detail?.message === 'send-email-pass') {
        setIsEntireLoading(true);
        executeAction(
          sendEmailPass({
            aid,
            email: candidate?.email,
            firstName: candidate?.firstName,
            lastName: candidate?.lastName,
          })
        )
          .unwrap()
          .then(() => {
            setIsEntireLoading(false);
            toast(t('toast.desc.sent-email'));
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
          });
      }
      window.document.removeEventListener('dismissModal', eventHandler);
    };
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'common/AlertModal',
      {
        event: {
          message: 'send-email-pass',
        },
        title: {
          main: t('dialog.title.pass-candidate'),
          sub: t('dialog.desc.pass-candidate'),
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  const onSendEmailReviewHandler = () => {
    const eventHandler = (event) => {
      if (event.detail?.message === 'send-email-review') {
        setIsEntireLoading(true);
        executeAction(
          sendEmailReview({
            aid,
            email: candidate?.email,
            firstName: candidate?.firstName,
            lastName: candidate?.lastName,
          })
        )
          .unwrap()
          .then(() => {
            setIsEntireLoading(false);
            toast(t('toast.desc.sent-email'));
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
          });
      }
      window.document.removeEventListener('dismissModal', eventHandler);
    };
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'common/AlertModal',
      {
        event: {
          message: 'send-email-review',
        },
        title: {
          main: t('dialog.title.review-candidate'),
          sub: t('dialog.desc.review-candidate'),
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  const onSendEmailRejectHandler = () => {
    const eventHandler = (event) => {
      if (event.detail?.message === 'send-email-reject') {
        setIsEntireLoading(true);
        executeAction(
          sendEmailReject({
            aid,
            email: candidate?.email,
            firstName: candidate?.firstName,
            lastName: candidate?.lastName,
          })
        )
          .unwrap()
          .then(() => {
            setIsEntireLoading(false);
            toast(t('toast.desc.sent-email'));
            // callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
            // callback && callback();
          });
      }
      window.document.removeEventListener('dismissModal', eventHandler);
    };
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'common/AlertModal',
      {
        event: {
          message: 'send-email-reject',
        },
        title: {
          main: t('dialog.title.reject-candidate'),
          sub: t('dialog.desc.reject-candidate'),
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  useMountEffect(() => {});

  const goURL = (url) => {
    window.location = url;
  };

  const getTestColorClass = (score) => {
    if (score >= 0.51) return 'blue';
    if (score >= 0.26) return 'orange';
    if (score > 0) return 'red';
    return '';
  };

  const getAuditColorClass = (audit) => {
    audit = Number(audit);

    switch (audit) {
      case 0:
        return '';
      case 1:
        return 'orange-opc';
      case 2:
        return 'red500-opc';
      case 3:
        return 'red-opc';
      default:
        return '';
    }
  };

  const setStanineCandidate = (candidate) => {
    if (!candidate) return;
    const selectedCandidate = __candidates.find((x) => x.id === candidate?.key);

    setOBPIBenchmark({
      id: selectedCandidate?.id,
      obpiResult: selectedCandidate.results.obpi,
    });
  };

  return (
    <article
      className={`pages-protected-candidates-detail-assessment-print ${
        showDetail ? 'show' : 'hide'
      }`}
    >
      {!isPersonality ? (
        <section className="user-info">
          <span className="name fs18">
            {displayName(candidate?.firstName, candidate?.lastName, t('lang'))}
          </span>
          <span className="e-mail fs12">{`${maskedEmail}`}</span>
          <span className="completed fs12">
            {t('desc.completion-date')}:{' '}
            {dateToDateFormat(
              new Date(candidate?.answeredAt?.seconds * 1000),
              t('lang')
            )}
          </span>
        </section>
      ) : (
        <section className="user-info khai">
          <span className="name fs16">
            <span class="title fs12">{t('label.table.name')} | </span>
            {displayName(candidate?.firstName, candidate?.lastName, t('lang'))}
          </span>
          <span className="e-mail fs12">
            <span class="title fs12">{t('field.email')} | </span>
            {`${maskedEmail}`}
          </span>
          <span className="e-mail fs12">
            <span class="title fs12">
              {t('text-field.label.test-number')} |{' '}
            </span>
            {candidate?.testNumber}
          </span>
          <span className="e-mail fs12">
            <span class="title fs12">{t('text-field.label.objective')} | </span>
            {candidate?.objective}
          </span>
          <span className="completed fs12">
            <span class="title fs12">{t('desc.completion-date')} | </span>
            {dateToDateFormat(
              new Date(candidate?.answeredAt?.seconds * 1000),
              t('lang')
            )}
          </span>
        </section>
      )}
      {!isPersonality ? (
        <section className="result">
          <section className="results-section print">
            {/* 종합 평가 */}
            <article className="summary-container">
              <aside className="summary-proctoring">
                {/* 부정행위 */}
                <h4 className="results-sub-title">
                  {t('title.summary.proctoring')}
                </h4>
                <div className="summary-proctoring-list">
                  <ul>
                    <li>
                      {convertDeviceCheck(candidate?.results?.detect?.ipOnce)}
                      {t('label.filled-out-only-once-from-ip-address')}
                    </li>
                    <li>
                      {convertDeviceCheck(candidate?.results?.detect?.onWebcam)}
                      {t('label.webcam-enabled')}
                    </li>
                    <li>
                      {convertDeviceCheck(
                        candidate?.results?.detect?.onFullscreen
                      )}
                      {t('label.full-screen-mode-always-active')}
                    </li>
                    <li>
                      {convertDeviceCheck(
                        candidate?.results?.detect?.isMouseLeave
                      )}
                      {t('label.mouse-always-in-assessment-window')}
                    </li>
                  </ul>
                </div>
              </aside>
              <aside className="summary-contents">
                <aside className="summary-average">
                  {/* 평균 점수 */}
                  <h4 className="results-sub-title">
                    {t('title.summary.average-score')}
                  </h4>
                  <div className="summary-total-chart">
                    <div className="single-chart">
                      <svg viewBox="0 0 36 36" className={`circular-chart`}>
                        <path
                          className="circle-bg"
                          d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path
                          className="circle"
                          strokeDasharray={`${Math.trunc(
                            totalAverage * 100
                          )} , 100`}
                          d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                      </svg>
                    </div>
                    <div className="percentage">
                      <span className="number fs56">{`${Math.trunc(
                        totalAverage * 100
                      )}`}</span>
                      <span className="unit fs36">%</span>
                    </div>
                  </div>
                </aside>
                <aside className="summary-detail-score">
                  {/* 상세 점수 */}
                  <h4 className="results-sub-title">
                    {t('title.summary.detail-score')}
                  </h4>
                  <div className="summary-bar-chart">
                    {testsAverageSuccess &&
                      testsAverageSuccess?.map((test) => (
                        <CustomBarChart
                          key={test?.id}
                          score={Math.trunc(test?.average * 100)}
                          title={test?.title}
                        />
                      ))}
                  </div>
                </aside>
              </aside>
            </article>

            {/* 테스트 결과 */}
            <article className="tests-container page-break">
              <aside className="tests-title">
                <h3 className="results-semi-main-title">{t('title.test')}</h3>
              </aside>
              <aside className="tests-contents page-break">
                {/* 반복문을 통해 하나의 테스트를 props로 넘겨줌 */}
                {newTests?.length !== 0 &&
                  newTests?.map((test, idx) => {
                    if (test?.id === '6010') return null;

                    if (test?.section && test?.section !== 'language') {
                      return (
                        <CustomTestResultToggleContainerPrint
                          type="print"
                          test={test}
                          key={idx}
                          getAnswer={getAnswer}
                          candidate={candidate}
                          assessment={assessment}
                          setRefreshing={setRefreshing}
                        />
                      );
                    }

                    return (
                      <TestResultToggleContainer
                        test={test}
                        key={idx}
                        getAnswer={getAnswer}
                        type={'print'}
                        candidate={candidate}
                        assessment={assessment}
                        setRefreshing={setRefreshing}
                        objectiveTests={objectiveTests}
                      />
                    );
                  })}
              </aside>
            </article>
          </section>
        </section>
      ) : (
        <>
          <section className="result">
            <section className="result-objective">
              {hasKhaiP && (
                <section className="result-khai-p-section">
                  <div className="khai-title">
                    <div className="khai-logo">
                      <KHAILogo />
                    </div>
                    <div className="result-khai-p-title fs11">
                      <h4>{t('title.khai-p')}</h4>
                    </div>
                  </div>
                  <div className="result-khai-p-wrap">
                    <div className="result-khai-p-overall">
                      <div className="result-khai-p-overall-result">
                        <div className="title fs11">
                          {t('title.overall-results')}
                        </div>
                        <div className="score">
                          <div className="overall-grade">
                            <div className="title fs8">
                              {t('title.overall-grade')}
                            </div>
                            <div className="grade">
                              {getKHAI_P_Grade('total', KHAIPResult)}
                            </div>
                          </div>
                          <div className="overall-score">
                            <div className="title fs8">
                              {t('title.overall-score-khai')}
                            </div>
                            <div className="score-graph">
                              <div className="single-chart">
                                <svg
                                  viewBox="0 0 36 36"
                                  className={`circular-chart ${getKHAIPColor(
                                    KHAIPResult?.total?.score
                                  )} ${KHAIPResult?.total?.score}`}
                                >
                                  <path
                                    className="circle-bg-khai"
                                    d="M18 2.0845
                                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                  />
                                  <path
                                    className="circle-khai"
                                    strokeDasharray={`${KHAIPResult?.total?.score} , 100`}
                                    d="M18 2.0845
                                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                  />
                                </svg>
                              </div>
                              <div className="percentage">
                                <span className="number fs16">{`${KHAIPResult?.total?.score}`}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="candidates-rank"
                          style={{ display: candidatecnt < 30 ? 'none' : '' }}
                        >
                          <div className="title fs9">
                            {t('title.candidates-ranking')}
                          </div>
                          <div className="rank">
                            <div className="my-rank fs9">{rank}등</div>
                            <div className="base fs7">
                              /
                              <Trans
                                t={t}
                                i18nKey="desc.candidates-ranking"
                                values={{
                                  candidatecnt,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="bigdata-rank">
                          <div className="title fs9">
                            {t('title.bigdata-ranking')}
                          </div>
                          <div className="rank">
                            <div className="my-rank fs9">
                              {getKHAI_P_BigDataRank(KHAIPResult?.total?.level)}
                            </div>
                          </div>
                        </div>
                        <div className="response-confidence">
                          <div className="title fs9">
                            {t('title.response-confidence')}
                          </div>
                          <div className="rank fs9">
                            {getKHAIPReliability(KHAIPResult, t, 'fs9')}
                          </div>
                        </div>
                      </div>
                      <div
                        className="result-hkai-p-ovrall-score-graph"
                        style={{ height: candidatecnt < 30 ? '100%' : '256px' }}
                      >
                        <div className="title fs11">
                          {t('title.score-distribution-graph')}
                        </div>
                        <div className="graph">
                          <div className="score-distribution-graph">
                            <ScoreGraph
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                          <div
                            className="candidate-score"
                            style={{
                              left: `calc(428.65px * ${KHAIPResult?.total?.score} / 100)`,
                            }}
                          >
                            <CandidateScore
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="result-khai-p-result-by-propensity">
                      <div className="title fs11">
                        {t('title.result-by-propensity')}
                      </div>
                      <div className="result">
                        <div className="radar-graph">
                          <div className="graph">
                            <ResponsiveContainer width="100%" height="100%">
                              <RadarChart
                                cx="50%"
                                cy="50%"
                                outerRadius="80%"
                                data={KHAIPRadarResult}
                              >
                                <PolarGrid gridType="circle" />
                                <PolarAngleAxis dataKey="subject" />
                                <Radar
                                  name="본인"
                                  dataKey="A"
                                  stroke="#3294EE"
                                  strokeWidth={3}
                                  fill="#3294EE"
                                  fillOpacity={0.1}
                                />
                                <Radar
                                  name="빅데이터 평균"
                                  dataKey="B"
                                  stroke="#24C185"
                                  strokeWidth={3}
                                  fill="#24C185"
                                  fillOpacity={0.1}
                                />
                              </RadarChart>
                            </ResponsiveContainer>
                          </div>
                          <div className="legend">
                            <div className="candidate">
                              <div className="title fs7">본인</div>
                              <div className="bullet" />
                            </div>
                            <div className="bigdata">
                              <div className="title fs7">빅데이터 평균</div>
                              <div className="bullet" />
                            </div>
                          </div>
                        </div>
                        <div className="result-desc">
                          <div className="propensity">
                            <div className="title fs9">
                              {t('title.personal-propensity')}
                            </div>
                            <div className="grade">
                              {getKHAI_P_Grade(
                                'personal-propensity',
                                KHAIPResult
                              )}
                            </div>
                            <div className="desc fs8">
                              {t('desc.personal-propensity')}
                            </div>
                          </div>
                          <div className="propensity">
                            <div className="title fs9">
                              {t('title.task-propensity')}
                            </div>
                            <div className="grade">
                              {getKHAI_P_Grade('task-propensity', KHAIPResult)}
                            </div>
                            <div className="desc fs8">
                              {t('desc.task-propensity')}
                            </div>
                          </div>
                          <div className="propensity">
                            <div className="title fs9">
                              {t('title.relationship-propensity')}
                            </div>
                            <div className="grade">
                              {getKHAI_P_Grade(
                                'relationship-propensity',
                                KHAIPResult
                              )}
                            </div>
                            <div className="desc fs8">
                              {t('desc.relationship-propensity')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="result-khai-p-detail-result">
                      <div className="title fs11">
                        {t('title.detailed-results')}
                      </div>
                      <div className="detail-result">
                        <div className="propensity" style={{ width: '33.3%' }}>
                          <div className="title fs9">
                            {t('title.personal-propensity')}
                          </div>
                          <div className="graphs">
                            {KHAIPResult &&
                              (
                                Object.keys(
                                  KHAIPResult?.['personal-propensity']
                                ) || []
                              ).map((data, index) => (
                                <KHAIPDetailGraph
                                  key={index}
                                  myscore={
                                    KHAIPResult?.['personal-propensity'][data]
                                      ?.myscore
                                  }
                                  bigdatascore={
                                    KHAIPResult?.['personal-propensity'][data]
                                      ?.bigdatascore
                                  }
                                  tooltip={
                                    KHAIPResult?.['personal-propensity'][data]
                                      ?.tooltip
                                  }
                                  title={t(
                                    KHAIPResult?.['personal-propensity'][data]
                                      ?.title
                                  )}
                                  title_class="fs8"
                                  score_class="fs8"
                                />
                              ))}
                          </div>
                        </div>
                        <div className="propensity" style={{ width: '44.4%' }}>
                          <div className="title fs9">
                            {t('title.task-propensity')}
                          </div>
                          <div className="graphs">
                            {KHAIPResult &&
                              (
                                Object.keys(KHAIPResult?.['task-propensity']) ||
                                []
                              ).map((data, index) => (
                                <KHAIPDetailGraph
                                  key={index}
                                  myscore={
                                    KHAIPResult?.['task-propensity'][data]
                                      ?.myscore
                                  }
                                  bigdatascore={
                                    KHAIPResult?.['task-propensity'][data]
                                      ?.bigdatascore
                                  }
                                  tooltip={
                                    KHAIPResult?.['task-propensity'][data]
                                      ?.tooltip
                                  }
                                  title={t(
                                    KHAIPResult?.['task-propensity'][data]
                                      ?.title
                                  )}
                                  title_class="fs8"
                                  score_class="fs8"
                                />
                              ))}
                          </div>
                        </div>
                        <div className="propensity" style={{ width: '22.3%' }}>
                          <div className="title fs9">
                            {t('title.relationship-propensity')}
                          </div>
                          <div className="graphs">
                            {KHAIPResult &&
                              (
                                Object.keys(
                                  KHAIPResult?.['relationship-propensity']
                                ) || []
                              ).map((data, index) => (
                                <KHAIPDetailGraph
                                  key={index}
                                  myscore={
                                    KHAIPResult?.['relationship-propensity'][
                                      data
                                    ]?.myscore
                                  }
                                  bigdatascore={
                                    KHAIPResult?.['relationship-propensity'][
                                      data
                                    ]?.bigdatascore
                                  }
                                  tooltip={
                                    KHAIPResult?.['relationship-propensity'][
                                      data
                                    ]?.tooltip
                                  }
                                  title={t(
                                    KHAIPResult?.['relationship-propensity'][
                                      data
                                    ]?.title
                                  )}
                                  title_class="fs8"
                                  score_class="fs8"
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="legend">
                        <div className="candidate">
                          <div className="title fs7">본인</div>
                          <div className="bullet" />
                        </div>
                        <div className="bigdata">
                          <div className="title fs7">빅데이터 평균</div>
                          <div className="bullet" />
                        </div>
                      </div>
                    </div>
                    <div
                      className="result-khai-p-comment"
                      style={{ breakAfter: 'page' }}
                    >
                      <div className="result-khai-p-detail-comment">
                        <div className="title fs11">요인별 정의</div>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                  rowSpan={3}
                                >
                                  {t('title.personal-propensity')}
                                </TableCell>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                >
                                  {t('title.positive-attitude')}
                                </TableCell>
                                <TableCell className="grey900 fs9">
                                  <ul style={{ marginLeft: '12px' }}>
                                    <li className="fs9">
                                      긍정성: 매사를 긍정적으로 바라보고
                                      타인에게까지 이를 전파하는 역량
                                    </li>
                                    <li className="fs9">
                                      자신감: 무엇이든 할 수 있다는 믿음을 갖고
                                      매사에 당당하게 맞서는 역량
                                    </li>
                                    <li className="fs9">
                                      활기: 넘치는 에너지와 힘으로 항상 생동감을
                                      유지하는 역량
                                    </li>
                                  </ul>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                >
                                  {t('title.trust-will')}
                                </TableCell>
                                <TableCell className="grey900 fs9">
                                  <ul style={{ marginLeft: '12px' }}>
                                    <li className="fs9">
                                      소신: 자신의 생각과 믿음에 따라 행동하고
                                      이를 지키는 역량
                                    </li>
                                    <li className="fs9">
                                      신뢰성: 거짓이나 꾸밈없이 정직하게
                                      행동하고 신뢰를 주는 역량
                                    </li>
                                    <li className="fs9">
                                      우직함: 묵묵히 어려움을 참아내고 맡은바
                                      임무나 일을 끈기 있게 완수하는 역량
                                    </li>
                                    <li className="fs9">
                                      윤리성: 어떤 상황에서든 자신의 양심에 따라
                                      원칙과 규정을 준수하는 역량
                                    </li>
                                  </ul>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                >
                                  {t('title.self-management')}
                                </TableCell>
                                <TableCell className="grey900 fs9">
                                  <ul style={{ marginLeft: '12px' }}>
                                    <li className="fs9">
                                      감정조절: 자신의 감정을 조절하고 통제하여
                                      평정심을 유지하는 역량
                                    </li>
                                    <li className="fs9">
                                      자기개발: 자신의 발전을 위해 스스로
                                      학습하고 개발하는 역량
                                    </li>
                                    <li className="fs9">
                                      자기관리: 자신의 신체적/정신적 상태를 항상
                                      최적의 상태로 유지하는 역량
                                    </li>
                                  </ul>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                  rowSpan={4}
                                >
                                  {t('title.task-propensity')}
                                </TableCell>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                >
                                  {t('title.creativity-innovation')}
                                </TableCell>
                                <TableCell className="grey900 fs9">
                                  <ul style={{ marginLeft: '12px' }}>
                                    <li className="fs9">
                                      도전정신: 어렵거나 새로운 일에 도전하고
                                      경쟁을 즐기는 역량
                                    </li>
                                    <li className="fs9">
                                      창의성: 독특하고 유용한 아이디어를
                                      생각해내고 적용하는 역량
                                    </li>
                                    <li className="fs9">
                                      혁신지향: 현재에 안주하지 않고 새로운
                                      방식이나 기술을 기꺼이 받아들이고 변화하는
                                      역량
                                    </li>
                                  </ul>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                >
                                  {t('title.execution-promotion')}
                                </TableCell>
                                <TableCell className="grey900 fs9">
                                  <ul style={{ marginLeft: '12px' }}>
                                    <li className="fs9">
                                      실행력: 생각을 행동으로 옮기거나 실제
                                      적용하고 밀고 나가는 역량
                                    </li>
                                    <li className="fs9">
                                      적극성: 스스로 먼저 나서 일을 맡고
                                      주도적으로 처리해내는 역량
                                    </li>
                                    <li className="fs9">
                                      책임감: 자신이 맡은 일을 정해진 기한 내에
                                      완벽하게 마무리하고 책임을 지는 역량
                                    </li>
                                    <li className="fs9">
                                      열정: 맡은바 임무나 일에 애정과 열정을
                                      가지고 몰입해 최선을 다하는 역량
                                    </li>
                                  </ul>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                >
                                  {t('title.analysis-management')}
                                </TableCell>
                                <TableCell className="grey900 fs9">
                                  <ul style={{ marginLeft: '12px' }}>
                                    <li className="fs9">
                                      분석적사고: 상황이나 문제를 통합적으로
                                      분석하고 해석하여 의미와 핵심을 찾아내는
                                      역량
                                    </li>
                                    <li className="fs9">
                                      계획성: 사전에 일의 처리방법이나 프로세스
                                      등을 준비하고 계획하는 역량
                                    </li>
                                    <li className="fs9">
                                      신중성: 가용한 정보나 자원을 충분히
                                      검토하고 조심스럽게 일을 처리하는 역량
                                    </li>
                                    <li className="fs9">
                                      꼼꼼함: 작은 실수도 없도록 철저하고
                                      세밀하게 검토/점검하는 역량
                                    </li>
                                  </ul>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                >
                                  {t('title.teamwork')}
                                </TableCell>
                                <TableCell className="grey900 fs9">
                                  <ul style={{ marginLeft: '12px' }}>
                                    <li className="fs9">
                                      통솔력: 동료나 구성원들을 원하는 방향으로
                                      이끌고 관리하는 역량
                                    </li>
                                    <li className="fs9">
                                      솔선수범: 매사에 말과 행동의 모범을 보여
                                      본이 되는 역량
                                    </li>
                                    <li className="fs9">
                                      팀워크: 팀 공동의 목표달성을 위해 기꺼이
                                      협력하고 희생하는 역량
                                    </li>
                                  </ul>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                  rowSpan={2}
                                >
                                  {t('title.relationship-propensity')}
                                </TableCell>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                >
                                  {t('title.acceptance-understanding')}
                                </TableCell>
                                <TableCell className="grey900 fs9">
                                  <ul style={{ marginLeft: '12px' }}>
                                    <li className="fs9">
                                      개방성: 다양한 사람들의 다양한 의견이나
                                      가치관을 인정/존중하고 받아들이는 역량
                                    </li>
                                    <li className="fs9">
                                      순응성: 상하간의 위계질서를 지키고
                                      윗사람의 질책이나 충고를 기꺼이 받아들이는
                                      역량
                                    </li>
                                    <li className="fs9">
                                      타인이해: 상대방의 입장에서 상대방의
                                      생각이나 감정을 이해하고 배려하는 역량
                                    </li>
                                  </ul>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ width: '90px' }}
                                  className="bold grey900 fs9"
                                >
                                  {t('title.consideration-relationship')}
                                </TableCell>
                                <TableCell className="grey900 fs9">
                                  <ul style={{ marginLeft: '12px' }}>
                                    <li className="fs9">
                                      겸손: 항상 자신을 낮추고 스스로를
                                      내새우기보다 타인을 존중하는 역량
                                    </li>
                                    <li className="fs9">
                                      예의: 항상 상황과 장소, 대상에 따라 적절한
                                      예의를 지키는 역량
                                    </li>
                                    <li className="fs9">
                                      낯선 환경, 낯선 사람과 쉽게 어울리고
                                      친밀감을 형성하는 역량
                                    </li>
                                  </ul>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                    <div
                      className="result-khai-p-comment"
                      style={{ breakAfter: 'page' }}
                    >
                      <div className="title fs11">
                        {t('title.overall-comment')}
                      </div>
                      <KhaiPInterview iv={KHAIPQuestion} />
                    </div>
                  </div>
                </section>
              )}
              {hasKhaiO && (
                <section className="result-khai-o-section">
                  <div className="khai-title">
                    <div className="khai-logo">
                      <KHAILogo />
                    </div>
                    <div className="result-khai-o-title fs14">
                      <h4>{t('title.khai-o')}</h4>
                    </div>
                  </div>
                  <div className="result-khai-o-overall">
                    <div className="title fs11">
                      {t('title.overall-results')}
                    </div>
                    <div className="result-wrap">
                      <div className="result-cell">
                        <div className="result-title fs11">
                          {t('title.response-confidence')}
                        </div>
                        {getKhaiO_Reliability(
                          KHAIOResult?.response_reliability,
                          'fs16',
                          'result-comment',
                          'response_reliability',
                          t
                        )}
                      </div>
                      <div className="result-cell">
                        <div className="result-title fs11">
                          {t('title.organizational-suitability')}
                        </div>
                        {getKhaiO_Reliability(
                          KHAIOResult?.organization_conformity,
                          'fs16',
                          'result-comment',
                          'organization_conformity',
                          t
                        )}
                      </div>
                    </div>
                    <div className="alert">
                      <div className="icon">
                        <ErrorIcon width="16px" height="16px" />
                      </div>
                      <div className="comment fs11">
                        {t('desc.organizational-suitability')}
                      </div>
                    </div>
                  </div>
                  <div className="result-khai-o-detail">
                    <div className="title fs11">
                      {t('title.detailed-results')}
                    </div>
                    <div className="info">
                      <div className="title fs11">
                        {t('title.organizational-criteria')}
                      </div>
                      <div className="contents">
                        <div className="content">
                          <div className="bullet grey400" />
                          <div className="comment fs9">
                            {t('desc.organizational-criteria-average')}
                          </div>
                        </div>
                        <div className="content">
                          <div className="bullet orange500" />
                          <div className="comment fs9">
                            {t('desc.organizational-criteria-caution')}
                          </div>
                        </div>
                        <div className="content">
                          <div className="bullet red500" />
                          <div className="comment fs9">
                            {t('desc.organizational-criteria-vigilance')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <TableContainer>
                      <Table className="table">
                        <TableHead className="header">
                          <TableRow>
                            <TableCell
                              className="table-header-cell fs9"
                              width={68}
                            >
                              {t('title.organizational')}
                            </TableCell>
                            <TableCell
                              className="table-header-cell fs9"
                              width="auto"
                            >
                              {t('title.definition-contents')}
                            </TableCell>
                            <TableCell
                              className="table-header-cell fs9"
                              colSpan={2}
                              width="auto"
                            >
                              {t('title.t-score')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {KHAIOResult?.organization_adaptability &&
                            [
                              'aggression',
                              'impulsiveness',
                              'interest_desire',
                              'indolence',
                              'dependence',
                              'self_downing',
                              'obsession',
                              'contempt_others',
                              'isolation',
                              'rigor',
                              'immorality',
                              'noncooperation',
                            ].map((value) => (
                              <TableRow key={value}>
                                <TableCell
                                  className="table-body-cell bold fs9"
                                  width={25}
                                >
                                  {
                                    KHAIOResult?.organization_adaptability[
                                      value
                                    ].title
                                  }
                                </TableCell>
                                <TableCell className="table-body-cell fs9">
                                  {
                                    KHAIOResult?.organization_adaptability[
                                      value
                                    ].desc
                                  }
                                </TableCell>
                                <TableCell
                                  className="table-body-cell bold fs9 score"
                                  width={20}
                                >
                                  {
                                    KHAIOResult?.organization_adaptability[
                                      value
                                    ].score
                                  }
                                </TableCell>
                                <TableCell
                                  className="table-body-cell graph-cell"
                                  width={160}
                                >
                                  <div className="progress-bar">
                                    <div
                                      className={`fill ${getKhaiO_organization_color(
                                        KHAIOResult?.organization_adaptability[
                                          value
                                        ].score
                                      )}`}
                                      style={{
                                        width: `${parseInt(
                                          KHAIOResult
                                            ?.organization_adaptability[value]
                                            .score
                                        )}%`,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="average"
                                    style={{ left: '50%' }}
                                  />
                                  <div
                                    className="caution"
                                    style={{ left: '70%' }}
                                  />
                                  <div
                                    className="vigilance"
                                    style={{ left: '80%' }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div className="alert">
                      <div className="icon">
                        <ErrorIcon width="16px" height="16px" />
                      </div>
                      <div className="comment fs11">
                        {t('desc.propensity-risk-level')}
                      </div>
                    </div>
                    <div className="info">
                      <div className="title fs11">
                        {t('title.propensity-risk-level')}
                      </div>
                      <div className="contents">
                        <div className="content">
                          <div className="bullet red500" />
                          <div className="comment fs9">
                            {t('desc.propensity-risk-level-element-a')}
                          </div>
                        </div>
                        <div className="content">
                          <div className="bullet red500" />
                          <div className="comment fs9">
                            {t('desc.propensity-risk-level-element-b')}
                          </div>
                        </div>
                        <div className="content">
                          <div className="bullet red500" />
                          <div className="comment fs9">
                            {t('desc.propensity-risk-level-element-c')}
                          </div>
                        </div>
                        <div className="content">
                          <div className="bullet red500" />
                          <div className="comment fs9">
                            {t('desc.propensity-risk-level-element-d')}
                          </div>
                        </div>
                        <div className="content">
                          <div className="bullet red500" />
                          <div className="comment fs9">
                            {t('desc.propensity-risk-level-element-e')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <TableContainer>
                      <Table className="table">
                        <TableHead className="header">
                          <TableRow>
                            <TableCell
                              className="table-header-cell fs9"
                              width={68}
                            >
                              {t('title.propensity')}
                            </TableCell>
                            <TableCell
                              className="table-header-cell fs9"
                              width="auto"
                            >
                              {t('title.definition-contents')}
                            </TableCell>
                            <TableCell
                              className="table-header-cell fs9"
                              colSpan={2}
                              width="auto"
                            >
                              {t('title.percentile')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {KHAIOResult?.abnormality_tendency &&
                            [
                              'boundary_line',
                              'antisociality',
                              'anxiety',
                              'gloom',
                            ].map((value) => (
                              <TableRow key={value}>
                                <TableCell className="table-body-cell bold fs9">
                                  {
                                    KHAIOResult?.abnormality_tendency[value]
                                      .title
                                  }
                                </TableCell>
                                <TableCell className="table-body-cell fs9">
                                  {
                                    KHAIOResult?.abnormality_tendency[value]
                                      .desc
                                  }
                                </TableCell>
                                <TableCell
                                  className="table-body-cell bold fs9 score"
                                  width={20}
                                >
                                  {KHAIOResult?.abnormality_tendency[value]
                                    .score ||
                                    KHAIOResult?.abnormality_tendency[value]}
                                  %
                                </TableCell>
                                <TableCell
                                  className="table-body-cell graph-cell"
                                  width={160}
                                >
                                  <div className="progress-bar">
                                    <div
                                      className={`fill ${
                                        parseInt(
                                          KHAIOResult?.abnormality_tendency[
                                            value
                                          ].score
                                        ) >
                                        parseInt(
                                          KHAIOResult?.abnormality_tendency[
                                            value
                                          ].boundary
                                        )
                                          ? 'red500'
                                          : 'blue500'
                                      }`}
                                      style={{
                                        width: `${parseInt(
                                          KHAIOResult?.abnormality_tendency[
                                            value
                                          ].score
                                        )}%`,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="vigilance"
                                    style={{
                                      left: `${KHAIOResult?.abnormality_tendency[value].boundary}%`,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {Object.keys(KHAIOQuestion).length > 0 && (
                    <div className="result-khai-o-interview">
                      <div className="title fs11">
                        {t('title.comments-and-interview-questions')}
                      </div>
                      <KhaiOInterview iv={KHAIOQuestion} type="print" />
                    </div>
                  )}
                </section>
              )}
              <section
                className="result-obpi"
                style={{ display: !obpiTest?.traits && 'none' }}
              >
                <div className="result-obpi-title fs18">
                  {t('title.obpi-result')}
                </div>
                <div className="result-obpi-content">
                  <div className="result-obpi-content-left">
                    <div className="obpi-fit-score">
                      <div className="obpi-fit-score-title fs16">
                        {t('title.obpi-fit-score')}
                      </div>
                      <div className="result-obpi-fitscore-graph">
                        <div className="average-score-graph">
                          <div className="single-chart">
                            <svg
                              viewBox="0 0 36 36"
                              className={`circular-chart ${getTestColorClass(
                                obpimatchScore
                              )} ${obpimatchScore}`}
                            >
                              <path
                                className="circle-bg"
                                d="M18 2.0845
                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <path
                                className="circle-noani"
                                strokeDasharray={`${parseInt(
                                  obpimatchScore * 100
                                )} , 100`}
                                d="M18 2.0845
                                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                            </svg>
                          </div>
                          <div className="percentage">
                            <span className="number fs56">{`${parseInt(
                              obpimatchScore * 100
                            )}`}</span>
                            <span className="unit fs36">%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="result-obpi-audit">
                      <div className="result-obpi-audit-title fs16">
                        {t('title.audit')}
                      </div>
                      <div className="result-obpi-audit-result">
                        <div className="row">
                          {__OBPI_AUDIT__.map((audit, idx) => (
                            <div className="col" key={idx}>
                              <div className="title fs14">{t(audit.title)}</div>
                              <div className="graph">
                                <div className="single-chart">
                                  <svg
                                    viewBox="0 0 36 36"
                                    className={`circular-chart ${getAuditColorClass(
                                      obpiAudit[idx]
                                    )}`}
                                  >
                                    <path
                                      className="circle-bg"
                                      d="M18 2.0845
                                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                    <path
                                      className="circle-noani"
                                      strokeDasharray={`${parseInt(
                                        (obpiAudit[idx] / 3) * 100
                                      )} , 100`}
                                      d="M18 2.0845
                                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                  </svg>
                                </div>
                                <div className="percentage">
                                  <span className="number fs28">
                                    {obpiAudit[idx]}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="result-obpi-content-right">
                    {(OBPI_TESTS || []).map((obpi, idx) => (
                      <OBPIResult
                        key={idx}
                        title={t(obpi.title)}
                        title_class="fs14"
                        selected={obpiStanine?.[idx]}
                        disc={obpi.disc}
                        disc_left={t(obpi.left)}
                        disc_right={t(obpi.right)}
                        disc_class="fs12"
                        interp_class="fs14"
                        interpretation={obpiInterpretation?.[idx]}
                        printmode
                      />
                    ))}
                  </div>
                </div>
              </section>
              <section
                className="result-subjective-table"
                style={{ display: libraryTests?.length === 0 ? 'none' : '' }}
              >
                <h4 className="result-subjective-title fs18">
                  {t('title.subjective-test-result')}
                </h4>
                <Paper>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="title thead fs12">
                            {t('column.test')}
                          </TableCell>
                          <TableCell className="section thead fs12" width="20%">
                            {t('column.type')}
                          </TableCell>
                          <TableCell className="score thead fs12" width="15%">
                            {t('column.score')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(libraryTests || []).map((test, idx) => (
                          <React.Fragment key={idx}>
                            <TableRow key={idx}>
                              <TableCell className="title tbody fs12">
                                {convertHtmlToText(test?.category_skill)}
                              </TableCell>
                              <TableCell className="section tbody fs12">
                                <div className="types">
                                  {(
                                    test?.question_type
                                      ?.replaceAll(' ', '')
                                      .split(',') || []
                                  ).map((type, idx) => (
                                    <span
                                      className={`skill-type fs12 ${type.replaceAll(
                                        '.',
                                        '_'
                                      )}`}
                                      key={idx}
                                    >
                                      {t(type)}
                                    </span>
                                  ))}
                                </div>
                              </TableCell>
                              <TableCell
                                className={`score tbody fs12 ${getCurrentLibraryAverageScore(
                                  test
                                )}`}
                              >
                                <div className="container">
                                  <ProgressScore
                                    score={getCurrentLibraryAverageScore(test)}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                            {(libraryDetail?.[test.id] || []).map(
                              (detail, didx) => (
                                <>
                                  <TableRow
                                    key={didx}
                                    sx={{ backgroundColor: '#F8F9FA' }}
                                  >
                                    <TableCell
                                      className="detailtitle tbody fs12"
                                      sx={{ paddingLeft: 4 }}
                                    >
                                      <pre
                                        className="fs12"
                                        dangerouslySetInnerHTML={{
                                          __html: convertHtmlForDisplay(
                                            detail?.question
                                          ),
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell className="section tbody fs12">
                                      <span
                                        className={`skill-type fs12 ${convertSkillTypeToI18nKey(
                                          detail?.section
                                        ).replaceAll('.', '_')}`}
                                      >
                                        {t(
                                          convertSkillTypeToI18nKey(
                                            detail?.section
                                          )
                                        )}
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      className={`score tbody fs16 ${getScore(
                                        test
                                      )}`}
                                    >
                                      <div className="container">
                                        <ProgressScore
                                          score={getScore(detail)}
                                        />
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow sx={{ backgroundColor: '#F8F9FA' }}>
                                    <TableCell
                                      className="answer tbody fs12"
                                      colSpan={3}
                                      sx={{ paddingLeft: 8 }}
                                    >
                                      <div className="title fs12">
                                        {t('title.answer')}
                                      </div>
                                      {detail?.section === 'video' && (
                                        <div className="subjective-answer fs12">
                                          <a
                                            href={getAnswer(detail)}
                                            className="sendfile fs12"
                                          >
                                            {t('btn.view-file-link')}
                                          </a>
                                        </div>
                                      )}
                                      {detail?.section === 'essay' && (
                                        <div className="subjective-answer fs12">
                                          {getAnswer(detail)}
                                        </div>
                                      )}
                                      {detail?.section ===
                                        'multiple-choice' && (
                                        <>
                                          <section className="answer">
                                            <div className="answer-description multiple-choice">
                                              <ul className="answer-description-list">
                                                {(
                                                  detail?.answers?.list || []
                                                ).map((item, idx) => (
                                                  <li
                                                    key={idx}
                                                    className={`answer-description-list-item ${
                                                      Object.values(
                                                        answer?.checks || {}
                                                      ).findIndex(
                                                        (x) => Number(x) === idx
                                                      ) >= 0 && 'selected'
                                                    }
                                                                                                ${
                                                                                                  (
                                                                                                    test
                                                                                                      ?.answers
                                                                                                      ?.correct ||
                                                                                                    []
                                                                                                  ).findIndex(
                                                                                                    (
                                                                                                      x
                                                                                                    ) =>
                                                                                                      Number(
                                                                                                        x
                                                                                                      ) ===
                                                                                                      idx
                                                                                                  ) >=
                                                                                                    0 &&
                                                                                                  'answer'
                                                                                                }`}
                                                  >
                                                    <div className="container">
                                                      <Radio
                                                        className="radio-button"
                                                        checked={
                                                          Object.values(
                                                            answer?.checks || {}
                                                          ).findIndex(
                                                            (x) =>
                                                              Number(x) === idx
                                                          ) >= 0
                                                        }
                                                        disabled
                                                      />
                                                      <div className="text">
                                                        {item}
                                                      </div>
                                                    </div>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          </section>
                                          <section className="currect">
                                            <h4 className="answer-title fs12">
                                              {t('title.correct-answer')}
                                            </h4>
                                            <div className="answer-description multiple-choice">
                                              <ul className="answer-description-list">
                                                <li className="answer-description-list-item selected">
                                                  <div className="container">
                                                    <Radio
                                                      className="radio-button"
                                                      checked
                                                      disabled
                                                    />
                                                    <div className="text">
                                                      {
                                                        detail?.answers?.list[
                                                          detail?.answers
                                                            ?.correct
                                                        ]
                                                      }
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </section>
                                        </>
                                      )}
                                      {test?.section === 'file-upload' && (
                                        <div className="subjective-answer">
                                          <a
                                            href={getAnswer(test)}
                                            className="sendfile"
                                          >
                                            {t('btn.view-file-link')}
                                          </a>
                                        </div>
                                      )}
                                      {detail?.section === 'code' && (
                                        <div className="subjective-answer fs12">
                                          <pre className="fs12">
                                            {getAnswer(detail)}
                                          </pre>
                                        </div>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </>
                              )
                            )}
                          </React.Fragment>
                        ))}
                        <TableRow
                          sx={{
                            backgroundColor: '#FFFFFF',
                          }}
                        >
                          <TableCell className="fs12">
                            {t('desc.average-score')}
                          </TableCell>
                          <TableCell />
                          <TableCell
                            className={`score tbody fs12 ${getLibrayAverageScore()}`}
                          >
                            <div className="container">
                              <ProgressScore score={getLibrayAverageScore()} />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </section>
              <section
                className="result-objective-section"
                style={{ display: objectiveTests?.length === 0 ? 'none' : '' }}
              >
                <h4 className="result-objective-title fs20">
                  {t('title.objective-test-result')}
                </h4>
                <div className="result-objective-wrap">
                  <div className="result-objective-graph">
                    <div className="graph-title fs16">
                      {t('desc.average-score')}
                    </div>
                    <div className="average-score-graph">
                      <div className="single-chart">
                        <svg
                          viewBox="0 0 36 36"
                          className={`circular-chart ${
                            getAverageObjectiveScore() > 0
                              ? getTestColorClass(getAverageObjectiveScore())
                              : ''
                          }`}
                        >
                          <path
                            className="circle-bg"
                            d="M18 2.0845
                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                          <path
                            className="circle-noani"
                            strokeDasharray={`${parseInt(
                              getAverageObjectiveScore() * 100
                            )} , 100`}
                            d="M18 2.0845
                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                        </svg>
                      </div>
                      <div className="percentage">
                        <span className="number fs56">{`${
                          getAverageObjectiveScore() >= 0
                            ? parseInt(getAverageObjectiveScore() * 100)
                            : '-'
                        }`}</span>
                        <span className="unit fs36">%</span>
                      </div>
                    </div>
                  </div>
                  <article className="result-objective-table">
                    <Paper sx={{ boxShadow: 'none' }}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className="title thead fs12"
                                width="55%"
                              >
                                {t('column.test')}
                              </TableCell>
                              <TableCell
                                className="score thead fs12"
                                width="45%"
                              >
                                {t('column.score')}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(objectiveTests || []).map((test, idx) => (
                              <React.Fragment key={idx}>
                                <TableRow key={idx}>
                                  <TableCell
                                    sx={{ fontWeight: 'bold' }}
                                    className="title tbody fs12"
                                  >
                                    {test?.[`title_${t('en')}`] || test?.title}
                                  </TableCell>
                                  <TableCell
                                    className={`score tbody fs12 ${
                                      getScoreObjective(test)?.[0]
                                    }`}
                                  >
                                    <div className="container">
                                      {test?.id === '71' ? (
                                        <></>
                                      ) : (
                                        <>
                                          <span>
                                            {`${parseInt(
                                              getScoreObjective(test)?.[0] * 100
                                            )}% 
                                                                                    (${
                                                                                      getScoreObjective(
                                                                                        test
                                                                                      )?.[1]
                                                                                    }/${
                                              getScoreObjective(test)?.[2]
                                            })`}
                                          </span>
                                          <div className="progress-bar">
                                            <div
                                              className="fill"
                                              style={{
                                                width: `${
                                                  getScoreObjective(test)?.[0] *
                                                  100
                                                }%`,
                                              }}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </TableCell>
                                </TableRow>
                                {(objectiveCats?.[test?.uuid] || []).map(
                                  (cat, idx) => (
                                    <TableRow
                                      key={idx}
                                      sx={{ backgroundColor: '#F8F9FA' }}
                                    >
                                      <TableCell className="tbody fs12 category">
                                        {cat?.category}
                                      </TableCell>
                                      <TableCell
                                        className={`score tbody fs12 ${
                                          getScoreObjective(test)?.[0]
                                        }`}
                                      >
                                        <div className="container">
                                          <span>
                                            {`${parseInt(
                                              (
                                                cat?.val?.correct /
                                                cat?.val?.cnt
                                              ).toFixed?.(2) * 100
                                            )}% 
                                                                                        (${
                                                                                          cat
                                                                                            ?.val
                                                                                            ?.correct
                                                                                        }/${
                                              cat?.val?.cnt
                                            })`}
                                          </span>
                                          <div className="progress-bar">
                                            <div
                                              className="fill sub"
                                              style={{
                                                width: `${parseInt(
                                                  (
                                                    cat?.val?.correct /
                                                    cat?.val?.cnt
                                                  ).toFixed?.(2) * 100
                                                )}%`,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}

                                {test.id === '71' && (
                                  <TableRow>
                                    <TableCell
                                      className="tbody fs12 culture-fit"
                                      colSpan={2}
                                    >
                                      <div className="container">
                                        <div className="value-survey">
                                          <h3 className="culture-fit-title fs12">
                                            {t(
                                              'title.culture-fit.value-survey'
                                            )}
                                          </h3>
                                          <ul className="attributes-list">
                                            {(
                                              candidateCultureFitAttributes ||
                                              []
                                            ).map((attribute, idx) => (
                                              <li
                                                key={idx}
                                                className="attributes-list-item"
                                              >
                                                {
                                                  attribute?.[
                                                    t('lang') === 'ko'
                                                      ? 'ko'
                                                      : 'en'
                                                  ]
                                                }
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                        <div className="culture-survey">
                                          <h3 className="culture-fit-title fs12">
                                            {t(
                                              'title.culture-fit.culture-survey'
                                            )}
                                          </h3>
                                          <ul className="attributes-list">
                                            {(
                                              candidateCultureFitCultureAttributes ||
                                              []
                                            ).map((attribute, idx) => (
                                              <li
                                                key={idx}
                                                className="attributes-list-item"
                                              >
                                                {
                                                  attribute?.[
                                                    t('lang') === 'ko'
                                                      ? 'ko'
                                                      : 'en'
                                                  ]
                                                }
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </article>
                </div>
              </section>
            </section>
            <section
              className="result-subjective page-break"
              style={{ display: subjectiveTests?.length === 0 ? 'none' : '' }}
            >
              <h4 className="result-subjective-title fs18">
                {t('title.custom-question-result')}
              </h4>
              <div className="alert page-break">
                <ErrorIcon width="24px" height="24px" />
                <div className="text fs12">{t('desc.warning-answer-url')}</div>
              </div>
              <article className="result-subjective-table page-break">
                <Paper className="page-break">
                  <TableContainer className="page-break">
                    <Table className="page-break">
                      <TableHead className="page-break">
                        <TableRow className="page-break">
                          <TableCell className="title thead fs12">
                            {t('column.question')}
                          </TableCell>
                          <TableCell className="section thead fs12" width="20%">
                            {t('column.type')}
                          </TableCell>
                          <TableCell className="score thead fs12" width="15%">
                            {t('column.score')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(subjectiveTests || []).map((test, idx) => (
                          <>
                            <TableRow key={idx} className="page-break">
                              <TableCell className="title tbody fs12">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: convertHtmlForDisplay(
                                      test?.question
                                    ),
                                  }}
                                />
                              </TableCell>
                              <TableCell className="section tbody fs12">
                                <span
                                  className={`skill-type fs12 ${convertSkillTypeToI18nKey(
                                    test?.section
                                  ).replaceAll('.', '_')}`}
                                >
                                  {t(convertSkillTypeToI18nKey(test?.section))}
                                </span>
                              </TableCell>
                              <TableCell
                                className={`score tbody fs12 ${getScore(test)}`}
                              >
                                <div className="container">
                                  <ProgressScore score={getScore(test)} />
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: '#F8F9FA' }}>
                              <TableCell
                                className="answer tbody fs12"
                                colSpan={3}
                                sx={{ paddingLeft: 4 }}
                              >
                                <div className="title fs12">
                                  {t('title.answer')}
                                </div>
                                {test?.section === 'video' && (
                                  <div className="subjective-answer fs12">
                                    <a
                                      href={getAnswer(test)}
                                      className="sendfile fs12"
                                    >
                                      {t('btn.view-file-link')}
                                    </a>
                                  </div>
                                )}
                                {test?.section === 'essay' && (
                                  <div className="subjective-answer fs12">
                                    {getAnswer(test)}
                                  </div>
                                )}
                                {test?.section === 'multiple-choice' && (
                                  <>
                                    <section className="answer">
                                      <div className="answer-description multiple-choice">
                                        <ul className="answer-description-list">
                                          {(test?.answers?.list || []).map(
                                            (item, idx) => (
                                              <li
                                                key={idx}
                                                className={`answer-description-list-item 
                                                                                        ${
                                                                                          Object.values(
                                                                                            answer?.checks ||
                                                                                              {}
                                                                                          ).findIndex(
                                                                                            (
                                                                                              x
                                                                                            ) =>
                                                                                              Number(
                                                                                                x
                                                                                              ) ===
                                                                                              idx
                                                                                          ) >=
                                                                                            0 &&
                                                                                          'selected'
                                                                                        }
                                                                                        ${
                                                                                          (
                                                                                            test
                                                                                              ?.answers
                                                                                              ?.correct ||
                                                                                            []
                                                                                          ).findIndex(
                                                                                            (
                                                                                              x
                                                                                            ) =>
                                                                                              Number(
                                                                                                x
                                                                                              ) ===
                                                                                              idx
                                                                                          ) >=
                                                                                            0 &&
                                                                                          'answer'
                                                                                        }`}
                                              >
                                                <div className="container">
                                                  <Radio
                                                    className="radio-button"
                                                    checked={
                                                      Object.values(
                                                        test?.answers
                                                          ?.correct || {}
                                                      ).findIndex(
                                                        (x) => Number(x) === idx
                                                      ) >= 0
                                                    }
                                                    disabled
                                                  />
                                                  <div className="text">
                                                    {item}
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </section>
                                    <section className="currect">
                                      <h4 className="answer-title fs12">
                                        {t('title.correct-answer')}
                                      </h4>
                                      <div className="answer-description multiple-choice">
                                        <ul className="answer-description-list">
                                          <li className="answer-description-list-item selected">
                                            <div className="container">
                                              <Radio
                                                className="radio-button"
                                                checked
                                                disabled
                                              />
                                              <div className="text">
                                                {
                                                  test?.answers?.list[
                                                    test?.answers?.correct
                                                  ]
                                                }
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </section>
                                  </>
                                )}
                                {test?.section === 'file-upload' && (
                                  <div className="subjective-answer">
                                    <a
                                      href={getAnswer(test)}
                                      className="sendfile"
                                    >
                                      {t('btn.view-file-link')}
                                    </a>
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                        <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                          <TableCell className="tbody fs12">
                            {t('desc.average-score')}
                          </TableCell>
                          <TableCell />
                          <TableCell
                            className={`score tbody fs12 ${subjectiveTestsAverageScore}`}
                          >
                            <div className="container">
                              <ProgressScore
                                score={subjectiveTestsAverageScore}
                                // presetScore={subjectiveTestsAveragePresetScore}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </article>
            </section>
          </section>
          <section className="info page-break">
            <article className="info-prevent box">
              <h4 className="info-prevent-title fs16">
                {t('title.anti-cheating-monitor')}
              </h4>
              <section className="info-prevent-device row fs12">
                <h5 className="info-prevent-device-title column key fs12">
                  {t('label.device-used')}
                </h5>
                <span className="info-prevent-device-value column value fs12">
                  {candidate?.results?.detect?.device?.client?.name || ''}
                </span>
              </section>

              <section className="info-prevent-ip-once row fs12">
                <h5 className="info-prevent-ip-once-title column key fs12">
                  {t('label.filled-out-only-once-from-ip-address')}
                </h5>
                <span
                  className={`info-prevent-ip-once-value column value fs12 ${
                    candidate?.results?.detect?.ipOnce ? 'yes' : 'no'
                  }`}
                >
                  {candidate?.results?.detect?.ipOnce
                    ? t('label.yes')
                    : t('label.no')}
                </span>
              </section>
              <section className="info-prevent-webcam-activate row fs12">
                <h5 className="info-prevent-webcam-active-title column key fs12">
                  {t('label.webcam-enabled')}
                </h5>
                <span
                  className={`info-prevent-webcam-activate-value column value fs12 ${
                    candidate?.results?.detect?.onWebcam ? 'yes' : 'no'
                  }`}
                >
                  {candidate?.results?.detect?.onWebcam
                    ? t('label.yes')
                    : t('label.no')}
                </span>
              </section>
              <section className="info-prevent-full-size row fs12">
                <h5 className="info-prevent-full-size-title column key fs12">
                  {t('label.full-screen-mode-always-active')}
                </h5>
                <span
                  className={`info-prevent-full-size-value column value fs12 ${
                    candidate?.results?.detect?.onFullscreen ? 'yes' : 'no'
                  }`}
                >
                  {candidate?.results?.detect?.onFullscreen
                    ? t('label.yes')
                    : t('label.no')}
                </span>
              </section>
              <section className="info-prevent-mouse-pointer row fs12">
                <h5 className="info-prevent-mouse-pointer-title column key fs12">
                  {t('label.mouse-always-in-assessment-window')}
                </h5>
                <span
                  className={`info-prevent-mouse-pointer-value column value fs12 ${
                    candidate?.results?.detect?.isMouseLeave ? 'no' : 'yes'
                  }`}
                >
                  {candidate?.results?.detect?.isMouseLeave
                    ? t('label.no')
                    : t('label.yes')}
                </span>
              </section>
            </article>
            <article className="info-detail box">
              <section className="info-detail-invited-at">
                <h5 className="info-detail-invited-at-title title fs16">
                  {t('title.invited')}
                </h5>
                <div className="info-detail-invited-at-timestamp desc fs12">
                  {dateToDateFormat(
                    new Date(candidate?.answeredAt?.seconds * 1000),
                    t('lang')
                  )}
                </div>
              </section>
              <section className="info-detail-completed-at">
                <h5 className="info-detail-completed-at-title title fs16">
                  {t('title.completed')}
                </h5>
                <div className="info-detail-completed-at-timestamp desc fs12">
                  {dateToDateFormat(
                    new Date(candidate?.answeredAt?.seconds * 1000),
                    t('lang')
                  )}
                </div>
              </section>
              <section className="info-detail-entry-route">
                <h5 className="info-detail-entry-route-title title fs16">
                  {t('title.source')}
                </h5>
                <div className="info-detail-entry-route-string desc fs12">
                  {/* In current, only way to exit is email. */}
                  Email
                </div>
              </section>
            </article>
          </section>
        </>
      )}
      <div id="divFooter" />
      <span id="pageNumber" />
    </article>
  );
};

export default NewAssessmentPrint;
