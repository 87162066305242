import React from 'react';
import { dateToDateFormat } from 'utils/string';
import { sumSalary } from 'utils/converter';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RecruitmentStatusTab from '../RecruitmentStatusTab';

const OfferDetail = ({ recruitment, currentCandidate, processLength }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);
  const step = processLength === 7 ? 5 : 6;
  const currentProgress = currentCandidate?.progressSubmit[step];

  const convertStatusToText = (status) => {
    let name;

    switch (status) {
      case 'extended':
        name = '요청 대기중';
        break;
      case 'accept':
        name = '요청 수락';
        break;
      case 'reject':
        name = '요청 거절';
        break;
      default:
        name = '';
        break;
    }

    return name;
  };

  return (
    <div className="wrapper offer-detail">
      <div className="flex">
        <div className="left">
          <div className="status-container">
            <div className="tagging">
              <p className="fs14">
                {convertStatusToText(currentProgress?.status)}
              </p>
            </div>
            <div className="start-date-box">
              <h6 className="fs16">{t('title.starting-date')}</h6>
              <span className="fs14">
                {dateToDateFormat(new Date(currentProgress?.startDate))}
              </span>
            </div>
          </div>
        </div>
        <div className="right">
          {currentCandidate?.recruitProgress === step &&
            userInfo?.memberType !== 20 && (
              <RecruitmentStatusTab
                recruitment={recruitment}
                currentCandidate={currentCandidate}
                processLength={processLength}
              />
            )}
        </div>
      </div>
      <section className="contents-section">
        <article className="title-box">
          <h6 className="fs16">{t('title.compensation-memo')}</h6>
        </article>
        <article className="contents-box">
          <aside className="salary-box">
            <span className="grey-label">
              {t('text-field.label.base-salary')}
            </span>
            <p className="fs24">
              {`${currentProgress?.totalFixed?.salary} ${currentProgress?.totalFixed?.currency}`}
            </p>
          </aside>
          <aside className="etc-box">
            <span className="grey-label">{t('title.cash-allowance')}</span>
            <p className="fs14">{currentProgress?.etcText}</p>
          </aside>
        </article>
      </section>
    </div>
  );
};

export default OfferDetail;
