// for test server
// module.exports = {
//     apiKey: 'AIzaSyB_h9IVAcaZ8w8RLibONU7UiHQG0djALto',
//     authDomain: 'munchskilldev.firebaseapp.com',
//     projectId: 'munchskilldev',
//     storageBucket: 'munchskilldev.appspot.com',
//     messagingSenderId: '363085340176',
//     appId: '1:363085340176:web:31c07c5041093ffd767495',
//     measurementId: 'G-8ZDFXJKL11',
// };

// for prod server
// module.exports = {
//     apiKey: "AIzaSyD2ufqp1zCkXejE1kOa7ER0JfkR7odfRUk",
//     authDomain: "munchskill-c700d.firebaseapp.com",
//     projectId: "munchskill-c700d",
//     storageBucket: "munchskill-c700d.appspot.com",
//     messagingSenderId: "1036471501457",
//     appId: "1:1036471501457:web:357afd7627bfa8ced83530",
//     measurementId: "G-21932SNPSS"
// }

module.exports = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
