import { Button } from 'components/common/Button';
import React from 'react';

const CompletedApply = () => {
  return (
    <main id="pages-completed-apply">
      <section className="completed-apply-container">
        <article className="completed-text-wrapper">
          <h2 className="fs28">지원서가 제출 되었습니다.</h2>
          <p className="fs16">
            서류 전형 결과에 대해 빠른 시일내에 이메일 안내 드릴 수 있도록
            하겠습니다.
          </p>
        </article>
        {/* <article className="button-wrapper">
          <Button
            label="Go to the main page"
            theme="grey"
            size="48"
            fontSize="fs16"
            onClick={onSubmitHandler}
          />
        </article> */}
      </section>
    </main>
  );
};

export default CompletedApply;
