import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { useModal } from 'utils/modal';
import { useTranslation } from 'react-i18next';
import { TextareaAutosize } from '@mui/material';
import { executeAction } from 'utils/redux';
import { getCandidateInAssessment } from 'store/slices/candidatesSlice';
import { gradeResult } from 'store/slices/assessmentsSlice';
import Sample from 'assets/images/styling-sample.jpg';
import { Score, EstimateScore } from 'components/progress/Score';

const Styling = ({ aid, cid, test, setRefreshing }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();
  const [isLoading, setIsLoading] = React.useState(false);
  const [candidate, setCandidate] = React.useState(null);
  const [answer, setAnswer] = React.useState(null);
  const [score, setScore] = React.useState(0);
  const [presetScore, setPresetScore] = React.useState(0);
  const [comment, setComment] = React.useState('');
  const [questions, setQuestions] = React.useState([]);
  const [cansubmit, setCanSubmit] = React.useState(false);

  React.useEffect(() => {
    if (!aid || !cid) return;

    executeAction(
      getCandidateInAssessment({
        aid,
        cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        const testId = new Array();

        test?.test?.map((t, i) => {
          if (doc?.results[t] !== undefined) {
            testId.push(doc?.results[t]);
          } else {
            return;
          }
        });

        setCandidate(doc);
        // console.log(doc);
        setAnswer(testId);
        setScore(doc?.results?.[test?.uuid || test?.id]?.score);
        setPresetScore(doc?.results?.[test?.uuid || test?.id]?.preset_score);
        setComment(doc?.results?.[test?.uuid || test?.id]?.comment);
      })
      .catch((e) => {
        console.dir(e);
        setCandidate(null);
        setAnswer(null);
      });
  }, [aid, cid]);

  React.useEffect(() => {
    const questionlist = new Array();
    const _score = {};
    const _comment = {};
    (test?.question || []).forEach((question) => {
      if (question.selected === true) {
        questionlist.push(question);
        _score[question.id] = candidate?.results?.[question.id]?.score || 0;
        _comment[question.id] =
          candidate?.results?.[question.id]?.comment || '';
      }
    });
    setQuestions(questionlist);
    setScore(_score);
    setComment(_comment);
    checkCanSubmit(_score);
  }, [test, candidate]);

  const onDismissHandler = () => {
    window.document.querySelector('body').style.position = 'relative';
    dismissModal();
  };

  const onSubmitHandler = () => {
    setIsLoading(true);
    executeAction(
      gradeResult({
        aid,
        cid,
        tuuid: test?.uuid || test?.id,
        score,
        comment,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setRefreshing(true);
        dismissModal();
      })
      .catch((e) => {
        console.dir(e);
        setIsLoading(false);
      });
    window.document.querySelector('body').style.position = 'relative';
  };

  const onSetCurrentScore = (tid, _score) => {
    let __score = score;
    __score[tid] = _score;
    // console.log(__score);
    // console.log(tid)
    setScore((__score) => ({ ...score, __score }));
    checkCanSubmit(__score);
    setComment((__comment) => ({ ...comment, __comment }));
    getCurrentLibraryAverageScore();
  };

  const getCurrentLibraryAverageScore = (question) => {
    let testcnt = 0;
    let _score = 0;
    question?.forEach((tmp) => {
      const result = score?.[tmp.id];
      _score += result || 0;
      testcnt += 1;
    });

    return testcnt !== 0 ? _score / testcnt.toFixed(2) : 0;
  };

  const getCurrentLibraryAveragePresetScore = (question) => {
    let testcnt = 0;
    let presetScore = 0;

    question?.forEach((tmp) => {
      const result = candidate?.results?.[tmp.id];

      if (result) {
        presetScore += result?.preset_score || 0;
        testcnt += 1;
      }
    });
    return testcnt !== 0 ? presetScore / testcnt.toFixed(2) : 0;
  };

  const checkCanSubmit = (_score) => {
    let submit = false;
    (questions || []).forEach((question) => {
      if (_score[question.id] !== undefined && _score[question.id] >= 0) {
        submit = true;
      } else {
        submit = false;
      }
    });
    setCanSubmit((submit) => ({ ...cansubmit, submit }));
  };

  return (
    <article className="modals-assessments-view-answer-styling">
      <header className="header">
        <h3 className="header-title fs20">{t('btn.view-answer')}</h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <div className="title-box">
        <div className="title fs28">{test?.category_skill}</div>
        <div className="left">
          <div
            className={`score fs16 ${getCurrentLibraryAverageScore(questions)}`}
          >
            <p className="fs16">{t('title.avarage-score')}</p>
            <div className="container">
              <Score
                score={getCurrentLibraryAverageScore(questions)}
                presetScore={getCurrentLibraryAveragePresetScore(questions)}
              />
            </div>
          </div>
          <button
            className={`submit common-button fsbtn16 ${
              candidate?.progress === 0 ||
              getCurrentLibraryAverageScore(questions) < 0.001
                ? 'disabled'
                : 'active'
            }`}
            onClick={!isLoading && cansubmit ? onSubmitHandler : null}
            disabled={
              !!(
                candidate?.progress === 0 ||
                getCurrentLibraryAverageScore(questions) < 0.001
              )
            }
          >
            {t('btn.save')}
            {/* {isLoading && <CircularProgress color="grey" size={25} />} */}
          </button>
        </div>
      </div>
      <div className="outer">
        {(questions || []).map((question, idx) => (
          <div className="form" key={idx}>
            <section className="left">
              <section className="question">
                <div className="question-number fs24">{`Q${idx + 1}`}</div>
                <h4 className="question-title fs18">{t('title.question')}</h4>
                <div className="question-description fs16">
                  {question?.question}
                </div>
              </section>
              <section className="answer">
                <h4 className="answer-title fs18">{t('title.answer')}</h4>
                <div className="answer-description">
                  <Sample style={{ width: '100%', height: 'auto' }} />
                </div>
              </section>
            </section>
            <section className="right">
              <section className="guide">
                <div className="title fs18">{t('title.scoring-guide')}</div>
                <div className="desc fs16">채점 기준을 입력할 수 있습니다.</div>
              </section>

              <section className="estimate">
                <h4 className="estimate-title fs18">
                  {t('title.rate-answer')}
                </h4>
                <div className="estimate-score">
                  <EstimateScore
                    score={score}
                    questionId={question?.id}
                    onClick={onSetCurrentScore}
                    presetScore={presetScore}
                  />
                </div>
                <div className="estimate-comment">
                  <TextareaAutosize
                    placeholder={t('title.comment')}
                    onChange={(e) => setComment(e.target.value)}
                    defaultValue={comment}
                    className="fs16"
                  />
                </div>
              </section>
            </section>
          </div>
        ))}
      </div>
    </article>
  );
};

export default Styling;
