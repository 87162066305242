import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as NoData } from 'assets/images/no-data-ai-interview.svg';
import { ReactComponent as Calendar } from 'assets/images/icon/calendar-illust-120x120.svg';
import { Timestamp } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { calculatePercentageAverage } from 'utils/array';
import { useLocation } from 'react-router-dom';
import { executeAction } from '../../../../utils/redux';
import { setCandidateOfRecruitment } from '../../../../store/slices/recruitments/recruitmentsSlice';
import { toast } from '../../../../utils/notify';
import InterviewScore from './InterviewScore';
import OpinionContainer from './OpinionContainer';
import SummaryContainer from './SummaryContainer';
import BasicSelect from '../../../select/BasicSelect';
import { dateToDateFormat } from '../../../../utils/string';

const PersonInterviewDetail = ({
  recruitment,
  currentCandidate,
  processName,
  processLength,
  selectedHistory,
  setSelectedHistory,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);
  const step = processName === 'firstInterview' ? 3 : 4;
  const { standardList } = recruitment;
  const [isSubmit, setIsSubmit] = useState(false);
  const isDone =
    currentCandidate.progressSubmit[step].interviewSchedule?.isDone || false;
  const completedSubmit =
    currentCandidate.progressSubmit[step]?.isSubmit || false;
  const [isRetry, setIsRetry] = useState(false);
  const [interviewHistory, setInterviewHistory] = useState([]);
  const [interviewHistoryList, setInterviewHistoryList] = useState([
    {
      key: 'ongoing-interview',
      i18nKey:
        (currentCandidate?.progressSubmit[step]?.interviewSchedule?.isDone &&
          currentCandidate?.progressSubmit[step]?.interviewSchedule
            ?.interviewDate <= new Date().getTime()) ||
        currentCandidate?.progressSubmit[step]?.isSubmit
          ? 'select.ongoing-interview'
          : 'select.before-proceeding-interview',
    },
  ]);

  const isInterviewer =
    currentCandidate.progressSubmit[step]?.interviewer?.email ===
    userInfo.email;
  const [opinion, setOpinion] = useState('');
  const [comment, setComment] = useState('');
  const [scoreList, setScoreList] = useState([]);
  const commentList = currentCandidate.progressSubmit[1].comment;

  useEffect(() => {
    if (selectedHistory.key === 'ongoing-interview') {
      if (currentCandidate.progressSubmit[step]?.scoreList)
        setScoreList(currentCandidate.progressSubmit[step]?.scoreList);
      else setScoreList([]);

      if (currentCandidate.progressSubmit[step]?.opinion)
        setOpinion(currentCandidate.progressSubmit[step].opinion);
      else setOpinion('');

      if (currentCandidate.progressSubmit[step]?.comment)
        setComment(currentCandidate.progressSubmit[step].comment);
      else setComment('');

      setIsSubmit(Boolean(currentCandidate.progressSubmit[step]?.comment));
    } else {
      const idx = selectedHistory.key;
      if (
        currentCandidate.progressSubmit[step]?.interviewHistory[idx]?.scoreList
      ) {
        setScoreList(
          currentCandidate.progressSubmit[step]?.interviewHistory[idx].scoreList
        );
      } else setScoreList([]);

      if (currentCandidate.progressSubmit[step]?.interviewHistory[idx].opinion)
        setOpinion(
          currentCandidate.progressSubmit[step].interviewHistory[idx].opinion
        );
      else setOpinion('');

      if (currentCandidate.progressSubmit[step]?.interviewHistory[idx].comment)
        setComment(
          currentCandidate.progressSubmit[step].interviewHistory[idx].comment
        );
      else setComment('');

      setIsSubmit(true);
    }
  }, [currentCandidate, selectedHistory]);

  useEffect(() => {
    setIsRetry(
      Boolean(currentCandidate.progressSubmit[step]?.interviewHistory)
    );
    setInterviewHistoryList([
      {
        key: 'ongoing-interview',
        i18nKey:
          (currentCandidate?.progressSubmit[step]?.interviewSchedule?.isDone &&
            currentCandidate?.progressSubmit[step]?.interviewSchedule
              ?.interviewDate <= new Date().getTime()) ||
          currentCandidate?.progressSubmit[step]?.isSubmit
            ? 'select.ongoing-interview'
            : 'select.before-proceeding-interview',
      },
    ]);
    setSelectedHistory({
      key: 'ongoing-interview',
      i18nKey:
        (currentCandidate?.progressSubmit[step]?.interviewSchedule?.isDone &&
          currentCandidate?.progressSubmit[step]?.interviewSchedule
            ?.interviewDate <= new Date().getTime()) ||
        currentCandidate?.progressSubmit[step]?.isSubmit
          ? 'select.ongoing-interview'
          : 'select.before-proceeding-interview',
    });

    if (currentCandidate.progressSubmit[step]?.interviewHistory) {
      setInterviewHistory(
        currentCandidate.progressSubmit[step].interviewHistory
      );
      currentCandidate.progressSubmit[step]?.interviewHistory.forEach(
        (item, idx) => {
          setInterviewHistoryList((prev) => [
            ...prev,
            {
              key: idx,
              i18nKey: `${dateToDateFormat(
                new Date(item.updatedAt),
                t('lang')
              )} ${t('desc.interview-history')}`,
            },
          ]);
        }
      );
    }
  }, [currentCandidate]);

  useEffect(() => {
    if (!scoreList?.length) return;

    if (calculatePercentageAverage(scoreList) <= 40) {
      return setOpinion(opinionList[3]?.key);
    }

    return setOpinion('');
  }, [scoreList]);

  const opinionList = [
    { i18nKey: 'tab.step.strong-hire', key: 'Strong' },
    { i18nKey: 'tab.step.kiv', key: 'Shortlist' },
    { i18nKey: 'tab.step.suggest-other-position', key: 'other' },
    { i18nKey: 'tab.step.reject', key: 'Reject' },
  ];

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const onSelectChangeHandler = (_, menu) => {
    setSelectedHistory(menu);
  };

  const onChangeOpinion = (e) => {
    if (!isInterviewer) return;

    const { textContent } = e.target;

    if (calculatePercentageAverage(scoreList) <= 40 && scoreList?.length) {
      if (textContent === '채용 추천' || textContent === 'Strong Hire') {
        return toast(t('toast.combined-score-is-less-than-forty'));
      }
    }

    const foundObject = opinionList.find((obj) => {
      return t(obj.i18nKey) === textContent;
    });

    if (foundObject) {
      const otherKeyValue = foundObject.key;
      setOpinion(otherKeyValue);
    }
  };

  const onSubmitHandler = () => {
    const { rid } = recruitment;
    const { email } = currentCandidate;
    const isEmptyScore = scoreList.some((item) => item === undefined);
    const endPoint = location?.pathname?.split('/')[4];

    const commentForm = {
      comment,
      writer: `${userInfo?.firstName} ${userInfo?.lastName}`,
      date: Timestamp.now(),
      isShow: true,
    };
    if (isEmptyScore || standardList?.length !== scoreList?.length) {
      toast(t('toast.please-complete-full-evaluation'));
      return;
    }
    if (opinion === '') {
      toast(t('toast.desc.no-opinion'));
      return;
    }
    if (comment === '') {
      toast(t('toast.desc.comments-have-written'));
      return;
    }

    if (scoreList.length === 0) {
      toast(t('toast.desc.no-score'));
      return;
    }
    if (step === 3) {
      executeAction(
        setCandidateOfRecruitment({
          aid: rid,
          cid: email,
          type: 'evaluation',
          candidateFirstName: currentCandidate?.firstName,
          candidateLastName: currentCandidate?.lastName,
          stage: endPoint,
          progressSubmit: {
            1: {
              comment: !commentList
                ? [commentForm]
                : [...commentList, commentForm],
            },
            3: {
              opinion,
              comment,
              scoreList,
              updatedAt: new Date().getTime(),
              isSubmit: true,
            },
          },
        })
      )
        .unwrap()
        .then(() => {
          toast(t('toast.desc.you-comment-submitted'));
          setIsSubmit(true);
        })
        .catch((e) => console.error(e));
    } else {
      executeAction(
        setCandidateOfRecruitment({
          aid: rid,
          cid: email,
          type: 'evaluation',
          candidateFirstName: currentCandidate?.firstName,
          candidateLastName: currentCandidate?.lastName,
          stage: endPoint,
          progressSubmit: {
            1: {
              comment: !commentList
                ? [commentForm]
                : [...commentList, commentForm],
            },
            4: {
              opinion,
              comment,
              scoreList,
              updatedAt: new Date().getTime(),
              isSubmit: true,
            },
          },
        })
      )
        .unwrap()
        .then(() => {
          toast(t('toast.desc.you-comment-submitted'));
          setIsSubmit(true);
        })
        .catch((e) => console.error(e));
    }
  };
  return (
    <div className="wrapper person-interview-detail">
      {isRetry && (
        <BasicSelect
          menus={interviewHistoryList}
          label={t('desc.interview-history')}
          name={t('desc.interview-history')}
          value={selectedHistory}
          onChange={onSelectChangeHandler}
        />
      )}
      {selectedHistory.key !== 'ongoing-interview' ||
      currentCandidate.progressSubmit[step]?.interviewSchedule?.isDone ? (
        <>
          {standardList?.map((item, idx) => {
            return (
              <InterviewScore
                key={`standard_${idx}`}
                idx={idx}
                standard={item.standard}
                standardDesc={item.standardDesc}
                setScore={
                  !isDone || isSubmit || completedSubmit || !isInterviewer
                    ? undefined
                    : setScoreList
                }
                scoreList={scoreList}
                score={scoreList[idx] || undefined}
              />
            );
          })}
          <div className="summary-title">{t('title.summary')}</div>
          <SummaryContainer
            comment={comment}
            scoreList={scoreList}
            onChangeHandler={onChangeHandler}
            isSubmit={!isDone || isSubmit || completedSubmit}
            isInterviewer={isInterviewer}
          />
          <OpinionContainer
            isInterviewer
            opinionList={opinionList}
            opinion={opinion}
            isSubmit={!isDone || isSubmit || completedSubmit}
            onChangeOpinion={onChangeOpinion}
          />
          {selectedHistory.key === 'ongoing-interview' &&
            (!isDone || !isSubmit) &&
            !completedSubmit &&
            isInterviewer && (
              <div className="submit-button">
                <button
                  className="common-button primary fs16 active"
                  onClick={onSubmitHandler}
                >
                  {t('btn.submit')}
                </button>
              </div>
            )}
        </>
      ) : Object.keys(currentCandidate.progressSubmit[step]?.interviewSchedule)
          .length === 0 ? (
        <div className="no-candidate-container">
          <div className="no-candidate-image-box">
            <NoData />
          </div>
          <div className="no-candidate-warning-box">
            <div className="no-candidate-warning-text">
              {t('desc.not-yet-interview-schedule')}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-candidate-container">
          <div className="no-candidate-image-box">
            <Calendar />
          </div>
          <div className="no-candidate-warning-box">
            <div className="no-candidate-warning-text">
              {t('desc.sent-interview-date')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonInterviewDetail;
