import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import { executeAction } from 'utils/redux';
import { deleteLibraryDoc } from 'store/slices/assessmentsSlice';
import { isSameLowerCaseString } from 'utils/string';

const ConfirmDelete = ({ lid, libraryDocs, setLibraryDocs }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();

  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmitHandler = () => {
    if (!lid) return;
    setIsLoading(true);

    const idx = (libraryDocs || []).findIndex((x) =>
      isSameLowerCaseString(x.id, lid)
    );
    if (idx < 0) return;

    executeAction(
      deleteLibraryDoc({
        lid,
      })
    )
      .unwrap()
      .then((result) => {
        setIsLoading(false);
        setLibraryDocs([
          ...libraryDocs.slice(0, idx),
          ...libraryDocs.slice(idx + 1, libraryDocs.length),
        ]);
        dismissModal({
          message: 'dismiss',
        });
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  return (
    <article className="modals-assessments-confirm-delete">
      <section className="title">
        <h3 className="title-main">{t('dialog.title.delete-question')}</h3>
      </section>
      <section className="buttons">
        <button
          className="common-button fsbtn16 cancel active"
          onClick={() => dismissModal()}
        >
          {t('btn.no')}
        </button>
        <button
          className="common-button fsbtn16 primary active"
          onClick={onSubmitHandler}
        >
          {t('btn.yes')}
        </button>
      </section>
    </article>
  );
};

export default ConfirmDelete;
