import React from 'react';
import { marked } from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/base16/dracula.css';

const SourceCode = ({ language, code, isSub }) => {
  const langCode = language?.toLowerCase();
  marked.setOptions({
    langPrefix: 'hljs language-',
    highlight(code) {
      return hljs.highlightAuto(code, [langCode]).value;
    },
  });
  const className = isSub ? 'codeBlock-sub' : 'codeBlock';

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: marked(code) }}
    />
  );
};

export default SourceCode;
