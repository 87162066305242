import ApplyAgreeContainer from 'components/apply/ApplyAgreeContainer';
import ApplyFormContainer from 'components/apply/ApplyFormContainer';
import React, { useCallback, useEffect, useState } from 'react';
import { useStatus } from 'utils/status';
import { Button } from 'components/common/Button';
import { executeAction } from 'utils/redux';
import { uploadBytes } from 'store/slices/uploadSlice';
import { setResume } from 'store/slices/profileSlice';
import { toast } from 'utils/notify';
import { useLocation, useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import {
  addRecruitmentCandidate,
  checkSupportCountWithin3Years,
  postInsertCandidateData,
} from 'store/slices/recruitments/recruitmentsSlice';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'utils/auth';
import ApplyFormCareerAcademic from 'components/apply/ApplyFormCareerAcademic';
import ApplyFormOther from 'components/apply/ApplyFormOther';

const Apply = ({ouid, rid, recruitment }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsEntireLoading } = useStatus();

  const [notAllow, setNotAllow] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [applyFile, setApplyFile] = useState([]);
  const [applyCV, setApplyCV] = useState({});
  const [linkIndex, setLinkIndex] = useState(0);
  const [channelPath, setChannelPath] = useState('');
  const [channelGucci, setChannelGucci] = React.useState([]);
  const [areaGucci, setAreaGucci] = React.useState([]);
  const [applyFileType, setApplyFileType] = useState('');
  const [checkedAgree, setCheckedAgree] = useState({
    privacy: true,
    term: false,
  });
  const [input, setInput] = useState({
    firstName: '',
    lastName: '',
    passportFirstName: '',
    passportLastName: '',
    visaType: '',
    nameOfEmployee: '',
    nameOfStore: '',
    searchFirm: '',
    school: '',
    sns: '',
    snsOther: '',
    jobCenter: '',
    jobCenterOther: '',
    email: '',
    country: '',
    countryCode: '',
    phoneNumber: '',
    channel: '',
    career: '',
    relatedCareer: '',
    companyName: '',
    nationalCurrency: '',
    salary: '',
    finalEducation: '',
    major: '',
    minor: '',
    language: '',
    languageLevel: '',
    linkedIn: '',
    etcLink1: '',
    etcLink2: '',
    etcLink3: '',
    isKorean: '',
    isWorkVisa: '',
    birth: '',
    location: '',
    gender: '',
    variableSalary: '',
    monetaryCompensation: '',
    otherChannel: '',
  });

  useEffect(() => {
    let flag = true;

    Object.keys(input).forEach((key) => {
      if (
        key === 'searchFirm' ||
        key === 'school' ||
        key === 'jobCenter' ||
        key === 'jobCenterOther' ||
        key === 'sns' ||
        key === 'snsOther' ||
        key === 'otherChannel' ||
        key === 'finalEducation' ||
        key === 'major' ||
        key === 'minor' ||
        key === 'language' ||
        key === 'languageLevel' ||
        key === 'linkedIn' ||
        key === 'etcLink1' ||
        key === 'etcLink2' ||
        key === 'etcLink3' ||
        key === 'nameOfEmployee' ||
        key === 'nameOfStore' ||
        key === 'passportFirstName' ||
        key === 'passportLastName' ||
        key === 'isWorkVisa' ||
        key === 'visaType' ||
        key === 'relatedCareer' ||
        key === 'companyName' ||
        key === 'nationalCurrency' ||
        key === 'salary' ||
        key === 'variableSalary' ||
        key === 'monetaryCompensation'
      )
        return;

      if (!input[key]) {
        flag = false;
      }

      if (key === 'email') {
        if (!validateEmail(input[key]) && input[key] !== '') {
          flag = false;
          setNotAllow(true);
        } else {
          setNotAllow(false);
        }
      }
    });

    if(Object.values(applyCV).length === 0) {
      flag = false;
    }

    if (!channelGucci.length || !areaGucci.length) {
      flag = false;
    }

    if (input.career.key !== 'none') {
      if (
        !input.relatedCareer ||
        !input.companyName ||
        !input.nationalCurrency ||
        !input.salary
      ) {
        flag = false;
      }
    }

    if (input.channel.key === 'sns') {
      if (!input.sns.key) {
        flag = false;
      }

      if (input.sns.key === 'other' && !input.snsOther) {
        flag = false;
      }

      setChannelPath(input.sns.key);
    } else if (input.channel.key === 'searchFirm') {
      if (!input.searchFirm.key) {
        flag = false;
      }
      setChannelPath(input.searchFirm.key);
    } else if (input.channel.key === 'school') {
      if (!input.school.key) {
        flag = false;
      }
      setChannelPath(input.school.key);
    } else if (input.channel.key === 'job') {
      if (!input.jobCenter.key) {
        flag = false;
      }

      if (input.jobCenter.key === 'others' && !input.jobCenterOther) {
        flag = false;
      }

      setChannelPath(input.jobCenter.key);
    } else if (input.channel.key === 'others') {
      if (!input.otherChannel) {
        flag = false;
      }
      setChannelPath(input.otherChannel);
    }

    if (!checkedAgree.privacy) {
      flag = false;
    }

    setCanSubmit(flag);
  }, [input, checkedAgree, channelGucci, areaGucci, applyCV]);

  useEffect(() => {
    const limitedBytes = 1024 * 1024 * 10;
    if(applyCV?.file?.size > limitedBytes) {
      toast('Please upload files no larger than 10 MB.');
      setApplyCV({});
    }
    applyFile?.map((file) => {
      if (file?.file?.size > limitedBytes) {
        toast('Please upload files no larger than 10 MB.');
        setApplyFile([]);
      }
    });
  }, [applyFile]);

  const onSubmitHandler = () => {
    setIsEntireLoading(true);

    const resumeArr = [];
    const applyResumeArr = applyFile.concat(applyCV);

    applyResumeArr?.forEach((file, idx) => {
      resumeArr.push(
        executeAction(
          uploadBytes({
            collection: 'gucciapply',
            category: 'resume',
            email: input.email,
            rid: recruitment?.rid,
            fileName: file.file.name,
            bytes: file.file,
          })
        )
          .unwrap()
          .then(({ downloadURL }) => {
            return {
              type: applyResumeArr[idx].key,
              url: downloadURL,
              name: applyResumeArr[idx].name,
            };
          })
          .catch((e) => {
            console.error(e);
          })
      );
    });

    Promise.all(resumeArr).then((resume) => {
      executeAction(
        setResume({
          '국가/지역': input.country.key || '',
          '여권 성': input.passportFirstName,
          '여권 이름': input.passportLastName,
          '추천인 이름': input.nameOfEmployee,
          비자: input.visaType,
          성: input.lastName,
          이름: input.firstName,
          이메일: input.email,
          국가번호: input.countryCode.key || '',
          휴대전화번호: input.phoneNumber,
          이력서: resume,
          성별: input.gender.key || '',
          지원경로: input.channel.key || '',
          추천매장: input.nameOfStore,
          직업센터: input.jobCenter.key || '',
          서치펌: input.searchFirm.key || '',
          추천학교: input.school.key || '',
          추천기타: input.otherChannel,
          SNS: input.sns.key || '',
          SNS기타: input.snsOther || '',
          개인정보동의: checkedAgree?.privacy,
          이용약관동의: checkedAgree?.term,
          경력: input.career.key || '',
          유관경력: input.relatedCareer.key || '',
          회사이름: input.companyName,
          국제통화: input.nationalCurrency.key || '',
          연봉: input.salary,
          기타변동급: input.variableSalary,
          금전보상: input.monetaryCompensation,
          최종학력: input.finalEducation.key || '',
          주전공: input.major.key || '',
          부전공: input.minor.key || '',
          언어: input.language.key || '',
          언어단계: input.languageLevel.key || '',
          링크드인: input.linkedIn,
          기타링크1: input.etcLink1,
          기타링크2: input.etcLink2,
          기타링크3: input.etcLink3,
          대한민국국민: input.isKorean.key || '',
          취업비자: input.isWorkVisa.key || '',
          생년월일: input.birth,
          지역: input.location.key || '',
          지원채널: channelGucci || [],
          지원지역: areaGucci || [],
        })
      )
        .unwrap()
        .then(() => {
          executeAction(
            addRecruitmentCandidate({
              uid: ouid,
              rid,
              email: input.email,
              firstName: input.firstName,
              lastName: input.lastName,
              lang: 'ko',
              job: recruitment?.recruitmentName,
              recruitProgress: 1,
              progressSubmit: {
                1: {
                  country: input.country.key || '',
                  countryCode: input.countryCode.key || '',
                  phoneNumber: input.phoneNumber,
                  resumeArr: resume,
                  channel: input.channel.key || '',
                  passportFirstName: input.passportFirstName,
                  passportLastName: input.passportLastName,
                  visaType: input.visaType,
                  nameOfEmployee: input.nameOfEmployee,
                  nameOfStore: input.nameOfStore,
                  jobCenter: input.jobCenter.key || '',
                  searchFirm: input.searchFirm.key || '',
                  school: input.school.key || '',
                  otherChannel: input.otherChannel,
                  sns: input.sns.key || '',
                  snsOther: input.snsOther || '',
                  privacy: checkedAgree?.privacy,
                  termOfUse: checkedAgree?.term,
                  career: input.career.key || '',
                  relatedCareer: input.relatedCareer.key || '',
                  companyName: input.companyName,
                  currency: input.nationalCurrency.key || '',
                  salary: input.salary,
                  variableSalary: input.variableSalary,
                  monetaryCompensation: input.monetaryCompensation,
                  finalEducation: input.finalEducation.key || '',
                  major: input.major.key || '',
                  minor: input.minor.key || '',
                  language: input.language.key || '',
                  languageLevel: input.languageLevel.key || '',
                  linkedIn: input.linkedIn,
                  etcLink1: input.etcLink1,
                  etcLink2: input.etcLink2,
                  etcLink3: input.etcLink3,
                  isKorean: input.isKorean.key || '',
                  isWorkVisa: input.isWorkVisa.key || '',
                  birth: input.birth,
                  location: input.location.key || '',
                  gender: input.gender.key || '',
                  applyChannel: channelGucci || [],
                  applyLocation: areaGucci || [],
                },
              },
            })
          )
            .unwrap()
            .then(() => {
              if (ouid === 'NutKIKbumXTu6piL12SodBLNsqQ2') {
                executeAction(
                  postInsertCandidateData({
                    uid: ouid,
                    aid: rid,
                    cid: input.email,
                    data: {
                      channel: input.channel.key || '',
                      channelPath,
                      recommender: input.nameOfEmployee || '',
                      recommenderStore: input.nameOfStore || '',
                      applyPosition: recruitment.recruitmentName || '',
                      applyField: recruitment.positionInfo.job[0] || '',
                      applyDate: `${Timestamp.now().seconds * 1000}` || '',
                      firstName: input.firstName || '',
                      lastName: input.lastName || '',
                      phone: input.phoneNumber || '',
                      email: input.email || '',
                      gender: input.gender.key || '',
                      birth: input.birth || '',
                      career: input.career.key || '',
                      finalCompany: input.companyName || '',
                      totalCareer: input.career.key || '',
                      finalEducation: input.finalEducation.key || '',
                      major: input.major.key || '',
                    },
                  })
                )
                  .then(() => {
                    executeAction(
                      checkSupportCountWithin3Years({
                        uid: ouid,
                        aid: rid,
                        cid: input.email,
                        email: input.email,
                        phone: input.phoneNumber,
                      })
                    )
                      .then(() => {
                        navigate('/applyCompleted');
                        setIsEntireLoading(false);
                      })
                      .catch((e) => {
                        console.dir(e);
                        setIsEntireLoading(false);
                      });
                  })
                  .catch((e) => {
                    console.dir(e);
                    setIsEntireLoading(false);
                  });
              } else {
                navigate('/applyCompleted');
                setIsEntireLoading(false);
              }
            })
            .catch((e) => {
              console.error(e);
              setIsEntireLoading(false);

              switch (e?.message) {
                case 'already_exists_email':
                  toast(t('This candidate has already been invited.'));
                  break;
                default:
                  break;
              }
            });
        })
        .catch((e) => {
          console.error(e);
        });
    });
  };

  const handleInput = useCallback((e, menu, type) => {
    if (type === 'select') {
      setInput((prevInput) => ({
        ...prevInput,
        [e]: menu,
      }));

      return;
    }

    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      const number = value.replace(/[^0-9]/g, '');

      setInput((prevInput) => ({
        ...prevInput,
        [name]: number,
      }));
      return;
    }

    if (
      name === 'salary' ||
      name === 'variableSalary' ||
      name === 'monetaryCompensation'
    ) {
      const number = value.replace(/[^0-9]/g, '');

      setInput((prevInput) => ({
        ...prevInput,
        [name]: Number(number).toLocaleString('ko-KR'),
      }));
      return;
    }

    if (name === 'birth') {
      const number = value.replace(/[^0-9]/g, '');
      const regexValue = number.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

      if (regexValue.length > 10) {
        return toast(t('toast.desc.10-characters'));
      }
      setInput((prevInput) => ({
        ...prevInput,
        [name]: regexValue,
      }));
      return;
    }

    setInput((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  }, []);

  const addLinkHandler = () => {
    if (linkIndex >= 2) {
      return toast('최대 3개까지 추가 할 수 있습니다.');
    }
    setLinkIndex((prev) => prev + 1);
  };

  const handleInputFile = useCallback((e) => {
    const { files, name } = e.target;

    if (files.length === 0) {
      return setApplyFile([]);
    }

    setApplyFile((prevInput) => [
      ...prevInput,
      { key: name, file: files[0], name: files[0]?.name },
    ]);
    setApplyFileType('');
  }, []);

  const handleInputCV = useCallback((e) => {
    const { files, name } = e.target;

    if (files.length === 0) {
      return setApplyCV({});
    }

    setApplyCV({ key: name, file: files[0], name: files[0]?.name });
  }, []);

  const handleFileType = useCallback((e, menu, type) => {
    if (type === 'select') {
      setApplyFileType(menu);
    }
  });

  return (
    <main id="pages-apply">
      <section className="apply-container">
        <article className="apply-title-wrapper">
          <h2 className="fs28">{t('title.apply-for-this-job')}</h2>
        </article>
        <article className="apply-recruitment-title">
          <h3 className="fs18">
            {recruitment && recruitment?.recruitmentName}
          </h3>
        </article>
        <article className="apply-title-wrapper">
          <h4 className="fs24">{t('title.applicant-information')}</h4>
        </article>
        <article className="apply-form-wrapper">
          <ApplyFormContainer
            input={input}
            handleInput={handleInput}
            notAllow={notAllow}
          />
        </article>
        <article className="apply-title-wrapper">
          <h4 className="fs24">{t('title.career-academic')}</h4>
        </article>
        <article className="apply-form-wrapper">
          <ApplyFormCareerAcademic
            applyCV={applyCV}
            setApplyCV={setApplyCV}
            handleInputCV={handleInputCV}
            applyFileType={applyFileType}
            handleFileType={handleFileType}
            applyFile={applyFile}
            setApplyFile={setApplyFile}
            input={input}
            handleInput={handleInput}
            handleInputFile={handleInputFile}
            linkIndex={linkIndex}
            addLinkHandler={addLinkHandler}
          />
        </article>
        <article className="apply-title-wrapper">
          <h4 className="fs24">{t('title.other')}</h4>
        </article>
        <article className="apply-form-wrapper">
          <ApplyFormOther
            input={input}
            handleInput={handleInput}
            channelGucci={channelGucci}
            areaGucci={areaGucci}
            setChannelGucci={setChannelGucci}
            setAreaGucci={setAreaGucci}
          />
        </article>
        <article className="apply-agree-wrapper">
          <ApplyAgreeContainer
            checkedAgree={checkedAgree}
            setCheckedAgree={setCheckedAgree}
          />
        </article>
        <article className="apply-submit-button">
          <Button
            label="Submit"
            theme="grey"
            size="48"
            fontSize="fs16"
            onClick={onSubmitHandler}
            isDisabled={!canSubmit}
          />
        </article>
      </section>
    </main>
  );
};

export default Apply;
