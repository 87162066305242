import React from 'react';
import { deleteRecruitmentCandidate,
} from 'store/slices/recruitments/recruitmentsSlice';
import { executeAction } from 'utils/redux';
import { useModal } from 'utils/modal';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { toast } from 'utils/notify';

export const DeleteCandidateButton = ({ rid, candidateEmail }) => {
  const {displayModal, dismissModal} = useModal();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const onClickMoveToPrevStepButton = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const eventHandler = (event) => {
      if (event.detail?.message === 'delete-candidate') {
        setIsLoading(true);
        executeAction(
          deleteRecruitmentCandidate({
            aid: rid,
            email: candidateEmail,
          })
        )
          .unwrap()
          .then(() => {
            toast(t('toast.delete-candidate'));
          })
          .catch((e) => {
            Sentry.captureException(e);
          }).finally(() => {
          setIsLoading(false);
        });
      }
      window.document.removeEventListener('dismissModal', eventHandler);
    };
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'common/AlertModal',
      {
        event: {
          message: 'delete-candidate',
        },
        title: {
          main: t('dialog.title.delete-candidate'),
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };


  React.useEffect(() => {
    if (isLoading){
      displayModal(
        'common/Loading',
        {},
        {
          frame: 'part',
          hasCloseButton: false,
        }
      );
    } else {
      dismissModal();
    }
  }, [isLoading]);

  return (
    <div className="recruitment-table-dropdown-list" onClick={onClickMoveToPrevStepButton}>{t('menu.delete-candidate')}</div>
  );
};