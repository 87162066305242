import { ReactComponent as CloseIcon } from 'assets/images/icon/close-modal.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'utils/notify';
import { useModal } from 'utils/modal';
import { executeAction } from 'utils/redux';
import { setMemberInfo } from 'store/slices/recruitments/membersSlice';

import BasicSelect from 'components/select/BasicSelect';
import { Button } from 'components/common/Button';

const UpdateMemberType = ({ menuItems, selectedUser }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();

  const [memberType, setMemberType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    if (memberType === 0) {
      return setCanSubmit(false);
    }

    setCanSubmit(true);
  }, [memberType]);

  const onSelectChangeHandler = (_, menu) => {
    setMemberType(menu);
  };

  const userMemberTypeUpdate = () => {
    setIsLoading(true);

    executeAction(
      setMemberInfo({
        uid: selectedUser?.id,
        memberType: memberType?.key,
      })
    )
      .unwrap()
      .then(() => {
        toast('권한 변경을 완료하였습니다.');
        setIsLoading(false);
        dismissModal();
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  return (
    <div className="modal-invited-member modal-update-member-type">
      <section className="invited-member-container">
        <article className="invited-header-wrapper">
          <h3 className="fs16">Update Member type</h3>
          <span onClick={() => dismissModal()}>
            <CloseIcon />
          </span>
        </article>
        <article className="invited-form-wrapper">
          <BasicSelect
            value={memberType}
            required
            label={'Member Type'}
            name={'memberType'}
            onChange={onSelectChangeHandler}
            menus={menuItems.membersType}
          />
        </article>
        <article className="invited-button">
          <Button
            theme={'grey'}
            size={56}
            label={'Submit'}
            isDisabled={!canSubmit && true}
            isLoading={isLoading}
            fontSize={'fs16'}
            onClick={!isLoading && canSubmit ? userMemberTypeUpdate : null}
          />
        </article>
      </section>
    </div>
  );
};

export default UpdateMemberType;
