import { toast as _toast } from 'react-toastify';
import variables from 'styles/include.scss';

let repeat = null;

export const toast = (message, options) => {
  options = options || {};
  const { type } = options;
  const { appBackgroundColor } = variables || {};

  if (type === 'error') {
    repeat && clearTimeout(repeat);
    const elem = window.document.getElementById('root');
    elem.style.animation = 'shake 0.5s cubic-bezier(.36,.07,.19,.97) both';
    elem.style.transform = 'translate3d(0, 0, 0)';
    elem.style.backfaceVisibility = 'hidden';
    elem.style.perspective = '1000px';

    repeat = setTimeout(() => {
      elem.style.animation = '';
      elem.style.transform = '';
      elem.style.backfaceVisibility = '';
      elem.style.perspective = '';

      repeat = null;
    }, 500);
  }
  return _toast(message);
};
