import React from 'react';

const CustomProgressChart = ({ score }) => {
  return (
    <div className="total-score-container">
      <div className="score-box">
        <div
          className="score-bar"
          style={{ width: score ? `${score}%` : '1%' }}
        />
      </div>
      <div className="score-percent">
        <span>
          {score || '0'}
          <i> %</i>
        </span>
      </div>
    </div>
  );
};

export default CustomProgressChart;
