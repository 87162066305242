import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function MaterialDateTimePicker({
  period,
  defaultDate,
  periodDateTime,
  setPeriodDateTime,
  disabled,
  withoutTime,
}) {
  const handleDateChange = (date) => {
    setPeriodDateTime(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        ampm={false}
        className={`timePicker ${period} width100`}
        onChange={(newValue) =>
          newValue ? handleDateChange(new Date(newValue.$d).getTime()) : undefined
        }
        value={
          periodDateTime
            ? dayjs(new Date(periodDateTime))
            : dayjs(new Date(defaultDate))
        }
        defaultValue={defaultDate && dayjs(new Date(defaultDate))}
        views={
          withoutTime ? ['year', 'day'] : ['year', 'day', 'hours', 'minutes']
        }
        format={`${withoutTime ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'}`}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}
