import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateToDateFormat } from 'utils/string';
import { getDaysElapsed } from 'utils/dateFormat';
import Tag from 'components/common/Tag';
import { CandidateDetailMoreMenu } from 'components/recruitment/detail/CandidateDetailMoreMenu';
import { candidateActionMenu } from 'constants/recruitments/candidateActionMenu';
import BodyTableRowSkeleton from '../BodyTableRowSkeleton';
import { useModal } from '../../../../utils/modal';
import { nameConversionLanguage } from '../../../../utils/converter';

const RecruitmentTableBody = ({ candidates, recruitment, step }) => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();

  const viewDetailModal = (candidate) => {
    displayModal(
      'recruitments/CandidateDetail',
      {
        candidates,
        candidate,
        recruitment,
        processName: 'offer',
      },
      { frame: 'full' }
    );
  };

  const convertStatus = (status, type) => {
    let name;
    let color;

    if (type === 'label') {
      switch (status) {
        case 'extended':
          name = 'Offer extended';
          break;
        case 'accept':
          name = 'Offer accepted';
          break;
        case 'reject':
          name = 'Offer declined';
          break;
        default:
          name = '';
          break;
      }

      return name;
    }

    if (type === 'color') {
      switch (status) {
        case 'extended':
          color = 'grey300';
          break;
        case 'accept':
          color = 'blue500';
          break;
        case 'reject':
          color = 'red500';
          break;
        default:
          color = '';
          break;
      }

      return color;
    }
  };

  return (
    <>
    <tbody>
      {candidates === null ? (
        <>
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
        </>
      ) : candidates?.length === 0 ? (
        <tr>
          <td>{t('recruitment.desc.empty-no-applicant')}</td>
        </tr>
      ) : (
        candidates?.map((candidate) => (
          <tr key={candidate?.email} onClick={() => viewDetailModal(candidate)}>
            <td>
              {nameConversionLanguage(
                candidate?.firstName,
                candidate?.lastName,
                t('lang')
              )}
            </td>
            <td>
              <Tag
                label={convertStatus(
                  candidate?.progressSubmit[step]?.status,
                  'label'
                )}
                color={convertStatus(
                  candidate?.progressSubmit[step]?.status,
                  'color'
                )}
                fontSize={'fs12'}
              />
            </td>
            <td>
              {candidate?.progressSubmit[step]?.submitedAt
                ? dateToDateFormat(
                    new Date(
                      candidate?.progressSubmit[step]?.submitedAt?.seconds *
                        1000
                    ),
                    t('lang')
                  )
                : '-'}
            </td>
            <td>
              {candidate.progressSubmit[step]?.submitedAt
                ? getDaysElapsed(
                    candidate.progressSubmit[step]?.submitedAt?.seconds * 1000
                  ) === 0
                  ? t('lead-time.today')
                  : `${getDaysElapsed(
                      candidate.progressSubmit[step]?.submitedAt?.seconds * 1000
                    )} ${t('lead-time.days')}`
                : '-'}
            </td>
            <td>
              <CandidateDetailMoreMenu
                candidate={candidate}
                rid={recruitment?.rid}
                menuList={[candidateActionMenu.deleteCandidate, candidateActionMenu.moveToPrevStep]}
              />
            </td>
          </tr>
        ))
      )}
    </tbody>
    </>
);
};

export default RecruitmentTableBody;
