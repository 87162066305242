import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'serialize-error';
import { _uploadBytes } from 'utils/firebase/upload';

const initialState = {};

export const uploadBytes = createAsyncThunk(
  'upload/uploadBytes',
  async (payload = {}, { rejectWithValue }) => {
    const { collection, category, email, fileName, bytes, rid } = payload;
    try {
      const downloadURL = await _uploadBytes({
        collection,
        category,
        rid,
        email,
        fileName,
        bytes,
      });

      return {
        downloadURL,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {},
  extraReducers: {},
});

export const {} = uploadSlice.actions;

export default uploadSlice.reducer;
