import React from 'react';
import { useTranslation } from 'react-i18next';

const OpinionContainer = ({
  isInterviewer,
  opinionList,
  opinion,
  isSubmit,
  onChangeOpinion,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`opinion-container ${!isInterviewer && 'disabled'}`}>
      {opinionList.map((item) => {
        return (
          <div
            key={item.i18nKey}
            onClick={!isSubmit ? (e) => onChangeOpinion(e) : undefined}
            className={opinion === item.key ? 'active' : ''}
          >
            {t(item.i18nKey)}
          </div>
        );
      })}
    </div>
  );
};

export default OpinionContainer;
