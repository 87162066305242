import React from 'react';
import { useTranslation } from 'react-i18next';
// import { useOutletContext } from 'react-router-dom';
import { getTestInfos } from 'store/slices/testsSlice';
import { useModal } from 'utils/modal';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Box,
  MenuItem,
  Select,
} from '@mui/material';
import EmptyTable from 'components/logo/EmptyTable';
import { ReactComponent as DeleteIcon } from 'assets/images/icon/close-icon-grey.svg';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';
import { sortCopy } from 'utils/array';
import { executeAction } from 'utils/redux';
import CategoryCard from 'components/card/CategoryCard';

const __TIME_LIMIT__ = 10;

const __MAX_QUESTION__ = 15;
const __MAX_BASIC_QUESTION__ = 6;
const __MAX_INTERMEDIATE_QUESTION__ = 5;
const __MAX_ADVANCED_QUESTION__ = 4;
const __GROUP_TEST_TIME__ = 10;

const getQuestions = (basics, intermediates, advanceds) => {
  var basicCnt = basics.length;
  var intermediateCnt = intermediates.length;
  var advancedCnt = advanceds.length;
  var ba = new Array();
  var it = new Array();
  var ad = new Array();
  var ret = new Array();

  if (basicCnt + intermediateCnt + advancedCnt <= __MAX_QUESTION__) {
    ret = shuffle([...basics, ...intermediates, ...advanceds]);
  } else if (
    basicCnt >= __MAX_BASIC_QUESTION__ &&
    intermediateCnt >= __MAX_INTERMEDIATE_QUESTION__ &&
    advancedCnt >= __MAX_ADVANCED_QUESTION__
  ) {
    ba = basics.slice(0, __MAX_BASIC_QUESTION__);
    it = intermediates.slice(0, __MAX_INTERMEDIATE_QUESTION__);
    ad = advanceds.slice(0, __MAX_ADVANCED_QUESTION__);
    ret = shuffle([...ba, ...it, ...ad]);
  } else if (
    intermediateCnt + advancedCnt <
    __MAX_QUESTION__ - __MAX_BASIC_QUESTION__
  ) {
    ba = basics.slice(0, __MAX_QUESTION__ - (intermediateCnt + advancedCnt));
    ret = shuffle([...ba, ...intermediates, ...advanceds]);
  } else {
    ba = basics.slice(0, __MAX_QUESTION__ / 2);
    var temp = shuffle([...intermediates, ...advanceds]).slice(
      0,
      __MAX_QUESTION__ / 2
    );
    ret = shuffle([...ba, ...temp]);
  }
  return ret;
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const Step1_1 = ({
  init,
  setInit,
  curStep,
  positionList,
  skillList: __skillList,
  position,
  objectiveTests,
  setObjectiveTests,
  testCategory,
  setTestCategory,
  groupTests,
  setGroupTests,
  setCanGoStep2,
  language,
}) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();
  const [isCardLoading, setIsCardLoading] = React.useState(false);
  const [curTab, setCurTab] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [filterTestType, setFilterTestType] = React.useState('');
  const [_skillList, _setSkillList] = React.useState([]);
  const [skillList, setSkillList] = React.useState([]);
  const [filterTestTypeList, setFilterTestTypeList] = React.useState([]);
  const [skillGroup, setSkillGroup] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState('');
  const [testlimit, setTestLimit] = React.useState(3);
  const [groupTestDuration, setGroupTestDruration] = React.useState(45);
  const [isDeleted, setIsDeleted] = React.useState(false);

  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const lang = language;

  React.useEffect(() => {
    if (isDeleted) {
      setGroupTests({});
      return;
    }
    let newFilterTestTypeList = [
      { key: t('tab.all'), display: { en: t('tab.all'), ko: t('tab.all') } },
    ];

    let skillListCopy = new Array();
    Object.values(__skillList || {}).forEach((skill) => {
      if (skill.group && skill.group.length > 0) {
        skillListCopy.push(skill);
        var filtertype = {
          key: skill.category,
          display: {
            en: skill.category,
            ko: skill.category,
          },
        };

        if (
          newFilterTestTypeList.filter((e) => e.key === filtertype.key)
            .length <= 0
        ) {
          newFilterTestTypeList.push({
            key: skill.category,
            display: {
              en: skill.category,
              ko: skill.category,
            },
          });
        }
      }
    });

    setFilterTestTypeList(newFilterTestTypeList);
    if (!filterTestType) {
      setFilterTestType(newFilterTestTypeList?.[1]?.key);
    }

    if (filterTestType) {
      skillListCopy = skillListCopy.filter(
        (x) => x.category === filterTestType
      );
    }

    skillListCopy = skillListCopy.filter((x) => {
      const convertedTitle = (x?.[`title_${t('en')}`] || '')
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      const convertedSearch = (search || '')
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      if (convertedTitle?.includes?.(convertedSearch)) {
        return true;
      }
      return false;
    });

    skillListCopy = sortCopy(skillListCopy, (a, b) => {
      const titleA = a?.[`title_${t('en')}`];
      const titleB = b?.[`title_${t('en')}`];
      if (titleA > titleB) return 1;
      if (titleA < titleB) return -1;
      if (titleA === titleB) return 0;
    });

    var skillgroup = [];

    const tasks = new Array();
    skillListCopy.forEach((skills, idx) => {
      if (skills.group === 'NA') return;
      if (!skillgroup[skills.group]) {
        skillgroup[skills.group] = new Array();
      }
      skills.limit = __GROUP_TEST_TIME__;
      skillgroup[skills.group].push(skills);
    });

    _setSkillList(skillListCopy);
    setSkillGroup(skillgroup);
    if (groupTests.group) {
      setSelectedGroup(groupTests.group);
      setTestLimit(groupTests.testcnt);
      setGroupTestDruration(groupTests.testcnt * __GROUP_TEST_TIME__);
      setCanGoStep2(true);
    }
  }, [__skillList, filterTestType, search, groupTests]);

  React.useEffect(() => {
    setPage(0);
  }, [search, filterTestType]);

  React.useEffect(() => {
    const startIdx = page * rowsPerPage;
    setSkillList(_skillList.slice(startIdx, startIdx + rowsPerPage));
  }, [_skillList, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelectedItem = (item) => !!(selectedGroup === item);

  React.useEffect(() => {
    setCurTab(filterTestType);
  }, [filterTestType]);

  const onTabClickHandler = (testtype) => {
    setFilterTestType(testtype?.key);
  };

  const onAddClickHandler1_1 = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    setCanGoStep2(true);

    var groups;
    if (skillGroup?.[item]?.length >= 3) {
      groups = 3;
    } else {
      groups = skillGroup?.[item]?.length;
    }
    setTestLimit(groups);
    setGroupTestDruration(groups * __GROUP_TEST_TIME__);
    const tasks = new Array();
    skillGroup?.[item].forEach((skills) => {
      skills.uuid = uuidV4();
      tasks.push(
        executeAction(
          getTestInfos({
            tids: skills?.test || [],
            lang: language,
          })
        )
          .unwrap()
          .then(({ basics, intermediates, advanceds }) => {
            var question = getQuestions(basics, intermediates, advanceds);
            skills.question = question;
          })
          .catch((e) => {
            console.dir(e);
          })
      );
    });
    Promise.allSettled(tasks)
      .then(() => {
        setGroupTests({
          group: item,
          testcnt: groups,
          skills: skillGroup?.[item],
        });
      })
      .catch((e) => {
        console.dir(e);
        setGroupTests({});
      });
    setObjectiveTests([]);
    setSelectedGroup(item);
  };

  const onDeleteClickHandler1_1 = (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    groupTests = {};
    setGroupTests({});
    setSelectedGroup('');
    setTestLimit(0);
    setGroupTestDruration(0);
    setIsDeleted(true);
    setCanGoStep2(false);
  };
  const onChageMaxQuestionHandler = (e) => {
    const tests = parseInt(e.target.value);
    setTestLimit(tests);
    setGroupTestDruration(tests * __GROUP_TEST_TIME__);
    setGroupTests({
      group: selectedGroup,
      testcnt: tests,
      skills: skillGroup?.[selectedGroup],
    });
  };

  return (
    <main className="pages-protected-assessments-create-step-2">
      <section className="guide">
        <p>{t('select-test-category.desc.select-test')}</p>
      </section>
      <section className="result">
        <Box>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="70%" className="table-type thead">
                      <span>{t('column.test-name')}</span>
                    </TableCell>
                    <TableCell width="10%" className="table-question thead">
                      <span>{t('column.test-select-count')}</span>
                    </TableCell>
                    <TableCell width="10%" className="table-question thead">
                      <span>{t('column.duration')}</span>
                    </TableCell>
                    <TableCell width="10%" className="table-duration thead">
                      <span />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedGroup.length > 0 ? (
                    <TableRow>
                      <TableCell className="tbody">
                        <div className="fs16">{selectedGroup}</div>
                      </TableCell>
                      <TableCell className="tbody">
                        <div>
                          <Select
                            className="form-question-select"
                            value={testlimit}
                            onChange={onChageMaxQuestionHandler}
                            displayEmpty
                            inputProps={{ 'aria-label': 'maxQuestion' }}
                          >
                            {Array.from(
                              new Set(
                                (skillGroup?.[selectedGroup] || []).map(
                                  (skill, idx) => {
                                    const detailTest = idx + 1;
                                    return detailTest;
                                  }
                                )
                              )
                            )
                              .sort((a, b) => b - a)
                              .map((item, key) => (
                                <MenuItem value={item} key={key}>
                                  {item}
                                </MenuItem>
                              ))}
                          </Select>
                        </div>
                      </TableCell>
                      <TableCell className="tbody duration">
                        <div className="duration-icon fs16">
                          {groupTestDuration}
                          {t('desc.minute')}
                        </div>
                      </TableCell>
                      <TableCell className="tbody">
                        <DeleteIcon
                          onClick={(e) =>
                            onDeleteClickHandler1_1(e, selectedGroup)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} className="empty-test">
                        <div className="container empty">
                          {t('select-test.desc.no-test')}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </section>
      <section className="form">
        <section className="form-tabs">
          <ul className="form-tabs-list">
            {(filterTestTypeList || []).map((testtype, idx) => (
              <li
                key={idx}
                className={`form-tabs-list-item ${
                  curTab === testtype?.key && 'selected'
                } ${idx === 0 && 'disabled'}`}
              >
                <div
                  className="container"
                  onClick={() => {
                    idx > 0 ? onTabClickHandler(testtype) : null;
                  }}
                >
                  <span className="fs18">{testtype?.display?.[t('en')]}</span>
                </div>
              </li>
            ))}
          </ul>
        </section>
        <section className="form-tests">
          <Box sx={{ width: '100%' }}>
            {isCardLoading || (Object.keys(skillGroup) || []).length > 0 ? (
              <ul className="form-tests-list">
                {(Object.keys(skillGroup) || []).map((group, idx) => (
                  <li key={idx} className="form-tests-list-item">
                    <CategoryCard
                      isCardLoading={isCardLoading}
                      title={group}
                      contains={Array.from(
                        new Set(
                          (skillGroup?.[group] || []).map((skill) => {
                            const detailTest = skill?.title_en;

                            return detailTest;
                          })
                        )
                      )}
                      button={
                        <button
                          onClick={
                            isSelectedItem(group)
                              ? (e) => {
                                  onDeleteClickHandler1_1(e, group);
                                }
                              : (e) => onAddClickHandler1_1(e, group)
                          }
                          className={`fsbtn16 blue ${
                            isSelectedItem(group) && 'primary selected white'
                          } ${
                            selectedGroup.length > 0 &&
                            !isSelectedItem(group) &&
                            'disabled'
                          }
                                                        active`}
                        >
                          <span>
                            {isSelectedItem(group)
                              ? t('btn.delete')
                              : t('btn.add')}
                          </span>
                        </button>
                      }
                      key={idx}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <section className="empty-table">
                <EmptyTable />
              </section>
            )}
          </Box>
        </section>
      </section>
    </main>
  );
};

Step1_1.propTypes = {
  init: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setInit: PropTypes.func,
  curStep: PropTypes.number,
  position: PropTypes.object,
  objectiveTests: PropTypes.array,
  setObjectiveTests: PropTypes.func,
};

export default Step1_1;
