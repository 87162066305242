import ApplicantInformation from 'components/recruitment/candidates/ApplicantInformation';
import FinalResults from 'components/recruitment/candidates/FinalResults';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameConversionLanguage } from 'utils/converter';
import { useModal } from 'utils/modal';

const CandidateListDetail = ({ candidates, candidate }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();
  const [currentCandidate, setCurrentCandidate] = useState(candidate);
  const [currentCandidateIdx, setCurrentCandidateIdx] = useState(
    candidates.data.indexOf(candidate)
  );
  const [prevCandidate, setPrevCandidate] = useState();
  const [nextCandidate, setNextCandidate] = useState();
  const [currentCandidateCount, setCurrentCandidateCount] = useState(0);

  useEffect(() => {
    if (candidates.data.length === 0) return;
    if (currentCandidateIdx >= 0) {
      if (candidates.data[currentCandidateIdx] === undefined) {
        setCurrentCandidateIdx(currentCandidateIdx - 1);
        setCurrentCandidate(candidates.data[currentCandidateIdx - 1]);
      } else if (candidates.data[currentCandidateIdx] === 'end') {
        setCurrentCandidate(undefined);
      } else {
        setCurrentCandidate(candidates.data[currentCandidateIdx]);
      }
    }
  }, [currentCandidateIdx]);

  useEffect(() => {
    setPrevCandidate(
      candidates.data[candidates.data?.indexOf(currentCandidate) - 1]
    );
    setNextCandidate(
      candidates.data[candidates.data?.indexOf(currentCandidate) + 1]
    );

    const findEmail = candidates.count.find(
      (item) => item.email === currentCandidate.email
    );
    setCurrentCandidateCount(findEmail.count);
  }, [currentCandidate]);

  const onShowNextCandidate = () => {
    setCurrentCandidate(nextCandidate);
    setCurrentCandidateIdx((prev) => prev + 1);
  };

  const onShowPrevCandidate = () => {
    setCurrentCandidate(prevCandidate);
    setCurrentCandidateIdx((prev) => prev - 1);
  };

  return (
    <article className="modal-candidate-list-detail">
      <header className="header">
        <div className="header-wrapper">
          {currentCandidate && (
            <div
              className={`header-sub-title-left ${
                !prevCandidate && 'null'
              } fs14`}
              onClick={onShowPrevCandidate}
            >
              {prevCandidate
                ? nameConversionLanguage(
                    prevCandidate.firstName,
                    prevCandidate.lastName,
                    t('lang')
                  )
                : ' '}
            </div>
          )}
          {currentCandidate && (
            <h2 className="header-title fs28">
              {nameConversionLanguage(
                currentCandidate?.firstName,
                currentCandidate?.lastName,
                t('lang')
              )}
            </h2>
          )}
          {currentCandidate && (
            <div
              className={`header-sub-title-right ${
                !nextCandidate && 'null'
              } fs14`}
              onClick={onShowNextCandidate}
            >
              {nextCandidate
                ? nameConversionLanguage(
                    nextCandidate.firstName,
                    nextCandidate.lastName,
                    t('lang')
                  )
                : ' '}
            </div>
          )}
          <div className="header-close" onClick={() => dismissModal()} />
        </div>
      </header>
      <div className="container">
        <div className="wrapper candidate-list-detail">
          <section className="tagging">
            <p>
              {t('title.apply-count')}: {currentCandidateCount || 0}
            </p>
          </section>
          <section className="information-container">
            <article className="information-wrapper">
              <h3 className="main-title">{t('title.applicant-information')}</h3>
              <ApplicantInformation t={t} user={currentCandidate} />
            </article>
            <article className="information-wrapper">
              <h3 className="main-title">{t('title.final-result')}</h3>
              <FinalResults t={t} user={currentCandidate} />
            </article>
          </section>
        </div>
      </div>
    </article>
  );
};

export default CandidateListDetail;
