import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'utils/auth';
import { useModal } from 'utils/modal';
import { useStatus } from 'utils/status';
import MaterialInput from 'components/input/MaterialInput';
import MaterialSelect from 'components/select/MaterialSelect';
import { supportedLangs } from 'i18n/index';
import { executeAction } from 'utils/redux';
import { setLang as setLangReducer } from 'store/slices/variableSlice';
import { CircularProgress } from '@mui/material';
import { toast } from 'utils/notify';

const Account = () => {
  const { t } = useTranslation();
  const { user, userInfo, updateAccountInfo } = useAuth();
  const { displayModal } = useModal();
  const { setIsEntireLoading } = useStatus();

  const [canDone, setCanDone] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [lang, setLang] = React.useState('');

  const initFirstName = React.useRef(firstName);
  const initLastName = React.useRef(lastName);
  const initLang = React.useRef(lang);

  React.useEffect(() => {
    if (
      firstName !== initFirstName.current ||
      lastName !== initLastName.current ||
      lang !== initLang.current
    ) {
      return setCanDone(true);
    }
    setCanDone(false);
  }, [
    firstName,
    lastName,
    lang,
    initFirstName.current,
    initLastName.current,
    initLang.current,
  ]);

  React.useEffect(() => {
    if (!userInfo) {
      setIsEntireLoading(true);
      return;
    }
    setFirstName(userInfo?.firstName || '');
    initFirstName.current = userInfo?.firstName || '';
    setLastName(userInfo?.lastName || '');
    initLastName.current = userInfo?.lastName || '';
    setEmail(user?.email);
    const curLang =
      window.localStorage.getItem('i18nextLng')?.slice?.(0, 2) ||
      window.sessionStorage.getItem('i18nextLng')?.slice?.(0, 2) ||
      '';
    setLang(curLang);
    initLang.current = curLang;

    setIsEntireLoading(false);
  }, [userInfo]);

  const onChangePasswordHandler = () => {
    displayModal(
      'profile/ChangePassword',
      {},
      {
        frame: 'integration',
      }
    );
  };

  const onSubmitHandler = () => {
    setIsLoading(true);
    executeAction(setLangReducer(lang));
    updateAccountInfo({
      firstName,
      lastName,
    })
      .then(() => {
        setIsLoading(false);
        toast(t('toast.desc.save-complete'));
        initFirstName.current = firstName || '';
        initLastName.current = lastName || '';
        initLang.current = lang || '';
      })
      .catch((e) => {
        console.dir(e);
        setIsLoading(false);
      });
  };

  return (
    <section className="pages-protected-profile-account">
      <section className="row">
        <MaterialInput
          placeholder={t('text-field.label.first-name')}
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
        />
        <MaterialInput
          placeholder={t('text-field.label.last-name')}
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />
      </section>
      <section className="row">
        <MaterialInput
          placeholder={t('text-field.label.job-email')}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          disabled
        />
        <MaterialSelect
          title={t('text-field.label.language')}
          value={lang}
          onChange={(item) => setLang(item.key)}
          items={supportedLangs}
        />
      </section>
      <button
        className="change-password common-button fsbtn16 gray-light active"
        onClick={onChangePasswordHandler}
      >
        {t('btn.change-password')}
      </button>
      <button
        className={`submit common-button fsbtn16 primary ${
          !isLoading && canDone && 'active'
        } ${isLoading && 'pending'}`}
        onClick={!isLoading && canDone ? onSubmitHandler : null}
      >
        {t('btn.save-change')}
        {isLoading && <CircularProgress color="grey" size={25} />}
      </button>
    </section>
  );
};

export default Account;
