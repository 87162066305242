import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import RecruitmentLeftContents from './recruitmentLayout/RecruitmentLeftContents';
import RecruitmentRightContents from './recruitmentLayout/RecruitmentRightContents';

const RecruitmentContainer = ({ assessments }) => {
  const { getRecruitmentsSuccess } = useSelector((state) => state.recruitments);

  const [filteredRecruitments, setFilteredRecruitments] = useState();
  const [currentTab, setCurrentTab] = useState(
    window.sessionStorage.getItem('recruitment_cur_tab') || 'current'
  );

  useEffect(() => {
    window.sessionStorage.setItem('recruitment_cur_tab', currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (!getRecruitmentsSuccess) return;
    let newRecruitments;

    if (currentTab === 'all') {
      newRecruitments = getRecruitmentsSuccess;
    } else if (currentTab === 'current') {
      const filtered = getRecruitmentsSuccess.filter(
        (item) => !item.isRecruitmentArchived
      );
      newRecruitments = filtered;
    } else {
      const filtered = getRecruitmentsSuccess.filter(
        (item) => item.isRecruitmentArchived
      );
      newRecruitments = filtered;
    }

    setFilteredRecruitments(newRecruitments);
  }, [currentTab, getRecruitmentsSuccess]);

  return (
    <main id="recruitment">
      <section className="recruitment-container">
        <RecruitmentLeftContents
          assessments={assessments}
          filteredRecruitments={filteredRecruitments}
        />
        <RecruitmentRightContents
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          filteredRecruitments={filteredRecruitments}
        />
      </section>
    </main>
  );
};

export default RecruitmentContainer;
