import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const ApplyAgreeRadio = ({ value, onChange, label, name, required }) => {
  return (
    <div className="apply-agree-radio-wrapper">
      <label className="input-label" htmlFor={name}>
        {label || ''} {required && <i>*</i>}
      </label>
      <div className="apply-agree-radio">
        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            name={name}
            value={value}
            onChange={onChange}
            sx={{ flexDirection: 'row' }}
          >
            <FormControlLabel
              sx={{ flex: '1' }}
              value="yes"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              sx={{ flex: '1' }}
              value="no"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default ApplyAgreeRadio;
