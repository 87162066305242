import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'assets/images/icon/search.svg';
import { ReactComponent as UpIcon } from 'assets/images/icon/north.svg';
import { ReactComponent as DownIcon } from 'assets/images/icon/south.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/icon/close_24px.svg';
import MaterialInput from 'components/input/MaterialInput';
import { useModal } from 'utils/modal';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EmptyTable from 'components/logo/EmptyTable';
import { isSameLowerCaseString } from 'utils/string';
import { toast } from 'utils/notify';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';
import { removeNull, sortCopy } from 'utils/array';
import SkillCard from 'components/card/SkillCard';
import { getLibTests } from 'store/slices/assessmentsSlice';
import { getTests } from 'store/slices/testsSlice';
import { executeAction } from 'utils/redux';
import { useAuth } from 'utils/auth';

const __TIME_LIMIT__ = 8;

const Step1 = ({
                 init,
                 setInit,
                 curStep,
                 positionList,
                 skillList: __skillList,
                 position,
                 objectiveTests,
                 setObjectiveTests,
                 setGroupTests,
                 language,
                 plan,
                 enabledSkillList,
               }) => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const { displayModal } = useModal();
  const [isCardLoading, setIsCardLoading] = React.useState(false);
  const [curTab, setCurTab] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [filterTestType, setFilterTestType] = React.useState('');
  const [_skillList, _setSkillList] = React.useState([]);
  const [skillList, setSkillList] = React.useState([]);
  const [searchedSkillList, setSearchedSkillList] = React.useState([]);
  const [filterTestTypeList, setFilterTestTypeList] = React.useState([]);
  const [detailTests, setDetailTests] = React.useState({});
  const [deflibraryDocs, setDefLibraryDocs] = React.useState([]);
  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const lang = language;

  React.useEffect(() => {
    setIsCardLoading(true);
    executeAction(getTests(lang))
      .unwrap()
      .then(({ docs }) => {
        const newDetailTests = {};
        Object.values(docs || {}).forEach((doc) => {
          newDetailTests[doc.id] = doc;
        });
        executeAction(getLibTests(lang))
          .unwrap()
          .then(({ docs }) => {
            Object.values(docs || {}).forEach((doc) => {
              newDetailTests[doc.id] = doc;
            });
            setDetailTests(newDetailTests || {});
          })
          .catch((e) => {
            console.dir(e);
          });
        setIsCardLoading(false);
      })
      .catch((e) => {
        console.dir(e);
        setDetailTests({});
        setIsCardLoading(false);
      });
    setGroupTests({});
  }, []);

  React.useEffect(() => {
    const tmpnewTestTypeList = Array.from(
      new Set(Object.values(__skillList || {}).map((x) => x.category)),
    ).map((x) => ({
      key: x,
      display: {
        en: x,
        ko: x,
      },
    }));
    const newFilterTestTypeList = removeNull([
      { key: t('tab.all'), display: { en: t('tab.all'), ko: t('tab.all') } },
      tmpnewTestTypeList.find((x) => x.key === '인적성검사'),
      ...tmpnewTestTypeList.filter((x) => x.key !== '인적성검사'),
    ]);

    /**---------------------------------------
     * 스타일링 숨김 분기처리
     * plan 값이 100 이하 일 때, 스타일링 탭버튼 삭제
     ----------------------------------------*/
    if (userInfo && (userInfo?.plan < 100 || userInfo?.plan === undefined)) {
      newFilterTestTypeList.map((list, idx) => {
        if (list.key === 'Custom') {
          newFilterTestTypeList.splice(idx, 1);
        }
      });
    }

    setFilterTestTypeList(newFilterTestTypeList);

    if (!filterTestType) {
      setFilterTestType(newFilterTestTypeList?.[0]?.key);
    }

    let skillListCopy = Object.values(__skillList || {}).slice(0);

    if (Boolean(filterTestType) && filterTestType !== t('tab.all')) {
      skillListCopy = skillListCopy.filter(
        (x) => x.category === filterTestType,
      );
    }

    if (userInfo && (userInfo?.plan < 100 || userInfo?.plan === undefined)) {
      skillListCopy = skillListCopy.filter(
        (value) => value?.category !== 'Custom',
      );
    }

    skillListCopy = skillListCopy.filter((x) => {
      const convertedTitle = (x?.[`title_${t('en')}`] || '')
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      const convertedSearch = (search || '')
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      if (convertedTitle?.includes?.(convertedSearch)) {
        return true;
      }
      return false;
    });

    skillListCopy = sortCopy(skillListCopy, (a, b) => {
      const titleA = a?.[`title_${t('lang')}`] || a?.title;
      const titleB = b?.[`title_${t('lang')}`] || b?.title;
      if (titleA > titleB) return 1;
      if (titleA < titleB) return -1;
      if (titleA === titleB) return 0;
    });

    const newSkillList = removeNull([
      skillListCopy.find((x) => x.id === '1321'),
      skillListCopy.find((x) => x.id === '1322'),
      ...skillListCopy.filter((x) => x.id !== '1321' && x.id !== '1322'),
    ]);

    _setSkillList(newSkillList);
  }, [__skillList, filterTestType, search]);

  React.useEffect(() => {
    if (init?.step1) return;
    if (Array.isArray(objectiveTests) && objectiveTests.length > 0) {
    } else {
      const initialObjectiveTests = [];
      (position?.skill || []).forEach((it) => {
        const finded = Object.values(skillList || {}).find((ot) =>
          isSameLowerCaseString(ot.id, it),
        );
        finded.limit = finded.duration || __TIME_LIMIT__;
        if (finded)
          initialObjectiveTests.push({
            uuid: uuidV4(),
            ...finded,
          });
      });
      setObjectiveTests(initialObjectiveTests);
    }
    setInit({
      ...init,
      step1: true,
    });
  }, [position]);

  React.useEffect(() => {
    setPage(0);
  }, [search, filterTestType]);

  React.useEffect(() => {
    const startIdx = page * rowsPerPage;
    setSkillList(_skillList.slice(startIdx, startIdx + rowsPerPage));
  }, [_skillList, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelectedItem = (item) =>
    !!(objectiveTests || []).find((it) => it.id === item.id);

  React.useEffect(() => {
    setCurTab(filterTestType);
  }, [filterTestType]);

  const onTabClickHandler = (testtype) => {
    setFilterTestType(testtype?.key);
  };

  const onAddClickHandler = (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    const finded = objectiveTests.find((it) => it.id === item.id);
    if (finded) {
      return;
    }
    if (objectiveTests.length >= 5) {
      toast(t('toast.desc.only-five-tests'));
      return;
    }
    item.limit = item.duration || __TIME_LIMIT__;
    // if(item?.title_en?.indexOf('OBPI') >= 0) {
    // if(item?.title_en?.indexOf('인성/심리검사') >= 0) {
    // tid가 1310이고 lang가 ko일 때만 OBPI이다.
    // tid가 6010이면 영문 독해이다.

    if (
      (item?.id === '1310' && lang === 'ko') ||
      (item?.id === '1321' && lang === 'ko') ||
      (item?.id === '1322' && lang === 'ko')
    ) {
      item.limit = 30;
    }

    if (item?.id === '6010' || item?.id === '6011' || item?.id === '6012') {
      // 영어, 중국어, 일본어 독해 레벨 테스트 limit 지정
      item.limit = 15;
    }
    toast(t('toast.desc.add-test'));
    setObjectiveTests([
      ...objectiveTests,
      {
        uuid: uuidV4(),
        ...item,
      },
    ]);
  };

  const onDeleteClickHandler = (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    const idx = (objectiveTests || []).findIndex((it) => it.id === item.id);
    setObjectiveTests([
      ...objectiveTests.slice(0, idx),
      ...objectiveTests.slice(idx + 1, objectiveTests.length),
    ]);
  };

  const onMoveUpClickHandler = (event, idx) => {
    event.preventDefault();
    event.stopPropagation();
    setObjectiveTests([
      ...objectiveTests.slice(0, idx - 1),
      objectiveTests[idx],
      objectiveTests[idx - 1],
      ...objectiveTests.slice(idx + 1, objectiveTests.length),
    ]);
  };

  const onMoveDownClickHandler = (event, idx) => {
    event.preventDefault();
    event.stopPropagation();
    setObjectiveTests([
      ...objectiveTests.slice(0, idx),
      objectiveTests[idx + 1],
      objectiveTests[idx],
      ...objectiveTests.slice(idx + 2, objectiveTests.length),
    ]);
  };

  const getTestDuration = (test) => {
    if (test?.group?.length > 0 && test?.testcnt > 0) {
      return test.testcnt * __GROUP_TEST_TIME__ + t('desc.minute');
    }
    if (typeof test?.limit === 'number') {
      return test?.limit + t('desc.minute');
    }
    if (test?.limit && typeof test?.limit === 'string') {
      return t(`text-field.input.${test?.limit}`);
    }
    if (test?.duration) {
      return test?.duration + t('desc.minute');
    }
    return t('15mins');
  };

  return (
    <main className="pages-protected-assessments-create-step-2">
      <section className="guide">
        <p>{t('select-test.desc.add-test')}</p>
        <Box>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      // width="65%"
                      className="table-type thead"
                    >
                      <span>{t('column.test')}</span>
                    </TableCell>
                    <TableCell width="15%" className="table-question thead">
                      <span>{t('column.type')}</span>
                    </TableCell>
                    <TableCell width="10%" className="table-duration thead">
                      <span>{t('column.duration')}</span>
                    </TableCell>
                    <TableCell
                      align="right"
                      className="table-more thead"
                      width="160px"
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(objectiveTests || []).length ? (
                    (objectiveTests || []).map((test, idx) => (
                      <TableRow key={idx} hover>
                        <TableCell
                          className="tablecell"
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          {test?.[`title_${t('en')}`] || test?.category_skill}
                        </TableCell>
                        <TableCell className="tablecell">
                          <div className="types">
                            {(
                              test?.question_type
                                .replaceAll(' ', '')
                                .split(',') || []
                            ).map((type, idx) => (
                              <span
                                className={`skill-type fs12 ${type.replaceAll(
                                  '.',
                                  '_',
                                )}`}
                                key={idx}
                              >
                                {t(type)}
                              </span>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell className="duration tablecell">
                          <div className="duration-icon" />
                          <span>{getTestDuration(test)}</span>
                        </TableCell>
                        <TableCell align="right" className="tablecell">
                          <div className="buttons">
                            <button
                              className={`down ${
                                idx === (objectiveTests || []).length - 1 &&
                                'disabled'
                              }`}
                              onClick={(e) => onMoveDownClickHandler(e, idx)}
                            >
                              <DownIcon />
                            </button>
                            <button
                              className={`up ${idx === 0 && 'disabled'}`}
                              onClick={(e) => onMoveUpClickHandler(e, idx)}
                            >
                              <UpIcon />
                            </button>
                            <button
                              className="delete"
                              onClick={(e) => onDeleteClickHandler(e, test)}
                            >
                              <RemoveIcon />
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} className="empty-test">
                        <div className="container empty">
                          {t('select-test.desc.no-test')}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </section>
      <section className="header">
        <header className="form-header">
          <article className="form-header-search">
            <MaterialInput
              placeholder={t('text-field.label.test-search')}
              icon={<SearchIcon />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </article>
          <article className="form-header-type" />
        </header>
      </section>
      <section className="form">
        <section className="form-tabs">
          <ul className="form-tabs-list">
            {(filterTestTypeList || []).map((testtype, idx) => (
              <li
                key={idx}
                className={`form-tabs-list-item ${
                  curTab === testtype?.key && 'selected'
                }`}
              >
                <div
                  className="container"
                  onClick={() => onTabClickHandler(testtype)}
                >
                  <span className="fs16">{testtype?.display?.[t('en')]}</span>
                </div>
              </li>
            ))}
          </ul>
        </section>
        <section className="form-tests">
          <Box sx={{ width: '100%' }}>
            {isCardLoading || (skillList || []).length > 0 ? (
              <ul className="form-tests-list">
                {(_skillList || []).map((skill, idx) => (
                  <li key={idx} className="form-tests-list-item">
                    <SkillCard
                      isCardLoading={isCardLoading}
                      title={skill?.[`title_${t('en')}`]}
                      contains={Array.from(
                        new Set(
                          (skill?.test || []).map((it) => {
                            const detailTest = detailTests?.[it];
                            return detailTest?.[`skill_${t('lang')}`];
                          }),
                        ),
                      )}
                      duration={skill?.duration}
                      button={
                        <button
                          onClick={
                            isSelectedItem(skill)
                              ? (e) => {
                                onDeleteClickHandler(e, skill);
                              }
                              : (e) => onAddClickHandler(e, skill)
                          }
                          className={`common-button fsbtn16 add ${
                            isSelectedItem(skill) && 'primary selected'
                          } active`}
                        >
                          {isSelectedItem(skill)
                            ? t('btn.delete')
                            : t('btn.add')}
                        </button>
                      }
                      key={idx}
                      test={skill}
                      detailTests={detailTests}
                      isSelected={isSelectedItem(skill)}
                      plan={plan}
                      enabledSkillList={enabledSkillList}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <section className="empty-table">
                <EmptyTable />
              </section>
            )}
          </Box>
        </section>
      </section>
    </main>
  );
};

Step1.propTypes = {
  init: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setInit: PropTypes.func,
  curStep: PropTypes.number,
  position: PropTypes.object,
  objectiveTests: PropTypes.array,
  setObjectiveTests: PropTypes.func,
};

export default Step1;
