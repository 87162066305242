export const languageOptions = [
  {
    id: 45,
    name: 'Assembly',
    label: 'Assembly',
    value: 'assembly',
  },
  {
    id: 46,
    name: 'Bash',
    label: 'Bash',
    value: 'bash',
  },
  {
    id: 47,
    name: 'Basic',
    label: 'Basic',
    value: 'basic',
  },
  {
    id: 50,
    name: 'C',
    label: 'C',
    value: 'c',
  },
  {
    id: 54,
    name: 'C++',
    label: 'C++',
    value: 'cpp',
  },
  {
    id: 86,
    name: 'Clojure',
    label: 'Clojure',
    value: 'clojure',
  },
  {
    id: 51,
    name: 'C#',
    label: 'C#',
    value: 'csharp',
  },
  {
    id: 77,
    name: 'COBOL',
    label: 'COBOL',
    value: 'cobol',
  },
  {
    id: 55,
    name: 'Common Lisp',
    label: 'Common Lisp',
    value: 'lisp',
  },
  {
    id: 56,
    name: 'D',
    label: 'D',
    value: 'd',
  },
  {
    id: 57,
    name: 'Elixir',
    label: 'Elixir',
    value: 'elixir',
  },
  {
    id: 58,
    name: 'Erlang',
    label: 'Erlang',
    value: 'erlang',
  },
  {
    id: 44,
    label: 'Executable',
    name: 'Executable',
    value: 'exe',
  },
  {
    id: 87,
    name: 'F#',
    label: 'F#',
    value: 'fsharp',
  },
  {
    id: 59,
    name: 'Fortran',
    label: 'Fortran',
    value: 'fortran',
  },
  {
    id: 60,
    name: 'Go',
    label: 'Go',
    value: 'go',
  },
  {
    id: 88,
    name: 'Groovy',
    label: 'Groovy',
    value: 'groovy',
  },
  {
    id: 61,
    name: 'Haskell',
    label: 'Haskell',
    value: 'haskell',
  },
  {
    id: 62,
    name: 'Java',
    label: 'Java',
    value: 'java',
  },
  {
    id: 63,
    name: 'JavaScript',
    label: 'JavaScript',
    value: 'javascript',
  },
  {
    id: 78,
    name: 'Kotlin',
    label: 'Kotlin',
    value: 'kotlin',
  },
  {
    id: 64,
    name: 'Lua',
    label: 'Lua',
    value: 'lua',
  },

  {
    id: 79,
    name: 'Objective-C',
    label: 'Objective-C',
    value: 'objectivec',
  },
  {
    id: 65,
    name: 'OCaml',
    label: 'OCaml',
    value: 'ocaml',
  },
  {
    id: 66,
    name: 'Octave',
    label: 'Octave',
    value: 'octave',
  },
  {
    id: 67,
    name: 'Pascal',
    label: 'Pascal',
    value: 'pascal',
  },
  {
    id: 85,
    name: 'Perl',
    label: 'Perl',
    value: 'perl',
  },
  {
    id: 68,
    name: 'PHP',
    label: 'PHP',
    value: 'php',
  },

  {
    id: 69,
    name: 'Prolog',
    label: 'Prolog',
    value: 'prolog',
  },

  {
    id: 71,
    name: 'Python',
    label: 'Python',
    value: 'python',
  },
  {
    id: 80,
    name: 'R',
    label: 'R',
    value: 'r',
  },

  {
    id: 73,
    name: 'Rust',
    label: 'Rust',
    value: 'rust',
  },
  {
    id: 81,
    name: 'Scala',
    label: 'Scala',
    value: 'scala',
  },
  {
    id: 82,
    name: 'SQL',
    label: 'SQL',
    value: 'sql',
  },
  {
    id: 83,
    name: 'Swift',
    label: 'Swift',
    value: 'swift',
  },
  {
    id: 74,
    name: 'TypeScript',
    label: 'TypeScript',
    value: 'typescript',
  },
  {
    id: 84,
    name: 'Visual Basic.Net',
    label: 'Visual Basic.Net',
    value: 'vbnet',
  },
];
