import React from 'react';
import { ReactComponent as PlusIcon } from 'assets/images/icon/plus.svg';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';

const RecruitmentCreateButton = ({ userInfo, assessments, disabled }) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();

  const onClickEventHandler = () => {
    displayModal(
      'recruitments/CreateRecruitment',
      {
        userInfo,
        assessments,
      },
      { frame: 'full' }
    );
  };

  return (
    <li className="create-button">
      <button
        className={`common-button primary fs16 ${!disabled && 'active'}`}
        onClick={onClickEventHandler}
        disabled={disabled}
      >
        <PlusIcon />
        {t('btn.open-new-position')}
      </button>
    </li>
  );
};

export default RecruitmentCreateButton;
