import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDaysElapsed } from 'utils/dateFormat';
import BodyTableRowSkeleton from '../BodyTableRowSkeleton';
import { useModal } from '../../../../utils/modal';
import { nameConversionLanguage } from '../../../../utils/converter';
import { CandidateDetailMoreMenu } from '../CandidateDetailMoreMenu';
import { candidateActionMenu } from '../../../../constants/recruitments/candidateActionMenu';

const RecruitmentTableBody = ({ candidates, recruitment, processLength }) => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();

  const viewDetailModal = (candidate) => {
    displayModal(
      'recruitments/CandidateDetail',
      {
        candidates,
        candidate,
        recruitment,
        processName: 'reject',
      },
      { frame: 'full' }
    );
  };

  const convertFinalHiringStep = (step) => {
    if (!processLength) return;

    let title;

    if (processLength === 7) {
      switch (step) {
        case 1:
          title = 'Reviewing Application';
          break;
        case 2:
          title = 'Digital Interview';
          break;
        case 3:
          title = 'In-person Interview';
          break;
        case 4:
          title = 'Compensation Document';
          break;
        case 5:
          title = 'Offer Letter';
          break;
        default:
          title = '';
          break;
      }
    } else if (processLength === 8) {
      switch (step) {
        case 1:
          title = 'Reviewing Application';
          break;
        case 2:
          title = 'Digital Interview';
          break;
        case 3:
        case 4:
          title = 'In-person Interview';
          break;
        case 5:
          title = 'Compensation Document';
          break;
        case 6:
          title = 'Offer Letter';
          break;
        default:
          title = '';
          break;
      }
    }

    return title;
  };

  const convertRejectType = (...arg) => {
    if (!processLength) return;
    const step = arg[0];
    const opinion = arg[1];

    let title;

    if (processLength === 7 && step === 5) {
      title = 'offer reject';
    } else if (processLength === 8 && step === 6) {
      title = 'offer reject';
    } else if (opinion) {
      title = opinion;
    }

    return title;
  };

  return (
    <>
    <tbody>
      {candidates === null ? (
        <>
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
        </>
      ) : candidates?.length === 0 ? (
        <tr>
          <td>{t('recruitment.desc.empty-no-applicant')}</td>
        </tr>
      ) : (
        candidates?.map((candidate) => (
          <tr key={candidate?.email} onClick={() => viewDetailModal(candidate)}>
            <td>
              {nameConversionLanguage(
                candidate?.firstName,
                candidate?.lastName,
                t('lang')
              )}
            </td>
            <td>{candidate?.progressSubmit[1]?.channel || ''}</td>
            <td>{convertFinalHiringStep(candidate?.finalHiringStep) || ''}</td>
            <td>
              {getDaysElapsed(candidate?.updatedAt?.seconds * 1000) === 0
                ? t('lead-time.today')
                : `${getDaysElapsed(candidate?.updatedAt?.seconds * 1000)} ${t(
                    'lead-time.days'
                  )}` || ''}
            </td>
            <td>
              {convertRejectType(
                candidate?.finalHiringStep,
                candidate.progressSubmit[candidate?.finalHiringStep].opinion
              ) || ''}
            </td>
            <td>
              <CandidateDetailMoreMenu
                candidate={candidate}
                menuList={[candidateActionMenu.deleteCandidate]}
                rid={recruitment?.rid}
              />
            </td>
          </tr>
        ))
      )}
    </tbody>
    </>
  );
};

export default RecruitmentTableBody;
