import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';

const ContactToHelpDesk = () => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();

  return (
    <article className="modals-common-alert-modal">
      <section className="title">
        <h3 className="title-main fs16">
          {t('dialog.title.required-additional-services')}
        </h3>
        <div className="title-sub fs14">
          {t('dialog.desc.required-additional-services')}
        </div>
      </section>
      <section className="buttons">
        <button
          className="common-button fsbtn16 cancel active"
          onClick={() => dismissModal()}
        >
          {t('btn.cancel')}
        </button>
        <button
          className="common-button fsbtn16 primary active"
          onClick={() => {
            dismissModal();
            window.open('https://v1n62.channel.io', '_blank');
          }}
        >
          {t('btn.contact-us')}
        </button>
      </section>
    </article>
  );
};

export default ContactToHelpDesk;
