import React, { useEffect, useState } from 'react';
import { useModal } from 'utils/modal';
import { ReactComponent as PlusIcon } from 'assets/images/icon/plus.svg';
import { ReactComponent as SearchIcon } from 'assets/images/icon/ico-search-normal.svg';
import { ReactComponent as FilterIcon } from 'assets/images/icon/filter-list.svg';
import RecruitmentInput from 'components/input/RecruitmentInput';
import { useLocation } from 'react-router-dom';
import { Button } from 'components/common/Button';
import { useTranslation } from 'react-i18next';

const DetailForm = ({
  searchValue,
  setSearchValue,
  userInfo,
  recruitment,
  render,
  setRender,
}) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();
  const location = useLocation();

  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    const pathArr = (location?.pathname || '').split('/');

    setCurrentTab(pathArr[4] || '');
  }, [location?.pathname]);

  const onClickAddCandidate = () => {
    displayModal('recruitments/AddCandidate', {
      recruitment,
      userInfo,
      render,
      setRender,
    });
  };

  return (
    <aside className="dashboard-detail-search-form">
      <div className={`add-candidate ${currentTab !== '' && 'hidden'}`}>
        <Button
          label={t('btn.add-candidate')}
          size="40"
          theme="blue"
          isDisabled={
            (userInfo && userInfo?.memberType === 20) ||
            recruitment?.isRecruitmentArchived ||
            (recruitment?.isSchedule &&
              recruitment?.startPeriodSchedule > new Date().getTime())
          }
          onClick={onClickAddCandidate}
          fontSize="fs16"
          frontIcon={<PlusIcon />}
        />
      </div>
      <div className="search-filter-form">
        <RecruitmentInput
          icon={<SearchIcon />}
          value={searchValue}
          setValue={setSearchValue}
          placeHolder={t('text-field.label.search')}
        />
        <button className="search-filter">
          <FilterIcon />
        </button>
      </div>
    </aside>
  );
};

export default DetailForm;
