import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { MaterialSelectRecruitment } from 'components/select/MaterialSelect';
import { useTranslation } from 'react-i18next';

const CheckboxContainer = ({
  assessments,
  process,
  setProcess,
  selectedAsssessment,
  setSelectedAssessment,
  disabled,
}) => {
  const { t } = useTranslation();
  const { secondInterview } = process;

  const [noArchivedAssessments, setNoArchicedAssessments] = useState([]);

  useEffect(() => {
    if (assessments?.length === 0) return;

    const filteredAssessments = assessments?.filter((item) => !item.archived);
    setNoArchicedAssessments(filteredAssessments);
  }, [assessments]);

  const handleChange = (e) => {
    setProcess({
      ...process,
      [e.target.name]: {
        isUsed: e.target.checked,
        label: process[e.target.name].label,
      },
    });
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormGroup className="process-setting-wrapper">
        {/* 서류 접수 */}
        <div className="label-row-group">
          <aside className="flow-icon">
            <i className="rect" />
          </aside>
          <aside className="flow-name fs14">
            {t('title.process.reviewing-application')}
          </aside>
        </div>
        {/* Digital interview */}
        <div className="label-row-group selected-assessment">
          <article className="selected-wrapper">
            <aside className="flow-icon">
              <i className="rect" />
            </aside>
            <aside className="flow-name fs14">
              {t('title.process.digital-interview')}
            </aside>
          </article>
          <MaterialSelectRecruitment
            className="assessment-form"
            width="100"
            selectedLabel={selectedAsssessment?.name}
            onClick={setSelectedAssessment}
            menuList={noArchivedAssessments}
            defaultValue={t('menu.placeholder.select-assessment')}
          />
        </div>
        {/* in-person interview */}
        <div className="label-row-group no-flex">
          <article className="optional-checkbox-wrapper">
            <aside className="flow-icon">
              <i className="rect" />
            </aside>
            <aside className="flow-name fs14">
              {t('title.process.in-person-interview')}
            </aside>
          </article>
          {/* 2차 대면 면접 */}
          <div className="label-row-group optional">
            <FormControlLabel
              control={
                <Checkbox
                  checked={secondInterview.isUsed}
                  onChange={handleChange}
                  name="secondInterview"
                  disabled={disabled}
                />
              }
              label={t(secondInterview.label)}
            />
          </div>
        </div>
        {/* information request */}
        <div className="label-row-group">
          <aside className="flow-icon">
            <i className="rect" />
          </aside>
          <aside className="flow-name fs14">
            {t('title.process.compensation')}
          </aside>
        </div>
        {/* offer letter */}
        <div className="label-row-group">
          <aside className="flow-icon">
            <i className="rect" />
          </aside>
          <aside className="flow-name fs14">
            {t('title.process.offer-letter')}
          </aside>
        </div>
        {/* certificates */}
        <div className="label-row-group">
          <aside className="flow-icon">
            <i className="rect" />
          </aside>
          <aside className="flow-name fs14">{t('title.process.hired')}</aside>
        </div>
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxContainer;
