import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  _getGucciUserList,
  _createMemberUserInfo,
} from 'utils/firebase/recruitments/member';
import { serializeError } from 'serialize-error';
import {
  _createAllowanceReceipt,
  _createAuthToken,
  _deleteAllowanceReceipt,
  _getAllowanceReceipt,
  _isAccountWithEmail,
  _getInfoFromAuthToken,
} from 'utils/firebase/auth';
import axios from 'axios';
import { validateEmailDomain } from 'utils/auth';
import {
  _memberInvitedCandidate,
  _addCandidateOfMember,
  _setMemberInfo,
  _setMemberDisabled,
} from 'utils/firebase/recruitments/member';

const initialState = {
  getGucciUsersLoading: false,
  getGucciUsersError: null,
  getGucciUsersSuccess: null,
};

export const createMemberUserInfo = createAsyncThunk(
  'recruitments/members/createMemberUserInfo',
  async (payload, { getState, rejectWithValue }) => {
    const { authToken } = payload;
    try {
      const uid = getState().auth.user?.uid;
      const { allowanceReceipt } = await _getAllowanceReceipt({
        authToken,
      });
      await _createMemberUserInfo({
        payload,
        uid,
        allowanceReceipt,
      });
      await _deleteAllowanceReceipt({ authToken });
      const { email } = await _getInfoFromAuthToken({ authToken });
      await axios.post(
        `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/auth/intercom/contact/create`,
        {
          uid,
          email,
        }
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getGucciUserList = createAsyncThunk(
  'recruitments/members/getGucciUserList',
  async (_, { rejectWithValue }) => {
    try {
      const doc = await _getGucciUserList();

      return { doc };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const invitedValidateMail = createAsyncThunk(
  'recruitments/members/invitedValidateMail',
  async (payload, { rejectWithValue }) => {
    let {
      email,
      firstName,
      lastName,
      type,
      allowanceReceipt,
      account,
      ouid,
      memberType,
      authToken,
    } = payload;

    try {
      if (type === 'set') {
        const isAccount = await _isAccountWithEmail({ email });
        if (isAccount) {
          throw new Error('already_joined');
        }
        await validateEmailDomain(email);

        authToken = await _createAuthToken({ email });
        await _createAllowanceReceipt({ authToken, allowanceReceipt });
      } else throw new Error('no_type');

      const result = await axios.post(
        `${process.env.REACT_APP_POST_BOX}/auth/send/member/email`,
        {
          email,
          firstName,
          lastName,
          authToken,
          param: type,
          account,
          ouid,
          memberType,
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
          },
        }
      );

      return {
        authToken: result?.data,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const memberInvitedCandidate = createAsyncThunk(
  'members/memberInvitedCandidate',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const { uid, rid, isRecruitment, email, stage, recruitProgress } = payload;

    try {
      await _memberInvitedCandidate({
        uid,
        rid,
        isRecruitment,
        stage,
      });

      await _addCandidateOfMember({ uid, rid, email, recruitProgress });
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const setMemberInfo = createAsyncThunk(
  'members/setMemberInfo',
  async (payload, { rejectWithValue, dispatch }) => {
    const { uid, interviewInfo, memberType } = payload;

    try {
      await _setMemberInfo({ uid, interviewInfo, memberType });
      await dispatch(getGucciUserList());
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const disabledUser = createAsyncThunk(
  'recruitments/members/disabledUser',
  async (payload, { rejectWithValue, dispatch }) => {
    const { uid, disabled } = payload;

    try {
      await _setMemberDisabled({ uid, disabled });
      await dispatch(getGucciUserList());
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGucciUserList.pending, (state) => {
        state.getGucciUsersLoading = true;
        state.getGucciUsersError = null;
      })
      .addCase(getGucciUserList.fulfilled, (state, action) => {
        state.getGucciUsersLoading = false;
        state.getGucciUsersSuccess = action.payload.doc;
      })
      .addCase(getGucciUserList.rejected, (state, action) => {
        state.getGucciUsersLoading = false;
        state.getGucciUsersError = action.error.message;
      });
  },
});

export const {} = membersSlice.actions;

export default membersSlice.reducer;
