import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NextIcon } from 'assets/images/icon/icon-next.svg';
import KhaiPContentsLeft from './KhaiPContentsLeft';
import KhaiPContentsRight from './KhaiPContentsRight';

const KhaiPContents = ({ tag, data }) => {
  const { t } = useTranslation();
  const [categoryData, setCategoryData] = useState([]);
  const [interviewData, setInterviewData] = useState('');

  useEffect(() => {
    if (!data) return;

    const splitData = data?.split('+');
    const leftData = {
      title: splitData[0],
      desc: splitData[1],
    };
    const rightData = splitData[2];

    setCategoryData(leftData);
    setInterviewData(rightData);
  }, [data]);

  return (
    <article className="interview-wrap">
      <KhaiPContentsLeft tag={tag} categoryData={categoryData} />
      <aside className="interview-icon-box">
        <NextIcon />
      </aside>
      <KhaiPContentsRight interviewData={interviewData} />
    </article>
  );
};

export default KhaiPContents;
