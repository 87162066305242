import React from 'react';

const EstimateAvarage = ({ averageScore, title }) => (
  <div className="estimate-score-flex mb40">
    <div className="estimate-rate-title">
      <h5 className="fs16">{title}</h5>
    </div>
    <div className="estimate-score flex">
      <div className="estimate-score-number">
        <span className="fs16 number">{Math.trunc(averageScore * 100)}</span>
        <span>%</span>
      </div>
    </div>
  </div>
);

export default EstimateAvarage;
