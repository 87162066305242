import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'serialize-error';
import { _setResume, _updateResume } from 'utils/firebase/profile';

const initialState = {};

export const setResume = createAsyncThunk(
  'profile/setResume',
  async (payload, { rejectWithValue }) => {
    const { 증빙업데이트 } = payload;

    try {
      let doc;

      if (!증빙업데이트) {
        doc = await _setResume({ payload });
      } else {
        doc = await _updateResume({ payload });
      }

      return { doc };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {},
});

export const {} = profileSlice.actions;

export default profileSlice.reducer;
