import React from 'react';

const BasicFile = ({
  className,
  icon,
  label,
  name,
  value,
  onChange,
  required,
  caution,
  placeholder,
  disabled,
  multiple,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onDrop,
}) => {
  const onFileUploadChangeHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onChange(event);
  };

  return (
    <div className={`basic-file ${className && className}`}>
      <span className="input-label" htmlFor={name}>
        {label || ''} {required && <i>*</i>}
      </span>
      <div className="input-box">
        <label
          htmlFor={name}
          className={disabled ? 'disabled' : ''}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <span>{!value ? placeholder || '' : value}</span> {icon && icon}
        </label>
        <input
          id={name}
          name={name}
          type="file"
          multiple={multiple}
          onChange={onFileUploadChangeHandler}
          accept="application/pdf"
          hidden
          disabled={disabled}
        />
      </div>
      {caution && <span className="input-caution">{caution}</span>}
    </div>
  );
};

export default BasicFile;
