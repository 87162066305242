import React from 'react';
import Tag from 'components/common/Tag';
import BodyTableRowSkeleton from 'components/recruitment/detail/BodyTableRowSkeleton';
import { useModal } from 'utils/modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PencilIcon } from 'assets/images/icon/settings-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icon/settings-minimize.svg';
import { ReactComponent as ActiveIcon } from 'assets/images/icon/add.svg';
import {
  convertMemberTypeToTitle,
  nameConversionLanguage,
} from 'utils/converter';
import { dateToDateFormat } from 'utils/string';
import { Button } from 'components/common/Button';
import { FILTER_ITEMS } from 'constants/members/filter';
import { executeAction } from 'utils/redux';
import { disabledUser } from 'store/slices/recruitments/membersSlice';
import { toast } from 'utils/notify';
import {
  convertDisabled,
  convertTeam,
  convertJobFunction,
  convertWorkspace,
} from 'utils/convertKey';

const MembersTableBody = ({ users, userInfo }) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();

  const userMemberTypeUpdate = (user) => {
    displayModal(
      'profile/UpdateMemberType',
      {
        selectedUser: user,
        menuItems: FILTER_ITEMS,
      },
      { frame: 'full' }
    );
  };

  const userSuspended = (user) => {
    executeAction(disabledUser({ uid: user?.id, disabled: true }))
      .then(() => {
        toast('계정 비활성화가 완료 되었습니다.');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const userActive = (user) => {
    executeAction(disabledUser({ uid: user?.id, disabled: false }))
      .then(() => {
        toast('계정 활성화가 완료 되었습니다.');
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <tbody>
      {users === null ? (
        <>
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
        </>
      ) : users?.length === 0 ? (
        <tr>
          <td>{t('recruitment.desc.empty-no-applicant')}</td>
        </tr>
      ) : (
        users?.map(
          (user) =>
            user?.ownerUid === userInfo?.ownerUid && (
              <tr
                key={user?.email}
                className={user?.disabled ? 'disabled' : ''}
              >
                <td>{t(convertMemberTypeToTitle(user?.memberType))}</td>
                <td>
                  {nameConversionLanguage(
                    user?.firstName,
                    user?.lastName,
                    t('lang')
                  )}
                </td>
                <td>{user?.email}</td>
                <td>
                  <Tag
                    label={t(convertDisabled(user?.disabled))}
                    fontSize={'fs12'}
                    color={!user?.disabled ? 'blue500' : 'red500'}
                  />
                </td>
                <td>{t(convertTeam(user?.team))}</td>
                <td>{t(convertJobFunction(user?.jobFunction))}</td>
                <td>{t(convertWorkspace(user?.workspace))}</td>
                <td>
                  {!user?.createdAt
                    ? dateToDateFormat(new Date(), t('lang'))
                    : dateToDateFormat(
                        new Date(user.createdAt.seconds * 1000 || ''),
                        t('lang')
                      )}
                </td>
                <td>
                  <div className="flex">
                    <Button
                      frontIcon={<PencilIcon />}
                      padding={6}
                      theme={'borderGrey'}
                      radius={8}
                      onClick={() => userMemberTypeUpdate(user)}
                      isDisabled={userInfo?.memberType > 5}
                    />
                    {!user?.disabled ? (
                      <Button
                        frontIcon={<CloseIcon />}
                        padding={6}
                        theme={'borderGrey'}
                        radius={8}
                        onClick={() => userSuspended(user)}
                        isDisabled={userInfo?.memberType > 5}
                      />
                    ) : (
                      <Button
                        frontIcon={<ActiveIcon />}
                        padding={6}
                        theme={'borderGrey'}
                        radius={8}
                        onClick={() => userActive(user)}
                        isDisabled={userInfo?.memberType > 5}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )
        )
      )}
    </tbody>
  );
};

export default MembersTableBody;
