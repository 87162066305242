import React, { useEffect, useState } from 'react';
import BasicInput from 'components/input/BasicInput';
import DetailLeftForm from 'components/compensation/DetailLeftForm';
import DetailRightForm from 'components/compensation/DetailRightForm';
import CheckedReference from 'components/compensation/CheckedReference';
import MaterialDateTimePicker from 'components/common/MaterialDateTimePicker';
import { Button } from 'components/common/Button';
import { Timestamp } from 'firebase/firestore';
import { useStatus } from 'utils/status';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { uploadBytes } from 'store/slices/uploadSlice';
import { setResume } from 'store/slices/profileSlice';
import { setCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'utils/notify';
import { dateToDateFormat } from 'utils/string';

const CompensationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { uid, rid, email, step, candidate, recruitment } = location?.state;
  const { setIsEntireLoading } = useStatus();
  const currentProgress = candidate?.progressSubmit[step];

  const [canSubmit, setCanSubmit] = useState(false);
  const [isNewcommer, setIsNewcommer] = useState(
    currentProgress?.isNewcommer ? currentProgress?.isNewcommer : false
  );
  const [salaryData, setSalaryData] = useState({});
  const [fileData, setFileData] = useState([]);
  const [startDate, setStartDate] = useState(
    currentProgress?.startDate ? currentProgress?.startDate : 0
  );
  const [isOverFourWeeks, setIsOverFourWeeks] = useState(false);
  const [hasOverFourWeeksReason, setHasOverFourWeeksReason] = useState(
    currentProgress?.hasOverFourWeeksReason || ''
  );
  const [otherAllowances, setOtherAllowances] = useState(
    currentProgress?.otherAllowances ? currentProgress?.otherAllowances : ''
  );
  const [checkedReference1, setCheckedReference1] = useState({});
  const [checkedReference2, setCheckedReference2] = useState({});
  const [dueDate, setDueDate] = useState(0);
  const [personalInformation, setPersonalInformation] = useState(
    currentProgress?.personalInformation || {}
  );

  useEffect(() => {
    let flag = true;

    if (!Object.keys(salaryData).length) {
      flag = false;

      if (isNewcommer) {
        flag = true;
      }
    }

    if (!fileData?.length) {
      flag = false;

      if (isNewcommer) {
        flag = true;
      }
    }

    if (!startDate) {
      flag = false;
    }

    if (isOverFourWeeks) {
      if (!hasOverFourWeeksReason) {
        flag = false;
      }
    }

    if (
      !personalInformation?.englishLastname ||
      !personalInformation?.englishFirstname ||
      !personalInformation?.birth
    ) {
      flag = false;
    }

    setCanSubmit(flag);
  }, [
    salaryData,
    isNewcommer,
    fileData,
    startDate,
    checkedReference1,
    checkedReference2,
    personalInformation,
    isOverFourWeeks,
    hasOverFourWeeksReason,
  ]);

  useEffect(() => {
    if (!currentProgress) return;

    const sendDate = new Date(currentProgress?.invitedAt?.seconds * 1000);
    const fourWeeksLaterDate = new Date(
      sendDate?.getTime() + 4 * 7 * 24 * 60 * 60 * 1000
    );

    setDueDate(fourWeeksLaterDate);
  }, [currentProgress]);

  useEffect(() => {
    if (!startDate) return;
    const currentDate = new Date(
      currentProgress?.invitedAt?.seconds * 1000 || 0
    );
    const fourWeeksLater = new Date(
      currentDate?.getTime() + 4 * 7 * 24 * 60 * 60 * 1000
    );

    if (new Date(startDate)?.getTime() >= fourWeeksLater.getTime()) {
      setIsOverFourWeeks(true);
    } else {
      setIsOverFourWeeks(false);
    }
  }, [startDate]);

  const fetchSalary = (data) => {
    setSalaryData(data);
  };

  const fetchFile = (data) => {
    setFileData(data);
  };

  const fetchReference = (data, type) => {
    if (type === 1) {
      setCheckedReference1(data);
    } else if (type === 2) {
      setCheckedReference2(data);
    }
  };

  const fetchPersonal = (e) => {
    const { value, name } = e.target;

    if (name === 'birth') {
      const number = value.replace(/[^0-9]/g, '');
      const regexValue = number.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

      if (regexValue.length > 10) {
        return toast(t('toast.desc.10-characters'));
      }
      setPersonalInformation((prev) => ({
        ...prev,
        [name]: regexValue,
      }));
      return;
    }

    setPersonalInformation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchOverFourWeeks = (e) => {
    const { value } = e.target;

    setHasOverFourWeeksReason(value);
  };

  const onClickSaveButton = () => {
    setIsEntireLoading(true);
    const filesArr = [];

    fileData?.forEach((file) => {
      if (file?.name === '') return;

      filesArr.push(
        executeAction(
          uploadBytes({
            collection: 'gucciapply',
            category: 'compensation',
            email,
            rid,
            fileName: file.name,
            bytes: file,
          })
        )
          .unwrap()
          .then(({ downloadURL }) => {
            return {
              url: downloadURL,
              name: file.name,
            };
          })
          .catch((e) => {
            console.error(e);
          })
      );
    });

    Promise.all(filesArr).then((file) => {
      executeAction(
        setResume({
          이메일: email,
          신입: isNewcommer,
          증빙서류: file,
          증빙업데이트: true,
        })
      )
        .unwrap()
        .then(() => {
          executeAction(
            setCandidateOfRecruitment({
              ouid: uid,
              aid: rid,
              cid: email,
              lang: 'ko',
              progressSubmit: {
                [step]: {
                  isNewcommer,
                  salaryData,
                  files: file,
                  startDate,
                  checkedReference1,
                  checkedReference2,
                  otherAllowances,
                  saveDate: Timestamp.now(),
                  state: 'save',
                  personalInformation,
                  hasOverFourWeeksReason,
                },
              },
            })
          )
            .then(() => {
              toast(t('toast.completed-save'));
              setIsEntireLoading(false);
            })
            .catch((e) => {
              console.error(e);
              setIsEntireLoading(false);
            });
        })
        .catch((e) => {
          console.error(e);
          setIsEntireLoading(false);
        });
    });
  };

  const onClickSubmitButton = () => {
    setIsEntireLoading(true);
    const filesArr = [];

    fileData?.forEach((file) => {
      if (file?.name === '') return;

      filesArr.push(
        executeAction(
          uploadBytes({
            collection: 'gucciapply',
            category: 'compensation',
            email,
            rid,
            fileName: file.name,
            bytes: file,
          })
        )
          .unwrap()
          .then(({ downloadURL }) => {
            return {
              url: downloadURL,
              name: file.name,
            };
          })
          .catch((e) => {
            console.error(e);
          })
      );
    });

    Promise.all(filesArr).then((file) => {
      executeAction(
        setResume({
          이메일: email,
          신입: isNewcommer,
          증빙서류: file,
          증빙업데이트: true,
        })
      )
        .unwrap()
        .then(() => {
          executeAction(
            setCandidateOfRecruitment({
              ouid: uid,
              aid: rid,
              cid: email,
              lang: 'ko',
              progressSubmit: {
                [step]: {
                  isNewcommer,
                  salaryData,
                  files: file,
                  startDate,
                  checkedReference1,
                  checkedReference2,
                  otherAllowances,
                  submitDate: Timestamp.now(),
                  state: 'submit',
                  personalInformation,
                  hasOverFourWeeksReason,
                },
              },
            })
          )
            .then(() => {
              navigate('/compensation/completed');
              setIsEntireLoading(false);
            })
            .catch((e) => {
              console.error(e);
              setIsEntireLoading(false);
            });
        })
        .catch((e) => {
          console.error(e);
          setIsEntireLoading(false);
        });
    });
  };

  return (
    <main id="pages-compensation-form">
      <div className="container">
        <section className="title-wrapper flex-column-gap32">
          <article className="recruitment-title">
            <h3 className="fs18">
              {recruitment && recruitment?.recruitmentName}
            </h3>
          </article>
          <article className="main-title">
            <h2 className="fs28">{t('title.upload-document')}</h2>
          </article>
        </section>
        <section className="detail-wrapper flex-column-gap32">
          <article className="sub-title">
            <h3 className="fs24">{t('title.personal-detail')}</h3>
          </article>
          <article className="detail-form flex-column-gap32">
            <aside className="left">
              <div className="flex">
                <BasicInput
                  required
                  inputType="text"
                  label={t('text-field.label.english-last-name')}
                  name="englishLastname"
                  value={personalInformation.englishLastname || ''}
                  onChange={fetchPersonal}
                />
                <BasicInput
                  required
                  inputType="text"
                  label={t('text-field.label.english-first-name')}
                  name="englishFirstname"
                  value={personalInformation.englishFirstname || ''}
                  onChange={fetchPersonal}
                />
              </div>
              <BasicInput
                required
                inputType={'text'}
                label={t('field.birth')}
                name="birth"
                value={personalInformation.birth || ''}
                onChange={fetchPersonal}
                placeholder={t('text-field.placeholder.birth')}
              />
            </aside>
          </article>
          <article className="sub-title">
            <h3 className="fs24">{t('title.compensation-detail')}</h3>
          </article>
          <article className="checkbox-box">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNewcommer}
                  onChange={(e) => setIsNewcommer(e.target.checked)}
                  name="secondInterview"
                />
              }
              label={t('title.newCommer')}
            />
          </article>
          {!isNewcommer && (
            <article className="detail-form flex-column-gap32">
              <aside className="left">
                <DetailLeftForm
                  fetchSalary={fetchSalary}
                  currentProgress={currentProgress}
                />
              </aside>
              <aside className="right">
                <DetailRightForm fetchFile={fetchFile} />
              </aside>

              <BasicInput
                inputType={'text'}
                label={t('text-field.label.non-monetary-compensation')}
                placeholder={`| ${t('text-field.placeholder.enter-text')}`}
                value={otherAllowances}
                onChange={(e) => setOtherAllowances(e.target.value)}
              />
            </article>
          )}
        </section>
        <section className="start-date-wrapper flex-column-gap32">
          <article className="title-box">
            <h3 className="sub-title fs24">
              {t('text-field.label.starting-date')}
            </h3>
            <p className="sub-description fs20">
              {t('text-field.notify.earliest-possible-date')}
            </p>
          </article>
          <article className="selected-date-box">
            <MaterialDateTimePicker
              periodDateTime={startDate}
              setPeriodDateTime={setStartDate}
              withoutTime
            />
            <BasicInput
              required={isOverFourWeeks}
              inputType="text"
              label={t('text-field.label.reason')}
              name="overFourWeeks"
              value={hasOverFourWeeksReason}
              onChange={fetchOverFourWeeks}
              disabled={!isOverFourWeeks}
              isTransCaution
              caution={
                <Trans
                  t={t}
                  i18nKey="text-field.helper.reason"
                  values={{
                    dueDate: dateToDateFormat(dueDate, t('lang')),
                  }}
                />
              }
            />
          </article>
        </section>
        <section className="reference-wrapper flex-column-gap32">
          <article className="title-box">
            <h3 className="sub-title fs24">
              {t('title.reference-check-detail')}
            </h3>
            <p className="sub-description fs20">
              {t('desc.reference-check-option')}
            </p>
          </article>
          <article className="reference-box">
            <h5 className="fs16">{t('title.referee1')}</h5>
            <CheckedReference
              setReference={fetchReference}
              number={1}
              currentProgress={currentProgress}
            />
          </article>
          <article className="reference-box">
            <h5 className="fs16">{t('title.referee2')}</h5>
            <CheckedReference
              setReference={fetchReference}
              number={2}
              currentProgress={currentProgress}
            />
          </article>
        </section>
        <section className="button-wrapper">
          <article className="button-box">
            <Button
              label={t('btn.recruitment-save')}
              theme={'grey700'}
              size={48}
              fontSize={'fs16'}
              onClick={onClickSaveButton}
            />
            <Button
              label={t('btn.submit')}
              theme={'grey'}
              size={48}
              fontSize={'fs16'}
              isDisabled={!canSubmit}
              onClick={onClickSubmitButton}
            />
          </article>
        </section>
      </div>
    </main>
  );
};

export default CompensationForm;
