import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from '@mui/material';
import { useAuth } from 'utils/auth';
import { useModal } from 'utils/modal';
import { useStatus } from 'utils/status';
import { useOutletContext } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  getCandidatesOfAssessment,
  getAssessment,
} from 'store/slices/assessmentsSlice';
import { useSelector } from 'react-redux';

const Styling = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userInfo } = useSelector((state) => state.auth);
  const { displayModal, dismissModal } = useModal();
  const { setIsEntireLoading } = useStatus();
  const [aid] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [_cid, _setCid] = React.useState();
  const [isRefreshing, setIsRefreshing] = React.useState(true);
  const [refreshing, setRefreshing] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [assessmentInfo, setAssessmentInfo] = React.useState({});
  const [__candidates, __setCandidates] = React.useState([]);
  const [_candidates, _setCandidates] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);

  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //

  const lang = t('lang');

  // get assessment info
  React.useEffect(() => {
    if (!aid || Object.keys(userInfo).length === 0) return;

    executeAction(
      getAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setAssessmentInfo(doc || {});
      })
      .catch((e) => {
        console.dir(e);
        setAssessmentInfo({});
      });
  }, [aid, userInfo]);

  React.useEffect(() => {
    if (isRefreshing) {
      __setCandidates([]);
      return setIsRefreshing(false);
    }
    const stylingCandidates = new Array();

    setIsLoading(true);
    executeAction(
      getCandidatesOfAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ docs }) => {
        (docs || []).forEach((doc, idx) => {
          if (
            doc?.results?.['99000100000'] &&
            doc?.results?.['99000100000']?.img?.length > 0
          ) {
            stylingCandidates.push(doc);
          }
        });

        (stylingCandidates || []).sort((a, b) => {
          if (a.updatedAt < b.updatedAt) {
            return 1;
          }
          if (a.updatedAt > b.updatedAt) {
            return -1;
          }
          if (a.updatedAt === undefined && Boolean(b.updatedAt)) {
            return 1;
          }
          if (b.updatedAt === undefined && Boolean(a.updatedAt)) {
            return -1;
          }

          return 0;
        });
        __setCandidates(stylingCandidates || []);
        setIsLoading(false);
      })
      .catch((e) => {
        __setCandidates([]);
        setIsLoading(false);
        console.dir(e);
      });
  }, [aid, isRefreshing, assessmentInfo]);

  // display datas change.
  React.useEffect(() => {
    const startIdx = page * rowsPerPage;
    setCandidates(_candidates.slice(startIdx, startIdx + rowsPerPage));
  }, [_candidates, page, rowsPerPage]);

  React.useEffect(() => {
    let candidatesCopy = (__candidates || []).slice(0);
    _setCandidates(candidatesCopy);
    // TODO: add the initialStep.
  }, [__candidates]);

  const onCandidateClickHandler = (row, cell) => {
    const cid = candidates[row * 4 + cell]?.id;
    navigate(`/customer/candidates/${cid}?aid=${aid}&entry=assessment`);
  };

  const showTableRow = () => {
    const result = new Array();
    const rowcnt =
      parseInt(candidates.length / 4) + (candidates.length % 4 > 0 ? 1 : 0);
    for (let i = 0; i < rowcnt; i += 1) {
      const cells = getCells(i);
      result.push(
        <TableRow className="styling-row" key={i}>
          {cells}
        </TableRow>
      );
    }
    return result;
  };

  const getCells = (row) => {
    const cells = [];
    for (let i = 0; i < 4; i += 1) {
      var imgurl = candidates[row * 4 + i]?.results?.['99000100000']?.img || '';
      if (imgurl && imgurl.length > 0) {
        cells.push(
          <TableCell className="styling-cell" style={{ width: '25%' }} key={i}>
            <img
              alt=""
              src={imgurl}
              onClick={() => {
                onCandidateClickHandler(row, i);
              }}
            />
          </TableCell>
        );
      } else {
        cells.push(
          <TableCell className="styling-cell" style={{ width: '25%' }} key={i}>
            <img alt="" />
          </TableCell>
        );
      }
    }

    return cells;
  };

  return (
    <section className="pages-protected-detail-styling">
      <section className="candidates">
        <h3 className="candidates-title fs20">
          {t('menu.label.result-styling')}
        </h3>
        <Paper className="revert-paper">
          <TableContainer>
            <Table className="style-table">
              <TableBody>{showTableRow()}</TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[20, 24, 28]}
              component="div"
              count={_candidates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </section>
    </section>
  );
};

export default Styling;
