import React, { useEffect, useState } from 'react';
import { useModal } from 'utils/modal';
import { ReactComponent as NoData } from 'assets/images/no-data-ai-interview.svg';
import VirturalDetail from 'components/recruitment/detail/virtualInterview/VirtualDetail';
import { useSelector } from 'react-redux';
import EvidenceDetail from 'components/recruitment/detail/evidence/EvidenceDetail';
import OfferDetail from 'components/recruitment/detail/offer/OfferDetail';
import { Button } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { nameConversionLanguage } from 'utils/converter';
import ChangeStatusModal from 'components/recruitment/detail/ChangeStatusModal';
import { useLocation } from 'react-router-dom';
import { setCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { executeAction } from 'utils/redux';
import { toast } from 'utils/notify';
import ReviewingDetail from '../../components/recruitment/detail/reviewing/ReviewingDetail';
import { useFilterOfRecruitmentProcess } from '../../utils/hooks';
import PersonInterviewDetail from '../../components/recruitment/detail/interview/PersonInterviewDetail';
import PersonInterviewHrDetail from '../../components/recruitment/detail/interview/PersonInterviewHrDetail';
import ConfirmInterviewDate from './ConfirmInterviewDate';

const CandidateDetail = ({
  candidates,
  candidate,
  recruitment,
  processName,
}) => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();
  const { getCandidatesSuccess } = useSelector((state) => state.recruitments);
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();

  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [currentCandidateIdx, setCurrentCandidateIdx] = useState(
    candidates.indexOf(candidate)
  );
  const [currentCandidate, setCurrentCandidate] = useState(candidate);
  const [prevCandidate, setPrevCandidate] = useState();
  const [nextCandidate, setNextCandidate] = useState();
  const [processSetting, processLength] =
    useFilterOfRecruitmentProcess(recruitment);
  const [showProcess, setShowProcess] = useState();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState({
    key: 'ongoing-interview',
    i18nKey: 'select.ongoing-interview',
  });
  const [rejectType, setRejectType] = useState('');
  const [openConfirmDate, setOpenConfirmDate] = useState(false);

  const onShowNextCandidate = () => {
    setCurrentCandidate(nextCandidate);
    setCurrentCandidateIdx((prev) => prev + 1);
    setSelectedHistory({
      key: 'ongoing-interview',
      i18nKey: 'select.ongoing-interview',
    });
  };

  const onShowPrevCandidate = () => {
    setCurrentCandidate(prevCandidate);
    setCurrentCandidateIdx((prev) => prev - 1);
    setSelectedHistory({
      key: 'ongoing-interview',
      i18nKey: 'select.ongoing-interview',
    });
  };

  const createScheduleModal = () => {
    const eventHandler = (event) => {
      if (event.detail?.message !== 'Detail Modal Open') return;
      window.document.querySelector('body').style.position = 'relative';
      window.document.removeEventListener('dismissModal', eventHandler);
    };

    window.document.querySelector('body').style.position = 'fixed';
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'recruitments/InterviewSchedule',
      {
        recruitment,
        currentCandidate,
        processName,
        candidates,
        userInfo,
        event: {
          message: 'Detail Modal Open',
        },
      },
      {
        frame: 'integration',
      }
    );
  };

  const rejectOpenModal = (type) => {
    setRejectType(type);
    setShowRejectModal(true);
  };

  const rejectSubmitHandler = (type) => {
    const step = processName === 'first-interview' ? 3 : 4;
    const endPoint = location?.pathname?.split('/')[4];
    const { rid } = recruitment;
    const { firstName, lastName, email } = currentCandidate;
    const newType = type === 'reject' ? 'evaluation' : '';
    const opinion = type === 'reject' ? 'Reject' : 'Giveup';

    executeAction(
      setCandidateOfRecruitment({
        aid: rid,
        cid: email,
        type: newType,
        candidateFirstName: firstName,
        candidateLastName: lastName,
        stage: endPoint,
        progressSubmit: {
          [step]: {
            opinion,
            updatedAt: new Date().getTime(),
            isSubmit: true,
            interviewSchedule: {
              isDone: true,
            },
          },
        },
      })
    )
      .unwrap()
      .then(() => {
        toast(t('toast.desc.you-comment-submitted'));
        setShowRejectModal(false);
      })
      .catch((e) => {
        console.error(e);
        setShowRejectModal(false);
      });
  };

  const checkedMovePosition = (candidate) => {
    let process = 0;

    if (candidate.progressSubmit && Object.keys(candidate?.progressSubmit).length - 1 === 2) {
      if (candidate?.movePosition && candidate?.recruitProgress === 2) {
        process = 1;
      } else {
        process = Object.keys(candidate?.progressSubmit).length - 1;
      }
    } else {
      process = Object.keys(candidate?.progressSubmit).length - 1;
    }

    return process;
  };

  useEffect(() => {
    if (!processLength) return;

    let filterd;

    if (processLength === 7) {
      if (processName === 'application') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 1
        );
      } else if (processName === 'aiInterview') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 2
        );
      } else if (processName === 'first-interview') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 3
        );
      } else if (processName === 'evidence') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 4
        );
      } else if (processName === 'offer') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 5
        );
      } else if (processName === 'submission') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 6
        );
      } else if (processName === 'reject') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === -1
        );
      }
    } else if (processLength === 8) {
      if (processName === 'application') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 1
        );
      } else if (processName === 'aiInterview') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 2
        );
      } else if (processName === 'first-interview') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 3
        );
      } else if (processName === 'second-interview') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 4
        );
      } else if (processName === 'evidence') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 5
        );
      } else if (processName === 'offer') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 6
        );
      } else if (processName === 'submission') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === 7
        );
      } else if (processName === 'reject') {
        filterd = getCandidatesSuccess?.filter(
          (item) => item?.recruitProgress === -1
        );
      }
    }
    if (filterd.length === 0) filterd = ['end'];
    setFilteredCandidates(filterd);
  }, [getCandidatesSuccess, processLength]);

  useEffect(() => {
    if (Object.keys(processSetting).length === 0 || !currentCandidate) return;
    setShowProcess(
      Object.keys(processSetting)[checkedMovePosition(currentCandidate)]
    );
  }, [processSetting]);

  useEffect(() => {
    if (filteredCandidates.length === 0) return;
    if (currentCandidateIdx >= 0) {
      if (filteredCandidates[currentCandidateIdx] === undefined) {
        setCurrentCandidateIdx(currentCandidateIdx - 1);
        setCurrentCandidate(filteredCandidates[currentCandidateIdx - 1]);
      } else if (filteredCandidates[currentCandidateIdx] === 'end')
        setCurrentCandidate(undefined);
      else setCurrentCandidate(filteredCandidates[currentCandidateIdx]);
    }
  }, [filteredCandidates, currentCandidateIdx]);

  useEffect(() => {
    if (filteredCandidates?.length === 0) return;
    setPrevCandidate(
      filteredCandidates[filteredCandidates?.indexOf(currentCandidate) - 1]
    );
    setNextCandidate(
      filteredCandidates[filteredCandidates?.indexOf(currentCandidate) + 1]
    );
  }, [currentCandidate, filteredCandidates]);

  const checkUserPermission = (progress) => {
    let userPlan = 'hr';

    if (userInfo.memberType === 15) userPlan = 'interviewer';
    else if (
      currentCandidate?.progressSubmit[progress]?.interviewer?.email ===
      userInfo.email
    ) {
      if (currentCandidate?.progressSubmit[progress]?.isSubmit) userPlan = 'hr';
      else userPlan = 'interviewer';
    }

    return userPlan;
  };

  return (
    <article className="modal-candidate-detail">
      <header className="header">
        <div className="header-wrapper">
          {currentCandidate && (
            <div
              className={`header-sub-title-left ${
                !prevCandidate && 'null'
              } fs14`}
              onClick={onShowPrevCandidate}
            >
              {prevCandidate
                ? nameConversionLanguage(
                    prevCandidate.firstName,
                    prevCandidate.lastName,
                    t('lang')
                  )
                : ' '}
            </div>
          )}
          {currentCandidate && (
            <h2 className="header-title fs28">
              {nameConversionLanguage(
                currentCandidate?.firstName,
                currentCandidate?.lastName,
                t('lang')
              )}
            </h2>
          )}
          {currentCandidate && (
            <div
              className={`header-sub-title-right ${
                !nextCandidate && 'null'
              } fs14`}
              onClick={onShowNextCandidate}
            >
              {nextCandidate
                ? nameConversionLanguage(
                    nextCandidate.firstName,
                    nextCandidate.lastName,
                    t('lang')
                  )
                : ' '}
            </div>
          )}
          <div className="header-close" onClick={() => dismissModal()} />
        </div>
      </header>
      {currentCandidate ? (
        <div className="container">
          <div className="tab-flex">
            <div className="tab-container">
              {Object.keys(processSetting).map((process, idx) => {
                return (
                  <div
                    key={process}
                    className={`tab-box fs14
                  ${idx > checkedMovePosition(currentCandidate) ? 'hidden' : ''}
                  ${showProcess === process ? 'selected' : ''}
                `}
                    onClick={() =>
                      setShowProcess(Object.keys(processSetting)[idx])
                    }
                  >
                    {t(processSetting[process].label)}
                  </div>
                );
              })}
            </div>
            {/* 
              조건식
              1. 해당 step에 interviewer라는 필드 값이 있는지 체크
              2. 해당 step에 interviewer의 이메일과 접속한 user의 email이 같은지 체크
              3. 해당 step에 interviewSchedule의 isDone이 true인지 체크
            */}
            {currentCandidate?.progressSubmit[currentCandidate?.recruitProgress]
              ?.interviewer &&
              currentCandidate?.progressSubmit[
                currentCandidate?.recruitProgress
              ]?.interviewer?.email === userInfo?.email &&
              !currentCandidate?.progressSubmit[
                currentCandidate?.recruitProgress
              ]?.interviewSchedule?.isDone && (
                <div className="interviewer-button">
                  {userInfo?.memberType !== 20 && (
                    <div className="flex">
                      <Button
                        theme={'blue'}
                        size={48}
                        label={t('btn.interview-date')}
                        onClick={createScheduleModal}
                      />
                      <Button
                        theme={'red'}
                        size={48}
                        label="면접포기"
                        onClick={() => rejectOpenModal('giveup')}
                      />

                      {Object.keys(
                        currentCandidate?.progressSubmit[
                          currentCandidate?.recruitProgress
                        ]?.interviewSchedule
                      ).length <= 0 ? (
                        <Button
                          theme={'red'}
                          size={48}
                          label={t('btn.reject')}
                          onClick={() => rejectOpenModal('reject')}
                        />
                      ) : (
                        <Button
                          theme={'borderBlue'}
                          size={48}
                          label="면접조율확정"
                          onClick={() => setOpenConfirmDate(true)}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
          </div>
          {showProcess === 'application' && (
            <ReviewingDetail
              recruitment={recruitment}
              currentCandidate={currentCandidate}
            />
          )}
          {showProcess === 'aiInterview' && (
            <VirturalDetail
              recruitment={recruitment}
              currentCandidate={currentCandidate}
              processLength={processLength}
            />
          )}
          {showProcess === 'firstInterview' &&
            (checkUserPermission(3) === 'hr' ? (
              <PersonInterviewHrDetail
                recruitment={recruitment}
                currentCandidate={currentCandidate}
                processName={showProcess}
                processLength={processLength}
                selectedHistory={selectedHistory}
                setSelectedHistory={setSelectedHistory}
              />
            ) : (
              <PersonInterviewDetail
                recruitment={recruitment}
                currentCandidate={currentCandidate}
                processName={showProcess}
                processLength={processLength}
                selectedHistory={selectedHistory}
                setSelectedHistory={setSelectedHistory}
              />
            ))}

          {showProcess === 'secondInterview' &&
            (checkUserPermission(4) === 'hr' ? (
              <PersonInterviewHrDetail
                recruitment={recruitment}
                currentCandidate={currentCandidate}
                processName={showProcess}
                processLength={processLength}
                selectedHistory={selectedHistory}
                setSelectedHistory={setSelectedHistory}
              />
            ) : (
              <PersonInterviewDetail
                recruitment={recruitment}
                currentCandidate={currentCandidate}
                processName={showProcess}
                processLength={processLength}
                selectedHistory={selectedHistory}
                setSelectedHistory={setSelectedHistory}
              />
            ))}
          {showProcess === 'evidence' && (
            <EvidenceDetail
              recruitment={recruitment}
              currentCandidate={currentCandidate}
              processLength={processLength}
            />
          )}
          {showProcess === 'pass' && (
            <OfferDetail
              recruitment={recruitment}
              currentCandidate={currentCandidate}
              processLength={processLength}
            />
          )}
        </div>
      ) : (
        <div className="no-candidate-container">
          <div className="no-candidate-image-box">
            <NoData />
          </div>
          <div className="no-candidate-warning-box">
            <div className="no-candidate-warning-text">
              {t('desc.no-more-candidates')}
            </div>
          </div>
        </div>
      )}

      {showRejectModal && (
        <ChangeStatusModal
          open={showRejectModal}
          onClickDisagree={() => setShowRejectModal(false)}
          onClickAgree={rejectSubmitHandler}
          main={
            rejectType === 'reject'
              ? t('dialog.title.reject')
              : t('dialog.title.giveup')
          }
          sub={t('dialog.desc.no-turning-back')}
          rejectType={rejectType}
        />
      )}

      {openConfirmDate && (
        <ConfirmInterviewDate
          open={openConfirmDate}
          close={setOpenConfirmDate}
          recruitment={recruitment}
          candidate={currentCandidate}
          ouid={userInfo?.ownerUid}
        />
      )}
    </article>
  );
};
export default CandidateDetail;
