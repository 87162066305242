import React, { useState, useEffect } from 'react';
import { ReactComponent as EyeIcon } from 'assets/images/icon/eye.svg';
import { useTranslation } from 'react-i18next';
import CommentInput from 'components/input/CommentInput';
import { useSelector } from 'react-redux';
import { Timestamp } from 'firebase/firestore';
import { executeAction } from 'utils/redux';
import { setCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { dateToDateFormat } from 'utils/string';
import { convertResumeType } from 'utils/convertKey';
import { Button } from 'components/common/Button';
import UploadCandidateFile from 'modals/recruitments/UploadCandidateFile';
import RecruitmentStatusTab from '../RecruitmentStatusTab';
import ReviewingFieldBox from './ReviewingFieldBox';

const ReviewingDetail = ({ recruitment, currentCandidate }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);
  const currentProgress = currentCandidate?.progressSubmit[1];

  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState('');
  const [savedComment, setSavedComment] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);

  useEffect(() => {
    if (!currentProgress?.comment) return;

    setCommentList(currentProgress?.comment);
  }, [currentCandidate]);

  useEffect(() => {
    if (!savedComment) return;

    executeAction(
      setCandidateOfRecruitment({
        aid: recruitment?.rid,
        cid: currentCandidate?.email,
        progressSubmit: {
          1: {
            comment: commentList,
          },
        },
      })
    )
      .then(() => {
        setSavedComment(false);
      })
      .catch((e) => {
        console.error(e);
        setSavedComment(false);
      });
  }, [commentList, currentCandidate]);

  const onChangeComment = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const commentSubmitHander = () => {
    if (!comment) return;

    const data = {
      comment,
      writer: `${userInfo?.firstName} ${userInfo?.lastName}`,
      date: Timestamp.now(),
      isShow: true,
    };

    setCommentList((prevData) => [...prevData, data]);
    setSavedComment(true);
    setComment('');
  };

  return (
    <>
    <div className="wrapper reviewing-detail">
      <div className="tagging">
        {currentCandidate?.applyCount > 1 ? (
          <p className="fs14">{t('field.duplication-apply')}</p>
        ) : (
          <p className="fs14">{t('field.new-applicant')}</p>
        )}

        {currentProgress?.KIV && <p className="fs14">KIV</p>}
      </div>
      <div className="reviewing-header">
        <div className="status-tab">
          {currentCandidate?.recruitProgress === 1 &&
            userInfo?.memberType !== 20 && (
              <RecruitmentStatusTab
                recruitment={recruitment}
                currentCandidate={currentCandidate}
              />
            )}
        </div>
        <div className="resume-list-section">
          <div className='apply-resume'><div className="fs16 apply-title">{t('field.supporing-document')}</div>
            {userInfo?.memberType <= 5 &&
              <Button size={26} label={t('btn.file-upload')} onClick={() => setShowUploadFileModal(true)}/>
            }
          </div>
          <ul className="resume-list">
            {currentProgress &&
              currentProgress.resumeArr.map((resume, idx) => (
                <li key={`${resume}_${idx}`}>
                  <span className="fs12">{convertResumeType(resume.type)}</span>
                  <a href={resume.url} className="fs12">
                    {resume.name}
                    <i>
                      <EyeIcon />
                    </i>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <ReviewingFieldBox
        rid={recruitment?.rid}
        currentProgress={currentProgress}
        email={currentCandidate?.email}
        createdAt={currentCandidate?.createdAt}
        count={currentCandidate?.applyCount}
      />
      {userInfo?.memberType !== 15 && (
        <section className="comment-container">
          <article className="comment-wrapper">
            <h3 className="fs16 apply-title">{t('text-field.label.memo')}</h3>
            <ul>
              {commentList?.length !== 0 &&
                commentList?.map((comment, idx) => (
                  <li key={`comment${idx}`}>
                    <aside className="comment">
                      <pre className="fs14">{comment?.comment}</pre>
                    </aside>
                    <aside className="write-info">
                      <div className="writer">
                        <span className="title fs14">{t('title.writer')}</span>
                        <p className="fs12">{comment?.writer}</p>
                      </div>
                      <div className="date">
                        <span className="title fs14">{t('title.date')}</span>
                        <p className="fs12">
                          {dateToDateFormat(
                            new Date(comment?.date?.seconds * 1000),
                            t('lang')
                          )}
                        </p>
                      </div>
                    </aside>
                  </li>
                ))}
            </ul>
            {userInfo?.memberType !== 20 && (
              <CommentInput
                value={comment}
                onChange={onChangeComment}
                hasButton
                placeholder={`| ${t('text-field.placeholder.write-memo')}`}
                onClick={commentSubmitHander}
              />
            )}
          </article>
        </section>
      )}
    </div>
      {showUploadFileModal &&
        <div className="modal-wrapper">
        <UploadCandidateFile onDismissHandler={() => setShowUploadFileModal(false)} progressSubmit={currentProgress}
                            rid={recruitment.rid} cid={currentCandidate.id} /></div>}
  </>
);
};

export default ReviewingDetail;
