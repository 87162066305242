import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from '@mui/material';
import MaterialInput from 'components/input/MaterialInput';
import { ReactComponent as SearchIcon } from 'assets/images/icon/search.svg';
import { ReactComponent as ArrowBotton } from 'assets/images/icon/arrow-down.svg';
import MaterialSelect from 'components/select/MaterialSelect';
import {
  writeTextToClipboard,
  dateToDateFormat,
  displayName,
} from 'utils/string';
import { useAuth } from 'utils/auth';
import { useModal } from 'utils/modal';
import { useStatus } from 'utils/status';
import { useOutletContext } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  getCandidatesOfAssessment,
  sendEmailToCandidate,
  sendEmailReview,
  sendEmailReject,
  sendEmailPass,
  sendEmailRemind,
  getLibTestInfos,
  deleteCandidateOfAssesssment,
  getAssessment,
  setOBPIBenchmarkToAssessment,
  getScoreResult,
  pass,
  review,
  reject,
} from 'store/slices/assessmentsSlice';
import { getCandidateInAssessment } from 'store/slices/candidatesSlice';
import { toast } from 'utils/notify';
import { ReactComponent as UpIcon } from 'assets/images/icon/north.svg';
import { ReactComponent as DownIcon } from 'assets/images/icon/south.svg';
import { CircularProgress } from '@mui/material';
import { ProgressScore } from 'components/progress/Score';
import { useAverageScore, useCheckedAdminPage } from 'utils/hooks';
import { ReactComponent as GradeS } from 'assets/images/icon/GradeS.svg';
import { ReactComponent as GradeA } from 'assets/images/icon/GradeA.svg';
import { ReactComponent as GradeB } from 'assets/images/icon/GradeB.svg';
import { ReactComponent as GradeC } from 'assets/images/icon/GradeC.svg';
import { ReactComponent as GradeD } from 'assets/images/icon/GradeD.svg';
import {
  getKHAI_P_Grade,
  getKHAIPColor,
  getKhaiO_Reliability,
} from 'utils/khaifunc';
import { useSelector } from 'react-redux';
import { convertGradeToScore } from 'utils/converter';

const __TABLE_ACTIONS__ = [
  {
    key: 'copy-assessment-link',
    i18nKey: 'actions.menu.copy-assessment-link',
    onClick: (
      {
        t,
        showDropdown,
        setShowDropdown,
        setIsEntireLoading,
        uid,
        aid,
        candidates,
      },
      callback
    ) => {
      setShowDropdown(-1);
      setIsEntireLoading(true);
      writeTextToClipboard(
        `${process.env.REACT_APP_ASSESSMENT_HOST}/testtaker/auth/redirect?type=takeinvitation&uid=${uid}&aid=${aid}&email=${candidates?.[showDropdown]?.email}&firstName=${candidates?.[showDropdown]?.firstName}&lastName=${candidates?.[showDropdown]?.lastName}`
      )
        .then(() => {
          setIsEntireLoading(false);
          toast(t('toast.desc.copy-link'));
        })
        .catch((e) => {
          console.dir(e);
          setIsEntireLoading(false);
        });
    },
  },
  {
    key: 'resend-email-invite',
    i18nKey: 'actions.menu.resend-email-invite',
    onClick: (
      {
        t,
        showDropdown,
        setShowDropdown,
        setIsEntireLoading,
        aid,
        candidates,
        assessmentInfo,
      },
      callback
    ) => {
      const candidate = candidates?.[showDropdown];
      setShowDropdown(-1);
      setIsEntireLoading(true);
      executeAction(
        sendEmailToCandidate({
          type: 'resend',
          aid,
          firstName: candidate?.firstName,
          lastName: candidate?.lastName,
          email: candidate?.email || candidate?.id,
          lang: assessmentInfo?.language,
        })
      )
        .unwrap()
        .then(() => {
          setIsEntireLoading(false);
          toast(t('toast.desc.success-invite'));
        })
        .catch((e) => {
          console.dir(e);
          setIsEntireLoading(false);
        });
    },
  },
  {
    key: 'send-email-remind',
    i18nKey: 'actions.menu.send-email-remind',
    onClick: (
      {
        t,
        showDropdown,
        setShowDropdown,
        setIsEntireLoading,
        aid,
        displayModal,
        candidates,
        assessmentInfo,
      },
      callback
    ) => {
      const candidate = candidates?.[showDropdown];
      const eventHandler = (event) => {
        if (event.detail?.message === 'send-email-remind') {
          setIsEntireLoading(true);
          executeAction(
            sendEmailRemind({
              aid,
              email: candidate?.email || candidate?.id,
              firstName: candidate?.firstName,
              lastName: candidate?.lastName,
              lang: assessmentInfo?.language,
            })
          )
            .unwrap()
            .then(() => {
              setIsEntireLoading(false);
              toast(t('toast.desc.sent-email'));
              callback && callback();
            })
            .catch((e) => {
              console.dir(e);
              setIsEntireLoading(false);
              callback && callback();
            });
        }
        window.document.removeEventListener('dismissModal', eventHandler);
      };
      setShowDropdown(-1);
      window.document.addEventListener('dismissModal', eventHandler);
      displayModal(
        'common/AlertModal',
        {
          event: {
            message: 'send-email-remind',
          },
          title: {
            main: t('dialog.title.remind-candidate'),
            sub: t('dialog.desc.remind-candidate'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    },
  },
  {
    key: 'send-email-pass',
    i18nKey: 'actions.menu.send-email-pass',
    onClick: (
      {
        t,
        showDropdown,
        setShowDropdown,
        setIsEntireLoading,
        aid,
        displayModal,
        candidates,
        assessmentInfo,
      },
      callback
    ) => {
      const candidate = candidates?.[showDropdown];
      const eventHandler = (event) => {
        if (event.detail?.message === 'send-email-pass') {
          setIsEntireLoading(true);
          executeAction(
            sendEmailPass({
              aid,
              email: candidate?.email || candidate?.id,
              firstName: candidate?.firstName,
              lastName: candidate?.lastName,
              lang: assessmentInfo?.language,
            })
          )
            .unwrap()
            .then(() => {
              setIsEntireLoading(false);
              toast(t('toast.desc.sent-email'));
              callback && callback();
            })
            .catch((e) => {
              console.dir(e);
              setIsEntireLoading(false);
              callback && callback();
            });
        }
        window.document.removeEventListener('dismissModal', eventHandler);
      };
      setShowDropdown(-1);
      window.document.addEventListener('dismissModal', eventHandler);
      displayModal(
        'common/AlertModal',
        {
          event: {
            message: 'send-email-pass',
          },
          title: {
            main: t('dialog.title.pass-candidate'),
            sub: t('dialog.desc.pass-candidate'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    },
  },
  {
    key: 'send-email-review',
    i18nKey: 'actions.menu.send-email-review',
    onClick: (
      {
        t,
        showDropdown,
        setShowDropdown,
        setIsEntireLoading,
        aid,
        displayModal,
        candidates,
        assessmentInfo,
      },
      callback
    ) => {
      const candidate = candidates?.[showDropdown];
      const eventHandler = (event) => {
        if (event.detail?.message === 'send-email-review') {
          setIsEntireLoading(true);
          executeAction(
            sendEmailReview({
              aid,
              email: candidate?.email || candidate?.id,
              firstName: candidate?.firstName,
              lastName: candidate?.lastName,
              lang: assessmentInfo?.language,
            })
          )
            .unwrap()
            .then(() => {
              setIsEntireLoading(false);
              toast(t('toast.desc.sent-email'));
              callback && callback();
            })
            .catch((e) => {
              console.dir(e);
              setIsEntireLoading(false);
              callback && callback();
            });
        }
        window.document.removeEventListener('dismissModal', eventHandler);
      };
      setShowDropdown(-1);
      window.document.addEventListener('dismissModal', eventHandler);
      displayModal(
        'common/AlertModal',
        {
          event: {
            message: 'send-email-review',
          },
          title: {
            main: t('dialog.title.review-candidate'),
            sub: t('dialog.desc.review-candidate'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    },
  },
  {
    key: 'send-email-reject',
    i18nKey: 'actions.menu.send-email-reject',
    onClick: (
      {
        t,
        showDropdown,
        setShowDropdown,
        setIsEntireLoading,
        aid,
        displayModal,
        candidates,
        assessmentInfo,
      },
      callback
    ) => {
      const candidate = candidates?.[showDropdown];
      const eventHandler = (event) => {
        if (event.detail?.message === 'send-email-reject') {
          setIsEntireLoading(true);
          executeAction(
            sendEmailReject({
              aid,
              email: candidate?.email || candidate?.id,
              firstName: candidate?.firstName,
              lastName: candidate?.lastName,
              lang: assessmentInfo?.language,
            })
          )
            .unwrap()
            .then(() => {
              setIsEntireLoading(false);
              toast(t('toast.desc.sent-email'));
              callback && callback();
            })
            .catch((e) => {
              console.dir(e);
              setIsEntireLoading(false);
              callback && callback();
            });
        }
        window.document.removeEventListener('dismissModal', eventHandler);
      };
      setShowDropdown(-1);
      window.document.addEventListener('dismissModal', eventHandler);
      displayModal(
        'common/AlertModal',
        {
          event: {
            message: 'send-email-reject',
          },
          title: {
            main: t('dialog.title.reject-candidate'),
            sub: t('dialog.desc.reject-candidate'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    },
  },
  {
    key: 'delete-candidate',
    i18nKey: 'actions.menu.delete-candidate',
    onClick: (
      {
        t,
        showDropdown,
        setShowDropdown,
        setIsEntireLoading,
        aid,
        displayModal,
        candidates,
      },
      callback
    ) => {
      const candidate = candidates?.[showDropdown];
      const eventHandler = (event) => {
        if (event.detail?.message === 'delete-candidate') {
          setIsEntireLoading(true);
          executeAction(
            deleteCandidateOfAssesssment({
              aid,
              email: candidate?.email || candidate?.id,
            })
          )
            .unwrap()
            .then(() => {
              setIsEntireLoading(false);
              callback && callback();
            })
            .catch((e) => {
              console.dir(e);
              setIsEntireLoading(false);
              callback && callback();
            });
        }
        window.document.removeEventListener('dismissModal', eventHandler);
      };
      setShowDropdown(-1);
      window.document.addEventListener('dismissModal', eventHandler);
      displayModal(
        'common/AlertModal',
        {
          event: {
            message: 'delete-candidate',
          },
          title: {
            main: t('dialog.title.delete-candidate'),
            sub: t('dialog.desc.delete-candidate'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    },
  },
];

const __ASSESSMENTS_STAGE__ = [
  {
    key: 'under-investigation',
    i18nKey: 'text.hiring-stage.under-investigation',
    onClick: (
      {
        stageShowDropdown,
        setStageShowDropdown,
        setIsEntireLoading,
        aid,
        candidates,
      },
      callback
    ) => {
      const candidate = candidates?.[stageShowDropdown];
      const eventHandler = () => {
        setIsEntireLoading(true);
        executeAction(
          review({
            aid,
            email: candidate?.email,
          })
        )
          .unwrap()
          .then(() => {
            setIsEntireLoading(false);
            callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
            callback && callback();
          });
      };
      setStageShowDropdown(-1);
      eventHandler();
    },
  },
  {
    key: 'accept',
    i18nKey: 'text.hiring-stage.accept',
    onClick: (
      {
        stageShowDropdown,
        setStageShowDropdown,
        setIsEntireLoading,
        aid,
        candidates,
      },
      callback
    ) => {
      const candidate = candidates?.[stageShowDropdown];
      const eventHandler = () => {
        setIsEntireLoading(true);
        executeAction(
          pass({
            aid,
            email: candidate?.email,
          })
        )
          .unwrap()
          .then(() => {
            setIsEntireLoading(false);
            callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
            callback && callback();
          });
      };
      setStageShowDropdown(-1);
      eventHandler();
    },
  },
  {
    key: 'reject',
    i18nKey: 'text.hiring-stage.reject',
    onClick: (
      {
        stageShowDropdown,
        setStageShowDropdown,
        setIsEntireLoading,
        aid,
        candidates,
      },
      callback
    ) => {
      const candidate = candidates?.[stageShowDropdown];
      const eventHandler = () => {
        setIsEntireLoading(true);
        executeAction(
          reject({
            aid,
            email: candidate?.email,
          })
        )
          .unwrap()
          .then(() => {
            setIsEntireLoading(false);
            callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
            callback && callback();
          });
      };
      setStageShowDropdown(-1);
      eventHandler();
    },
  },
];

const OBPI_TESTS = [
  {
    category: 'fellowship',
    title: 'title.fellowship',
    left: 'desc.fellowship-left',
    right: 'desc.fellowship-right',
  },
  {
    category: 'authority',
    title: 'title.authority',
    left: 'desc.authority-left',
    right: 'desc.authority-right',
  },
  {
    category: 'conformity',
    title: 'title.conformity',
    left: 'desc.conformity-left',
    right: 'desc.conformity-right',
  },
  {
    category: 'emotion',
    title: 'title.emotion',
    left: 'desc.emotion-left',
    right: 'desc.emotion-right',
  },
  {
    category: 'detail',
    title: 'title.detail',
    left: 'desc.detail-left',
    right: 'desc.detail-right',
  },
  {
    category: 'proficiency',
    title: 'title.proficiency',
    left: 'desc.proficiency-left',
    right: 'desc.proficiency-right',
  },
  {
    category: 'work-orientation',
    title: 'title.work-orientation',
    left: 'desc.work-orientation-left',
    right: 'desc.work-orientation-right',
  },
  {
    category: 'patience',
    title: 'title.patience',
    left: 'desc.patience-left',
    right: 'desc.patience-right',
  },
  {
    category: 'fair-mindedness',
    title: 'title.fair-mindedness',
    left: 'desc.fair-mindedness-left',
    right: 'desc.fair-mindedness-right',
  },
  {
    category: 'loyalty',
    title: 'title.loyalty',
    left: 'desc.loyalty-left',
    right: 'desc.loyalty-right',
  },
  {
    category: 'disclosure',
    title: 'title.disclosure',
    left: 'desc.disclosure-left',
    right: 'desc.disclosure-right',
  },
  {
    category: 'initiative',
    title: 'title.initiative',
    left: 'desc.initiative-left',
    right: 'desc.initiative-right',
  },
];

const __OBPI_RESULT_NUM__ = 9;
const __OBPI_LIST_NUM__ = 12;
const __MAX_OBPI_MATCH_SCORE = 8;

const __GROUP_TEST_TIME__ = 10;

const Content = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { displayModal, dismissModal } = useModal();
  const { setIsEntireLoading } = useStatus();
  const { userInfo } = useSelector((state) => state.auth);
  const [aid] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [_cid, _setCid] = React.useState();
  const [isRefreshing, setIsRefreshing] = React.useState(true);
  const [refreshing, setRefreshing] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [hiringStage, setHiringStage] = React.useState('');
  const [showDropdown, setShowDropdown] = React.useState(-1);
  const [stageShowDropdown, setStageShowDropdown] = React.useState(-1);
  const [assessment, setAssessment] = React.useState({});
  const [assessmentInfo, setAssessmentInfo] = React.useState({});
  const [__candidates, __setCandidates] = React.useState([]);
  const [_candidates, _setCandidates] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [obpiBenchmark, setOBPIBenchmark] = React.useState({});
  const [objectiveScores, setObjectiveScores] = React.useState({});
  const [hasOBPI, setHasOBPI] = React.useState(false);
  const [hasObjectivetest, setHasObjectivetests] = React.useState(false);
  const [hasLibrarytest, setHasLibrarytests] = React.useState(false);
  const [libraryTests, setLibraryTests] = React.useState([]);
  const [libraryDetail, setLibraryDetail] = React.useState([]);
  const [sortType, setSortType] = React.useState('invited');
  const [sortOrder, setSortOrder] = React.useState('desc');
  const [subjectiveTests, setSubjectiveTests] = React.useState([]);
  const [answer, setAnswer] = React.useState(null);
  const [subjectiveTestsAverageScore, setSubjectiveTestsAverageScore] =
    React.useState(0);
  const [subjectiveScoreStore, setSubjectiveScoreStore] = React.useState(0);
  const [subjectivePresetScoreStore, setSubjectivePresetScoreStore] =
    React.useState(0);
  const [hasKhaiP, setHasKhaiP] = React.useState(false);
  const [hasKhaiO, setHasKhaiO] = React.useState(false);
  const [hasEnglishTests, setHasEnglishTests] = React.useState(false);
  const [hasChineseTests, setHasChineseTests] = React.useState(false);
  const [hasJapaneseTests, setHasJapaneseTests] = React.useState(false);
  const [hasKoreanTests, setHasKoreanTests] = React.useState(false);
  const checkedAdmin = useCheckedAdminPage();

  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const lang = t('lang');

  const dropdownRef = React.useRef(null);
  const stageDropdownRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const rowRefs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];

  // get assessment info
  React.useEffect(() => {
    if (!aid || Object.keys(userInfo).length === 0) return;

    executeAction(
      getAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        doc?.tests?.forEach((test) => {
          if (test?.id === '1321' && lang === 'ko') {
            setHasKhaiP(true);
          }
          if (test?.id === '1322' && lang === 'ko') {
            setHasKhaiO(true);
          }
        });
        setAssessmentInfo(doc || {});
        if (doc.obpiBenchmark) {
          setOBPIBenchmark(doc.obpiBenchmark);
        }
      })
      .catch((e) => {
        console.dir(e);
        setAssessmentInfo({});
      });
  }, [aid, userInfo]);

  React.useEffect(() => {
    if (isRefreshing) {
      __setCandidates([]);
      return setIsRefreshing(false);
    }

    setIsLoading(true);
    executeAction(
      getCandidatesOfAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ docs }) => {
        (docs || []).forEach((doc, idx) => {
          const cid = doc?.id;
          _setCid(cid);
          const { tests } = assessmentInfo.tests || {};
          const newObjectiveTests = new Array();
          const newSubjectiveTests = new Array();
          const newLibraryTests = new Array();
          const newLanguageTests = new Array();

          var newOBPIResult;
          var obpiMatchScore = -1;

          (assessmentInfo.tests || []).forEach((test, idx) => {
            if (test?.testcnt > 0 || test?.skills?.length > 0) {
              (test?.skills || []).forEach((skill) => {
                if (doc?.results?.[skill.uuid]) {
                  newObjectiveTests.push(skill);
                  setHasObjectivetests(true);
                }
              });
              return;
            }
            const { section } = test;
            if (
              section === 'video' ||
              section === 'essay' ||
              section === 'multiple-choice' ||
              section === 'file-upload' ||
              section === 'spreadsheets' ||
              section === 'code'
            ) {
              newSubjectiveTests.push(test);
            } else {
              // tid가 1310이고 lang가 ko일 때만 OBPI이다.
              if (test?.id === '1310' && lang === 'ko') {
                // if(test?.title_en?.indexOf('OBPI') >= 0) {
                newOBPIResult = doc?.results?.obpi;
                setHasOBPI(true);
                if (obpiBenchmark?.obpiResult) {
                  var scores = 0;
                  OBPI_TESTS.forEach((category) => {
                    const benchmark = obpiBenchmark?.obpiResult?.traits?.find(
                      (x) => x.scale === category.category
                    );
                    const curOBPI = newOBPIResult?.traits?.find(
                      (x) => x.scale === category.category
                    );
                    scores +=
                      __MAX_OBPI_MATCH_SCORE -
                      Math.abs(benchmark?.stanine - curOBPI?.stanine);
                  });
                  obpiMatchScore = parseFloat(
                    (
                      scores /
                      (__MAX_OBPI_MATCH_SCORE * __OBPI_LIST_NUM__)
                    ).toFixed?.(2)
                  );
                }
                return;
              }
              if (
                test?.question_type?.indexOf('tag.subjective') >= 0 ||
                test?.question_type?.indexOf('tag.code') >= 0 ||
                test?.question_type?.indexOf('tag.video') >= 0
              ) {
                newLibraryTests.push(test);
                setHasLibrarytests(true);
              } else if (test?.question_type?.indexOf('tag.language') >= 0) {
                newLanguageTests.push(test);
              } else {
                if (
                  (test?.id === '1310' && lang === 'ko') ||
                  (test?.id === '1321' && lang === 'ko') ||
                  (test?.id === '1322' && lang === 'ko')
                )
                  return;

                newObjectiveTests.push(test);
                setHasObjectivetests(true);
              }
            }
          });
          const results = doc?.results;
          const _objectiveScores = {};
          let score = 0;
          let cnt = 0;

          for (const skill of newObjectiveTests) {
            const suuid = skill?.uuid;
            const result = results?.[suuid];
            const lang2 = assessmentInfo?.language;

            const answers = skill.question;

            const temp = new Array();
            for (const [key, val] of Object.entries(result || {})) {
              const tid = key;
              const answer = answers.find((x) => x.tid === tid);
              if (answer?.testInfo?.correct.length > 1) {
                let isCorrect = true;
                answer?.testInfo?.correct.forEach((cor, index) => {
                  isCorrect = isCorrect && cor === val?.[0][index];
                });
                temp.push(true);
              } else {
                temp.push(answer?.testInfo?.correct?.[0] === val?.[0]);
              }
            }
            let _score = 0;
            if (temp.length)
              _score = temp.filter((x) => x).length / temp.length;
            _objectiveScores[suuid] = _score;

            if (skill?.id !== '71') {
              // skill.id === 2 : Culture fit.
              if (_score >= 0 && _score <= 1) {
                score += _score;
                cnt += 1;
              }
            }
          }

          // 언어테스트 언어별 세팅
          let englishResult = {};
          let chineseResult = {};
          let japaneseResult = {};
          let koreanResult = {};
          const noneResult = {
            listening: { grade: 'none' },
            reading: { grade: 'none' },
            speaking: { grade: 'none' },
          };
          if (newLanguageTests.length !== 0) {
            const enResult = results?.english;
            const chResult = results?.chinese;
            const jaResult = results?.japanese;
            const krResult = results?.korean;

            if (enResult !== undefined) {
              englishResult = enResult;
              setHasEnglishTests(true);
            } else {
              englishResult = noneResult;
            }

            if (chResult !== undefined) {
              chineseResult = chResult;
              setHasChineseTests(true);
            } else {
              chineseResult = noneResult;
            }

            if (jaResult !== undefined) {
              japaneseResult = jaResult;
              setHasJapaneseTests(true);
            } else {
              japaneseResult = noneResult;
            }

            if (krResult !== undefined) {
              koreanResult = krResult;
              setHasKoreanTests(true);
            } else {
              koreanResult = noneResult;
            }
          }

          docs[idx].subjectiveTests = newSubjectiveTests;
          docs[idx].objectiveTests = newObjectiveTests;
          docs[idx].libraryTests = newLibraryTests;
          docs[idx].obpiResult = newOBPIResult;
          docs[idx].obpiMatchScore = obpiMatchScore;
          docs[idx].objectiveScores = _objectiveScores;
          docs[idx].libarayAverageScore = getLibrayAverageScore(doc);
          docs[idx].presetAverageScore = getLibrayAveragePresetScore(doc);
          docs[idx].subjectAverageScore = getAverageSubjectScore(doc);
          docs[idx].totalAverageScore = getTotalScore(doc);
          docs[idx].englishTotalScore = getLanguageTotalGrade(englishResult);
          docs[idx].chineseTotalScore = getLanguageTotalGrade(chineseResult);
          docs[idx].japaneseTotalScore = getLanguageTotalGrade(japaneseResult);
          docs[idx].koreanTotalScore = getLanguageTotalGrade(koreanResult);

          setObjectiveScores(_objectiveScores);
          setSubjectiveTests(newSubjectiveTests || []);

          if (cnt === 0) {
            docs[idx].AverageObjectiveScore = 0;
          } else {
            docs[idx].AverageObjectiveScore = parseFloat(
              (score / cnt)?.toFixed?.(2)
            );
          }
        });

        // Khai P점수가 있으면 그 점수로 정렬 후 등수를 정한다.
        (docs || []).sort((a, b) => {
          if (
            Boolean(b.results?.['Khai-P']?.result?.total?.score) &&
            Boolean(a.results?.['Khai-P']?.result?.total?.score)
          ) {
            return (
              b.results?.['Khai-P']?.result?.total?.score -
              a.results?.['Khai-P']?.result?.total?.score
            );
          }
          if (
            !b.results?.['Khai-P']?.result?.total?.score &&
            Boolean(a.results?.['Khai-P']?.result?.total?.score)
          ) {
            return -1;
          }
          if (
            Boolean(b.results?.['Khai-P']?.result?.total?.score) &&
            !a.results?.['Khai-P']?.result?.total?.score
          ) {
            return 1;
          }
          return 0;
        });

        (docs || []).forEach((doc, idx) => {
          if (doc.results?.['Khai-P']?.result) {
            docs[idx].results['Khai-P'].result.rank = idx + 1;
          }
        });

        (docs || []).sort((a, b) => {
          if (a.updatedAt < b.updatedAt) {
            return 1;
          }
          if (a.updatedAt > b.updatedAt) {
            return -1;
          }
          if (a.updatedAt === undefined && Boolean(b.updatedAt)) {
            return 1;
          }
          if (b.updatedAt === undefined && Boolean(a.updatedAt)) {
            return -1;
          }

          return 0;
        });
        // 프리셋 점수 초기 sort 설정
        (docs || []).sort(
          (b, a) => a.presetAverageScore - b.presetAverageScore
        );
        __setCandidates(docs || []);
        setIsLoading(false);
      })
      .catch((e) => {
        __setCandidates([]);
        setIsLoading(false);
        console.dir(e);
      });
  }, [aid, isRefreshing, assessmentInfo]);

  React.useEffect(() => {
    if (_cid === undefined) return;

    if (refreshing) {
      setIsEntireLoading(true);
      executeAction(
        getCandidateInAssessment({
          aid,
          cid: _cid,
        })
      )
        .unwrap()
        .then(({ doc }) => {
          setAnswer(doc.results);
          setIsEntireLoading(false);
          setRefreshing(false);
        })
        .catch((e) => {
          console.dir(e);
          setIsEntireLoading(false);
          setRefreshing(false);
        });
    }
  }, [aid, _cid, refreshing]);

  /** ======================================
     *        맞춤질문 평균 스코어 계산 함수
     ======================================= */
  React.useEffect(() => {
    if (!Object.keys(candidates || {}).length) return;

    candidates?.map((candidate, index) => {
      candidateResultSetting(candidate);
    });
  }, [_cid, candidates, subjectiveTests]);

  /** ----------------------------------------------------------
     * @param {*} candidate : object => candidates 각각의 candidate
     * @returns
     -----------------------------------------------------------*/
  const candidateResultSetting = (candidate) => {
    candidate.subjectiveTests?.map((test) => {
      subjectiveAnswer(test, candidate);
    });
  };

  /** --------------------------------------------------------
     * @param {*} test : object => subjectiveTest 각각의 테스트 정보
     * @param {*} candidate : object => candidates 각각의 응시자 정보
     * @returns 
     ----------------------------------------------------------*/
  const subjectiveAnswer = (test, candidate) => {
    const answerCheck = Object.values(
      candidate.results?.[test?.uuid || test?.id]?.checks || {}
    )[0];
    const correctCheck = Object.values(test?.answers?.correct || [])[0];

    const scores = new Array();
    if (test.section === 'multiple-choice') {
      if (answerCheck !== correctCheck) {
        /** ---------------------------------
                 * 응시자 초대만 했을 때 클릭 시 오류 분기처리
                 -----------------------------------*/
        if (candidate.results !== undefined) {
          if (!candidate?.results[test.uuid || test.id]) return;
          candidate.results[test.uuid || test.id].score = 0;
        } else {
          return;
        }

        executeAction(
          getScoreResult({
            aid,
            cid: _cid,
            score: 0,
            tuuid: test?.uuid || test?.id,
          })
        )
          .unwrap()
          .then(() => {
            dismissModal();
          })
          .catch((e) => {
            console.dir(e);
          });
      } else {
        if (candidate.results !== undefined) {
          candidate.results[test.uuid || test.id].score = 1;
        } else {
          return;
        }

        executeAction(
          getScoreResult({
            aid,
            cid: _cid,
            score: 1,
            tuuid: test?.uuid || test?.id,
          })
        )
          .unwrap()
          .then(() => {
            dismissModal();
          })
          .catch((e) => {
            console.dir(e);
          });
      }

      const score = candidate.results?.[test.uuid || test.id]?.score;
      if (score) scores.push(score);
    } else {
      const score = candidate.results?.[test.uuid || test.id]?.score;
      if (score) scores.push(score);
    }

    const average = scores.reduce((a, b) => a + b, 0) / subjectiveTests.length;
    setSubjectiveTestsAverageScore(average || 0);
  };

  // display datas change.
  React.useEffect(() => {
    const startIdx = page * rowsPerPage;

    const docs = _candidates.slice(startIdx, startIdx + rowsPerPage);

    setCandidates(_candidates.slice(startIdx, startIdx + rowsPerPage));
  }, [_candidates, page, rowsPerPage, obpiBenchmark]);

  React.useEffect(() => {
    const tasks = new Array();
    const _libDetails = {};
    for (const test of libraryTests) {
      tasks.push(
        executeAction(getLibTestInfos({ tids: test?.test, lang: t('lang') }))
          .unwrap()
          .then((docs) => {
            const details = new Array();
            test?.question?.forEach((question, idx) => {
              if (question?.selected === true) {
                details.push(docs[idx]);
              }
            });

            _libDetails[test.id] = details;
          })
          .catch((e) => {
            console.dir(e);
          })
      );
    }

    Promise.allSettled(tasks)
      .then(() => {
        setLibraryDetail(_libDetails);
      })
      .catch((e) => {
        console.dir(e);
        setLibraryDetail({});
      });
  }, [libraryTests]);

  React.useEffect(() => {
    const handler = (event) => {
      for (const rowRef of rowRefs) {
        if (rowRef.current) {
          const moreElem = rowRef.current.querySelector('.more-icon');
          if (moreElem.contains(event.target)) return;
        }
      }

      if (!dropdownRef.current || dropdownRef.current.contains(event.target))
        return;

      setShowDropdown(-1);
    };

    window.document.addEventListener('mousedown', handler);
    window.document.addEventListener('touchstart', handler);

    return () => {
      window.document.removeEventListener('mousedown', handler);
      window.document.removeEventListener('touchstart', handler);
    };
  }, [dropdownRef, rowRefs]);

  React.useEffect(() => {
    const handler = (event) => {
      for (const rowRef of rowRefs) {
        if (rowRef.current) {
          const stageElem = rowRef.current.querySelector('.stage');
          if (stageElem.contains(event.target)) return;
        }
      }

      if (
        !stageDropdownRef.current ||
        stageDropdownRef.current.contains(event.target)
      )
        return;

      setStageShowDropdown(-1);
    };

    window.document.addEventListener('mousedown', handler);
    window.document.addEventListener('touchstart', handler);

    return () => {
      window.document.removeEventListener('mousedown', handler);
      window.document.removeEventListener('touchstart', handler);
    };
  }, [stageDropdownRef, rowRefs]);

  React.useEffect(() => {
    if (
      !obpiBenchmark?.obpiResult ||
      obpiBenchmark === assessmentInfo.obpiBenchmark
    ) {
      return;
    }

    executeAction(
      setOBPIBenchmarkToAssessment({
        aid,
        obpiBenchmark,
      })
    )
      .unwrap()
      .then((docs) => {})
      .catch((e) => {
        console.dir(e);
      });
  }, [obpiBenchmark]);

  React.useEffect(() => {
    let candidatesCopy = (__candidates || []).slice(0);

    candidatesCopy = candidatesCopy.filter((x) => {
      const fullNameConverted1 = (x.firstName + x.lastName)
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      const fullNameConverted2 = (x.lastName + x.firstName)
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      const searchConverted = (search || '')
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();

      if (fullNameConverted1.includes(searchConverted)) return true;
      if (fullNameConverted2.includes(searchConverted)) return true;
      return false;
    });

    if (!hiringStage) {
      return _setCandidates(candidatesCopy || []);
    }
    let hiringStep = -1;
    switch (hiringStage) {
      case 'all':
        return _setCandidates(candidatesCopy || []);
      case 'sended-email':
        hiringStep = 0;
        break;
      case 'wirte-done':
        hiringStep = 1;
        break;
      case 'under-investigation':
        hiringStep = 2;
        break;
      case 'reject':
        hiringStep = 3;
        break;
      case 'accept':
        hiringStep = 4;
        break;
    }
    _setCandidates(
      candidatesCopy.filter((x) => x?.progress === hiringStep) || []
    );
    // TODO: add the initialStep.
  }, [__candidates, search, hiringStage, sortOrder, sortType]);

  const getLibrayAverageScore = (candidate) => {
    let testcnt = 0;
    let score = [];
    candidate?.libraryTests?.forEach((lib) => {
      lib?.question?.forEach((tmp) => {
        if (tmp?.section === 'styling') {
          let result = 0;
          result += candidate?.results?.[tmp.id]?.originalityScore;
          result += candidate?.results?.[tmp.id]?.fashionScore;
          result += candidate?.results?.[tmp.id]?.fitScore;

          score.push(Number((result / 3).toFixed(2)) || 0);
          testcnt += 1;
        } else if (tmp?.id === '99000100001') {
          let result = 0;
          result += candidate?.results?.[tmp.id]?.communicationScore;
          result += candidate?.results?.[tmp.id]?.fashionScore;
          result += candidate?.results?.[tmp.id]?.fitScore;

          score.push(Number((result / 3).toFixed(2)) || 0);
          testcnt += 1;
        } else {
          if (candidate?.results?.[tmp.id] === undefined) return;
          let result = 0;
          result += candidate?.results?.[tmp.id]?.score;

          if (result) {
            score.push(result || 0);
            testcnt += 1;
          }
        }
      });
    });

    if (score.length === 0) return 0;
    if (score.length >= 2) {
      return testcnt !== 0
        ? score.reduce((a, b) => a + b, 0) / score.length
        : 0;
    }
    return testcnt !== 0 ? score.reduce((a, b) => a + b, 0) / testcnt : 0;
  };

  /** ==========
   * @function getLanguageTotalGrade
   * 언어테스트 결과 등급에 따라 score로 변환 해주는 함수
   * @param {*} langObj (object)
   * @returns 
  ============== */
  const getLanguageTotalGrade = (langObj) => {
    if (Object.keys(langObj).length === 0) return;

    const gradeArr = [];
    (Object.values(langObj) || []).map((val) => {
      if (!val) return;
      gradeArr.push(val.grade || 'Prepare');
    });

    if (gradeArr.length === 0) return;
    const gradeScore = [];

    (gradeArr || []).map((grade) => {
      if (grade === 'Advanced') {
        gradeScore.push(3);
      } else if (grade === 'Intermediate') {
        gradeScore.push(2);
      } else if (grade === 'Beginner') {
        gradeScore.push(1);
      } else {
        gradeScore.push(0);
      }
    });

    const gradeAverage = gradeScore.reduce((a, b) => a + b, 0);
    return gradeAverage;
  };

  const getLibrayAveragePresetScore = (candidate) => {
    let testcnt = 0;
    let presetScore = 0;
    candidate?.libraryTests?.forEach((lib) => {
      lib?.test?.forEach((tmp) => {
        const result = candidate?.results?.[tmp];
        if (
          result?.preset_score === undefined ||
          result?.preset_score <= 0 ||
          tmp === '99000100001' ||
          tmp === '99101000006'
        )
          return;

        if (result) {
          presetScore += result?.preset_score || 0;
          testcnt += 1;
        }
      });
    });

    if (presetScore === 0) return 0;
    return parseFloat((presetScore / testcnt).toFixed(2));
  };

  const stageClickHandler = (idx, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (tableRef?.current && rowRefs?.[idx]?.current)
      var resultRect = tableRef.current.getBoundingClientRect(),
        elemRect = rowRefs?.[idx].current
          .querySelector('.stage')
          .getBoundingClientRect(),
        offsetTop = elemRect.top - resultRect.top,
        offsetLeft = elemRect.left - resultRect.left;

    stageDropdownRef.current.style.top = `${offsetTop + elemRect?.height}px`;
    stageDropdownRef.current.style.left = `${offsetLeft}px`;
    stageDropdownRef.current.style.width = `${elemRect?.width}px`;
    tableRef.current.style.setProperty('--dropdown-top', `${offsetTop}px`);
    setStageShowDropdown(idx);
  };

  const onMoreClickHandler = (idx, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (tableRef?.current && rowRefs?.[idx]?.current)
      var resultRect = tableRef.current.getBoundingClientRect(),
        elemRect = rowRefs?.[idx].current.getBoundingClientRect(),
        offset = elemRect.top - resultRect.top;

    dropdownRef.current.style.top = `${offset + 30}px`;
    tableRef.current.style.setProperty('--dropdown-top', `${offset}px`);
    setShowDropdown(idx);
  };

  // candidate 상태 설정
  // 0 : 초대메일 발송
  // 1 : 평가 생성 완료
  // 2 : 검토 중
  // 3 : 평가 불합격
  // 4 : 합격
  //
  const getHiringStageText = (step, updateTime) => {
    const start = parseInt(new Date().getTime() / 1000);
    const timeDif = 2 * 3600;

    step = Number(step);

    if (Math.abs(start - updateTime) > timeDif && step === 0) {
      return 'text.hiring-leave-during-assessment';
    }

    switch (step) {
      case -1:
        return 'text.hiring-stage.sended-email';
      case 0:
        return 'text.hiring-stage.sended-email';
      case 1:
        return 'text.hiring-stage.wirte-done';
      case 2:
        return 'text.hiring-stage.under-investigation';
      case 3:
        return 'text.hiring-stage.reject';
      case 4:
        return 'text.hiring-stage.accept';
    }
  };

  const onCandidateClickHandler = (candidate) => {
    const cid = candidate?.id;
    if (checkedAdmin) {
      navigate(
        `/admin/customer/candidates/${cid}?aid=${aid}&entry=assessment`,
        {
          state: {
            khaiP_rank: candidate?.results?.['Khai-P']?.result?.rank,
            candidatecnt: __candidates.length,
            checkedAdmin,
          },
        }
      );
    } else {
      navigate(`/customer/candidates/${cid}?aid=${aid}&entry=assessment`, {
        state: {
          khaiP_rank: candidate?.results?.['Khai-P']?.result?.rank,
          candidatecnt: __candidates.length,
        },
      });
    }
  };

  const getAverageSubjectScore = (candidate) => {
    let { subjectiveTests } = candidate;

    if (!Object.keys(candidate || {}).length || !(subjectiveTests || []).length)
      return;

    const scores = new Array();
    (subjectiveTests || []).forEach((test) => {
      const score = candidate?.results?.[test?.uuid]?.score;
      if (score) scores.push(score);
    });

    const average = scores.reduce((a, b) => a + b, 0) / subjectiveTests.length;
    return average;
  };

  const getAverageSubjectPresetScore = (candidate) => {
    let { subjectiveTests } = candidate;

    if (!Object.keys(candidate || {}).length || !(subjectiveTests || []).length)
      return;

    const presetScores = new Array();
    (subjectiveTests || []).forEach((test) => {
      const presetScore = candidate?.results?.[test?.uuid]?.preset_score;
      if (presetScore) presetScores.push(presetScore);
    });

    const presetAverage =
      presetScores.reduce((a, b) => a + b, 0) / subjectiveTests.length;
    return presetAverage;
  };

  const getAverageObjectiveScore = (candidate) => {
    let score = 0;
    let cnt = 0;
    let { objectiveScores } = candidate;
    let { objectiveTests } = candidate;
    Object.entries(objectiveScores || {}).forEach(([key, val], idx) => {
      const skill = (objectiveTests || []).find((x) => x.uuid === key);
      // tid가 1310이고 lang가 ko일 때만 OBPI이다.
      if (skill?.id !== '71' && !(skill?.id === '1310' && lang === 'ko')) {
        // skill.id === 2 : Culture fit.
        if (val >= 0 && val <= 1) {
          score += val;
          cnt += 1;
        }
      }
    });
    if (cnt === 0 || !candidate.results) return -1;
    return parseFloat((score / cnt)?.toFixed?.(2));
  };

  const onSetOBPIFitClickHandler = () => {
    displayModal(
      'assessments/obpi/SetOBPIFit',
      {
        candidates: __candidates,
        setOBPIBenchmark,
        OBPI_TESTS,
        __OBPI_LIST_NUM__,
        __OBPI_RESULT_NUM__,
        selected: assessmentInfo?.obpiBenchmark?.id || '',
      },
      {
        frame: 'integration',
        backgroundColor: 'transparent',
      }
    );
  };

  const onSortClickHandler = (type) => {
    var order = sortOrder;
    if (type === sortType) {
      if (sortOrder === 'desc') {
        order = 'asc';
      } else {
        order = 'desc';
      }
    } else {
      setSortType(type);
      order = 'desc';
    }
    setSortOrder(order);
    sortCandidates(type, order);
  };

  function sortCandidates(type, order) {
    const docs = __candidates;
    switch (type) {
      case 'invited':
        if (order === 'desc') {
          (docs || []).sort((a, b) => {
            if (a.updatedAt < b.updatedAt) {
              return 1;
            }
            if (a.updatedAt > b.updatedAt) {
              return -1;
            }
            if (a.updatedAt === undefined && Boolean(b.updatedAt)) {
              return 1;
            }
            if (b.updatedAt === undefined && Boolean(a.updatedAt)) {
              return -1;
            }

            return 0;
          });
        } else {
          (docs || []).sort((a, b) => {
            if (a.updatedAt > b.updatedAt) {
              return 1;
            }
            if (a.updatedAt < b.updatedAt) {
              return -1;
            }
            if (a.updatedAt === undefined && Boolean(b.updatedAt)) {
              return 1;
            }
            if (b.updatedAt === undefined && Boolean(a.updatedAt)) {
              return -1;
            }

            return 0;
          });
        }
        break;
      case 'objective':
        if (order === 'desc') {
          (docs || []).sort(
            (b, a) => a.AverageObjectiveScore - b.AverageObjectiveScore
          );
        } else {
          (docs || []).sort(
            (b, a) => b.AverageObjectiveScore - a.AverageObjectiveScore
          );
        }
        break;
      case 'subjective':
        if (order === 'desc') {
          (docs || []).sort(
            (b, a) =>
              a.presetAverageScore - b.presetAverageScore ||
              b.libarayAverageScore - a.libarayAverageScore
          );
        } else {
          (docs || []).sort(
            (b, a) =>
              b.presetAverageScore - a.presetAverageScore ||
              a.libarayAverageScore - b.libarayAverageScore
          );
        }
        break;
      case 'total':
        if (order === 'desc') {
          (docs || []).sort(
            (b, a) => a.totalAverageScore - b.totalAverageScore
          );
        } else {
          (docs || []).sort(
            (b, a) => b.totalAverageScore - a.totalAverageScore
          );
        }
        break;
      case 'obpi':
        if (order === 'desc') {
          (docs || []).sort((b, a) => a.obpiMatchScore - b.obpiMatchScore);
        } else {
          (docs || []).sort((b, a) => b.obpiMatchScore - a.obpiMatchScore);
        }
        break;
      case 'khai-p':
        if (order === 'desc') {
          (docs || []).sort((b, a) => {
            if (
              Boolean(b.results?.['Khai-P']?.result?.total?.score) &&
              Boolean(a.results?.['Khai-P']?.result?.total?.score)
            ) {
              return (
                a.results?.['Khai-P']?.result?.total?.score -
                b.results?.['Khai-P']?.result?.total?.score
              );
            }
            if (
              !b.results?.['Khai-P']?.result?.total?.score &&
              Boolean(a.results?.['Khai-P']?.result?.total?.score)
            ) {
              return 1;
            }
            if (
              Boolean(b.results?.['Khai-P']?.result?.total?.score) &&
              !a.results?.['Khai-P']?.result?.total?.score
            ) {
              return -1;
            }
            return 0;
          });
        } else {
          (docs || []).sort((b, a) => {
            if (
              Boolean(b.results?.['Khai-P']?.result?.total?.score) &&
              Boolean(a.results?.['Khai-P']?.result?.total?.score)
            ) {
              return (
                b.results?.['Khai-P']?.result?.total?.score -
                a.results?.['Khai-P']?.result?.total?.score
              );
            }
            if (
              !b.results?.['Khai-P']?.result?.total?.score &&
              Boolean(a.results?.['Khai-P']?.result?.total?.score)
            ) {
              return 1;
            }
            if (
              Boolean(b.results?.['Khai-P']?.result?.total?.score) &&
              !a.results?.['Khai-P']?.result?.total?.score
            ) {
              return -1;
            }
            return 0;
          });
        }
        break;
      case 'khai-o':
        if (order === 'desc') {
          (docs || []).sort((b, a) => {
            if (
              Boolean(b.results?.['Khai-O']?.result?.organization_conformity) &&
              Boolean(a.results?.['Khai-O']?.result?.organization_conformity)
            ) {
              return (
                getKhaiOSortScore(
                  a.results?.['Khai-O']?.result?.organization_conformity
                ) -
                getKhaiOSortScore(
                  b.results?.['Khai-O']?.result?.organization_conformity
                )
              );
            }
            if (
              !b.results?.['Khai-O']?.result?.organization_conformity &&
              Boolean(a.results?.['Khai-O']?.result?.organization_conformity)
            ) {
              return 1;
            }
            if (
              Boolean(b.results?.['Khai-O']?.result?.organization_conformity) &&
              !a.results?.['Khai-O']?.result?.organization_conformity
            ) {
              return -1;
            }
            return 0;
          });
        } else {
          (docs || []).sort((b, a) => {
            if (
              Boolean(b.results?.['Khai-O']?.result?.organization_conformity) &&
              Boolean(a.results?.['Khai-O']?.result?.organization_conformity)
            ) {
              return (
                getKhaiOSortScore(
                  b.results?.['Khai-O']?.result?.organization_conformity
                ) -
                getKhaiOSortScore(
                  a.results?.['Khai-O']?.result?.organization_conformity
                )
              );
            }
            if (
              !b.results?.['Khai-O']?.result?.organization_conformity &&
              Boolean(a.results?.['Khai-O']?.result?.organization_conformity)
            ) {
              return 1;
            }
            if (
              Boolean(b.results?.['Khai-O']?.result?.organization_conformity) &&
              !a.results?.['Khai-O']?.result?.organization_conformity
            ) {
              return -1;
            }
            return 0;
          });
        }
        break;
      case 'custom':
        if (order === 'desc') {
          (docs || []).sort(
            (b, a) => a.subjectAverageScore - b.subjectAverageScore
          );
        } else {
          (docs || []).sort(
            (b, a) => b.subjectAverageScore - a.subjectAverageScore
          );
        }
        break;
      case 'hiring':
        if (order === 'desc') {
          (docs || []).sort((b, a) => a.progress - b.progress);
        } else {
          (docs || []).sort((b, a) => b.progress - a.progress);
        }
        break;
    }
    __setCandidates(docs);
  }

  const getKhaiOSortScore = (oScore) => {
    if (oScore === 'normal') return 10;
    if (oScore === 'caution') return 5;
    if (oScore === 'incongruity') return 0;
    return -1;
  };

  const getKhaiPScoreCell = (candidate) => {
    var barcolor = 'blue500';
    switch (candidate?.results?.['Khai-P']?.result?.total?.level) {
      case 'S':
        barcolor = 'blue600';
        break;
      case 'A':
        barcolor = 'blue500';
        break;
      case 'B':
        barcolor = 'orange500';
        break;
      case 'C':
        barcolor = 'red500';
        break;
      case 'D':
        barcolor = 'red700';
        break;
    }

    return (
      <div className="container">
        <div className="grade">
          {getKHAI_P_Grade('total', candidate?.results?.['Khai-P']?.result)}
        </div>
        <div className="score">
          <span>
            {candidate?.results?.['Khai-P']?.result?.total?.score >= 0
              ? candidate?.results?.['Khai-P']?.result?.total?.score
              : '-'}
          </span>
          <div
            className="progress-bar"
            style={{ display: candidate?.progress <= 0 ? 'none' : 'block' }}
          >
            <div
              className={`fill ${getKHAIPColor(
                candidate?.results?.['Khai-P']?.result?.total?.score
              )}`}
              style={{
                width: `${
                  parseInt(
                    candidate?.results?.['Khai-P']?.result?.total?.score
                  ) <= 0
                    ? 0
                    : parseInt(
                        candidate?.results?.['Khai-P']?.result?.total?.score
                      )
                }%`,
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  /** ==========
   * @function convertScoreToRank
   * 언어테스트 전체 점수에 따라 등급을 리턴해주는 함수
   * @param {*} score (number)
   * @returns
  ============== */
  const convertScoreToRank = (score) => {
    if (score !== undefined) {
      if (score >= 6) {
        return <GradeA />;
      }
      if (score <= 5 && score >= 4) {
        return <GradeB />;
      }
      if (score <= 3 && score >= 2) {
        return <GradeC />;
      }
      if (score <= 1 && score > 0) {
        return <GradeD />;
      }
      if (score === 0) {
        return 'none';
      }
    }
  };

  const getTotalScore = (candidate) => {
    if (!Object.keys(assessmentInfo).length) return;

    const { results } = candidate;

    const languageTests = [];

    assessmentInfo?.tests?.forEach((test) => {
      if (typeof test?.question === 'string') return;

      test?.question?.forEach((ques) => {
        if (ques?.tid === '601000000') {
          if (results?.english) {
            languageTests.push({
              category_skill: 'English Test',
              section: 'language',
              question: [
                {
                  name: 'english',
                  ...results.english,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.chinese) {
            languageTests.push({
              category_skill: 'Chinese Test',
              section: 'language',
              question: [
                {
                  name: 'chinese',
                  ...results.chinese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.japanese) {
            languageTests.push({
              category_skill: 'Japanese Test',
              section: 'language',
              question: [
                {
                  name: 'japanese',
                  ...results.japanese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.korean) {
            languageTests.push({
              category_skill: 'Korean Test',
              section: 'language',
              question: [
                {
                  name: 'korean',
                  ...results.korean,
                  section: 'language',
                },
              ],
            });
          }
        }
      });
    });

    const newTests = [...assessmentInfo?.tests, ...languageTests];

    let testsArr;

    if (!results) {
      testsArr = [];
    } else {
      testsArr = newTests.map((test, idx) => {
        if (
          test?.id === '6010' ||
          test?.section === 'file-upload' ||
          test?.section === 'multiple-choice'
        )
          return;

        const testArr = [];
        const { question } = test;

        // 커스텀 질문이고 language 테스트가 아닐 때
        if (test?.section && test?.section !== 'language') {
          testArr.push(results[test?.uuid]);
        } else if (test?.question_type === 'tag.multiple-choice') {
          testArr.push(results[test?.uuid]);
        } else {
          for (const q of question) {
            let count = 0;

            if (q?.section === 'language') {
              Object.keys(results[q?.name])?.forEach((item) => {
                testArr.push({
                  score:
                    q?.score && count < 1
                      ? q?.score
                      : q?.preset_score && count < 1
                      ? q?.preset_score
                      : Math.abs(
                          convertGradeToScore(
                            results[q?.name][item]?.judged_difficulty
                          ) / 10
                        ),
                });
                count += 1;
              });
            } else if (q?.id !== '99101000006') {
              if (!results) return;

              testArr.push(results[q?.id]);
            }
          }
        }

        const removeUndefined = testArr?.filter(
          (item) => item?.preset_score || item?.score
        );

        const scoreArr = removeUndefined?.map((item) => {
          return !item?.score ? item?.preset_score : item?.score;
        });

        const average = useAverageScore(scoreArr);

        return {
          id: idx,
          title: test?.category_skill || test?.title_en || test?.question,
          average: average || 0,
        };
      });
    }

    const filtered = testsArr?.filter((item) => item);
    const sortedData = filtered.sort((a, b) => b.average - a.average);

    const avgScore =
      sortedData.reduce((acc, cur) => acc + cur.average, 0) / sortedData.length;

    return avgScore || 0;
  };

  return (
    <section className="pages-protected-detail-content">
      <section className="candidates">
        <h3 className="candidates-title fs20">{t('title.candidates')}</h3>
        <header className="candidates-table-header">
          <div className="candidates-table-header-left">
            <MaterialInput
              className="candidates-table-header-candidates-search"
              placeholder={t('text-field.label.candidate-search')}
              icon={<SearchIcon />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <MaterialSelect
              className="candidates-table-header-hiring-stage"
              title={t('menu.label.hiring-stage')}
              value={hiringStage}
              onChange={(item) => setHiringStage(item?.key)}
              items={[
                {
                  key: 'all',
                  i18nKey: 'text.hiring-stage.all',
                },
                {
                  key: 'sended-email',
                  i18nKey: 'text.hiring-stage.sended-email',
                },
                {
                  key: 'wirte-done',
                  i18nKey: 'text.hiring-stage.wirte-done',
                },
                {
                  key: 'under-investigation',
                  i18nKey: 'text.hiring-stage.under-investigation',
                },
                {
                  key: 'reject',
                  i18nKey: 'text.hiring-stage.reject',
                },
                {
                  key: 'accept',
                  i18nKey: 'text.hiring-stage.accept',
                },
              ]}
            />
          </div>
          <div
            className="candidates-table-header-right"
            style={{ display: hasOBPI === false ? 'none' : '' }}
          >
            <button className="fsbtn16" onClick={onSetOBPIFitClickHandler}>
              {t('btn.obpi-benchmark-setting')}
            </button>
          </div>
        </header>
        <section ref={tableRef} className="candidates-table">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="name thead" width="10%">
                      {t('column.name')}
                    </TableCell>
                    <TableCell
                      className="invited-on sort thead"
                      width="13%"
                      onClick={() => onSortClickHandler('invited')}
                    >
                      <div className="content">
                        {t('column.last-activity')}
                        {sortType === 'invited' && sortOrder === 'desc' ? (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <UpIcon style={{ width: '16px', height: '16px' }} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <DownIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell>
                    {/* 토탈 점수 */}
                    <TableCell
                      className="total-score sort thead"
                      width="14%"
                      onClick={() => onSortClickHandler('total')}
                    >
                      <div className="content">
                        {t('column.test-total-score')}
                        {sortType === 'total' && sortOrder === 'desc' ? (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <UpIcon style={{ width: '16px', height: '16px' }} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <DownIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell>
                    {/* 객관식 */}
                    {/* <TableCell
                      className="objective-score sort thead"
                      width="14%"
                      style={{
                        display: hasObjectivetest === false ? 'none' : '',
                      }}
                      onClick={() => onSortClickHandler('objective')}
                    >
                      <div className="content">
                        {t('column.objective-test-score')}
                        {sortType === 'objective' && sortOrder === 'desc' ? (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <UpIcon style={{ width: '16px', height: '16px' }} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <DownIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell> */}
                    {/* 주관식 */}
                    {/* <TableCell
                      className="subjective-score sort thead"
                      width="14%"
                      style={{
                        display: hasLibrarytest === false ? 'none' : '',
                      }}
                      onClick={() => onSortClickHandler('subjective')}
                    >
                      <div className="content">
                        {t('column.subjective-test-score')}
                        {sortType === 'subjective' && sortOrder === 'desc' ? (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <UpIcon style={{ width: '16px', height: '16px' }} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <DownIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell> */}
                    {/* 영어 테스트 */}
                    {/* <TableCell
                      className="language-english sort thead"
                      width="14%"
                      style={{
                        display: hasEnglishTests === false ? 'none' : '',
                      }}
                    >
                      <div className="content">{t('title.english-test')}</div>
                    </TableCell> */}
                    {/* 한국어 테스트 */}
                    {/* <TableCell
                      className="language-korean sort thead"
                      width="14%"
                      style={{
                        display: hasKoreanTests === false ? 'none' : '',
                      }}
                    >
                      <div className="content">{t('title.korean-test')}</div>
                    </TableCell> */}
                    {/* 중국어 테스트 */}
                    {/* <TableCell
                      className="language-chinese thead"
                      width="14%"
                      style={{
                        display: hasChineseTests === false ? 'none' : '',
                      }}
                    >
                      <div className="content">{t('title.chinese-test')}</div>
                    </TableCell> */}
                    {/* 일본어 테스트 */}
                    {/* <TableCell
                      className="language-japanese thead"
                      width="14%"
                      style={{
                        display: hasJapaneseTests === false ? 'none' : '',
                      }}
                    >
                      <div className="content">{t('title.japanese-test')}</div>
                    </TableCell> */}
                    {/* KHAI-P */}
                    <TableCell
                      className="khai-p-score sort thead"
                      width="14%"
                      style={{ display: hasKhaiP === false ? 'none' : '' }}
                      onClick={() => onSortClickHandler('khai-p')}
                    >
                      <div className="content">
                        {t('title.khai-p-score')}
                        {sortType === 'khai-p' && sortOrder === 'desc' ? (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <UpIcon style={{ width: '16px', height: '16px' }} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <DownIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell>
                    {/* KHAI-O */}
                    <TableCell
                      className="khai-score sort thead"
                      width="14%"
                      style={{ display: hasKhaiO === false ? 'none' : '' }}
                      onClick={() => onSortClickHandler('khai-o')}
                    >
                      <div className="content">
                        {t('title.khai-o')}
                        {sortType === 'khai-o' && sortOrder === 'desc' ? (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <UpIcon style={{ width: '16px', height: '16px' }} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <DownIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell>
                    {/* OBPI */}
                    <TableCell
                      className="obpi-score sort thead"
                      width="14%"
                      style={{ display: hasOBPI === false ? 'none' : '' }}
                      onClick={() => onSortClickHandler('obpi')}
                    >
                      <div className="content">
                        {t('column.obpi-fit-score')}
                        {sortType === 'obpi' && sortOrder === 'desc' ? (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <UpIcon style={{ width: '16px', height: '16px' }} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <DownIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell>
                    {/* 커스텀 */}
                    {/* <TableCell
                      className="custom-score sort thead"
                      width="14%"
                      onClick={() => onSortClickHandler('custom')}
                      style={{
                        display: subjectiveTests.length === 0 ? 'none' : '',
                      }}
                    >
                      <div className="content">
                        {aid === 'RxO4xPgfw9REcXJnbHtG'
                          ? t('column.custom-interview')
                          : t('column.custom-question-score')}
                        {sortType === 'custom' && sortOrder === 'desc' ? (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <UpIcon style={{ width: '16px', height: '16px' }} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <DownIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell> */}
                    {/* 평가 단계 */}
                    <TableCell
                      className="hiring-stage sort thead"
                      width="18%"
                      onClick={() => onSortClickHandler('hiring')}
                    >
                      <div className="content">
                        {t('column.hiring-stage')}
                        {sortType === 'hiring' && sortOrder === 'desc' ? (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <UpIcon style={{ width: '16px', height: '16px' }} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            style={{ width: '16px', height: '16px' }}
                          >
                            <DownIcon
                              style={{ width: '16px', height: '16px' }}
                            />
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="right" className="more thead" width="7%">
                      {t('column.actions')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow className="pending-row tbody">
                      <TableCell colSpan={6}>
                        <div className="container">
                          <CircularProgress color="primary" size={25} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    candidates &&
                    (candidates || []).map((candidate, idx) => (
                      <TableRow
                        ref={rowRefs[idx]}
                        key={idx}
                        hover
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          onCandidateClickHandler(candidate);
                        }}
                      >
                        <TableCell className="name tbody">
                          {displayName(
                            candidate?.firstName,
                            candidate?.lastName,
                            t('lang')
                          )}
                        </TableCell>
                        <TableCell className="invited-on tbody">
                          {dateToDateFormat(
                            candidate?.updatedAt?.toDate(),
                            t('lang')
                          )}
                        </TableCell>
                        {/* 토탈 점수 */}
                        <TableCell className="subjective-score tbody">
                          <div className="container">
                            <ProgressScore score={getTotalScore(candidate)} />
                          </div>
                        </TableCell>
                        {/* 객관식 */}
                        {/* <TableCell
                          className="objective-score tbody"
                          style={{
                            display: hasObjectivetest === false ? 'none' : '',
                          }}
                        >
                          <div className="container">
                            <ProgressScore
                              score={getAverageObjectiveScore(candidate)}
                            />
                          </div>
                        </TableCell> */}
                        {/* 주관식 */}
                        {/* <TableCell
                          className="subjective-score tbody"
                          style={{
                            display: hasLibrarytest === false ? 'none' : '',
                          }}
                        >
                          <div className="container">
                            <ProgressScore
                              score={getLibrayAverageScore(candidate)}
                              presetScore={getLibrayAveragePresetScore(
                                candidate
                              )}
                            />
                          </div>
                        </TableCell> */}
                        {/* 영어 테스트 */}
                        {/* <TableCell
                          className="language language-english tbody"
                          style={{
                            display: hasEnglishTests === false ? 'none' : '',
                          }}
                        >
                          <div
                            className={`content ${
                              convertScoreToRank(
                                candidate?.englishTotalScore
                              ) === 'none' && 'text'
                            }`}
                          >
                            {convertScoreToRank(
                              candidate?.englishTotalScore
                            ) !== 'none'
                              ? convertScoreToRank(candidate?.englishTotalScore)
                              : t('desc.not-taking-a-test')}
                          </div>
                        </TableCell> */}
                        {/* 한국어 테스트 */}
                        {/* <TableCell
                          className="language language-korean tbody"
                          style={{
                            display: hasKoreanTests === false ? 'none' : '',
                          }}
                        >
                          <div
                            className={`content ${
                              convertScoreToRank(
                                candidate?.koreanTotalScore
                              ) === 'none' && 'text'
                            }`}
                          >
                            {convertScoreToRank(candidate?.koreanTotalScore) !==
                            'none'
                              ? convertScoreToRank(candidate?.koreanTotalScore)
                              : t('desc.not-taking-a-test')}
                          </div>
                        </TableCell> */}
                        {/* 중국어 테스트 */}
                        {/* <TableCell
                          className="language language-chinese tbody"
                          style={{
                            display: hasChineseTests === false ? 'none' : '',
                          }}
                        >
                          <div
                            className={`content ${
                              convertScoreToRank(
                                candidate?.chineseTotalScore
                              ) === 'none' && 'text'
                            }`}
                          >
                            {convertScoreToRank(
                              candidate?.chineseTotalScore
                            ) !== 'none'
                              ? convertScoreToRank(candidate?.chineseTotalScore)
                              : t('desc.not-taking-a-test')}
                          </div>
                        </TableCell> */}
                        {/* 일본어 테스트 */}
                        {/* <TableCell
                          className="language language-japanese tbody"
                          style={{
                            display: hasJapaneseTests === false ? 'none' : '',
                          }}
                        >
                          <div
                            className={`content ${
                              convertScoreToRank(
                                candidate?.japaneseTotalScore
                              ) === 'none' && 'text'
                            }`}
                          >
                            {convertScoreToRank(
                              candidate?.japaneseTotalScore
                            ) !== 'none'
                              ? convertScoreToRank(
                                  candidate?.japaneseTotalScore
                                )
                              : t('desc.not-taking-a-test')}
                          </div>
                        </TableCell> */}
                        {/* KHAI-P */}
                        <TableCell
                          className="khai-score tbody"
                          style={{ display: hasKhaiP === false ? 'none' : '' }}
                        >
                          {getKhaiPScoreCell(candidate)}
                        </TableCell>
                        {/* KHAI-O */}
                        <TableCell
                          className="khai-score tbody"
                          style={{ display: hasKhaiO === false ? 'none' : '' }}
                        >
                          {getKhaiO_Reliability(
                            candidate?.results?.['Khai-O']?.result
                              ?.organization_conformity,
                            'fs14',
                            'khai-o-score',
                            'organization_conformity',
                            t
                          )}
                        </TableCell>
                        {/* OBPI */}
                        <TableCell
                          className="objective-score tbody"
                          style={{ display: hasOBPI === false ? 'none' : '' }}
                        >
                          <div className="container">
                            <span>
                              {parseInt(candidate?.obpiMatchScore * 100) >= 0
                                ? parseInt(candidate?.obpiMatchScore * 100)
                                : '-'}
                              %
                              {`${
                                candidate?.id === obpiBenchmark?.id
                                  ? `(${t('tag.benchmark-score')})`
                                  : ''
                              }`}
                            </span>
                            <div
                              className="progress-bar"
                              style={{
                                display:
                                  candidate?.progress <= 0 ? 'none' : 'block',
                              }}
                            >
                              {
                                // Need the real score.
                              }
                              <div
                                className="fill"
                                style={{
                                  width: `${
                                    parseInt(candidate?.obpiMatchScore * 100) <=
                                    0
                                      ? 0
                                      : parseInt(
                                          candidate?.obpiMatchScore * 100
                                        )
                                  }%`,
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        {/* custom */}
                        {/* <TableCell
                          className="subjective-score tbody"
                          style={{
                            display: subjectiveTests.length === 0 ? 'none' : '',
                          }}
                        >
                          <div className="container">
                            <ProgressScore
                              score={getAverageSubjectScore(candidate)}
                              presetScore={getAverageSubjectPresetScore(
                                candidate
                              )}
                            />
                          </div>
                        </TableCell> */}
                        <TableCell
                          className={`hiring-stage step-leave-${
                            t(
                              getHiringStageText(
                                candidate?.progress,
                                candidate?.updatedAt?.seconds
                              )
                            ) === t('text.hiring-leave-during-assessment')
                          } step-${candidate?.progress} tbody`}
                          onClick={(event) =>
                            candidate?.progress === 0 ||
                            candidate?.progress === -1 ? (
                              <></>
                            ) : (
                              stageClickHandler(
                                stageShowDropdown === idx ? -1 : idx,
                                event
                              )
                            )
                          }
                        >
                          <div className="container stage">
                            <div className="status-left">
                              <div className="status-icon" />
                              <span>
                                {t(
                                  getHiringStageText(
                                    candidate?.progress,
                                    candidate?.updatedAt?.seconds
                                  )
                                )}
                              </span>
                            </div>
                            <div
                              className="status-right"
                              style={{
                                display:
                                  candidate?.progress === 0 ||
                                  candidate?.progress === -1
                                    ? 'none'
                                    : 'block',
                              }}
                            >
                              <ArrowBotton />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="more tbody">
                          <div className="container">
                            <div
                              className="more-icon"
                              onClick={(event) =>
                                onMoreClickHandler(
                                  showDropdown === idx ? -1 : idx,
                                  event
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                  {candidates?.length <= 0 && (
                    <TableRow>
                      <TableCell colSpan={6} className="empty-candidate">
                        <div className="container empty fs16">
                          {t('candidates.desc.empty')}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={_candidates.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Paper>
          <div ref={dropdownRef} className="candidates-table-dropdown">
            {showDropdown > -1 && (
              <ul className="candidates-table-dropdown-list">
                {(__TABLE_ACTIONS__ || []).map((tab, idx) => (
                  <li
                    key={idx}
                    className="candidates-table-dropdown-list-item"
                    onClick={() => {
                      tab?.onClick(
                        {
                          t,
                          displayModal,
                          uid: userInfo?.ownerUid || user?.uid,
                          aid,
                          firstName: candidates?.[idx]?.firstName,
                          lastName: candidates?.[idx]?.lastName,
                          email: candidates?.[idx]?.email,
                          candidate: candidates?.[idx],
                          candidates,
                          assessmentInfo,
                          showDropdown,
                          setShowDropdown,
                          setIsEntireLoading,
                        },
                        () => {
                          setIsRefreshing(true);
                        }
                      );
                    }}
                  >
                    {t(tab.i18nKey)}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div ref={stageDropdownRef} className="candidates-stage-dropdown">
            {stageShowDropdown > -1 && (
              <ul className="candidates-stage-dropdown-list">
                {(__ASSESSMENTS_STAGE__ || []).map((tab, index) => (
                  <li
                    key={index}
                    className="candidates-stage-dropdown-list-item"
                    onClick={() => {
                      tab?.onClick(
                        {
                          aid,
                          candidates,
                          stageShowDropdown,
                          setStageShowDropdown,
                          setIsEntireLoading,
                        },
                        () => {
                          setIsRefreshing(true);
                        }
                      );
                    }}
                  >
                    <div
                      className={`candidates-stage-dropdown-status-icon ${tab.key}`}
                    />
                    {t(tab.i18nKey)}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </section>
      </section>
    </section>
  );
};

export default Content;
