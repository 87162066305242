import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialInput from 'components/input/MaterialInput';
import MaterialSelect from 'components/select/MaterialSelect';
import IntroFileInput from 'components/input/IntroFileInput';
import PropTypes from 'prop-types';
import { supportedLangs } from 'i18n/index';

const purpose = [
  {
    key: 'recruitment-assessment',
    i18nKey: 'text-field.input.recruitment-assessment',
  },
  {
    key: 'employee-assessment',
    i18nKey: 'text-field.input.employee-assessment',
  },
];

const Step0 = ({
  curStep,
  name,
  setName,
  nameErrorMessage,
  positionList,
  skillList,
  position,
  setPosition,
  positionErrorMessage,
  language,
  setLanguage,
  testCategory,
  setTestCategory,
  defaultCategory,
  cleartest,
  languageErrorMessage,
  setPurpose,
  introVideoUrl,
  setIntroVideoUrl,
  fileName,
  setFileName,
}) => {
  const { t } = useTranslation();

  const onTestCategoryClickHandler = (testcategory) => {
    setTestCategory(testcategory);
  };

  return (
    <section className="pages-protected-assessments-create-step-1">
      <section className="form">
        <section className="form-input">
          <MaterialInput
            className="form-input-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={`${t('text-field.label.name-your-assessment')} *`}
            color={Boolean(nameErrorMessage) && 'error'}
            message={nameErrorMessage}
          />
          <div className="helper fs12">
            {t('text-field.helper.name-your-assessment')}
          </div>
        </section>
        <section className="form-purpose">
          <p>{t('name-assessment.desc.assessment-purpose')}</p>
          <MaterialSelect
            className="form-purpose-select"
            title={`${t('text-field.label.assessment-purpose')} *`}
            value={purpose[0]}
            onChange={(item) => {
              setPurpose(item?.key);
            }}
            items={purpose}
          />
        </section>

        <section className="form-assessment">
          <p>{t('name-assessment.desc.assessment-form')}</p>
          <div className="selector">
            <div
              className={`box ${
                testCategory === 'evaluator-select-test' && 'selected'
              } 
                            ${
                              defaultCategory === 'candidate-select-test' &&
                              'disabled'
                            }`}
              onClick={() =>
                onTestCategoryClickHandler('evaluator-select-test')
              }
            >
              <div className="content">
                <div className="text">
                  <div className="title fs20">
                    {t('btn.evaluator-select-test')}
                  </div>
                  <div className="comment fs16">
                    {t('btn.desc.evaluator-select-test')}
                  </div>
                </div>
                <div className="icon left" />
              </div>
            </div>
            {/* <div
              className={`box ${
                testCategory === 'candidate-select-test' && 'selected'
              }
                            ${
                              defaultCategory === 'evaluator-select-test' &&
                              'disabled'
                            }`}
              onClick={() =>
                onTestCategoryClickHandler('candidate-select-test')
              }
            >
              <div className="content">
                <div className="text">
                  <div className="title fs20">
                    {t('btn.candidate-select-test')}
                  </div>
                  <div className="comment fs16">
                    {t('btn.desc.candidate-select-test')}
                  </div>
                </div>
                <div className="icon right" />
              </div>
            </div> */}
          </div>
          <div className="selector-info fs12">
            {t('desc.assessment-form-change')}
          </div>
        </section>

        <section className="form-language">
          <p>{t('name-assessment.desc.candidate-lang')}</p>
          <MaterialSelect
            className="form-language-select"
            title={`${t('menu.label.candidate-lang')} *`}
            value={language}
            onChange={(item) => {
              setLanguage(item?.key);
              cleartest(item?.key);
            }}
            items={supportedLangs}
            color={Boolean(languageErrorMessage) && 'error'}
            message={languageErrorMessage}
          />
        </section>
        <section className="advanced-option">
          <div className="sub">
            <div className="sub-title fs16">
              <h6>{t('name-assessment.desc.intro-video')}</h6>
            </div>
            <IntroFileInput
              videoUrl={introVideoUrl}
              setVideoUrl={setIntroVideoUrl}
              fileValue={fileName}
              setFileValue={setFileName}
            />
          </div>
        </section>
      </section>
    </section>
  );
};

Step0.propTypes = {
  curStep: PropTypes.number,
  name: PropTypes.string,
  setName: PropTypes.func,
  nameErrorMessage: PropTypes.string,
  position: PropTypes.object,
  setPosition: PropTypes.func,
  positionErrorMessage: PropTypes.string,
  introVideoUrl: PropTypes.string,
  setIntroVideoUrl: PropTypes.func,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
};

export default Step0;
