import React, { useState, useCallback } from 'react';
import { ReactComponent as EmailIcon } from 'assets/images/icon/email.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';
import { useTranslation } from 'react-i18next';
import ApplyInputBox from 'components/apply/ApplyInputBox';
import { Button } from 'components/common/Button';
import { validateEmail } from 'utils/auth';
import { executeAction } from 'utils/redux';
import {
  deleteRecruitmentCandidate, editRecruitmentCandidateEmail,
} from 'store/slices/recruitments/recruitmentsSlice';
import * as Sentry from '@sentry/react';
import { toast } from 'utils/notify';
import { useSelector } from 'react-redux';

export const  EditCandidateEmailModal = ({ changeOpenClose, rid, candidate }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(false);
  const [notAllow, setNotAllow] = useState(false);
  const [email, setEmail] = useState('');

  const dismissModal = () => {
    changeOpenClose(false);
  };

  const onSubmitHandler = useCallback(async() => {
    setIsLoading(true);
    executeAction(
      editRecruitmentCandidateEmail({
        uid: userInfo?.ownerUid,
        rid,
        email,
        candidateInfo: candidate
      })
    ).unwrap().then(() => {
      executeAction(
        deleteRecruitmentCandidate({aid: rid, email: candidate.email  })
      ).then(() => {
        toast(t('toast.successes-edit'));
      })
        .catch((e) => {
          Sentry.captureException(e);
          toast(t('toast.fail-edit'));
        }).finally(() => {
          setIsLoading(false);
          dismissModal();
      });
    }).catch((e) => {
      Sentry.captureException(e);
      switch (e?.message) {
        case 'already_exists_email':
          toast(t('toast.desc.already-invite'));
          break;
        default:
          break;
      }

      setIsLoading(false);
      dismissModal();
    });
    },[ email, candidate, rid, t, dismissModal, setIsLoading]);

  const handleInput = useCallback((e) => {
    setEmail(e.target.value);

      if (!validateEmail(e.target.value)) {
        setNotAllow(true);
      } else {
        setNotAllow(false);
      }
  },[]);

  return (
    <div className="modal-wrapper">
    <article className="edit-candidate-email">
      <header className="modal-header">
        <span className="title fs16">{t('btn.add-candidate')}</span>
        <i className="close">
          <CloseIcon onClick={dismissModal} />
        </i>
      </header>
      <main className="modal-main">
        <aside className="apply-input-box">
          <ApplyInputBox
            inputType="text"
            placeholder={t('text-field.label.email')}
            label={t('text-field.label.email')}
            required
            name="email"
            value={email}
            handleInput={handleInput}
            notAllow={notAllow}
            icon={<EmailIcon />}
          />
        </aside>
        <Button isDisabled={!email || notAllow} theme="grey" onClick={onSubmitHandler} size={48} label={t('btn.submit')} isLoading={isLoading} />
      </main>
    </article>
    </div>
  );
};
