import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  Timestamp,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './index';

const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, 'asia-northeast1');

export const _uploadBytes = ({
  collection,
  category,
  email,
  fileName,
  bytes,
  rid,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const storageRef = ref(
        storage,
        `${collection}/${category}/${rid}/${email}/${fileName}`
      );

      await uploadBytes(storageRef, bytes);
      const downloadURL = await getDownloadURL(storageRef);
      return resolve(downloadURL);
    } catch (e) {
      return reject(e);
    }
  });
