import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { ReactComponent as ChevronBottomIcon } from 'assets/images/icon/chevron-bottom-dropdown.svg';

const ApplyAgreeCheckbox = ({
  required,
  desc,
  label,
  isCheckbox,
  checked,
  onChange,
}) => {
  const [visibilityDropdown, setVisibilityDropdown] = useState(false);
  const [dropdownAnimation, setDropdownAnimation] = useState(false);
  const [repeat, setRepeat] = useState(false);

  useEffect(() => {
    if (visibilityDropdown) {
      clearTimeout(repeat);
      setDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setDropdownAnimation(false), 150));
    }
  }, [visibilityDropdown]);

  const onClickToggleButton = () => {
    setVisibilityDropdown(!visibilityDropdown);
  };

  return (
    <div className="apply-agree-checkbox-wrapper">
      <div className={`apply-agree-checkbox ${visibilityDropdown}`}>
        <aside className="check-box-flex">
          {isCheckbox && checked !== undefined ? (
            <>
              <FormControlLabel
                className="apply-checkbox"
                sx={{ gap: '4px' }}
                control={
                  <Checkbox
                    className="check"
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                label={label}
              />
              <i className={`fs12 ${required && 'required'}`}>
                {required ? 'Required' : 'Optional'}
              </i>
            </>
          ) : (
            <span
              className="apply-agree-title fs16"
              onClick={onClickToggleButton}
            >
              {label}
            </span>
          )}
        </aside>
        <aside className="icon" onClick={onClickToggleButton}>
          <ChevronBottomIcon />
        </aside>
      </div>
      {dropdownAnimation && (
        <div className="apply-agree-desc">
          <div
            className={`apply-dropdown-animation ${
              visibilityDropdown ? 'slide-in' : 'slide-out'
            }`}
          >
            {desc}
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplyAgreeCheckbox;
