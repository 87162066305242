import { MaterialSelectSmall } from 'components/select/MaterialSelect';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDisabled } from 'utils/convertKey';

const MembersFilter = ({
  menuItems,
  users,
  setFilteredList,
  userInfo,
  filter,
  setFilter,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!users) return;

    const filteredData = users?.reduce((acc, item) => {
      if (item?.ownerUid === userInfo?.ownerUid) {
        if (
          !filter?.memberType?.key ||
          item.memberType === filter?.memberType?.key ||
          (filter?.memberType?.key === 5 && item.memberType === 1)
        ) {
          if (!filter?.team?.key || item.team === filter?.team?.key) {
            if (
              !filter?.workPlace?.key ||
              item.workspace === filter?.workPlace?.key
            ) {
              if (
                !filter?.status?.key ||
                convertDisabled(item.disabled) === filter?.status?.i18nKey
              ) {
                acc?.push(item);
              }
            }
          }
        }
      }

      return acc;
    }, []);

    setFilteredList(filteredData);
  }, [filter, users]);

  return (
    <div className="members-filter">
      <MaterialSelectSmall
        radius={'8px'}
        selectedLabel={
          filter?.memberType ? t(filter?.memberType?.i18nKey) : 'Member type'
        }
        menuList={menuItems.membersType}
        onClick={(option) => setFilter({ ...filter, memberType: option })}
      />
      <MaterialSelectSmall
        radius={'8px'}
        selectedLabel={filter?.team ? t(filter?.team?.i18nKey) : 'Team'}
        menuList={menuItems.team}
        onClick={(option) => setFilter({ ...filter, team: option })}
      />
      <MaterialSelectSmall
        radius={'8px'}
        selectedLabel={
          filter?.workPlace ? t(filter?.workPlace?.i18nKey) : 'Location'
        }
        menuList={menuItems.workspace}
        onClick={(option) => setFilter({ ...filter, workPlace: option })}
      />
      <MaterialSelectSmall
        radius={'8px'}
        selectedLabel={filter?.status ? t(filter?.status?.i18nKey) : 'Status'}
        menuList={menuItems.status}
        onClick={(option) => setFilter({ ...filter, status: option })}
      />
    </div>
  );
};

export default MembersFilter;
