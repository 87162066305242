import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import {
  _updateSchedule,
  _setRecruitment,
  _getRecruitment,
  _getRecruitments,
  _createRecruitment,
  _updateRecruitment,
  _addRecruitmentCandidate,
  _updateRecruitmentCandidate,
  _getCandidatesOfRecruitment,
  _setCandidateOfRecruitment,
  _getCandidateOfRecruitment,
  _deleteCandidateAtPreInterviewer,
  _deleteCandidateOfRecruitment,
  _editRecruitmentCandidateEmail,
  _deleteFieldRecruitmentCandidate,
  _deleteInterviewerRecruitmentCandidate, _deleteAssessmentResultRecruitmentCandidate,
} from 'utils/firebase/recruitments/recruitments';
import { _getUserInfo } from 'utils/firebase/auth';
import { serializeError } from 'serialize-error';
import axios from 'axios';
import * as Sentry from '@sentry/react';

const initialState = {
  updateRecruitmentLoading: false,
  updateRecruitmentError: null,
  updateRecruitmentSuccess: null,
  getRecruitmentsLoading: false,
  getRecruitmentsError: null,
  getRecruitmentsSuccess: null,
  getCandidatesLoading: false,
  getCandidatesSuccess: null,
  getCandidatesError: null,
  countStageLoading: false,
  countStateSuccess: null,
  countStateError: null,
  getGucciCandidateListLoading: false,
  getGucciCandidateListSuccess: null,
  getGucciCandidateListError: null,
};

export const createRecruitment = createAsyncThunk(
  'recruitments/createRecruitment',
  async (payload, { getState, rejectWithValue }) => {
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _createRecruitment({
        uid,
        payload,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateRecruitment = createAsyncThunk(
  'recruitments/updateRecruitment',
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _updateRecruitment({
        uid,
        ...payload,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getRecruitments = createAsyncThunk(
  'recruitments/getRecruitments',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const { memberType } = payload;

    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;

      const results = await _getRecruitments({ uid });
      const tasks = [];

      for (const result of results) {
        const aid = result?.rid;
        if (
          result.isSchedule &&
          result.endPeriodSchedule < new Date().getTime()
        ) {
          tasks.push(dispatch(changeStateOfRecruitment({ aid })).unwrap());
          tasks.push(
            dispatch(
              updateSchedule({
                aid,
                isSchedule: false,
                startPeriodSchedule: result.startPeriodSchedule,
                endPeriodSchedule: result.endPeriodSchedule,
              })
            ).unwrap()
          );
        }
      }
      await Promise.all(tasks);

      let docs;
      if (memberType !== 15) {
        docs = await _getRecruitments({ uid });
      } else {
        const owner = await _getRecruitments({ uid });
        const member = await _getRecruitments({
          uid: getState().auth.user?.uid,
        });

        if (owner.length === 0 || member.length === 0) return;

        docs = owner.reduce((acc, obj) => {
          if (member.some((item) => item.rid === obj.rid)) {
            acc.push(obj);
          }
          return acc;
        }, []);
      }

      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getRecruitment = createAsyncThunk(
  'recruitments/getRecruitment',
  async (payload = {}, { getState, rejectWithValue }) => {
    const { aid, ouid, type } = payload;
    try {
      let uid;

      if (type === 'interviewer') {
        uid = getState().auth.user?.uid;
      } else {
        uid =
          getState().auth.userInfo?.ownerUid ||
          getState().auth.user?.uid ||
          ouid;
      }

      const doc = await _getRecruitment({ uid, aid });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const changeStateOfRecruitment = createAsyncThunk(
  'recruitments/changeStateOfRecruitment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;

      const { isRecruitmentArchived: isArchived } = await _getRecruitment({
        uid,
        aid,
      });

      return await _setRecruitment({
        uid,
        aid,
        data: {
          isRecruitmentArchived: !isArchived,
        },
      });
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateSchedule = createAsyncThunk(
  'recruitments/updateSchedule',
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;

      await _updateSchedule({
        uid,
        ...payload,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidatesOfRecruitment = createAsyncThunk(
  'recruitments/getCandidatesOfRecruitment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, memberType } = payload;

    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;

      if(!uid) return;

      let docs;
      if (memberType !== 15) {
        docs = await _getCandidatesOfRecruitment({ uid, aid });
      } else {
        const owner = await _getCandidatesOfRecruitment({ uid, aid });
        const member = await _getCandidatesOfRecruitment({
          uid: getState().auth.user?.uid,
          aid,
        });

        if (owner.length === 0 || member.length === 0) return;

        docs = owner.reduce((acc, obj) => {
          if (
            member.some(
              (item) =>
                item.id === obj.id &&
                item.recruitProgress === obj.recruitProgress
            )
          ) {
            acc.push(obj);
          }
          return acc;
        }, []);
      }

      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateRecruitmentCandidate = createAsyncThunk(
  'recruitments/updateRecruitmentCandidate',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const uid = getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
    const memberType = getState().auth.userInfo?.memberType;
    const {
      aid,
      cid,
      recruitProgress,
      firstName,
      lastName,
      job,
      lang,
      processLength,
      finalHiringStep,
      phoneNumber,
      movePosition,
      rejectType,
    } = payload;
    const { logoURL, corporation } = await _getUserInfo({ uid });

    try {
      await _updateRecruitmentCandidate({
        uid,
        aid,
        cid,
        recruitProgress,
        finalHiringStep,
        movePosition,
      });

      if (recruitProgress === 1) {
        await _setCandidateOfRecruitment({
          uid,
          aid,
          cid,
          progressSubmit: {
            [recruitProgress]: {
              invitedAt: Timestamp.now(),
            },
          },
        });
      }

      // step 1 pass => invite ai interview email
      if (recruitProgress === 2) {
        const {userInfo} = getState().auth;
        const reviewer = userInfo?.lastName + userInfo?.firstName;
        await _setCandidateOfRecruitment({
          uid,
          aid,
          cid,
          progressSubmit: {
            1: {
              reviewer
            },
            [recruitProgress]: {
              invitedAt: Timestamp.now(),
            },
          },
        });
        // invited digital interview
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/apply/invite/assessment`,
          {
            companyName: corporation,
            logoURL,
            email: cid,
            firstName,
            lastName,
            job,
            uid,
            aid,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );

        // digital interview 평가 초대 안내 알림톡
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/aligo/kakao/send`,
          {
            phone_no: phoneNumber,
            subject: '구찌 코리아',
            name: `${lastName + firstName}`,
            template_code: 'TP_2415',
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (
        (processLength === 8 && recruitProgress === 6) ||
        (processLength === 7 && recruitProgress === 5)
      ) {
        await _setCandidateOfRecruitment({
          uid,
          aid,
          cid,
          progressSubmit: {
            [recruitProgress]: {
              invitedAt: Timestamp.now(),
            },
          },
        });
      }

      // reject email
      if (recruitProgress === -1 && rejectType) {
        await _setCandidateOfRecruitment({
          uid,
          aid,
          cid,
          progressSubmit: {
            [finalHiringStep]: {
              opinion:
                rejectType === 'reject'
                  ? 'Reject'
                  : rejectType === 'other position'
                  ? 'Other position'
                  : 'Give up',
            },
          },
        });

        if (rejectType === 'reject') {
          await axios.post(
            `${process.env.REACT_APP_POST_BOX}/recruitment/reject`,
            {
              companyName: corporation,
              logoURL,
              email: cid,
              firstName,
              lastName,
              job,
            },
            {
              headers: {
                'Publish-Type': process.env.REACT_APP_NODE_ENV,
                'User-Lang': lang || getState().variable.lang.slice(0, 2),
              },
            }
          );
        }
      }

      return dispatch(
        getCandidatesOfRecruitment({
          aid,
          memberType,
        })
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const sendAiInterviewRemindMail = createAsyncThunk(
  'recruitments/sendAiInterviewRemindMail',
  async (payload, { getState, rejectWithValue }) => {
    const uid = getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
    const {
      aid,
      cid,
      firstName,
      lastName,
      job,
      lang,
      phoneNumber
    } = payload;
    const { logoURL, corporation } = await _getUserInfo({ uid });
    try {
      await axios.post(
        `${process.env.REACT_APP_POST_BOX}/apply/invite/assessment`,
        {
          companyName: corporation,
          logoURL,
          email: cid,
          firstName,
          lastName,
          job,
          uid,
          aid,
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
            'User-Lang': lang || getState().variable.lang.slice(0, 2),
          },
        }
      );
      await axios.post(
        `${process.env.REACT_APP_POST_BOX}/aligo/kakao/send`,
        {
          phone_no: phoneNumber,
          subject: '구찌 코리아',
          name: `${lastName + firstName}`,
          template_code: 'TP_2415',
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
            'User-Lang': lang || getState().variable.lang.slice(0, 2),
          },
        }
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const addRecruitmentCandidate = createAsyncThunk(
  'recruitments/addRecruitmentCandidate',
  async (payload, { getState, rejectWithValue }) => {
    const {
      uid,
      rid,
      email,
      firstName,
      lastName,
      lang,
      job,
      progressSubmit,
      recruitProgress,
      phoneNumber,
    } = payload;
    const { logoURL, corporation } = await _getUserInfo({ uid });

    try {
      await _addRecruitmentCandidate({
        uid,
        rid,
        email,
        firstName,
        lastName,
        recruitProgress,
        payload: { ...payload },
      });

      if (recruitProgress === 1) {
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/apply/complete`,
          {
            companyName: corporation,
            logoURL,
            email,
            firstName,
            lastName,
            job,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
        // 지원서 접수 확인 안내 알림톡
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/aligo/kakao/send`,
          {
            phone_no: progressSubmit[1].phoneNumber,
            subject: '구찌 코리아',
            name: `${lastName + firstName}`,
            template_code: 'TO_9789',
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (recruitProgress === 2) {
        // AI 인터뷰 안내 메일 / 알림톡
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/apply/invite/assessment`,
          {
            companyName: corporation,
            logoURL,
            email,
            firstName,
            lastName,
            job,
            uid,
            aid: rid,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );

        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/aligo/kakao/send`,
          {
            phone_no: phoneNumber,
            subject: '구찌 코리아',
            name: `${lastName + firstName}`,
            template_code: 'TP_2415',
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const editRecruitmentCandidateEmail = createAsyncThunk(
  'recruitments/editRecruitmentCandidateEmail',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const {
      uid,
      rid,
      email,
      candidateInfo
    } = payload;

    try {
      await _editRecruitmentCandidateEmail({
        uid,
        rid,
        email,
        candidateInfo,
      });

      const memberType = getState().auth.userInfo?.memberType;
      return dispatch(
        getCandidatesOfRecruitment({
          aid: rid,
          memberType,
        })
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const deleteFieldRecruitmentCandidate = createAsyncThunk(
  'recruitments/deleteFieldRecruitmentCandidate',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const {
      rid,
      email,
      newCandidateData
    } = payload;

    const ouid = getState().auth.userInfo?.ownerUid;
    try {
      await _deleteFieldRecruitmentCandidate({
        ouid,
        rid,
        email,
        newCandidateData,
      });

      const memberType = getState().auth.userInfo?.memberType;
      return dispatch(
        getCandidatesOfRecruitment({
          aid: rid,
          memberType,
        })
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const deleteAssessmentResultRecruitmentCandidate = createAsyncThunk(
  'recruitments/deleteFieldRecruitmentCandidate',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const {
      rid,
      email,
    } = payload;

    const ouid = getState().auth.userInfo?.ownerUid;
    try {
      await _deleteAssessmentResultRecruitmentCandidate({
        ouid,
        rid,
        email,
      });

      const memberType = getState().auth.userInfo?.memberType;
      return dispatch(
        getCandidatesOfRecruitment({
          aid: rid,
          memberType,
        })
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);


export const deleteInterviewerRecruitmentCandidate = createAsyncThunk(
  'recruitments/deleteFieldRecruitmentCandidate',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const {
      uid,
      rid,
      email,
    } = payload;

    try {
      await _deleteInterviewerRecruitmentCandidate({
        uid,
        rid,
        email,
      });

      const memberType = getState().auth.userInfo?.memberType;
      return dispatch(
        getCandidatesOfRecruitment({
          aid: rid,
          memberType,
        })
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const deleteRecruitmentCandidate = createAsyncThunk(
  'recruitments/deleteRecruitmentCandidate',
  async (payload = {}, { rejectWithValue, dispatch, getState }) => {
    const { aid, email } = payload;
    try {
      const uid = getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const memberType = getState().auth.userInfo?.memberType;
      await _deleteCandidateOfRecruitment({
        uid,
        aid,
        email,
      });
      return dispatch(
        getCandidatesOfRecruitment({
          aid,
          memberType,
        })
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);


export const countStage = createAsyncThunk(
  'recruitments/countStage',
  async (payload, { rejectWithValue }) => {
    const { candidates, count } = payload;
    const stepArr = new Array(count);
    stepArr.fill(0);

    try {
      candidates?.forEach((candidate) => {
        switch (candidate?.recruitProgress) {
          case 1:
            stepArr[0] = stepArr[0] + 1;
            break;
          case 2:
            stepArr[1] = stepArr[1] + 1;
            break;
          case 3:
            stepArr[2] = stepArr[2] + 1;
            break;
          case 4:
            stepArr[3] = stepArr[3] + 1;
            break;
          case 5:
            stepArr[4] = stepArr[4] + 1;
            break;
          case 6:
            stepArr[5] = stepArr[5] + 1;
            break;
          case 7:
            stepArr[6] = stepArr[6] + 1;
            break;
          case -1:
            stepArr[stepArr.length - 1] = stepArr[stepArr.length - 1] + 1;
            break;
          default:
            break;
        }
      });

      return {
        result: { counts: stepArr },
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const recruitmentsCountStage = createAsyncThunk(
  'recruitments/recruitmentsCountStage',
  async (payload, { rejectWithValue, dispatch }) => {
    const { recruitments, memberType, countArr } = payload;

    try {
      const tasks = [];
      let idx = 0;

      for (const recruitment of recruitments) {
        const { rid, recruitmentName } = recruitment;

        await dispatch(getCandidatesOfRecruitment({ aid: rid, memberType }))
          .unwrap()
          .then(({ docs }) => {
            tasks.push(
              dispatch(countStage({ candidates: docs, count: countArr[idx] }))
                .unwrap()
                .then(({ result }) => {
                  result.rid = rid;
                  result.name = recruitmentName;
                  idx = idx + 1;
                  return result;
                })
            );
          });
      }

      const results = await Promise.all(tasks);

      return {
        results,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const setCandidateOfRecruitment = createAsyncThunk(
  'recruitments/setCandidateOfRecruitment',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const {
      aid,
      cid,
      progressSubmit,
      ouid,
      type,
      stage,
      interviewerEmail,
      interviewerFirstName,
      interviewerLastName,
      candidateFirstName,
      candidateLastName,
      step,
      interviewDate,
      interviewTime,
      interviewLocation,
      refreshToken,
      dueDate,
      lang,
      companyAddr,
      job,
      phoneNumber,
      precautions,
    } = payload;

    const uid =
      getState().auth.userInfo?.ownerUid || getState().auth.user?.uid || ouid;
    const memberType = getState().auth.userInfo?.memberType;
    const { logoURL, corporation } = await _getUserInfo({ uid });

    try {
      await _setCandidateOfRecruitment({
        uid,
        aid,
        cid,
        progressSubmit,
      });

      if (type === 'toInterviewer') {
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/recruitment/interview/interviewer`,
          {
            email: interviewerEmail,
            companyName: corporation,
            logoUrl: logoURL,
            firstName: interviewerFirstName,
            lastName: interviewerLastName,
            candidateFirstName,
            candidateLastName,
            redirectUrl: `${process.env.REACT_APP_MEDIA_URL}/customer/recruitments/${aid}/${stage}`,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (type === 'toCandidate') {
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/recruitment/interview/invitation`,
          {
            email: cid,
            companyName: corporation,
            logoURL,
            firstName: candidateFirstName,
            lastName: candidateLastName,
            interviewLocation,
            dueDate,
            uid,
            aid,
            step,
            precautions,
            refresh_token: !refreshToken ? 'none' : refreshToken,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
        // 대면 면접 일정 안내 알림톡
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/aligo/kakao/send`,
          {
            phone_no: phoneNumber,
            subject: '구찌 코리아',
            name: `${candidateLastName + candidateFirstName}`,
            template_code: 'TO_9791',
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (type === 'confirmDate') {
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/recruitment/confirm/interview`,
          {
            email: cid,
            companyName: corporation,
            logoURL,
            firstName: candidateFirstName,
            lastName: candidateLastName,
            interviewerFirstName: null,
            interviewerLastName: null,
            interviewerEmail: null,
            isInterviewer: false,
            interviewDate,
            interviewLocation,
            interviewTime,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
        // interviewer
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/recruitment/confirm/interview`,
          {
            email: interviewerEmail,
            companyName: corporation,
            logoURL,
            firstName: interviewerFirstName,
            lastName: interviewerLastName,
            interviewerFirstName: candidateFirstName || null,
            interviewerLastName: candidateLastName || null,
            interviewerEmail: cid || null,
            isInterviewer: true,
            interviewDate,
            interviewLocation,
            interviewTime,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
        // 대면 면접 일정 확정 안내 알림톡
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/aligo/kakao/send`,
          {
            phone_no: phoneNumber,
            subject: '구찌 코리아',
            name: `${candidateLastName + candidateFirstName}`,
            template_code: 'TO_9792',
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (type === 'remind') {
        // 대면 면접관 일정 조율 독촉 안내
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/recruitment/remind/interviewer`,
          {
            companyName: corporation,
            email: interviewerEmail,
            interviewerFirstName,
            interviewerLastName,
            firstName: candidateFirstName,
            lastName: candidateLastName,
            redirectURL: `${process.env.REACT_APP_MEDIA_URL}/customer/recruitments/${aid}/${stage}`,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (type === 'evaluation') {
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/complete/evaluation`,
          {
            email: `${process.env.REACT_APP_GUCCI_HR_EMAIL}`,
            companyName: corporation,
            logoURL,
            firstName: candidateFirstName,
            lastName: candidateLastName,
            redirectURL: `${process.env.REACT_APP_MEDIA_URL}/customer/recruitments/${aid}/${stage}`,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (type === 'passedStore') {
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/recruitment/compensation`,
          {
            companyName: corporation,
            logoURL,
            email: cid,
            firstName: candidateFirstName,
            lastName: candidateLastName,
            dueDate,
            job,
            companyAddr,
            uid,
            aid,
            step,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
        // 증빙 서류 제출 안내 알림톡
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/aligo/kakao/send`,
          {
            phone_no: phoneNumber,
            subject: '구찌 코리아',
            name: `${candidateLastName + candidateFirstName}`,
            template_code: 'TO_9794',
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (type === 'offer') {
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/recruitment/offer`,
          {
            companyName: corporation,
            logoURL,
            email: cid,
            firstName: candidateFirstName,
            lastName: candidateLastName,
            dueDate,
            job,
            uid,
            aid,
            step,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
        // 오퍼레터 안내 알림톡
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/aligo/kakao/send`,
          {
            phone_no: phoneNumber,
            subject: '구찌 코리아',
            name: `${candidateLastName + candidateFirstName}`,
            template_code: 'TO_9795',
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (type === 'passedOffer') {
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/recruitment/complete`,
          {
            companyName: corporation,
            logoURL,
            email: cid,
            firstName: candidateFirstName,
            lastName: candidateLastName,
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
        // 최종 합격 안내 알림톡
        await axios.post(
          `${process.env.REACT_APP_POST_BOX}/aligo/kakao/send`,
          {
            phone_no: phoneNumber,
            subject: '구찌 코리아',
            name: `${candidateLastName + candidateFirstName}`,
            template_code: 'TO_9802',
          },
          {
            headers: {
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
              'User-Lang': lang || getState().variable.lang.slice(0, 2),
            },
          }
        );
      }

      if (type === 'retryInterview') return;

      return dispatch(
        getCandidatesOfRecruitment({
          aid,
          memberType,
        })
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidateOfRecruitment = createAsyncThunk(
  'recruitments/getCandidateOfRecruitment',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, rid, cid } = payload;
    try {
      const doc = await _getCandidateOfRecruitment({ uid, rid, cid });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const deleteCandidateAtPreInterviewer = createAsyncThunk(
  'recruitments/deleteCandidateAtPreInterviewer',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { interviewerId, aid, email } = payload;
    try {
      await _deleteCandidateAtPreInterviewer({
        interviewerId,
        aid,
        email,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidatesList = createAsyncThunk(
  'recruitments/getCandidatesList',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { pages, per_page, name, email, phone, is_excel } = payload;

    try {
      const data = await axios.get(
        `${process.env.REACT_APP_SWAGGER_PATH}/gucci/list`,
        {
          params: {
            is_excel,
            pages,
            per_page,
            name,
            email,
            phone,
          },
        }
      );
      return data;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const checkSupportCountWithin3Years = createAsyncThunk(
  'recruitments/checkSupportCountWithin3Years',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { uid, aid, cid, email, phone } = payload;

    try {
      const data = await axios.get(
        `${process.env.REACT_APP_SWAGGER_PATH}/gucci/count`,
        {
          params: {
            email,
            phone,
            uid,
            aid,
            cid,
          },
        }
      );
      return data;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const postInsertCandidateData = createAsyncThunk(
  'recruitments/postInsertCandidateData',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { uid, aid, cid, data } = payload;

    try {
      await axios.post(
        `${process.env.REACT_APP_SWAGGER_PATH}/gucci/insert`,
        { ...data },
        {
          params: {
            uid,
            aid,
            cid,
          },
        }
      );
      return {
        success: true,
      };
    } catch (e) {
      Sentry.captureException(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const postSyncWithRDB = createAsyncThunk(
  'recruitments/postSyncWithRDB',
  async (payload = {}, { rejectWithValue, getState }) => {
    const uid = getState().auth.userInfo?.ownerUid;

    try {
      await axios.post(
        `${process.env.REACT_APP_SWAGGER_PATH}/gucci/update`,
        {},
        {
          params: {
            uid,
          },
        }
      );
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getExcelList = createAsyncThunk(
  'recruitments/getExcelList',
  async (payload = {}, { rejectWithValue }) => {
    const { is_excel } = payload;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_PATH}/gucci/list`,
        {
          params: {
            is_excel,
          },
        }
      );

      return response.data;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const downloadCandidatesExcel = createAsyncThunk(
  'recruitments/downloadCandidatesExcel',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SWAGGER_PATH}/gucci/excel`
      );

      return res;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const recruitmentsSlice = createSlice({
  name: 'recruitments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateRecruitment.pending, (state) => {
        state.updateRecruitmentLoading = true;
        state.updateRecruitmentError = null;
      })
      .addCase(updateRecruitment.fulfilled, (state, action) => {
        state.updateRecruitmentLoading = false;
        state.updateRecruitmentSuccess = action.payload;
      })
      .addCase(updateRecruitment.rejected, (state, action) => {
        state.updateRecruitmentLoading = false;
        state.updateRecruitmentError = action.error.message;
      });
    builder
      .addCase(getRecruitments.pending, (state) => {
        state.getRecruitmentsLoading = true;
        state.getRecruitmentsError = null;
      })
      .addCase(getRecruitments.fulfilled, (state, action) => {
        state.getRecruitmentsLoading = false;
        state.getRecruitmentsSuccess = action.payload?.docs;
      })
      .addCase(getRecruitments.rejected, (state, action) => {
        state.getRecruitmentsLoading = false;
        state.getRecruitmentsError = action.error.message;
      });
    builder
      .addCase(getCandidatesOfRecruitment.pending, (state) => {
        state.getCandidatesLoading = true;
        state.getCandidatesError = null;
      })
      .addCase(getCandidatesOfRecruitment.fulfilled, (state, action) => {
        state.getCandidatesLoading = false;
        state.getCandidatesSuccess = action.payload?.docs;
      })
      .addCase(getCandidatesOfRecruitment.rejected, (state, action) => {
        state.getCandidatesLoading = false;
        state.getCandidatesError = action.error.message;
      });
    builder
      .addCase(recruitmentsCountStage.pending, (state) => {
        state.countStageLoading = true;
      })
      .addCase(recruitmentsCountStage.fulfilled, (state, action) => {
        state.countStageLoading = false;
        state.countStateSuccess = action.payload?.results;
      })
      .addCase(recruitmentsCountStage.rejected, (state, action) => {
        state.countStageLoading = false;
        state.countStateError = action.error.message;
      });
    builder
      .addCase(getCandidatesList.pending, (state) => {
        state.getGucciCandidateListLoading = true;
      })
      .addCase(getCandidatesList.fulfilled, (state, action) => {
        state.getGucciCandidateListLoading = false;
        state.getGucciCandidateListSuccess = action.payload?.data;
      })
      .addCase(getCandidatesList.rejected, (state, action) => {
        state.getGucciCandidateListLoading = false;
        state.getGucciCandidateListError = action.error.message;
      });
  },
});

export const {} = recruitmentsSlice.actions;

export default recruitmentsSlice.reducer;
