import React, { useState, useEffect, useRef } from 'react';
import Confetti from 'react-confetti';
import { ReactComponent as Illust } from 'assets/images/icon/completed-illustration.svg';

const CompletedOffer = () => {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const confetiRef = useRef(null);

  useEffect(() => {
    setHeight(confetiRef.current.clientHeight);
    setWidth(confetiRef.current.clientWidth);
  }, []);

  return (
    <main
      id="pages-completed-apply"
      className="pages-completed-date custom-completed"
      ref={confetiRef}
    >
      <section className="completed-apply-container">
        <article className="completed-text-wrapper">
          <h2 className="fs28">
            구찌 Dream-makers의 일원이 되신 걸 진심으로 축하드립니다.
          </h2>
          <div className="color-box">
            <h3 className="fs80">Thank you.</h3>
            <div className="img-box">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/munchskilldev.appspot.com/o/gucci%2Fnew_empowering_email.png?alt=media&token=3675f6f9-4d77-4f54-a903-976892aa5f7d"
                alt="logo"
              />
            </div>
          </div>
          <Confetti numberOfPieces={200} width={width} height={height} />
        </article>
      </section>
    </main>
  );
};

export default CompletedOffer;
