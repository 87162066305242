import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { useModal } from 'utils/modal';
import { useTranslation } from 'react-i18next';
import QuestionContent from 'components/detailModal/QuestionContent';

// lang를 넘겨 OBPI, KHAI-P등 인적성검사의 경우 예외처리에 사용한다.

const LibraryDetail = ({ test, lang, detail, event: { message } }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();

  const onDismissHandler = () => {
    dismissModal({
      message,
    });
  };

  return (
    <article className="modals-assessment-view-detail-library">
      <header className="header">
        <h3 className="header-title fs20">{t('title.test-preview')}</h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <div className="title-box">
        <h2 className="fs28">{test?.title_en || test?.category_skill}</h2>
      </div>
      <section className="contents">
        <QuestionContent test={test} detail={detail} lang={lang} />
      </section>
    </article>
  );
};

export default LibraryDetail;
