import React from 'react';
import MainLogo from 'components/logo/MainLogo';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { sendValidateMail } from 'store/slices/authSlice';
import { CircularProgress } from '@mui/material';
import { toast } from 'utils/notify';
import PropTypes from 'prop-types';

const ExpireAuthToken = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account } = useParams();
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('authToken');
  const type = searchParams.get('type');

  const [isLoading, setIsLoading] = React.useState(false);
  const [didSend, setDidSend] = React.useState(false);

  const onSubmitClickHandler = () => {
    if (authToken) {
      if (type === 'set') {
        onSubmitClickHandlerSet();
      } else if (type === 'reset') {
        navigate(`${account}/auth/set-password`);
      }
    }
  };

  const onSubmitClickHandlerSet = () => {
    setIsLoading(true);
    executeAction(
      sendValidateMail({
        authToken,
        type: 'expired',
        account,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setDidSend(true);
        toast(t('toast.desc.send-email'));
      })
      .catch((e) => {
        setIsLoading(false);
        console.dir(e);
        switch (e?.message) {
          case 'invalid-email':
            toast(t('toast.desc.invalid-email'));
            break;
          case 'invalid-job-email':
            toast(t('toast.desc.invalid-job-email'));
            break;
          case 'already_joined':
            toast(t('toast.desc.already-joined'));
            break;
          default:
            break;
        }
      });
  };

  const onGoBackClickHandler = () => {
    navigate(`${account}/auth/signin`);
  };

  return (
    <main className="pages-auth-expire-auth-token">
      <MainLogo className="logo" />
      <section className="explain">
        <h2 className="explain-main fs24">
          {type === 'set'
            ? t('set-pw-expired.title.set-pw-expired')
            : t('reset-pw-expired.title.reset-pw-expired')}
        </h2>
        <h3 className="explain-sub fs16">
          {type === 'set'
            ? t('set-pw-expired.desc.set-pw-expired')
            : t('reset-pw-expired.desc.reset-pw-expired')}
        </h3>
      </section>
      <section className="form">
        <button
          className={`form-submit common-button fsbtn16 ${
            !isLoading && !didSend && 'active'
          } ${isLoading && 'pending'}`}
          onClick={!isLoading && !didSend ? onSubmitClickHandler : null}
        >
          {type === 'set' ? t('btn.set-pw') : t('btn.reset-pw')}
          {isLoading && <CircularProgress size={25} color="grey" />}
        </button>
      </section>
      <button
        className="go-back text-button fsbtn16 primary"
        onClick={onGoBackClickHandler}
      >
        {t('btn.back-login')}
      </button>
    </main>
  );
};

ExpireAuthToken.propTypes = {};

export default ExpireAuthToken;
