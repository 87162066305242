import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowBotton } from 'assets/images/icon/arrow-down.svg';
import { convertQuestionType } from 'utils/converter';
import { useTranslation } from 'react-i18next';

const ToggleBodyHeader = ({
  test,
  visibilityDropdown,
  currentCandidate,
  onClick,
}) => {
  const { t } = useTranslation();
  const [averageScore, setAverageScore] = useState(0);

  useEffect(() => {
    if (typeof test?.question === 'string') return;
    const selectedQuestion = test?.question?.filter((item) => item.selected);
    const scoreArr = [];

    Object.keys(currentCandidate?.results).map((result) => {
      if (
        result === 'survey' ||
        result === 'detect' ||
        result === 'voiceTest' ||
        currentCandidate?.results[result].preset_score === undefined
      )
        return;

      selectedQuestion.forEach((question) => {
        if (question.id === result) {
          scoreArr.push(currentCandidate?.results[result].preset_score);
        }
      });
    });

    if (scoreArr.length === 0) return;

    const average = scoreArr.reduce((a, b) => a + b, 0) / scoreArr.length;

    setAverageScore(average);
  }, [test, currentCandidate]);

  const convertTagClassName = (tag) => {
    let name;

    switch (tag) {
      case 'tag.video':
        name = 'video';
        break;
      case 'tag.multiple-choice':
        name = 'multiple-choice';
        break;
      case 'tag.essay':
        name = 'essay';
        break;
      case 'tag.code':
        break;
      default:
        name = '';
        break;
    }

    return name;
  };

  return (
    <div className="toggle-body-header">
      <ul className="toggle-header" onClick={onClick}>
        <li className="fs14">
          {test?.category_skill || test?.title_en || test?.section}
        </li>
        <li className="fs14">
          <i
            className={`fs12 tag ${convertTagClassName(
              convertQuestionType(test?.question_type || test?.section)
            )}`}
          >
            {t(convertQuestionType(test?.question_type || test?.section))}
          </i>
        </li>
        <li className="fs14">
          <BarGraphScore score={averageScore && averageScore} />
        </li>
        <li className="fs14">
          <span className={`arrow-toggle-button ${visibilityDropdown}`}>
            <ArrowBotton />
          </span>
        </li>
      </ul>
    </div>
  );
};

export default ToggleBodyHeader;

export const BarGraphScore = ({ score, barColor }) => {
  return (
    <div className="bar-graph-score">
      <div className="percent-wrapper">
        <span className="fs14">{Math.trunc(score * 100)}%</span>
      </div>
      <div className="progress-wrapper">
        <svg height="4" fill="none">
          <rect
            className="fill"
            x="0.25"
            width={`${
              score <= 0 || score === undefined ? 0 : Math.trunc(score * 100)
            }%`}
            height="4"
            rx="2"
            fill={barColor || '#808080'}
          />
        </svg>
      </div>
    </div>
  );
};
