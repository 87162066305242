import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { store } from 'store/index';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import reportWebVitals from './reportWebVitals';
import App from './App';
import 'utils/firebase/index';
import './i18n';

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_SENTRY_DSN
      : false,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
