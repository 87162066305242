import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

// slices
import authReducer from './slices/authSlice';
import modalReducer from './slices/modalSlice';
import variableSlice from './slices/variableSlice';
import assessmentsSlice from './slices/assessmentsSlice';
import candidatesSlice from './slices/candidatesSlice';
import testsSlice from './slices/testsSlice';
import profileReducer from './slices/profileSlice';
import membersReducer from './slices/recruitments/membersSlice';
import recruitmentsReducer from './slices/recruitments/recruitmentsSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    variable: variableSlice,
    assessments: assessmentsSlice,
    candidates: candidatesSlice,
    tests: testsSlice,
    profile: profileReducer,
    members: membersReducer,
    recruitments: recruitmentsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
