import axios from 'axios';
import { useEffect, useState } from 'react';
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button } from 'components/common/Button';
import { useTranslation } from 'react-i18next';

const OutLookCalendar = ({
  recruitment,
  processName,
  refreshToken,
}) => {
  const [Url, setUrl] = useState('');
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = useState(false);
  const [scheduleArr, setScheduleArr] = useState();

  useEffect(() => {
    if (refreshToken !== '') {
      setIsLogin(true);
      axios
        .post(
          `${process.env.REACT_APP_POST_BOX}/outlook/refresh`,
          { refresh_token: refreshToken },
          {
            headers: {
              'Content-Type': 'application/json',
              'Publish-Type': process.env.REACT_APP_NODE_ENV,
            },
          }
        )
        .then((response) => {
          axios
            .post(
              `${process.env.REACT_APP_POST_BOX}/outlook/calendar/read`,
              { access_token: response.data.access_token },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Publish-Type': process.env.REACT_APP_NODE_ENV,
                },
              }
            )
            .then((response) => {
              const arr = [];
              response.data.map((data) => {
                arr.push({
                  title: data.subject,
                  start: data.start,
                  end: data.end,
                });
              });
              setScheduleArr(arr);
            });
        });
    } else {
      setIsLogin(false);
      axios
        .get(`${process.env.REACT_APP_POST_BOX}/outlook/auth`, {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
          },
        })
        .then((response) => {
          setUrl(response.data);
        });
    }
  }, [refreshToken]);

  const setSession = () => {
    sessionStorage.setItem('rid', recruitment?.rid);
    sessionStorage.setItem('processName', processName);
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        events={scheduleArr}
      />
      {!isLogin && (
        <div className="outlook-login">
          <div>
            <a href={Url} style={{ textDecoration: 'none' }}>
              <Button
                label={t('btn.connect-outlook')}
                theme="blue"
                size="56"
                fontSize="fs16"
                onClick={setSession}
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutLookCalendar;
