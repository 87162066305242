import RecruitmentTableHead from 'components/recruitment/detail/RecruitmentTableHead';
import RecruitmentTableBody from 'components/recruitment/detail/onboarding/RecruitmentTableBody';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFilterOfRecruitmentProcess } from 'utils/hooks';

const HEADS = [
  'column.candidate-name',
  'field.preferred-channel',
  'column.submission-date',
  'column.job-store',
  'column.job-position',
  'column.onboarding-date',
  '-'
];

const Onboarding = () => {
  const recruitmentProps = useOutletContext();
  const { recruitment, candidates, userInfo } = recruitmentProps;
  const [, processLength] = useFilterOfRecruitmentProcess(recruitment);
  const [step, setStep] = useState(0);

  const [onboardingCandidates, setOnboardingCandidates] = useState([]);

  useEffect(() => {
    if (!processLength) return;

    let filtered;
    if (processLength === 7) {
      setStep(6);
      filtered = candidates?.filter((item) => item?.recruitProgress === 6);
    } else {
      setStep(7);
      filtered = candidates?.filter((item) => item?.recruitProgress === 7);
    }

    setOnboardingCandidates(filtered);
  }, [candidates, processLength]);

  return (
    <section className="dashboard-onboarding-contents table-border">
      <table className="recruitment-table">
        <caption>candidates onboarding list</caption>
        <RecruitmentTableHead heads={HEADS} />
        <RecruitmentTableBody
          candidates={onboardingCandidates}
          recruitment={recruitment}
          step={step}
        />
      </table>
    </section>
  );
};

export default Onboarding;
