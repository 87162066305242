/*eslint-disable */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { executeAction } from 'utils/redux';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import {
  getAssessment,
  getCandidateInAssessment,
} from 'store/slices/candidatesSlice';
import {
  sendEmailReview,
  sendEmailReject,
  sendEmailPass,
  getTestInfos,
  getLibTestInfos,
  getAttributes,
  getCultureAttributes,
  getCandidatesOfAssessment,
  setOBPIBenchmarkToAssessment,
  updateCandidate,
  getScoreResult,
  testsAverage,
  pass,
  review,
  reject,
  getKHAITestResult,
  setKHAIRetryResults,
} from 'store/slices/assessmentsSlice';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Box,
  TextareaAutosize,
} from '@mui/material';
import {
  convertHtmlToText,
  dateToDateFormat,
  convertSkillTypeToI18nKey,
  convertHtmlForDisplay,
} from 'utils/string';
import { useModal } from 'utils/modal';
import { useStatus } from 'utils/status';
import { toast } from 'utils/notify';
import { cmp, compare } from 'semver';
import { ReactComponent as ArrowBotton } from 'assets/images/icon/arrow-down.svg';
import { ReactComponent as PrintIcon } from 'assets/images/icon/print.svg';
import { ReactComponent as ProctoringSuccess } from 'assets/images/icon/chip-category-check.svg';
import { ReactComponent as ProctoringFailed } from 'assets/images/icon/chip-category.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icon/error_24px.svg';
import { ReactComponent as CandidateScore } from 'assets/images/icon/candidate-score-position.svg';
import { ReactComponent as ScoreGraph } from 'assets/images/icon/score-distribution-graph.svg';
import { ReactComponent as KHAILogo } from 'assets/images/logo/customer-candidates-logo-khai.svg';
import { ReactComponent as RefreshIcon } from 'assets/images/icon/refresh-white-18x18.svg';
import MaterialSelect from 'components/select/MaterialSelect';
import { Score, ProgressScore } from 'components/progress/Score';
import { history } from 'utils/event';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts';
import { Trans } from 'react-i18next';
import { useCheckedAdminPage } from 'utils/hooks';
import {
  getKHAI_P_Grade,
  getKHAI_P_AverageScore,
  getKHAI_P_BigDataAverageScore,
  getKHAIPColor,
  getKHAIPReliability,
  getKHAI_P_BigDataRank,
  getKhaiO_Reliability,
  getKhaiO_organization_color,
  ORGANIZATIONAL_LIST,
  PROPENSITY_LIST,
} from 'utils/khaifunc';
import KHAIPDetailGraph from './KHAIPDetailGraph';
import OBPIResult from './OBPIResult';
import LanguageContainer from 'components/assessment/languageTestResult/LanguageContainer';
import KhaiOInterview from 'components/assessment/khai/khai-o/KhaiOInterview';
import KhaiPInterview from 'components/assessment/khai/khai-p/KhaiPInterview';
import { useSelector } from 'react-redux';
import CustomBarChart from 'components/assessment/chart/CustomBarChart';
import TestResultToggleContainer from 'components/assessment/testResult/TestResultToggleContainer';
import { Button } from 'components/common/Button';
import CustomTestResultToggleContainer from 'components/assessment/testResult/CustomTestResultToggleContainer';

const __TABS__ = [
  {
    key: 'result',
    i18nKey: 'tab.assessment-result',
    onClick: ({ setCurTab }) => {
      setCurTab('result');
    },
  },
  {
    key: 'info',
    i18nKey: 'tab.candidate-info',
    onClick: ({ setCurTab }) => {
      setCurTab('info');
    },
  },
];

const __ASSESSMENTS_STAGE__ = [
  {
    key: 'under-investigation',
    i18nKey: 'text.hiring-stage.under-investigation',
    onClick: (
      { setStageShowDropdown, setIsEntireLoading, aid, candidate },
      callback
    ) => {
      const eventHandler = () => {
        setIsEntireLoading(true);
        executeAction(
          review({
            aid,
            email: candidate,
          })
        )
          .unwrap()
          .then(() => {
            setIsEntireLoading(false);
            callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
            callback && callback();
          });
      };
      setStageShowDropdown(false);
      eventHandler();
    },
  },
  {
    key: 'accept',
    i18nKey: 'text.hiring-stage.accept',
    onClick: (
      { setStageShowDropdown, setIsEntireLoading, aid, candidate },
      callback
    ) => {
      const eventHandler = () => {
        setIsEntireLoading(true);
        executeAction(
          pass({
            aid,
            email: candidate,
          })
        )
          .unwrap()
          .then(() => {
            setIsEntireLoading(false);
            callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
            callback && callback();
          });
      };
      setStageShowDropdown(false);
      eventHandler();
    },
  },
  {
    key: 'reject',
    i18nKey: 'text.hiring-stage.reject',
    onClick: (
      { setStageShowDropdown, setIsEntireLoading, aid, candidate },
      callback
    ) => {
      const eventHandler = () => {
        setIsEntireLoading(true);
        executeAction(
          reject({
            aid,
            email: candidate,
          })
        )
          .unwrap()
          .then(() => {
            setIsEntireLoading(false);
            callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
            callback && callback();
          });
      };
      setStageShowDropdown(false);
      eventHandler();
    },
  },
];

const _objectiveCats = {};

const OBPI_TESTS = [
  {
    category: 'fellowship',
    title: 'title.fellowship',
    left: 'desc.fellowship-left',
    right: 'desc.fellowship-right',
    disc: "유대는 성격 5요인(빅 파이브) 중 '외향성/내향성'을 평가합니다. 높은 점수를 받는 사람들은 보통 팀 혹은 다른 사람과 함께 일할 때 더 행복합니다. 낮은 점수를 받는 사람들은 일반적으로 어느 정도의 독립성을 요구하는 업무를 선호합니다.",
  },
  {
    category: 'authority',
    title: 'title.authority',
    left: 'desc.authority-left',
    right: 'desc.authority-right',
    disc: "권위는 강인함 대 부드러움이라는 성격 5요인(빅 파이브) 중 '우호성'을 평가합니다. 높은 점수를 받는 사람들은 보통 어려운 결정을 내릴 수 있습니다. 낮은 점수를 받는 사람들은 일반적으로 더 협력적인 접근을 선택합니다.",
  },
  {
    category: 'conformity',
    title: 'title.conformity',
    left: 'desc.conformity-left',
    right: 'desc.conformity-right',
    disc: "순응은 성격 5요인(빅 파이브) 중 '경험에 대한 개방성'을 평가합니다. 높은 점수를 받는 사람들은 일을 할 때 전통적인 방식을 선호하고 기존의 가치를 존중할 것입니다. 낮은 점수를 받는 사람들은 종종 다르게 일하기를 원하고 문제에 대한 대안적 해결책을 모색하기를 바랍니다.",
  },
  {
    category: 'emotion',
    title: 'title.emotion',
    left: 'desc.emotion-left',
    right: 'desc.emotion-right',
    disc: "정서는 성격 5요인(빅 파이브) 중 '신경증'을 평가합니다. 높은 점수를 받는 사람들은 긴장하는 경향이 있지만 다른 사람들의 감정에 민감할 수 있습니다. 낮은 점수를 받는 사람들은 스트레스가 많은 상황에서도 잘 수행할 수 있지만 조심성이 부족할 수 있습니다.",
  },
  {
    category: 'detail',
    title: 'title.detail',
    left: 'desc.detail-left',
    right: 'desc.detail-right',
    disc: "디테일은 성격 5요인(빅 파이브) 중 '성실성'을 평가합니다. 높은 점수를 받는 사람들은 세세한 부분까지 지나치게 관여할 수 있지만 특별한 주의가 필요한 일상적인 작업에 탁월할 수 있습니다. 낮은 점수를 받는 사람들은 일상적 과업에 대한 인내심이 부족하고 대국적 관점으로 보는 것을 선호합니다.",
  },
  {
    category: 'proficiency',
    title: 'title.proficiency',
    left: 'desc.proficiency-left',
    right: 'desc.proficiency-right',
    disc: '숙달은 시행착오 접근법과 비교하여 세부작업에서의 주의와 관심을 평가합니다.',
  },
  {
    category: 'work-orientation',
    title: 'title.work-orientation',
    left: 'desc.work-orientation-left',
    right: 'desc.work-orientation-right',
    disc: '업무지향성은 일-삶의 균형에 대한 신념과 비교하여 근면에 대한 신념을 평가합니다.',
  },
  {
    category: 'patience',
    title: 'title.patience',
    left: 'desc.patience-left',
    right: 'desc.patience-right',
    disc: '인내심은 경쟁적인 공격성과 비교하여 침착성과 인내심을 평가합니다.',
  },
  {
    category: 'fair-mindedness',
    title: 'title.fair-mindedness',
    left: 'desc.fair-mindedness-left',
    right: 'desc.fair-mindedness-right',
    disc: '공정성은 정서적 접근과 비교하여 합리적 접근을 평가합니다.',
  },
  {
    category: 'loyalty',
    title: 'title.loyalty',
    left: 'desc.loyalty-left',
    right: 'desc.loyalty-right',
    disc: '충성심은 이끌려는 욕구와 비교하여 추종하려는 욕구를 평가합니다.',
  },
  {
    category: 'disclosure',
    title: 'title.disclosure',
    left: 'desc.disclosure-left',
    right: 'desc.disclosure-right',
    disc: '개방성은 자신의 의도나 계획을 숨기려는 것과 비교하여 진정으로 열려 있는 것을 평가합니다.',
  },
  {
    category: 'initiative',
    title: 'title.initiative',
    left: 'desc.initiative-left',
    right: 'desc.initiative-right',
    disc: '주도성은 전통에 가치를 두는 것과 비교하여 빠른 변화를 즐기는 것을 평가합니다. ',
  },
];

const __OBPI_LIST_NUM__ = 12;
const __MAX_OBPI_MATCH_SCORE = 8;

const __OBPI_AUDIT__ = [
  { category: 'dissimulation', title: 'column.dissimulation' },
  { category: 'contradiction', title: 'column.contradiction' },
  { category: 'despondency', title: 'column.despondency' },
  { category: 'inattention', title: 'column.inattention' },
];

const Assessment = ({ cid, aid, idx }) => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();
  const { setIsEntireLoading } = useStatus();
  const { userInfo } = useSelector((state) => state.auth);
  const { testsAverageSuccess } = useSelector((state) => state.assessments);
  const navigate = useNavigate();
  const location = useLocation();
  const [newTests, setNewTests] = useState([]);
  const [totalAverage, setTotalAverage] = useState(0);
  const [assessment, setAssessment] = React.useState({});
  const [objectiveTests, setObjectiveTests] = React.useState([]);
  const [subjectiveTests, setSubjectiveTests] = React.useState([]);
  const [libraryTests, setLibraryTests] = React.useState([]);
  const [libraryDetail, setLibraryDetail] = React.useState([]);
  const [subjectiveTestsAverageScore, setSubjectiveTestsAverageScore] =
    React.useState(0);
  const [
    subjectiveTestsAveragePresetScore,
    setSubjectiveTestsAveragePresetScore,
  ] = React.useState(0);
  const [candidate, setCandidate] = React.useState({});
  const [curTab, setCurTab] = React.useState('result');
  const [showDetail, setShowDetail] = React.useState(false);
  const [objectiveScores, setObjectiveScores] = React.useState({});
  const [objectiveCats, setObjectiveCats] = React.useState({});
  const candidateAttributes = React.useRef(null);
  const [candidateCultureFitAttributes, setCandidateCultureFitAttributes] =
    React.useState([]);
  const candidateCultureAttributes = React.useRef(null);
  const [
    candidateCultureFitCultureAttributes,
    setCandidateCultureFitCultureAttributes,
  ] = React.useState([]);
  const [refreshing, setRefreshing] = React.useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [obpiBenchmark, setOBPIBenchmark] = React.useState({});
  const [obpiTest, setOBPITest] = React.useState([]);
  const [obpiStanine, setOBPIStanine] = React.useState([]);
  const [obpimatchScore, setOBPIMatchScore] = React.useState([]);
  const [obpiAudit, setOBPIAudit] = React.useState([]);
  const [isRefreshing, setIsRefreshing] = React.useState(true);
  const [__candidates, __setCandidates] = React.useState([]);
  const [_candidates, _setCandidates] = React.useState([]);
  const [comment, setComment] = React.useState('');
  const [answer, setAnswer] = React.useState(null);
  const [gucciStoreInterview, setGucciStoreInterview] = React.useState(false);
  const [obpiInterpretation, setobpiInterpretation] = React.useState([]);
  const [stageShowDropdown, setStageShowDropdown] = React.useState(false);
  const [hasKhaiP, setHasKhaiP] = React.useState(false);
  const [hasKhaiPAnswer, setHasKhaiPAnswer] = React.useState(false);
  const [KHAIPResult, setKHAIPResult] = React.useState();
  const [KHAIPAnswer, setKHAIPAnswer] = React.useState();
  const [KHAIPAnswerArray, setKHAIPAnswerArray] = React.useState([]);
  const [KHAIPQuestion, setKHAIPQuestion] = React.useState();
  const [hasKhaiO, setHasKhaiO] = React.useState(false);
  const [hasKhaiOAnswer, setHasKhaiOAnswer] = React.useState(false);
  const [KHAIOResult, setKHAIOResult] = React.useState();
  const [KHAIOQuestion, setKHAIOQuestion] = React.useState();
  const [KHAIOAnswer, setKHAIOAnswer] = React.useState();
  const [KHAIOAnswerArray, setKHAIOAnswerArray] = React.useState([]);
  const [KHAIPRadarResult, setKHAIPRadarResult] = React.useState();
  const [KHAIOHashQuestion, setKHAIOHashQuestion] = React.useState([]);
  const [hasSurvey, setHasSurvey] = React.useState(true);
  const [isPersonality, setIsPersonality] = React.useState(false);
  const [personalityLoading, setPersonalityLoading] = React.useState(true);

  const entry = searchParams.get('entry');
  const lang = t('lang');
  const stageDropdownRef = React.useRef(null);
  const rank = location?.state?.khaiP_rank || -1;
  const candidatecnt = location?.state?.candidatecnt || -1;
  const checkedAdmin = useCheckedAdminPage();

  const [noResultLanguage, setNoResultLanguage] = React.useState(false);

  useEffect(() => {
    if (Object.keys(assessment)?.length === 0) return;

    const languageTests = [];
    const { results } = candidate;

    assessment?.tests?.forEach((test) => {
      if (typeof test?.question === 'string') return;

      test?.question?.forEach((ques) => {
        if (ques?.tid === '601000000') {
          if (results?.english) {
            languageTests.push({
              category_skill: 'English Test',
              section: 'language',
              question: [
                {
                  name: 'english',
                  ...results.english,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.chinese) {
            languageTests.push({
              category_skill: 'Chinese Test',
              section: 'language',
              question: [
                {
                  name: 'chinese',
                  ...results.chinese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.japanese) {
            languageTests.push({
              category_skill: 'Japanese Test',
              section: 'language',
              question: [
                {
                  name: 'japanese',
                  ...results.japanese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.korean) {
            languageTests.push({
              category_skill: 'Korean Test',
              section: 'language',
              question: [
                {
                  name: 'korean',
                  ...results.korean,
                  section: 'language',
                },
              ],
            });
          }
        }
      });
    });

    const newTests = assessment?.tests;

    setNewTests([...languageTests, ...newTests]);
  }, [assessment, candidate]);

  useEffect(() => {
    executeAction(
      testsAverage({
        tests: newTests,
        candidate,
      })
    );
  }, [newTests, refreshing]);

  useEffect(() => {
    if (!testsAverageSuccess) return;

    if (assessment.tests) {
      setNoResultLanguage(false);
      if (
        assessment.tests.some((item) => item.id === '6010') &&
        !testsAverageSuccess.some((item) => item.title === 'Chinese Test') &&
        !testsAverageSuccess.some((item) => item.title === 'Japanese Test') &&
        !testsAverageSuccess.some((item) => item.title === 'English Test') &&
        !testsAverageSuccess.some((item) => item.title === 'Korean Test')
      ) {
        setNoResultLanguage(true);
      }
    }
    const avgScore =
      testsAverageSuccess.reduce((acc, cur) => acc + cur.average, 0) /
      testsAverageSuccess.length;

    setTotalAverage(avgScore);
  }, [testsAverageSuccess]);

  const convertDeviceCheck = (bool) => {
    if (bool) {
      return <ProctoringSuccess />;
    }

    return <ProctoringFailed />;
  };

  React.useEffect(() => {
    const listenBackEvent = () => {
      window.document.querySelector('body').style.position = 'relative';
    };
    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return unlistenHistoryEvent;
  }, []);

  React.useState(() => {
    if (idx === 0) {
      setShowDetail(!showDetail);
    } else {
      setShowDetail(showDetail);
    }
  }, [idx, showDetail]);

  React.useEffect(() => {
    const obpistanine = new Array();
    const interpretations = new Array();
    const obpi_id = cid.split('@')[0];
    for (const obpis of OBPI_TESTS) {
      const score = obpiTest?.traits?.find((x) => x.scale === obpis.category);
      obpistanine.push(score?.stanine);

      const interpretation = obpiTest?.narrative?.find(
        (x) => x.id === `${obpis.category}-interpretation`
      );
      if (interpretation !== undefined) {
        interpretations.push(interpretation.text.replaceAll(obpi_id, '응시자'));
      } else {
        interpretations.push('');
      }
    }
    setOBPIStanine(obpistanine);
    setobpiInterpretation(interpretations);
    const audits = new Array();
    for (const audit of __OBPI_AUDIT__) {
      const score = obpiTest?.audits?.find(
        (x) => x.scale.toUpperCase() === audit.category.toUpperCase()
      );
      audits.push(score?.rank);
    }
    setOBPIAudit(audits);
  }, [obpiTest]);

  React.useEffect(() => {
    if (
      !obpiBenchmark?.obpiResult ||
      obpiBenchmark?.id === assessment?.obpiBenchmark?.id
    ) {
      return;
    }

    executeAction(
      setOBPIBenchmarkToAssessment({
        aid,
        obpiBenchmark,
      })
    )
      .unwrap()
      .then((docs) => {
        // console.log(docs);
      })
      .catch((e) => {
        console.dir(e);
      });
  }, [obpiBenchmark]);

  React.useEffect(() => {
    if (!assessment || !Object.keys(assessment || {}).length) {
      return;
    }

    const { tests } = assessment || {};
    const newObjectiveTests = new Array();
    const newSubjectiveTests = new Array();
    const newLibraryTests = new Array();
    (tests || []).forEach((test, idx) => {
      if (test?.testcnt > 0 || test?.skills?.length > 0) {
        (test?.skills || []).forEach((skill) => {
          if (candidate?.results?.[skill.uuid]) {
            newObjectiveTests.push(skill);
          }
        });
        return;
      }

      const { section } = test;
      if (
        section === 'video' ||
        section === 'essay' ||
        section === 'multiple-choice' ||
        section === 'file-upload' ||
        section === 'spreadsheets' ||
        section === 'code'
      ) {
        newSubjectiveTests.push(test);
      } else {
        // if(test?.title_en?.indexOf('인성/심리검사') >= 0) {
        // if(test?.title_en?.indexOf('OBPI') >= 0) {
        // tid가 1310이고 lang가 ko일 때만 OBPI이다.
        if (test?.id === '1310' && lang === 'ko') {
          setOBPITest(candidate?.results?.obpi);
          var newOBPIResult;
          var obpiMatchScore = 0;

          newOBPIResult = candidate?.results?.obpi;
          if (obpiBenchmark?.obpiResult) {
            var scores = 0;
            OBPI_TESTS.forEach((category) => {
              const benchmark = obpiBenchmark?.obpiResult?.traits?.find(
                (x) => x.scale === category.category
              );
              const curOBPI = newOBPIResult?.traits?.find(
                (x) => x.scale === category.category
              );
              scores +=
                __MAX_OBPI_MATCH_SCORE -
                Math.abs(benchmark?.stanine - curOBPI?.stanine);
            });
            obpiMatchScore = (
              scores /
              (__MAX_OBPI_MATCH_SCORE * __OBPI_LIST_NUM__)
            ).toFixed?.(2);
            setOBPIMatchScore(obpiMatchScore);
          }
          return;
        }
        if (test?.id === '1321' && lang === 'ko') {
          setKHAIPResult(candidate?.results?.['Khai-P']?.result);
          setKHAIPAnswer(candidate?.results?.['Khai-P']?.answer);
          setKHAIPQuestion(candidate?.results?.['Khai-P']?.result?.interview);
          setHasKhaiP(true && Boolean(candidate?.results?.['Khai-P']?.result));
          setHasKhaiPAnswer(
            true && Boolean(candidate?.results?.['Khai-P']?.answer)
          );
        } else if (test?.id === '1322' && lang === 'ko') {
          setKHAIOResult(candidate?.results?.['Khai-O']?.result);
          setKHAIOQuestion(
            candidate?.results?.['Khai-O']?.result?.khai_question
          );
          setKHAIOAnswer(candidate?.results?.['Khai-O']?.answer);
          setHasKhaiO(true && Boolean(candidate?.results?.['Khai-O']?.result));
          setHasKhaiOAnswer(
            true && Boolean(candidate?.results?.['Khai-O']?.answer)
          );
        } else if (
          test?.question_type?.indexOf('tag.subjective') >= 0 ||
          test?.question_type?.indexOf('tag.code') >= 0 ||
          test?.question_type?.indexOf('tag.video') >= 0
        ) {
          newLibraryTests.push(test);
        } else {
          if (test?.question_type === 'tag.language') return;
          newObjectiveTests.push(test);
        }
      }
    });
    setObjectiveTests(newObjectiveTests || []);
    setSubjectiveTests(newSubjectiveTests || []);
    setLibraryTests(newLibraryTests || []);
  }, [assessment, candidate, obpiBenchmark]);

  React.useEffect(() => {
    if (!KHAIPAnswer) return;

    const answerArr = Object.entries(KHAIPAnswer).map(([key, value]) => value);
    setKHAIPAnswerArray(answerArr);
  }, [KHAIPAnswer]);

  React.useEffect(() => {
    if (!KHAIOAnswer) return;

    const answerArr = Object.entries(KHAIOAnswer).map(([key, value]) => value);
    setKHAIOAnswerArray(answerArr);
  }, [KHAIOAnswer]);

  const retryKhaiResults = (khaitype) => {
    const KHAIPResult = {
      total: {
        score: 90,
        grade: 'S',
        level: '+10',
        response_reliability: 'normal',
      },
      'personal-propensity': [
        {
          title: 'title.positive-attitude',
          myscore: 93.33,
          bigdatascore: 80,
          tooltip: [
            '긍정적으로 사고하고 행동으로 실행함',
            '할 수 있다는 믿음으로 매사에 당당하게 임하는 자세',
            '넘치는 에너지와 힘으로 생동감을 유지함',
          ],
        },
        {
          title: 'title.trust-will',
          myscore: 95.83,
          bigdatascore: 70,
          tooltip: [
            '자신의 생각과 믿음에 따라 일관되게 행동함',
            '거짓 없이 정직하게 행동하고 신뢰롭게 행동함',
            '어려움을 참고 맡은 임무를 끈기 있게 완수함',
            '자신의 양심에 따라 원칙과 규정을 준수하는 역량',
          ],
        },
        {
          title: 'title.self-management',
          myscore: 94.44,
          bigdatascore: 60,
          tooltip: [
            '감정을 조절하고 통제하여 평정심을 유지할 수 있음',
            '자신의 발전을 위해 스스로 학습하고 개발함',
            '신체/정신적으로 최적의 상태를 유지하고자 노력함',
          ],
        },
      ],
      'task-propensity': [
        {
          title: 'title.creativity-innovation',
          myscore: 88.89,
          bigdatascore: 95,
          tooltip: [
            '어렵거나 새로운 일에 도전하고 경쟁을 즐기는 행동',
            '독특하고 유용한 아이디어를 생각하고 적용함',
            '새로운 방식이나 기술을 기꺼이 수용하고자 노력함',
          ],
        },
        {
          title: 'title.execution-promotion',
          myscore: 96.67,
          bigdatascore: 80,
          tooltip: [
            '생각을 행동으로 옮기고 적용하고자 노력함',
            '자발적으로 일을 맡고 주도적으로 처리하는 행동',
            '맡은 일을 기한 내에 마무리 하기위해 노력함',
            '맡은 임무에 애정과 열정을 다해 최선을 다하는 역량',
          ],
        },
        {
          title: 'title.analysis-management',
          myscore: 99.17,
          bigdatascore: 100,
          tooltip: [
            '문제를 통합적으로 분석하고 해석할 수 있음',
            '사전에 처리방법이나 프로세스 등을 계획함',
            '가용한 정보나 자원을 꼼꼼하게 점검함',
            '실수없이 철저하고 세밀하게 검토/점검하는 역량',
          ],
        },
        {
          title: 'title.teamwork',
          myscore: 97.78,
          bigdatascore: 50,
          tooltip: [
            '동료나 구성원들을 통합하여 이끌고 관리할 수 있음',
            '사전에 처리방법이나 프로세스 등을 계획함',
            '가용한 정보나 자원을 꼼꼼하게 점검함',
            '실수없이 철저하고 세밀하게 검토/점검하는 역량',
          ],
        },
      ],
      'relationship-propensity': [
        {
          title: 'title.acceptance-understanding',
          myscore: 98.98,
          bigdatascore: 60,
          tooltip: [
            '다양한 의견이나 가치관을 인정/존중함',
            '위계를 존중하고 타인의 충고에 개방적인 태도',
            '상대방의 생각이나 감정을 이해하고 배려함',
          ],
        },
        {
          title: 'title.consideration-relationship',
          myscore: 100,
          bigdatascore: 80,
          tooltip: [
            '스스로를 내새우기보다 타인을 먼저 존중함',
            '때와 장소,대상에 따라 적절한 예의를 지킬 수 있음',
            '쉽게 타인과 어울리고 친밀감을 형성할 수 있음',
          ],
        },
      ],
      interview: {
        self: {
          strong:
            '감정조절+자신의 감정을 이해하고 부정적인 감정을 통제하여, 일상 생활에서 평정심을 유지할 수 있습니다.+상대방으로 인해 화가나거나 불편한 상황이지만, 감정을 표출하지 않고 조절하려 노력해본 경험이 있다면 말씀해주시기 바랍니다.||- 어떤 부분에서 화가 많이 났었습니까?|- 그때 본인은 어떤 생각을 하였고, 자신의 상태를 컨트롤 해야겠다고 마음먹은 이유는 무엇 때문이었습니까?|- 이런 경험처럼 대체적으로 화가나는 일이 있는경우 잘 참는 편입니까? 반대로 화를 냈던 경험이 있다면 언제였습니까?',
          weak: '자신감+자신이 가진 능력을 부정적으로 평가하여, 실수나 실패에 대한 불안, 두려움을 갖는 경향이 있습니다.+맡은 일에 대한 자신감이 부족해, 불안하거나 초초한 감정을 느낀 경험이 있다면 말씀해주시기 바랍니다.||- 맡은 일에서 자신감이 부족했던 이유는 무엇입니까?|- 불안과 초조함을 느낀 이유는 무엇입니까?|- (실수, 실패에 대한 두려움 때문이라면) 그 상황을 극복하기 위해 어떤 방법을 사용하였습니까?',
        },
        work: {
          strong:
            '꼼꼼함+작은 오류나 실수도 발생하지 않도록 업무 수행 과정과 결과물을 철저하게 확인하고 점검합니다.+작성한 보고서나 결과물에 실수가 있을까 우려되어, 지속적으로 검토하고 확인하여 좋은 결과가 있었던 경험이 있다면 말씀해주시기 바랍니다.||- 했던 일을 다시 검토해야 할 이유가 있었다면 무엇입니까?|- 다시 검토 했을 때 어떤 부분들이 수정되었습니까?|- 다시 검토 하지 않았다면 어떤 문제가 예상 되었을까요?',
          weak: '분석적사고+주어진 정보를 정확하게 분석하고 해석하여 중요한 의미를 찾아내는 일련의 과정에 어려움을 겪습니다.+처음 접하는 상황이나 문제를 이해하기 위해 다양한 정보를 수집하여 분석했던 경험이 있다면 말씀해 주시기바랍니다.||- 본인에게 그런 상황이나 문제가 어떤 부분에서 생소하였습니까?|- 그런 상황이나 문제를 이해하기 위해 어떤 정보가 필요하다고 생각했습니까?|- 필요한 정보를 수집하기 위해 어떤 조치들을 하였으며, 그 상황이나 문제에 대해 어떻게 이해할 수 있게 되었습니까?',
        },
        people: {
          strong:
            '겸손+자신의 성과, 능력을 자랑하기 보다 타인을 존중하고 자신을 낮추려는 겸손한 태도를 갖고 있습니다.+팀, 집단 속에서 나의 기여가 더 많았음에도 불구하고, 팀원들의 성과를 인정하고 격려했던 경험이 있다면 말씀해주시기 바랍니다.||- 본인의 역할은 무엇이었으며, 함께한 팀원들의 역할은 무엇이었습니까?|- 본인의 역할이나 기여가 조금 더 컸다고 생각 한 이유는 무엇 때문입니까?|- 팀원들의 성과를 인정하고, 격려코자 했던 이유는 무엇이었습니까?',
          weak: '순응성+보편적 가치와 규범에 따르기 보다는 자신의 의견, 방식을 우선시 하는 경향이 있습니다.+윗 사람의 지시나 방식이 아무래도 문제가 있다고 판단에 다른 방식으로 일을 했던 적이 있다면 말씀해주시기 바랍니다.||- 어떤 상황이었으며, 윗 사람의 지시에는 어떤 문제가 있다고 생각했습니까?|- 본인이 생각한 방식에는 어떤 이점이 있었습니까?|- 윗 사람의 지시와는 달라 나중에 알게 되었을 때 걱정이 되지는 않았습니까?',
        },
      },
    };

    let khaiAnswer = khaitype === 'P' ? KHAIPAnswerArray : KHAIOAnswerArray;

    executeAction(
      getKHAITestResult({
        khaitype,
        lang: 'en',
        item: khaiAnswer,
        id: cid,
      })
    )
      .unwrap()
      .then((doc) => {
        if (khaitype === 'P') {
          KHAIPResult.total.score = doc?.overall_results?.total_average_score;
          KHAIPResult.total.level = doc?.overall_results?.level;
          KHAIPResult.total.grade = doc?.overall_results?.grade;
          KHAIPResult.total.response_reliability = doc?.response_reliability;
          KHAIPResult['personal-propensity'][0].myscore =
            doc?.individual_personality?.positive_behavior?.subtotal;
          KHAIPResult['personal-propensity'][1].myscore =
            doc?.individual_personality?.trust_will?.subtotal;
          KHAIPResult['personal-propensity'][2].myscore =
            doc?.individual_personality?.self_management?.subtotal;
          KHAIPResult['task-propensity'][0].myscore =
            doc?.task_oriented?.creativity_innovation?.subtotal;
          KHAIPResult['task-propensity'][1].myscore =
            doc?.task_oriented?.carry_forward?.subtotal;
          KHAIPResult['task-propensity'][2].myscore =
            doc?.task_oriented?.analysis_management?.subtotal;
          KHAIPResult['task-propensity'][3].myscore =
            doc?.task_oriented?.team_work?.subtotal;
          KHAIPResult['relationship-propensity'][0].myscore =
            doc?.relation_oriented?.acceptance_understand?.subtotal;
          KHAIPResult['relationship-propensity'][1].myscore =
            doc?.relation_oriented?.consideration_relation?.subtotal;
          KHAIPResult.interview.self = doc?.['면접질문_자아'];
          KHAIPResult.interview.work = doc?.['면접질문_업무'];
          KHAIPResult.interview.people = doc?.['면접질문_사람'];

          executeAction(
            setKHAIRetryResults({
              aid,
              cid,
              sid: `Khai-${khaitype}`,
              result: KHAIPResult,
            })
          )
            .then(() => {
              setRefreshing(true);
            })
            .catch((e) => {
              console.error(e);
              setRefreshing(false);
            });
        } else {
          // KHAI O
          executeAction(
            setKHAIRetryResults({
              aid,
              cid,
              sid: `Khai-${khaitype}`,
              result: doc,
            })
          )
            .then(() => {
              setRefreshing(true);
            })
            .catch((e) => {
              console.error(e);
              setRefreshing(false);
            });
        }
        setIsEntireLoading(false);
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });
  };

  /** ==========
   * KHAI-O 면접질문 데이터 세팅
  ============== */
  React.useEffect(() => {
    if (KHAIOQuestion === undefined) return;

    // const questionSplit = KHAIOQuestion.split('*');
    // const questionArr = questionSplit.filter((empty) => empty !== '');

    // const modifiedQuestion = [];
    // (questionArr || []).map((question, idx) => {
    //   const classification = question.split('+');
    //   modifiedQuestion.push({
    //     title: classification[0],
    //     desc: classification[1],
    //     interview: classification[2],
    //   });
    // });

    // setKHAIOHashQuestion(modifiedQuestion);
    setKHAIOHashQuestion([]);
  }, [KHAIOQuestion]);

  React.useEffect(() => {
    let data = [
      {
        subject: t('label.personal-propensity'),
        A: 94.54,
        B: 86.74,
      },
      {
        subject: t('label.task-propensity'),
        A: 95.62,
        B: 87.21,
      },
      {
        subject: t('label.relationship-propensity'),
        A: 99.44,
        B: 91.56,
      },
    ];
    data[0].A = getKHAI_P_AverageScore('personal-propensity', KHAIPResult);
    data[1].A = getKHAI_P_AverageScore('task-propensity', KHAIPResult);
    data[2].A = getKHAI_P_AverageScore('relationship-propensity', KHAIPResult);
    data[0].B = getKHAI_P_BigDataAverageScore(
      'personal-propensity',
      KHAIPResult
    );
    data[1].B = getKHAI_P_BigDataAverageScore('task-propensity', KHAIPResult);
    data[2].B = getKHAI_P_BigDataAverageScore(
      'relationship-propensity',
      KHAIPResult
    );

    setKHAIPRadarResult(data);
  }, [KHAIPResult]);

  React.useEffect(() => {
    const tasks = new Array();
    const _libDetails = {};
    for (const test of libraryTests) {
      tasks.push(
        executeAction(getLibTestInfos({ tids: test?.test, lang: t('lang') }))
          .unwrap()
          .then((docs) => {
            const details = new Array();
            test?.question?.forEach((question, idx) => {
              if (question?.selected === true) {
                details.push(docs[idx]);
              }
            });

            _libDetails[test.id] = details;
          })
          .catch((e) => {
            console.dir(e);
          })
      );
    }

    Promise.allSettled(tasks)
      .then(() => {
        setLibraryDetail(_libDetails);
      })
      .catch((e) => {
        console.dir(e);
        setLibraryDetail({});
      });
  }, [libraryTests]);

  React.useEffect(() => {
    if (Object.keys(userInfo).length === 0) return;

    executeAction(
      getAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setAssessment(doc || {});
        setOBPIBenchmark(doc.obpiBenchmark);

        const personalityTest = doc?.tests?.some(
          (item) =>
            item?.id === '1310' || item?.id === '1321' || item?.id === '1322'
        );
        setIsPersonality(personalityTest);
        setPersonalityLoading(false);
      })
      .catch((e) => {
        console.dir(e);
        setAssessment({});
        setPersonalityLoading(true);
      });
  }, [aid, userInfo]);

  React.useEffect(() => {
    if (isRefreshing) {
      __setCandidates([]);
      return setIsRefreshing(false);
    }

    executeAction(
      getCandidatesOfAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ docs }) => {
        __setCandidates(docs || []);
      })
      .catch((e) => {
        __setCandidates([]);
        console.dir(e);
      });
  }, [aid, isRefreshing]);

  React.useEffect(() => {
    const candidates = new Array();
    __candidates.map((candidate) => {
      if (candidate?.results?.obpi === undefined) return;
      candidates.push({
        key: candidate.id,
        display: {
          en: `${candidate.firstName} ${candidate.lastName}`,
          ko: `${candidate.lastName} ${candidate.firstName}`,
        },
      });
    });
    _setCandidates(candidates);
  }, [__candidates]);

  React.useEffect(() => {
    if (Object.keys(userInfo).length === 0) return;

    if (refreshing) {
      setIsEntireLoading(true);
      executeAction(
        getCandidateInAssessment({
          aid,
          cid,
        })
      )
        .unwrap()
        .then(({ doc }) => {
          setCandidate(doc || {});

          setComment(doc.comment);
          setAnswer(doc.results);
          setIsEntireLoading(false);
          setRefreshing(false);
        })
        .catch((e) => {
          console.dir(e);
          setIsEntireLoading(false);
          setRefreshing(false);
        });
    }
  }, [aid, cid, refreshing, userInfo]);

  /** ==================================================================================
     *                              커스텀 질문 평균 스코어 계산 함수
     ================================================================================== */
  React.useEffect(() => {
    if (!Object.keys(candidate || {}).length || !(subjectiveTests || []).length)
      return;

    const scores = new Array();
    const presetScores = new Array();
    /** ----------------------------------------------
         * answerCheck : 응시자가 선택한 답변
         * correctCheck : 출제자가 선택한 정답
         ---------------------------------------------- */
    (subjectiveTests || []).map((test, idx) => {
      if (answer === undefined) return;

      if (test.section === 'multiple-choice') {
        if (!answer?.[test?.uuid || test?.id]) return;

        const answerCheck = Object.values(
          answer?.[test?.uuid || test?.id]?.checks
        )[0];

        const correctCheck = Object.values(test?.answers?.correct)[0];

        if (answerCheck !== correctCheck) {
          /** ---------------------------------
                     * 응시자 초대 완료 일 때 클릭 시 오류 분기처리
                     -----------------------------------*/
          if (candidate.results !== undefined) {
            candidate.results[test.uuid || test.id].score = 0;
          } else {
            return;
          }

          executeAction(
            getScoreResult({
              aid,
              cid,
              score: 0,
              tuuid: test?.uuid || test?.id,
            })
          )
            .unwrap()
            .then(() => {
              dismissModal();
            })
            .catch((e) => {
              console.dir(e);
            });
        } else {
          if (candidate.results !== undefined) {
            candidate.results[test.uuid || test.id].score = 1;
          } else {
            return;
          }

          executeAction(
            getScoreResult({
              aid,
              cid,
              score: 1,
              tuuid: test?.uuid || test?.id,
            })
          )
            .unwrap()
            .then(() => {
              dismissModal();
            })
            .catch((e) => {
              console.dir(e);
            });
        }

        const score = candidate.results?.[test.uuid || test.id]?.score;
        const presetScore =
          candidate.results?.[test.uuid || test.id]?.preset_score;
        if (score) {
          scores.push(score);
        } else if (presetScore) {
          presetScores.push(presetScore);
        }
      } else {
        const score = candidate.results?.[test.uuid || test.id]?.score;
        const presetScore =
          candidate.results?.[test.uuid || test.id]?.preset_score;
        if (score) {
          scores.push(score);
        } else if (presetScore) {
          presetScores.push(presetScore);
        }

        return;
      }
    });

    const average = scores.reduce((a, b) => a + b, 0) / subjectiveTests.length;
    const presetAverage =
      presetScores.reduce((a, b) => a + b, 0) / subjectiveTests.length;

    setSubjectiveTestsAverageScore(average || 0);
    setSubjectiveTestsAveragePresetScore(presetAverage || 0);
  }, [aid, cid, candidate, subjectiveTests]);

  React.useEffect(() => {
    if (!Object.keys(candidate).length) return;

    if (!candidate?.results || !Object.keys(candidate?.results).length) return;

    const results = candidate?.results;

    const _objectiveScores = {};
    const _objectiveCats = {};
    const tasks = new Array();
    for (const skill of objectiveTests) {
      const suuid = skill?.uuid;
      const result = results?.[suuid];

      if (!result) return;

      const filterResult = Object.entries(result).filter(([key, value]) => {
        if (key === 'score' || key === 'comment') {
          return;
        }
        return {
          [key]: value,
        };
      });
      const changeObj = filterResult.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
      const lang2 = assessment?.language;

      if (!result) continue;
      tasks.push(
        executeAction(
          getTestInfos({
            tids: skill?.test || [],
            lang: lang2,
          })
        )
          .unwrap()
          .then(({ docs: answers }) => {
            const temp = new Array();
            const category = {};
            const array = new Array();

            for (const [key, val] of Object.entries(changeObj || {})) {
              const tid = key;
              const answer = answers.find((x) => x.id === tid);
              if (!category[answer.skill_en]) category[answer.skill_en] = {};
              if (!category[answer.skill_en].cnt)
                category[answer.skill_en].cnt = 0;
              if (!category[answer.skill_en].correct)
                category[answer.skill_en].correct = 0;
              category[answer.skill_en].cnt++;
              if (answer?.correct.length > 1) {
                let isCorrect = true;
                answer?.correct.forEach((cor, idx) => {
                  isCorrect = isCorrect && cor === val?.[0][idx];
                });
                temp.push(true);
                category[answer.skill_en].correct++;
              } else {
                if (answer?.correct?.[0] === val?.[0]) {
                  category[answer.skill_en].correct++;
                }
                temp.push(answer?.correct?.[0] === val?.[0]);
              }
            }
            let _score = 0;
            if (temp.length)
              _score = temp.filter((x) => x).length / temp.length;
            const correct = temp.filter((x) => x).length;
            // console.log("correct = " + correct + ", total = " + temp.length);
            _objectiveScores[suuid] = new Array();
            _objectiveScores[suuid].push(_score);
            _objectiveScores[suuid].push(correct);
            _objectiveScores[suuid].push(temp.length);

            _objectiveCats[suuid] = new Array();
            for (const [key, val] of Object.entries(category || {})) {
              const tmp = { category: key, val };
              array.push(tmp);
            }
            if (array.length !== 0) {
              array.sort((a, b) =>
                String(a.category).localeCompare(String(b.category))
              );
              _objectiveCats[suuid] = array;
            }
          })
          .catch((e) => {
            console.dir(e);
          })
      );
    }
    Promise.allSettled(tasks)
      .then(() => {
        setObjectiveScores(_objectiveScores);
        setObjectiveCats(_objectiveCats);
      })
      .catch((e) => {
        console.dir(e);
        setObjectiveScores({});
        setObjectiveCats({});
      });
  }, [objectiveTests, candidate]);

  React.useEffect(() => {
    for (const [key, value] of Object.entries(candidate?.results || {})) {
      if (Object.values(value?.attributes || {}).length) {
        candidateAttributes.current = Object.values(value.attributes);
      }
      if (Object.values(value?.cultureAttributes || {}).length) {
        candidateCultureAttributes.current = Object.values(
          value.cultureAttributes
        );
      }
    }

    // TODO: check candidate have a culture fit test, because performance.
    executeAction(
      getAttributes({
        sid: '71',
      })
    )
      .unwrap()
      .then(({ docs }) => {
        const temp = new Array();
        if (candidateCultureAttributes.current) {
          for (const candidateAttribute of candidateAttributes.current || []) {
            const doc = (docs || []).find((x) => x.id === candidateAttribute);
            temp.push(doc);
          }
          setCandidateCultureFitAttributes(temp);
        }
      });

    executeAction(
      getCultureAttributes({
        sid: '71',
      })
    )
      .unwrap()
      .then(({ docs }) => {
        const temp = new Array();
        if (candidateCultureAttributes.current) {
          for (const candidateCultureAttribute of candidateCultureAttributes.current) {
            const doc = (docs || []).find(
              (x) => x.id === candidateCultureAttribute
            );
            temp.push(doc);
          }
          setCandidateCultureFitCultureAttributes(temp);
        }
      });
  }, [candidate]);

  React.useEffect(() => {
    const handler = (event) => {
      if (
        !stageDropdownRef.current ||
        stageDropdownRef.current.contains(event.target)
      )
        return;

      setStageShowDropdown(false);
    };

    window.document.addEventListener('mousedown', handler);
    window.document.addEventListener('touchstart', handler);

    return () => {
      window.document.addEventListener('mousedown', handler);
      window.document.addEventListener('touchstart', handler);
    };
  }, [stageDropdownRef]);

  React.useEffect(() => {
    if (Object.keys(assessment).length === 0) return;

    if (assessment?.surveyOnOff === 'noSurvey') {
      setHasSurvey(false);
    }

    if (assessment?.tests === undefined) {
      setGucciStoreInterview(false);
    }

    if (userInfo.plan === 100) {
      setGucciStoreInterview(true);
      return;
    }
  }, [assessment, userInfo]);

  const getHiringStageText = (step, updateTime) => {
    const start = parseInt(new Date().getTime() / 1000);
    const timeDif = 2 * 3600;

    step = Number(step);

    if (Math.abs(start - updateTime) > timeDif && step === 0) {
      return 'text.hiring-leave-during-assessment';
    }

    switch (step) {
      case -1:
        return 'text.hiring-stage.sended-email';
      case 0:
        return 'text.hiring-stage.sended-email';
      case 1:
        return 'text.hiring-stage.wirte-done';
      case 2:
        return 'text.hiring-stage.under-investigation';
      case 3:
        return 'text.hiring-stage.reject';
      case 4:
        return 'text.hiring-stage.accept';

      default:
        return '';
    }
  };

  const getI18nKeyOfSection = (sectionCode) => {
    switch (sectionCode) {
      case 'video':
        return 'btn.video';
      case 'essay':
        return 'btn.essay';
      case 'multiple-choice':
        return 'btn.multiple-choice';
      case 'file-upload':
        return 'btn.file-upload';
      case 'spreadsheets':
        return 'btn.spreadsheet';
      case 'code':
        return 'btn.code';
      default:
        return '';
    }
  };

  const getScoreObjective = (test) => [
    objectiveScores?.[test?.uuid]?.[0]?.toFixed?.(2) || 0,
    objectiveScores?.[test?.uuid]?.[1] || 0,
    objectiveScores?.[test?.uuid]?.[2] || 0,
  ];

  const getAverageObjectiveScore = () => {
    let score = 0;
    let cnt = 0;
    Object.entries(objectiveScores || {}).forEach(([key, val], idx) => {
      const skill = (objectiveTests || []).find((x) => x.uuid === key);

      if (skill?.id !== '71') {
        if (val?.[0] >= 0 && val?.[0] <= 1) {
          score += val?.[0];
          cnt += 1;
        }
      }
    });

    if (cnt == 0) return 0;

    return (score / cnt)?.toFixed?.(2);
  };

  const getScore = (test) => {
    const tuuid = test?.uuid || test?.id;
    let result = candidate?.results?.[tuuid];

    return result?.score || 0;
  };

  const getPresetScore = (test) => {
    const tuuid = test?.uuid || test?.id;
    let result = candidate?.results?.[tuuid];

    return result?.preset_score || 0;
  };

  const getCurrentLibraryAverageScore = (test) => {
    let testcnt = 0;
    let score = [];

    test?.question.forEach((tmp) => {
      if (tmp?.section === 'styling') {
        let result = 0;
        result += candidate?.results?.[tmp.id]?.originalityScore;
        result += candidate?.results?.[tmp.id]?.fashionScore;
        result += candidate?.results?.[tmp.id]?.fitScore;

        score.push(Number((result / 3).toFixed(2)) || 0);
        testcnt++;
      } else if (tmp?.id === '99000100001') {
        let result = 0;
        result += candidate?.results?.[tmp.id]?.communicationScore;
        result += candidate?.results?.[tmp.id]?.fashionScore;
        result += candidate?.results?.[tmp.id]?.fitScore;

        score.push(Number((result / 3).toFixed(2)) || 0);
        testcnt++;
      } else {
        if (candidate?.results?.[tmp.id] === undefined) return;
        let result = 0;
        result += candidate?.results?.[tmp.id].score;

        if (result) {
          score.push(result || 0);
          testcnt++;
        }
      }
    });

    if (score.length >= 2) {
      return testcnt !== 0
        ? score.reduce((a, b) => a + b, 0) / score.length
        : 0;
    }
    return testcnt !== 0 ? score.reduce((a, b) => a + b, 0) / testcnt : 0;
  };

  const getCurrentLibraryAveragePresetScore = (test) => {
    let testcnt = 0;
    let presetScore = 0;
    test?.question.forEach((tmp) => {
      if (tmp?.id !== '99000100001') {
        const result = candidate?.results?.[tmp.id];
        if (
          result === undefined ||
          result.preset_score === undefined ||
          result.preset_score <= 0 ||
          tmp?.id === '99101000006'
        )
          return;

        if (result) {
          presetScore += result?.preset_score || 0;
          testcnt++;
        }
      } else {
        presetScore = 0;
      }
    });

    if (presetScore === 0) return 0;
    return (presetScore / testcnt).toFixed(2);
  };
  const getLibrayAverageScore = () => {
    let testcnt = 0;
    let score = [];

    libraryTests?.forEach((lib) => {
      lib?.question?.forEach((tmp) => {
        if (tmp?.section === 'styling') {
          let result = 0;
          result += candidate?.results?.[tmp.id]?.originalityScore;
          result += candidate?.results?.[tmp.id]?.fashionScore;
          result += candidate?.results?.[tmp.id]?.fitScore;

          score.push(Number((result / 3).toFixed(2)) || 0);
          testcnt++;
        } else if (tmp?.id === '99000100001') {
          let result = 0;
          result += candidate?.results?.[tmp.id]?.communicationScore;
          result += candidate?.results?.[tmp.id]?.fashionScore;
          result += candidate?.results?.[tmp.id]?.fitScore;

          score.push(Number((result / 3).toFixed(2)) || 0);
          testcnt++;
        } else {
          if (candidate?.results?.[tmp.id] === undefined) return;
          let result = 0;
          result += candidate?.results?.[tmp.id].score;

          if (result) {
            score.push(result || 0);
            testcnt++;
          }
        }
      });
    });

    if (score.length === 0) return 0;
    if (score.length >= 2) {
      return testcnt !== 0
        ? score.reduce((a, b) => a + b, 0) / score.length
        : 0;
    }
    return testcnt !== 0 ? score.reduce((a, b) => a + b, 0) / testcnt : 0;
  };

  const getLibrayAveragePresetScore = () => {
    let testcnt = 0;
    let presetScore = 0;
    libraryTests?.forEach((lib) => {
      lib?.test?.forEach((tmp) => {
        const result = candidate?.results?.[tmp];
        if (
          result?.preset_score === undefined ||
          result.preset_score <= 0 ||
          tmp === '99000100001' ||
          tmp === '99101000006'
        )
          return;

        if (result) {
          presetScore += result?.preset_score || 0;
          testcnt++;
        }
      });
    });

    if (presetScore === 0) return 0;
    return (presetScore / testcnt).toFixed(2);
  };

  /** --------------------------------------------
     * @returns(number): 커스텀 질문 자동채점 결과 평균 반환
    -----------------------------------------------*/
  const getSubjectiveAveragePresetScore = () => {
    const presetScores = new Array();

    (subjectiveTests || []).forEach((test) => {
      const presetScore = candidate?.results?.[test?.uuid]?.preset_score;
      if (presetScore) presetScores.push(presetScore);
    });

    if (presetScores.length === 0) return 0;
    const presetAverage =
      presetScores.reduce((a, b) => a + b, 0) / subjectiveTests.length;
    return presetAverage;
  };

  const changeSexWord = () => {
    let word;

    switch (candidate?.results?.survey?.sex) {
      case 'male':
        word = t('menu.label.male');
        break;
      case 'female':
        word = t('menu.label.female');
        break;
      case 'non-disclosure':
        word = t('menu.label.non-disclosure');
        break;
      default:
        word = '';
        break;
    }

    return word;
  };

  const changeEducationWord = () => {
    let word;

    switch (candidate?.results?.survey?.educationLevel) {
      case 'high-school-graduation':
        word = t('menu.input.high-school-graduation');
        break;
      case 'college-graduation':
        word = t('menu.input.college-graduation');
        break;
      case 'while-in-university':
        word = t('menu.input.while-in-university');
        break;
      case 'university-graduation':
        word = t('menu.input.university-graduation');
        break;
      case 'finish-graduate-school':
        word = t('menu.input.finish-graduate-school');
        break;
      default:
        word = '';
        break;
    }

    return word;
  };

  const changeYearsExperience = () => {
    let word;

    switch (candidate?.results?.survey?.yearsExperience) {
      case '0-year':
        word = t('menu.input.0-year');
        break;
      case '1-year':
        word = t('menu.input.1-year');
        break;
      case '2-years':
        word = t('menu.input.2-years');
        break;
      case '3-years':
        word = t('menu.input.3-years');
        break;
      case '4-years':
        word = t('menu.input.4-years');
        break;
      case '5-years':
        word = t('menu.input.5-years');
        break;
      case '6-years':
        word = t('menu.input.6-years');
        break;
      case '7-years':
        word = t('menu.input.7-years');
        break;
      case '8-years':
        word = t('menu.input.8-years');
        break;
      case '9-years':
        word = t('menu.input.9-years');
        break;
      case '10-years':
        word = t('menu.input.10-years');
        break;
      case '11-years':
        word = t('menu.input.11-years');
        break;
      case '12-years':
        word = t('menu.input.12-years');
        break;
      case '13-years':
        word = t('menu.input.13-years');
        break;
      case '14-years':
        word = t('menu.input.14-years');
        break;
      case '15-years':
        word = t('menu.input.15-years');
        break;
      case '16-years':
        word = t('menu.input.16-years');
        break;
      case '17-years':
        word = t('menu.input.17-years');
        break;
      case '18-years':
        word = t('menu.input.18-years');
        break;
      case '19-years':
        word = t('menu.input.19-years');
        break;
      case '20-years':
        word = t('menu.input.20-years');
        break;
      case 'for-over-21-years':
        word = t('menu.input.for-over-21-years');
        break;
      default:
        word = '';
        break;
    }

    return word;
  };

  const changeAgeWord = (data) => {
    let word;
    word = data.replaceAll('-', '.');
    return word;
  };

  const changeAgeWordBefore = (data) => {
    let word;

    switch (data) {
      case 'under-18-years-old':
        word = t('menu.input.under-18-years-old');
        break;
      case '18-24-years-old':
        word = t('menu.input.18-24-years-old');
        break;
      case '25-34-years-old':
        word = t('menu.input.25-34-years-old');
        break;
      case '35-44-years-old':
        word = t('menu.input.35-44-years-old');
        break;
      case '45-54-years-old':
        word = t('menu.input.45-54-years-old');
        break;
      case '55-64-years-old':
        word = t('menu.input.55-64-years-old');
        break;
      case '64-years-old-and-above':
        word = t('menu.input.64-years-old-and-above');
        break;
      default:
        word = '';
        break;
    }

    return word;
  };

  const onViewAnswerClickHandler = (test) => {
    switch (test?.section) {
      case 'video':
        displayModal(
          'assessments/viewAnswer/Video',
          {
            aid,
            cid,
            test,
            setRefreshing,
          },
          {
            frame: 'integration',
          }
        );
        break;
      case 'essay':
        displayModal(
          'assessments/viewAnswer/Essay',
          {
            aid,
            cid,
            test,
            setRefreshing,
          },
          {
            frame: 'integration',
          }
        );
        break;
      case 'multiple-choice':
        displayModal(
          'assessments/viewAnswer/MultipleChoice',
          {
            aid,
            cid,
            test,
            setRefreshing,
          },
          {
            frame: 'integration',
          }
        );
        break;
      case 'file-upload':
        displayModal(
          'assessments/viewAnswer/FileUpload',
          {
            aid,
            cid,
            test,
            setRefreshing,
          },
          {
            frame: 'integration',
          }
        );
        break;
      case 'spreadsheets':
        displayModal(
          'assessments/viewAnswer/Spreadsheet',
          {
            aid,
            cid,
            test,
            setRefreshing,
          },
          {
            frame: 'integration',
          }
        );
        break;
      case 'code':
        break;
      default:
        break;
    }
  };

  const onViewLibarayAnswerClickHandler = (test) => {
    const eventHandler = (event) => {
      if (event.detail?.message !== 'Library Modal Open') return;
      window.document.querySelector('body').style.position = 'relative';
      window.document.removeEventListener('dismissModal', eventHandler);
    };

    window.document.querySelector('body').style.position = 'fixed';
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'assessments/viewAnswer/Library',
      {
        aid,
        cid,
        test,
        candidate,
        setRefreshing,
        checkedAdmin,
        event: {
          message: 'Library Modal Open',
        },
      },
      {
        frame: 'integration',
      }
    );
  };

  const onSendEmailPassHandler = (callback) => {
    const eventHandler = (event) => {
      if (event.detail?.message === 'send-email-pass') {
        setRefreshing(true);
        setIsEntireLoading(true);
        executeAction(
          sendEmailPass({
            aid,
            email: candidate?.email,
            firstName: candidate?.firstName,
            lastName: candidate?.lastName,
          })
        )
          .unwrap()
          .then(() => {
            setRefreshing(false);
            setIsEntireLoading(false);
            toast(t('toast.desc.sent-email'));
            callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setRefreshing(false);
            setIsEntireLoading(false);
            callback && callback();
          });
      }
      window.document.removeEventListener('dismissModal', eventHandler);
    };
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'common/AlertModal',
      {
        event: {
          message: 'send-email-pass',
        },
        title: {
          main: t('dialog.title.pass-candidate'),
          sub: t('dialog.desc.pass-candidate'),
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  const onSendEmailReviewHandler = (callback) => {
    const eventHandler = (event) => {
      if (event.detail?.message === 'send-email-review') {
        setRefreshing(true);
        setIsEntireLoading(true);
        executeAction(
          sendEmailReview({
            aid,
            email: candidate?.email,
            firstName: candidate?.firstName,
            lastName: candidate?.lastName,
          })
        )
          .unwrap()
          .then(() => {
            setRefreshing(false);
            setIsEntireLoading(false);
            toast(t('toast.desc.sent-email'));
            callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setRefreshing(false);
            setIsEntireLoading(false);
            callback && callback();
          });
      }
      window.document.removeEventListener('dismissModal', eventHandler);
    };
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'common/AlertModal',
      {
        event: {
          message: 'send-email-review',
        },
        title: {
          main: t('dialog.title.review-candidate'),
          sub: t('dialog.desc.review-candidate'),
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  const onSendEmailRejectHandler = (callback) => {
    const eventHandler = (event) => {
      if (event.detail?.message === 'send-email-reject') {
        setRefreshing(true);
        setIsEntireLoading(true);
        executeAction(
          sendEmailReject({
            aid,
            email: candidate?.email,
            firstName: candidate?.firstName,
            lastName: candidate?.lastName,
          })
        )
          .unwrap()
          .then(() => {
            setRefreshing(false);
            setIsEntireLoading(false);
            toast(t('toast.desc.sent-email'));
            callback && callback();
          })
          .catch((e) => {
            console.dir(e);
            setRefreshing(false);
            setIsEntireLoading(false);
            callback && callback();
          });
      }
      window.document.removeEventListener('dismissModal', eventHandler);
    };
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'common/AlertModal',
      {
        event: {
          message: 'send-email-reject',
        },
        title: {
          main: t('dialog.title.reject-candidate'),
          sub: t('dialog.desc.reject-candidate'),
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  const goPrint = () => {
    navigate(`/customer/candidates/${cid}/print?aid=${aid}&entry=${entry}`, {
      state: {
        assessment,
        candidate,
        objectiveTests,
        subjectiveTests,
        objectiveScores,
        objectiveCats,
        libraryTests,
        libraryDetail,
        hasKhaiP,
        KHAIPResult,
        KHAIPRadarResult,
        hasKhaiO,
        KHAIOResult,
        rank,
        candidatecnt,
        KHAIOHashQuestion,
        KHAIPQuestion,
        KHAIOQuestion,
        isPersonality,
      },
    });
  };

  const getTestColorClass = (score) => {
    if (score >= 0.51) return 'blue';
    if (score >= 0.26) return 'orange';
    if (score > 0) return 'red';
    return '';
  };

  const getAuditColorClass = (audit) => {
    if (!audit) return '';
    audit = Number(audit);

    switch (audit) {
      case 0:
        return '';
      case 1:
        return 'orange-opc';
      case 2:
        return 'red500-opc';
      case 3:
        return 'red-opc';
      default:
        return '';
    }
  };

  const setStanineCandidate = (candidate) => {
    if (!candidate) return;
    const selectedCandidate = __candidates.find((x) => x.id === candidate?.key);

    setOBPIBenchmark({
      id: selectedCandidate?.id,
      obpiResult: selectedCandidate?.results?.obpi,
    });
  };

  const onGuideClickHandler = () => {
    displayModal(
      'assessments/obpi/OBPIGuide',
      {},
      {
        frame: 'integration',
        backgroundColor: 'transparent',
      }
    );
  };

  const onSaveClickHandler = () => {
    setIsEntireLoading(true);
    executeAction(
      updateCandidate({
        aid: assessment?.id,
        email: candidate?.email,
        data: { comment },
      })
    )
      .unwrap()
      .then(() => {
        setIsEntireLoading(false);
        toast(t('toast.desc.save-memo'));
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });
  };

  return (
    <article
      className={`pages-protected-candidates-detail-assessment ${
        showDetail ? 'show' : 'hide'
      }`}
    >
      <header className="header">
        <section className="header-title">
          <h4 className="header-title-main">
            <span className="fs28">{t('header.menu.my-assessments')}</span>
            <span className="fs28">{assessment?.name}</span>
            <button
              className="print-button fsbtn16"
              style={{
                display: candidate?.progress === 0 ? 'none' : 'block',
              }}
              onClick={() => goPrint()}
            >
              <PrintIcon className="icon" /> {t('btn.print-pdf-download')}
            </button>
          </h4>
          <div className="header-title-sub fs14">
            {`${t('desc.completed-date')}: ${dateToDateFormat(
              assessment?.createdAt?.toDate(),
              t('lang')
            )}`}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </section>
        <button
          className="header-fold common-button fsbtn16 gray active"
          onClick={() => setShowDetail(!showDetail)}
        />
      </header>

      {/* ==============================결과화면==================================== */}
      {!isPersonality && !personalityLoading ? (
        <section className="results-section">
          {gucciStoreInterview &&
            userInfo?.email !== 'design@promenade-ai.com' && (
              <section className="gucci survey">
                <div className="title fs20">
                  {t('title.gucci-store-survey')}
                </div>
                {candidate?.results?.survey?.gucciNewSurvey ? (
                  <>
                    <div className="row">
                      <div className="cell">
                        <div className="title fs14">{t('title.career')}</div>
                        <div className="content fs16">
                          {Object.values(
                            candidate?.results?.survey?.gucciNewSurvey
                              .newCareerGucci || ''
                          )?.join(', ')}
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('title.retail-career')}
                        </div>
                        <div className="content fs16">
                          {Object.values(
                            candidate?.results?.survey?.gucciNewSurvey
                              .retailCareerGucci || ''
                          )?.join(', ')}
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('title.reason-for-job-change')}
                        </div>
                        <div className="content fs16">
                          {
                            candidate?.results?.survey?.gucciNewSurvey
                              .changingGucci
                          }
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('title.overseas-experience')}
                        </div>
                        <div className="content fs16">
                          {
                            candidate?.results?.survey?.gucciNewSurvey
                              .overSeasYesOrNoGucci
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="cell">
                        <div className="title fs14">
                          {t('title.foreign-language-level')}
                        </div>
                        <div className="content fs16">
                          {
                            candidate?.results?.survey?.gucciNewSurvey
                              .langLevelGucci
                          }
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('title.main-values -choosing-company')}
                        </div>
                        <div className="content fs16">
                          {
                            candidate?.results?.survey?.gucciNewSurvey
                              .importantValueGucci
                          }
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('title.competencies-job-applied')}
                        </div>
                        <div className="content fs16">
                          {
                            candidate?.results?.survey?.gucciNewSurvey
                              .competencyGucci
                          }
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('title.share-feedback')}
                        </div>
                        <div className="content fs16">
                          {
                            candidate?.results?.survey?.gucciNewSurvey
                              .feedbackGucci
                          }
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="cell">
                        <div className="title fs14">
                          {t('survey.position-applying')}
                        </div>
                        <div className="content fs16">
                          {candidate?.results?.survey &&
                            candidate?.results?.survey?.applyGucci}
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('survey.preferred-channel')}
                        </div>
                        <div className="content fs16">
                          {candidate?.results?.survey &&
                            Object.values(
                              candidate?.results?.survey?.channelGucci || ''
                            )?.join(',')}
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('survey.preferred-location')}
                        </div>
                        <div className="content fs16">
                          {candidate?.results?.survey &&
                            Object.values(
                              candidate?.results?.survey?.areaGucci || ''
                            )?.join(',')}
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('survey.major-of-studying')}
                        </div>
                        <div className="content fs16">
                          {candidate?.results?.survey &&
                            candidate?.results?.survey?.majorGucci}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="cell">
                        <div className="title fs14">
                          {t('survey.sales-experience')}
                        </div>
                        <div className="content fs16">
                          {candidate?.results?.survey &&
                            candidate?.results?.survey?.specialExperienceGucci}
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('survey.industry-background')}
                        </div>
                        <div className="content fs16">
                          {candidate?.results?.survey &&
                            candidate?.results?.survey?.careerGucci}
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('survey.sales-other-industry')}
                        </div>
                        <div className="content fs16">
                          {candidate?.results?.survey &&
                            candidate?.results?.survey
                              ?.saleLuxryExperienceGucci}
                        </div>
                      </div>
                      <div className="cell">
                        <div className="title fs14">
                          {t('survey.sales-experience-luxury')}
                        </div>
                        <div className="content fs16">
                          {candidate?.results?.survey?.saleExperienceGucci}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </section>
            )}

          {/* 종합 평가 */}
          <article className="summary-container">
            <aside className="summary-title">
              <h3 className="results-main-title">{t('title.summary')}</h3>
              {noResultLanguage && (
                <div className="tests-no-lang-result">
                  {t('tag.no-lang-result')}
                </div>
              )}
            </aside>
            <aside className="summary-contents">
              <aside className="summary-average">
                {/* 평균 점수 */}
                <h4 className="results-sub-title">
                  {t('title.summary.average-score')}
                </h4>
                <div className="summary-total-chart">
                  <div className="single-chart">
                    <svg viewBox="0 0 36 36" className={`circular-chart`}>
                      <path
                        className="circle-bg"
                        d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                      <path
                        className="circle"
                        strokeDasharray={`${
                          Math.trunc(totalAverage * 100) || 0
                        } , 100`}
                        d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                      />
                    </svg>
                  </div>
                  <div className="percentage">
                    <span className="number fs56">{`${
                      Math.trunc(totalAverage * 100) || 0
                    }`}</span>
                    <span className="unit fs36">%</span>
                  </div>
                </div>
              </aside>
              <aside className="summary-detail-score">
                {/* 상세 점수 */}
                <h4 className="results-sub-title">
                  {t('title.summary.detail-score')}
                </h4>
                <div className="summary-bar-chart">
                  {testsAverageSuccess &&
                    testsAverageSuccess?.map((test) => (
                      <CustomBarChart
                        key={test?.id}
                        score={Math.trunc(test?.average * 100) || 0}
                        title={test?.title}
                      />
                    ))}
                </div>
              </aside>
              <aside className="summary-proctoring">
                {/* 부정행위 */}
                <h4 className="results-sub-title">
                  {t('title.summary.proctoring')}
                </h4>
                <div className="summary-proctoring-list">
                  <ul>
                    <li>
                      {convertDeviceCheck(candidate?.results?.detect?.ipOnce)}
                      {t('title.ip')}
                      <span className="tooltip">
                        {t('label.filled-out-only-once-from-ip-address')}
                      </span>
                    </li>
                    <li>
                      {convertDeviceCheck(candidate?.results?.detect?.onWebcam)}
                      {t('title.webcam')}
                      <span className="tooltip">
                        {t('label.webcam-enabled')}
                      </span>
                    </li>
                    <li>
                      {convertDeviceCheck(
                        candidate?.results?.detect?.onFullscreen
                      )}
                      {t('title.full-screen')}
                      <span className="tooltip">
                        {t('label.full-screen-mode-always-active')}
                      </span>
                    </li>
                    <li>
                      {convertDeviceCheck(
                        candidate?.results?.detect?.isMouseLeave
                      )}
                      {t('title.mouse')}
                      <span className="tooltip">
                        {t('label.mouse-always-in-assessment-window')}
                      </span>
                    </li>
                  </ul>
                </div>
              </aside>
            </aside>
          </article>

          {/* 테스트 결과 */}
          <article className="tests-container">
            <aside className="tests-title">
              <h3 className="results-semi-main-title">{t('title.test')}</h3>
            </aside>
            <aside className="tests-contents">
              {/* 반복문을 통해 하나의 테스트를 props로 넘겨줌 */}
              {newTests?.length !== 0 &&
                newTests?.map((test, idx) => {
                  if (test?.id === '6010') return null;

                  if (test?.section && test?.section !== 'language') {
                    return (
                      <CustomTestResultToggleContainer
                        type="custom"
                        test={test}
                        key={idx}
                        candidate={candidate}
                        assessment={assessment}
                        setRefreshing={setRefreshing}
                      />
                    );
                  }

                  return (
                    // db에 저장되어 있는 문제
                    <TestResultToggleContainer
                      test={test}
                      key={idx}
                      candidate={candidate}
                      assessment={assessment}
                      setRefreshing={setRefreshing}
                      objectiveTests={objectiveTests}
                    />
                  );
                })}
            </aside>
          </article>
        </section>
      ) : (
        <>
          <nav className="tabs">
            <ul className="tabs-list">
              {__TABS__.map((tab, idx) => (
                <li
                  key={idx}
                  className="tabs-list-item"
                  onClick={() => tab?.onClick({ setCurTab })}
                >
                  <div
                    className={`container ${curTab === tab?.key && 'selected'}`}
                  >
                    <span className="fs16">{t(tab?.i18nKey)}</span>
                  </div>
                </li>
              ))}
            </ul>
          </nav>
          {curTab === 'result' && (
            <section className="result">
              <section className="result-hiring-stage">
                <section className="result-hiring-stage-left">
                  <h4 className="result-hiring-stage-left-title fs20">
                    {t('title.hiring-stage')}
                  </h4>
                  <div
                    className={`result-hiring-stage-left-description ${
                      candidate?.progress === -1 || candidate?.progress === 0
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={() =>
                      candidate?.progress === -1 ||
                      candidate?.progress === 0 ? (
                        <></>
                      ) : (
                        setStageShowDropdown(!stageShowDropdown)
                      )
                    }
                  >
                    <div
                      className={`status-left step-leave-${
                        t(
                          getHiringStageText(
                            candidate?.progress,
                            candidate?.updatedAt?.seconds
                          )
                        ) === t('text.hiring-leave-during-assessment')
                      } step-${candidate?.progress}`}
                    >
                      <div className="progress-icon" />
                      <span>
                        {t(
                          getHiringStageText(
                            candidate?.progress,
                            candidate?.updatedAt?.seconds
                          )
                        )}
                      </span>
                    </div>
                    <div
                      className="status-right"
                      style={{
                        display:
                          candidate?.progress === -1 ||
                          candidate?.progress === 0
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <ArrowBotton />
                    </div>
                    {/* Modal */}
                    <div
                      ref={stageDropdownRef}
                      className="candidates-stage-dropdown"
                    >
                      {stageShowDropdown && (
                        <ul className="candidates-stage-dropdown-list">
                          {(__ASSESSMENTS_STAGE__ || []).map((tab, index) => (
                            <li
                              key={index}
                              className="candidates-stage-dropdown-list-item"
                              onClick={() => {
                                tab?.onClick(
                                  {
                                    aid,
                                    candidate: cid,
                                    stageShowDropdown,
                                    setStageShowDropdown,
                                    setIsEntireLoading,
                                  },
                                  () => {
                                    setRefreshing(true);
                                  }
                                );
                              }}
                            >
                              <div
                                className={`candidates-stage-dropdown-status-icon ${tab.key}`}
                              />
                              {t(tab.i18nKey)}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </section>
                <section className="result-hiring-stage-right">
                  <h4 className="result-hiring-stage-right-title fs20">
                    {t('title.send-email-desc')}
                  </h4>
                  <div className="result-hiring-stage-right-buttons">
                    <button
                      className={`common-button fsbtn16 gray ${
                        candidate.results !== undefined ? 'active' : 'disabled'
                      }`}
                      onClick={() =>
                        onSendEmailPassHandler(() => {
                          setRefreshing(true);
                        })
                      }
                      disabled={candidate.results === undefined}
                    >
                      {t('actions.menu.send-email-pass')}
                    </button>
                    <button
                      className={`common-button fsbtn16 gray ${
                        candidate.results !== undefined ? 'active' : 'disabled'
                      }`}
                      onClick={() =>
                        onSendEmailReviewHandler(() => {
                          setRefreshing(true);
                        })
                      }
                      disabled={candidate.results === undefined}
                    >
                      {t('actions.menu.send-email-review')}
                    </button>
                    <button
                      className={`common-button fsbtn16 gray ${
                        candidate.results !== undefined ? 'active' : 'disabled'
                      }`}
                      onClick={() =>
                        onSendEmailRejectHandler(() => {
                          setRefreshing(true);
                        })
                      }
                      disabled={candidate.results === undefined}
                    >
                      {t('actions.menu.send-email-reject')}
                    </button>
                  </div>
                </section>
              </section>
              {hasSurvey && (
                <section className="gucci original-survey">
                  <div className="title fs20">
                    {t('title.post-assessment-survey')}
                  </div>
                  <div className="row">
                    <div className="cell">
                      <div className="title fs14">
                        {t('menu.label.education-level')}
                      </div>
                      <div className="content fs16">
                        {changeEducationWord()}
                      </div>
                    </div>
                    <div className="cell">
                      <div className="title fs14">
                        {t('text-field.placeholder.what-did-you-study')}
                      </div>
                      <div className="content fs16">
                        {candidate?.results?.survey &&
                          candidate?.results?.survey?.major}
                      </div>
                    </div>
                    <div className="cell">
                      <div className="title fs14">
                        {t('menu.label.years-experience')}
                      </div>
                      <div className="content fs16">
                        {candidate?.results?.survey && changeYearsExperience()}
                      </div>
                    </div>
                    <div className="cell">
                      {candidate?.results?.survey?.birth ? (
                        <div className="title fs14">
                          {t('menu.year-of-birth')}
                        </div>
                      ) : (
                        <div className="title fs14">{t('menu.label.age')}</div>
                      )}
                      <div className="content fs16">
                        {candidate?.results?.survey &&
                          t(
                            `${
                              candidate?.results?.survey?.birth
                                ? changeAgeWord(
                                    candidate?.results?.survey?.birth
                                  )
                                : changeAgeWordBefore(
                                    candidate?.results?.survey?.age
                                  )
                            }`
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="cell">
                      <div className="title fs14">{t('menu.label.sex')}</div>
                      <div className="content fs16">
                        {candidate?.results?.survey && changeSexWord()}
                      </div>
                    </div>
                    <div className="cell">
                      <div className="title fs14">
                        {t('menu.label.final-annual-salary')}
                      </div>
                      <div className="content fs16">
                        {candidate?.results?.survey &&
                          `${candidate?.results?.survey?.salary} ${candidate?.results?.survey?.currency}`}
                      </div>
                    </div>
                    <div className="cell" style={{ visibility: 'hidden' }}>
                      <div className="title fs14" />
                      <div className="content fs16" />
                    </div>
                    <div className="cell" style={{ visibility: 'hidden' }}>
                      <div className="title fs14" />
                      <div className="content fs16" />
                    </div>
                  </div>
                </section>
              )}

              <section className="result-objective">
                <article className="khai-retry-button-wrapper">
                  {!hasKhaiP && hasKhaiPAnswer && (
                    <Button
                      theme="blue"
                      onClick={() => retryKhaiResults('P')}
                      label={t('btn.khai-p-retry')}
                      frontIcon={<RefreshIcon />}
                      className="khai-retry-button"
                    />
                  )}
                  {!hasKhaiO && hasKhaiOAnswer && (
                    <Button
                      theme="blue"
                      onClick={() => retryKhaiResults('O')}
                      label={t('btn.khai-o-retry')}
                      frontIcon={<RefreshIcon />}
                      className="khai-retry-button"
                    />
                  )}
                </article>
                {hasKhaiP && (
                  <section className="result-khai-p-section">
                    <div className="khai-logo">
                      <KHAILogo />
                    </div>
                    <h4 className="result-khai-p-title fs20">
                      {t('title.khai-p')}
                    </h4>
                    <div className="result-khai-p-wrap">
                      <div className="result-khai-p-overall">
                        <div className="result-khai-p-overall-result">
                          <div className="title fs18">
                            {t('title.overall-results')}
                          </div>
                          <div className="score">
                            <div className="overall-grade">
                              <div className="title fs14">
                                {t('title.overall-grade')}
                              </div>
                              <div className="grade">
                                {getKHAI_P_Grade('total', KHAIPResult)}
                              </div>
                            </div>
                            <div className="overall-score">
                              <div className="title fs14">
                                {t('title.overall-score-khai')}
                              </div>
                              <div className="score-graph">
                                <div className="single-chart">
                                  <svg
                                    viewBox="0 0 36 36"
                                    className={`circular-chart ${getKHAIPColor(
                                      KHAIPResult?.total?.score
                                    )} ${KHAIPResult?.total?.score}`}
                                  >
                                    <path
                                      className="circle-bg-khai"
                                      d="M18 2.0845
                                                                  a 15.9155 15.9155 0 0 1 0 31.831
                                                                  a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                    <path
                                      className="circle-khai"
                                      strokeDasharray={`${KHAIPResult?.total?.score} , 100`}
                                      d="M18 2.0845
                                                                  a 15.9155 15.9155 0 0 1 0 31.831
                                                                  a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                  </svg>
                                </div>
                                <div className="percentage">
                                  <span className="number fs28">{`${KHAIPResult?.total?.score}`}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="candidates-rank"
                            style={{ display: candidatecnt < 30 ? 'none' : '' }}
                          >
                            <div className="title fs18">
                              {t('title.candidates-ranking')}
                            </div>
                            <div className="rank">
                              <div className="my-rank fs18">{rank}등</div>
                              <div className="base fs14">
                                /
                                <Trans
                                  t={t}
                                  i18nKey="desc.candidates-ranking"
                                  values={{
                                    candidatecnt,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="bigdata-rank">
                            <div className="title fs18">
                              {t('title.bigdata-ranking')}
                            </div>
                            <div className="rank">
                              <div className="my-rank fs18">
                                {getKHAI_P_BigDataRank(
                                  KHAIPResult?.total?.level
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="response-confidence">
                            <div className="title fs18">
                              {t('title.response-confidence')}
                            </div>
                            <div className="rank">
                              {getKHAIPReliability(KHAIPResult, t, 'fs18')}
                            </div>
                          </div>
                        </div>
                        <div
                          className="result-hkai-p-ovrall-score-graph"
                          style={{
                            height: candidatecnt < 30 ? '100%' : '448px',
                          }}
                        >
                          <div className="title fs18">
                            {t('title.score-distribution-graph')}
                          </div>
                          <div className="graph">
                            <div className="score-distribution-graph">
                              <ScoreGraph />
                            </div>
                            <div
                              className="candidate-score"
                              style={{
                                left:
                                  KHAIPResult?.total?.score > 0
                                    ? `calc(776px * ${KHAIPResult?.total?.score} / 100)`
                                    : 0,
                              }}
                            >
                              <CandidateScore />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="result-khai-p-result-by-propensity">
                        <div className="title fs18">
                          {t('title.result-by-propensity')}
                        </div>
                        <div className="result">
                          <div className="radar-graph">
                            <div className="graph">
                              <ResponsiveContainer width="100%" height="100%">
                                <RadarChart
                                  cx="50%"
                                  cy="50%"
                                  outerRadius="80%"
                                  data={KHAIPRadarResult}
                                >
                                  <PolarGrid gridType="circle" />
                                  <PolarAngleAxis dataKey="subject" />
                                  <Radar
                                    name="본인"
                                    dataKey="A"
                                    stroke="#3294EE"
                                    strokeWidth={3}
                                    fill="#3294EE"
                                    fillOpacity={0.1}
                                  />
                                  <Radar
                                    name="빅데이터 평균"
                                    dataKey="B"
                                    stroke="#24C185"
                                    strokeWidth={3}
                                    fill="#24C185"
                                    fillOpacity={0.1}
                                  />
                                </RadarChart>
                              </ResponsiveContainer>
                            </div>
                            <div className="legend">
                              <div className="candidate">
                                <div className="title fs12">본인</div>
                                <div className="bullet" />
                              </div>
                              <div className="bigdata">
                                <div className="title fs12">빅데이터 평균</div>
                                <div className="bullet" />
                              </div>
                            </div>
                          </div>
                          <div className="result-desc">
                            <div className="propensity">
                              <div className="title fs18">
                                {t('title.personal-propensity')}
                              </div>
                              <div className="grade">
                                {getKHAI_P_Grade(
                                  'personal-propensity',
                                  KHAIPResult
                                )}
                              </div>
                              <div className="desc fs14">
                                {t('desc.personal-propensity')}
                              </div>
                            </div>
                            <div className="propensity">
                              <div className="title fs18">
                                {t('title.task-propensity')}
                              </div>
                              <div className="grade">
                                {getKHAI_P_Grade(
                                  'task-propensity',
                                  KHAIPResult
                                )}
                              </div>
                              <div className="desc fs14">
                                {t('desc.task-propensity')}
                              </div>
                            </div>
                            <div className="propensity">
                              <div className="title fs18">
                                {t('title.relationship-propensity')}
                              </div>
                              <div className="grade">
                                {getKHAI_P_Grade(
                                  'relationship-propensity',
                                  KHAIPResult
                                )}
                              </div>
                              <div className="desc fs14">
                                {t('desc.relationship-propensity')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="result-khai-p-detail-result">
                        <div className="title fs18">
                          {t('title.detailed-results')}
                        </div>
                        <div className="detail-result">
                          <div className="propensity">
                            <div className="title fs18">
                              {t('title.personal-propensity')}
                            </div>
                            <div className="graphs">
                              {KHAIPResult &&
                                (
                                  Object.keys(
                                    KHAIPResult?.['personal-propensity']
                                  ) || []
                                ).map((data, index) => (
                                  <KHAIPDetailGraph
                                    key={index}
                                    myscore={
                                      KHAIPResult?.['personal-propensity'][data]
                                        ?.myscore
                                    }
                                    bigdatascore={
                                      KHAIPResult?.['personal-propensity'][data]
                                        ?.bigdatascore
                                    }
                                    tooltip={
                                      KHAIPResult?.['personal-propensity'][data]
                                        ?.tooltip
                                    }
                                    title={t(
                                      KHAIPResult?.['personal-propensity'][data]
                                        ?.title
                                    )}
                                    title_class="fs14"
                                    score_class="fs14"
                                  />
                                ))}
                            </div>
                          </div>
                          <div className="propensity">
                            <div className="title fs18">
                              {t('title.task-propensity')}
                            </div>
                            <div className="graphs">
                              {KHAIPResult &&
                                (
                                  Object.keys(
                                    KHAIPResult?.['task-propensity']
                                  ) || []
                                ).map((data, index) => (
                                  <KHAIPDetailGraph
                                    key={index}
                                    myscore={
                                      KHAIPResult?.['task-propensity'][data]
                                        ?.myscore
                                    }
                                    bigdatascore={
                                      KHAIPResult?.['task-propensity'][data]
                                        ?.bigdatascore
                                    }
                                    tooltip={
                                      KHAIPResult?.['task-propensity'][data]
                                        ?.tooltip
                                    }
                                    title={t(
                                      KHAIPResult?.['task-propensity'][data]
                                        ?.title
                                    )}
                                    title_class="fs14"
                                    score_class="fs14"
                                  />
                                ))}
                            </div>
                          </div>
                          <div className="propensity">
                            <div className="title fs18">
                              {t('title.relationship-propensity')}
                            </div>
                            <div className="graphs">
                              {KHAIPResult &&
                                (
                                  Object.keys(
                                    KHAIPResult?.['relationship-propensity']
                                  ) || []
                                ).map((data, index) => (
                                  <KHAIPDetailGraph
                                    key={index}
                                    myscore={
                                      KHAIPResult?.['relationship-propensity'][
                                        data
                                      ]?.myscore
                                    }
                                    bigdatascore={
                                      KHAIPResult?.['relationship-propensity'][
                                        data
                                      ]?.bigdatascore
                                    }
                                    tooltip={
                                      KHAIPResult?.['relationship-propensity'][
                                        data
                                      ]?.tooltip
                                    }
                                    title={t(
                                      KHAIPResult?.['relationship-propensity'][
                                        data
                                      ]?.title
                                    )}
                                    title_class="fs14"
                                    score_class="fs14"
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="legend">
                          <div className="candidate">
                            <div className="title fs12">본인</div>
                            <div className="bullet" />
                          </div>
                          <div className="bigdata">
                            <div className="title fs12">빅데이터 평균</div>
                            <div className="bullet" />
                          </div>
                        </div>
                      </div>
                      {(KHAIPQuestion?.work !== null ||
                        KHAIPQuestion?.people !== null ||
                        KHAIPQuestion?.self !== null) && (
                        <div className="result-khai-p-comment">
                          <div className="title fs18">
                            {t('title.overall-comment-and-interview-question')}
                          </div>
                          <KhaiPInterview iv={KHAIPQuestion} />
                        </div>
                      )}
                    </div>
                  </section>
                )}
                {hasKhaiO && (
                  <section className="result-khai-o-section">
                    <div className="khai-logo">
                      <KHAILogo />
                    </div>
                    <h4 className="result-khai-o-title fs20">
                      {t('title.khai-o')}
                    </h4>
                    <div className="result-khai-o-overall">
                      <div className="title fs16">
                        {t('title.overall-results')}
                      </div>
                      <div className="result-wrap">
                        <div className="result-cell">
                          <div className="result-title fs18">
                            {t('title.response-confidence')}
                          </div>
                          {getKhaiO_Reliability(
                            KHAIOResult?.response_reliability,
                            'fs36',
                            'result-comment',
                            'response_reliability',
                            t
                          )}
                        </div>
                        <div className="result-cell">
                          <div className="result-title fs18">
                            {t('title.organizational-suitability')}
                          </div>
                          {getKhaiO_Reliability(
                            KHAIOResult?.organization_conformity,
                            'fs36',
                            'result-comment',
                            'organization_conformity',
                            t
                          )}
                        </div>
                      </div>
                      <div className="alert">
                        <div className="icon">
                          <ErrorIcon />
                        </div>
                        <div className="comment fs14">
                          {t('desc.organizational-suitability')}
                        </div>
                      </div>
                    </div>
                    <div className="result-khai-o-detail">
                      <div className="title fs16">
                        {t('title.detailed-results')}
                      </div>
                      <div className="info">
                        <div className="title fs14">
                          {t('title.organizational-criteria')}
                        </div>
                        <div className="contents">
                          <div className="content">
                            <div className="bullet grey400" />
                            <div className="comment fs14">
                              {t('desc.organizational-criteria-average')}
                            </div>
                          </div>
                          <div className="content">
                            <div className="bullet orange500" />
                            <div className="comment fs14">
                              {t('desc.organizational-criteria-caution')}
                            </div>
                          </div>
                          <div className="content">
                            <div className="bullet red500" />
                            <div className="comment fs14">
                              {t('desc.organizational-criteria-vigilance')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <TableContainer>
                        <Table className="table">
                          <TableHead className="header">
                            <TableRow>
                              <TableCell
                                className="table-header-cell fs14"
                                width={140}
                              >
                                {t('title.organizational')}
                              </TableCell>
                              <TableCell
                                className="table-header-cell fs14"
                                width="auto"
                              >
                                {t('title.definition-contents')}
                              </TableCell>
                              <TableCell
                                className="table-header-cell fs14"
                                colSpan={2}
                              >
                                {t('title.t-score')}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {KHAIOResult?.organization_adaptability &&
                              [
                                'aggression',
                                'impulsiveness',
                                'interest_desire',
                                'indolence',
                                'dependence',
                                'self_downing',
                                'obsession',
                                'contempt_others',
                                'isolation',
                                'rigor',
                                'immorality',
                                'noncooperation',
                              ].map((value) => (
                                <TableRow key={value}>
                                  <TableCell className="table-body-cell bold fs16">
                                    {
                                      KHAIOResult?.organization_adaptability[
                                        value
                                      ].title
                                    }
                                  </TableCell>
                                  <TableCell className="table-body-cell fs16">
                                    {
                                      KHAIOResult?.organization_adaptability[
                                        value
                                      ].desc
                                    }
                                  </TableCell>
                                  <TableCell
                                    className="table-body-cell bold fs16 score"
                                    width={100}
                                  >
                                    {
                                      KHAIOResult?.organization_adaptability[
                                        value
                                      ].score
                                    }
                                  </TableCell>
                                  <TableCell
                                    className="table-body-cell graph-cell"
                                    width={300}
                                  >
                                    <div className="progress-bar">
                                      <div
                                        className={`fill ${getKhaiO_organization_color(
                                          KHAIOResult
                                            ?.organization_adaptability[value]
                                            .score
                                        )}`}
                                        style={{
                                          width: `${parseInt(
                                            KHAIOResult
                                              ?.organization_adaptability[value]
                                              .score
                                          )}%`,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="average"
                                      style={{ left: '50%' }}
                                    />
                                    <div
                                      className="caution"
                                      style={{ left: '70%' }}
                                    />
                                    <div
                                      className="vigilance"
                                      style={{ left: '80%' }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div className="alert">
                        <div className="icon">
                          <ErrorIcon />
                        </div>
                        <div className="comment fs14">
                          {t('desc.propensity-risk-level')}
                        </div>
                      </div>
                      <div className="info">
                        <div className="title fs14">
                          {t('title.propensity-risk-level')}
                        </div>
                        <div className="contents">
                          <div className="content">
                            <div className="bullet red500" />
                            <div className="comment fs14">
                              {t('desc.propensity-risk-level-element-a')}
                            </div>
                          </div>
                          <div className="content">
                            <div className="bullet red500" />
                            <div className="comment fs14">
                              {t('desc.propensity-risk-level-element-b')}
                            </div>
                          </div>
                          <div className="content">
                            <div className="bullet red500" />
                            <div className="comment fs14">
                              {t('desc.propensity-risk-level-element-c')}
                            </div>
                          </div>
                          <div className="content">
                            <div className="bullet red500" />
                            <div className="comment fs14">
                              {t('desc.propensity-risk-level-element-d')}
                            </div>
                          </div>
                          <div className="content">
                            <div className="bullet red500" />
                            <div className="comment fs14">
                              {t('desc.propensity-risk-level-element-e')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <TableContainer>
                        <Table className="table">
                          <TableHead className="header">
                            <TableRow>
                              <TableCell
                                className="table-header-cell fs14"
                                width={140}
                              >
                                {t('title.propensity')}
                              </TableCell>
                              <TableCell
                                className="table-header-cell fs14"
                                width="auto"
                              >
                                {t('title.definition-contents')}
                              </TableCell>
                              <TableCell
                                className="table-header-cell fs14"
                                colSpan={2}
                              >
                                {t('title.percentile')}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* {(PROPENSITY_LIST || []).map((list, idx) => (
                        <TableRow key={idx}>
                          <TableCell className="table-body-cell bold fs16">
                            {t(list.title)}
                          </TableCell>
                          <TableCell className="table-body-cell fs16">
                            {t(list.desc)}
                          </TableCell>
                          <TableCell
                            className="table-body-cell bold fs16 score"
                            width={100}
                          >
                            {KHAIOResult?.abnormality_tendency?.[list.score]}%
                          </TableCell>
                          <TableCell
                            className="table-body-cell graph-cell"
                            width={300}
                          >
                            <div className="progress-bar">
                              <div
                                className={`fill ${
                                  parseInt(
                                    KHAIOResult?.abnormality_tendency?.[
                                      list.score
                                    ]
                                  ) > list.boundary
                                    ? 'red500'
                                    : 'blue500'
                                }`}
                                style={{
                                  width: `${parseInt(
                                    KHAIOResult?.abnormality_tendency?.[
                                      list.score
                                    ]
                                  )}%`,
                                }}
                              />
                            </div>
                            <div
                              className="vigilance"
                              style={{ left: `${list.boundary}%` }}
                            />
                          </TableCell>
                        </TableRow>
                      ))} */}
                            {KHAIOResult?.abnormality_tendency &&
                              [
                                'boundary_line',
                                'antisociality',
                                'anxiety',
                                'gloom',
                              ].map((value) => (
                                <TableRow key={value}>
                                  <TableCell className="table-body-cell bold fs16">
                                    {
                                      KHAIOResult?.abnormality_tendency[value]
                                        .title
                                    }
                                  </TableCell>
                                  <TableCell className="table-body-cell fs16">
                                    {
                                      KHAIOResult?.abnormality_tendency[value]
                                        .desc
                                    }
                                  </TableCell>
                                  <TableCell
                                    className="table-body-cell bold fs16 score"
                                    width={100}
                                  >
                                    {KHAIOResult?.abnormality_tendency[value]
                                      .score ||
                                      KHAIOResult?.abnormality_tendency[value]}
                                    %
                                  </TableCell>
                                  <TableCell
                                    className="table-body-cell graph-cell"
                                    width={300}
                                  >
                                    <div className="progress-bar">
                                      <div
                                        className={`fill ${
                                          parseInt(
                                            KHAIOResult?.abnormality_tendency[
                                              value
                                            ].score
                                          ) >
                                          parseInt(
                                            KHAIOResult?.abnormality_tendency[
                                              value
                                            ].boundary
                                          )
                                            ? 'red500'
                                            : 'blue500'
                                        }`}
                                        style={{
                                          width: `${parseInt(
                                            KHAIOResult?.abnormality_tendency[
                                              value
                                            ].score
                                          )}%`,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="vigilance"
                                      style={{
                                        left: `${KHAIOResult?.abnormality_tendency[value].boundary}%`,
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    {Object.keys(KHAIOQuestion).length > 0 && (
                      <div className="result-khai-o-interview">
                        <div className="title fs16">
                          {t('title.comments-and-interview-questions')}
                        </div>
                        <KhaiOInterview iv={KHAIOQuestion} type="main" />
                      </div>
                    )}
                  </section>
                )}
                <section
                  className="result-obpi"
                  style={{ display: !obpiTest?.traits && 'none' }}
                >
                  <div className="result-obpi-title fs20">
                    {t('title.obpi-result')}
                  </div>

                  <div className="result-obpi-content">
                    <div className="result-obpi-content-left">
                      <div className="obpi-fit-score">
                        <div className="obpi-fit-score-title fs18">
                          {t('title.obpi-fit-score')}
                        </div>
                        <div className="result-obpi-fitscore-graph">
                          <div className="average-score-graph">
                            <div className="single-chart">
                              <svg
                                viewBox="0 0 36 36"
                                className={`circular-chart ${getTestColorClass(
                                  obpimatchScore
                                )} ${obpimatchScore}`}
                              >
                                <path
                                  className="circle-bg"
                                  d="M18 2.0845
                                                              a 15.9155 15.9155 0 0 1 0 31.831
                                                              a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                <path
                                  className="circle"
                                  strokeDasharray={`${parseInt(
                                    obpimatchScore * 100
                                  )} , 100`}
                                  d="M18 2.0845
                                                              a 15.9155 15.9155 0 0 1 0 31.831
                                                              a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                              </svg>
                            </div>
                            <div className="percentage">
                              <span className="number fs56">{`${parseInt(
                                obpimatchScore * 100
                              )}`}</span>
                              <span className="unit fs36">%</span>
                            </div>
                          </div>
                        </div>
                        <div className="result-obpi-content-select-candidate">
                          <section className="select-form">
                            <MaterialSelect
                              title={t(
                                'text-field.label.select-candidate-for-benchmark'
                              )}
                              onChange={(item) => {
                                setStanineCandidate(item);
                              }}
                              value={
                                _candidates?.find(
                                  (x) => x.key === obpiBenchmark?.id
                                )?.key
                              }
                              items={_candidates}
                            />
                          </section>
                          <div className="result-obpi-content-select-candidate-discription fs12">
                            {t('desc.select-candidate-for-benchmark')}
                          </div>
                        </div>
                      </div>
                      <div className="result-obpi-audit">
                        <div className="result-obpi-audit-title fs18">
                          {t('title.audit')}
                          <button onClick={onGuideClickHandler} />
                        </div>
                        <div className="result-obpi-audit-result">
                          <div className="row">
                            {__OBPI_AUDIT__.map((audit, idx) => (
                              <div className="col" key={idx}>
                                <div className="title fs14">
                                  {t(audit.title)}
                                </div>
                                <div className="graph">
                                  <div className="single-chart">
                                    <svg
                                      viewBox="0 0 36 36"
                                      className={`circular-chart ${getAuditColorClass(
                                        obpiAudit[idx]
                                      )}`}
                                    >
                                      <path
                                        className="circle-bg"
                                        d="M18 2.0845
                                                                      a 15.9155 15.9155 0 0 1 0 31.831
                                                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                                      />
                                      <path
                                        className="circle"
                                        strokeDasharray={`${parseInt(
                                          (obpiAudit[idx] / 3) * 100
                                        )} , 100`}
                                        d="M18 2.0845
                                                                      a 15.9155 15.9155 0 0 1 0 31.831
                                                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                                      />
                                    </svg>
                                  </div>
                                  <div className="percentage">
                                    <span className="number fs28">
                                      {obpiAudit[idx]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="result-obpi-content-right">
                      {(OBPI_TESTS || []).map((obpi, idx) => (
                        <OBPIResult
                          key={idx}
                          title={t(obpi.title)}
                          title_class="fs14"
                          selected={obpiStanine?.[idx]}
                          disc_left={t(obpi.left)}
                          disc_right={t(obpi.right)}
                          disc={obpi.disc}
                          disc_class="fs12"
                          interp_class="fs16"
                          interpretation={obpiInterpretation?.[idx]}
                          printmode={false}
                        />
                      ))}
                    </div>
                  </div>
                </section>
                <section
                  className="result-subjective-table"
                  style={{ display: libraryTests?.length === 0 ? 'none' : '' }}
                >
                  <h4
                    className="result-subjective-title fs20"
                    style={{
                      display: objectiveTests?.length === 0 ? 'none' : '',
                    }}
                  >
                    {t('title.subjective-test-result')}
                  </h4>
                  <Paper>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="title thead fs16">
                              {t('column.test')}
                            </TableCell>
                            <TableCell
                              className="section thead fs16"
                              width="15%"
                            >
                              {t('column.type')}
                            </TableCell>
                            <TableCell className="score thead fs16" width="10%">
                              {t('column.score')}
                            </TableCell>
                            <TableCell
                              className="view-answer thead fs16"
                              width="15%"
                            />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(libraryTests || []).map((test, idx) => (
                            <React.Fragment key={idx}>
                              <TableRow
                                key={idx}
                                className={`${
                                  test.id === '990001' && 'relative'
                                }`}
                              >
                                <TableCell className="title tbody fs16">
                                  {convertHtmlToText(test?.category_skill)}
                                </TableCell>
                                <TableCell className="section tbody fs16">
                                  <div className="types">
                                    {(
                                      test?.question_type
                                        ?.replaceAll(' ', '')
                                        .split(',') || []
                                    ).map((type, idx) => (
                                      <span
                                        className={`skill-type fs12 ${type.replaceAll(
                                          '.',
                                          '_'
                                        )}`}
                                        key={idx}
                                      >
                                        {t(type)}
                                      </span>
                                    ))}
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={`score tbody fs16 ${getCurrentLibraryAverageScore(
                                    test
                                  )}`}
                                >
                                  <div className="contain-flex">
                                    <div className="container">
                                      {/* 주관식 테스트 결과 테스트 점수 */}
                                      <ProgressScore
                                        score={Number(
                                          getCurrentLibraryAverageScore(test)
                                        )}
                                        presetScore={Number(
                                          getCurrentLibraryAveragePresetScore(
                                            test
                                          )
                                        )}
                                      />
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell className="view-answer tbody fs16">
                                  <div className="container">
                                    <button
                                      className={`common-button fsbtn16 primary ${
                                        candidate?.progress >= 1
                                          ? 'active'
                                          : 'disabled'
                                      }`}
                                      onClick={() =>
                                        candidate?.progress >= 1
                                          ? onViewLibarayAnswerClickHandler(
                                              test
                                            )
                                          : null
                                      }
                                    >
                                      {t('btn.view-answer')}
                                    </button>
                                  </div>
                                </TableCell>
                              </TableRow>
                              {
                                /** -------------------------------------------------
                                                           * 구찌 스타일링 테스트 일 경우에만 리스트에 결과 화면 display  *  
                                                          --------------------------------------------------- */
                                (
                                  test?.question_type
                                    ?.replaceAll(' ', '')
                                    .split(',') || []
                                ).map(
                                  (type, idx) =>
                                    type === 'tag.styling' && (
                                      <tr
                                        className="styling-container"
                                        key={idx}
                                      >
                                        <td colSpan={4}>
                                          <div className="styling-wrapper">
                                            {test &&
                                              test.question.map((item, i) => (
                                                <div
                                                  className="styling-question"
                                                  key={i}
                                                >
                                                  <div className="styling-title">
                                                    <h4 className="fs16">{`Q${
                                                      i + 1
                                                    }`}</h4>
                                                    <p className="fs14">
                                                      {item.question}
                                                    </p>
                                                  </div>
                                                  <div className="styling-answer">
                                                    <h4 className="fs14">
                                                      {t('title.answer')}
                                                    </h4>
                                                    <div>
                                                      {candidate?.results?.[
                                                        item.id
                                                      ] &&
                                                      candidate?.results?.[
                                                        item.id
                                                      ].img !== undefined ? (
                                                        <div className="styling-answer-image">
                                                          <img
                                                            src={
                                                              candidate
                                                                ?.results?.[
                                                                item.id
                                                              ]?.img
                                                            }
                                                          />
                                                        </div>
                                                      ) : candidate?.results?.[
                                                          item.id
                                                        ] &&
                                                        candidate?.results?.[
                                                          item.id
                                                        ].text !== undefined ? (
                                                        <div className="styling-answer-text">
                                                          <pre
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                convertHtmlForDisplay(
                                                                  candidate
                                                                    ?.results?.[
                                                                    item.id
                                                                  ]?.text
                                                                ),
                                                            }}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                )
                              }
                              {}
                            </React.Fragment>
                          ))}
                          <TableRow
                            sx={{
                              backgroundColor: '#FFFFFF',
                            }}
                          >
                            <TableCell className="fs16">
                              {t('desc.average-score')}
                            </TableCell>
                            <TableCell />
                            <TableCell
                              className={`score tbody fs16 ${getLibrayAverageScore()}`}
                            >
                              <div className="contain-flex">
                                <div className="container">
                                  {/* 주관식 테스트 결과 평균점수 */}
                                  <ProgressScore
                                    score={getLibrayAverageScore()}
                                    presetScore={getLibrayAveragePresetScore()}
                                  />
                                </div>
                              </div>
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </section>
                <section
                  className="result-objective-section"
                  style={{
                    display: objectiveTests?.length === 0 ? 'none' : '',
                  }}
                >
                  <h4 className="result-objective-title fs20">
                    {t('title.objective-test-result')}
                  </h4>
                  <div className="result-objective-wrap">
                    <div className="result-objective-graph">
                      <div className="graph-title fs18">
                        {t('desc.average-score')}
                      </div>
                      <div className="average-score-graph">
                        <div className="single-chart">
                          <svg
                            viewBox="0 0 36 36"
                            className={`circular-chart ${
                              getAverageObjectiveScore() > 0
                                ? getTestColorClass(getAverageObjectiveScore())
                                : ''
                            }`}
                          >
                            <path
                              className="circle-bg"
                              d="M18 2.0845
                                                      a 15.9155 15.9155 0 0 1 0 31.831
                                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              className="circle"
                              strokeDasharray={`${parseInt(
                                getAverageObjectiveScore() * 100
                              )} , 100`}
                              d="M18 2.0845
                                                      a 15.9155 15.9155 0 0 1 0 31.831
                                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                          </svg>
                        </div>
                        <div className="percentage">
                          <span className="number fs56">{`${
                            getAverageObjectiveScore() >= 0
                              ? parseInt(getAverageObjectiveScore() * 100)
                              : '-'
                          }`}</span>
                          <span className="unit fs36">%</span>
                        </div>
                      </div>
                    </div>
                    <article className="result-objective-table">
                      <Paper>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className="title thead fs16"
                                  width="55%"
                                >
                                  {t('column.test')}
                                </TableCell>
                                <TableCell
                                  className="score thead fs16"
                                  width="45%"
                                >
                                  {t('column.score')}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(objectiveTests || []).map((test, idx) => (
                                <React.Fragment key={idx}>
                                  <TableRow>
                                    <TableCell
                                      sx={{ fontWeight: 'bold' }}
                                      className="title tbody fs16"
                                    >
                                      {test?.[`title_${t('en')}`] ||
                                        test?.title}
                                    </TableCell>
                                    <TableCell
                                      className={`score tbody fs16 ${
                                        getScoreObjective(test)?.[0]
                                      }`}
                                    >
                                      <div className="container">
                                        {test?.id === '71' ? (
                                          <></>
                                        ) : (
                                          <>
                                            <span>
                                              {`${parseInt(
                                                getScoreObjective(test)?.[0] *
                                                  100
                                              )}% (${
                                                getScoreObjective(test)?.[1]
                                              }/${
                                                getScoreObjective(test)?.[2]
                                              })`}
                                            </span>
                                            <div className="progress-bar">
                                              <div
                                                className="fill"
                                                style={{
                                                  width: `${
                                                    getScoreObjective(
                                                      test
                                                    )?.[0] * 100
                                                  }%`,
                                                }}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                  {(objectiveCats?.[test?.uuid] || []).map(
                                    (cat, idx) => (
                                      <TableRow
                                        key={idx}
                                        sx={{ backgroundColor: '#F8F9FA' }}
                                      >
                                        <TableCell
                                          sx={{ paddingLeft: 4 }}
                                          className="fs16"
                                        >
                                          {cat?.category}
                                        </TableCell>
                                        <TableCell
                                          className={`score tbody fs16 ${
                                            getScoreObjective(test)?.[0]
                                          }`}
                                        >
                                          <div className="container">
                                            <span>
                                              {`${parseInt(
                                                (
                                                  cat?.val?.correct /
                                                  cat?.val?.cnt
                                                ).toFixed?.(2) * 100
                                              )}% 
                                                                                      (${
                                                                                        cat
                                                                                          ?.val
                                                                                          ?.correct
                                                                                      }/${
                                                cat?.val?.cnt
                                              })`}
                                            </span>
                                            <div className="progress-bar">
                                              <div
                                                className="fill sub"
                                                style={{
                                                  width: `${parseInt(
                                                    (
                                                      cat?.val?.correct /
                                                      cat?.val?.cnt
                                                    ).toFixed?.(2) * 100
                                                  )}%`,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}

                                  {test.id === '71' && (
                                    <TableRow>
                                      <TableCell
                                        className="tbody fs16 culture-fit"
                                        colSpan={2}
                                      >
                                        <div className="container">
                                          <div className="value-survey">
                                            <h3 className="culture-fit-title fs14">
                                              {t(
                                                'title.culture-fit.value-survey'
                                              )}
                                            </h3>
                                            <ul className="attributes-list">
                                              {(
                                                candidateCultureFitAttributes ||
                                                []
                                              ).map((attribute, idx) => (
                                                <li
                                                  key={idx}
                                                  className="attributes-list-item"
                                                >
                                                  {
                                                    attribute?.[
                                                      t('lang') === 'ko'
                                                        ? 'ko'
                                                        : 'en'
                                                    ]
                                                  }
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                          <div className="culture-survey">
                                            <h3 className="culture-fit-title fs14">
                                              {t(
                                                'title.culture-fit.culture-survey'
                                              )}
                                            </h3>
                                            <ul className="attributes-list">
                                              {(
                                                candidateCultureFitCultureAttributes ||
                                                []
                                              ).map((attribute, idx) => (
                                                <li
                                                  key={idx}
                                                  className="attributes-list-item"
                                                >
                                                  {
                                                    attribute?.[
                                                      t('lang') === 'ko'
                                                        ? 'ko'
                                                        : 'en'
                                                    ]
                                                  }
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </React.Fragment>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </article>
                  </div>
                </section>
                {/* 언어테스트 결과 */}
                <LanguageContainer candidate={candidate} />
              </section>

              <section
                className="result-subjective"
                style={{ display: subjectiveTests?.length === 0 ? 'none' : '' }}
              >
                <h4 className="result-subjective-title fs20">
                  {t('title.custom-question-result')}
                </h4>
                <article className="result-subjective-table">
                  <Paper>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="title thead fs16">
                              {t('column.test')}
                            </TableCell>
                            <TableCell
                              className="section thead fs16"
                              width="15%"
                            >
                              {t('column.type')}
                            </TableCell>
                            <TableCell className="score thead fs16" width="10%">
                              {t('column.score')}
                            </TableCell>
                            <TableCell
                              className="view-answer thead fs16"
                              width="15%"
                            />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(subjectiveTests || []).map((test, idx) => (
                            <TableRow key={idx}>
                              <TableCell className="title tbody">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: convertHtmlForDisplay(
                                      test?.question
                                    ),
                                  }}
                                />
                                {/* {convertHtmlToText(
                                                              test?.question
                                                          )} */}
                              </TableCell>
                              <TableCell className="section tbody fs16">
                                <span
                                  className={`skill-type fs12 ${convertSkillTypeToI18nKey(
                                    test?.section
                                  ).replaceAll('.', '_')}`}
                                >
                                  {t(convertSkillTypeToI18nKey(test?.section))}
                                </span>
                              </TableCell>
                              <TableCell
                                className={`score tbody fs16 ${getScore(test)}`}
                              >
                                <div className="contain-flex">
                                  <div className="container">
                                    {/* 커스텀 질문 결과 테스트 점수 */}
                                    {test?.section === 'multiple-choice' ? (
                                      <ProgressScore
                                        score={
                                          answer?.[test?.uuid || test?.id]
                                            ?.score
                                        }
                                      />
                                    ) : (
                                      <ProgressScore
                                        score={getScore(test)}
                                        presetScore={getPresetScore(test)}
                                      />
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="view-answer tbody fs16">
                                <div className="container">
                                  <button
                                    className={`common-button fsbtn16 primary ${
                                      candidate?.progress >= 1
                                        ? 'active'
                                        : 'disabled'
                                    }`}
                                    onClick={() =>
                                      candidate?.progress >= 1
                                        ? onViewAnswerClickHandler(test)
                                        : null
                                    }
                                  >
                                    {t('btn.view-answer')}
                                  </button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow
                            sx={{
                              backgroundColor: '#FFFFFF',
                            }}
                          >
                            <TableCell className="fs16">
                              {t('desc.average-score')}
                            </TableCell>
                            <TableCell />
                            <TableCell
                              className={`score tbody fs16 ${subjectiveTestsAverageScore}`}
                            >
                              {
                                <div className="contain-flex">
                                  <div className="container">
                                    {/* 커스텀 질문 결과 평균 점수 */}
                                    <ProgressScore
                                      score={subjectiveTestsAverageScore}
                                      presetScore={getSubjectiveAveragePresetScore()}
                                    />
                                  </div>
                                </div>
                              }
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </article>
              </section>
              <section className="result-comment">
                <TextareaAutosize
                  placeholder={t('placeholder.personal-memo')}
                  onChange={(e) => setComment(e.target.value)}
                  defaultValue={comment}
                  className="fs16"
                />
                <button
                  className={`common-button fsbtn16 primary ${
                    comment?.length > 0 ? 'active' : 'disabled'
                  }`}
                  onClick={() =>
                    comment?.length > 0 ? onSaveClickHandler() : null
                  }
                >
                  {t('btn.save')}
                </button>
              </section>
            </section>
          )}
          {curTab === 'info' && (
            <section className="info">
              <article className="info-thumbnail box">
                <img src={candidate?.results?.detect?.profile || null} />
              </article>
              <article className="info-prevent box">
                <h4 className="info-prevent-title fs18">
                  {t('title.anti-cheating-monitor')}
                </h4>
                <section className="info-prevent-device row">
                  <h5 className="info-prevent-device-title column key fs14">
                    {t('label.device-used')}
                  </h5>
                  <span className="info-prevent-device-value column value fs14">
                    {candidate?.results?.detect?.device?.client?.name || ''}
                  </span>
                </section>

                <section className="info-prevent-ip-once row">
                  <h5 className="info-prevent-ip-once-title column key fs14">
                    {t('label.filled-out-only-once-from-ip-address')}
                  </h5>
                  <span
                    className={`info-prevent-ip-once-value column value fs14 ${
                      candidate?.results?.detect?.ipOnce ? 'yes' : 'no'
                    }`}
                  >
                    {candidate?.results?.detect?.ipOnce
                      ? t('label.yes')
                      : t('label.no')}
                  </span>
                </section>
                <section className="info-prevent-webcam-activate row">
                  <h5 className="info-prevent-webcam-active-title column key fs14">
                    {t('label.webcam-enabled')}
                  </h5>
                  <span
                    className={`info-prevent-webcam-activate-value column value fs14 ${
                      candidate?.results?.detect?.onWebcam ? 'yes' : 'no'
                    }`}
                  >
                    {candidate?.results?.detect?.onWebcam
                      ? t('label.yes')
                      : t('label.no')}
                  </span>
                </section>
                <section className="info-prevent-full-size row">
                  <h5 className="info-prevent-full-size-title column key fs14">
                    {t('label.full-screen-mode-always-active')}
                  </h5>
                  <span
                    className={`info-prevent-full-size-value column value fs14 ${
                      candidate?.results?.detect?.onFullscreen ? 'yes' : 'no'
                    }`}
                  >
                    {candidate?.results?.detect?.onFullscreen
                      ? t('label.yes')
                      : t('label.no')}
                  </span>
                </section>
                <section className="info-prevent-mouse-pointer row">
                  <h5 className="info-prevent-mouse-pointer-title column key fs14">
                    {t('label.mouse-always-in-assessment-window')}
                  </h5>
                  <span
                    className={`info-prevent-mouse-pointer-value column value fs14 ${
                      candidate?.results?.detect?.isMouseLeave ? 'no' : 'yes'
                    }`}
                  >
                    {candidate?.results?.detect?.isMouseLeave
                      ? t('label.no')
                      : t('label.yes')}
                  </span>
                </section>
              </article>
              <article className="info-detail box">
                <section className="info-detail-invited-at">
                  <h5 className="info-detail-invited-at-title title fs18">
                    {t('title.invited')}
                  </h5>
                  <div className="info-detail-invited-at-timestamp desc fs14">
                    {dateToDateFormat(
                      candidate?.createdAt?.toDate(),
                      t('lang')
                    )}
                  </div>
                </section>
                <section className="info-detail-completed-at">
                  <h5 className="info-detail-completed-at-title title fs18">
                    {t('title.completed')}
                  </h5>
                  <div className="info-detail-completed-at-timestamp desc fs14">
                    {dateToDateFormat(
                      candidate?.answeredAt?.toDate(),
                      t('lang')
                    )}
                  </div>
                </section>
                <section className="info-detail-entry-route">
                  <h5 className="info-detail-entry-route-title title fs18">
                    {t('title.source')}
                  </h5>
                  <div className="info-detail-entry-route-string desc fs14">
                    {/* In current, only way to exit is email. */}
                    Email
                  </div>
                </section>
              </article>
            </section>
          )}
        </>
      )}
      {/* ==============================결과화면 END.==================================== */}
    </article>
  );
};

export default Assessment;
