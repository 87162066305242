import React from 'react';
import { ReactComponent as NextIcon } from 'assets/images/icon/icon-next.svg';
import KhaiOInterviewLeft from './KhaiOInterviewLeft';
import KhaiOInterviewRight from './KhaiOInterviewRight';

const KhaiOInterview = ({ iv, type }) => {
  const interview = iv;
  const rank1 =
    Object.keys(interview)?.length &&
    Object.keys(interview)?.filter((item) => item === 'rank1' || '');
  const rank2 =
    Object.keys(interview)?.length &&
    Object.keys(interview)?.filter((item) => item === 'rank2' || '');
  const rank3 =
    Object.keys(interview)?.length &&
    Object.keys(interview)?.filter((item) => item === 'rank3' || '');

  return (
    <section className="interview-container">
      {interview && (
        <>
          <article className="interview-wrap">
            <KhaiOInterviewLeft
              title={interview?.rank1[0]}
              desc={interview?.rank1[2]}
              rank={rank1[0]}
              type={type}
            />
            <aside className={`interview-icon-box ${type}`}>
              <NextIcon />
            </aside>
            <KhaiOInterviewRight interview={interview?.rank1[3]} type={type} />
          </article>
          <article className="interview-wrap">
            <KhaiOInterviewLeft
              title={interview?.rank2[0]}
              desc={interview?.rank2[2]}
              rank={rank2[0]}
              type={type}
            />
            <aside className={`interview-icon-box ${type}`}>
              <NextIcon />
            </aside>
            <KhaiOInterviewRight interview={interview?.rank2[3]} type={type} />
          </article>
          <article className="interview-wrap">
            <KhaiOInterviewLeft
              title={interview?.rank3[0]}
              desc={interview?.rank3[2]}
              rank={rank3[0]}
              type={type}
            />
            <aside className={`interview-icon-box ${type}`}>
              <NextIcon />
            </aside>
            <KhaiOInterviewRight interview={interview?.rank3[3]} type={type} />
          </article>
        </>
      )}
    </section>
  );
};

export default KhaiOInterview;
