import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';

const WarningModal = ({ title: { sub } }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();

  return (
    <article className="modals-common-warning-modal">
      <div className="title-sub fs16">
        <div>{sub}</div>
      </div>
      <section className="buttons">
        <button
          className="common-button fsbtn16 primary active"
          onClick={() => dismissModal()}
        >
          {t('btn.ok')}
        </button>
      </section>
    </article>
  );
};

export default WarningModal;
