import React, { useEffect, useState } from 'react';
import { executeAction } from 'utils/redux';
import {
  getRecruitments,
  updateRecruitment,
} from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';
import { ReactComponent as CopyIcon } from 'assets/images/icon/copy-link-icon.svg';
import { useTranslation } from 'react-i18next';
import { recruitmentProcess } from '../../../constants/recruitments/position';
import RadioContainer from '../../modal/recruitments/position/radio/RadioContainer';
import CheckboxContainer from '../../modal/recruitments/position/checkbox/CheckboxContainer';
import DatePickerContainer from '../../modal/recruitments/position/datepicker/DatePickerContainer';
import { checkValidationPeriod, setInitialPeriod } from '../../../utils/period';

const link = process.env.REACT_APP_MEDIA_URL;
const SettingContainer = ({ recruitment }) => {
  const { t } = useTranslation();

  const [process, setProcess] = useState(recruitmentProcess);
  const [isSchedule, setIsSchedule] = useState(recruitment.isSchedule);
  const [startPeriodSchedule, setStartPeriodSchedule] = useState(
    recruitment.startPeriodSchedule
  );
  const [endPeriodSchedule, setEndPeriodSchedule] = useState(
    recruitment.endPeriodSchedule
  );
  const [positionJob, setPositionJob] = useState('');
  const [positionCareer, setPositionCareer] = useState('');
  const [position, setPosition] = useState('');

  useEffect(() => {
    setIsSchedule(recruitment.isSchedule);
    setStartPeriodSchedule(recruitment.startPeriodSchedule);
    setEndPeriodSchedule(recruitment.endPeriodSchedule);
    setPositionJob(recruitment.positionInfo.job[0]);
    setPositionCareer(recruitment.positionInfo.career[0]);
    setPosition(recruitment.positionInfo.position[0]);
  }, [recruitment]);

  useEffect(() => {
    if (!endPeriodSchedule) {
      setInitialPeriod(
        isSchedule,
        setStartPeriodSchedule,
        setEndPeriodSchedule
      );
    }
  }, [isSchedule, endPeriodSchedule]);

  const onUpdateRecruitmentHandler = () => {
    const periodValidate = checkValidationPeriod(
      isSchedule,
      startPeriodSchedule,
      endPeriodSchedule
    );
    if (periodValidate === 'none period') toast('none period');
    if (periodValidate === 'end period error') toast('end period error');
    if (periodValidate === 'success') {
      executeAction(
        updateRecruitment({
          rid: recruitment.rid,
          isSchedule,
          startPeriodSchedule: isSchedule
            ? startPeriodSchedule
            : recruitment.startPeriodSchedule,
          endPeriodSchedule: isSchedule
            ? endPeriodSchedule
            : recruitment.endPeriodSchedule,
          isRecruitmentArchived:
            startPeriodSchedule < new Date().getTime() &&
            endPeriodSchedule < new Date().getTime,
        })
      )
        .unwrap()
        .then(() => {
          toast('Successed Update Recruitment');
          executeAction(getRecruitments({ aid: recruitment.rid }));
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const onCreateRecruitmentLink = async () => {
    try {
      await window.navigator.clipboard.writeText(
        `${link}/apply?recruitment=${recruitment.rid}&owner=${recruitment.uid}`
      );
      toast('Link copy successful');
    } catch (error) {
      toast('Link copy failed');
    }
  };

  return (
    <article className="setting-recruitment-container">
      <section className="container">
        <article className="wrapper title-wrapper">
          <div className="application-link-container">
            <div className="application-link-copy-button">
              <button className="copy-button" onClick={onCreateRecruitmentLink}>
                <CopyIcon />
                {t('title.link.copy-link')}
              </button>
            </div>
          </div>
        </article>
        <article className="wrapper information-wrapper">
          <aside className="left">
            <div className="title-box">
              <pre>{t('title.position-information')}</pre>
            </div>
          </aside>
          <aside className="right">
            <RadioContainer
              type="setting"
              firstPositionDepth={positionJob}
              setSecondPositionDepth={undefined}
              secondPositionDepth={positionCareer}
              setFirstPositionDepth={undefined}
              thirdPositionDepth={position}
              setThirdPositionDepth={undefined}
            />
          </aside>
        </article>
        <article className="wrapper process-wrapper">
          <aside className="left">
            <div className="title-box">
              <pre>{t('title.process-setting')}</pre>
            </div>
          </aside>
          <aside className="right">
            <CheckboxContainer
              selectedAsssessment={recruitment}
              process={recruitment?.processSetting}
              setProcess={setProcess}
              disabled
            />
            <hr />
          </aside>
        </article>
        <article className="wrapper standard-wrapper">
          <aside className="left">
            <div className="title-box">
              <pre>{t('text-field.placeholder.evaluation-criteria')}</pre>
            </div>
          </aside>
          <aside className="right">
            {recruitment.standardList?.map((standard, idx) => {
              return (
                <div className="input-standard" key={idx}>
                  <div className="standard-box disable">
                    {standard.standard}
                  </div>
                  <div className="standard-box disable">
                    {standard.standardDesc}
                  </div>
                  <div />
                </div>
              );
            })}
          </aside>
        </article>
        <article className="wrapper schedule-wrapper">
          <aside className="left">
            <div className="title-box">
              <pre>{t('title.set-schedule')}</pre>
            </div>
          </aside>
          <aside className="right">
            <DatePickerContainer
              isSchedule={isSchedule}
              setIsSchedule={setIsSchedule}
              startPeriodSchedule={startPeriodSchedule}
              setStartPeriodSchedule={setStartPeriodSchedule}
              endPeriodSchedule={endPeriodSchedule}
              setEndPeriodSchedule={setEndPeriodSchedule}
            />
          </aside>
        </article>
        <article className="wrapper button-box">
          <button className="save-button" onClick={onUpdateRecruitmentHandler}>
            {t('btn.recruitment-save')}
          </button>
        </article>
      </section>
    </article>
  );
};

export default SettingContainer;
