import React from 'react';
import ReactDOM from 'react-dom';
import { useModal } from 'utils/modal';
import FullModal from './FullModal';
import PartModal from './PartModal';
import IntegrationModal from './IntegrationModal';

const ModalSelector = ({ priority }) => {
  const { path, options, subPath, subOptions } = useModal();

  React.useEffect(() => {
    const rootElem = window.document.getElementById('root');
    if (path) {
      rootElem.classList.add('hasModal');
    } else {
      rootElem.classList.remove('hasModal');
    }
  }, [path]);

  if (priority === 'main' || !priority) {
    if (path) {
      switch (options?.frame) {
        case 'integration':
          return ReactDOM.createPortal(
            <IntegrationModal priority="main" />,
            document.getElementById('modal')
          );
        case 'full':
          return ReactDOM.createPortal(
            <FullModal priority="main" />,
            document.getElementById('modal')
          );
        case 'part':
        default:
          return ReactDOM.createPortal(
            <PartModal priority="main" />,
            document.getElementById('modal')
          );
      }
    } else return null;
  } else if (priority === 'sub') {
    if (subPath) {
      switch (subOptions?.frame) {
        case 'integration':
          return ReactDOM.createPortal(
            <IntegrationModal priority="sub" />,
            document.getElementById('sub-modal')
          );
        case 'full':
          return ReactDOM.createPortal(
            <FullModal priority="sub" />,
            document.getElementById('sub-modal')
          );
        case 'part':
        default:
          return ReactDOM.createPortal(
            <PartModal priority="sub" />,
            document.getElementById('sub-modal')
          );
      }
    } else {
      return null;
    }
  }
};

export default ModalSelector;
