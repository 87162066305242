import React, { useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import { Button } from 'components/common/Button';
import { executeAction } from 'utils/redux';
import { useNavigate } from 'react-router-dom';
import { setCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { useStatus } from 'utils/status';
import ChangeStatusModal from 'components/recruitment/detail/ChangeStatusModal';
import { Trans, useTranslation } from 'react-i18next';

const Benefit = ({ uid, rid, cid, step, candidate, recruitment }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsEntireLoading } = useStatus();

  const [rejectOpen, setRejectOpen] = useState(false);

  const onClickRejectDisagree = () => {
    setRejectOpen(false);
  };
  const onClickRejectAgree = () => {
    setRejectOpen(false);
    setIsEntireLoading(true);

    executeAction(
      setCandidateOfRecruitment({
        ouid: uid,
        aid: rid,
        cid,
        lang: 'ko',
        progressSubmit: {
          [step]: {
            status: 'reject',
            submitedAt: Timestamp.now(),
          },
        },
      })
    )
      .then(() => {
        setIsEntireLoading(false);
        navigate('/offer/reject');
      })
      .catch((e) => {
        console.error(e);
        setIsEntireLoading(false);
      });
  };

  const acceptClickHandler = () => {
    setIsEntireLoading(true);
    if(!uid || !rid || !cid || !candidate) return;

    executeAction(
      setCandidateOfRecruitment({
        ouid: uid,
        aid: rid,
        cid,
        type: 'passedOffer',
        candidateFirstName: candidate?.firstName,
        candidateLastName: candidate?.lastName,
        phoneNumber: candidate?.progressSubmit[1].phoneNumber,
        lang: 'ko',
        progressSubmit: {
          [step]: {
            status: 'accept',
            submitedAt: Timestamp.now(),
          },
        },
      })
    )
      .then(() => {
        navigate('/offer/completed');
        setIsEntireLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsEntireLoading(false);
      });
  };

  return (
    <>
      <article className="recruitment-title">
        <h3 className="fs18">{recruitment?.recruitmentName}</h3>
      </article>
      <article className="benefit-contents-wrapper">
        <aside className="sub-title">{t('title.benefit')}</aside>
        <aside className="contents">
          <div className="content">
            <h5 className="content-title">{t('title.gg-joy-point')}</h5>
            <ul className="content-list">
              <li>{t('desc.gg-joy-point-list-1')}</li>
              <li>{t('desc.gg-joy-point-list-2')}</li>
            </ul>
          </div>
          <div className="content">
            <h5 className="content-title">{t('title.maternity-leave')}</h5>
            <ul className="content-list">
              <li>{t('desc.maternity-leave-list-1')}</li>
              <li>{t('desc.maternity-leave-list-2')}</li>
            </ul>
          </div>
          <div className="content">
            <h5 className="content-title">
              {t('title.long-service-award')}{' '}
              <span>{t('title.long-service-award-helper')}</span>
            </h5>
            <ul className="content-list">
              <li>{t('desc.long-service-award-list-1')}</li>
              <li>{t('desc.long-service-award-list-2')}</li>
              <li>{t('desc.long-service-award-list-3')}</li>
              <li>{t('desc.long-service-award-list-4')}</li>
            </ul>
          </div>
          <div className="content">
            <h5 className="content-title">{t('title.health-wellbeing')}</h5>
            <ul className="content-list">
              <li>{t('desc.health-wellbeing-list-1')}</li>
              <li>{t('desc.health-wellbeing-list-2')}</li>
              {/* <li>{t('desc.health-wellbeing-list-3')}</li> */}
              <li>{t('desc.health-wellbeing-list-4')}</li>
              <li>
                {t('title.health-wellbeing-list-5')}
                <ul className="sub-list">
                  <li>{t('desc.health-wellbeing-list-5-1')}</li>
                  <li>{t('desc.health-wellbeing-list-5-2')}</li>
                  <li>{t('desc.health-wellbeing-list-5-3')}</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="content">
            <h5 className="content-title">{t('title.education-support')}</h5>
            <ul className="content-list">
              <li>{t('desc.education-support-list-1')}</li>
              <li>
                {t('title.education-support-list-2')}
                <ul className="sub-list">
                  <li>{t('desc.education-support-list-2-1')}</li>
                  <li>{t('desc.education-support-list-2-2')}</li>
                  <li>
                    {<Trans t={t} i18nKey="desc.education-support-list-2-3" />}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="content">
            <h5 className="content-title">{t('title.employee-discount')}</h5>
            <ul className="content-list">
              <li>{t('desc.employee-discount-list-1')}</li>
              <li>{t('desc.employee-discount-list-2')}</li>
            </ul>
          </div>
        </aside>
        <aside className="caution">
          <p>
            <i>*</i> {t('helper.benefits-may-change')}
          </p>
        </aside>
      </article>
      <article className="benefit-button-wrapper">
        <div className="flex">
          <Button
            theme={'grey700'}
            label={t('btn.offer-reject')}
            size={56}
            fontSize={'fs16'}
            onClick={() => setRejectOpen(true)}
          />
          <Button
            theme={'grey'}
            label={t('btn.offer-submit')}
            size={56}
            fontSize={'fs16'}
            onClick={acceptClickHandler}
          />
        </div>
      </article>
      <ChangeStatusModal
        open={rejectOpen}
        onClickDisagree={onClickRejectDisagree}
        onClickAgree={onClickRejectAgree}
        main={t('dialog.title.offer-reject')}
        sub={t('dialog.desc.offer-reject')}
      />
    </>
  );
};

export default Benefit;
