import React, { useEffect, useState } from 'react';
import { convertHtmlForDisplay } from 'utils/string';
import { useModal } from 'utils/modal';
import UpdateScoreModal from 'modals/assessments/UpdateScoreModal';
import ResultScoreBox from './ResultScoreBox';

const StylingResult = ({
  visibilityDropdown,
  question,
  candidate,
  assessment,
  setRefreshing,
  test,
  type,
}) => {
  const [isOpenScoreModal, setIsOpenScoreModal] = useState(false);

  const onUpdateScoreModal = () => {
    setIsOpenScoreModal(true);
  };

  const scoreDisplay = (score) => {
    if (score || score === 0) {
      return Math.trunc(
        candidate?.results[`${Number(question?.id) + 1}`]?.score * 100
      );
    }
    if (score === undefined || score === null) {
      return Math.trunc(
        candidate?.results[`${Number(question?.id) + 1}`]?.preset_score * 100
      );
    }
  };

  return (
    <aside
      className={`toggle-body-content styling page-break ${
        visibilityDropdown ? 'slide-in' : 'slide-out'
      }`}
    >
      <div className="toggle-body-answer-box">
        <aside className="img-box">
          <h5 className="styling-title">Image</h5>
          <div className="img-bg">
            <img
              src={candidate?.results[question?.id]?.img || ''}
              alt="styling"
            />
          </div>
        </aside>
        <aside className="answer-box">
          <h5 className="styling-title">Description</h5>
          <div className="answer-wrapper">
            <ResultScoreBox
              score={
                scoreDisplay(
                  candidate?.results[`${Number(question?.id) + 1}`]?.score
                ) || 0
              }
              onClick={onUpdateScoreModal}
              isHideButton={type === 'print' || type !== 'current'}
            />

            <div className="result-stt-box">
              <p
                className="results-desc-v2"
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(
                    candidate?.results[`${Number(question?.id) + 1}`]?.text
                  ),
                }}
              />
            </div>
          </div>
        </aside>
      </div>

      {isOpenScoreModal && (
        <UpdateScoreModal
          open={isOpenScoreModal}
          close={setIsOpenScoreModal}
          setRefreshing={setRefreshing}
          category_skill={test?.category_skill}
          assessment={assessment}
          candidate={candidate}
          question={question}
          type="styling"
        />
      )}
    </aside>
  );
};

export default StylingResult;
