import React from 'react';
import { convertHtmlForDisplay } from 'utils/string';
import { useTranslation } from 'react-i18next';

const CommonQuestion = ({ questions }) => {
  const { t } = useTranslation();

  const convertSection = (question) => {
    const section = question?.section;

    switch (section) {
      case 'essay':
        return t('desc.this-is-a-essay-test');
      case 'video':
        return t('desc.this-is-a-video-test');
      case 'code':
        return t('desc.this-is-a-coding-test');
      case 'file-upload':
        return t('desc.this-is-a-file-upload-test');
      case 'styling':
        return t('desc.this-is-a-styling-test');
      default:
        return '';
    }
  };

  return (
    <article className="question-contents-list">
      {questions &&
        questions?.map((question, index) => (
          <aside className="question-contents-list-items" key={index}>
            <div className="question-contents-list-title">
              <h6 className="fs24">{`Q${index + 1}`}</h6>
              <pre
                className="fs16"
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(question?.question),
                }}
              />
            </div>
            <div className="question-contents-list-choice">
              <div className="grey-box">
                <p className="fs18">{convertSection(question)}</p>
              </div>
            </div>
          </aside>
        ))}
    </article>
  );
};

export default CommonQuestion;
