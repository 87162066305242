import React from 'react';
import { ReactComponent as NoData } from 'assets/images/no-data-ai-interview.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { nameConversionLanguage } from 'utils/converter';
import RecruitmentStatusTab from '../RecruitmentStatusTab';
import { dateFormat } from '../../../../utils/dateFormat';

const EvidenceDetail = ({ recruitment, currentCandidate, processLength }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);
  const step = processLength === 7 ? 4 : 5;
  const docContent = currentCandidate?.progressSubmit[step];

  const performance =
    docContent?.state === 'submit' &&
    !docContent?.isNewcommer &&
    (docContent?.salaryData?.performance === ''
      ? 0
      : parseInt((docContent?.salaryData?.performance).replace(/,/g, '')));

  if (docContent?.state === 'submit') {
    return (
      <div className="wrapper document-detail">
        <div className="flex">
          <div className="left" />
          <div className="right">
            {currentCandidate?.recruitProgress === step &&
              userInfo?.memberType !== 20 && (
                <RecruitmentStatusTab
                  recruitment={recruitment}
                  currentCandidate={currentCandidate}
                  processLength={processLength}
                />
              )}
          </div>
        </div>
        <div className="flex">
          <div className="left">
            <div className="salary-info-box">
              <div className="salary-title">{t('title.salary')}</div>
              <div>
                <div>
                  <div className="title">
                    {t('text-field.label.base-salary')}
                  </div>
                  <div className="number-box">
                    {docContent?.isNewcommer ? (
                      <div>-</div>
                    ) : (
                      <>
                        <div>{docContent?.salaryData.default}</div>
                        <div>{docContent?.salaryData.currency?.i18nKey}</div>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className="title">{t('text-field.label.bonus')}</div>
                  <div className="number-box">
                    {docContent?.isNewcommer ? (
                      <div>-</div>
                    ) : (
                      <>
                        <div>
                          {docContent?.salaryData.performance === ''
                            ? 0
                            : docContent?.salaryData.performance}
                        </div>
                        <div>{docContent?.salaryData.currency?.i18nKey}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="title">{t('title.total-compensation')}</div>
              <div className="number-box">
                {docContent?.isNewcommer
                  ? '-'
                  : (
                      parseInt(
                        (docContent?.salaryData?.default).replace(/,/g, '')
                      ) + performance
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                {!docContent?.isNewcommer &&
                  docContent?.salaryData?.currency?.i18nKey}
              </div>
              <hr />
              <div className="title">{t('title.cash-allowance')}</div>
              <div>
                {docContent?.isNewcommer ? '-' : docContent?.otherAllowances}
              </div>
              <div className="start-date-box">
                <div className="salary-title">
                  {t('text-field.label.starting-date')}
                </div>
                <div className="salary-sub-title">
                  {dateFormat(docContent?.startDate)
                    .slice(0, 10)
                    .replace(/-/g, '.')}
                </div>
              </div>
              {docContent?.hasOverFourWeeksReason && (
                <div className="start-date-box reason">
                  <div className="salary-title">
                    {t('text-field.label.reason')}
                  </div>
                  <div className="salary-sub-title">
                    <span className="desc">
                      {docContent?.hasOverFourWeeksReason}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="right">
            <div className="salary-info-box">
              <div>
                <div className="salary-title">{t('title.personal-detail')}</div>
                <div className="doc-list-box">
                  <div>
                    <h6 className="title">{t('title.english-name')}</h6>
                  </div>
                  <div>
                    <span className="desc">
                      {nameConversionLanguage(
                        docContent?.personalInformation?.englishFirstname,
                        docContent?.personalInformation?.englishLastname,
                        t('lang')
                      )}
                    </span>
                  </div>
                </div>
                <div className="doc-list-box">
                  <div>
                    <h6 className="title">{t('field.birth')}</h6>
                  </div>
                  <div>
                    <span className="desc">
                      {docContent?.personalInformation?.birth}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="salary-title">
                  {t('title.payroll-documents')}
                </div>
                {docContent?.files.map((item, idx) => {
                  return (
                    <div className="doc-list-box" key={`file${idx}`}>
                      <div>
                        <a href={item.url}>{item.name}</a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="flex">
          <div className="refer">
            {docContent?.checkedReference1.name !== '' && (
              <>
                <div className="salary-title reference-title">
                  {t('title.reference-check-1')}
                </div>
                <section className="information-container">
                  <ul>
                    <li>
                      <span className="sub-title fs12">
                        {t('text-field.label.name')}
                      </span>
                      <p className="desc fs12">
                        {docContent?.checkedReference1.name}
                      </p>
                    </li>
                    <li>
                      <span className="sub-title fs12">
                        {t('text-field.label.phone-number')}
                      </span>
                      <p className="desc fs12">
                        {docContent?.checkedReference1.phoneNumber}
                      </p>
                    </li>
                    <li>
                      <span className="sub-title fs12">{t('field.email')}</span>
                      <p className="desc fs12">
                        {docContent?.checkedReference1.email}
                      </p>
                    </li>
                    <li>
                      <span className="sub-title fs12">
                        {t('text-field.label.relation-ship')}
                      </span>
                      <p className="desc fs12">
                        {t(docContent?.checkedReference1.relative.i18nKey)}
                      </p>
                    </li>
                  </ul>
                </section>
              </>
            )}
          </div>
          <div className="refer">
            {docContent?.checkedReference2.name !== '' && (
              <>
                <div className="salary-title reference-title">
                  {t('title.reference-check-2')}
                </div>
                <section className="information-container">
                  <ul>
                    <li>
                      <span className="sub-title fs12">
                        {' '}
                        {t('text-field.label.name')}
                      </span>
                      <p className="desc fs12">
                        {docContent?.checkedReference2.name}
                      </p>
                    </li>
                    <li>
                      <span className="sub-title fs12">
                        {t('text-field.label.phone-number')}
                      </span>
                      <p className="desc fs12">
                        {docContent?.checkedReference2.phoneNumber}
                      </p>
                    </li>
                    <li>
                      <span className="sub-title fs12">{t('field.email')}</span>
                      <p className="desc fs12">
                        {docContent?.checkedReference2.email}
                      </p>
                    </li>
                    <li>
                      <span className="sub-title fs12">
                        {t('text-field.label.relation-ship')}
                      </span>
                      <p className="desc fs12">
                        {t(docContent?.checkedReference2.relative.i18nKey)}
                      </p>
                    </li>
                  </ul>
                </section>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
  if (docContent?.state === 'waiting') {
    return (
      <div className="wrapper document-detail">
        <div className="no-data-container">
          <div className="no-data-image-box">
            <NoData />
          </div>
          <div className="no-data-warning-box">
            <div className="no-data-warning-text">
              {t('desc.not-yet-submitted')}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default EvidenceDetail;
