import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Home = (props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const account = window.localStorage.getItem('munch_skill_account') || 'main';

  React.useEffect(() => {
    if (!Object.keys(userInfo).length) return;

    if (userInfo?.isATS) {
      navigate('/customer/recruitments');
    } else {
      if (userInfo?.privacyAgree) {
        return navigate('/customer/assessments');
      } else {
        return navigate(`/${account}/auth/agree`, { replace: true });
      }
    }
  }, [userInfo]);

  return <main></main>;
};

export default Home;
