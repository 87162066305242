import React from 'react';
import {
  deleteAssessmentResultRecruitmentCandidate,
  deleteFieldRecruitmentCandidate,
  deleteInterviewerRecruitmentCandidate,
} from 'store/slices/recruitments/recruitmentsSlice';
import { executeAction } from 'utils/redux';
import { useSelector } from 'react-redux';
import { useModal } from 'utils/modal';
import { Trans, useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { toast } from '../../../utils/notify';

export const MoveToPrevStepButton = ({targetStageNumber, candidate, rid}) => {
  const { userInfo } = useSelector((state) => state.auth);
  const {displayModal, dismissModal} = useModal();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const onClickMoveToPrevStepButton = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let modifiedCandidate;
    switch(targetStageNumber) {
      case 1:
        modifiedCandidate = {
          ...candidate,
          recruitProgress: 1,
          condition: 0,
          progressSubmit: {
            1: candidate.progressSubmit[1],
          }
        };
        break;
      case 2:
        modifiedCandidate = {
          ...candidate,
          recruitProgress: 2,
          progressSubmit: {
            1: candidate.progressSubmit[1],
            2: candidate.progressSubmit[2]
          }
        };
        break;
      case 3:
        modifiedCandidate = {
          ...candidate,
          recruitProgress: 3,
          progressSubmit: {
            1: candidate.progressSubmit[1],
            2: candidate.progressSubmit[2],
            3: candidate.progressSubmit[3]
          }
        };
        break;
      case 4:
        modifiedCandidate = {
          ...candidate,
          recruitProgress: 4,
          progressSubmit: {
            1: candidate.progressSubmit[1],
            2: candidate.progressSubmit[2],
            3: candidate.progressSubmit[3],
            4: candidate.progressSubmit[4]
          }
        };
        break;
      case 5:
        modifiedCandidate = {
          ...candidate,
          recruitProgress: 5,
          progressSubmit: {
            1: candidate.progressSubmit[1],
            2: candidate.progressSubmit[2],
            3: candidate.progressSubmit[3],
            4: candidate.progressSubmit[4],
            5: candidate.progressSubmit[5]
          }
        };
        break;
      default:
        break;
    }
    const eventHandler = (event) => {
      if (event.detail?.message === 'move-to-prev-step') {
        setIsLoading(true);
        executeAction(
          deleteFieldRecruitmentCandidate({
            ouid: userInfo?.ownerUid,
            rid,
            email: candidate.email,
            newCandidateData: modifiedCandidate
          })
        ).then(() => {
          if(targetStageNumber === 1) {
            executeAction(
              deleteAssessmentResultRecruitmentCandidate(
                {
                  rid,
                  email: candidate.email,
                }
              )
            ).then(() => {
              toast(<Trans
                t={t}
                parent="p"
                i18nKey="toast.desc.movo-to-next"
                values={{
                  firstName: candidate?.firstName,
                  lastName: candidate?.lastName,
                }}
              />);
            })
              .catch((e) => {
                toast(t('toast.fail-edit'));
                Sentry.captureException(e);
              }).finally(() => {
              setIsLoading(false);
            });
          }
          if(!candidate?.progressSubmit[targetStageNumber + 1]?.interviewer ||
            candidate?.progressSubmit[targetStageNumber + 1]?.interviewer?.email === 'Not designated') {
            return;
          }
          executeAction(
            deleteInterviewerRecruitmentCandidate(
              {
                uid: candidate?.progressSubmit[targetStageNumber + 1]?.interviewer.id,
                rid,
                email: candidate.email,
              }
            )
          ).then(() => {
            toast(<Trans
              t={t}
              parent="p"
              i18nKey="toast.desc.movo-to-next"
              values={{
                firstName: candidate?.firstName,
                lastName: candidate?.lastName,
              }}
            />);
          })
            .catch((e) => {
              toast(t('toast.fail-edit'));
              Sentry.captureException(e);
            }).finally(() => {
            setIsLoading(false);
          });
        }).catch((e) => {
          toast(t('toast.fail-edit'));
          Sentry.captureException(e);
        }).finally(() => {
          setIsLoading(false);
        });
      }
      window.document.removeEventListener('dismissModal', eventHandler);
    };
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'common/AlertModal',
      {
        event: {
          message: 'move-to-prev-step',
        },
        title: {
          main: t('dialog.move-prev-step'),
          sub: t('dialog.desc.move-prev-step'),
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };


  React.useEffect(() => {
    if (isLoading){
      displayModal(
        'common/Loading',
        {},
        {
          frame: 'part',
          hasCloseButton: false,
        }
      );
    } else {
      dismissModal();
    }
  }, [isLoading]);
  return (
    <div className="recruitment-table-dropdown-list" onClick={onClickMoveToPrevStepButton}>{t('menu.move-prev-step')}</div>
  );
};