import React from 'react';
import { Outlet } from 'react-router-dom';

const RecruitmentRightContents = ({
  currentTab,
  setCurrentTab,
  filteredRecruitments,
}) => {
  return (
    <article className="recruit-right-container">
      <Outlet context={{ setCurrentTab, filteredRecruitments, currentTab }} />
    </article>
  );
};

export default RecruitmentRightContents;
