import RecruitmentContainer from 'components/recruitment/RecruitmentContainer';
import React, { useEffect, useState } from 'react';
import { executeAction } from 'utils/redux';
import { useSelector } from 'react-redux';
import { getRecruitments } from 'store/slices/recruitments/recruitmentsSlice';
import { getAssessmentsOfCurrentUser } from 'store/slices/assessmentsSlice';
import { useNavigate } from 'react-router-dom';

const Recruitments = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [assessments, setAssessments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) return;

    executeAction(getRecruitments({ memberType: userInfo?.memberType }));
    executeAction(getAssessmentsOfCurrentUser())
      .unwrap()
      .then(({ docs }) => {
        setAssessments(docs);
      })
      .catch((e) => {
        console.error(e);
      });
    if(userInfo.memberType  === 15) {
      navigate('/customer/recruitments/dashboard');
    }

  }, [userInfo]);

  return (
    <>
      <RecruitmentContainer userInfo={userInfo} assessments={assessments} />
    </>
  );
};

export default Recruitments;
