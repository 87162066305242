import RecruitmentTableHead from 'components/recruitment/detail/RecruitmentTableHead';
import RecruitmentTableBody from 'components/recruitment/detail/virtualInterview/RecruitmentTableBody';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

const HEADS = [
  'column.candidate-name',
  'column.mobile-number',
  'field.preferred-channel',
  'field.preferred-location',
  'column.status',
  'column.invitation',
  'column.lead-time',
  '-'
];

const VirtualInterview = () => {
  const recruitmentProps = useOutletContext();
  const { recruitment, candidates, userInfo } = recruitmentProps;

  const [virtualCandidates, setVirtualCandidates] = useState([]);

  useEffect(() => {
    const filterd = candidates?.filter((item) => item?.recruitProgress === 2);
    setVirtualCandidates(filterd);
  }, [candidates]);

  return (
    <section className="dashboard-virtual-interview-contents table-border">
      <table className="recruitment-table">
        <caption>candidates virtual Interview list</caption>
        <RecruitmentTableHead heads={HEADS} />
        <RecruitmentTableBody
          candidates={virtualCandidates}
          recruitment={recruitment}
          userInfo={userInfo}
        />
      </table>
    </section>
  );
};

export default VirtualInterview;
