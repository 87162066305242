import React from 'react';

export const PersonalInformation = (lang) => {
  if(lang === 'ko') {
    return (
      <div className="privacy">
        <p>
          주식회사 프롬나드에이아이(이하 ‘회사’)는 기업이 지원자를 채용함에 있어
          회사가 제공하는 먼치스킬(MunchSkill) 및 먼치스킬 관련 제반 서비스(이하
          ‘서비스’)를 이용하는 것과 관련하여 개인정보보호법 제30조에 따라
          정보주체의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한
          정보주체의 고충을 원활하게 처리할 수 있도록 다음과 같이 개인정보
          처리방침을 수립 공개합니다.
        </p>
        <div className="box">
          <h3>제 1조. 개인정보의 처리 목적</h3>
          <p>
            회사는 개인정보를 다음의 목적을 위해 처리합니다. 회사가 처리하는
            개인정보는 다음 목적 이외의 용도로는 사용되지 않으며 이용 목적이
            변경될 시에는 개인정보보호법에 따라 별도의 동의를 받는 등 필요한
            조치를 이행할 예정입니다.
          </p>
          <ol>
            <li>
              홈페이지 회원가입 및 관리
              <br />
              회원제 서비스 이용에 따른 본인확인, 회원가입 의사 확인, 회원자격
              유지 및 관리, 서비스 부정 이용 방지, 고객문의에 대한 응답, 새로운
              정보의 소개 및 각종 전달사항 고지ㆍ 통지, 고충처리 목적으로
              개인정보를 처리합니다.
            </li>
            <li>
              서비스 제공
              <br />
              서비스 제공, 계약서ㆍ청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공,
              본인인증, 연령인증, 요금결제·정산, 채권추심, 통계분석, 서비스
              고도화, 서비스 개선, 신규 서비스 개발 등을 목적으로 개인정보를
              처리합니다.
            </li>
            <li>
              마케팅 활용
              <br />
              맞춤 서비스 제공, 신규 서비스 안내 및 이용 권유, 통계에 따른 광고,
              이벤트 정보 및 참여기회 제공을 목적으로 개인정보를 처리합니다.
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>제 2조. 개인정보의 처리 및 보유 기간</h3>
          <ol>
            <li>
              회사는 법령에 따른 개인정보 보유ㆍ이용기간 또는 정보주체로부터
              개인정보를 수집 시에 동의 받은 개인정보 보유ㆍ이용기간 내에서
              개인정보를 처리 및 보유합니다.
            </li>
            <li>
              각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
              <ol>
                <li>
                  홈페이지 회원 가입 및 관리 : 회원의 회사 홈페이지 탈퇴 시까지
                  <br />
                  단, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
                  <ol>
                    <li>
                      관계 법령 위반에 따른 수사, 조사 등이 진행중인 경우에는
                      해당 수사, 조사 등 종료 시까지
                    </li>
                    <li>
                      서비스 이용에 따른 채권, 채무관계 잔존 시에는 해당 채권,
                      채무관계 정산 시까지
                    </li>
                  </ol>
                </li>
                <li>
                  서비스 제공 : 서비스 공급완료 및 요금결제ㆍ정산 완료 시까지
                  <br />
                  단, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
                  <ol>
                    <li>
                      「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른
                      표시ㆍ광고, 계약내용 및 이행 등 거래에 관한 기록
                      <ul>
                        <li>표시ㆍ광고에 관한 기록 : 6개월</li>
                        <li>
                          계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년
                        </li>
                        <li>소비자 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                        <li>부정이용 등에 관한 기록: 5년</li>
                      </ul>
                    </li>
                    <li>
                      「통신비밀보호법」제41조에 따른 통신사실확인자료 보관
                      <ul>
                        <li>
                          컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 1년
                        </li>
                      </ul>
                    </li>
                    <li>
                      부가가치세법
                      <ul>
                        <li>세금계산서, 영수증 등 거래내역 관련 정보: 5년</li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>마케팅 활용 : 1년</li>
              </ol>
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>제 3조. 처리하는 개인정보의 항목</h3>
          <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
          <ol>
            <li>
              홈페이지 회원가입 및 관리
              <ul>
                <li>
                  필수 항목(담당자 정보) : 성명, 직위, 연락처, 이메일 주소,
                  비밀번호
                </li>
              </ul>
            </li>
            <li>
              서비스 제공
              <ul>
                <li>
                  필수항목(지원자 정보): 성명, 이메일 주소, 최종 학력, 전공,
                  경력사항(담당 직무 및 근무기간), 거주국가, 나이, 언어능력,
                  인종, 성별, 사진
                </li>
              </ul>
            </li>
            <li>
              마케팅 활용
              <ul>
                <li>선택 항목(담당자 정보): 성명, 이메일 주소</li>
              </ul>
            </li>
            <li>
              인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어
              수집될 수 있습니다.
              <ul>
                <li>
                  IP 주소, 쿠키, MAC 주소, 서비스 이용 기록, 방문 기록, 불량
                  이용기록
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>제 4조. 개인정보의 제 3자 제공</h3>
          <p>
            회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한
            범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등
            개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를
            제3자에게 제공합니다.
          </p>
        </div>
        <div className="box">
          <h3>제 5조. 개인정보 처리위탁</h3>
          <ol>
            <li>
              회사는 원만한 서비스 제공을 위해 아래와 같이 개인정보를 위탁하여
              처리하고 있습니다.
              <table>
                <tbody>
                <tr>
                  <td>수탁업체</td>
                  <td>[ Payment Gateway 사 ]</td>
                </tr>
                <tr>
                  <td>위탁업무 내용</td>
                  <td>결제 처리</td>
                </tr>
                <tr>
                  <td>보유 및 이용기간</td>
                  <td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
                </tr>
                </tbody>
              </table>
            </li>
            <li>
              회사는 원만한 서비스 제공을 위해 아래와 같이 개인정보를 국외법인에
              위탁하여 처리하고 있습니다.
              <table>
                <colgroup>
                  <col width="50%" />
                  <col width="50%" />
                </colgroup>
                <tbody>
                <tr>
                  <td>수탁업체 (연락처)</td>
                  <td>
                    Amazon Web Services, Inc
                    <br />
                    AWS Korea Privacy
                    <br />
                    Email:{' '}
                    <a href="mailto:aws-korea-privacy@amazon.com">
                      aws-korea-privacy@amazon.com
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>위탁업무 내용</td>
                  <td>개인정보 처리방침에 기재된 항목</td>
                </tr>
                <tr>
                  <td>위탁국가</td>
                  <td>미국</td>
                </tr>
                <tr>
                  <td>서비스 제공 과정 중 정보통신망을 통한 수시 이전</td>
                  <td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
                </tr>
                <tr>
                  <td>위탁목적(위탁업무 내용)</td>
                  <td>개인정보 백업 및 보관</td>
                </tr>
                <tr>
                  <td>보유 및 이용기간</td>
                  <td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
                </tr>
                </tbody>
              </table>
              <table>
                <colgroup>
                  <col width="50%" />
                  <col width="50%" />
                </colgroup>
                <tbody>
                <tr>
                  <td>수탁업체 (연락처)</td>
                  <td>
                    Google LLC
                    <br />
                    Privacy Team
                    <br />
                    Email:{' '}
                    <a href="mailto:googlekrsupport@google.com">
                      googlekrsupport@google.com
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>위탁업무 내용</td>
                  <td>개인정보 처리방침에 기재된 항목</td>
                </tr>
                <tr>
                  <td>위탁국가</td>
                  <td>미국</td>
                </tr>
                <tr>
                  <td>서비스 제공 과정 중 정보통신망을 통한 수시 이전</td>
                  <td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
                </tr>
                <tr>
                  <td>위탁목적(위탁업무 내용)</td>
                  <td>개인정보 백업 및 보관</td>
                </tr>
                <tr>
                  <td>보유 및 이용기간</td>
                  <td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
                </tr>
                </tbody>
              </table>
              <table>
                <colgroup>
                  <col width="50%" />
                  <col width="50%" />
                </colgroup>
                <tbody>
                <tr>
                  <td>수탁업체 (연락처)</td>
                  <td>
                    Intercom
                    <br />
                    Security Team
                    <br />
                    Email:{' '}
                    <a href="mailto:security@intercom.com">
                      security@intercom.com
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>위탁업무 내용</td>
                  <td>고객 문의 관련 이메일 발송 대행</td>
                </tr>
                <tr>
                  <td>위탁국가</td>
                  <td>미국</td>
                </tr>
                <tr>
                  <td>서비스 제공 과정 중 정보통신망을 통한 수시 이전</td>
                  <td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
                </tr>
                <tr>
                  <td>위탁목적(위탁업무 내용)</td>
                  <td>고객 문의 관련 이메일 발송 대행</td>
                </tr>
                <tr>
                  <td>보유 및 이용기간</td>
                  <td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
                </tr>
                </tbody>
              </table>
            </li>
            <li>
              회사는 위탁계약 체결 시 개인정보 보호법 제26조에 따라 위탁업무
              수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁
              제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을
              계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게
              처리하는지를 감독하고 있습니다.
            </li>
            <li>
              위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
              처리방침을 통하여 공개하도록 하겠습니다.
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>제 6조. 정보주체와 법정대리인의 권리·의무 및 그 행사 방법</h3>
          <ol>
            <li>
              정보주체는 회사에 대해 언제든지 개인정보 열람, 정정, 삭제,
              처리정지 요구 등의 권리를 행사할 수 있습니다.
            </li>
            <li>
              제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행령 제41조
              제1항에 따라 서면, 전자우편 등을 통하여 하실 수 있으며, 회사는
              이에 대해 지체 없이 조치하겠습니다.
            </li>
            <li>
              정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
              경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
              이용하거나 제공하지 않습니다.
            </li>
            <li>
              제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자
              등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리 방법에
              관한 고시(제2020-7호) 별지 제11호 서식에 따른 위임장을 제출하셔야
              합니다.
            </li>
            <li>
              개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항,
              제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
            </li>
            <li>
              개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
              대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
            </li>
            <li>
              회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
              처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
              대리인인지를 확인합니다.
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>제 7조. 개인정보의 파기</h3>
          <ol>
            <li>
              회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
              불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
            </li>
            <li>
              정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이
              달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
              보존하여야 하는 경우에는, 해당 개인정보를 별도의
              데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
            </li>
            <li>
              개인정보 파기의 절차 및 방법은 다음과 같습니다.
              <ol>
                <li>
                  파기절차
                  <br />
                  회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
                  보호책임자의 승인을 받아 개인정보를 파기합니다.
                </li>
                <li>
                  파기방법
                  <br />
                  회사는 전자적 파일 형태로 기록, 저장된 개인정보는 기록을
                  재생할 수 없도록 관련 방법을 이용하여 파기하며, 종이 문서에
                  기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여
                  파기합니다.
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>제7조의1. 미이용자의 개인정보 파기 등에 관한 조치</h3>
          <ol>
            <li>
              회사는 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로
              전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리 보관된
              개인정보는 1년간 보관 후 지체없이 파기합니다.
            </li>
            <li>
              회사는 휴면전환 30일 전까지 휴면예정 회원에게 별도 분리 보관되는
              사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일,
              문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.
            </li>
            <li>
              휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스
              로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되더라도 로그인을
              하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인
              서비스를 이용할 수 있습니다.
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>제 8조. 개인정보의 안전성 확보조치</h3>
          <p>
            회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
            있습니다.
          </p>
          <ol>
            <li>
              개인정보 보호책임자의 지정 등 개인정보 보호 조직의 구성 및 운영에
              관한 사항 등을 포함하여 개인정보 내부관리계획을 수립하고, 매년
              내부관리계획을 잘 시행하고 있는지를 점검하고 있습니다.
            </li>
            <li>
              개인정보를 처리하는 데이터베이스시스템에 대한 접근권한 관리를 통해
              개인정보에 대한 접근을 통제하고 내∙외부로부터의 무단 접근을
              통제하고 있습니다.
            </li>
            <li>
              개인정보취급자가 개인정보처리시스템에 접속한 기록을 보관 및
              관리하며, 개인정보의 오남용, 분실, 위조 및 변조 등을 방지하기
              위하여 접속기록 등을 정기적으로 점검하며, 개인정보취급자의
              접속기록이 위, 변조 및 도난, 분실되지 않도록 해당 접속기록을
              안전하게 보관하고 있습니다.
            </li>
            <li>
              개인정보를 보관하고 있는 물리적 보관 장소가 있을 시 별도로 두고
              이에 대해 출입통제 절차를 수립, 운영합니다.
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>
            제 9조. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항
          </h3>
          <ol>
            <li>
              회사는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고
              수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
            </li>
            <li>
              쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자들의
              컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
              컴퓨터내의 하드디스크에 저장되기도 합니다.
              <ol>
                <li>
                  쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에
                  대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을
                  파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                </li>
                <li>
                  쿠키의 설치, 운영 및 거부 : 웹브라우저 상단의 [도구 - 인터넷
                  옵션 - 개인정보] 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할
                  수 있습니다.
                </li>
                <li>
                  쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할
                  수 있습니다.
                </li>
              </ol>
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>제 10조. 개인정보 보호책임자</h3>
          <ol>
            <li>
              회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 고충처리를
              위하여 개인정보 보호책임자를 지정하고 있으며 개인정보 보호
              담당부서를 운영하고 있습니다.
              <br />
              [ 개인정보 보호 담당부서 ]<br />
              <ul>
                <li>부서명: 고객지원팀</li>
                <li>
                  Email:{' '}
                  <a href="mailto:help@munchskill.com">help@munchskill.com</a>
                </li>
              </ul>
            </li>
            <li>
              이용자는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호
              관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자
              또는 담당부서로 문의하실 수 있습니다.
            </li>
          </ol>
        </div>
        <div className="box">
          <h3>제 11조. 정보주체의 권익침해 구제방법</h3>
          <p>
            이용자는 개인정보침해로 인한 구제를 받기 위하여
            개인정보분쟁조정위원회, 한국인터넷진흥원, 개인정보침해신고센터 등에
            분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
            개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
            바랍니다.
          </p>
          <ul>
            <li>
              개인정보분쟁조정위원회: (국번없이) 1833-6972 (
              <a href="www.kopico.go.kr">www.kopico.go.kr</a>)
            </li>
            <li>
              개인정보침해신고센터: (국번없이) 118(
              <a href="http://privacy.kisa.or.kr">privacy.kisa.or.kr</a>)
            </li>
            <li>
              대검찰청: (국번없이) 1301
              <a href="www.spo.go.kr">www.spo.go.kr</a>)
            </li>
            <li>
              경찰청: (국번없이) 182 (
              <a href="ecrm.cyber.go.kr">ecrm.cyber.go.kr</a>) )
            </li>
          </ul>
        </div>
        <div className="box">
          <h3>제 12조. 개인정보 처리방침 적용 제외</h3>
          <p>
            회사는 회원에게 서비스 페이지를 통하여 다른 회사의 웹사이트 또는
            자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및
            자료에 대하여 통제권이 없을 뿐만 아니라 이들이 개인정보를 수집하는
            행위에 대하여 회사의 개인정보처리방침이 적용되지 않습니다. 따라서,
            회사가 포함하고 있는 링크를 클릭하여 타 사이트의 페이지로 이동할
            경우에는 새로 방문한 사이트의 개인정보처리방침을 반드시 확인하시기
            바랍니다.
          </p>
        </div>
        <div className="box">
          <h3>제 13조. 개인정보 처리방침의 변경</h3>
          <p>본 개인정보 처리방침은 2022년 7월 12일부터 적용됩니다.</p>
        </div>
      </div>
    );
  }
  if (lang === 'en') {
    return (
      <div className="privacy">
      <p>
        In accordance with Article 30 of the Personal Information Protection Act (the “PIPA”), Promenade AI Inc. (hereinafter referred to as the “Company”) uses MunchSkill and MunchSkill-related services (hereinafter referred to as “Service” or “Services”) provided by the Company in the process of hiring candidates. In order to protect the personal information and rights and interests of the data subject and to effectively handle the complaints of the data subject related to personal information, this Privacy Policy is established and announced as below.
      </p>
      <div className="box">
        <h3>1. The Purposes of Processing Personal Information</h3>
        <p>
          The Company processes personal information for the following purposes. The personal information processed by the Company will not be used for any purpose other than the following, and if the purpose of use is changed, necessary measures will be taken such as obtaining separate consent in accordance with the PIPA.
        </p>
        <ol>
          <li>
            Registration and Management of Membership
            <br />
            The Company processes personal information for the purpose of verifying the identity of persons using the website and intention of signing up for the membership, maintaining and managing right of a membership, preventing from illegal use of Service, replying to customer inquiries, notifying information of a new Service and various notices, and resolving complaints.
          </li>
          <li>
            Service Provision
            <br />
            The Company processes personal information for the purpose of providing Service, sending contracts/invoices, providing contents, customizing Services, verifying identity and age, settling bills, collecting debts, analyzing statistics, advancing and improving Service, developing new Service, etc.
          </li>
          <li>
            Use for Marketing
            <br />
            The Company processes personal information for the purpose of providing customized services, informing new services, recommending use of new services, advertising based on statistics, providing opportunities to participate in events.
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>2. Period of Processing and Retention of Personal Information</h3>
        <ol>
          <li>
            The Company retains and processes personal information only for the period in compliance with all relevant laws and regulations or within the scope of the period agreed by the data subject when his/her personal information was collected.
          </li>
          <li>
            The period of processing and retention of personal information is as follows:
            <ol>
              <li>
                Registration and management of membership at the home page : Until the user withdraws the membership from the home page;
                <br />
                provided that, in any of the following events, until the relevant event is completed:
                <ol>
                  <li>
                    If an investigation is in progress due to a violation of the relevant laws and regulations, until the investigation is completed
                  </li>
                  <li>
                    If any debt or credit exists relating to use of Service, until debt or credit is finally settled
                  </li>
                </ol>
              </li>
              <li>
                Provision of Service: Until the Service is fully provided and the relevant fees are fully paid and settled;
                <br />
                provided that, in any of the following events, until the relevant period is completed:
                <ol>
                  <li>
                    Display and advertisements, transactions records such as contract details and performance under the 「Act on Consumer Protection in Electronic Commerce, Etc.」
                    <ul>
                      <li>records of display and advertisement: 6 months</li>
                      <li>
                        record of contracts or withdrawal of subscription, payment, supply of goods, etc.: 5 years
                      </li>
                      <li>records of consumer complaints or dispute resolution: 3 years</li>
                      <li>records of fraudulent use: 5 years</li>
                    </ul>
                  </li>
                  <li>
                    Storing communication confirmation data in accordance with「Protection of Communication Secret Act」
                    <ul>
                      <li>
                        Computer communications, internet log records, data on tracing a location of information communication apparatus: 1 year
                      </li>
                    </ul>
                  </li>
                  <li>
                    Value-Added Tax Act
                    <ul>
                      <li>Data relating to transaction details such as tax invoices and receipts: 5 years</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>For use of marketing purposes: 1 year</li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>3. Items of Personal information Processed by the Company</h3>
        <p>The Company processes following items of personal information:</p>
        <ol>
          <li>
            Registration and management of membership at the home page
            <ul>
              <li>
                Mandatory information (user information): name, position, phone number, email address, passwords
              </li>
            </ul>
          </li>
          <li>
            Provision of Service
            <ul>
              <li>
                Mandatory information (candidate information): name, email address, academic background and major, work experience (assignment task and work period), country of residence, age, language, ethnicity, gender, photo
              </li>
            </ul>
          </li>
          <li>
            For use of marketing
            <ul>
              <li>Optional information (user information): name, email address</li>
            </ul>
          </li>
          <li>
            Following types of items of personal information may be automatically generated and collected during the use of Services on internet.
            <ul>
              <li>
                IP address, cookie, MAC address, Service usage records, website visit records, records of fraudulent use
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>4. Provision of Personal Information to Third Parties</h3>
        <p>
          The Company processes the personal information only within the scope specified in Article 1 (The purpose of processing personal information), and provides personal information to third parties only when it falls under Articles 17 and 18 of the PIPA such as when the consent is obtained from the data subject or when required by special provisions in the law
        </p>
      </div>
      <div className="box">
        <h3>5. Outsourcing of Personal Information Processing</h3>
        <ol>
          <li>
            The Company outsources personal information processing to a third party as follows for smooth provision of Services
            <table>
              <tbody>
              <tr>
                <td>Outsourcee</td>
                <td>[ Payment Gateway companies ]</td>
              </tr>
              <tr>
                <td>Outsourced Task</td>
                <td>Processing settlement</td>
              </tr>
              <tr>
                <td>Retention and Usage Period of Personal Information</td>
                <td>Until the user withdraws membership, or the outsourcing agreement is terminated</td>
              </tr>
              </tbody>
            </table>
          </li>
          <li>
            The Company conducts overseas transfer of the following personal information for outsourced processing by foreign entities.
            <table>
              <colgroup>
                <col width="50%" />
                <col width="50%" />
              </colgroup>
              <tbody>
              <tr>
                <td>Outsourcee (Contact details)</td>
                <td>
                  Amazon Web Services, Inc
                  <br />
                  AWS Korea Privacy
                  <br />
                  Email:{' '}
                  <a href="mailto:aws-korea-privacy@amazon.com">
                    aws-korea-privacy@amazon.com
                  </a>
                </td>
              </tr>
              <tr>
                <td>Outsourced personal information</td>
                <td>All types of personal information described in Article 3</td>
              </tr>
              <tr>
                <td>Country of Outsourcee</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Time and Method of Outsourcing</td>
                <td>Frequent transfer using dedicated private networks during Service provision</td>
              </tr>
              <tr>
                <td>Purpose of Entrustment</td>
                <td>Data backup and store</td>
              </tr>
              <tr>
                <td>Retention & Usage Period</td>
                <td>Until the user’s withdrawal of membership or the termination of the outsourcing agreement</td>
              </tr>
              </tbody>
            </table>
            <table>
              <colgroup>
                <col width="50%" />
                <col width="50%" />
              </colgroup>
              <tbody>
              <tr>
                <td>Outsourcee (Contact details)</td>
                <td>
                  Google LLC
                  <br />
                  Privacy Team
                  <br />
                  Email:{' '}
                  <a href="mailto:googlekrsupport@google.com">
                    googlekrsupport@google.com
                  </a>
                </td>
              </tr>
              <tr>
                <td>Outsourced personal information</td>
                <td>All types of personal information described in Article 3</td>
              </tr>
              <tr>
                <td>Country of Outsourcee</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Time and Method of Outsourcing</td>
                <td>Frequent transfer using dedicated private networks during Service provision</td>
              </tr>
              <tr>
                <td>Purpose of Entrustment</td>
                <td>Data backup and store, sending emails</td>
              </tr>
              <tr>
                <td>Retention & Usage Period</td>
                <td>Until the user’s withdrawal of membership or the termination of the outsourcing agreement</td>
              </tr>
              </tbody>
            </table>
            <table>
              <colgroup>
                <col width="50%" />
                <col width="50%" />
              </colgroup>
              <tbody>
              <tr>
                <td>Outsourcee (Contact details)</td>
                <td>
                  Intercom
                  <br />
                  Security Team
                  <br />
                  Email:{' '}
                  <a href="mailto:security@intercom.com">
                    security@intercom.com
                  </a>
                </td>
              </tr>
              <tr>
                <td>Outsourced personal information</td>
                <td>All types of personal information described in Article 3</td>
              </tr>
              <tr>
                <td>Country of Outsourcee</td>
                <td>United States</td>
              </tr>
              <tr>
                <td>Time and Method of Outsourcing</td>
                <td>Frequent transfer using dedicated private networks during Service provision</td>
              </tr>
              <tr>
                <td>Purpose of Entrustment</td>
                <td>Sending email responses related to customer inquiries</td>
              </tr>
              <tr>
                <td>Retention & Usage Period</td>
                <td>Until the user’s withdrawal of membership or the termination of the outsourcing agreement</td>
              </tr>
              </tbody>
            </table>
          </li>
          <li>
            When the Company executes outsourcing agreements, the Company states prevention of personal information processing for other purposes that the outsourcing purpose, technical and managerial safeguards of personal information, prevention of sub-outsourcing, management and supervision of outsourcee, matters relating to liabilities such as compensation for damages, etc. through a document in the outsourcing agreement, and supervise whether the outsourcee processes the personal information in a safe manner, in accordance with the Article 26 of the PIPA.
          </li>
          <li>
            The Company will immediately notify through this Privacy Policy if there is a change in outsourcee, outsourced task, etc..
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>6. Rights and Obligations of Data Subject and Its Legal Representatives, How to Exercising Such Rights</h3>
        <ol>
          <li>
            The data subject may exercise its rights such as requesting the Company to allow viewing, update, modify, delete or suspend processing of its personal information at any time.
          </li>
          <li>
            Exercise of the rights under the paragraph 1 may be conducted in writing or via in accordance with Article 41(1) of the Enforcement Decree of the PIPA, And the Company will take measures without delay.
          </li>
          <li>
            When the data subject requests the Company to modify or delete errors in the personal information, the Company will not use or provide the relevant personal information until requested modification or deletion is completed.
          </li>
          <li>
            Exercise of the rights under the paragraph 1 may be conducted by the legal representation of the data subject or anyone having delegated authority from the data subject, in which event, the power of attorney prepared in accordance with Form 11 attached to the Public Notice on Personal Information Handling Methods (No.2020-7) shall be submitted.
          </li>
          <li>
            In case of the request for viewing or suspension of processing of personal information, the rights of the data subject user may be restricted under Article 35(4) and Article 37(2) of the PIPA.
          </li>
          <li>
            In case of the request for modification or deletion of personal information, such request will not be accepted if collection of the relevant personal information is required under other laws.
          </li>
          <li>
            The Company verifies whether the person who requests to view, modify, delete or suspend processing of the personal information is the data subject himself/herself or legal representative.
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>7. Destruction of Personal Information</h3>
        <ol>
          <li>
            The Company destroys personal information without delay when the personal information becomes unnecessary, for instance, when the retention period is expired, purpose of processing is accomplished etc.
          </li>
          <li>
            If retention of personal information is required under other laws even after the retention period of personal information for which consent is obtained from the data subject is expired or it’s the purpose of processing personal information is accomplished, the Company will store personal information by transferring the personal information to a separate database (DB) or in a different location.
          </li>
          <li>
            The process and methods of destruction of personal information are as follows.
            <ol>
              <li>
                Destruction process
                <br />
                The Company selects the personal information for which cause of destruction has occurred, and destroys the personal information with the approval of the Company’s Privacy Officer.
              </li>
              <li>
                Destruction methods
                <br />
                The Company destroys personal information recorded and stored in the form of electronic files using related methods so that the record cannot be recovered, and personal information recorded and stored in paper documents is destroyed with a shredder or burnt.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>7.1 Measures Regarding Destruction of Personal Information of Dormant Users</h3>
        <ol>
          <li>
            The Company converts users who have not used the Service for at least one year to a dormant account, and separately stores and manage personal information. Separately stored personal information is stored for one year and then destroyed without delay.
          </li>
          <li>
            The Company notifies user whose account would be converted dormant until 30 days prior to conversion to dormancy with information about the fact relating to conversion, timing of conversions, items that would be separately stored by any means available for such notice including email or text message.
          </li>
          <li>
            Users who do not want their accounts to be converted to dormancy may log in to the Service before the scheduled conversion. Furthermore, even after the account has converted to dormant account, the user still may the Service by logging in to the account, providing the consent and restoring the account.
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>8. Measures to Secure Safety of Personal Information</h3>
        <p>
          The Company takes following measures to secure safety of the personal information.
        </p>
        <ol>
          <li>
            The Company has established an internal management plan of personal information, containing matters in relation to the composition and operation of the personal information protection organization, such as the designation of Privacy Officer, and inspects whether the internal management plan is duly implemented each year.
          </li>
          <li>
            The Company controls access to personal information by managing access authority for database system that processes personal information and restricts unauthorized access from inside and outside.
          </li>
          <li>
            The Company stores and manages access records of the personal information processing system by personal information handler, periodically inspects access records, etc. to prevent any misuse, loss, forgery, or tampering of personal information, and safely stores the relevant access records so that they are not forged, stolen, or lost.
          </li>
          <li>
            If the personal information is stored in a physical location, the Company establishes and operates access control procedures.
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>
          9. Matters concerning Installation, Operation, and Denial of Automatic Collection Tool for Personal Information
        </h3>
        <ol>
          <li>
            The Company use ‘cookies’ to save and retrieve use information in order to provide personally customized services.
          </li>
          <li>
            Cookie is small amount of information that the server (http) used to operate the website sends to users’ computer browsers, and is also stored on the hard disk of users’ PC.
            <ol>
              <li>
                Purpose of using cookies: to provide optimized information to users by identifying visits and usage, popular search terms, secure access, etc. based on user’s visit of each Service and website
              </li>
              <li>
                b. Installation, operation and denial of cookies: user may deny saving of cookies at at ( Tools > Internet option > Personal information ) on top of the browser.
              </li>
              <li>
                If user denies to saving cookies, user may experience difficulties using personalized Service.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>10. Privacy officer</h3>
        <ol>
          <li>
            The Company designated the following person as the Privacy Officer and operates the Personal Information Protection Team in order to protect the personal information of users and to resolve disputes involving the personal information of users.
            <br />
            [ Personal Information Protection Team ]<br />
            <ul>
              <li>Department: Customer support team</li>
              <li>
                Email:{' '}
                <a href="mailto:help@munchskill.com">help@munchskill.com</a>
              </li>
            </ul>
          </li>
          <li>
            Users may inquire any matter relating to questions about personal information protection, complaints, compensation for damage, etc. to the Privacy Officer or Personal Information Protection Team.
          </li>
        </ol>
      </div>
      <div className="box">
        <h3>11. Remedy for Infringement of Rights of Data Subject</h3>
        <p>
          Users may apply for dispute resolution or consultation in order to obtain remedies for damages resulting from infringement of personal information by contacting Personal Information Dispute Mediation Committee, Korea Internet & Security Agency, Personal Information Infringement Report Center, etc. Please contact following institutions for reporting or consultation on infringement of personal information.
        </p>
        <ul>
          <li>
            Personal Information Dispute Mediation Committee: (Call 1833-6972 /
            <a href="www.kopico.go.kr">www.kopico.go.kr</a>)
          </li>
          <li>
            Personal Information Infringement Report Center: (Call 118 /
            <a href="http://privacy.kisa.or.kr">privacy.kisa.or.kr</a>)
          </li>
          <li>
            Supreme Prosecutor’s office: (Call 1301 /
            <a href="www.spo.go.kr">www.spo.go.kr</a>)
          </li>
          <li>
            National Policy (Call 182 /
            <a href="ecrm.cyber.go.kr">ecrm.cyber.go.kr</a>)
          </li>
        </ul>
      </div>
      <div className="box">
        <h3>12. Exclusion of Application of Privacy Policy</h3>
        <p>
          The Company may provide members with hyperlinks to other companies’ websites or materials through the Service page. In this case, the Company has no control over external sites or materials, and the Company’s Privacy Policy does not apply to collection of personal information by such other companies. In this regard, please be sure to check the privacy policy of the newly visited website once you click on a new hyperlink included in Company’s website.
        </p>
      </div>
      <div className="box">
        <h3>13. Amendment of Privacy Policy</h3>
        <p>This Privacy Policy is effective as of 2022-07-12.</p>
      </div>
    </div>
    );
  }
};