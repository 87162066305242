import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';

export const EditMultiSelectBox = ({ menu, name, value, onChange }) => {
  const {t} = useTranslation();
  return (
    <Select
      sx={{ fontSize: 12, width: '100%'}}
      name={name}
      classes={{ outlined: 'candidate-info-edit-select'}}
      multiple
      value={value}
      onChange={onChange}
      renderValue={(selected) => selected.join(', ')}>
      {menu.map((item) =>
        <MenuItem sx={{padding:  '2px'}} key={item.key} value={t(item.i18nKey)}>
          <Checkbox  sx={{padding:  '4px'}} size="small" checked={value.indexOf(t(item.i18nKey)) > -1} />
          <ListItemText primaryTypographyProps={{fontSize: 12}} primary={t(item.i18nKey)} />
        </MenuItem>)}
    </Select>
  );
};