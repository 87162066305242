import React from 'react';

const CategoryCard = ({ isCardLoading, title, contains, button }) => (
  <article
    className={`components-card-category-card card ${
      isCardLoading && 'pending'
    }`}
  >
    <h3 className="title fs18">{title || ''}</h3>
    <ul>
      {(contains || []).slice(0, 5).map((contain, idx) =>
        contain?.length > 0 ? (
          <li className="fs14" key={idx}>
            {contain}
          </li>
        ) : (
          <></>
        )
      )}
      {contains?.length > 5 ? (
        <li className="fs14"> +{contains?.length - 5} </li>
      ) : (
        <></>
      )}
    </ul>
    <footer>{button}</footer>
  </article>
);

export default CategoryCard;
