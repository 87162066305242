import React, { useEffect, useState } from 'react';
import BasicInput from 'components/input/BasicInput';
import BasicSelect from 'components/select/BasicSelect';
import { toast } from 'utils/notify';
import { sumSalary } from 'utils/converter';
import { useTranslation } from 'react-i18next';

const DetailLeftForm = ({ fetchSalary, currentProgress }) => {
  const { t } = useTranslation();

  const [isCompleted, setIsCompleted] = useState(false);
  const [inputValue, setInputValue] = useState({
    default: currentProgress?.salaryData?.default
      ? currentProgress?.salaryData?.default
      : '',
    performance: currentProgress?.salaryData?.performance
      ? currentProgress?.salaryData?.performance
      : '',
    currency: currentProgress?.salaryData?.currency
      ? currentProgress?.salaryData?.currency
      : {
          key: 'KRW',
          i18nKey: 'KRW',
        },
  });

  useEffect(() => {
    if (isCompleted) {
      fetchSalary(inputValue);
    }
  }, [inputValue, isCompleted]);

  useEffect(() => {
    let flag = true;

    if (!inputValue.default) {
      flag = false;
    }

    setIsCompleted(flag);
  }, [inputValue]);

  const handleInputValue = (e, menu, type) => {
    if (type === 'select') {
      setInputValue((prevInput) => ({
        ...prevInput,
        [e]: menu,
      }));

      return;
    }

    const { name, value } = e.target;

    const number = value.replace(/[^0-9]/g, '');

    if (number.length > 14) {
      return toast('14자 이내로 작성해주세요.');
    }
    setInputValue((prevInput) => ({
      ...prevInput,
      [name]: Number(number).toLocaleString('ko-KR'),
    }));
    return;
  };

  return (
    <>
      <div className="flex">
        {/* 기본급 */}
        <div className="form-box flex">
          <BasicInput
            required
            inputType={'text'}
            label={t('text-field.label.base-salary')}
            name={'default'}
            value={inputValue.default}
            onChange={handleInputValue}
          />
          <BasicSelect
            className={'currency-form'}
            value={inputValue.currency}
            name={'currency'}
            onChange={handleInputValue}
            menus={[
              { key: 'KRW', i18nKey: 'KRW' },
              { key: 'USD', i18nKey: 'USD' },
            ]}
          />
        </div>
        {/* 상여금 */}
        <div className="form-box">
          <BasicInput
            inputType={'text'}
            label={t('text-field.label.bonus')}
            name={'performance'}
            value={inputValue.performance}
            onChange={handleInputValue}
          />
        </div>
      </div>
      <div className="salary-box">
        <h4 className="fs20">{t('title.total-compensation')}</h4>
        <p className="fs24">
          {sumSalary(inputValue.default, inputValue.performance)}
        </p>
      </div>
    </>
  );
};

export default DetailLeftForm;
