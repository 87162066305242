import React from 'react';
import { useModal } from 'utils/modal';

const IntegrationModal = ({ priority }) => {
  let { dismissModal, path, params, options, subPath, subParams, subOptions } =
    useModal();

  const __PADDING__ = options?.padding || '20px';

  const onDismissHandler = () => {
    dismissModal();
  };

  if (priority === 'main' || !priority) {
    if (path) {
      const ModalComponent = require(`modals/${path}.jsx`).default;

      return (
        <div
          className={`modal integration-modal ${
            options.hasButton && 'hasButton'
          }`}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: options.backgroundColor || 'white',
          }}
        >
          <ModalComponent {...params} {...options} />
        </div>
      );
    }
  } else if (priority === 'sub') {
    if (subPath) {
      const ModalComponent = require(`modals/${subPath}.jsx`).default;

      return (
        <div
          className={`modal integration-modal ${
            subOptions.hasButton && 'hasButton'
          }`}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'white',
          }}
        >
          <ModalComponent {...subParams} {...subOptions} />
        </div>
      );
    }
  }

  return <></>;
};

export default IntegrationModal;
