import React from 'react';

const PrintLanguageScore = ({ title, score }) => {
  const convertScoreToGrade = (score) => {
    let grade;

    if (score < 4) {
      grade = 'Begginer';
    } else if (score >= 4 && score < 7) {
      grade = 'Intermediate';
    } else if (score >= 7 && score < 11) {
      grade = 'Advanced';
    }

    return grade;
  };

  return (
    <div className="language-score-box">
      <div className="language-score-title">
        <h5>{title}</h5>
      </div>
      <div className="language-score-content">
        <div className={`score-bg ${convertScoreToGrade(score)}`}>
          <span className="score-number">{score || 0}</span>
          <span className="score-grade">{convertScoreToGrade(score)}</span>
        </div>
      </div>
    </div>
  );
};

export default PrintLanguageScore;
