import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePrevious, useLoading, useCheckedAdminPage } from 'utils/hooks';
import { ReactComponent as PlusIcon } from 'assets/images/icon/plus.svg';
import { ReactComponent as PasteSearch } from 'assets/images/icon/content-paste-search.svg';
import { dateToDateFormat, isSameLowerCaseString } from 'utils/string';
import { ReactComponent as MoreIcon } from 'assets/images/icon/more.svg';
import { useModal } from 'utils/modal';
import { useAuth } from 'utils/auth';
import { useStatus } from 'utils/status';
import {
  getAssessmentsOfCurrentUser,
  changeStateOfAssessment,
  getCandidatesOfAssessments,
  createAssessment,
  updateCandidate,
  updatePeriod,
} from 'store/slices/assessmentsSlice';
import { executeAction } from 'utils/redux';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import MaterialInput from 'components/input/MaterialInput';
import MaterialSelect from 'components/select/MaterialSelect';
import { ReactComponent as SearchIcon } from 'assets/images/icon/search.svg';
import { getUserInfo } from 'store/slices/authSlice';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { CircularProgress } from '@mui/material';

const PURPOSE = [
  {
    key: 'view-all',
    i18nKey: 'menu.input.view-all',
  },
  {
    key: 'employee-assessment',
    i18nKey: 'menu.input.employee-assessment',
  },
  {
    key: 'recruitment-assessment',
    i18nKey: 'menu.input.recruitment-assessment',
  },
];

const ASSESSMENT_TABS = [
  {
    key: 'active',
    i18nKey: 'tab.open-assessment',
    onClick: ({ navigate, setCurTab }, callback) => {
      setCurTab('active');
    },
  },
  {
    key: 'archived',
    i18nKey: 'tab.close-assessment',
    onClick: ({ navigate, setCurTab }, callback) => {
      setCurTab('archived');
    },
  },
  {
    key: 'all',
    i18nKey: 'tab.all',
    onClick: ({ navigate, setCurTab }, callback) => {
      setCurTab('all');
    },
  },
];

const TABS = [
  {
    key: 'active',
    i18nKey: 'btn.active',
    onClick: ({ navigate, setCurTab }, callback) => {
      setCurTab('active');
    },
  },
  {
    key: 'archived',
    i18nKey: 'btn.archived',
    onClick: ({ navigate, setCurTab }, callback) => {
      setCurTab('archived');
    },
  },
];

const __DROPDOWN_ITEMS__ = [
  {
    key: 'clone',
    i18nKey: 'actions.menu.clone',
    tabs: ['active', 'archived'],
    active: true,
    onClick: ({ idx, tests, setShowDropdown, setIsEntireLoading, setInit }) => {
      const assessment = tests?.[idx];
      setIsEntireLoading(true);
      executeAction(
        createAssessment({
          name: assessment?.name || '',
          position: assessment?.position || {},
          tests: assessment?.tests || [],
          groupTests: assessment?.groupTests || [],
          objectiveTests: assessment?.objectiveTests || [],
          subjectiveTests: assessment?.subjectiveTests || [],
          AntiCheating: assessment?.AntiCheating || false,
          purpose: assessment?.purpose || 'recruitment-assessment',
          language: assessment?.language || 'ko',
        })
      )
        .unwrap()
        .then(() => {
          setShowDropdown(-1);
          setIsEntireLoading(false);
          setInit(false);
        })
        .catch((e) => {
          console.dir(e);
          setShowDropdown(-1);
          setIsEntireLoading(false);
        });
    },
  },
  {
    key: 'access',
    i18nKey: 'actions.menu.manage-access',
    tabs: ['active', 'archived'],
    active: false,
    onClick: ({ idx, setShowDropdown }) => {},
  },
  {
    key: 'archive',
    i18nKey: 'menu.input.close-assessment',
    tabs: ['active'],
    active: true,
    onClick: ({
      idx,
      tests,
      _tests,
      _setTests,
      setShowDropdown,
      onClickToggleAssessment,
    }) => {
      onClickToggleAssessment(tests?.[idx]);
    },
  },
  {
    key: 'active',
    i18nKey: 'actions.menu.active',
    tabs: ['archived'],
    active: true,
    onClick: ({
      idx,
      tests,
      _tests,
      _setTests,
      setShowDropdown,
      onClickToggleAssessment,
    }) => {
      onClickToggleAssessment(tests?.[idx]);
    },
  },
];

const Assessments = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { displayModal } = useModal();
  const { user } = useAuth();
  const auth = useAuth();
  const { setIsEntireLoading } = useStatus();
  const [init, setInit] = React.useState(false);
  const [isLoading, setIsLoading] = useLoading(() => {});
  const [curTab, setCurTab] = React.useState(
    window.sessionStorage.getItem('munch_skill_assessments_cur_tab') || 'active'
  );
  const [_tests, _setTests] = React.useState([]);
  const [totalTests, setTotalTests] = React.useState([]);
  const [tests, setTests] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [curAssessmentTab, setCurAssessmentTab] = React.useState('all');
  const checkedAdmin = useCheckedAdminPage();
  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showDropdown, setShowDropdown] = React.useState(-1);
  const [skillList, setSkillList] = React.useState({});
  const [sorting, setSorting] = React.useState({
    createdAt: 'desc',
  });
  const [purpose, setPurpose] = React.useState('');
  const { userInfo } = useSelector((state) => state.auth);
  const account = window.localStorage.getItem('munch_skill_account') || 'main';

  const rowRefs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];
  const resultRef = React.useRef(null);
  const dropdownRef = React.useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /** ============================
     * url에 admin이 있으면 유지
    ================================ */
  React.useEffect(() => {
    if (checkedAdmin) {
      navigate('/admin/customer/assessments', { replace: true });
    } else {
      navigate('/customer/assessments', { replace: true });
    }
  }, [checkedAdmin]);

  React.useEffect(() => {
    if (Object.keys(userInfo).length === 0) return;
    if (init) return;
    setIsLoading(true);
    const skilllist = {};
    executeAction(getAssessmentsOfCurrentUser())
      .unwrap()
      .then(({ docs }) => {
        executeAction(
          getCandidatesOfAssessments({
            assessments: docs,
          })
        )
          .unwrap()
          .then(({ candidates }) => {
            (docs || []).forEach((doc, idx) => {
              const skills = new Array();
              doc.candidates = candidates?.[idx] || [];
              (doc?.tests || []).forEach((test, idx) => {
                skills.push(
                  test?.title_en ||
                    test?.category_skill ||
                    doc?.groupTests?.group
                );
              });
              skilllist[doc.id] = skills;
            });

            setSkillList(skilllist);
            _setTests(docs || []);
            setInit(true);
          });
      })
      .catch((e) => {
        Sentry.captureMessage('getAssessmentsOfCurrentUser', {extra: {e}});
        console.dir(e);
      });
  }, [init, userInfo]);

  React.useEffect(() => {
    const handler = (event) => {
      for (const rowRef of rowRefs) {
        if (rowRef.current) {
          const moreElem = rowRef.current.querySelector('.more-icon');
          if (moreElem.contains(event.target)) return;
        }
      }

      if (!dropdownRef.current || dropdownRef.current.contains(event.target))
        return;

      setShowDropdown(-1);
    };

    window.document.addEventListener('mousedown', handler);
    window.document.addEventListener('touchstart', handler);

    return () => {
      window.document.removeEventListener('mousedown', handler);
      window.document.removeEventListener('touchstart', handler);
    };
  }, [dropdownRef, rowRefs]);

  const prevState = usePrevious({ curTab });
  React.useEffect(() => {
    if (prevState?.curTab !== curTab) {
      window.sessionStorage.setItem('munch_skill_assessments_cur_tab', curTab);
    }
    setPage(0);
  }, [curTab]);

  // tab move. active <-> archived. change the test sets.
  // 검색과 조건에 따라 평가를 필터링 한다.
  React.useEffect(() => {
    const startIdx = page * rowsPerPage;
    let newTests = new Array();
    let tests_temp = (_tests || []).filter((x) => {
      const assessment_name = x?.name || x.groupTests?.group;
      let flag = false;
      const convertedSearch = (search || '')
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();

      if (
        assessment_name
          .replaceAll(' ', '')
          .trim()
          .toLowerCase()
          .includes(convertedSearch)
      ) {
        flag = true;
      }
      if (flag) {
        return x;
      }
    });

    if (purpose === 'employee-assessment') {
      tests_temp = (tests_temp || []).filter(
        (x) => x.purpose === 'employee-assessment'
      );
    } else if (purpose === 'recruitment-assessment') {
      tests_temp = (tests_temp || []).filter(
        (x) => !x.purpose || x.purpose === 'recruitment-assessment'
      );
    }

    if (curTab === 'all') {
      setTotalTests(tests_temp);

      newTests = tests_temp;
    } else if (curTab === 'active') {
      const filteredTests = (tests_temp || []).filter(
        (test) => !test?.archived
      );
      setTotalTests(filteredTests);

      newTests = filteredTests;
    } else {
      const filteredTests = (tests_temp || []).filter(
        (test) => !!test?.archived
      );
      setTotalTests(filteredTests);

      newTests = filteredTests;
    }

    setTests(newTests);
    if (init) setIsLoading(false);
  }, [_tests, curTab, page, rowsPerPage, search, purpose]);

  React.useEffect(() => {
    const testsCopy = [..._tests];
    if (sorting.createdAt === 'asc') {
      testsCopy.sort(
        (a, b) =>
          new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate())
      );
    } else if (sorting.createdAt === 'desc') {
      testsCopy.sort(
        (a, b) =>
          new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
      );
    }
    _setTests(testsCopy);
  }, [sorting]);

  React.useEffect(() => {
    const isShowVideo = cookies.load('showvideo') || true;
    if (isShowVideo === true) {
      setTimeout(() => {
        displayModal('layout/Tutorial', {});
      }, 1000);
    }
  }, []);

  const onDetailClickHandler = (test) => {
    displayModal(
      'assessments/MyAssessmentsDetail',
      { test },
      {
        frame: 'full',
        hasCloseButton: true,
      }
    );
  };

  const onMoreClickHandler = (idx) => {
    if (resultRef?.current && rowRefs?.[idx]?.current)
      var resultRect = resultRef.current.getBoundingClientRect(),
        elemRect = rowRefs?.[idx].current.getBoundingClientRect(),
        offset = elemRect.top - resultRect.top,
        offsetleft = elemRect.left - resultRect.left;

    resultRef.current.style.setProperty('--dropdown-top', `${offset}px`);
    resultRef.current.style.setProperty('--dropdown-left', `${offsetleft}px`);
    setShowDropdown(idx);
  };

  // 준비 또는 진행중인 응시자를 찾아 상태를 취소로 바꾼다.
  const setCondiationToCanceled = (assessment) => {
    (assessment?.candidates || [])
      .filter((x) => x.condition <= 1)
      .forEach((candidate) => {
        candidate.condition = 3;
        executeAction(
          updateCandidate({
            aid: assessment?.id,
            email: candidate?.email,
            data: { condition: 3 },
          })
        )
          .unwrap()
          .then()
          .catch((e) => {
            console.dir(e);
          });
      });
  };

  const assessmentTabOnClickListener = (cur) => {
    setCurAssessmentTab(cur);
  };

  // 평가를 종료 또는 활성화 할 수 있다.
  // 평가를 종료할 때 응시자 상태가 준비 또는 진행중이면 모두 취소로 변경한다.
  const onClickToggleAssessment = (assessment) => {
    const eventHandler = (event) => {
      if (event.detail?.message !== 'ToggleAssessment') return;
      executeAction(changeStateOfAssessment({ aid: assessment?.id }))
        .unwrap()
        .then(() => {
          setInit(false);
          const _testsIdx = (_tests || []).findIndex((x) =>
            isSameLowerCaseString(x.id, assessment?.id)
          );
          // 평가를 종료할 때 각 응시자 상태를 체크하여 취소로 변경한다.
          if (assessment?.archived === false) {
            setCondiationToCanceled(assessment);
          }
          _setTests([
            ..._tests.slice(0, _testsIdx),
            {
              ...assessment,
              archived: !assessment?.archived,
            },
            ..._tests.slice(_testsIdx + 1, _tests.length),
          ]);
          setShowDropdown(-1);
        })
        .catch((e) => {
          console.dir(e);
          setShowDropdown(-1);
        });
      if (assessment?.archived === false) changePeriodOnOff(assessment);

      window.document.removeEventListener('dismissModal', eventHandler);
    };
    setShowDropdown(-1);
    window.document.addEventListener('dismissModal', eventHandler);
    let title_main, title_sub;
    if (assessment?.archived === false) {
      // 진행중인 평가
      title_main = t('dialog.title.close-assessment');
      title_sub = t('dialog.desc.close-assessment');
    } else {
      // 종료된 평가
      title_main = t('dialog.title.resume-assessment');
      title_sub = t('dialog.desc.resume-assessment');
    }
    displayModal(
      'assessments/ConfirmToggleAssessment',
      {
        event: {
          message: 'ToggleAssessment',
        },
        title: {
          main: title_main,
          sub: title_sub,
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  const changePeriodOnOff = (assessment) => {
    executeAction(
      updatePeriod({
        aid: assessment?.id,
        periodOnOff: false,
        startPeriodDateTime: assessment?.startPeriodDateTime,
        endPeriodDateTime: assessment?.endPeriodDateTime,
      })
    )
      .unwrap()
      .catch((e) => {
        console.dir(e);
      });
  };

  const createOnHandler = () => {
    if (auth?.userInfo?.assessmentLimit <= 0) {
      displayModal(
        'assessments/ConfirmCreateLimit',
        {
          event: {
            message: 'CreateLimit',
          },
          title: {
            main: t('dialog.title.exceeded-assessment'),
            sub: t('dialog.desc.exceeded-assessment'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    } else {
      navigate('/customer/assessments/create');
    }
  };

  const adminNavigateHandler = (id) => {
    if (checkedAdmin) {
      navigate(`/admin/customer/assessments/${id}`, {
        state: {
          checkedAdmin,
        },
      });
    } else {
      navigate(`/customer/assessments/${id}`);
    }
  };

  return (
    <main className="pages-protected-assessments">
      <header className="header">
        <h2 className="header-title fs28">{t('header.menu.my-assessments')}</h2>
        <div className="header-right">
          <div className="header-right-search">
            <MaterialInput
              placeholder={t('text-field.label.search')}
              className="input"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              icon={<SearchIcon />}
            />
          </div>
          <div className="header-right-purpose">
            <MaterialSelect
              className="table-header-right-assessment select"
              title={t('menu.label.assessment-purpose')}
              onChange={(item) => setPurpose(item?.key)}
              value={purpose}
              items={PURPOSE}
            />
          </div>
        </div>
      </header>

      <section ref={resultRef} className="content">
        <ul className="tabs-list">
          {(ASSESSMENT_TABS || []).map((tab, idx) => (
            <li
              className={`tabs-list-item 
                        ${curTab === tab.key && 'selected'}`}
              key={tab.key}
              onClick={() => tab.onClick({ navigate, setCurTab }, () => {})}
            >
              <div
                className={`tabs-list-item-container ${
                  curTab === tab.key && 'selected'
                }`}
                onClick={() => tab.onClick({ navigate, setCurTab }, () => {})}
              >
                <span>{t(tab.i18nKey)}</span>
              </div>
            </li>
          ))}
        </ul>
        {isLoading ? <CircularProgress color="grey" size={25} /> :<ul
          className={`assessments-list ${
            curTab === 'archived' && tests?.length === 0 && 'no'
          }`}
        >
          {(tests || []).map((it, idx) => (
            <li
              className="assessments-list-item"
              ref={rowRefs[idx]}
              key={idx}
              onClick={() => adminNavigateHandler(it?.id)}
              hover="true"
            >
              <article className="compoments-card-assessment">
                <div className="title">
                  <div className="header">
                    {it?.purpose === 'employee-assessment' ? (
                      <div className="purpose employee-assessment fs12">
                        {t('text-field.input.employee-assessment')}
                      </div>
                    ) : (
                      <div className="purpose recruitment-assessment fs12">
                        {t('text-field.input.recruitment-assessment')}
                      </div>
                    )}
                    <div className="more">
                      <div
                        className="more-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onMoreClickHandler(showDropdown === idx ? -1 : idx);
                        }}
                      >
                        <MoreIcon />
                      </div>
                    </div>
                  </div>
                  <div className="name fs18">{it.name}</div>
                </div>
                <div className="candidates">
                  <div className="status">
                    <span className="fs12">{t('progress-status.total')}</span>
                    <span className="fs12">{t('progress-status.ready')}</span>
                    <span className="fs12">
                      {t('progress-status.in-progress')}
                    </span>
                    <span className="fs12">
                      {t('progress-status.complete')}
                    </span>
                  </div>
                  <div className="cnt">
                    <span className="fs18">
                      {
                        // 전체
                        it?.candidates?.length
                      }
                    </span>
                    <span className="fs18">
                      {
                        // 준비 중 : 이메일만 작성하거나 초대만 했을 경우
                        (it?.candidates || []).filter((x) => x.condition === 0)
                          .length
                      }
                    </span>
                    <span className="fs18">
                      {
                        // 진행 중 : 테스트를 진행 중이거나 이탈 했을 경우
                        (it?.candidates || []).filter((x) => x.condition === 1)
                          .length
                      }
                    </span>
                    <span className="fs18">
                      {
                        // 완료 : 테스트를 완료 했을 경우
                        (it?.candidates || []).filter((x) => x.condition === 2)
                          .length
                      }
                    </span>
                  </div>
                </div>
                <div className="skills">
                  <div className="skill fs14">
                    {it?.tests[0]?.title_en ||
                    it?.tests[0]?.category_skill ||
                    it?.groupTests?.group !== undefined
                      ? it?.tests[0]?.title_en ||
                        it?.tests[0]?.category_skill ||
                        it?.groupTests?.group
                      : t('column.question')}
                  </div>
                  {skillList?.[it.id]?.length - 1 > 0 && (
                    <div className="skill fs14">
                      + {skillList?.[it.id]?.length - 1}
                    </div>
                  )}
                </div>
                <div className="current-status">
                  {it?.archived === true ? (
                    <button
                      className="fsbtn16 closed"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        onClickToggleAssessment(it);
                      }}
                    >
                      {t('btn.close-assessment')}
                    </button>
                  ) : it?.periodOnOff &&
                    it?.startPeriodDateTime > new Date().getTime() ? (
                    <button
                      className="fsbtn16 preparing"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClickToggleAssessment(it);
                      }}
                    >
                      {t('btn.preparing-assessment')}
                    </button>
                  ) : (
                    <button
                      className="fsbtn16 inprogress"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClickToggleAssessment(it);
                      }}
                    >
                      {t('btn.open-assessment')}
                    </button>
                  )}
                  <div className="date fs14">
                    {dateToDateFormat(it?.createdAt?.toDate(), t('lang'))}
                  </div>
                </div>
              </article>
            </li>
          ))}
          {curTab !== 'archived' ? (
            <li
              className="assessments-list-item create"
              onClick={() => createOnHandler()}
            >
              <div className="inner">
                <button onClick={() => createOnHandler()}>
                  <PlusIcon />
                </button>
                <span className="fs16">{t('btn.create-new-assessment')}</span>
              </div>
            </li>
          ) : (
            tests?.length === 0 && (
              <li className="no-assessment-list-item">
                <div className="image-box">
                  <PasteSearch />
                </div>
                <h4 className="fs16">{t('desc.empty-closed-assessment')}</h4>
              </li>
            )
          )}
        </ul>}
        {showDropdown > -1 && (
          <div ref={dropdownRef} className="more-dropdown">
            <ul className="more-dropdown-list">
              {(__DROPDOWN_ITEMS__ || []).map((itt) => {
                let isDisplayed = itt.tabs.includes(curTab) || curTab === 'all';
                if (curTab === 'all') {
                  if (itt.key === 'active') {
                    if (tests[showDropdown]?.archived !== true) {
                      isDisplayed = false;
                    }
                  } else if (itt.key === 'archive') {
                    if (tests[showDropdown]?.archived === true) {
                      isDisplayed = false;
                    }
                  }
                }

                if (isDisplayed)
                  return (
                    <li
                      key={itt.key}
                      className={`more-dropdown-list-item ${
                        itt.active ? 'active' : 'inactive'
                      }`}
                      onClick={() =>
                        itt.onClick({
                          idx: showDropdown,
                          tests,
                          _tests,
                          _setTests,
                          setShowDropdown,
                          setIsEntireLoading,
                          setInit,
                          onClickToggleAssessment,
                        })
                      }
                    >
                      {t(itt.i18nKey)}
                    </li>
                  );
              })}
            </ul>
          </div>
        )}
      </section>
    </main>
  );
};

Assessments.propTypes = {};

export default Assessments;
