import React from 'react';

const RecruitmentInput = ({ value, setValue, icon, placeHolder }) => {
  const onChangeInputText = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  return (
    <div className="recruitment-input-component">
      <input
        type="text"
        value={value}
        onChange={onChangeInputText}
        placeholder={placeHolder}
        className="recruitment-input fs14"
      />
      {icon && <i className="icon">{icon}</i>}
    </div>
  );
};

export default RecruitmentInput;
