import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';
import { useModal } from 'utils/modal';
import cookies from 'react-cookies';

const Tutorial = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { dismissModal } = useModal();

  const onDismissHandler = () => {
    dismissModal();
  };

  const onDismissHandlerWithCookie = () => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 10);
    cookies.save('showvideo', false, {
      path: '/',
      expires,
    });
    dismissModal();
  };

  return (
    <article className="modals-tutorial">
      <header className="modal-header">
        <span className="title fs16">{t('title.start-munchskill')}</span>
        <CloseIcon onClick={onDismissHandler} />
      </header>
      <main className="modal-main">
        <span className="title fs18">{t('desc.guide-assessment')}</span>
        <div className="video">
          <iframe
            src="https://player.vimeo.com/video/752410984?h=23085a9b29"
            width="412"
            height="231.12"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
          />
        </div>
        <button className="close-button" onClick={onDismissHandlerWithCookie}>
          {t('btn.hide-pop-up')}
        </button>
      </main>
    </article>
  );
};

export default Tutorial;
