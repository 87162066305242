import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertHtmlToText } from 'utils/string';

const BasicInput = ({
  inputType,
  placeholder,
  label,
  required,
  name,
  icon,
  value,
  onChange,
  disabled,
  notAllow,
  caution,
  defaultText,
  isTransCaution,
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState(inputType);
  const [visibilityPassword, setVisibilityPassword] = useState(false);

  useEffect(() => {
    if (visibilityPassword) {
      setType('text');
    } else {
      setType(inputType);
    }
  }, [visibilityPassword]);

  const toggleVisibilityOfPassword = () => {
    setVisibilityPassword(!visibilityPassword);
  };

  return (
    <div className="basic-input">
      <label className="input-label" htmlFor={name}>
        <span>
          {label || ''} {required && <i>*</i>}
        </span>
        {notAllow && (
          <span className="not-allow">
            {t('toast.desc.please-fill-email-format')}
          </span>
        )}
      </label>
      <div className="input-box">
        <input
          type={type}
          placeholder={placeholder}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          autoComplete={type === 'password' ? 'off' : 'on'}
          className={`input ${inputType} ${icon !== undefined && 'showIcon'} ${
            defaultText !== undefined && 'padding-left'
          }`}
        />
        {defaultText && <div className="default-text fs16">{defaultText}</div>}
        {inputType === 'password' && (
          <div className="password-icon" onClick={toggleVisibilityOfPassword}>
            {icon}
          </div>
        )}
        {inputType === 'text' && icon !== undefined ? (
          <div className="icon">{icon}</div>
        ) : (
          <></>
        )}
      </div>
      {caution &&
        (isTransCaution ? (
          <pre className="input-caution">{caution}</pre>
        ) : (
          <pre
            className="input-caution"
            dangerouslySetInnerHTML={{ __html: convertHtmlToText(caution) }}
          />
        ))}
    </div>
  );
};

export default BasicInput;
