import React, { useEffect } from 'react';

const Sentry = () => {
  useEffect(() =>{
    throw new Error('Sentry Error');
  },[]);
  return (
    <div/>
  );
};

export default Sentry;