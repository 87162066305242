import React from 'react';
import { Outlet } from 'react-router-dom';
import ApplyFooter from 'components/footer/ApplyFooter';
import ApplyHeader from 'components/header/ApplyHeader';

const ApplyLayout = () => (
  <>
    <ApplyHeader />
    <Outlet />
    <ApplyFooter />
  </>
);

export default ApplyLayout;
