import React from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ChangeStatusModal = ({
  open,
  onClickDisagree,
  main,
  sub,
  onClickAgree,
  rejectType,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} className="part-modal" sx={{ justifyContent: 'center' }}>
      <article className="modals-change-status">
        <section className="title">
          <h3 className="title-main fs16">{t(main)}</h3>
          <div className="title-sub fs14">{t(sub)}</div>
        </section>
        <section className="buttons">
          <button
            className="common-button fsbtn16 cancel active"
            onClick={onClickDisagree}
          >
            {t('btn.no')}
          </button>
          <button
            className="common-button fsbtn16 primary active"
            onClick={() => onClickAgree(rejectType)}
          >
            {t('btn.yes')}
          </button>
        </section>
      </article>
    </Modal>
  );
};

export default ChangeStatusModal;
