import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EyeIcon } from 'assets/images/icon/eye.svg';
import { ReactComponent as EmailIcon } from 'assets/images/icon/email.svg';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  setPassword as _setPassword,
  resetPassword as _resetPassword,
  sendValidateMail,
  isAvailableAuthToken,
} from 'store/slices/authSlice';
import MaterialInput from 'components/input/MaterialInput';
import { validateEmail, validatePassword } from 'utils/auth';
import { CircularProgress } from '@mui/material';
import { toast } from 'utils/notify';
import MainLogo from 'components/logo/MainLogo';
import PropTypes from 'prop-types';

const SetPassword = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { account } = useParams();
  const authToken = searchParams.get('authToken');
  const type = searchParams.get('type');

  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [canDone, setCanDone] = React.useState(false);

  React.useEffect(() => {
    if (
      (Boolean(authToken) && type === 'reset') ||
      (Boolean(authToken) && type === 'set')
    ) {
      setIsLoading(true);
      executeAction(isAvailableAuthToken({ authToken }))
        .unwrap()
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          navigate(
            `/${account}/auth/expired?authToken=${authToken}&type=${type}`
          );

          console.dir(e);
        });
    }
  }, [authToken]);

  React.useEffect(() => {
    let flag = true;

    if (authToken) {
      // if (!validatePassword(password)?.success) {
      //   flag = false;
      // }
    } else if (!validateEmail(email)) {
      flag = false;
    }

    setCanDone(flag);
  }, [email, password]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (canDone && !isLoading) onSubmitHandler();
    }
  };

  const onSubmitHandler = () => {
    if (authToken) {
      if (type === 'set') return onSubmitHandlerWithAuthToken();
      if (type === 'reset') return onSubmitHandlerWithAuthTokenReset();
    } else {
      return onSubmitHandlerWithEmail();
    }
  };

  const onSubmitHandlerWithEmail = () => {
    if (!validateEmail(email)) return;

    setIsLoading(true);
    executeAction(sendValidateMail({ email, type: 'reset', account }))
      .unwrap()
      .then((authToken) => {
        setIsLoading(false);
        toast(t('toast.desc.send-email'));
        setEmail('');
      })
      .catch((e) => {
        setIsLoading(false);
        console.dir(e);
        switch (e?.message) {
          case 'no_user':
            toast(t('toast.desc.invalid-email'));
            break;
          default:
            break;
        }
      });
  };

  const onSubmitHandlerWithAuthToken = () => {
    if (!authToken) {
      return false;
    }

    if (password.length < 8) {
      return toast(t('toast.desc.pw-condition'));
    }

    if (!validatePassword(password)?.success) {
      switch (validatePassword(password).payload?.error?.message) {
        case 'no_lower_case':
          return toast(t('toast.desc.no-lower-case'));
        case 'no_upper_case':
          return toast(t('toast.desc.no-upper-case'));
        case 'no_number':
          return toast(t('toast.desc.no-number'));
        case 'no_special_character':
          return toast(t('toast.desc.no-spacial-character'));
        case 'has_no_allowance_special_character':
          return toast(t('toast.desc.no-allowance-character'));
      }
      return;
    }

    setIsLoading(true);
    executeAction(_setPassword({ authToken, password }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        toast(t('toast.desc.set-password-success'));
        navigate(`/${account}/auth/signup/info`, {
          state: {
            authToken,
            account,
          },
        });
      })
      .catch((e) => {
        setIsLoading(false);
        switch (e.message) {
          case 'expired_token':
            toast(t('set-pw-expired.title.set-pw-expired'));
            break;
          default:
            toast(t(e.message));
            break;
        }
        console.dir(e);
      });
  };

  const onSubmitHandlerWithAuthTokenReset = () => {
    if (!authToken) {
      return false;
    }

    if (password.length < 8) {
      return toast(t('toast.desc.pw-condition'));
    }

    if (!validatePassword(password)?.success) {
      switch (validatePassword(password).payload?.error?.message) {
        case 'no_lower_case':
          return toast(t('toast.desc.no-lower-case'));
        case 'no_upper_case':
          return toast(t('toast.desc.no-upper-case'));
        case 'no_number':
          return toast(t('toast.desc.no-number'));
        case 'no_special_character':
          return toast(t('toast.desc.no-spacial-character'));
        case 'has_no_allowance_special_character':
          return toast(t('toast.desc.no-allowance-character'));
      }
      return;
    }

    setIsLoading(true);
    executeAction(_resetPassword({ authToken, password }))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        toast(t('toast.desc.set-password-success'));
        navigate(`/${account}/auth/signin`);
      })
      .catch((e) => {
        setIsLoading(false);
        switch (e.message) {
          case 'expired_token':
            toast(t('reset-pw-expired.title.reset-pw-expired'));
            break;
          default:
            toast(t(e.message));
            break;
        }
        console.dir(e);
      });
  };

  return (
    <main className="pages-auth-set-password">
      <MainLogo className="logo" />
      <section className="explain">
        <h2 className="explain-main fs24">
          {authToken
            ? type === 'set'
              ? t('set-pw.title.set-pw')
              : t('reset-pw.title.reset-pw')
            : t('reset-pw.title.reset-pw')}
        </h2>
      </section>
      <section className="form">
        {authToken ? (
          <>
            <MaterialInput
              className="form-input password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={onKeyPress}
              placeholder={`${t('text-field.label.pw')} *`}
              icon={<EyeIcon />}
            />
            <p className="form-caution fs12">
              {t('text-field.helper.pw-condition')}
            </p>
          </>
        ) : (
          <>
            <MaterialInput
              className="form-input email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={onKeyPress}
              placeholder={`${t('text-field.label.email')} *`}
              icon={<EmailIcon />}
            />
          </>
        )}
        <button
          className={`form-submit common-button fsbtn16 ${
            canDone && !isLoading && 'active'
          } ${isLoading && 'pending'}`}
          onClick={canDone && !isLoading ? onSubmitHandler : null}
        >
          {authToken ? t('btn.set-pw') : t('btn.send-reset-pw-link')}
          {isLoading && <CircularProgress color="grey" size={25} />}
        </button>
      </section>
      {!authToken && (
        <button
          className="go-back text-button fsbtn16 primary"
          onClick={() => navigate(-1)}
        >
          {t('btn.back-login')}
        </button>
      )}
    </main>
  );
};

SetPassword.propTypes = {};

export default SetPassword;
