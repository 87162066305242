import React from 'react';
import { ReactComponent as ChevronBottomIcon } from 'assets/images/icon/chevron-bottom-dropdown.svg';
import { useNavigate, useParams, Outlet, useLocation } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  getAssessmentOfCurrentUser,
  changeStateOfAssessment,
  createAssessment,
  getCandidatesOfAssessments,
  getAssessmentOfEvaluatorUser,
  getEvaluatorsOfAssessments,
  updatePeriod,
} from 'store/slices/assessmentsSlice';
import { useAuth } from 'utils/auth';
import { useStatus } from 'utils/status';
import { useTranslation } from 'react-i18next';
import { convertTextToSec } from 'utils/string';
import { useOnClickOutside, useCheckedAdminPage } from 'utils/hooks';
import { useModal } from 'utils/modal';
import { dateFormat } from 'utils/dateFormat';

const __GROUP_TEST_TIME__ = 10;

const __TABS__ = [
  {
    key: 'content',
    i18nKey: 'tab.assessment-result',
    onClick: ({ navigate, aid }) => {
      navigate(`/customer/assessments/${aid}`);
    },
  },
  {
    key: 'styling',
    i18nKey: 'menu.label.result-styling',
    onClick: ({ navigate, aid }) => {
      navigate(`/customer/assessments/${aid}/styling`);
    },
  },
  {
    key: 'invite',
    i18nKey: 'tab.invite-candidates',
    onClick: ({ navigate, aid }) => {
      navigate(`/customer/assessments/${aid}/invite`);
    },
  },
  {
    key: 'edit',
    i18nKey: 'tab.edit-email',
    onClick: ({ navigate, aid }) => {
      navigate(`/customer/assessments/${aid}/edit`);
    },
  },
  {
    key: 'configure',
    i18nKey: 'tab.configuration-assessment',
    onClick: ({ navigate, aid }) => {
      navigate(`/customer/assessments/${aid}/configure`);
    },
  },
];

const __ACTIONS__ = [
  {
    key: 'edit',
    i18nKey: 'actions.menu.edit',
    display: ['active', 'archived'],
    onClick: ({
      setShowDropdown,
      assessment,
      setIsEntireLoading,
      setInit,
      navigate,
    }) => {
      setIsEntireLoading(true);
      executeAction(
        getCandidatesOfAssessments({
          assessments: [assessment],
        })
      )
        .unwrap()
        .then((doc) => {
          let candidates = doc?.candidates?.[0].length || 0;
          if (candidates >= 1) {
            // 수정 불가 메시지  나중에 문구 정해지면 수정하기
            toast(t('toast.desc.sent-email'));
          } else {
            navigate(`/customer/assessments/create?aid=${assessment?.id}`);
          }
          setShowDropdown(false);
          setIsEntireLoading(false);
        })
        .catch((e) => {
          console.dir(e);
          setShowDropdown(false);
          setIsEntireLoading(false);
        });

      navigate(`/customer/assessments/create?aid=${assessment?.id}`);
      setShowDropdown(false);
    },
  },
  {
    key: 'clone',
    i18nKey: 'actions.menu.clone',
    display: ['active', 'archived'],
    onClick: ({ setShowDropdown, assessment, setIsEntireLoading, setInit }) => {
      setIsEntireLoading(true);
      executeAction(
        createAssessment({
          name: assessment?.name || '',
          position: assessment?.position || {},
          tests: assessment?.tests || [],
          groupTests: assessment?.groupTests || [],
          objectiveTests: assessment?.objectiveTests || [],
          subjectiveTests: assessment?.subjectiveTests || [],
          AntiCheating: assessment?.AntiCheating || false,
          language: assessment?.language || 'ko',
        })
      )
        .unwrap()
        .then(() => {
          setShowDropdown(false);
          setIsEntireLoading(false);
          setInit(false);
        })
        .catch((e) => {
          console.dir(e);
          setShowDropdown(false);
          setIsEntireLoading(false);
        });
    },
  },
  {
    key: 'manage-access',
    i18nKey: 'actions.menu.manage-access',
    display: ['active', 'archived'],
    onClick: ({ setShowDropdown, assessment, setIsEntireLoading }) => {
      setShowDropdown(false);
    },
  },
  {
    key: 'set-period',
    i18nKey: 'actions.menu.assessment-period',
    display: ['active'],
    onClick: ({ setPeriodClickHandler }) => {
      setPeriodClickHandler();
    },
  },
  {
    key: 'archive',
    i18nKey: 'actions.menu.archive',
    display: ['active'],
    onClick: ({ setShowDropdown, assessment, setIsEntireLoading, setInit }) => {
      setIsEntireLoading(true);
      executeAction(changeStateOfAssessment({ aid: assessment?.id }))
        .unwrap()
        .then(() => {
          setIsEntireLoading(false);
          setShowDropdown(false);
          setInit(false);
        })
        .catch((e) => {
          console.dir(e);
          setIsEntireLoading(false);
          setShowDropdown(false);
        });

      executeAction(
        updatePeriod({
          aid: assessment?.id,
          periodOnOff: false,
          startPeriodDateTime: assessment?.startPeriodDateTime,
          endPeriodDateTime: assessment?.endPeriodDateTime,
        })
      )
        .unwrap()
        .catch((e) => {
          console.dir(e);
        });
    },
  },
  {
    key: 'active',
    i18nKey: 'actions.menu.active',
    display: ['archived'],
    onClick: ({ setShowDropdown, assessment, setIsEntireLoading, setInit }) => {
      setIsEntireLoading(true);
      executeAction(changeStateOfAssessment({ aid: assessment?.id }))
        .unwrap()
        .then(() => {
          setIsEntireLoading(false);
          setShowDropdown(false);
          setInit(false);
        })
        .catch((e) => {
          console.dir(e);
          setIsEntireLoading(false);
          setShowDropdown(false);
        });
    },
  },
];

const Detail = () => {
  const { t } = useTranslation();
  const { aid } = useParams();
  const [doc, setDoc] = React.useState({});
  const [init, setInit] = React.useState(false);
  const [showActionsDropdown, setShowActionsDropdown] = React.useState(false);
  const [curTab, setCurTab] = React.useState(null);
  const { setIsEntireLoading } = useStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const actionsDropdownRef = React.useRef(null);
  const [candidatesCnt, setCandidatesCnt] = React.useState(0);
  const [candidatesArr, setCandidatesArr] = React.useState([]);
  const [evaluatorsArr, setEvaluatorsArr] = React.useState([]);
  const [toggleStiker, setToggleStiker] = React.useState('');
  const [hasStyling, setHasStyling] = React.useState(false);
  const { userInfo } = useAuth();
  const { displayModal } = useModal();
  const [refreshing, setRefreshing] = React.useState(false);
  const checkedAdmin = useCheckedAdminPage();
  const [startPeriod, setStartPeriod] = React.useState();
  const [period, setPeriod] = React.useState();
  const [endPeriod, setEndPeriod] = React.useState();
  useOnClickOutside(actionsDropdownRef, () => setShowActionsDropdown(false));

  // assessment doc data load.
  React.useEffect(() => {
    if (init || !userInfo) return;
    setIsEntireLoading(true);
    executeAction(
      getAssessmentOfCurrentUser({
        aid,
      })
    )
      .unwrap()
      .then((result) => {
        // TODO: empty docs.
        if (!result?.doc) throw new Error();
        setDoc(result?.doc);
        const styling = result?.doc?.tests?.find((x) => x.id === '990001');
        if (styling) {
          setHasStyling(true);
        }
        executeAction(
          getCandidatesOfAssessments({
            assessments: [result?.doc],
          })
        )
          .unwrap()
          .then((doc) => {
            let candidates = doc?.candidates?.[0];
            let candidatesCnt = candidates.length || 0;
            setCandidatesArr(candidates);
            setCandidatesCnt(candidatesCnt);
          })
          .catch((e) => {
            console.dir(e);
          });

        setIsEntireLoading(false);
        setInit(true);
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });
  }, [init, aid, userInfo]);

  React.useEffect(() => {
    if (doc?.archived === undefined) return;
    setStartPeriod(doc?.startPeriodDateTime);
    setEndPeriod(doc?.endPeriodDateTime);
    setPeriod(doc?.periodOnOff);

    if (!doc?.archived) {
      setToggleStiker('active');
    } else {
      setToggleStiker('close');
    }
  }, [doc]);

  React.useEffect(() => {
    if (!userInfo) return;

    executeAction(getAssessmentOfEvaluatorUser({ aid }))
      .unwrap()
      .then((result) => {
        if (!result?.doc) throw new Error();
        executeAction(
          getEvaluatorsOfAssessments({
            assessments: [result?.doc],
          })
        )
          .unwrap()
          .then((doc) => {
            let evaluators = doc?.evaluators?.[0];
            setEvaluatorsArr(evaluators);
          });
      });
  }, [aid, refreshing, userInfo]);

  // set cur tab
  React.useEffect(() => {
    const keys = __TABS__.map((tab) => tab.key);
    const pathArr = (location?.pathname || '').split('/');
    setCurTab(
      (keys || []).filter((it) => {
        if (it === 'content') {
          return (
            !pathArr.includes('invite') &&
            !pathArr.includes('edit') &&
            !pathArr.includes('configure') &&
            !pathArr.includes('styling')
          );
        }
        return pathArr.includes(it);
      })?.[0]
    );
  }, [location?.pathname]);

  const getDuration = () => {
    let duration = 0;
    (doc?.objectiveTests || []).forEach((test) => {
      if (test?.limit) {
        duration += test?.limit;
      } else {
        duration += 15;
      }
    });
    if (doc?.groupTests?.testcnt > 0) {
      duration = +__GROUP_TEST_TIME__ * doc?.groupTests?.testcnt;
    }
    (doc?.subjectiveTests || []).forEach((test) => {
      const sec = convertTextToSec(test.limit);
      duration += sec / 60;
    });
    return duration;
  };

  const onActionsClickHandler = () => {
    displayModal(
      'assessments/InviteEvaluator',
      {
        userInfo,
        assessmentTitle: doc?.name,
        list: evaluatorsArr || [],
        aid,
        setLoading: setRefreshing,
        candidates: candidatesArr || [],
        assessment: doc,
      },
      {
        frame: 'full',
        padding: '40px',
      }
    );
  };

  const setPeriodClickHandler = () => {
    displayModal(
      'assessments/edit/PeriodDateTime',
      {
        aid: doc?.id,
        period,
        endPeriod,
        startPeriod,
        setPeriod,
        setStartPeriod,
        setEndPeriod,
      },
      {
        frame: 'part',
      }
    );
  };

  /** =================
     * @function toggleChangeState : assessment 보관 / 진행중 toggle 함수
    ===================== */
  const toggleChangeState = () => {
    if (toggleStiker === '') return;

    if (toggleStiker === 'active') {
      const toggleHandler = (event) => {
        if (event.detail?.message === 'close-assessment') {
          executeAction(
            updatePeriod({
              aid,
              periodOnOff: false,
              startPeriodDateTime: startPeriod,
              endPeriodDateTime: endPeriod,
            })
          )
            .unwrap()
            .catch((e) => {
              console.dir(e);
            });
          executeAction(changeStateOfAssessment({ aid }))
            .unwrap()
            .then(() => {
              setIsEntireLoading(false);
              setInit(false);
              setToggleStiker('close');
              window.document.removeEventListener(
                'dismissModal',
                toggleHandler
              );
            })
            .catch((e) => {
              console.dir(e);
              setIsEntireLoading(false);
              window.document.removeEventListener(
                'dismissModal',
                toggleHandler
              );
            });
        }
      };
      window.document.addEventListener('dismissModal', toggleHandler);
      displayModal(
        'common/AlertModal',
        {
          event: {
            message: 'close-assessment',
          },
          title: {
            main: t('dialog.title.close-assessment'),
            sub: t('dialog.desc.close-assessment'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
      return;
    }
    if (toggleStiker === 'close') {
      const toggleHandler = (event) => {
        if (event.detail?.message === 'active-assessment') {
          executeAction(changeStateOfAssessment({ aid }))
            .unwrap()
            .then(() => {
              setIsEntireLoading(false);
              setInit(false);
              setToggleStiker('active');
              window.document.removeEventListener(
                'dismissModal',
                toggleHandler
              );
            })
            .catch((e) => {
              console.dir(e);
              setIsEntireLoading(false);
              window.document.removeEventListener(
                'dismissModal',
                toggleHandler
              );
            });
        }
      };
      window.document.addEventListener('dismissModal', toggleHandler);
      displayModal(
        'common/AlertModal',
        {
          event: {
            message: 'active-assessment',
          },
          title: {
            main: t('dialog.title.resume-assessment'),
            sub: t('dialog.desc.resume-assessment'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
      return;
    }
  };

  const onClickBackButtonHandler = () => {
    if (checkedAdmin) {
      navigate('/admin/customer/assessments');
    } else {
      navigate('/customer/assessments');
    }
  };

  return (
    <main className="pages-protected-assessments-detail">
      <header className="header">
        <div className="header-right">
          <button
            className="header-go-back common-button fsbtn16 white active"
            onClick={onClickBackButtonHandler}
          />
          <section className="header-info">
            <div className="header-info-title">
              <h2 className="title fs28">{doc?.name}</h2>
              <div
                className={`stiker fs12 ${toggleStiker} ${
                  !doc?.archived &&
                  period &&
                  startPeriod > new Date().getTime() &&
                  'preparing'
                } ${
                  doc?.archived ||
                  (period && endPeriod < new Date().getTime() && 'close')
                }`}
                onClick={toggleChangeState}
              >
                {toggleStiker === 'active'
                  ? period && startPeriod > new Date().getTime()
                    ? t('btn.preparing-assessment')
                    : t(
                        doc?.archived ||
                          (period && endPeriod < new Date().getTime())
                          ? 'btn.close-assessment'
                          : 'btn.open-assessment'
                      )
                  : t('btn.close-assessment')}
                <i>
                  <ChevronBottomIcon />
                </i>
              </div>
            </div>
            <div className="header-info-description">
              <span className="header-info-description-count">
                {`${doc?.tests?.length} ${t('column.test')}`}
              </span>
              <span className="header-info-description-duration">
                {`${getDuration()} ${t('desc.minute')}`}
              </span>
              <span className="header-info-description-language">
                {t(`menu.input.lang-${doc?.language}`)}
              </span>
              {period && (
                <span className="header-info-description-timer">
                  {t('title.assessment-period')}:{dateFormat(startPeriod)} ~
                  {dateFormat(endPeriod)}
                </span>
              )}
            </div>
          </section>
        </div>

        <section ref={actionsDropdownRef} className="header-more">
          <button
            className="header-more-toggle common-button fsbtn16 active"
            onClick={() => setShowActionsDropdown(!showActionsDropdown)}
          >
            {t('btn.add-actions')}
            <div className="more-icon" />
          </button>
          <div className="header-more-dropdown">
            {showActionsDropdown && (
              <ul className="header-more-dropdown-list">
                {(__ACTIONS__ || []).map((action, idx) => {
                  if (
                    action.display.includes(
                      doc?.archived ? 'archived' : 'active'
                    )
                  ) {
                    if (idx === 0 && candidatesCnt > 0) {
                      return (
                        <li
                          key={idx}
                          className="header-more-dropdown-list-item-inactive"
                        >
                          {t(action?.i18nKey)}
                        </li>
                      );
                    }
                    return (
                      <li
                        key={idx}
                        className="header-more-dropdown-list-item"
                        onClick={() =>
                          action.onClick({
                            setShowDropdown: setShowActionsDropdown,
                            assessment: doc,
                            setIsEntireLoading,
                            setInit,
                            navigate,
                            setPeriodClickHandler,
                          })
                        }
                      >
                        {t(action?.i18nKey)}
                      </li>
                    );
                  }
                })}
              </ul>
            )}
          </div>
        </section>
      </header>
      <nav className="tabs">
        <ul className="tabs-list">
          {(__TABS__ || []).map((tab, idx) => {
            if (tab.key === 'styling') {
              if (hasStyling === true) {
                return (
                  <li
                    key={idx}
                    className={`tabs-list-item ${tab?.key} ${
                      curTab === tab?.key && 'selected'
                    }`}
                    onClick={() => tab.onClick({ navigate, aid })}
                  >
                    <div className="tabs-list-item-container">
                      <span className="fs16">{t(tab?.i18nKey)}</span>
                    </div>
                  </li>
                );
              }
            } else {
              return (
                <li
                  key={idx}
                  className={`tabs-list-item ${tab?.key} ${
                    curTab === tab?.key && 'selected'
                  }`}
                  onClick={() => tab.onClick({ navigate, aid })}
                >
                  <div className="tabs-list-item-container">
                    <span className="fs16">{t(tab?.i18nKey)}</span>
                  </div>
                </li>
              );
            }
          })}
          <div className="indicator" />
        </ul>
      </nav>
      <Outlet context={[aid]} />
    </main>
  );
};

export default Detail;
