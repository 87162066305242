import { BarGraphScore } from 'components/recruitment/detail/virtualInterview/toggle/ToggleBodyHeader';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MultipleChoiceTableBody = ({
  objectiveTests,
  objectiveCats,
  objectiveScores,
}) => {
  const { t } = useTranslation();
  const [filteredTests, setFilteredTests] = useState([]);

  useEffect(() => {
    const filter = objectiveTests.filter((item) => {
      if (item.uuid === Object.keys(objectiveScores)[0]) {
        return item;
      }
    });

    setFilteredTests(filter);
  }, [objectiveTests, objectiveScores]);

  const getScoreObjective = (test) => [
    objectiveScores?.[test?.uuid]?.[0]?.toFixed?.(2) || 0,
    objectiveScores?.[test?.uuid]?.[1] || 0,
    objectiveScores?.[test?.uuid]?.[2] || 0,
  ];

  return (
    <tbody>
      {(filteredTests || []).map((test, idx) => (
        <React.Fragment key={`test${idx}`}>
          <tr>
            <td className="bold">
              {test?.[`title_${t('en')}`] || test?.title}
            </td>
            <td>
              <BarGraphScore
                score={`${Number(getScoreObjective(test)?.[0])}`}
                barColor={'#3294ee'}
              />
            </td>
          </tr>
          {(objectiveCats[test?.uuid] || []).map((cat, _idx) => (
            <tr key={`cat${_idx}`}>
              <td className="cat_title">{cat?.category}</td>
              <td>
                <BarGraphScore
                  score={`${Number(
                    (cat?.val?.correct / cat?.val?.cnt).toFixed?.(2)
                  )}`}
                  barColor={'#7cb9f0'}
                />
              </td>
            </tr>
          ))}
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default MultipleChoiceTableBody;
