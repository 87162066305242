import React from 'react';
import { covertJobPosition } from 'utils/convertKey';
import { nameConversionLanguage } from 'utils/converter';
import { dateToDateFormat } from 'utils/string';

const EmployPositionTable = ({ candidate, step, t }) => {
  const { startDate } = candidate?.progressSubmit[step];
  const { companyAddr, job } = candidate?.progressSubmit[step - 1];

  return (
    <table>
      <caption>Join Date, Name, Location, Position</caption>
      <thead>
        <tr>
          <th>{t('title.join-date')}</th>
          <th>{t('title.name')}</th>
          <th>{t('title.location')}</th>
          <th>{t('title.position')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{dateToDateFormat(new Date(startDate), t('lang'))}</td>
          <td>
            {nameConversionLanguage(
              candidate?.firstName,
              candidate?.lastName,
              t('lang')
            )}
          </td>
          <td>{companyAddr && t(covertJobPosition(companyAddr))}</td>
          <td>{job && job}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default EmployPositionTable;
