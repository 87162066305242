import React from 'react';
import { Button } from 'components/common/Button';
import { useModal } from 'utils/modal';
import { ReactComponent as PlusIcon } from 'assets/images/icon/plus.svg';

const MemberInvitedButton = ({ menuItems, userInfo }) => {
  const { displayModal } = useModal();

  const onInviteModalHandler = () => {
    displayModal(
      'profile/InviteMember',
      {
        userInfo,
        menuItems,
      },
      { frame: 'full' }
    );
  };

  return (
    <aside className="members-invited">
      <Button
        label="Invite Members"
        size={40}
        theme="blue"
        onClick={onInviteModalHandler}
        frontIcon={<PlusIcon />}
      />
    </aside>
  );
};

export default MemberInvitedButton;
