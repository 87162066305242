import React from 'react';
import { convertHtmlToText } from 'utils/string';

const CustomBarChart = ({ score, title }) => {
  return (
    <div className="bar-chart-box">
      <span className="number">
        {score || '0'}
        <i>%</i>
      </span>
      <div
        className="bar-chart-graph"
        style={{ width: score ? `${score}%` : '1%' }}
      >
        <div className="score-graph" />
      </div>
      {title && <pre className="chart-title">{convertHtmlToText(title)}</pre>}
    </div>
  );
};

export default CustomBarChart;
