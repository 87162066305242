import React, { useState, useEffect } from 'react';
import VideoResult from 'components/assessment/testResult/VideoResult';
import CustomProgressChart from 'components/assessment/chart/CustomProgressChart';
import EssayResult from 'components/assessment/testResult/EssayResult';
import { ReactComponent as ArrowBottom } from 'assets/images/icon/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { useAverageScore } from 'utils/hooks';
import { convertHtmlToText } from 'utils/string';
import CustomMultipleResult from './CustomMultipleResult';
import FileUploadResult from './FileUploadResult';
import FeedbackScoreComponent from './FeedbackScoreComponent';

const CustomTestResultToggleContainerPrint = ({
  type,
  test,
  candidate,
  assessment,
  setRefreshing,
  getAnswer,
}) => {
  const { t } = useTranslation();
  const lang = t('lang');

  const [visibilityDropdown, setVisibilityDropdown] = useState(
    type === 'print'
  );
  const [dropdownAnimation, setDropdownAnimation] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [averageScore, setAverageScore] = useState(0);

  useEffect(() => {
    if (visibilityDropdown) {
      clearTimeout(repeat);
      setDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setDropdownAnimation(false), 150));
    }
  }, [visibilityDropdown]);

  useEffect(() => {
    if (Object.keys(candidate)?.length === 0 || Object.keys(test)?.length === 0)
      return;

    if (!candidate?.results) {
      return setAverageScore([0]);
    }

    const { results } = candidate;
    const { uuid } = test;
    const scoreArr = [];

    if (test.section === 'multiple-choice') {
      if (test?.answers.correct[0] !== results[test?.uuid]?.checks[0]) {
        scoreArr.push(0);
      } else {
        scoreArr.push(1);
      }
    } else {
      scoreArr.push(
        results[uuid]?.score === undefined || results[uuid]?.score === null
          ? results[uuid]?.preset_score
          : results[uuid]?.score
      );
    }

    const filterScore = scoreArr.filter((item) => item);

    const average = useAverageScore(filterScore);
    setAverageScore(average);
  }, [candidate, test]);

  const onClickToggleButton = () => {
    setVisibilityDropdown(!visibilityDropdown);
  };

  const splitText = (text) => {
    const split = text?.split(':');

    return split[1] || split[0];
  };

  const getSectionComponentBySectionName = (question, idx) => {
    const { section } = question;

    if (section === 'video') {
      return (
        <VideoResult
          type={type}
          visibilityDropdown={visibilityDropdown}
          question={question}
          assessment={assessment}
          candidate={candidate}
          test={test}
          idx={idx}
          setRefreshing={setRefreshing}
          getAnswer={getAnswer}
        />
      );
    }

    if (section === 'essay') {
      return (
        <EssayResult
          type={type}
          visibilityDropdown={visibilityDropdown}
          question={question}
          assessment={assessment}
          candidate={candidate}
          test={test}
          idx={idx}
          setRefreshing={setRefreshing}
        />
      );
    }

    if (section === 'multiple-choice') {
      return (
        <CustomMultipleResult
          type={type}
          visibilityDropdown={visibilityDropdown}
          question={question}
          assessment={assessment}
          candidate={candidate}
          test={test}
          idx={idx}
          setRefreshing={setRefreshing}
        />
      );
    }

    if (section === 'file-upload') {
      return (
        <FileUploadResult
          type={type}
          visibilityDropdown={visibilityDropdown}
          question={question}
          assessment={assessment}
          candidate={candidate}
          test={test}
          idx={idx}
          setRefreshing={setRefreshing}
        />
      );
    }
  };

  return (
    <>
      <aside className={`test-toggle-box ${type === 'print' ? 'print' : ''}`}>
        <div className="toggle-header page-break">
          <div
            className={`toggle-header-title ${
              type === 'print' ? 'disabled' : ''
            }`}
            onClick={type !== 'print' ? onClickToggleButton : null}
          >
            <h4 className="results-sub-title">
              {convertHtmlToText(test.question)}
            </h4>
            <div
              className={`toggle-button ${
                visibilityDropdown ? 'active' : 'disabled'
              }`}
            >
              <ArrowBottom />
            </div>
          </div>
          <div className="toggle-header-score-box page-break">
            <div className="toggle-header-score">
              <CustomProgressChart
                score={Math.trunc(averageScore * 100) || 0}
              />
            </div>
            {/* {(test?.section === 'video' || test?.section === 'essay') && (
              <div className="toggle-header-feedback page-break">
                {Object.keys(candidate)?.length !== 0 && candidate?.results ? (
                  <div className="feedback-container">
                    {candidate?.results[test?.uuid]?.total_feedback ? (
                      (
                        Object.keys(
                          candidate?.results[test?.uuid]?.total_feedback[lang]
                        ) || []
                      )?.map((feedback, idx) => (
                        <FeedbackScoreComponent
                          key={idx}
                          objKey={test?.uuid}
                          candidate={candidate}
                          feedback={feedback}
                          splitText={splitText}
                        />
                      ))
                    ) : (
                      <p className="toggle-header-feedback-desc">
                        {t('desc.wating-feedback')}
                      </p>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )} */}
          </div>
        </div>

        {dropdownAnimation &&
          (candidate?.results ? (
            <div className="toggle-body-wrapper">
              {/* 하나의 테스트 안에 문제 유형에 따라 다른 결과화면을 보여줌 */}
              {getSectionComponentBySectionName(test, 0)}
            </div>
          ) : (
            <h4 className="results-semi-sub-title">
              {t('desc.not-yet-assessment')}
            </h4>
          ))}
      </aside>
    </>
  );
};

export default CustomTestResultToggleContainerPrint;
