import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';


const OutlookRouter = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_POST_BOX}/outlook/token`,
      { code },
      {
        headers: {
          'Content-Type': 'application/json',
          'Publish-Type': process.env.REACT_APP_NODE_ENV,
        },
      })
      .then((response) => {
        const currentDate = new Date();

        const expirationDate = new Date(currentDate);
        expirationDate.setDate(currentDate.getDate() + 70);

        const tokenData = {
          outlook_refreshToken: response.data.refresh_token,
          expirationDate: expirationDate.getTime()
        };

        localStorage.setItem('tokenData', JSON.stringify(tokenData));

        const rid = sessionStorage.getItem('rid');
        const processName = sessionStorage.getItem('processName');

        return navigate(`/customer/recruitments/${rid}/${processName}`,{
          state: { outlook: true },
          replace: true,
        });
      });


  }, []);

  return <main />;
};

export default OutlookRouter;
