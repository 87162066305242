import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { ReactComponent as TriangleIcon } from 'assets/images/icon/triangle-icon.svg';
import { useModal } from 'utils/modal';
import { useTranslation } from 'react-i18next';
import { convertHtmlForDisplay } from 'utils/string';
import { executeAction } from 'utils/redux';
import { getCandidateInAssessment } from 'store/slices/candidatesSlice';
import {
  gradeResult,
  presetGradeResult,
  stylingGradeResult,
} from 'store/slices/assessmentsSlice';
import InputEditor from 'components/codeEditor/InputEditor';
import { TextareaAutosize } from '@mui/material';
import { EstimateScore } from 'components/progress/Score';
import EstimateList from 'components/library/EstimateList';
import EstimateAvarage from 'components/library/EstimateAvarage';
import HtmlEditor from 'components/frontCodeEditor/HtmlEditor';
import CssEditor from 'components/frontCodeEditor/CssEditor';
import JavascriptEditor from 'components/frontCodeEditor/JavascriptEditor';

const Library = ({
  aid,
  cid,
  test,
  candidate: _candidate,
  setRefreshing,
  event: { message },
  checkedAdmin,
}) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();
  const [isLoading, setIsLoading] = React.useState(false);
  const [candidate, setCandidate] = React.useState(null);
  const [imageAnswer, setImageAnswer] = React.useState(null);
  const [score, setScore] = React.useState({});
  const [originalityScore, setOriginalityScore] = React.useState({});
  const [communicationScore, setCommunicationScore] = React.useState({});
  const [fashionScore, setFashionScore] = React.useState({});
  const [fitScore, setFitScore] = React.useState({});
  const [comment, setComment] = React.useState({});
  const [questions, setQuestions] = React.useState([]);
  const [cansubmit, setCanSubmit] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [frontActiveIndex, setFrontActiveIndex] = React.useState(0);
  const [testStore, setTestStore] = React.useState([]);
  const [iFrameKey, setIFrameKey] = React.useState(0);
  const [AIScore, setAIScore] = React.useState({});
  const [SttEdit, setSttEdit] = React.useState({});

  const iFrameRef = React.useRef(null);

  const __TAB_LIST__ = [
    {
      id: 0,
      value: 'HTML',
    },
    {
      id: 1,
      value: 'CSS',
    },
    {
      id: 2,
      value: 'JavaScript',
    },
  ];

  const __TAB_CONTENTS__ = [
    {
      id: 0,
      key: 'html',
    },
    {
      id: 1,
      key: 'css',
    },
    {
      id: 2,
      key: 'javascript',
    },
  ];

  const tabClickHandler = (index) => {
    setActiveIndex(index);
  };

  React.useEffect(() => {
    if (!aid || !cid) return;

    executeAction(
      getCandidateInAssessment({
        aid,
        cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        const testId = new Array();
        setTestStore(doc);
        test?.test.map((t, i) => {
          if (doc.results[t]?.img !== undefined) {
            testId.push(doc?.results[t].img);
          } else {
            return;
          }
        });

        setImageAnswer(testId);
      })
      .catch((e) => {
        console.dir(e);
      });
  }, [aid, cid]);

  React.useEffect(() => {
    if (!_candidate) return;

    setCandidate(_candidate);
  }, [_candidate]);

  React.useEffect(() => {
    const questionlist = new Array();
    const _score = {};
    const _presetScore = {};
    const _originalityScore = {};
    const _fashionScore = {};
    const _fitScore = {};
    const _communicationScore = {};
    const _comment = {};
    const _text = {};
    (test?.question || []).forEach((question) => {
      if (question.selected === true) {
        questionlist.push(question);
        _score[question.id] = candidate?.results?.[question.id]?.score || 0;
        _text[question.id] = candidate?.results?.[question.id]?.text || '';
        _presetScore[question.id] =
          candidate?.results?.[question.id]?.preset_score;
        _originalityScore[question.id] =
          candidate?.results?.[question.id]?.originalityScore || 0;
        _fashionScore[question.id] =
          candidate?.results?.[question.id]?.fashionScore || 0;
        _fitScore[question.id] =
          candidate?.results?.[question.id]?.fitScore || 0;
        _communicationScore[question.id] =
          candidate?.results?.[question.id]?.communicationScore || 0;
        _comment[question.id] =
          candidate?.results?.[question.id]?.comment || '';
      }
    });

    setQuestions(questionlist);
    setScore(_score);
    setAIScore(_presetScore);
    setSttEdit(_text);
    setOriginalityScore(_originalityScore);
    setFashionScore(_fashionScore);
    setFitScore(_fitScore);
    setCommunicationScore(_communicationScore);
    setComment(_comment);
    checkCanSubmit(_score);
  }, [test, candidate]);

  const onDismissHandler = () => {
    dismissModal({
      message,
    });
  };

  const onSetCurrentPresetScore = (e, tid) => {
    let _presetScore = AIScore;
    _presetScore[tid] = Number(e.target.value) / 100;
    setAIScore((_presetScore) => ({ ...AIScore, _presetScore }));
  };

  const onSetCurrentStt = (e, tid) => {
    let _sttEdit = SttEdit;
    _sttEdit[tid] = e.target.value;
    setSttEdit((_sttEdit) => ({ ...SttEdit, _sttEdit }));
  };

  const onSetCurrentScore = (tid, _score) => {
    let __score = score;
    __score[tid] = _score;

    setScore((__score) => ({ ...score, __score }));
    checkCanSubmit(__score);
    setComment((__comment) => ({ ...comment, __comment }));
    getCurrentLibraryAverageScore();
    getCurrentLibraryAveragePresetScore();
  };

  const onSetOriginalityScore = (tid, _score) => {
    let __originalityScore = originalityScore;
    __originalityScore[tid] = _score;

    setOriginalityScore((__originalityScore) => ({
      ...originalityScore,
      __originalityScore,
    }));
  };

  const onSetFashionScore = (tid, _score) => {
    let __fashionScore = fashionScore;
    __fashionScore[tid] = _score;

    setFashionScore((__fashionScore) => ({ ...fashionScore, __fashionScore }));
  };

  const onSetFitScore = (tid, _score) => {
    let __fitScore = fitScore;
    __fitScore[tid] = _score;

    setFitScore((__fitScore) => ({ ...fitScore, __fitScore }));
  };
  const onSetCommunicationScore = (tid, _score) => {
    let __communicationScore = communicationScore;
    __communicationScore[tid] = _score;

    setCommunicationScore((__communicationScore) => ({
      ...communicationScore,
      __communicationScore,
    }));
  };

  const checkCanSubmit = (_score) => {
    let submit = false;
    (questions || []).forEach((question) => {
      if (_score[question.id] !== undefined && _score[question.id] >= 0) {
        submit = true;
      } else {
        submit = false;
      }
    });
    setCanSubmit((submit) => ({ ...cansubmit, submit }));
  };

  const onChangeCurrentComment = (tid, _comment) => {
    const __comment = comment;
    __comment[tid] = _comment;
    setComment((__comment) => ({ ...comment, __comment }));
  };

  const onSubmitHandler = () => {
    setIsLoading(true);
    const tasks = new Array();
    (questions || []).forEach((question) => {
      if (checkedAdmin) {
        tasks.push(
          executeAction(
            presetGradeResult({
              aid,
              cid,
              tuuid: question?.id,
              preset_score: AIScore[question?.id],
              text: SttEdit[question?.id],
            })
          )
            .unwrap()
            .then(() => {})
            .catch((e) => {
              console.dir(e);
            })
        );
      } else if (question?.section !== 'styling') {
        tasks.push(
          executeAction(
            gradeResult({
              aid,
              cid,
              tuuid: question?.id,
              score: score[question.id],
              comment: comment[question.id],
            })
          )
            .unwrap()
            .then(() => {})
            .catch((e) => {
              console.dir(e);
            })
        );

        if (question?.id === '99000100001') {
          tasks.push(
            executeAction(
              stylingGradeResult({
                aid,
                cid,
                tuuid: question?.id,
                originalityScore: originalityScore[question.id],
                fashionScore: fashionScore[question.id],
                fitScore: fitScore[question.id],
                communicationScore: communicationScore[question.id],
                score: score[question.id],
                comment: comment[question.id],
              })
            )
              .unwrap()
              .then(() => {})
              .catch((e) => {
                console.dir(e);
              })
          );
        }
      } else {
        tasks.push(
          executeAction(
            stylingGradeResult({
              aid,
              cid,
              tuuid: question?.id,
              originalityScore: originalityScore[question.id],
              fashionScore: fashionScore[question.id],
              fitScore: fitScore[question.id],
              score: score[question.id],
              comment: comment[question.id],
            })
          )
            .unwrap()
            .then(() => {})
            .catch((e) => {
              console.dir(e);
            })
        );
      }
    });

    Promise.allSettled(tasks)
      .then(() => {
        setIsLoading(false);
        setRefreshing(true);
        dismissModal();
      })
      .catch((e) => {
        console.dir(e);
        setIsLoading(false);
      });
    window.document.querySelector('body').style.position = 'relative';
  };

  const getCurrentLibraryAverageScore = (questions) => {
    let testcnt = 0;
    let _score = [];

    questions?.forEach((tmp) => {
      if (tmp?.section === 'styling') {
        let result = 0;
        result += originalityScore?.[tmp.id];
        result += fashionScore?.[tmp.id];
        result += fitScore?.[tmp.id];

        _score.push(Number((result / 3).toFixed(2)) || 0);
        testcnt += 1;
      } else if (tmp?.id === '99000100001') {
        let result = 0;
        result += communicationScore?.[tmp.id];
        result += fashionScore?.[tmp.id];
        result += fitScore?.[tmp.id];

        _score.push(Number((result / 3).toFixed(2)) || 0);
        testcnt += 1;
      } else {
        let result = 0;
        result += score?.[tmp.id];

        _score.push(result || 0);
        testcnt += 1;
      }
    });

    if (_score.length >= 2) {
      return testcnt !== 0
        ? _score.reduce((a, b) => a + b, 0) / _score.length
        : 0;
    }
    return testcnt !== 0 ? _score.reduce((a, b) => a + b, 0) / testcnt : 0;
  };

  const getCurrentLibraryAveragePresetScore = (question) => {
    let testcnt = 0;
    let presetScore = 0;

    question?.forEach((tmp) => {
      if (tmp?.id !== '99000100001') {
        const result = candidate?.results?.[tmp.id];

        if (result?.preset_score === undefined || result?.preset_score <= 0)
          return;

        if (result) {
          presetScore += result?.preset_score || 0;
          testcnt += 1;
        }
      } else {
        presetScore = 0;
      }
    });

    return testcnt !== 0 ? presetScore / testcnt : 0;
  };

  const getCurrentStylingAverageScore = (question) => {
    let _score = 0;
    let originalityRresult;

    if (question.id === '99000100001') {
      originalityRresult = communicationScore?.[question.id];
    } else {
      originalityRresult = originalityScore?.[question.id];
    }

    const fashionResult = fashionScore?.[question.id];
    const fitResult = fitScore?.[question.id];

    _score += originalityRresult + fashionResult + fitResult || 0;

    return _score !== 0 ? (_score / 3).toFixed(2) : 0;
  };

  const frontTabClickHandler = (index) => {
    setFrontActiveIndex(index);
  };

  /** ==========================
     * @function convertComponents : __TAB_CONTENTS__의 key값에 따라 다른 에디터(컴포넌트)를 호출하는 함수
     * @param {*} content(object) : id값과 key값을 가지고 있는 객체
     * @returns 
     ============================= */
  const convertComponents = (content, htmlCode, cssCode, javascriptCode) => {
    switch (content?.key) {
      case 'html':
        return <HtmlEditor htmlCode={htmlCode} />;
      case 'css':
        return <CssEditor cssCode={cssCode} />;
      case 'javascript':
        return <JavascriptEditor javascriptCode={javascriptCode} />;
    }
  };

  const postMessageCode = (htmlCode, cssCode, javascriptCode) => {
    if (!iFrameRef.current) return;

    setIFrameKey((prevKey) => prevKey + 1);

    setTimeout(() => {
      iFrameRef.current.contentWindow.postMessage(
        { htmlCode, cssCode, javascriptCode },
        '*'
      );
    }, 3000);
  };

  return (
    <article className="modals-assessments-view-answer-library">
      <header className="header">
        <h3 className="header-title fs20">{t('btn.view-answer')}</h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <div className="title-box">
        <div className="title fs28">{test?.category_skill}</div>
        <div className="left">
          <div
            className={`score fs16 ${getCurrentLibraryAverageScore(questions)}`}
          >
            <p className="fs16">{t('title.avarage-score')}</p>
            <div className="container">
              {/* {isStyling && 
                                    isStyling ? */}
              <div className="number-scoring">
                <span className="number fs16">
                  {Math.trunc(getCurrentLibraryAverageScore(questions) * 100)}
                </span>
                <span className="fs16">%</span>
              </div>
            </div>
          </div>
          <button
            className={`submit common-button fsbtn16 ${
              checkedAdmin
                ? 'active'
                : candidate?.progress === 0 ||
                  getCurrentLibraryAverageScore(questions) < 0.001
                ? 'disabled'
                : 'active'
            }`}
            onClick={!isLoading ? onSubmitHandler : null}
            disabled={
              checkedAdmin
                ? false
                : !!(
                    candidate?.progress === 0 ||
                    getCurrentLibraryAverageScore(questions) < 0.001
                  )
            }
          >
            {t('btn.save')}
          </button>
        </div>
      </div>
      <nav className="question-tab-nav">
        <ul>
          {(questions || []).map(
            (question, idx) =>
              (question?.section === 'code' ||
                question?.section === 'front-code') && (
                <li
                  key={idx}
                  onClick={() => tabClickHandler(idx)}
                  className={`${activeIndex === idx ? 'active' : ''} fs18`}
                >
                  {`Q${idx + 1}`}
                </li>
              )
          )}
        </ul>
      </nav>
      <section className="contents">
        {(questions || []).map((question, idx) =>
          question?.section !== 'code' ? (
            question?.section !== 'front-code' ? (
              <React.Fragment key={idx}>
                <hr style={{ display: idx > 0 ? 'block' : 'none' }} />
                <div className="content">
                  <div className="left">
                    <div className="question-detail">
                      <div className="question-number fs24">
                        {`Q${idx + 1}`}
                      </div>
                      <div className="question">
                        <pre
                          className="fs16"
                          dangerouslySetInnerHTML={{
                            __html: convertHtmlForDisplay(question?.question),
                          }}
                        />
                      </div>
                    </div>
                    <div className="answer-detail">
                      <div className="title f18">{t('title.answer')}</div>
                      {/* video, styling 답변 */}
                      {question?.section && question?.section !== 'video' ? (
                        // styling 답변
                        question?.section !== 'styling' ? (
                          <div className="answer fs16">
                            <pre
                              className="fs16"
                              dangerouslySetInnerHTML={{
                                __html: convertHtmlForDisplay(
                                  candidate?.results?.[question?.id]?.text
                                ),
                              }}
                            />
                          </div>
                        ) : (
                          <div className="answer-description image">
                            <img
                              className="image"
                              alt=""
                              src={`${imageAnswer}`}
                            />
                          </div>
                        )
                      ) : (
                        <div className="answer-description video">
                          <video
                            className="video-question"
                            src={testStore.results?.[question?.id]?.video}
                            controls
                            playsInline
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="right">
                    {question?.section && question?.section === 'styling' ? (
                      <section className="estimate">
                        <h4 className="estimate-title fs18">
                          {t('title.rate-answer')}
                        </h4>
                        <EstimateList
                          score={fashionScore?.[question.id]}
                          onClick={onSetFashionScore}
                          questionId={question?.id}
                          title="패션에 대한 이해"
                        />
                        <EstimateList
                          score={fitScore?.[question.id]}
                          onClick={onSetFitScore}
                          questionId={question?.id}
                          title="Gucci와의 fit"
                        />
                        <EstimateList
                          score={originalityScore?.[question.id]}
                          onClick={onSetOriginalityScore}
                          questionId={question?.id}
                          title="스타일의 독창성"
                        />
                        <div className="divide" />
                        <EstimateAvarage
                          averageScore={getCurrentStylingAverageScore(question)}
                          title="평균 점수(자동 계산)"
                        />
                        <div className="estimate-comment">
                          <TextareaAutosize
                            placeholder={t('title.comment')}
                            onChange={(e) =>
                              onChangeCurrentComment(
                                question.id,
                                e.target.value
                              )
                            }
                            defaultValue={comment?.[question.id]}
                            className="fs16"
                          />
                        </div>
                      </section>
                    ) : question?.id === '99000100001' ? (
                      <section className="estimate">
                        <h4 className="estimate-title fs18">
                          {t('title.rate-answer')}
                        </h4>
                        <EstimateList
                          score={fashionScore?.[question.id]}
                          onClick={onSetFashionScore}
                          questionId={question?.id}
                          title="패션에 대한 이해"
                        />
                        <EstimateList
                          score={fitScore?.[question.id]}
                          onClick={onSetFitScore}
                          questionId={question?.id}
                          title="Gucci와의 fit"
                        />
                        <EstimateList
                          score={communicationScore?.[question.id]}
                          onClick={onSetCommunicationScore}
                          questionId={question?.id}
                          title="Communication"
                        />
                        <div className="divide" />
                        <EstimateAvarage
                          averageScore={getCurrentStylingAverageScore(question)}
                          title="평균 점수(자동 계산)"
                        />
                        <div className="estimate-comment">
                          <TextareaAutosize
                            placeholder={t('title.comment')}
                            onChange={(e) =>
                              onChangeCurrentComment(
                                question.id,
                                e.target.value
                              )
                            }
                            defaultValue={comment?.[question.id]}
                            className="fs16"
                          />
                        </div>
                      </section>
                    ) : (
                      <section className="estimate">
                        <h4 className="estimate-title fs18">
                          {t('title.rate-answer')}
                        </h4>
                        <div className="estimate-score-flex">
                          <div className="estimate-score">
                            <EstimateScore
                              score={score?.[question?.id]}
                              onClick={onSetCurrentScore}
                              questionId={question?.id}
                              presetScore={
                                question?.id !== '99101000006'
                                  ? AIScore?.[question?.id]
                                  : 0
                              }
                            />
                          </div>
                          <div className="estimate-score-number">
                            <div className="user-score">
                              <span className="score-title">
                                {t('column.score')}:
                              </span>
                              {question.section !== 'essay' ? (
                                score && score?.[question?.id] ? (
                                  <span className="score-number">
                                    {score?.[question?.id] * 100}
                                  </span>
                                ) : (
                                  <span className="score-number preset">
                                    {AIScore?.[question?.id] === undefined
                                      ? t('desc.in-progress')
                                      : AIScore?.[question?.id] <= 0
                                      ? t('desc.need-grading')
                                      : Math.floor(
                                          AIScore?.[question?.id] * 100
                                        )}
                                  </span>
                                )
                              ) : score && score?.[question?.id] ? (
                                <span className="score-number">
                                  {score?.[question?.id] * 100}
                                </span>
                              ) : (
                                <span className="score-number preset">
                                  {AIScore?.[question?.id] === undefined
                                    ? t('desc.in-progress')
                                    : AIScore?.[question?.id] <= 0 ||
                                      question?.id === '99101000006'
                                    ? t('desc.need-grading')
                                    : Math.floor(AIScore?.[question?.id] * 100)}
                                </span>
                              )}
                              {AIScore?.[question?.id] === undefined ||
                                (AIScore?.[question?.id] > 0 && <i>%</i>)}
                            </div>
                          </div>
                        </div>
                        <div className="estimate-comment">
                          <TextareaAutosize
                            placeholder={t('title.comment')}
                            onChange={(e) =>
                              onChangeCurrentComment(
                                question.id,
                                e.target.value
                              )
                            }
                            defaultValue={comment?.[question.id]}
                            className="fs16"
                          />
                        </div>
                        {/* /admin 경로로 들어왔을 시 AIScore / text 수정 */}
                        {checkedAdmin && (
                          <div className="admin-preset-text-edit">
                            <div className="preset-score">
                              <h4 className="preset-score-title fs18">
                                AI 채점
                              </h4>
                              <input
                                className="preset-score-input fs16"
                                value={Math.trunc(
                                  AIScore?.[question?.id] * 100
                                )}
                                onChange={(e) =>
                                  onSetCurrentPresetScore(e, question?.id)
                                }
                                placeholder="AI 점수 수정"
                              />
                            </div>
                            <div className="edit-text">
                              <h4 className="edit-text-title fs18">
                                Text 수정
                              </h4>
                              <textarea
                                className="edit-text-textarea fs16"
                                value={SttEdit?.[question?.id]}
                                onChange={(e) =>
                                  onSetCurrentStt(e, question?.id)
                                }
                                placeholder="스피치 투 텍스트 수정"
                              />
                            </div>
                          </div>
                        )}
                      </section>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              activeIndex === idx && (
                <React.Fragment key={idx}>
                  <div className="front-code-content">
                    <div className="left">
                      <div className="question-detail">
                        <div className="question">
                          <pre
                            className="fs16"
                            dangerouslySetInnerHTML={{
                              __html: convertHtmlForDisplay(question?.question),
                            }}
                          />
                        </div>
                      </div>

                      <section className="estimate">
                        <h4 className="estimate-title fs18">
                          {t('title.rate-answer')}
                        </h4>
                        <div className="estimate-score">
                          <EstimateScore
                            score={score?.[question?.id]}
                            onClick={onSetCurrentScore}
                            questionId={question?.id}
                            presetScore="none"
                          />
                        </div>
                        <div className="estimate-comment">
                          <TextareaAutosize
                            placeholder={t('title.comment')}
                            onChange={(e) =>
                              onChangeCurrentComment(
                                question.id,
                                e.target.value
                              )
                            }
                            defaultValue={comment?.[question.id]}
                            className="fs16"
                          />
                        </div>
                      </section>
                    </div>
                    <div className="right">
                      <div className="answer-result-detail">
                        <nav className="tab">
                          <ul>
                            {(__TAB_LIST__ || []).map((tab, idx) => (
                              <li
                                key={idx}
                                className={`fs16 ${
                                  frontActiveIndex === idx ? 'active' : ''
                                }`}
                                onClick={() => frontTabClickHandler(idx)}
                              >
                                {tab?.value}
                              </li>
                            ))}
                          </ul>
                        </nav>
                        <div className="answer-result-input">
                          {(__TAB_CONTENTS__ || []).map(
                            (content, idx) =>
                              frontActiveIndex === idx && (
                                <React.Fragment key={idx}>
                                  {convertComponents(
                                    content,
                                    candidate?.results?.[question?.id]
                                      ?.htmlCode,
                                    candidate?.results?.[question?.id]?.cssCode,
                                    candidate?.results?.[question?.id]
                                      ?.javascriptCode
                                  )}
                                </React.Fragment>
                              )
                          )}
                        </div>
                        <div className="answer-result-title">
                          <h6 className="fs16">{t('code.result-title')}</h6>
                          <button
                            className="common-button fsbtn16 primary active"
                            onClick={() =>
                              postMessageCode(
                                candidate?.results?.[question?.id]?.htmlCode,
                                candidate?.results?.[question?.id]?.cssCode,
                                candidate?.results?.[question?.id]
                                  ?.javascriptCode
                              )
                            }
                          >
                            {t('btn.code-run')}
                            <TriangleIcon />
                          </button>
                        </div>
                        <div className="answer-result-content">
                          <iframe
                            ref={iFrameRef}
                            key={iFrameKey}
                            id="iframe-preview"
                            name="front preview"
                            sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
                            src="/front-iframe"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            )
          ) : (
            activeIndex === idx && (
              <>
                <div className="code-content">
                  <div className="left">
                    <div className="question-detail">
                      <div className="question">
                        <pre className="fs16">{question?.question}</pre>
                      </div>
                    </div>
                    <div
                      className="guid-detail"
                      style={{
                        display:
                          question?.answer_guide?.length > 0 ? 'flex' : 'none',
                      }}
                    >
                      <div className="title fs18">{t('code.model-answer')}</div>
                      <div className="guid fs16">
                        <InputEditor
                          code={question?.answer_guide}
                          id={
                            test?.codeLanguage !== undefined
                              ? test?.codeLanguage
                              : candidate?.results[question?.id]?.codeLanguage
                          }
                          isLeadonly
                          isLine
                        />
                      </div>
                    </div>
                    <section className="estimate">
                      <h4 className="estimate-title fs18">
                        {t('title.rate-answer')}
                      </h4>
                      <div className="estimate-score">
                        <EstimateScore
                          score={score?.[question?.id]}
                          onClick={onSetCurrentScore}
                          questionId={question?.id}
                          presetScore="none"
                        />
                      </div>
                      <div className="estimate-comment">
                        <TextareaAutosize
                          placeholder={t('title.comment')}
                          onChange={(e) =>
                            onChangeCurrentComment(question.id, e.target.value)
                          }
                          defaultValue={comment?.[question.id]}
                          className="fs16"
                        />
                      </div>
                    </section>
                  </div>
                  <div className="right">
                    <div className="answer-result-detail">
                      <div className="answer-result-input">
                        <InputEditor
                          code={candidate?.results?.[question?.id].sourceCode}
                          id={
                            test?.codeLanguage !== undefined
                              ? test?.codeLanguage
                              : candidate?.results[question?.id]?.codeLanguage
                          }
                          isLeadonly
                          isLine
                        />
                      </div>
                      <div className="answer-result-title">
                        <h6 className="fs16">{t('code.result-title')}</h6>
                      </div>
                      <div className="answer-result-content">
                        <pre className="fs16">
                          {candidate?.results?.[question?.id].result}
                        </pre>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          )
        )}
      </section>
    </article>
  );
};

export default Library;
