import { convertGender, convertCareer } from './convertKey';

export const recruitmentSearchFilterHandler = (list, path, value, ...arg) => {
  const processLength = Number(arg);
  const filtered = (list || []).filter((x) => {
    const fullName1 = (x.firstName || '') + (x.lastName || '');
    const fullName2 = (x.lastName || '') + (x.firstName || '');

    let flag = false;

    const convertedSearch = (value || '')
      .replaceAll(' ', '')
      .trim()
      .toLowerCase();

    if (
      fullName1
        .replaceAll(' ', '')
        .trim()
        .toLowerCase()
        .includes(convertedSearch)
    ) {
      flag = true;
    }

    if (
      fullName2
        .replaceAll(' ', '')
        .trim()
        .toLowerCase()
        .includes(convertedSearch)
    ) {
      flag = true;
    }

    if (!path) {
      const { phoneNumber, birth, gender, career } = x?.progressSubmit[1];

      if (birth.replaceAll('-', '').trim().includes(convertedSearch)) {
        flag = true;
      }

      if (phoneNumber.includes(convertedSearch)) {
        flag = true;
      }
      if (convertGender(gender).toLowerCase().includes(convertedSearch)) {
        flag = true;
      }
      if (convertCareer(career).replaceAll(' ', '').includes(convertedSearch)) {
        flag = true;
      }
    } else if (path === 'virtual-interview') {
      const { condition } = x;

      let name;

      switch (condition) {
        case 0:
          name = 'Invited';
          break;
        case 1:
          name = 'In-progress';
          break;
        case 2:
          name = 'Completed';
          break;
        default:
          name = 'Invited';
          break;
      }

      if (
        name.replaceAll('-', '').trim().toLowerCase().includes(convertedSearch)
      ) {
        flag = true;
      }
    } else if (path === 'first-interview') {
      const { interviewer, opinion } = x.progressSubmit[3];
      const { firstName, lastName } = interviewer;

      const interviewer1 = (firstName || '') + (lastName || '');
      const interviewer2 = (lastName || '') + (firstName || '');

      if (
        interviewer1
          .replaceAll(' ', '')
          .trim()
          .toLowerCase()
          .includes(convertedSearch)
      ) {
        flag = true;
      }

      if (
        interviewer2
          .replaceAll(' ', '')
          .trim()
          .toLowerCase()
          .includes(convertedSearch)
      ) {
        flag = true;
      }

      let opinionName;

      if (!opinion) {
        opinionName = 'waiting';
      } else {
        opinionName = opinion;
      }

      if (opinionName.toLowerCase().includes(convertedSearch)) {
        flag = true;
      }
    } else if (path === 'second-interview') {
      const { interviewer, opinion } = x.progressSubmit[4];
      const { firstName, lastName } = interviewer;

      const interviewer1 = (firstName || '') + (lastName || '');
      const interviewer2 = (lastName || '') + (firstName || '');

      if (
        interviewer1
          .replaceAll(' ', '')
          .trim()
          .toLowerCase()
          .includes(convertedSearch)
      ) {
        flag = true;
      }

      if (
        interviewer2
          .replaceAll(' ', '')
          .trim()
          .toLowerCase()
          .includes(convertedSearch)
      ) {
        flag = true;
      }

      let opinionName;

      if (!opinion) {
        opinionName = 'waiting';
      } else {
        opinionName = opinion;
      }

      if (opinionName.toLowerCase().includes(convertedSearch)) {
        flag = true;
      }
    } else if (path === 'evidence') {
      const step = processLength === 7 ? 4 : 5;
      const { state } = x?.progressSubmit[step];

      if (state.includes(convertedSearch)) {
        flag = true;
      }
    } else if (path === 'pass') {
      const step = processLength === 7 ? 5 : 6;

      const status = x?.progressSubmit[step]?.status
        ? x?.progressSubmit[step]?.status
        : '';
      let name;

      switch (status) {
        case 'extended':
          name = 'Offer extended';
          break;
        case 'accept':
          name = 'Offer accepted';
          break;
        case 'reject':
          name = 'Offer declined';
          break;
        default:
          name = '';
          break;
      }

      if (
        name.replaceAll(' ', '').trim().toLowerCase().includes(convertedSearch)
      ) {
        flag = true;
      }
    } else if (path === 'submission') {
      const step = processLength === 7 ? 6 : 7;
    } else if (path === 'reject') {
      const { finalHiringStep } = x;
      const { channel } = x.progressSubmit[1];

      let title;

      if (processLength === 7) {
        switch (finalHiringStep) {
          case 1:
            title = 'Reviewing Application';
            break;
          case 2:
            title = 'Digital Interview';
            break;
          case 3:
            title = 'In-person Interview';
            break;
          case 4:
            title = 'Compensation Document';
            break;
          case 5:
            title = 'Offer Letter';
            break;
          default:
            title = '';
            break;
        }
      } else if (processLength === 8) {
        switch (finalHiringStep) {
          case 1:
            title = 'Reviewing Application';
            break;
          case 2:
            title = 'Digital Interview';
            break;
          case 3:
          case 4:
            title = 'In-person Interview';
            break;
          case 5:
            title = 'Compensation Document';
            break;
          case 6:
            title = 'Offer Letter';
            break;
          default:
            title = '';
            break;
        }
      }

      if (
        title
          .replaceAll(' ', '')
          .replaceAll('-', '')
          .trim()
          .toLowerCase()
          .includes(convertedSearch)
      ) {
        flag = true;
      }

      if (channel.toLowerCase().includes(convertedSearch)) {
        flag = true;
      }
    }

    if (flag) {
      return x;
    }
  });

  return filtered;
};
