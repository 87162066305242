import { Box, Skeleton } from '@mui/material';
import React from 'react';

const DashboardListSkeleton = () => {
  return (
    <li>
      <Box sx={{ width: '200px' }}>
        <Skeleton animation="wave" sx={{ height: 40 }} />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Skeleton animation="wave" sx={{ height: 50 }} />
      </Box>
    </li>
  );
};

export default DashboardListSkeleton;
