import React, { useState, useEffect } from 'react';
import { useModal } from 'utils/modal';
import RadioContainer from 'components/modal/recruitments/position/radio/RadioContainer';
import CheckboxContainer from 'components/modal/recruitments/position/checkbox/CheckboxContainer';
import DatePickerContainer from 'components/modal/recruitments/position/datepicker/DatePickerContainer';
import { executeAction } from 'utils/redux';
import {
  createRecruitment,
  getRecruitments,
} from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';
import RecruitmentInputText from 'components/input/RecruitmentInputText';
import { ReactComponent as Minus } from 'assets/images/icon/minus.svg';
import { useTranslation } from 'react-i18next';
import { recruitmentProcess } from '../../constants/recruitments/position';
import { checkValidationPeriod, setInitialPeriod } from '../../utils/period';
import ApplyAddButton from '../../components/apply/ApplyAddButton';
import { Button } from '../../components/common/Button';

const CreateRecruitment = ({ userInfo, assessments }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();

  const [recruitmentName, setRecruitmentName] = useState('');
  const [firstPositionDepth, setFirstPositionDepth] = useState('');
  const [secondPositionDepth, setSecondPositionDepth] = useState('');
  const [thirdPositionDepth, setThirdPositionDepth] = useState('');
  const [selectedAsssessment, setSelectedAssessment] = useState({});
  const [process, setProcess] = useState(recruitmentProcess);
  const [isSchedule, setIsSchedule] = useState(false);
  const [standardList, setStandardList] = React.useState([]);
  const [startPeriodSchedule, setStartPeriodSchedule] = React.useState();
  const [endPeriodSchedule, setEndPeriodSchedule] = React.useState();
  const [interviewStandard, setInterviewStandard] = React.useState('');
  const [interviewStandardDesc, setInterviewStandardDesc] = React.useState('');
  const [standardRender, setStandardRender] = useState(0);
  const addAndReturnModifiedArray = (arr, valueToAdd) => {
    arr.push(valueToAdd);
    return arr;
  };

  const removeElementFromArray = (arr, element) => {
    const index = arr.indexOf(element);
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const onCreateStandardList = () => {
    const newArr = addAndReturnModifiedArray(standardList, {
      standard: interviewStandard,
      standardDesc: interviewStandardDesc,
    });

    setStandardList(newArr);
    setInterviewStandard('');
    setInterviewStandardDesc('');
  };

  const onRemoveStandard = (idx) => {
    const newArr = removeElementFromArray(standardList, standardList[idx]);
    const newStandardRender = standardRender + 1;
    setStandardRender(newStandardRender);
    setStandardList(newArr);
  };

  useEffect(() => {
    setInitialPeriod(isSchedule, setStartPeriodSchedule, setEndPeriodSchedule);
  }, [isSchedule]);

  const onCreateRecruitmentHandler = () => {
    if(!recruitmentName) {
      toast(t('toast.desc.enter-name-items'));
      return;
    }
    if(!firstPositionDepth || !secondPositionDepth || !thirdPositionDepth) {
      toast(t('toast.desc.enter-position-items'));
      return;
    }
    if(Object.keys(selectedAsssessment).length === 0) {
      toast(t('toast.desc.enter-selected-assess-items'));
      return;
    }
    if(standardList.length === 0) {
      toast(t('toast.desc.enter-standard-items'));
      return;
    }

    const periodValidate = checkValidationPeriod(
      isSchedule,
      startPeriodSchedule,
      endPeriodSchedule
    );

    if (periodValidate === 'none period') toast('none period');
    if (periodValidate === 'end period error') toast('end period error');
    if (periodValidate === 'success') {
      executeAction(
        createRecruitment({
          recruitmentName,
          isRecruitment: true,
          positionInfo: {
            job: [firstPositionDepth],
            career: [secondPositionDepth],
            position: [thirdPositionDepth],
          },
          processSetting: {
            ...process,
            reject: {
              isUsed: true,
              label: t('btn.reject'),
            },
          },
          ...selectedAsssessment,
          standardList,
          isSchedule,
          startPeriodSchedule: isSchedule ? startPeriodSchedule : 0,
          endPeriodSchedule: isSchedule ? endPeriodSchedule : 0,
          isRecruitmentArchived: false,
        })
      )
        .unwrap()
        .then(() => {
          toast(t('toast.desc.successed-recruitment'));
          executeAction(getRecruitments({ memberType: userInfo?.memberType }));
          dismissModal();
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  return (
    <article className="modal-recruitments-create">
      <header className="header">
        <h2 className="header-title fs28">{t('btn.open-new-position')}</h2>
        <div className="header-close" onClick={() => dismissModal()} />
      </header>
      <section className="container">
        {/* 포지션 신규 생성 */}
        <article className="wrapper title-wrapper">
          <aside className="left">
            <div className="title-box">
              <pre>{t('title.recruitment-position-title')}</pre>
            </div>
          </aside>
          <aside className="right">
            <RecruitmentInputText
              type="text"
              placeholder={`${t(
                'title.recruitment-position-title'
              )} (Ex.Client Advisor)`}
              value={recruitmentName}
              onChange={setRecruitmentName}
            />
          </aside>
        </article>
        {/* 포지션 정보 */}
        <article className="wrapper information-wrapper">
          <aside className="left">
            <div className="title-box">
              <pre>{t('title.position-information')}</pre>
            </div>
          </aside>
          <aside className="right">
            <RadioContainer
              type="create"
              firstPositionDepth={firstPositionDepth}
              setFirstPositionDepth={setFirstPositionDepth}
              secondPositionDepth={secondPositionDepth}
              setSecondPositionDepth={setSecondPositionDepth}
              thirdPositionDepth={thirdPositionDepth}
              setThirdPositionDepth={setThirdPositionDepth}
            />
          </aside>
        </article>
        {/* 프로세스 설정 */}
        <article className="wrapper process-wrapper">
          <aside className="left">
            <div className="title-box">
              <pre>{t('title.process-setting')}</pre>
            </div>
          </aside>
          <aside className="right">
            <CheckboxContainer
              assessments={assessments}
              selectedAsssessment={selectedAsssessment}
              setSelectedAssessment={setSelectedAssessment}
              process={process}
              setProcess={setProcess}
            />
            <hr />
          </aside>
        </article>
        {/* 평가 기준 작성 */}
        <article className="wrapper standard-wrapper">
          <aside className="left">
            <div className="title-box">
              <pre>{t('title.evaluation-critera')}</pre>
            </div>
          </aside>
          <aside className="right">
            <div className="input-standard">
              <RecruitmentInputText
                type="text"
                placeholder={t('text-field.placeholder.evaluation-criteria')}
                value={interviewStandard}
                onChange={setInterviewStandard}
              />
              <RecruitmentInputText
                type="text"
                placeholder={t(
                  'text-field.placeholder.evaluation-criteria-detail'
                )}
                value={interviewStandardDesc}
                onChange={setInterviewStandardDesc}
              />
              <ApplyAddButton onClick={onCreateStandardList} />
            </div>
            <div className="standard-example">
              <div>{t('text-field.notify.project-knowledge')}</div>
              <div>{t('text-field.notify.ability-to-learn')}</div>
            </div>
            {standardList?.map((standard, idx) => {
              return (
                <div className="input-standard" key={idx}>
                  <div className="standard-box">{standard.standard}</div>
                  <div className="standard-box">{standard.standardDesc}</div>
                  <div>
                    <Button
                      theme="borderGrey"
                      frontIcon={<Minus />}
                      size="40"
                      onClick={() => onRemoveStandard(idx)}
                    />
                  </div>
                </div>
              );
            })}
          </aside>
        </article>
        {/* 스케쥴 설정 */}
        <article className="wrapper schedule-wrapper">
          <aside className="left">
            <div className="title-box">
              <pre>{t('title.set-schedule')}</pre>
            </div>
          </aside>
          <aside className="right">
            <DatePickerContainer
              isSchedule={isSchedule}
              setIsSchedule={setIsSchedule}
              startPeriodSchedule={startPeriodSchedule}
              setStartPeriodSchedule={setStartPeriodSchedule}
              endPeriodSchedule={endPeriodSchedule}
              setEndPeriodSchedule={setEndPeriodSchedule}
            />
          </aside>
        </article>
        <article className="wrapper button-box">
          <button
            className="confirm-button"
            onClick={onCreateRecruitmentHandler}
          >
            {t('btn.recruitment-confirm')}
          </button>
        </article>
      </section>
    </article>
  );
};

export default CreateRecruitment;
