import CryptoJS from 'crypto-js';
import DOMPurify from 'dompurify';

export const isSameLowerCaseString = (str1, str2) => {
  try {
    return str1.toLowerCase() === str2.toLowerCase();
  } catch (e) {
    return false;
  }
};

export const convertNoGap = (str) => {
  try {
    return str.replace(/ /g, '');
  } catch (e) {
    return false;
  }
};

export const convertHtmlToText = (htmlString) => {
  try {
    const regexp = /<[^>]*>/g;
    return replaceHtmlEntites(htmlString.replace(regexp, ''));
  } catch (e) {
    return htmlString;
  }
};

var replaceHtmlEntites = (function () {
  var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  var translate = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
  };
  return function (s) {
    return s.replace(translate_re, (match, entity) => translate[entity]);
  };
})();

export const convertTextToSec = (_text) => {
  try {
    let mod = 0;
    const charRegexp = /[^a-zA-Z]/g;
    const numRegexp = /[^0-9]/g;

    switch (_text.replace(charRegexp, '')) {
      case 'hour':
      case 'hours':
        mod = 60 * 60;
        break;
      case 'min':
      case 'mins':
        mod = 60;
        break;
      case 'sec':
      case 'secs':
        mod = 1;
        break;
    }

    return Number(_text.replace(numRegexp, '')) * mod;
  } catch (e) {
    return _text;
  }
};

export const writeTextToClipboard = (text) =>
  new Promise(async (resolve, reject) => {
    await window.navigator.clipboard.writeText(text).then(
      () => resolve(true),
      () => reject(false)
    );
  });

export const dateToDateFormat = (date, lang = 'ko') => {
  if (!date) return '';
  const dayForLang = {
    en: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    ko: ['일', '월', '화', '수', '목', '금', '토'],
  };
  const day = dayForLang[lang];
  var ret;
  try {
    ret = `${date.getFullYear()}-${
      date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()} (${
      day[date.getDay()]
    })`;
  } catch (e) {
    ret = date.toDateString();
  }

  return ret;
};

export const formatDateWithTime = (date) => {
  if (!date) return '';

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const dayOfMonth = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${dayOfMonth}. ${hour}:${minute}`;
};

export const convertHtmlForDisplay = (str) => DOMPurify.sanitize(str);

export const displayName = (firstname, lastname, lang = 'ko') => {
  if (firstname === undefined || lastname === undefined) {
    return '';
  }
  if (lang === 'ko') {
    return `${lastname} ${firstname}`;
  }
  return `${firstname} ${lastname}`;
};

const __AES_KEY__ = 'munchskill';

export const encryptAES = (text) =>
  CryptoJS.AES.encrypt(text, __AES_KEY__).toString();

export const decryptAES = (encrypted) => {
  const bytes = CryptoJS.AES.decrypt(encrypted, __AES_KEY__);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const convertSkillTypeToI18nKey = (skillType) => {
  switch (skillType) {
    case 'video':
      return 'tag.video';
    case 'essay':
      return 'tag.essay';
    case 'multiple-choice':
      return 'tag.multiple-choice';
    case 'file-upload':
      return 'tag.file-upload';
    case 'code':
      return 'tag.code';
    default:
      return skillType;
  }
};
