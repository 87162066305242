import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { getRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import Apply from './Apply';

const ApplyRouter = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const rid = searchParams.get('recruitment');
  const ouid = searchParams.get('owner');

  const [recruitment, setRecruitment] = useState({});
  const [redirectFlag, setRedirectFlag] = useState(false);

  useEffect(() => {
    if (!rid || !ouid) {
      return navigate('/apply/invalid');
    }

    executeAction(
      getRecruitment({
        ouid,
        aid: rid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setRecruitment(doc);

        if (doc.isSchedule) {
          if (
            doc.endPeriodSchedule < new Date().getTime() ||
            doc.startPeriodSchedule > new Date().getTime()
          ) {
            return navigate('/apply/expired');
          }
        } else if (doc?.isRecruitmentArchived) {
          return navigate('/apply/expired');
        }
        setRedirectFlag(true);
      })
      .catch((e) => {
        console.error(e);
        setRedirectFlag(false);
      });
  }, [rid, ouid]);

  if (redirectFlag) {
    return <Apply recruitment={recruitment} rid={rid} ouid={ouid} />;
  }
};

export default ApplyRouter;
