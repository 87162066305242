import React from 'react';
import { useTranslation } from 'react-i18next';
import { calculatePercentageAverage } from '../../../../utils/array';

const SummaryContainer = ({
  comment,
  scoreList,
  onChangeHandler,
  isSubmit,
  isInterviewer,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="summary-container">
        <div className="left">
          <div className="score-title">{t('title.total-score')}</div>
        </div>
        <div className="right">
          <div className="total-score-container">
            <div className="score-box">
              <div
                className="score-bar"
                style={{ width: `${calculatePercentageAverage(scoreList)}%` }}
              />
            </div>
            <div className="score-percent">
              <span>{calculatePercentageAverage(scoreList)}</span> 점
            </div>
          </div>
        </div>
      </div>
      <div className="comment-container">
        <textarea
          value={comment}
          onChange={onChangeHandler}
          disabled={isSubmit || !isInterviewer}
          placeholder={`| ${t('text-field-placeholder.comment')}`}
        />
      </div>
    </>
  );
};

export default SummaryContainer;
