import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAuth, validateEmail } from 'utils/auth';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmailIcon } from 'assets/images/icon/email.svg';
import { ReactComponent as EyeIcon } from 'assets/images/icon/eye.svg';
import MaterialInput from 'components/input/MaterialInput';
import MainLogo from 'components/logo/MainLogo';
import { CircularProgress } from '@mui/material';
import { getUserInfo } from 'store/slices/authSlice';
import PropTypes from 'prop-types';
import { executeAction } from 'utils/redux';

const Signin = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { account } = useParams();
  const auth = useAuth();

  const from = location.state?.from?.pathname || '/';

  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [checked, setChecked] = React.useState({ keep_session: false });
  const [canSubmit, setCanSubmit] = React.useState(false);

  React.useEffect(() => {
    window.localStorage.setItem('munch_skill_account', account || 'main');
  }, [account]);

  React.useEffect(() => {
    if (!validateEmail(email)) {
      return setCanSubmit(false);
    }

    if (password.length <= 0) {
      return setCanSubmit(false);
    }
    setCanSubmit(true);
  }, [email, password]);

  const onSetPasswordClickHandler = () => {
    navigate(`/${account}/auth/set-password`);
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (canSubmit && !isLoading) onSubmitHandler(e);
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();

    setIsLoading(true);
    auth
      .signin(
        { email, password },
        (authToken) => {
          setEmail('');
          setPassword('');
          setIsLoading(false);
          if (checked.keep_session) {
            window.localStorage.setItem('munch_skill_auth_token', authToken);
          }
          executeAction(getUserInfo())
            .unwrap()
            .then(({ doc }) => {
              if (
                doc?.privacyAgree === undefined ||
                doc?.termAgree === undefined ||
                !doc?.privacyAgree ||
                !doc?.termAgree
              ) {
                navigate(`/${account}/auth/agree`, { replace: true });
              } else {
                navigate(from, { replace: true });
              }
            })
            .catch((e) => {
              console.error(e);
            });
        },
        (e) => {
          setIsLoading(false);
        }
      )
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const signupClickHandler = () => {
    navigate(`/${account}/auth/signup/email`);
  };

  return (
    <main className="pages-auth-signin">
      <MainLogo className="logo" />
      <form className="form">
        <MaterialInput
          className="form-input email"
          icon={<EmailIcon />}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={onKeyPress}
          placeholder={`${t('text-field.label.email')} *`}
        />
        <MaterialInput
          className="form-input password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={onKeyPress}
          placeholder={`${t('text-field.label.pw')} *`}
          icon={<EyeIcon />}
        />
        <section className="form-option">
          <label
            className={`common-checkbox fs16 ${
              checked.keep_session && 'checked'
            }`}
            onClick={() => {
              setChecked({
                ...checked,
                keep_session: !checked.keep_session,
              });
            }}
          >
            {t('check-box.label.keep-login')}
          </label>
          <button
            className="text-button fsbtn16 primary"
            onClick={onSetPasswordClickHandler}
          >
            {t('btn.reset-pw')}
          </button>
        </section>
        <button
          className={`form-submit common-button fsbtn16 ${
            canSubmit && !isLoading && 'active'
          } ${isLoading && 'pending'}`}
          type="submit"
          onClick={canSubmit && !isLoading ? onSubmitHandler : null}
        >
          {t('btn.login')}
          {isLoading && <CircularProgress color="grey" size={25} />}
        </button>
        <section className="signup text-button primary">
          <p className="fs16">
            {t('login.desc.no-account')}
            <button className="fs16" onClick={signupClickHandler}>
              {t('btn.signup')}
            </button>
          </p>
        </section>
      </form>
    </main>
  );
};

Signin.propTypes = {};

export default Signin;
