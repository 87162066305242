import React from 'react';
import ResultScoreBox from './ResultScoreBox';

const VideoResultDetail = ({ result, onClick, scoreDisplay, type }) => {
  return (
    <div className="toggle-body-answer-box">
      <aside className="video-box">
        <video src={result?.video && result?.video} controls="controls" />
        {result?.audio && <audio src={result.audio} controls='controls' />}
      </aside>
      <aside className="answer-box">
        <ResultScoreBox
          score={scoreDisplay(result?.score) || 0}
          onClick={onClick}
          isHideButton={type === 'custom' ? false : type !== 'current'}
        />
        {/* <div className="result-stt-box">
          <p className="results-desc">{result?.text && result?.text}</p>
        </div> */}
      </aside>
    </div>
  );
};

export default VideoResultDetail;
