import React from 'react';
import { colors } from 'config/theme';
import { useModal } from 'utils/modal';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-modal.svg';

const FullModal = ({ priority }) => {
  const {
    dismissModal,
    path,
    params,
    options,
    subPath,
    subParams,
    subOptions,
  } = useModal();

  const __PADDING__ = options?.padding || '20px';

  const onDismissHandler = () => {
    dismissModal();
  };

  if ((priority === 'main' || !priority) && Boolean(path)) {
    const ModalComponent = require(`modals/${path}.jsx`).default;

    return (
      <div
        className="modal-overlay"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: colors.modalOverlay,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className={`modal full-modal ${options.hasButton && 'hasButton'}`}
          style={{
            position: 'relative',
          }}
        >
          {options.hasCloseButton && (
            <CloseIcon
              className="button modal-close"
              style={{}}
              onClick={onDismissHandler}
            />
          )}
          <ModalComponent {...params} {...options} />
        </div>
      </div>
    );
  }
  if (priority === 'sub' && Boolean(subPath)) {
    const ModalComponent = require(`modals/${subPath}.jsx`).default;

    return (
      <div
        className="modal-overlay"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: colors.modalOverlay,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          className={`modal full-modal ${subOptions.hasButton && 'hasButton'}`}
          style={{
            position: 'relative',
          }}
        >
          {subOptions.hasCloseButton && (
            <CloseIcon
              className="button modal-close"
              style={{}}
              onClick={onDismissHandler}
            />
          )}
          <ModalComponent {...subParams} {...subOptions} />
        </div>
      </div>
    );
  }

  return <></>;
};

export default FullModal;
