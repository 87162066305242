import React from 'react';
import { useTranslation } from 'react-i18next';
import { CandidateDetailMoreMenu } from 'components/recruitment/detail/CandidateDetailMoreMenu';
import { candidateActionMenu } from 'constants/recruitments/candidateActionMenu';
import BodyTableRowSkeleton from '../BodyTableRowSkeleton';
import { useModal } from '../../../../utils/modal';
import { nameConversionLanguage } from '../../../../utils/converter';
import { dateFormat, getDaysElapsed } from '../../../../utils/dateFormat';
import Tag from '../../../common/Tag';

const RecruitmentTableBody = ({ candidates, recruitment, step }) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();

  const viewDetailModal = (candidate) => {
    displayModal(
      'recruitments/CandidateDetail',
      {
        candidates,
        candidate,
        recruitment,
        processName: 'evidence',
      },
      { frame: 'full' }
    );
  };

  return (
    <>
    <tbody>
      {candidates === null ? (
        <>
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
        </>
      ) : candidates?.length === 0 ? (
        <tr>
          <td>{t('recruitment.desc.empty-no-applicant')}</td>
        </tr>
      ) : (
        candidates?.map((candidate) => (
          <tr key={candidate?.email} onClick={() => viewDetailModal(candidate)}>
            <td>
              {nameConversionLanguage(
                candidate?.firstName,
                candidate?.lastName,
                t('lang')
              )}
            </td>
            <td>
              <Tag
                label={candidate?.progressSubmit[step].state}
                color={
                  candidate?.progressSubmit[step].state === 'waiting'
                    ? 'grey300'
                    : 'blue500'
                }
                fontSize="fs12"
                width={'80%'}
              />
            </td>
            <td>
              {dateFormat(
                (candidate?.progressSubmit[step].invitedAt).seconds * 1000
              )}
            </td>
            <td>
              {candidate?.progressSubmit[step].submitDate
                ? getDaysElapsed(
                    candidate?.progressSubmit[step].submitDate.seconds * 1000
                  ) === 0
                  ? t('lead-time.today')
                  : `${getDaysElapsed(
                      candidate?.progressSubmit[step].submitDate.seconds * 1000
                    )} ${t('lead-time.days')}`
                : '-'}
            </td>
            <td>
              <CandidateDetailMoreMenu
                candidate={candidate}
                rid={recruitment?.rid}
                menuList={[candidateActionMenu.deleteCandidate, candidateActionMenu.moveToPrevStep]}
                />
            </td>
          </tr>
        ))
      )}
    </tbody>
    </>
  );
};

export default RecruitmentTableBody;
