import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MembersTableHead from './MembersTableHead';
import MembersTableBody from './MembersTableBody';

const HEADS = [
  'Type',
  'Name',
  'Email',
  'Status',
  'Team',
  'Job Title',
  'Location',
  'Submission Date',
  'Action',
];

const MembersList = ({ users }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <article className="members-list">
      <div className="table-border member-list-contents">
        <table className="recruitment-table">
          <caption>invited member list</caption>
          <MembersTableHead heads={HEADS} />
          <MembersTableBody users={users} userInfo={userInfo} />
        </table>
      </div>
    </article>
  );
};

export default MembersList;
