import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  changeStateOfRecruitment,
  getRecruitment,
  getRecruitments,
} from 'store/slices/recruitments/recruitmentsSlice';
import { executeAction } from 'utils/redux';
import { ReactComponent as InventoryIcon } from 'assets/images/icon/inventory-2.svg';
import { useModal } from 'utils/modal';
import { Trans, useTranslation } from 'react-i18next';
import { dateFormat } from '../../../utils/dateFormat';

const DashboardListItem = ({
  recruitment,
  userInfo,
  startPeriod,
  isArchived,
  isSchedule,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayModal } = useModal();

  const [isCounting, setIsCounting] = useState(true);

  useEffect(() => {
    const sum = recruitment.counts.reduce((acc, cur) => acc + cur, 0);

    if (sum === 0) {
      setIsCounting(false);
    }
  }, [recruitment]);

  const moveToDetailPage = (rid) => {
    if (userInfo?.memberType === 15) {
      executeAction(getRecruitment({ aid: rid, type: 'interviewer' }))
        .unwrap()
        .then(({ doc }) => {
          navigate(`/customer/recruitments/${rid}/${doc?.stage}`);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      navigate(`/customer/recruitments/${rid}`);
    }
  };

  const toggleArchivedRecruitment = (rid) => {
    const eventHandler = (event) => {
      if (event.detail?.message !== 'ToggleRecruitment') return;
      executeAction(changeStateOfRecruitment({ aid: rid }))
        .unwrap()
        .then(() => {
          executeAction(getRecruitments({ memberType: userInfo?.memberType }));
        })
        .catch((e) => {
          console.error(e);
        });

      window.document.removeEventListener('dismissModal', eventHandler);
    };

    window.document.addEventListener('dismissModal', eventHandler);

    let title_main, title_sub;
    if (!isArchived) {
      // 진행중인 평가
      title_main = '공고를 종료하시겠습니까?';
      title_sub =
        '공고를 보관하면 공유 링크를 통한 액세스가 비활성화됩니다. 응시자 데이터는 유지됩니다.';
    } else {
      // 종료된 평가
      title_main = '공고를 활성화하시겠습니까?';
      title_sub = '평가를 활성화하면 공유 링크를 통한 액세스가 활성화됩니다.';
    }

    displayModal(
      'recruitments/ConfirmToggleRecruitment',
      {
        event: {
          message: 'ToggleRecruitment',
        },
        title: {
          main: title_main,
          sub: title_sub,
        },
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  return (
    <>
      {(recruitment && isCounting) || !isSchedule ? (
        <li>
          <div className="process-area" key={recruitment?.rid}>
            <div className="flex">
              <span className="recruitment-name fs16">{recruitment?.name}</span>
              <button>
                <InventoryIcon
                  onClick={() => toggleArchivedRecruitment(recruitment?.rid)}
                />
              </button>
            </div>
            <div
              className={`step-wrapper ${`length-${recruitment?.counts?.length}`}`}
              onClick={() => moveToDetailPage(recruitment?.rid)}
            >
              {recruitment?.counts?.map((count, idx) => {
                let title;
                let className;

                if (recruitment?.counts?.length === 8) {
                  if (idx === 0) {
                    title = 'title.process.reviewing-application';
                    className = 'reviewing';
                  } else if (idx === 1) {
                    title = 'title.process.digital-interview';
                    className = 'digital';
                  } else if (idx === 2) {
                    title = 'title.process.in-person-interview';
                    className = 'in-person';
                  } else if (idx === 3) {
                    title = 'title.process.in-person-interview-optional';
                    className = 'in-person';
                  } else if (idx === 4) {
                    title = 'title.process.compensation';
                    className = 'document';
                  } else if (idx === 5) {
                    title = 'title.process.offer-letter';
                    className = 'offer';
                  } else if (idx === 6) {
                    title = 'title.process.hired';
                    className = 'certificate';
                  } else if (idx === 7) {
                    title = 'tab.step.reject';
                    className = 'reject';
                  }
                } else if (recruitment?.counts?.length === 7) {
                  if (idx === 0) {
                    title = 'title.process.reviewing-application';
                    className = 'reviewing';
                  } else if (idx === 1) {
                    title = 'title.process.digital-interview';
                    className = 'digital';
                  } else if (idx === 2) {
                    title = 'title.process.in-person-interview';
                    className = 'in-person';
                  } else if (idx === 3) {
                    title = 'title.process.compensation';
                    className = 'document';
                  } else if (idx === 4) {
                    title = 'title.process.offer-letter';
                    className = 'offer';
                  } else if (idx === 5) {
                    title = 'title.process.hired';
                    className = 'certificate';
                  } else if (idx === 6) {
                    title = 'tab.step.reject';
                    className = 'reject';
                  }
                }

                return (
                  <div
                    className={`step ${className} fs12`}
                    key={`${title}_${idx}`}
                  >{`${t(title)} (${count})`}</div>
                );
              })}
            </div>
          </div>
        </li>
      ) : startPeriod <= new Date().getTime() ? (
        <li>
          <div className="process-area no-area" style={{ width: '100%' }}>
            <div className="flex">
              <span className="recruitment-name fs16">{recruitment?.name}</span>
              <button>
                <InventoryIcon
                  onClick={() => toggleArchivedRecruitment(recruitment?.rid)}
                />
              </button>
            </div>
            <span
              className="no-apply-candidate fs14"
              onClick={() => moveToDetailPage(recruitment?.rid)}
            >
              {t('recruitment.desc.empty-no-applicant')}
            </span>
          </div>
        </li>
      ) : (
        <li>
          <div className="process-area no-area" style={{ width: '100%' }}>
            <div className="flex">
              <span className="recruitment-name fs16">{recruitment?.name}</span>
              <button>
                <InventoryIcon
                  onClick={() => toggleArchivedRecruitment(recruitment?.rid)}
                />
              </button>
            </div>
            <span
              className="no-apply-candidate"
              onClick={() => moveToDetailPage(recruitment?.rid)}
            >
              <Trans
                t={t}
                parent="p"
                i18nKey="desc.ready-to-be-open"
                values={{
                  date: dateFormat(startPeriod),
                }}
              />
            </span>
          </div>
        </li>
      )}
    </>
  );
};

export default DashboardListItem;
