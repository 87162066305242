import DashboardContainer from 'components/recruitment/dashboard/DashboardContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

const Dashboard = () => {
  const context = useOutletContext();
  const { t } = useTranslation();
  const { filteredRecruitments } = context;
  const { setCurrentTab } = context;
  const { currentTab } = context;
  const { countStageLoading } = useSelector((state) => state.recruitments);

  return (
    <main id="dashboard">
      <section className="tab-container toggle-tab-container">
        <ul>
          <li
            className={`current fs16 ${currentTab === 'current' && 'active'}`}
            onClick={() =>
              !countStageLoading ? setCurrentTab('current') : null
            }
          >
            {t('tab.current')}
          </li>
          <li
            className={`closed fs16 ${currentTab === 'closed' && 'active'}`}
            onClick={() =>
              !countStageLoading ? setCurrentTab('closed') : null
            }
          >
            {t('tab.closed')}
          </li>
          <li
            className={`all fs16 ${currentTab === 'all' && 'active'}`}
            onClick={() => (!countStageLoading ? setCurrentTab('all') : null)}
          >
            {t('tab.full-view')}
          </li>
        </ul>
      </section>
      <section className="dashboard-container">
        <DashboardContainer filteredRecruitments={filteredRecruitments} />
      </section>
    </main>
  );
};

export default Dashboard;
