import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { executeAction } from 'utils/redux';
import { recruitmentsCountStage } from 'store/slices/recruitments/recruitmentsSlice';
import DashboardListItem from './DashboardListItem';
import DashboardListSkeleton from './DashboardListSkeleton';

const DashboardContainer = ({ filteredRecruitments }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { countStateSuccess, countStageLoading } = useSelector(
    (state) => state.recruitments
  );

  const [countArray, setCountArray] = useState([]);

  useEffect(() => {
    const countArr = filteredRecruitments?.map((recruitment) => {
      return Object.values(recruitment?.processSetting).filter(
        (item) => item.isUsed
      ).length;
    });

    setCountArray(countArr);
  }, [filteredRecruitments]);

  useEffect(() => {
    executeAction(
      recruitmentsCountStage({
        recruitments: filteredRecruitments,
        memberType: userInfo?.memberType,
        countArr: countArray,
      })
    );
  }, [countArray]);

  return (
    <article className="dashboard-wrapper">
      <ul>
        {!filteredRecruitments || countStageLoading ? (
          <>
            <DashboardListSkeleton />
            <DashboardListSkeleton />
            <DashboardListSkeleton />
          </>
        ) : (
          countStateSuccess?.map((recruit, idx) => (
            <DashboardListItem
              key={`${recruit}_${idx}`}
              userInfo={userInfo}
              recruitment={recruit}
              startPeriod={filteredRecruitments[idx]?.startPeriodSchedule}
              isArchived={filteredRecruitments[idx]?.isRecruitmentArchived}
              isSchedule={filteredRecruitments[idx]?.isSchedule}
            />
          ))
        )}
      </ul>
    </article>
  );
};

export default DashboardContainer;
