import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EN from './langs/en.json';
import KO from './langs/ko.json';

export const supportedLangs = [
  {
    key: 'en',
    i18nKey: 'menu.input.lang-en',
  },
  {
    key: 'ko',
    i18nKey: 'menu.input.lang-ko',
  },
];

const resources = {
  en: {
    translation: EN,
  },
  ko: {
    translation: KO,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'], // cache user language on
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'span', 'p', 'bold', 'i'],
    },
  });

export default i18n;
