import MaterialInput from 'components/input/MaterialInput';
import React, { useState } from 'react';
import { useAuth } from 'utils/auth';

const VideoScoring = () => {
  const { user } = useAuth();
  const [aid, setAid] = useState('');
  const [cid, setCid] = useState('');

  return (
    <main className="admin-video-scoring">
      <section className="admin-video-scoring-title-box">
        <h2 className="admin-video-scoring-title fs28">비디오 AI 채점</h2>
      </section>

      <section className="admin-video-scoring-contents">
        <article className="admin-video-search-wrap">
          <aside className="admin-video-input">
            <MaterialInput
              placeholder="평가 아이디"
              value={aid}
              onChange={(e) => setAid(e.target.value)}
              placeColor="white"
            />
            <MaterialInput
              placeholder="이메일"
              value={cid}
              onChange={(e) => setCid(e.target.value)}
              placeColor="white"
            />
          </aside>
        </article>
      </section>
    </main>
  );
};

export default VideoScoring;
