import React from 'react';
import AppNavigator from 'components/navigator/AppNavigator';
import { AiOutlineMenu } from 'react-icons/ai';
import { ReactComponent as ChevronBottomIcon } from 'assets/images/icon/chevron-bottom-dropdown.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'utils/auth';
import MainLogo from 'components/logo/MainLogo';
import { useTranslation } from 'react-i18next';
import { useCheckedAdminPage, useOnClickOutside } from 'utils/hooks';
import { useModal } from 'utils/modal';
import { displayName } from 'utils/string';

const AppHeader = (props) => {
  const [visibilityMoreDropdown, setVisibilityMoreDropdown] =
    React.useState(false);
  const { t } = useTranslation();
  const auth = useAuth();
  const { updateUserLimit, userInfo } = useAuth();
  const navigate = useNavigate();
  const moreRef = React.useRef(null);
  const { displayModal } = useModal();
  const [assessmentLimit, setAssessmentLimit] = React.useState(50);
  const [candidateLimit, setCandidateLimit] = React.useState(300);
  const [isHideButton, setIsHideButton] = React.useState(false);
  const checkedAdmin = useCheckedAdminPage();

  const __MENUS__ = [
    {
      key: 'myProfile',
      i18nKey: 'header.menu.settings',
      onClick: ({ setShowDropdown, navigate }) => {
        navigate('/customer/profile');
        setShowDropdown(false);
      },
    },
    {
      key: 'userGuide',
      i18nKey: 'header.menu.user-guide',
      onClick: ({ setShowDropdown, navigate }) => {
        if (t('lang') === 'ko') {
          window.open('https://guide.munchskill.com/ko');
        } else {
          window.open('https://guide.munchskill.com/');
        }
        setShowDropdown(false);
      },
    },
    {
      key: 'logout',
      i18nKey: 'header.menu.logout',
      onClick: ({ setShowDropdown, auth }) => {
        auth.signout();
        setShowDropdown(false);
      },
    },
  ];

  /** ===============================
     * 크레딧 업데이트
     * assessmentLimit(Number): 생성가능한 평가 수
     * candidateLimit(Number): 초대가능한 응시자 수
     ================================= */
  React.useEffect(() => {
    if (userInfo === null) {
      return;
    }
    if (userInfo?.memberType <= 10 || !userInfo?.isATS) {
      setIsHideButton(true);
    }

    if (
      userInfo.assessmentLimit === undefined &&
      userInfo.candidateLimit === undefined
    ) {
      updateUserLimit({
        assessmentLimit,
        candidateLimit,
      })
        .then(() => {})
        .catch((e) => {
          console.dir(e);
        });
    } else {
      return;
    }
  }, [auth]);

  useOnClickOutside(moreRef, () => setVisibilityMoreDropdown(false));

  // 현재 assessment를 생성 중일 때는 생성하기 버튼이 동작하지 않도록 한다.
  const onCreateClickHandler = () => {
    if (userInfo?.assessmentLimit <= 0) {
      displayModal(
        'assessments/ConfirmCreateLimit',
        {
          event: {
            message: 'CreateLimit',
          },
          title: {
            main: t('dialog.title.exceeded-assessment'),
            sub: t('dialog.desc.exceeded-assessment'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    } else if (window.location.pathname !== '/customer/assessments/create') {
      navigate('/customer/assessments/create');
    }
  };

  const onDisplayMenuMobileClickHandler = () => {
    displayModal(
      'layout/MoreMenu',
      {},
      {
        frame: 'full',
        close: false,
      }
    );
  };

  const onClickMoveMainPage = () => {
    if (checkedAdmin) {
      navigate('/admin/customer/assessments');
    } else if (userInfo?.isATS) {
      navigate('/customer/recruitments');
    } else {
      navigate('/customer/assessments');
    }
  };

  return (
    <header className="components-header-app-header">
      <div className="header-container">
        <div className="header-container-left">
          <MainLogo
            type="appHeader"
            className="header-logo header"
            onClick={onClickMoveMainPage}
          />
          <AppNavigator />
        </div>
        <div className="header-container-right">
          <section ref={moreRef} className="header-more">
            {isHideButton && (
              <button
                className="header-create common-button fsbtn16 primary active"
                onClick={() => onCreateClickHandler()}
              >
                {t('btn.create-new-assessment')}
              </button>
            )}
            <div
              className="header-more-toggle"
              onClick={() => setVisibilityMoreDropdown(!visibilityMoreDropdown)}
            >
              <div className="wrapper">
                <span className="name fs14">
                  {displayName(
                    auth?.userInfo?.firstName,
                    auth?.userInfo?.lastName,
                    t('lang')
                  )}
                </span>
                <span className="email fs12">{auth?.user?.email}</span>
              </div>
              <ChevronBottomIcon />
            </div>
            {visibilityMoreDropdown && (
              <div className="header-more-dropdown">
                <ul className="header-more-dropdown-list">
                  {(__MENUS__ || []).map((menu, idx) => (
                    <li
                      key={`menu_${idx}`}
                      className="header-more-dropdown-list-item"
                      onClick={() =>
                        menu.onClick({
                          auth,
                          navigate,
                          setShowDropdown: setVisibilityMoreDropdown,
                        })
                      }
                    >
                      {t(menu.i18nKey)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </section>
          <section className="header-navigator mobile">
            <AiOutlineMenu
              className="header-navigator-toggle"
              onClick={onDisplayMenuMobileClickHandler}
              size="24"
            />
          </section>
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
