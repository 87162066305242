import React from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ExcelDownloadLoading = () => {
  const { t } = useTranslation();

  return (
    <article className='modals-excel-loading'>
      <h1>{t('modal.excel-desc')}</h1>
      <CircularProgress color='primary' size={50} />
    </article>
  );
};

export default ExcelDownloadLoading;
