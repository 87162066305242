import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const KhaiOInterviewLeft = ({ title, desc, rank, type }) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState();

  const colorByRank = (idx) => {
    switch (idx) {
      case 'rank1':
        return 'red';
      case 'rank2':
        return 'orange';
      case 'rank3':
        return 'green';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (!desc) return;

    const descArr = desc?.split('|');
    setDescription(descArr);
  }, [desc]);

  return (
    <aside className={`interview-title-wrap ${type}`}>
      <span className={`interview-tag ${type} ${colorByRank(rank)}`}>
        {rank === 'rank1' && t('title.top-1-organizational-criteria')}
        {rank === 'rank2' && t('title.top-2-organizational-criteria')}
        {rank === 'rank3' && t('title.top-3-organizational-criteria')}
      </span>
      <h5 className={`interview-title ${type === 'print' ? 'fs14' : 'fs20'}`}>
        {title && title}
      </h5>
      <div className="interview-desc">
        {description &&
          (description || []).map((content, idx) => (
            <p
              key={`desc_${idx}`}
              className={`${type === 'print' ? 'fs9' : 'fs16'}`}
            >
              {content}
            </p>
          ))}
      </div>
    </aside>
  );
};

export default KhaiOInterviewLeft;
