import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';
import { useModal } from 'utils/modal';
import { Checkbox } from '@mui/material';
import MaterialDateTimePicker from 'components/common/MaterialDateTimePicker';
import { updatePeriod } from 'store/slices/assessmentsSlice';
import { executeAction } from 'utils/redux';
import { toast } from 'utils/notify';

const PeriodDateTime = ({
  aid,
  period,
  startPeriod,
  endPeriod,
  setStartPeriod,
  setEndPeriod,
  setPeriod,
}) => {
  const { t } = useTranslation();
  let { dismissModal } = useModal();
  const { displayModal } = useModal();

  const [periodOnOff, setPeriodOnOff] = React.useState(period);
  const [startPeriodDateTime, setStartPeriodDateTime] =
    React.useState(startPeriod);
  const [endPeriodDateTime, setEndPeriodDateTime] = React.useState(endPeriod);
  const onDismissHandler = () => {
    dismissModal();
  };

  const onDismissHandlerWithSave = () => {
    if (
      (periodOnOff && endPeriodDateTime <= startPeriodDateTime) ||
      (periodOnOff && endPeriodDateTime < new Date().getTime())
    ) {
      toast(t('dialog.title.error-assessment-end-time'));
      return;
    }
    if (!endPeriodDateTime || !startPeriodDateTime) {
      toast(t('toast.desc.add-assessment-period'));
      return;
    }
    dismissModal();
    updatePeriods();
    setPeriod(periodOnOff);
    setEndPeriod(endPeriodDateTime);
    setStartPeriod(startPeriodDateTime);
  };

  const onPeriodChangeHandler = (event) => {
    event.preventDefault();
    const newPeriod = !periodOnOff;
    setPeriodOnOff(newPeriod);
  };

  const updatePeriods = () => {
    executeAction(
      updatePeriod({
        aid,
        periodOnOff,
        startPeriodDateTime: periodOnOff ? startPeriodDateTime : undefined,
        endPeriodDateTime: periodOnOff ? endPeriodDateTime : undefined,
      })
    )
      .unwrap()
      .catch((e) => {
        console.dir(e);
      });
  };

  return (
    <article className="modal-set-period ">
      <header className="modal-header">
        <span className="title fs16">
          {t('actions.menu.assessment-period')}
        </span>
        <CloseIcon onClick={onDismissHandler} />
      </header>
      <main className="modal-main">
        <div className="select-box">
          <div className="sub-title fs16">{t('title.assessment-period')}</div>
          <div
            className={`select ${periodOnOff === true ? 'selected' : ''}`}
            onClick={(e) => onPeriodChangeHandler(e)}
          >
            <Checkbox
              className={`radio-button ${
                periodOnOff === true ? 'selected' : ''
              }`}
              checked={periodOnOff}
              onChange={(e) => onPeriodChangeHandler(e)}
              name="radio-buttons"
            />
            <p className="fs16">{t('btn.assessment-period')}</p>
          </div>
        </div>
        <div className="time-picker-box">
          <div className="time-label">
            <div className="fs14 fcgrey700">
              {t('text-field.label.assessment-start-time')}
            </div>
            <MaterialDateTimePicker
              period={periodOnOff}
              defaultDate={startPeriodDateTime}
              setPeriodDateTime={setStartPeriodDateTime}
            />
          </div>
          <div className="time-label">
            <div className="fs14 fcgrey700">
              {t('text-field.label.assessment-end-time')}
            </div>
            <MaterialDateTimePicker
              period={periodOnOff}
              defaultDate={endPeriodDateTime}
              setPeriodDateTime={setEndPeriodDateTime}
            />
          </div>
        </div>
        <button className="save-button" onClick={onDismissHandlerWithSave}>
          {t('btn.save')}
        </button>
      </main>
    </article>
  );
};

export default PeriodDateTime;
