import React from 'react';

const Thumbnail = () => {
  return (
    <article className="intro-background">
      <div className="logo">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/munchskilldev.appspot.com/o/gucci%2Foffer_cover.jpg?alt=media&token=8caf6daa-5706-4ce5-ba8b-f35df66ff1e5"
          alt="cover"
        />
      </div>
    </article>
  );
};

export default Thumbnail;
