import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  _getTests,
  _getTestInfo,
  _getSkillGroups,
  _getSkillInfo,
} from 'utils/firebase/tests';
import { serializeError } from 'serialize-error';

const initialState = {};

export const getSkillGroups = createAsyncThunk(
  'tests/getSkillGroups',
  async (payload = {}, { rejectWithValue }) => {
    const lang = payload;
    try {
      const docs = await _getSkillGroups(lang);
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getSkillInfo = createAsyncThunk(
  'tests/getSkillInfo',
  async (payload = {}, { rejectWithValue }) => {
    let { sid, lang } = payload;
    try {
      const doc = await _getSkillInfo({ sid, lang });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getTests = createAsyncThunk(
  'tests/getTests',
  async (payload = {}, { rejectWithValue }) => {
    const lang = payload;
    try {
      const docs = await _getTests(lang);
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const testsSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {},
  extraReducers: {},
});

export const getTestInfo = createAsyncThunk(
  'tests/getTestInfo',
  async (payload = {}, { rejectWithValue }) => {
    let { tid, lang } = payload;
    try {
      const doc = await _getTestInfo({ tid, lang });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getTestInfos = createAsyncThunk(
  'tests/getTestInfos',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { tids, lang } = payload;
    try {
      const basics = new Array();
      const intermediates = new Array();
      const advanceds = new Array();

      const tasks = new Array();
      tids.forEach((tid) => {
        tasks.push(
          dispatch(
            getTestInfo({
              tid,
              lang,
            })
          )
            .unwrap()
            .then(({ doc: testInfo }) => {
              // console.log(testInfo);
              switch (testInfo?.level_en) {
                case 'Basic':
                case '초급':
                  basics.push({ tid, testInfo });
                  break;
                case 'Intermediate':
                case '중급':
                  intermediates.push({ tid, testInfo });
                  break;
                case 'Advanced':
                case '고급':
                  advanceds.push({ tid, testInfo });
                  break;
                default:
                  basics.push({ tid, testInfo });
              }
            })
        );
      });
      await Promise.allSettled(tasks);

      return {
        basics,
        intermediates,
        advanceds,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const {} = testsSlice.actions;

export default testsSlice.reducer;
