import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import LanguageResultBox from './LanguageResultBox';

const LanguageContainer = ({ candidate }) => {
  const { t } = useTranslation();
  const [saveEnglishTestResult, setSaveEnglishTestResult] = useState([]);

  /** ==========
   * 영어, 중국어, 일본어 순서대로 sorting
  ============== */
  useEffect(() => {
    const languageTestArr = [];
    if (Object.keys(candidate).length === 0 || candidate?.results === undefined)
      return;

    const sortedKeys = Object.keys(candidate?.results).sort((a, b) => {
      if (a === 'english') return -1;
      if (b === 'english') return 1;
      if (a === 'chinese') return -1;
      if (b === 'chinese') return 1;
      if (a === 'japanese') return -1;
      if (b === 'japanese') return 1;
      return 0;
    });

    sortedKeys.map((key) => {
      if (key === 'english' || key === 'chinese' || key === 'japanese') {
        languageTestArr.push({ [key]: candidate?.results[key] });
      }
    });

    setSaveEnglishTestResult(languageTestArr);
  }, [candidate]);

  return (
    <section
      className="language-result-container"
      style={{ display: saveEnglishTestResult?.length === 0 && 'none' }}
    >
      <div className="title-box">
        <h4 className="title fs20 ">{t('title.language-test-result')}</h4>
      </div>
      <Paper>
        <TableContainer>
          <Table className="table">
            <TableHead>
              <TableRow className="head-tr">
                <TableCell className="title head-td fs14" width="396px">
                  {t('title.language-test')}
                </TableCell>
                <TableCell className="total-result head-td fs14" width="200px">
                  {t('title.overall-results')}
                </TableCell>
                <TableCell className="reading head-td fs14" width="200px">
                  {t('title.reading')}
                </TableCell>
                <TableCell className="listening head-td fs14" width="200px">
                  {t('title.listening')}
                </TableCell>
                <TableCell className="speaking head-td fs14" width="300px">
                  {t('title.speaking')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {saveEnglishTestResult &&
                (saveEnglishTestResult || []).map((result, idx) => (
                  <LanguageResultBox key={`result_${idx}`} test={result} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </section>
  );
};

export default LanguageContainer;
