import React from 'react';
import { ReactComponent as LogoFullMain } from 'assets/images/logo/logo_full_main.svg';
import { ReactComponent as LogoFullAppHeader } from 'assets/images/logo/logo_full_app_header.svg';
import { ReactComponent as LogoFullSignup } from 'assets/images/logo/logo_full_signup.svg';

const MainLogo = ({ type, className, onClick }) => {
  if (type === 'signup') {
    return (
      <LogoFullSignup
        className={`components-logo-main-logo ${className}`}
        onClick={onClick}
      />
    );
  }
  if (type === 'appHeader') {
    return (
      <LogoFullAppHeader
        className={`components-logo-main-logo ${className}`}
        onClick={onClick}
      />
    );
  }
  return (
    <LogoFullMain
      className={`components-logo-main-logo ${className}`}
      onClick={onClick}
    />
  );
};

export default MainLogo;
