import React, { useState, useCallback } from 'react';
import { ReactComponent as FileIcon } from 'assets/images/icon/attach-file.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';
import { toast } from 'utils/notify';
import { executeAction } from 'utils/redux';
import {setCandidateOfRecruitment} from 'store/slices/recruitments/recruitmentsSlice';
import { uploadBytes } from 'store/slices/uploadSlice';
import { useTranslation } from 'react-i18next';
import ApplyInputBox from 'components/apply/ApplyInputBox';
import { Button } from 'components/common/Button';

const UploadCandidateFile = ({ onDismissHandler, rid, cid, progressSubmit }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [applyFile, setApplyFile] = useState({key: '', file: '', name: ''});
  const [applyFileType, setApplyFileType] = useState('');

  const onSubmitHandler = useCallback(async() => {
    setIsLoading(true);

        executeAction(
          uploadBytes({
            collection: 'gucciapply',
            category: 'resume',
            email: cid,
            rid,
            fileName: applyFile.file.name,
            bytes: applyFile.file,
          })
        )
          .unwrap()
          .then(({ downloadURL }) => {
            const resume = {
              type: applyFile.key,
              url: downloadURL,
              name: applyFile.name,
            };

            executeAction(
              setCandidateOfRecruitment({
                aid: rid,
                cid,
                progressSubmit: {
                  1: {
                    ...progressSubmit,
                    resumeArr: [...progressSubmit.resumeArr, resume],
                  },
                },
              })
            )
              .unwrap()
              .then(() => {
                toast(t('toast.desc.submission-complete'));
                onDismissHandler();
              })
              .catch((e) => {
                console.error(e);
              }).finally(() => {
              setIsLoading(false);
            });
          })
          .catch((e) => {
            console.error(e);
            setIsLoading(false);
          });
    },[applyFile, cid, onDismissHandler, progressSubmit, rid, t]);

  const handleInputFile = useCallback((e) => {
    const { files, name } = e.target;
    setApplyFile({ key: name, file: files[0], name: files[0]?.name });
  }, []);

  const handleFileType = (e, menu, type) => {
    if (type === 'select') {
      setApplyFileType(menu);
    }};

  return (
    <article className="upload-candidate-file">
      <header className="modal-header">
        <span className="title fs16">{t('btn.add-candidate')}</span>
        <i className="close">
          <CloseIcon onClick={onDismissHandler} />
        </i>
      </header>
      <main className="modal-main">
        <aside className="apply-input-box">
          <ApplyInputBox
            label={t('text-field.label.file-type')}
            isSelectBox
            name="fileType"
            required
            value={applyFileType}
            handleInput={handleFileType}
            menus={[
              { key: 'cv', i18nKey: 'menu.input.resume' },
              { key: 'work', i18nKey: 'menu.input.work-experience' },
              { key: 'portfolio', i18nKey: 'menu.input.portfolio' },
              { key: 'other', i18nKey: 'menu.input.other' },
            ]}
          />
          <div className="align-wrapper">
            <ApplyInputBox
              placeholder={t('text-field.label.file-upload')}
              label={t('text-field.label.file-upload')}
              required
              name={!applyFileType.key ? 'cv' : applyFileType.key}
              isFileInput
              disabled={applyFileType === ''}
              caution={`${t('text-field.notify.file-upload')}`}
              icon={<FileIcon />}
              value={applyFile && applyFile.name}
              handleInput={handleInputFile}
            />
          </div>
        </aside>
        <Button isDisabled={applyFile.file === ''} theme="grey" onClick={onSubmitHandler} size={48} label={t('btn.submit')} isLoading={isLoading} />
      </main>
    </article>
  );
};

export default UploadCandidateFile;
