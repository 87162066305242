import React from 'react';

const ModalContext = React.createContext(null);

export const ModalProvider = ({ children }) => {
  const [path, setPath] = React.useState('');
  const [params, setParams] = React.useState({});
  const [options, setOptions] = React.useState({});
  const [subPath, setSubPath] = React.useState('');
  const [subParams, setSubParams] = React.useState({});
  const [subOptions, setSubOptions] = React.useState({});

  const displayModal = (path, params, options) => {
    if (options?.priority === 'main' || !options?.priority) {
      setPath(path || '');
      setParams(params || {});
      setOptions(options || {});
    } else if (options?.priority === 'sub') {
      setSubPath(path || '');
      setSubParams(params || {});
      setOptions(options || {});
    }

    const event = new CustomEvent('displayModal', {
      detail: {
        path: options?.sub ? subPath : path,
        params: options?.sub ? subParams : params,
        options: options?.sub ? subOptions : options,
      },
    });
    window.document.dispatchEvent(event);
  };

  const dismissModal = (payload = {}) => {
    setPath('');
    setParams({});
    setOptions({});
    setSubPath('');
    setSubParams({});
    setSubOptions({});

    const event = new CustomEvent('dismissModal', {
      detail: payload,
    });
    window.document.dispatchEvent(event);
  };

  const value = {
    path,
    params,
    options,
    subPath,
    subParams,
    subOptions,
    displayModal,
    dismissModal,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export const useModal = () => React.useContext(ModalContext);
