import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as NoData } from 'assets/images/no-data-ai-interview.svg';
import { ReactComponent as Calendar } from 'assets/images/icon/calendar-illust-120x120.svg';
import { useTranslation } from 'react-i18next';
import { dateToDateFormat } from 'utils/string';
import RecruitmentStatusTab from '../RecruitmentStatusTab';
import InvitedInterviewer from '../../../../modals/recruitments/InvitedInterviewer';
import SummaryContainer from './SummaryContainer';
import OpinionContainer from './OpinionContainer';
import InterviewScore from './InterviewScore';
import BasicSelect from '../../../select/BasicSelect';

const PersonInterviewHrDetail = ({
  recruitment,
  currentCandidate,
  processName,
  processLength,
  selectedHistory,
  setSelectedHistory,
}) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);
  const step = processName === 'firstInterview' ? 3 : 4;
  const [invitedInterviewer, setInvitedInterviewer] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [interviewHistory, setInterviewHistory] = useState([]);
  const [interviewHistoryList, setInterviewHistoryList] = useState([
    {
      key: 'ongoing-interview',
      i18nKey:
        (currentCandidate?.progressSubmit[step]?.interviewSchedule?.isDone &&
          currentCandidate?.progressSubmit[step]?.interviewSchedule
            ?.interviewDate <= new Date().getTime()) ||
        currentCandidate?.progressSubmit[step]?.isSubmit
          ? 'select.ongoing-interview'
          : 'select.before-proceeding-interview',
    },
  ]);

  const { standardList } = recruitment;

  const [status, setStatus] = useState('');
  const [opinion, setOpinion] = useState('');
  const [comment, setComment] = useState('');
  const [scoreList, setScoreList] = useState([]);

  useEffect(() => {
    if (selectedHistory.key === 'ongoing-interview') {
      if (currentCandidate.progressSubmit[step]?.scoreList)
        setScoreList(currentCandidate.progressSubmit[step]?.scoreList);
      else setScoreList([]);

      if (currentCandidate.progressSubmit[step]?.opinion)
        setOpinion(currentCandidate.progressSubmit[step].opinion);
      else setOpinion('');

      if (currentCandidate.progressSubmit[step]?.comment)
        setComment(currentCandidate.progressSubmit[step].comment);
      else setComment('');

      setIsSubmit(Boolean(currentCandidate.progressSubmit[step]?.isSubmit));

      currentCandidate.progressSubmit[step]?.interviewer
        ? setInvitedInterviewer(false)
        : setInvitedInterviewer(true);
    } else {
      const idx = selectedHistory.key;

      if (
        currentCandidate.progressSubmit[step]?.interviewHistory[idx].scoreList
      )
        setScoreList(
          currentCandidate.progressSubmit[step]?.interviewHistory[idx].scoreList
        );
      else setScoreList([]);

      if (currentCandidate.progressSubmit[step]?.interviewHistory[idx].opinion)
        setOpinion(
          currentCandidate.progressSubmit[step].interviewHistory[idx].opinion
        );
      else setOpinion('');

      if (currentCandidate.progressSubmit[step]?.interviewHistory[idx].comment)
        setComment(
          currentCandidate.progressSubmit[step].interviewHistory[idx].comment
        );
      else setComment('');

      setIsSubmit(true);
      setInvitedInterviewer(false);
    }
  }, [currentCandidate, selectedHistory]);

  useEffect(() => {
    setIsRetry(
      Boolean(currentCandidate.progressSubmit[step]?.interviewHistory)
    );
    setInterviewHistoryList([
      {
        key: 'ongoing-interview',
        i18nKey:
          (currentCandidate?.progressSubmit[step]?.interviewSchedule?.isDone &&
            currentCandidate?.progressSubmit[step]?.interviewSchedule
              ?.interviewDate <= new Date().getTime()) ||
          currentCandidate?.progressSubmit[step]?.isSubmit
            ? 'select.ongoing-interview'
            : 'select.before-proceeding-interview',
      },
    ]);
    setSelectedHistory({
      key: 'ongoing-interview',
      i18nKey:
        (currentCandidate?.progressSubmit[step]?.interviewSchedule?.isDone &&
          currentCandidate?.progressSubmit[step]?.interviewSchedule
            ?.interviewDate <= new Date().getTime()) ||
        currentCandidate?.progressSubmit[step]?.isSubmit
          ? 'select.ongoing-interview'
          : 'select.before-proceeding-interview',
    });
    if (currentCandidate.progressSubmit[step]?.interviewHistory) {
      setInterviewHistory(
        currentCandidate.progressSubmit[step].interviewHistory
      );
      currentCandidate.progressSubmit[step]?.interviewHistory.forEach(
        (item, idx) => {
          setInterviewHistoryList((prev) => [
            ...prev,
            {
              key: idx,
              i18nKey: `${dateToDateFormat(
                new Date(item.updatedAt),
                t('lang')
              )} ${t('desc.interview-history')}`,
            },
          ]);
        }
      );
    }
  }, [currentCandidate]);

  const onSelectChangeHandler = (_, menu) => {
    setSelectedHistory(menu);
  };

  const opinionList = [
    { i18nKey: 'tab.step.strong-hire', key: 'Strong' },
    { i18nKey: 'tab.step.kiv', key: 'Shortlist' },
    { i18nKey: 'tab.step.suggest-other-position', key: 'other' },
    { i18nKey: 'tab.step.reject', key: 'Reject' },
  ];

  return (
    <div className="wrapper person-interview-detail">
      {isRetry && (
        <BasicSelect
          menus={interviewHistoryList}
          label={t('desc.interview-history')}
          name={t('desc.interview-history')}
          value={selectedHistory}
          onChange={onSelectChangeHandler}
        />
      )}
      {isSubmit ? (
        <>
          {userInfo?.memberType !== 20 &&
            selectedHistory.key === 'ongoing-interview' &&
            currentCandidate?.recruitProgress === step && (
              <>
                <div className="interview-status-tab">
                  <RecruitmentStatusTab
                    recruitment={recruitment}
                    currentCandidate={currentCandidate}
                    processLength={processLength}
                    isInterview
                  />
                </div>
                <hr
                  style={{
                    width: '100%',
                    height: '1px',
                    background: '#D6DBE0',
                    border: '0',
                  }}
                />
              </>
            )}
          <div className="summary-title">
            <span>{t('title.summary')}</span>
            <div className="interviewer-box">
              <span>
                {t('title.interviewer')}:{' '}
                {selectedHistory.key === 'ongoing-interview'
                  ? `${currentCandidate?.progressSubmit[step]?.interviewer?.lastName} ${currentCandidate?.progressSubmit[step]?.interviewer?.firstName}`
                  : `${
                      currentCandidate?.progressSubmit[step]?.interviewHistory[
                        selectedHistory.key
                      ].interviewer?.lastName
                    } ${
                      currentCandidate?.progressSubmit[step]?.interviewHistory[
                        selectedHistory.key
                      ].interviewer?.firstName
                    }`}
              </span>
              <span>
                {t('title.interviewer-date')}:{' '}
                {selectedHistory.key === 'ongoing-interview'
                  ? dateToDateFormat(
                      new Date(
                        currentCandidate?.progressSubmit[step]?.updatedAt
                      ),
                      t('lang')
                    )
                  : dateToDateFormat(
                      new Date(
                        currentCandidate?.progressSubmit[
                          step
                        ]?.interviewHistory[selectedHistory.key].updatedAt
                      ),
                      t('lang')
                    )}
              </span>
            </div>
          </div>
          <OpinionContainer
            isInterviewer={false}
            opinionList={opinionList}
            opinion={opinion}
            isSubmit
          />
          <SummaryContainer
            comment={comment}
            scoreList={scoreList}
            isSubmit
            isInterviewer={false}
          />
          {standardList.map((item, idx) => (
            <InterviewScore
              key={idx}
              idx={idx}
              standard={item.standard}
              standardDesc={item.standardDesc}
              setScore={undefined}
              scoreList={scoreList}
              score={scoreList[idx] || undefined}
            />
          ))}
          {invitedInterviewer && (
            <InvitedInterviewer
              open={invitedInterviewer}
              close={setInvitedInterviewer}
              recruitment={recruitment}
              currentCandidate={currentCandidate}
              setStatus={setStatus}
              clickStatus="retry"
            />
          )}
        </>
      ) : currentCandidate.progressSubmit[step]?.interviewSchedule ? (
        Object.keys(currentCandidate.progressSubmit[step]?.interviewSchedule)
          .length === 0 ? (
          <div className="no-candidate-container">
            <div className="no-candidate-image-box">
              <NoData />
            </div>
            <div className="no-candidate-warning-box">
              <div className="no-candidate-warning-text">
                {t('desc.not-yet-evaluation')}
              </div>
            </div>
          </div>
        ) : (
          <div className="no-candidate-container">
            <div className="no-candidate-image-box">
              <Calendar />
            </div>
            <div className="no-candidate-warning-box">
              <div className="no-candidate-warning-text">
                {t('desc.sent-interview-date')}
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="no-candidate-container">
          <div className="no-candidate-image-box">
            <NoData />
          </div>
          <div className="no-candidate-warning-box">
            <div className="no-candidate-warning-text">
              {t('desc.not-yet-evaluation')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonInterviewHrDetail;
