import React from 'react';
import {
  POSITION_DEPTH_1,
  POSITION_DEPTH_2,
  POSITION_DEPTH_3,
} from 'constants/recruitments/position';
import RadioGroupBox from './RadioGroupBox';

const RadioContainer = ({
  type,
  firstPositionDepth,
  setFirstPositionDepth,
  secondPositionDepth,
  setSecondPositionDepth,
  thirdPositionDepth,
  setThirdPositionDepth,
}) => {
  return (
    <div className="radio-container">
      <RadioGroupBox
        type={type}
        value={firstPositionDepth}
        setValue={setFirstPositionDepth}
        position={POSITION_DEPTH_1}
      />
      <RadioGroupBox
        type={type}
        value={secondPositionDepth}
        setValue={setSecondPositionDepth}
        position={POSITION_DEPTH_2}
      />
      <RadioGroupBox
        type={type}
        value={thirdPositionDepth}
        setValue={setThirdPositionDepth}
        position={POSITION_DEPTH_3}
      />
    </div>
  );
};

export default RadioContainer;
