import React, { useEffect, useState } from 'react';
import MaterialDateTimePicker from 'components/common/MaterialDateTimePicker';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-modal.svg';
import { Button } from 'components/common/Button';
import { Timestamp } from 'firebase/firestore';
import { dateToDateFormat } from 'utils/string';
import { executeAction } from 'utils/redux';
import { setCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';

const ConfirmInterviewDate = ({
  open,
  close,
  recruitment,
  candidate,
  ouid,
}) => {
  const { t } = useTranslation();
  const step = candidate?.recruitProgress;

  const [confirmDate, setConfirmData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    let flag = true;

    if (!confirmDate) {
      flag = false;
    }

    setCanSubmit(flag);
  }, [confirmDate]);

  const getHoursHandler = (date) => {
    const newDate = new Date(date);
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  };

  const endScheduleSetting = (date) => {
    const { takenTime } = candidate.progressSubmit[step].interviewSchedule;
    let endTime;

    if (takenTime === '30') {
      endTime = date + 1800000;
    }
    if (takenTime === '45') {
      endTime = date + 2700000;
    }
    if (takenTime === '60') {
      endTime = date + 3600000;
    }

    return endTime;
  };

  const onSubmitHandler = () => {
    if (!confirmDate) {
      return toast(t('btn.selected-schedules'));
    }

    setIsLoading(true);

    const { email, firstName, lastName } =
      candidate.progressSubmit[step].interviewer;
    const { interviewPlace } = candidate.progressSubmit[step].interviewSchedule;

    executeAction(
      setCandidateOfRecruitment({
        ouid,
        aid: recruitment?.rid,
        cid: candidate?.email,
        lang: 'ko',
        type: 'confirmDate',
        interviewerEmail: email,
        interviewerFirstName: firstName,
        interviewerLastName: lastName,
        candidateFirstName: candidate?.firstName,
        candidateLastName: candidate?.lastName,
        phoneNumber: candidate?.progressSubmit[1].phoneNumber,
        interviewDate: dateToDateFormat(new Date(confirmDate), t('lang')),
        interviewLocation: interviewPlace,
        interviewTime: `${getHoursHandler(confirmDate)} ~ ${getHoursHandler(
          endScheduleSetting(confirmDate)
        )}`,
        progressSubmit: {
          [step]: {
            interviewSchedule: {
              interviewDate: confirmDate,
              isDone: true,
              submitedAt: Timestamp.now(),
            },
          },
        },
      })
    )
      .then(() => {
        toast('인터뷰가 예약되었습니다!');
        setIsLoading(false);
        close(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        close(false);
      });
  };

  return (
    <Modal open={open}>
      <div className="modal-invited-member modal-confirm-date">
        <section className="invited-member-container">
          <article className="invited-header-wrapper">
            <h3 className="fs16">{t('확정일정선택')}</h3>
            <span onClick={() => close(false)}>
              <CloseIcon />
            </span>
          </article>
          <article className="form-contents-wrapper">
            <aside className="start-date-box">
              <span className="fs14 grey-title">{t('확정일정')}</span>
              <MaterialDateTimePicker
                periodDateTime={confirmDate}
                setPeriodDateTime={setConfirmData}
              />
            </aside>
          </article>
          <article className="invited-button">
            <Button
              theme={'grey'}
              size={56}
              label={t('btn.submit')}
              isDisabled={!canSubmit && true}
              isLoading={isLoading}
              fontSize={'fs16'}
              onClick={!isLoading && canSubmit ? onSubmitHandler : null}
            />
          </article>
        </section>
      </div>
    </Modal>
  );
};

export default ConfirmInterviewDate;
