import React from 'react';
import { useTranslation } from 'react-i18next';

const PositionDetail = ({ position, skillList }) => {
  const { t } = useTranslation();

  return (
    <article className="modals-assessments-position-detail">
      <header className="header">
        <h2 className="header-title fs28">
          {t(position?.display?.[t('lang')])}
        </h2>
      </header>
      <section className="split">
        <section className="split-left">
          <p className="split-left-description fs16">
            {position?.[`desc_${t('lang')}`]}
          </p>
          <ul className="split-left-recommend-list">
            {(position?.skill || []).map((skillIdx, idx) => (
              <li key={idx} className="split-left-recommend-list-item">
                {skillList?.[skillIdx]?.[`title_${t('lang')}`]}
              </li>
            ))}
          </ul>
        </section>
        <section className="split-right">
          <article className="split-right-tbd">
            <h3 className="split-right-tbd-title fs20">TBD</h3>
            <p className="split-right-tbd-description fs16">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </article>
        </section>
      </section>
    </article>
  );
};

export default PositionDetail;
