import React, { useState, useEffect } from 'react';
import BasicInput from 'components/input/BasicInput';
import BasicSelect from 'components/select/BasicSelect';
import { validateEmail } from 'utils/auth';
import { useTranslation } from 'react-i18next';

const CheckedReference = ({ setReference, number, currentProgress }) => {
  const { t } = useTranslation();

  const [notAllow, setNotAllow] = useState(false);
  const [inputValue, setInputValue] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    relative: {
      key: '',
      i18nKey: '',
    },
    // other: '',
  });

  useEffect(() => {
    if (
      !currentProgress?.checkedReference1 ||
      !currentProgress?.checkedReference2
    )
      return;

    if (number === 1) {
      setInputValue((prev) => ({
        ...prev,
        name: currentProgress?.checkedReference1?.name
          ? currentProgress?.checkedReference1?.name
          : '',
        phoneNumber: currentProgress?.checkedReference1?.phoneNumber
          ? currentProgress?.checkedReference1?.phoneNumber
          : '',
        email: currentProgress?.checkedReference1?.email
          ? currentProgress?.checkedReference1?.email
          : '',
        relative:
          Object.keys(currentProgress?.checkedReference1?.relative).length !== 0
            ? currentProgress?.checkedReference1?.relative
            : '',
      }));
    } else if (number === 2) {
      setInputValue((prev) => ({
        ...prev,
        name: currentProgress?.checkedReference2?.name
          ? currentProgress?.checkedReference2?.name
          : '',
        phoneNumber: currentProgress?.checkedReference2?.phoneNumber
          ? currentProgress?.checkedReference2?.phoneNumber
          : '',
        email: currentProgress?.checkedReference2?.email
          ? currentProgress?.checkedReference2?.email
          : '',
        relative:
          Object.keys(currentProgress?.checkedReference2?.relative).length !== 0
            ? currentProgress?.checkedReference2?.relative
            : '',
      }));
    }
  }, [currentProgress]);

  useEffect(() => {
    if (!notAllow) {
      setReference(inputValue, number);
    }

    if (inputValue?.email) {
      if (!validateEmail(inputValue?.email)) {
        setNotAllow(true);
      } else {
        setNotAllow(false);
      }
    }
  }, [inputValue]);

  const handleInputValue = (e, menu, type) => {
    if (type === 'select') {
      setInputValue((prevInput) => ({
        ...prevInput,
        [e]: menu,
      }));

      return;
    }

    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      const number = value.replace(/[^0-9]/g, '');

      setInputValue((prevInput) => ({
        ...prevInput,
        [name]: number,
      }));

      return;
    }

    setInputValue((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
    return;
  };

  return (
    <>
      <div className="flex">
        <BasicInput
          inputType={'text'}
          label={t('text-field.label.name')}
          name={'name'}
          value={inputValue.name}
          onChange={handleInputValue}
        />
        <BasicInput
          inputType={'text'}
          label={t('text-field.label.phone-number')}
          name={'phoneNumber'}
          value={inputValue.phoneNumber}
          onChange={handleInputValue}
        />
      </div>
      <div className="flex">
        <BasicInput
          inputType={'text'}
          label={t('field.email')}
          name={'email'}
          value={inputValue.email}
          onChange={handleInputValue}
          notAllow={notAllow}
        />
        <BasicSelect
          className={'relative-form'}
          label={t('text-field.label.relation-ship')}
          name={'relative'}
          value={inputValue.relative}
          onChange={handleInputValue}
          menus={[
            {
              key: 'colleagues',
              i18nKey: 'menu.input.colleagues',
            },
            {
              key: 'professors',
              i18nKey: 'menu.input.professors',
            },
            // todo: 추후에 처리 필요함
            // {
            //   key: 'other',
            //   i18nKey: 'menu.input.other',
            // },
          ]}
        />
      </div>
      {inputValue?.relative?.key === 'other' && (
        <div className="flex">
          <BasicInput
            inputType={'text'}
            label={t('title.other')}
            name={'other'}
            value={inputValue.other}
            onChange={handleInputValue}
            notAllow={notAllow}
          />
        </div>
      )}
    </>
  );
};

export default CheckedReference;
