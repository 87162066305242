import React from 'react';
import { Trans } from 'react-i18next';

const EmployPayTable = ({ candidate, step, t }) => {
  const { totalFixed } = candidate?.progressSubmit[step];

  const convertAnnualToBasedSalary = (string) => {
    const str = string.replace(/,/g, '');
    const number = Math.round(Number(str) / 12);
    const minusMeal = number - 200000;

    return minusMeal.toLocaleString('ko-KR');
  };

  return (
    <table>
      <caption>Total Fixed Compensation, Location, Total Annual Cash</caption>
      <thead>
        <tr>
          <th colSpan={3}>{t('title.total-fixed-compensation')}</th>
          <th colSpan={4}>{t('title.variable-pay')}</th>
        </tr>
        <tr className="pd-8">
          <th className="short-line">
            <Trans t={t} i18nKey="title.based-salary" />
          </th>
          <th className="short-line">
            <Trans t={t} i18nKey="title.meal-allowance" />
          </th>
          <th>
            <Trans t={t} i18nKey="title.annual" />
          </th>
          <th>{t('title.individual-incentive')}</th>
          <th>{t('title.high-ticket-incentive')}</th>
          <th>{t('title.collective-incentive')}</th>
          <th>{t('title.store-kpi-incentive')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="short-line">{`${convertAnnualToBasedSalary(
            totalFixed.salary
          )} ${totalFixed.currency}`}</td>
          <td className="short-line">{`200,000 ${totalFixed.currency}`}</td>
          <td>{`${totalFixed.salary} ${totalFixed.currency}`}</td>
          <td colSpan={4}>{t('desc.incentive')}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default EmployPayTable;
