import React from 'react';
import {
  convertCareer, convertChannel,
  convertCountry,
  convertFinalEducation,
  convertGender, convertLanguage, convertLanguageLevel,
  convertMajor, convertSubChannel,
} from 'utils/convertKey';
import { dateToDateFormat } from 'utils/string';
import { useTranslation } from 'react-i18next';

export const ReviewingFieldView = ({ currentProgress, email, createdAt, count }) => {
  const { t } = useTranslation();
  const countDuplicateEmails = (count) => {
    if (count > 1) {
      return 'Y';
    }
    return 'N';
  };
  return (
    <ul>
      <li>
        <span className="sub-title fs12">{t('field.mobile-number')}</span>
        <p className="desc fs12">{`${currentProgress?.countryCode} ${currentProgress?.phoneNumber}`}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.email')}</span>
        <p className="desc fs12">{email}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.gender')}</span>
        <p className="desc fs12">
          {t(convertGender(currentProgress?.gender))}
        </p>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.birth')}</span>
        <p className="desc fs12">{currentProgress?.birth}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.other-link')}</span>
        <p className="desc fs12">{currentProgress?.etcLink1 ? `http://${currentProgress?.etcLink1}` : ''}</p>
      </li>
      {/* 국적은 여권상 국적(country) */}
      <li>
        <span className="sub-title fs12">{t('field.nationality')}</span>
        <p className="desc fs12">
          {t(convertCountry(currentProgress?.country))}
        </p>
      </li>
      {/* 지역은 살고 있는 국가(location) */}
      <li>
        <span className="sub-title fs12">{t('field.region')}</span>
        <p className="desc fs12">
          {t(convertCountry(currentProgress?.location))}
        </p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('field.highest-education')}
              </span>
        <p className="desc fs12">
          {t(convertFinalEducation(currentProgress?.finalEducation))}
        </p>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.major')}</span>
        <p className="desc fs12">
          {currentProgress?.major ? t(convertMajor(currentProgress?.major)) : ''}
        </p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.within-3-years')}</span>
        <p className="desc fs12">{countDuplicateEmails(count)}</p>
      </li>
      <li>
            <span className="sub-title fs12">
              {t('field.total-work-experience')}
            </span>
        <p className="desc fs12">
          {t(convertCareer(currentProgress?.career))}
        </p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('field.work-experience-related')}
              </span>
        <p className="desc fs12">
          {currentProgress?.career !== 'none' ? t(convertCareer(currentProgress?.relatedCareer)) : ''}
        </p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('field.previous-employer')}
              </span>
        <p className="desc fs12">{currentProgress?.career !== 'none' ? currentProgress?.companyName : ''}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.available-languages')}</span>
        <p className="desc fs12">
          {currentProgress?.language ? `${t(convertLanguage(currentProgress?.language))} (${t(convertLanguageLevel(currentProgress?.languageLevel))})` : ''}
        </p>
      </li>
      <li/>
      <li>
            <span className="sub-title fs12">
              {t('column.submission-date')}
            </span>
        <p className="desc fs12">
          {dateToDateFormat(new Date(createdAt?.seconds * 1000), t('lang'))}
        </p>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.channel')}</span>
        <p className="desc fs12">
          {t(convertChannel(currentProgress?.channel))}
        </p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('field.channel-detail')}
              </span>
        <p className="desc fs12">
          {t(convertSubChannel(currentProgress))}
        </p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('field.preferred-channel')}
              </span>
        <p className="desc fs12">
          {(currentProgress?.applyChannel || []).map(
            (channel) => `${channel},`
          )}
        </p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('field.preferred-location')}
              </span>
        <p className="desc fs12">
          {(currentProgress?.applyLocation || []).map(
            (location) => `${location},`
          )}
        </p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('text-field.label.recommended-employee-name')}
              </span>
        <p className="desc fs12">{currentProgress?.nameOfEmployee ? currentProgress?.nameOfEmployee : ''}</p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('text-field.label.employee-department')}
              </span>
        <p className="desc fs12">{currentProgress?.nameOfStore ? currentProgress?.nameOfStore : ''}</p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('field.previous-compensation')}
              </span>
        <p className="desc fs12">{currentProgress?.career !== 'none' ? `${currentProgress?.currency} ${currentProgress?.salary}` : ''}</p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('text-field.label.variable-salary')}
              </span>
        <p className="desc fs12">{currentProgress?.variableSalary ? `${currentProgress?.currency} ${currentProgress?.variableSalary}` : ''}</p>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('text-field.label.monetary-compensation')}
              </span>
        <p className="desc fs12">{currentProgress?.monetaryCompensation ? `${currentProgress?.currency} ${currentProgress?.monetaryCompensation}` : ''}</p>
      </li>
    </ul>
  );
};
