import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  fetchSignInMethodsForEmail,
  updatePassword,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
  query,
  where,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { app } from './index';

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export const _createAuthToken = ({ email }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = await addDoc(collection(db, 'authToken'), {
        email,
        createdAt: Timestamp.now(),
      });
      return resolve(docRef.id);
    } catch (e) {
      reject(e);
    }
  });

export const _createUserWithEmailAndPassword = ({ email, password }) =>
  new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const { user } = userCredential;

        return resolve({
          user,
        });
      })
      .catch((e) => {
        const errorCode = e.code;
        const errorMessage = e.message;
        return reject(e);
      });
  });

export const _signInWithEmailAndPassword = ({ email, password }) =>
  new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const { user } = userCredential;

        return resolve({
          user,
        });
      })
      .catch((e) => {
        const errorCode = e.code;
        const errorMessage = e.message;
        return reject(e);
      });
  });

export const _signOut = () =>
  new Promise((resolve, reject) => {
    signOut(auth).then(resolve).catch(reject);
  });

export const _setPassword = ({ authToken, password }) =>
  new Promise(async (resolve, reject) => {
    try {
      const authRef = doc(db, 'authToken', authToken);

      await setDoc(
        authRef,
        { password, updatedAt: Timestamp.now() },
        { merge: true }
      );
      const docSnap = await getDoc(authRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      }
      throw new Error('empty');
    } catch (e) {
      return reject(e);
    }
  });

export const _resetPassword = ({ authToken, password }) =>
  new Promise(async (resolve, reject) => {
    const authRef = doc(db, 'authToken', authToken);
    let _password = '';
    try {
      const { email, password: _password } = await _getInfoFromAuthToken({
        authToken,
      });
      const { user } = await signInWithEmailAndPassword(auth, email, _password);
      await setDoc(
        authRef,
        { password, updatedAt: Timestamp.now() },
        { merge: true }
      );
      await updatePassword(user, password);
      // console.log('done');
      return resolve(true);
    } catch (e) {
      await setDoc(authRef, { password: _password }, { merge: true });
      return reject(e);
    }
  });

export const _getInfoFromAuthToken = ({ authToken }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'authToken', authToken);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return resolve(docSnap.data());
      }
      throw new Error('empty');
    } catch (e) {
      return reject(e);
    }
  });

export const _deleteAuthTokenDoc = ({ authToken }) =>
  new Promise(async (resolve, reject) => {
    try {
      return resolve(await deleteDoc(doc(db, 'authToken', authToken)));
    } catch (e) {
      return reject(e);
    }
  });

export const _uploadCorporationLogo = ({ uid, file }) =>
  new Promise(async (resolve, reject) => {
    try {
      const storageRef = ref(storage, `corporation/${uid}/${file?.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
              break;
            case 'storage/canceled':
              break;
            case 'storage/unknown':
              break;
          }
          throw error;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
            resolve(downloadURL)
          );
        }
      );
    } catch (e) {
      return reject(e);
    }
  });

export const _createUserInfo = ({
  uid,
  authToken,
  allowanceReceipt,
  email,
  firstName,
  lastName,
  corporation,
  position,
  numEmployee,
  trackingSystem,
  logoURL,
  account,
  enabledSkill_en,
  enabledSkill_ko,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);
      authToken = authToken || '';
      email = email || '';
      firstName = firstName || '';
      lastName = lastName || '';
      corporation = corporation || '';
      position = position || '';
      numEmployee = numEmployee || '';
      trackingSystem = trackingSystem || '';
      logoURL = logoURL || '';
      allowanceReceipt = allowanceReceipt || false;
      account = account || '';
      enabledSkill_en = enabledSkill_en || ['all'];
      enabledSkill_ko = enabledSkill_ko || ['all'];

      await setDoc(docRef, {
        authToken,
        email,
        firstName,
        lastName,
        corporation,
        position,
        numEmployee,
        trackingSystem,
        logoURL,
        allowanceReceipt,
        account,
        enabledSkill_en,
        enabledSkill_ko,
      });
      return resolve(uid);
    } catch (e) {
      return reject(e);
    }
  });

export const _getUserInfo = ({ uid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      }
      throw new Error('empty');
    } catch (e) {
      return reject(e);
    }
  });

export const _isAccountWithEmail = ({ email }) =>
  new Promise(async (resolve, reject) => {
    try {
      const providers = await fetchSignInMethodsForEmail(auth, email);
      if (providers.length === 0) {
        return resolve(false);
      }
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getAuthTokenFromEmail = ({ email }) =>
  new Promise(async (resolve, reject) => {
    try {
      const q = query(collection(db, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(q);
      let authToken = null;
      querySnapshot.forEach((doc) => {
        authToken = doc.data()?.authToken;
      });
      return resolve(authToken);
    } catch (e) {
      return reject(e);
    }
  });

export const _isAvailableAuthToken = ({ authToken }) =>
  new Promise(async (resolve, reject) => {
    try {
      const { createdAt, updatedAt } = await _getInfoFromAuthToken({
        authToken,
      });
      const tokenDate = updatedAt?.toDate?.() || createdAt?.toDate?.();
      const curDate = new Date();

      if (!tokenDate) {
        throw new Error('no_date');
      }
      if (curDate - tokenDate > 24 * 60 * 60 * 1000) {
        throw new Error('expired_token');
      }

      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _createAllowanceReceipt = ({ authToken, allowanceReceipt }) =>
  new Promise(async (resolve, reject) => {
    try {
      allowanceReceipt = allowanceReceipt || false;
      const docRef = doc(db, 'allowanceReceipt', authToken);
      await setDoc(
        docRef,
        {
          allowanceReceipt,
        },
        { merge: true }
      );
      return resolve(authToken);
    } catch (e) {
      return reject(e);
    }
  });

export const _getAllowanceReceipt = ({ authToken }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'allowanceReceipt', authToken);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve(docSnap.data());
      }
      return resolve(false);
      throw new Error('empty');
    } catch (e) {
      return reject(e);
    }
  });

export const _deleteAllowanceReceipt = ({ authToken }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'allowanceReceipt', authToken);
      await deleteDoc(docRef);
      return resolve(true);
    } catch (e) {
      return resolve(false);
    }
  });

export const _refreshAuthToken = ({ authToken }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'authToken', authToken);
      await setDoc(docRef, { updatedAt: Timestamp.now() }, { merge: true });
      return resolve(true);
    } catch (e) {
      return resolve(false);
    }
  });

export const _deleteAuthToken = ({ authToken }) =>
  new Promise(async (resolve, reject) => {
    try {
      return resolve(await deleteDoc(doc(db, 'authToken', authToken)));
    } catch (e) {
      return reject(e);
    }
  });

export const _updatePassword = ({ authToken, password }) =>
  new Promise(async (resolve, reject) => {
    try {
      const batch = writeBatch(db);

      const authTokenRef = doc(db, 'authToken', authToken);
      batch.update(authTokenRef, {
        password,
        updatedAt: Timestamp.now(),
      });
      await updatePassword(auth.currentUser, password);
      await batch.commit();

      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateUserInfo = ({ uid, data }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);
      await setDoc(
        docRef,
        {
          ...data,
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateUserLimit = ({ uid, data }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);
      await setDoc(
        docRef,
        {
          ...data,
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateCompanyInfoWithoutLogo = ({ uid, corporation }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);
      await setDoc(
        docRef,
        {
          corporation,
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _uploadCompanyLogo = ({ uid, logo }) =>
  new Promise(async (resolve, reject) => {
    try {
      // How to transact?
      const storageRef = ref(storage, `users/${uid}/companyLogo`);
      const uploadTask = uploadBytesResumable(storageRef, logo, {
        contentType: logo?.type,
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => reject(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
            resolve(downloadURL)
          );
        }
      );
    } catch (e) {
      return reject(e);
    }
  });

export const _updateCompanyInfoWithLogo = ({ uid, logoURL, corporation }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);
      await setDoc(
        docRef,
        {
          logoURL,
          corporation,
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });
