import React, { useRef, useState } from 'react';
import { ReactComponent as LogoFullMain } from 'assets/images/logo/logo_full_main.svg';
import { ReactComponent as EarthIcon } from 'assets/images/icon/earth.svg';
import { ReactComponent as LogoFullAppHeader } from 'assets/images/logo/logo_full_app_header.svg';
import { useOnClickOutside } from 'utils/hooks';
import { setLang } from 'store/slices/variableSlice';
import { useTranslation } from 'react-i18next';
import { supportedLangs } from 'i18n/index';
import { executeAction } from 'utils/redux';

const ApplyHeader = () => {
  const { t } = useTranslation();

  const logoURL = null;
  const dropdownRef = useRef(null);
  const [visibilityDropdown, setVisibilityDropdown] = useState(false);

  useOnClickOutside(dropdownRef, () => setVisibilityDropdown(false));

  const langSelectedHandler = (key) => {
    executeAction(setLang(key));
    setVisibilityDropdown(false);
  };

  return (
    <header className="components-header-apply-header">
      {logoURL ? (
        <img src="assets/images/logo/logo_full_main.svg" alt="logo" />
      ) : (
        <LogoFullAppHeader />
      )}

      <article ref={dropdownRef} className="language-selector">
        <div
          className={`language-selector-toggle ${visibilityDropdown && 'show'}`}
          onClick={() => setVisibilityDropdown(!visibilityDropdown)}
        >
          <EarthIcon />
          {window.localStorage.getItem('i18nextLng') && t(
            `label.lang-${window.localStorage
              .getItem('i18nextLng')
              .slice(0, 2)}`
          )}
        </div>
        {visibilityDropdown && (
          <div className="language-selector-dropdown">
            <ul className="language-selector-dropdown-list">
              {(supportedLangs || []).map((lang, idx) => (
                <li
                  key={lang.key}
                  className={`language-selector-dropdown-list-item ${lang.key}`}
                  onClick={() => langSelectedHandler(lang.key)}
                >
                  {t(`label.lang-${lang.key}`)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </article>
    </header>
  );
};

export default ApplyHeader;
