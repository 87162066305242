import React from 'react';
import {
  UNSAFE_NavigationContext as NavigationContext,
  useLocation,
} from 'react-router-dom';
import { useModal } from 'utils/modal';
import { isSameLowerCaseString, convertNoGap } from 'utils/string';
import { useTranslation } from 'react-i18next';

export const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    window.document.addEventListener('mousedown', listener);
    window.document.addEventListener('touchstart', listener);

    return () => {
      window.document.removeEventListener('mousedown', listener);
      window.document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useBlocker = (blocker, when = true) => {
  const { navigator } = React.useContext(NavigationContext);

  React.useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [navigator, blocker, when]);
};

export const usePrompt = (modalPath, when = true) => {
  const { displayModal } = useModal();
  const blocker = React.useCallback(
    (tx) => {
      if (tx?.location?.state?.message === 'createAssessmentDone') {
        tx.retry();
        return;
      }
      const onEventHandler = (e) => {
        if (e.detail?.message === 'dismiss') tx.retry();
        window.document.removeEventListener('dismissModal', onEventHandler);
      };
      window.document.addEventListener('dismissModal', onEventHandler);
      displayModal(modalPath, null, {
        frame: 'part',
        hasButton: true,
        hasCloseButton: false,
      });
    },
    [modalPath]
  );

  useBlocker(blocker, when);
};

export const useSearch = (search, list) => {
  const [newList, setNewList] = React.useState(list);

  React.useEffect(() => {
    if (!list.length) {
      return setNewList(list);
    }

    setNewList(
      (list || []).filter((it) =>
        convertNoGap(it.title)
          ?.toLowerCase?.()
          ?.includes(convertNoGap(search)?.toLowerCase?.())
      )
    );
  }, [search, list]);

  return newList;
};

export const useFilterFromTestType = (type, list) => {
  const [newList, setNewList] = React.useState(list);

  React.useEffect(() => {
    if (!type) {
      return setNewList(list);
    }

    setNewList(
      (list || []).filter((it) => isSameLowerCaseString(it.type, type))
    );
  }, [type, list]);

  return newList;
};

export const useFilterInCreateStep1 = (type, search, list) => {
  const { t } = useTranslation();
  const [newList, setNewList] = React.useState(list);

  React.useEffect(() => {
    if (Boolean(type) && Boolean(search)) {
      const filtered = (list || []).filter((it) =>
        isSameLowerCaseString(it.type, type?.key)
      );

      setNewList(
        (filtered || []).filter((it) =>
          convertNoGap(it?.[`title_${t('en')}`])
            ?.toLowerCase?.()
            ?.includes(convertNoGap(search)?.toLowerCase?.())
        )
      );
    } else if (type) {
      setNewList(
        (list || []).filter((it) => isSameLowerCaseString(it.type, type?.key))
      );
    } else if (search) {
      setNewList(
        (list || []).filter((it) =>
          convertNoGap(it?.[`title_${t('en')}`])
            ?.toLowerCase?.()
            ?.includes(convertNoGap(search)?.toLowerCase?.())
        )
      );
    } else {
      setNewList(list);
    }
  }, [type, search]);

  return newList;
};

export const useLoading = (callback) => {
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (isLoading) {
      callback && callback();
    }
  }, [callback, isLoading]);

  return [isLoading, setIsLoading];
};

export const usePrevState = (state) => {
  const ref = React.useRef(state);

  React.useEffect(() => {
    ref.current = state;
  }, [state]);
  return ref.current;
};

/** ==============================
 * @function ExtractFirstEmailChar
 * 단어의 첫번째 문자만 추출하여 대문자로 변환하는 함수
 * @param {*} word(string) 
 * @returns 
================================= */
export const ExtractFirstEmailChar = (word) => {
  if (word !== undefined) {
    const firstChar = word.slice(0, 1);
    const upperCase = firstChar.toUpperCase();

    return upperCase;
  }
  return '';
};

/** ============================
 * @function useCheckedAdminPage
 * url에 admin이 존재 하는지 확인
================================ */
export const useCheckedAdminPage = () => {
  const location = useLocation();
  const [checkedAdmin, setCheckedAdmin] = React.useState(false);

  React.useEffect(() => {
    const path = location?.pathname;
    const getAdminText = path.split('/').filter((item) => item !== '');

    if (getAdminText[0] === 'admin') {
      setCheckedAdmin(true);
    } else {
      setCheckedAdmin(false);
    }
  }, []);

  return checkedAdmin;
};

export const useFilterOfRecruitmentProcess = (recruitment) => {
  const [processSetting, setProcessSetting] = React.useState({});
  const [processLength, setProcessLength] = React.useState(0);

  React.useEffect(() => {
    if (!recruitment || Object.keys(recruitment).length === 0) return;

    const usedProcess = Object.entries(recruitment?.processSetting)
      .sort((a, b) => {
        const order = [
          'application',
          'aiInterview',
          'firstInterview',
          'secondInterview',
          'evidence',
          'pass',
          'submission',
          'reject',
        ];

        return order.indexOf(a[0]) - order.indexOf(b[0]);
      })
      .reduce((acc, [key, value]) => {
        if (value.isUsed) {
          acc[key] = value;
        }
        return acc;
      }, {});
    setProcessLength(Object.keys(usedProcess).length);
    setProcessSetting(usedProcess);
  }, [recruitment]);

  return [processSetting, processLength];
};

export const useLocalDateTime = () => {
  const [selectedCountry, setSelectedCountry] = React.useState(''); // Save as selected country
  const [utcDate, setUtcDate] = React.useState(''); // Save as selected dateTime(UTC)

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    const timeZone = getCountryTimeZone(event.target.value);
    const currentDate = new Date();
    const options = {
      timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
      currentDate
    );
    setUtcDate(formattedDate);
  };

  const getCountryTimeZone = (country) => {
    switch (country) {
      case 'USA':
        return 'America/New_York';
      case 'Korea':
        return 'Asia/Seoul';
      // 다른 국가들에 대한 시간대 정보도 추가 가능
      default:
        return 'UTC'; // default UTC
    }
  };

  return [utcDate, selectedCountry, handleCountryChange];
};

export const useAverageScore = (arr) => {
  if (arr.length === 0) return;

  const average = arr.reduce((a, b) => a + b, 0) / arr.length;
  return average;
};
