import { ReactComponent as GradeA } from 'assets/images/icon/GradeA.svg';
import { ReactComponent as GradeAplus } from 'assets/images/icon/GradeAplus.svg';
import { ReactComponent as GradeB } from 'assets/images/icon/GradeB.svg';
import { ReactComponent as GradeBplus } from 'assets/images/icon/GradeBplus.svg';
import { ReactComponent as GradeC } from 'assets/images/icon/GradeC.svg';
import { ReactComponent as GradeCplus } from 'assets/images/icon/GradeCplus.svg';
import { ReactComponent as GradeD } from 'assets/images/icon/GradeD.svg';
import { ReactComponent as GradeS } from 'assets/images/icon/GradeS.svg';

export const ORGANIZATIONAL_LIST = [
  {
    title: 'title.organizational-element-a',
    desc: 'desc.organizational-element-a',
    score: 'aggression',
  },
  {
    title: 'title.organizational-element-b',
    desc: 'desc.organizational-element-b',
    score: 'impulsiveness',
  },
  {
    title: 'title.organizational-element-c',
    desc: 'desc.organizational-element-c',
    score: 'interest_desire',
  },
  {
    title: 'title.organizational-element-d',
    desc: 'desc.organizational-element-d',
    score: 'indolence',
  },
  {
    title: 'title.organizational-element-e',
    desc: 'desc.organizational-element-e',
    score: 'dependence',
  },
  {
    title: 'title.organizational-element-f',
    desc: 'desc.organizational-element-f',
    score: 'self_downing',
  },
  {
    title: 'title.organizational-element-g',
    desc: 'desc.organizational-element-g',
    score: 'obsession',
  },
  {
    title: 'title.organizational-element-h',
    desc: 'desc.organizational-element-h',
    score: 'contempt_others',
  },
  {
    title: 'title.organizational-element-i',
    desc: 'desc.organizational-element-i',
    score: 'isolation',
  },
  {
    title: 'title.organizational-element-j',
    desc: 'desc.organizational-element-j',
    score: 'rigor',
  },
  {
    title: 'title.organizational-element-k',
    desc: 'desc.organizational-element-k',
    score: 'immorality',
  },
  {
    title: 'title.organizational-element-l',
    desc: 'desc.organizational-element-l',
    score: 'noncooperation',
  },
];

export const PROPENSITY_LIST = [
  {
    title: 'title.propensity-element-a',
    desc: 'desc.propensity-element-a',
    score: 'boundary_line',
    boundary: 70,
  },
  {
    title: 'title.propensity-element-b',
    desc: 'desc.propensity-element-b',
    score: 'antisociality',
    boundary: 50,
  },
  {
    title: 'title.propensity-element-c',
    desc: 'desc.propensity-element-c',
    score: 'anxiety',
    boundary: 75,
  },
  {
    title: 'title.propensity-element-d',
    desc: 'desc.propensity-element-d',
    score: 'gloom',
    boundary: 60,
  },
  {
    title: 'title.propensity-element-e',
    desc: 'desc.propensity-element-e',
    score: 'positive_impression',
    boundary: 90,
  },
];

export const getKHAI_P_Grade = (category, KHAIPResult) => {
  if (KHAIPResult === undefined) return;
  const subcategory = KHAIPResult?.[category];
  let totalscore = 0;
  let average_score = 0;

  if (category === 'total') {
    average_score = KHAIPResult?.[category]?.score;
  } else {
    average_score = getKHAI_P_AverageScore(category, KHAIPResult);
  }
  if (average_score >= 95.2) {
    return <GradeS style={{ width: '100%', height: '100%' }} />;
  }
  if (average_score >= 92.1) {
    return <GradeAplus style={{ width: '100%', height: '100%' }} />;
  }
  if (average_score >= 85.06) {
    return <GradeA style={{ width: '100%', height: '100%' }} />;
  }
  if (average_score >= 79.84) {
    return <GradeBplus style={{ width: '100%', height: '100%' }} />;
  }
  if (average_score >= 76.34) {
    return <GradeB style={{ width: '100%', height: '100%' }} />;
  }
  if (average_score >= 72.42) {
    return <GradeCplus style={{ width: '100%', height: '100%' }} />;
  }
  if (average_score >= 70.12) {
    return <GradeC style={{ width: '100%', height: '100%' }} />;
  }
  return <GradeD style={{ width: '100%', height: '100%' }} />;
};

export const getKHAI_P_AverageScore = (category, KHAIPResult) => {
  if (KHAIPResult === undefined) return;
  const subcategory = KHAIPResult?.[category];
  let totalscore = 0;
  let average_score = 0;

  Object.keys(subcategory)?.forEach((sub) => {
    totalscore += subcategory?.[sub]?.myscore;
  });
  if (Object.keys(subcategory)?.length > 0) {
    average_score = totalscore / Object.keys(subcategory).length;
  }

  return average_score;
};

export const getKHAI_P_BigDataAverageScore = (category, KHAIPResult) => {
  if (KHAIPResult === undefined) return;
  const subcategory = KHAIPResult?.[category];
  let totalscore = 0;
  let average_score = 0;

  Object.keys(subcategory)?.forEach((sub) => {
    totalscore += subcategory?.[sub]?.bigdatascore;
  });
  if (Object.keys(subcategory)?.length > 0) {
    average_score = totalscore / Object.keys(subcategory).length;
  }

  return average_score;
};

export const getKHAIPColor = (socre) => {
  if (socre >= 93) {
    return 'blue600';
  }
  if (socre >= 85) {
    return 'blue500';
  }
  if (socre >= 75) {
    return 'orange500';
  }
  if (socre >= 70) {
    return 'red500';
  }
  return 'red700';
};

export const getKHAIPReliability = (KHAIPResult, t, font_class) => {
  if (KHAIPResult === undefined) return;
  if (KHAIPResult?.total?.response_reliability === 'normal') {
    return (
      <div className={`desc ${font_class} blue500`}>{t('desc.suitable')}</div>
    );
  }
  if (KHAIPResult?.total?.response_reliability === 'caution') {
    return (
      <div className={`desc ${font_class} orange500`}>{t('desc.caution')}</div>
    );
  }
  return (
    <div className={`desc ${font_class} red500`}>{t('desc.unsuitable')}</div>
  );
};

export const getKHAI_P_BigDataRank = (bigdataLevel) => {
  var symbol = bigdataLevel.substring(0, 1);
  var score = bigdataLevel.substring(1);

  var ret = '-';
  if (symbol && score) {
    if (symbol === '+') {
      ret = `상위 ${score}%`;
    } else if (symbol === '-') {
      ret = `하위 ${score}%`;
    }
  }

  return ret;
};

export const getKhaiO_Reliability = (
  reliability,
  fsize,
  classname,
  Reliability_type,
  t
) => {
  var fontcolor = 'grey500';
  var text = '-';
  var fontweight = '500';
  switch (reliability) {
    case 'normal':
      fontcolor = 'blue500';
      if (Reliability_type === 'response_reliability') text = t('desc.normal');
      else text = t('desc.suitable');
      fontweight = 700;
      break;
    case 'caution':
      fontcolor = 'orange500';
      text = t('desc.caution');
      fontweight = 700;
      break;
    case 'incongruity':
      fontcolor = 'red500';
      text = t('desc.unsuitable');
      fontweight = 700;
      break;
  }

  return (
    <div
      className={`${classname} ${fsize} ${fontcolor}`}
      style={{ fontWeight: fontweight }}
    >
      {text}
    </div>
  );
};

export const getKhaiO_organization_color = (score) => {
  if (score < 70.0) {
    return 'blue500';
  }
  if (score < 80.0) {
    return 'orange500';
  }
  if (score >= 80.0) {
    return 'red500';
  }
  return '';
};
