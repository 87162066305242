export const channelGucciList = [
  {
    title: 'survey.preferred-channel',
    section: [
      {
        key: 'survey.flagship',
        i18nKey: 'survey.flagship',
      },
      {
        key: 'survey.outlet',
        i18nKey: 'survey.outlet',
      },
      {
        key: 'survey.duty-free-shops',
        i18nKey: 'survey.duty-free-shops',
      },
    ],
  },
];

export const areaGucciList = [
  {
    title: 'survey.preferred-location',
    section: [
      {
        key: 'survey.seoul',
        i18nKey: 'survey.seoul',
      },
      {
        key: 'survey.gyeonggi',
        i18nKey: 'survey.gyeonggi',
      },
      {
        key: 'survey.yeoju',
        i18nKey: 'survey.yeoju',
      },
      {
        key: 'survey.incheon',
        i18nKey: 'survey.incheon',
      },
      {
        key: 'survey.daejeon',
        i18nKey: 'survey.daejeon',
      },
      {
        key: 'survey.gwangju',
        i18nKey: 'survey.gwangju',
      },
      {
        key: 'survey.daegu',
        i18nKey: 'survey.daegu',
      },
      {
        key: 'survey.busan',
        i18nKey: 'survey.busan',
      },
      {
        key: 'survey.ulsan',
        i18nKey: 'survey.ulsan',
      },
    ],
  },
];
