import React, { useState, useEffect, useRef } from 'react';
import { useOnClickOutside } from 'utils/hooks';
import { ReactComponent as ChevronBottomIcon } from 'assets/images/icon/chevron-bottom-dropdown.svg';
import { useTranslation } from 'react-i18next';

const SelectAndSearchInput = ({
  value,
  setValue,
  icon,
  placeHolder,
  disabled,
  menus,
  selectValue,
  setSelectValue,
  onClick,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [visibilityDropdown, setVisibilityDropdown] = useState(false);
  const [dropdownAnimation, setDropdownAnimation] = useState(false);
  const [repeat, setRepeat] = useState(null);

  useOnClickOutside(containerRef, () => setVisibilityDropdown(false));

  useEffect(() => {
    if (visibilityDropdown) {
      clearTimeout(repeat);
      setDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setDropdownAnimation(false), 150));
    }
  }, [visibilityDropdown]);

  useEffect(() => {
    if (!selectValue) {
      setSelectValue(menus[0]);
    }
  }, []);

  const onClickToggleButton = () => {
    setVisibilityDropdown(!visibilityDropdown);
  };

  const onClickSelectedMenu = (menu) => {
    setSelectValue(menu);
    setVisibilityDropdown(false);
  };

  const onChangeInputText = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  return (
    <div className="recruitment-select-input-component" ref={containerRef}>
      <div className="search-select-wrapper">
        <div
          className={`search-select-text ${visibilityDropdown} ${
            disabled && 'disabled'
          }`}
          onClick={!disabled ? onClickToggleButton : null}
        >
          <span>{t(selectValue.i18nKey)}</span>
          <ChevronBottomIcon />
        </div>
        {dropdownAnimation && (
          <div className="dropdown-box">
            <ul
              className={`search-select-box ${
                visibilityDropdown ? 'slide-in' : 'slide-out'
              }`}
            >
              {menus &&
                (menus || []).map((menu) => (
                  <li key={menu.key} onClick={() => onClickSelectedMenu(menu)}>
                    {t(menu.i18nKey)}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
      <input
        type="text"
        value={value}
        onChange={onChangeInputText}
        placeholder={placeHolder}
        className="recruitment-input fs14"
      />
      <span className="search-icon" onClick={onClick}>
        {icon && <i className="icon">{icon}</i>}
      </span>
    </div>
  );
};

export default SelectAndSearchInput;
