import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { useModal } from 'utils/modal';
import { useTranslation } from 'react-i18next';
import { convertHtmlForDisplay } from 'utils/string';
import { CircularProgress, TextareaAutosize, Radio } from '@mui/material';
import { executeAction } from 'utils/redux';
import { getCandidateInAssessment } from 'store/slices/candidatesSlice';
import { gradeResult } from 'store/slices/assessmentsSlice';
import { MultipleEstimateScore } from 'components/progress/Score';

const MultipleChoice = ({ aid, cid, test, setRefreshing }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();
  const [isLoading, setIsLoading] = React.useState(false);
  const [candidate, setCandidate] = React.useState(null);
  const [answer, setAnswer] = React.useState(null);
  const [score, setScore] = React.useState(0);
  const [comment, setComment] = React.useState('');

  React.useEffect(() => {
    if (!aid || !cid) return;

    executeAction(
      getCandidateInAssessment({
        aid,
        cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setCandidate(doc);
        setAnswer(doc?.results?.[test?.uuid || test?.id]);
        setScore(doc?.results?.[test?.uuid || test?.id]?.score);
        setComment(doc?.results?.[test?.uuid || test?.id]?.comment);
      })
      .catch((e) => {
        console.dir(e);
        setCandidate(null);
        setAnswer(null);
      });
  }, [aid, cid]);

  const onDismissHandler = () => {
    dismissModal();
  };

  const onSubmitHandler = () => {
    setIsLoading(true);

    executeAction(
      gradeResult({
        aid,
        cid,
        tuuid: test?.uuid || test?.id,
        score,
        comment,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setRefreshing(true);
        dismissModal();
      })
      .catch((e) => {
        console.dir(e);
        setIsLoading(false);
      });
  };

  return (
    <article className="modals-assessments-view-answer-multiple-choice">
      <header className="header">
        <h3 className="header-title fs20">
          {t('title.view-custom-question-answer')}
        </h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <div className="form">
        <section className="question">
          <h4 className="question-title fs18">{t('title.question')}</h4>
          <div className="question-description fs16">
            <p
              dangerouslySetInnerHTML={{
                __html: convertHtmlForDisplay(test?.question),
              }}
            />
          </div>
          {test?.videoUrl !== '' && (
            <div className="video">
              <video
                className="video-question"
                src={`${process.env.REACT_APP_MEDIA_URL}/${test?.videoUrl}`}
                controls
                playsInline
                autoPlay
              />
            </div>
          )}
        </section>
        <section className="answer">
          <h4 className="answer-title fs18">{t('title.answer')}</h4>
          <div className="answer-description multiple-choice">
            <ul className="answer-description-list">
              {(test?.answers?.list || []).map((item, idx) => (
                <li
                  key={idx}
                  className={`answer-description-list-item ${
                    Object.values(answer?.checks || {}).findIndex(
                      (x) => Number(x) === idx
                    ) >= 0 && 'selected'
                  } ${
                    (test?.answers?.correct || []).findIndex(
                      (x) => Number(x) === idx
                    ) >= 0 && 'answer'
                  }`}
                >
                  <div className="container">
                    <Radio
                      className="radio-button"
                      checked={
                        Object.values(answer?.checks || {}).findIndex(
                          (x) => Number(x) === idx
                        ) >= 0
                      }
                      disabled
                    />
                    <div className="text">{item}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className="currect">
          <h4 className="answer-title fs18">{t('title.correct-answer')}</h4>
          <div className="answer-description multiple-choice">
            <ul className="answer-description-list">
              <li className="answer-description-list-item selected">
                <div className="container">
                  <Radio className="radio-button" checked disabled />
                  <div className="text">
                    {test?.answers?.list[test?.answers?.correct]}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section className="estimate">
          <h4 className="estimate-title fs18">{t('title.rate-answer')}</h4>
          <div className="estimate-score-flex">
            <div className="estimate-score">
              <MultipleEstimateScore
                score={test?.answers.correct[0] !== answer?.checks[0] ? 0 : 1}
              />
            </div>
            <div className="estimate-score-number">
              <div className="user-score">
                <span className="score-title">
                  {t('title.multiple-choice-answer')}:
                </span>
                <span className="score-number">
                  {test?.answers.correct[0] !== answer?.checks[0] ? 'X' : 'O'}
                </span>
              </div>
            </div>
          </div>
          <div className="estimate-comment">
            <TextareaAutosize
              placeholder={t('title.comment')}
              onChange={(e) => setComment(e.target.value)}
              defaultValue={comment}
              className="fs16"
            />
          </div>
        </section>
        <button
          className={`submit common-button fsbtn16 primary ${
            !isLoading > 0 && 'active'
          } ${isLoading && 'pending'}`}
          onClick={!isLoading > 0 ? onSubmitHandler : null}
        >
          {t('btn.save')}
          {isLoading && <CircularProgress color="grey" size={25} />}
        </button>
      </div>
    </article>
  );
};

export default MultipleChoice;
