import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const __TABS__ = [
  {
    key: 'account',
    i18nKey: 'tab.account',
    onClick: ({ setCurTab }) => {
      setCurTab('account');
    },
  },
  {
    key: 'company',
    i18nKey: 'tab.company',
    onClick: ({ setCurTab }) => {
      setCurTab('company');
    },
  },
  {
    key: 'members',
    i18nKey: 'tab.members',
    onClick: ({ setCurTab }) => {
      setCurTab('members');
    },
  },
];

const Profile = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  const [curTab, setCurTab] = useState('account');
  const [isShowMemberTab, setIsShowMemberTab] = useState(false);
  const [isShowCompanyTab, setIsShowCompanyTab] = useState(false);

  useEffect(() => {
    if (!userInfo || Object.keys(userInfo).length === 0) return;

    if (userInfo?.memberType === 1 || userInfo?.memberType === undefined) {
      setIsShowCompanyTab(true);
    }

    if (userInfo?.memberType <= 10) {
      setIsShowMemberTab(true);
    }
  }, [userInfo]);

  useEffect(() => {
    switch (curTab) {
      case 'account':
        navigate('');
        return;
      case 'company':
        navigate('company');
        return;
      case 'members':
        navigate('members');
        return;
    }
  }, [curTab]);

  return (
    <main className="pages-protected-profile">
      <div className="container">
        <h1 className="title fs28">{t('header.menu.settings')}</h1>
        <nav className="tabs">
          <ul className="tabs-list">
            {__TABS__.map((tab, idx) => {
              if (tab.key === 'members' && !isShowMemberTab) {
                return null;
              }

              if (tab.key === 'company' && !isShowCompanyTab) {
                return null;
              }

              return (
                <li
                  key={`tabs_${idx}`}
                  className={`tabs-list-item ${
                    curTab === tab.key && 'selected'
                  }`}
                >
                  <div
                    className="container"
                    onClick={() => tab.onClick({ setCurTab })}
                  >
                    <span className="fs18">{t(tab?.i18nKey)}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </nav>
        <Outlet />
      </div>
    </main>
  );
};

Profile.propTypes = {};

export default Profile;
