import React from 'react';
import 'styles/app.scss';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from 'config/theme';
import AppLayout from 'components/layout/AppLayout';
import AuthLayout from 'components/layout/AuthLayout';
import PrintLayout from 'components/layout/PrintLayout';
import ApplyLayout from 'components/layout/ApplyLayout';
import OutlookRouter from 'pages/interview/OutlookRouter';
import {
  PublicRouter,
  AuthRouter,
  ProtectedRouter,
  Home,
  Sub,
  Signin,
  Signup,
  ProcessEmail,
  ProcessInfo,
  ProcessThumbnail,
  SetPassword,
  ExpireAuthToken,
  MyPage,
  Active,
  Archived,
  Create,
  AssessmentDetail,
  AssessmentDetailContent,
  AssessmentDetailInvite,
  AssessmentDetailEdit,
  AssessmentDetailConfigure,
  AssessmentStyling,
  CandidatesList,
  CandidatesDetail,
  CandidatePrint,
  TestsList,
  TestsDetail,
  ProfileAccount,
  ProfileCompany,
  ProfileMembers,
  Statistic,
  FrontIframe,
  Agree,
  VideoScoring,
  GucciList,
  MemberSignup,
  Information,
  Recruitments,
  Dashboard,
  DashboardDetail,
  Reviewing,
  VirtualInterview,
  Interview,
  Apply,
  CompletedApply,
  ApplyRouter,
  ExpiredApply,
  InvalidApply,
  Evidence,
  Offer,
  Onboarding,
  Reject,
  DateRouter,
  SelectedDate,
  CompletedDate,
  CompensationRouter,
  CompensationForm,
  CompletedCompensation,
  OfferRouter,
  OfferLetter,
  CompletedOffer,
  RejectedOffer,
  CandidateList,
} from 'pages/index';

import { AuthProvider, RequireAuth } from 'utils/auth';
import { ModalProvider } from 'utils/modal';
import { StatusProvider } from 'utils/status';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import ModalSelector from 'components/modal/ModalSelector';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-blue500.svg';
import { Sentry } from './pages';

const Assessments = React.lazy(() => import('pages/protected/Assessments'));
const Candidates = React.lazy(() => import('pages/protected/Candidates'));
const Tests = React.lazy(() => import('pages/protected/Tests'));
const Profile = React.lazy(() => import('pages/protected/Profile'));

const App = () => {
  const [requireAuth, setRequireAuth] = React.useState(null);

  return (
    <ThemeProvider theme={muiTheme}>
      <ModalProvider>
        <AuthProvider>
          <StatusProvider>
            <CSSTransition in={requireAuth} timeout={500}>
              <React.Suspense fallback={<div />}>
                <Routes>
                  <Route path="/front-iframe" element={<FrontIframe />} />
                  <Route element={<AppLayout />}>
                    <Route path="/" element={<PublicRouter />}>
                      <Route path="admin">
                        <Route
                          path="scoring"
                          element={
                            <RequireAuth
                              requireAuth={requireAuth}
                              setRequireAuth={setRequireAuth}
                            >
                              <VideoScoring />
                            </RequireAuth>
                          }
                        />
                        <Route path="customer" element={<ProtectedRouter />}>
                          <Route
                            path="assessments"
                            element={
                              <RequireAuth
                                requireAuth={requireAuth}
                                setRequireAuth={setRequireAuth}
                              >
                                <Assessments />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path="assessments/:aid"
                            element={
                              <RequireAuth
                                requireAuth={requireAuth}
                                setRequireAuth={setRequireAuth}
                              >
                                <AssessmentDetail />
                              </RequireAuth>
                            }
                          >
                            <Route
                              path=""
                              element={<AssessmentDetailContent />}
                            />
                          </Route>
                          <Route
                            path="candidates"
                            element={
                              <RequireAuth
                                requireAuth={requireAuth}
                                setRequireAuth={setRequireAuth}
                              >
                                <Candidates />
                              </RequireAuth>
                            }
                          >
                            <Route
                              path=""
                              element={
                                <RequireAuth
                                  requireAuth={requireAuth}
                                  setRequireAuth={setRequireAuth}
                                >
                                  <CandidatesList />
                                </RequireAuth>
                              }
                            />
                            <Route
                              path=":cid"
                              element={
                                <RequireAuth
                                  requireAuth={requireAuth}
                                  setRequireAuth={setRequireAuth}
                                >
                                  <CandidatesDetail />
                                </RequireAuth>
                              }
                            />
                          </Route>
                        </Route>
                      </Route>
                      <Route
                        path=""
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <Home />
                          </RequireAuth>
                        }
                      />
                      <Route path="sub" element={<Sub />} />
                      <Route
                        path="mypage"
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <MyPage />
                          </RequireAuth>
                        }
                      />
                    </Route>
                    <Route path="/customer" element={<ProtectedRouter />}>
                      <Route
                        path="recruitments"
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <Recruitments />
                          </RequireAuth>
                        }
                      >
                        <Route path="outlook" element={<OutlookRouter />} />
                        <Route path="" element={<CandidateList />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path=":rid" element={<DashboardDetail />}>
                          <Route path="" element={<Reviewing />} />
                          <Route
                            path="virtual-interview"
                            element={<VirtualInterview />}
                          />
                          <Route
                            path="first-interview"
                            element={<Interview />}
                          />
                          <Route
                            path="second-interview"
                            element={<Interview />}
                          />
                          <Route path="evidence" element={<Evidence />} />
                          <Route path="pass" element={<Offer />} />
                          <Route path="submission" element={<Onboarding />} />
                          <Route path="reject" element={<Reject />} />
                        </Route>
                      </Route>
                      <Route
                        path="assessments"
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <Assessments />
                          </RequireAuth>
                        }
                      >
                        <Route path="active" element={<Active />} />
                        <Route path="archived" element={<Archived />} />
                      </Route>
                      <Route
                        path="assessments/create"
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <Create />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="assessments/:aid"
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <AssessmentDetail />
                          </RequireAuth>
                        }
                      >
                        <Route path="" element={<AssessmentDetailContent />} />
                        <Route
                          path="invite"
                          element={<AssessmentDetailInvite />}
                        />
                        <Route path="styling" element={<AssessmentStyling />} />
                        <Route path="edit" element={<AssessmentDetailEdit />} />
                        <Route
                          path="configure"
                          element={<AssessmentDetailConfigure />}
                        />
                      </Route>
                      <Route
                        path="candidates"
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <Candidates />
                          </RequireAuth>
                        }
                      >
                        <Route
                          path=""
                          element={
                            <RequireAuth
                              requireAuth={requireAuth}
                              setRequireAuth={setRequireAuth}
                            >
                              <CandidatesList />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path="list"
                          element={
                            <RequireAuth
                              requireAuth={requireAuth}
                              setRequireAuth={setRequireAuth}
                            >
                              <GucciList />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path=":cid"
                          element={
                            <RequireAuth
                              requireAuth={requireAuth}
                              setRequireAuth={setRequireAuth}
                            >
                              <CandidatesDetail />
                            </RequireAuth>
                          }
                        />
                      </Route>
                      <Route
                        path="tests"
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <Tests />
                          </RequireAuth>
                        }
                      >
                        <Route
                          path=""
                          element={
                            <RequireAuth
                              requireAuth={requireAuth}
                              setRequireAuth={setRequireAuth}
                            >
                              <TestsList />
                            </RequireAuth>
                          }
                        />
                        <Route
                          path=":tid"
                          element={
                            <RequireAuth
                              requireAuth={requireAuth}
                              setRequireAuth={setRequireAuth}
                            >
                              <TestsDetail />
                            </RequireAuth>
                          }
                        />
                      </Route>
                      <Route
                        path="profile"
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <Profile />
                          </RequireAuth>
                        }
                      >
                        <Route path="" element={<ProfileAccount />} />
                        <Route path="company" element={<ProfileCompany />} />
                        <Route path="members" element={<ProfileMembers />} />
                      </Route>
                      <Route path="statistic" element={<Statistic />} />
                    </Route>
                  </Route>
                  <Route element={<AuthLayout />}>
                    <Route path="/:account/auth" element={<AuthRouter />}>
                      <Route path="signin" element={<Signin />} />
                      <Route
                        path="agree"
                        element={
                          <RequireAuth
                            requireAuth={requireAuth}
                            setRequireAuth={setRequireAuth}
                          >
                            <Agree />
                          </RequireAuth>
                        }
                      />
                      <Route path="signup" element={<Signup />}>
                        <Route path="email" element={<ProcessEmail />} />
                        <Route path="info" element={<ProcessInfo />} />
                        <Route
                          path="thumbnail"
                          element={<ProcessThumbnail />}
                        />
                      </Route>
                      <Route path="member" element={<MemberSignup />}>
                        <Route path="signup" element={<Information />} />
                      </Route>
                      <Route path="set-password" element={<SetPassword />} />
                      <Route path="expired" element={<ExpireAuthToken />} />
                    </Route>
                  </Route>
                  <Route element={<PrintLayout />}>
                    <Route
                      path="/customer/candidates/:cid/print"
                      element={
                        <RequireAuth
                          requireAuth={requireAuth}
                          setRequireAuth={setRequireAuth}
                        >
                          <CandidatePrint />
                        </RequireAuth>
                      }
                    />
                  </Route>
                  <Route element={<ApplyLayout />}>
                    <Route path="/sentry" element={<Sentry/>}/>
                    <Route path="/apply" element={<ApplyRouter />} />
                    <Route path="/apply/expired" element={<ExpiredApply />} />
                    <Route path="/apply/invalid" element={<InvalidApply />} />
                    <Route
                      path="/applyCompleted"
                      element={<CompletedApply />}
                    />
                  </Route>
                  <Route>
                    <Route path="/interview" element={<DateRouter />} />
                    <Route path="/interview/date" element={<SelectedDate />} />
                    <Route
                      path="/interview/completed"
                      element={<CompletedDate />}
                    />
                  </Route>
                  <Route element={<ApplyLayout />}>
                    <Route
                      path="/compensation"
                      element={<CompensationRouter />}
                    />
                    <Route
                      path="/compensation/form"
                      element={<CompensationForm />}
                    />
                    <Route
                      path="/compensation/completed"
                      element={<CompletedCompensation />}
                    />
                  </Route>
                  <Route element={<ApplyLayout />}>
                    <Route path="/offer" element={<OfferRouter />} />
                    <Route path="/offer/detail" element={<OfferLetter />} />
                    <Route
                      path="/offer/completed"
                      element={<CompletedOffer />}
                    />
                    <Route path="/offer/reject" element={<RejectedOffer />} />
                  </Route>
                </Routes>
              </React.Suspense>
            </CSSTransition>
            <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              closeButton={CloseButton}
            />
            <ModalSelector priority="main" />
            <ModalSelector priority="sub" />
          </StatusProvider>
        </AuthProvider>
      </ModalProvider>
    </ThemeProvider>
  );
};

const CloseButton = ({ closeToast }) => {
  const { t } = useTranslation();

  return (
    <button
      className="toast-close-button fsbtn16 text-button primary"
      onClick={closeToast}
    >
      <CloseIcon />
    </button>
  );
};

export default App;
