import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
} from 'firebase/firestore';
import { app } from './index';

const db = getFirestore(app);

export const _getSkillGroups = (lang) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `skillgroup_${lang}`);
      const querySnap = await getDocs(collectionRef);

      const newDocs = new Array();
      querySnap.forEach((doc) => {
        newDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getTests = (lang) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `test_${lang}`);
      // const q = query(collectionRef, orderBy('title', 'asc'));

      const querySnap = await getDocs(collectionRef);
      const newDocs = new Array();
      querySnap.forEach((docSnap) => {
        newDocs.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      });
      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getTestInfo = ({ tid, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, `test_${lang}`, tid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _getSkillInfo = ({ sid, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, `skill_${lang}`, sid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });
