import { getFirestore, setDoc, doc, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app } from './index';

const db = getFirestore(app);
const auth = getAuth(app);

export const _setResume = ({ payload }) =>
  new Promise(async (resolve, reject) => {
    const { 이메일 } = payload;

    try {
      const docRef = doc(db, 'gucciapply', 이메일);
      await setDoc(
        docRef,
        {
          ...payload,
          createdAt: Timestamp.now(),
        },
        { merge: true }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateResume = ({ payload }) =>
  new Promise(async (resolve, reject) => {
    const { 이메일 } = payload;

    try {
      const docRef = doc(db, 'gucciapply', 이메일);
      await setDoc(
        docRef,
        {
          ...payload,
          updatedAt: Timestamp.now(),
        },
        { merge: true }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });
