import { createTheme } from '@mui/material/styles';
import color from 'styles/include.scss';

export const colors = {
  modalOverlay: 'rgba(0, 0, 0, .5)',
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: color.primaryColor,
    },
  },
});
