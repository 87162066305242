import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { nameConversionLanguage } from 'utils/converter';
import EmployPositionTable from './EmployPositionTable';
import EmployPayTable from './EmployPayTable';

const Employment = ({ candidate, recruitment, step }) => {
  const { t } = useTranslation();
  const { etcText } = candidate?.progressSubmit[step];

  return (
    <>
      <article className="recruitment-title">
        <h3 className="fs18">{recruitment?.recruitmentName}</h3>
      </article>
      <article className="contents-wrapper">
        <aside className="contents-desc">
          <p className="fs18">
            <Trans
              t={t}
              i18nKey="desc.offer-congratulations"
              values={{
                candidateName: nameConversionLanguage(
                  candidate?.firstName,
                  candidate?.lastName,
                  t('lang')
                ),
              }}
            />
          </p>
        </aside>
        <aside className="contents-employment">
          <h4 className="sub-title">{t('title.employment')}</h4>
          <div className="position-wrapper">
            <EmployPositionTable candidate={candidate} step={step} t={t} />
          </div>
          <div className="pay-wrapper">
            <EmployPayTable candidate={candidate} step={step} t={t} />
          </div>

          <div className="tip-wrapper">
            <span className="fs12">
              {`${t('helper.location-incentive')} ${etcText}` ||
                t('helper.incentives')}
            </span>
          </div>
        </aside>
      </article>
      <article className="offer-detail">
        <aside className="offer-detail-title-box">
          <h4 className="sub-title">{t('title.offer-detail')}</h4>
          <p className="fs18">
            {t('desc.please-do-not-leak-or-share-outside')}
          </p>
        </aside>
        <aside className="offer-detail-contents">
          {/* <p className="fs18">{t('desc.offer-detail-list-1')}</p> */}
          <p className="fs18">{t('desc.offer-detail-list-2')}</p>
          <p className="fs18">{t('desc.offer-detail-list-3')}</p>
          <p className="fs18">{t('desc.offer-detail-list-4')}</p>
        </aside>
      </article>
    </>
  );
};

export default Employment;
