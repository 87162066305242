import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthFooter from 'components/footer/AuthFooter';

const AuthLayout = (props) => (
  <>
    <Outlet />
    <AuthFooter />
  </>
);

export default AuthLayout;
