import React from 'react';

const FrontIframe = () => {
  const [htmlMessage, setHtmlMessage] = React.useState('');
  const [javascriptMessage, setJavascriptMessage] = React.useState('');
  const [cssMessage, setCssMessage] = React.useState('');

  React.useEffect(() => {
    window.addEventListener('message', function (e) {
      if (e.origin !== process.env.REACT_APP_MEDIA_URL) return;

      setHtmlMessage(e.data.type === 'webpackWarnings' ? '' : e.data.htmlCode);
      setJavascriptMessage(
        e.data.type === 'webpackWarnings' ? '' : e.data.javascriptCode
      );
      setCssMessage(e.data.type === 'webpackWarnings' ? '' : e.data.cssCode);

      window.document
        .querySelector('#ch-plugin')
        ?.setAttribute('style', 'display: none !important;');
    });
  }, []);

  /** ====================
   * iframe <head>안에 <style>태그를 넣는 함수
  ======================== */
  React.useEffect(() => {
    if (cssMessage === undefined) return;

    const head = document.getElementsByTagName('head')[0];
    const isStyle = head.querySelector('#__FRONT_CODE_CSS');

    if (isStyle === null || isStyle === undefined) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = '__FRONT_CODE_CSS';
      style.innerHTML = cssMessage;

      head.appendChild(style);
    } else {
      isStyle.innerHTML = cssMessage;
    }
  }, [cssMessage]);

  /** ====================
   * iframe <head>안에 <script>태그를 넣는 함수
  ======================== */
  React.useEffect(() => {
    if (javascriptMessage === undefined) return;

    const body = document.getElementsByTagName('body')[0];
    const isScript = body.querySelector('#__FRONT_CODE_JAVASCRIPT');

    if (isScript === null || isScript === undefined) {
      const script = document.createElement('script');
      script.id = '__FRONT_CODE_JAVASCRIPT';
      setTimeout(() => {
        script.innerHTML = javascriptMessage;
      }, 500);

      body.appendChild(script);
    } else {
      setTimeout(() => {
        isScript.innerHTML = javascriptMessage;
      }, 500);
    }
  }, [javascriptMessage]);

  return (
    <>
      <div
        className="container"
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
      ></div>
    </>
  );
};

export default FrontIframe;
