import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {},
  extraReducers: {},
});

export const {} = modalSlice.actions;

export default modalSlice.reducer;
