import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InvitedInterviewer from 'modals/recruitments/InvitedInterviewer';
import { ReactComponent as PencilIcon } from 'assets/images/icon/settings-icon.svg';
import { useAuth } from 'utils/auth';
import { useLocation } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { setCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { Timestamp } from 'firebase/firestore';
import { toast } from 'utils/notify';
import { dateToDateFormat } from 'utils/string';
import {
  convertChannel,
  convertSubChannel,
} from 'utils/convertKey';
import { CandidateDetailMoreMenu } from 'components/recruitment/detail/CandidateDetailMoreMenu';
import { candidateActionMenu } from 'constants/recruitments/candidateActionMenu';
import BodyTableRowSkeleton from '../BodyTableRowSkeleton';
import { useModal } from '../../../../utils/modal';
import { nameConversionLanguage } from '../../../../utils/converter';
import { getDaysElapsed } from '../../../../utils/dateFormat';
import { BarGraphScore } from '../virtualInterview/toggle/ToggleBodyHeader';
import { calculatePercentageAverage } from '../../../../utils/array';
import { Button } from '../../../common/Button';

const RecruitmentTableBody = ({ candidates, recruitment, interviewType }) => {
  const step = interviewType === 'first-interview' ? 3 : 4;
  const location = useLocation();
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();
  const { userInfo } = useAuth();

  const [status, setStatus] = useState('');
  const [invitedInterviewer, setInvitedInterviewer] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState();
  const [isResetting, setIsResetting] = useState(false);

  const onShowInvitedModal = (candidate) => {
    setInvitedInterviewer(true);
    setCurrentCandidate(candidate);
  };

  const viewDetailModal = (candidate) => {
    displayModal(
      'recruitments/CandidateDetail',
      {
        candidates,
        candidate,
        recruitment,
        processName: interviewType,
      },
      { frame: 'full' }
    );
  };

  const checkedInterviewInfo = (stepInfo) => {};

  const checkSendEmailTime = (candidate) => {
    if (!Object.keys(candidate)?.length) return;

    const { progressSubmit, recruitProgress } = candidate;
    const currentInfo = progressSubmit[recruitProgress];

    checkedInterviewInfo(currentInfo);

    if (!currentInfo?.interviewer?.sendEmailAt) return;

    if (currentInfo?.interviewSchedule) {
      if (currentInfo?.interviewSchedule?.isDone) {
        return false;
      }
    }

    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const currentTimestamp = Date.now();
    const baseTime = currentInfo?.interviewer?.sendEmailAt?.seconds * 1000;
    const timeDifference = currentTimestamp - baseTime;

    return timeDifference >= oneDayInMilliseconds;
  };

  const onClickRemindSendEmail = (e, candidate) => {
    e.stopPropagation();
    const endPoint = location?.pathname?.split('/')[4];
    const { progressSubmit, recruitProgress, firstName, lastName, email } =
      candidate;
    const currentInfo = progressSubmit[recruitProgress];
    const { interviewer } = currentInfo;

    executeAction(
      setCandidateOfRecruitment({
        aid: recruitment?.rid,
        cid: email,
        interviewerEmail: interviewer?.email,
        interviewerFirstName: interviewer?.firstName,
        interviewerLastName: interviewer?.lastName,
        candidateFirstName: firstName,
        candidateLastName: lastName,
        type: 'remind',
        stage: endPoint,
        lang: 'ko',
        progressSubmit: {
          [step]: {
            interviewer: {
              sendEmailAt: Timestamp.now(),
            },
          },
        },
      })
    )
      .then(() => {
        toast(t('toast.interviewer-resend-email'));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
    <tbody>
      {candidates === null ? (
        <>
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
        </>
      ) : candidates?.length === 0 ? (
        <tr>
          <td>{t('recruitment.desc.empty-no-applicant')}</td>
        </tr>
      ) : (
        candidates?.map((candidate) => (
          <tr key={candidate?.email} onClick={() => viewDetailModal(candidate)}>
            <td>
              {nameConversionLanguage(
                candidate?.firstName,
                candidate?.lastName,
                t('lang')
              )}{' '}
              {candidate?.progressSubmit[step].KIV ? (
                <span className="tag-kiv">KIV</span>
              ) : (
                ''
              )}
            </td>
            <td>{`${t(convertChannel(candidate?.progressSubmit[1]?.channel))} - ${t(convertSubChannel(candidate?.progressSubmit[1]))
            }`}</td>
            {candidate.progressSubmit[step].interviewer.email ===
            'Not designated' ? (
              <td>
                <div
                  onClick={() => onShowInvitedModal(candidate)}
                  className="td-container"
                >
                  <div className="invite-button">invite</div>
                </div>
              </td>
            ) : (
              <td>
                <div className="flex">
                  <span>
                    {candidate.progressSubmit[step]?.interviewer.firstName}{' '}
                    {candidate.progressSubmit[step]?.interviewer.lastName}
                  </span>
                  {userInfo?.memberType < 15 && (
                    <Button
                      frontIcon={<PencilIcon />}
                      padding={6}
                      theme={'borderGrey'}
                      radius={8}
                      onClick={() => {
                        onShowInvitedModal(candidate);
                        setIsResetting(true);
                      }}
                      isDisabled={
                        candidate.progressSubmit[step]?.interviewSchedule
                          ?.isDone
                      }
                    />
                  )}
                </div>
              </td>
            )}
            <td>
              {candidate.progressSubmit[step]?.interviewSchedule?.isDone
                ? candidate.progressSubmit[step]?.interviewSchedule
                    .interviewDate &&
                  typeof candidate.progressSubmit[step]?.interviewSchedule
                    .interviewDate !== 'object'
                  ? dateToDateFormat(
                      new Date(
                        candidate.progressSubmit[
                          step
                        ]?.interviewSchedule.interviewDate
                      ),
                      t('lang')
                    )
                  : t('ats.unprogress-interview')
                : `${t('ats.adjusting')}`}
            </td>
            <td>
              {candidate.progressSubmit[step]?.isSubmit && (
                <BarGraphScore
                  score={
                    calculatePercentageAverage(
                      candidate.progressSubmit[step]?.scoreList || [0]
                    ) / 100
                  }
                />
              )}
            </td>
            <td>
              <div className="td-container">
                <div
                  className={`${candidate.progressSubmit[step]?.opinion} opinion`}
                >
                  {candidate.progressSubmit[step]?.opinion
                    ? candidate.progressSubmit[step]?.opinion
                    : 'waiting'}
                </div>
              </div>
            </td>
            <td>
              {/* 이메일 다시 보내기 버튼 */}
              {checkSendEmailTime(candidate) ? (
                <div className="flex">
                  <Button
                    className="resend-email-button"
                    theme="borderGrey"
                    label={t('btn.resend-email')}
                    argument
                    onClick={(e) => onClickRemindSendEmail(e, candidate)}
                  />
                  <span>
                    {candidate.progressSubmit[step]?.interviewer?.sendEmailAt
                      ? getDaysElapsed(
                          candidate.progressSubmit[step]?.interviewer
                            ?.sendEmailAt.seconds * 1000
                        ) === 0
                        ? t('lead-time.today')
                        : `${getDaysElapsed(
                            candidate.progressSubmit[step]?.interviewer
                              ?.sendEmailAt.seconds * 1000
                          )} ${t('lead-time.days')}`
                      : '-'}
                  </span>
                </div>
              ) : candidate.progressSubmit[step]?.interviewer?.sendEmailAt ? (
                !candidate.progressSubmit[step]?.interviewSchedule?.isDone ? (
                  getDaysElapsed(
                    candidate.progressSubmit[step]?.interviewer?.sendEmailAt
                      .seconds * 1000
                  ) === 0 ? (
                    t('lead-time.today')
                  ) : (
                    '-'
                  )
                ) : (
                  '-'
                )
              ) : (
                '-'
              )}
            </td>
            <td>
              <CandidateDetailMoreMenu
                candidate={candidate}
                rid={recruitment?.rid}
                menuList={[candidateActionMenu.deleteCandidate, candidateActionMenu.moveToPrevStep]}
              />
            </td>
          </tr>
        ))
      )}
      {invitedInterviewer && (
        <InvitedInterviewer
          open={invitedInterviewer}
          close={setInvitedInterviewer}
          recruitment={recruitment}
          currentCandidate={currentCandidate}
          isResetting={isResetting}
          setStatus={setStatus}
          clickStatus="notYet"
        />
      )}
    </tbody>
    </>
);
};

export default RecruitmentTableBody;
