import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  getCandidateOfRecruitment,
  getRecruitment,
} from 'store/slices/recruitments/recruitmentsSlice';

const OfferRouter = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('uid');
  const rid = searchParams.get('aid');
  const cid = searchParams.get('email');
  const step = searchParams.get('step');

  const [recruitment, setRecruitment] = useState({});
  const [candidate, setCandidate] = useState({});
  const [redirectFlag, setRedirectFlag] = useState(false);

  useEffect(() => {
    if (!rid || !uid || !cid) return;

    executeAction(
      getRecruitment({
        ouid: uid,
        aid: rid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setRecruitment(doc);

        executeAction(
          getCandidateOfRecruitment({
            uid,
            rid,
            cid,
          })
        )
          .unwrap()
          .then(({ doc }) => {
            setCandidate(doc);
            setRedirectFlag(true);
          })
          .catch((e) => {
            console.error(e);
            setRedirectFlag(false);
          });
      });
  }, [rid, uid, cid]);

  useEffect(() => {
    if (!redirectFlag) return;
    const { status } = candidate?.progressSubmit[step];

    if (status === 'accept') {
      return navigate('/offer/completed');
    }

    if (status === 'reject') {
      return navigate('/offer/reject');
    }

    return navigate('/offer/detail', {
      state: { uid, rid, cid, step, candidate, recruitment },
      replace: true,
    });
  }, [redirectFlag]);

  return <main />;
};

export default OfferRouter;
