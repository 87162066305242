import React from 'react';
import { ReactComponent as PowerByLogo } from 'assets/images/logo/powerdby-logo.svg';

const ApplyFooter = () => {
  return (
    <>
      <footer className="components-footer-apply-footer">
        <div className="container">
          <div className="logo">
            <PowerByLogo />
          </div>
        </div>
      </footer>
    </>
  );
};

export default ApplyFooter;
