import React from 'react';
import { useModal } from 'utils/modal';

const StatusContext = React.createContext(null);

export const StatusProvider = ({ children }) => {
  const { displayModal, dismissModal } = useModal();
  const [isAuthLoading, setIsAuthLoading] = React.useState(false);
  const [isEntireLoading, setIsEntireLoading] = React.useState(false);
  const [authInfo, setAuthInfo] = React.useState();
  const [candidateInfo, setCandidateInfo] = React.useState();
  const [assessmentInfo, setAssessmentInfo] = React.useState();

  React.useEffect(() => {
    setIsEntireLoading(isAuthLoading);
  }, [isAuthLoading]);

  React.useEffect(() => {
    if (isEntireLoading) {
      displayModal(
        'common/Loading',
        {},
        {
          frame: 'part',
          hasCloseButton: false,
        }
      );
    } else {
      dismissModal();
    }
  }, [isEntireLoading]);

  const setTestInfo = ({
    authInfo: _authInfo,
    candidateInfo: _candidateInfo,
    assessmentInfo: _assessmentInfo,
  }) => {
    setAuthInfo(_authInfo);
    setCandidateInfo(_candidateInfo);
    setAssessmentInfo(_assessmentInfo);
  };

  const getTestInfo = () => ({
    authInfo,
    candidateInfo,
    assessmentInfo,
  });

  const value = {
    isAuthLoading,
    setIsAuthLoading,
    isEntireLoading,
    setIsEntireLoading,
    setTestInfo,
    getTestInfo,
  };

  return (
    <StatusContext.Provider value={value}>{children}</StatusContext.Provider>
  );
};

export const useStatus = () => React.useContext(StatusContext);

export const divideSkillScope = (skill) => {
  if (skill?.group?.length > 0 && skill?.testcnt > 0) {
    return 'grouptest';
  }
  switch (skill?.section) {
    case 'video':
    case 'essay':
    case 'multiple-choice':
    case 'file-upload':
    case 'spreadsheets':
      return 'subjective';
    default:
      return 'objective';
  }
};
