import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MaterialInput from 'components/input/MaterialInput';
import { ReactComponent as SearchIcon } from 'assets/images/icon/search.svg';
import { Box } from '@mui/material';
import { executeAction } from 'utils/redux';
import {
  getSkills,
  getLibSkills,
  getLibTests,
} from 'store/slices/assessmentsSlice';
import { getTests } from 'store/slices/testsSlice';
import { useModal } from 'utils/modal';
import { removeNull, sortCopy } from 'utils/array';
import SkillCard from 'components/card/SkillCard';
import { useAuth } from 'utils/auth';

const List = () => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const { displayModal } = useModal();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCardLoading, setIsCardLoading] = React.useState(false);
  const [curTab, setCurTab] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [jobRole, setJobRole] = React.useState('');
  const [testType, setTestType] = React.useState('');
  const [__tests, __setTests] = React.useState([]);
  const [_tests, _setTests] = React.useState([]);
  const [tests, setTests] = React.useState([]);
  const [detailTests, setDetailTests] = React.useState(null);
  const [testTypeList, setTestTypeList] = React.useState([]);
  const [objskillList, setObjSkillList] = React.useState([]);
  const [libskillList, setLibSkillList] = React.useState([]);
  const [enabledSkillList, setEnabledSkillList] = React.useState([]);

  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigatorRef = React.useRef(null);
  const lang = t('lang');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 평가 생성과 같이 객관식과 라이브러리 문제를 모두 나열한다.
  React.useEffect(() => {
    setIsLoading(true);
    setIsCardLoading(true);

    const tasks = [];
    tasks.push(
      executeAction(getSkills(lang))
        .unwrap()
        .then(({ docs: _docs }) => {
          // console.log(_docs);
          // DB에 객관식 문제에 시간이 15분으로 되어 있으면 8분으로 수정한다.
          // 객관식 기본 시간을 변경한다.
          _docs.forEach((doc) => {
            if (doc.duration === 15) {
              doc.duration = 8;
            }
          });
          setObjSkillList(_docs || []);
        })
        .catch((e) => {
          console.dir(e);
          setObjSkillList([]);
        })
    );
    tasks.push(
      executeAction(getLibSkills(lang))
        .unwrap()
        .then(({ docs: defdocs }) => {
          // 코딩 문제에 대서 테스트 시간을 20분으로 한다.
          defdocs.forEach((doc) => {
            if (doc.question_type === 'tag.code') {
              doc.duration = 20;
            }
          });
          setLibSkillList(defdocs || []);
        })
        .catch((e) => {
          console.dir(e);
          setLibSkillList([]);
        })
    );

    executeAction(getTests(lang))
      .unwrap()
      .then(({ docs }) => {
        const newDetailTests = {};
        Object.values(docs || {}).forEach((doc) => {
          newDetailTests[doc.id] = doc;
        });
        executeAction(getLibTests(lang))
          .unwrap()
          .then(({ docs }) => {
            Object.values(docs || {}).forEach((doc) => {
              newDetailTests[doc.id] = doc;
            });
            setDetailTests(newDetailTests || {});
          })
          .catch((e) => {
            console.dir(e);
          });
        setIsCardLoading(false);
      })
      .catch((e) => {
        console.dir(e);
        setDetailTests({});
        setIsCardLoading(false);
      });

    Promise.allSettled(tasks).then(() => {
      setIsLoading(false);
      setIsCardLoading(false);
    });
    setEnabledSkillList(userInfo?.[`enabledSkill_${lang}`] || []);
  }, [userInfo]);

  // 생성에서는 각 task를 완료 후 전제 스킬 변수를 생성했는데 별도로 작업이 완료된 후 skill을 저장
  React.useEffect(() => {
    let _skills = [...objskillList, ...libskillList];

    if (userInfo && (userInfo?.plan < 100 || userInfo?.plan === undefined)) {
      _skills = _skills.filter((value) => value?.category !== 'Custom');
    }

    const skills = sortCopy(_skills || [], (a, b) => {
      const titleA = a?.[`title_${t('lang')}`] || a?.title;
      const titleB = b?.[`title_${t('lang')}`] || b?.title;
      if (titleA > titleB) return 1;
      if (titleA < titleB) return -1;
      if (titleA === titleB) return 0;
    });

    const newSkillList = removeNull([
      skills.find((x) => x.id === '1321'),
      skills.find((x) => x.id === '1322'),
      ...skills.filter((x) => x.id !== '1321' && x.id !== '1322'),
    ]);

    __setTests(newSkillList || []);

    const tmpnewTestTypeList = (
      Array.from(new Set(skills.map((x) => x.category))) || []
    ).map((x) => ({
      key: x,
      display: {
        en: x,
        ko: x,
      },
    }));

    if(tmpnewTestTypeList.length !==0){
      const newTestTypeList = removeNull([
        { key: t('tab.all'), display: { en: t('tab.all'), ko: t('tab.all') } },
        tmpnewTestTypeList.find((x) => x.key === '인적성검사'),
        ...tmpnewTestTypeList.filter((x) =>x.key !== '인적성검사')
      ]);

      setTestTypeList(newTestTypeList);
      setTestType(newTestTypeList?.[0]?.key);
    }
  }, [objskillList, libskillList]);

  React.useEffect(() => {
    let testsCopy = __tests.slice(0);

    if (Boolean(testType) && testType !== t('tab.all')) {
      testsCopy = (testsCopy || []).filter((x) => x.category === testType);
    }

    testsCopy = (testsCopy || []).filter((x) => {
      const convertedTitle = (x?.[`title_${t('en')}`] || '')
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      const convertedSearch = (search || '')
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      if (convertedTitle?.includes?.(convertedSearch)) {
        return true;
      }
      return false;
    });

    _setTests(testsCopy);
  }, [__tests, search, testType]);

  React.useEffect(() => {
    const startIdx = page * rowsPerPage;
    setTests((_tests || []).slice(startIdx, startIdx + rowsPerPage));
  }, [_tests, page, rowsPerPage]);

  React.useEffect(() => {
    setCurTab(testType);
  }, [testType]);

  React.useEffect(() => {
    const idx = testTypeList.findIndex((x) => x.key === curTab);
  }, [curTab]);

  const onTabClickHandler = (testtype) => {
    setTestType(testtype?.key);
  };

  const onCreateHandler = () => {
    navigate('/customer/assessments/create');
  };

  const onActionsClickHandler = (skill) => {
    displayModal(
      'assessments/ObjectiveTestDetail',
      {
        test: skill,
      },
      {
        frame: 'full',
        padding: '40px',
        hasCloseButton: true,
      }
    );
  };

  return (
    <main className="pages-protected-tests-list">
      <header className="header">
        <h3 className="header-title fs28">{t('title.tests')}</h3>

        <div className="header-right">
          <div className="header-right-search">
            <MaterialInput
              placeholder={t('text-field.label.search')}
              className="header-right-search-input"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              icon={<SearchIcon />}
            />
          </div>
        </div>
      </header>

      <section className="table">
        <section className="tabs">
          <ul ref={navigatorRef} className="tabs-list">
            {(testTypeList || []).map((testtype, idx) => (
              <li
                key={idx}
                className={`tabs-list-item ${
                  curTab === testtype?.key && 'selected'
                }`}
              >
                <div
                  className="container"
                  onClick={() => onTabClickHandler(testtype)}
                >
                  <span className="fs16">{testtype?.display?.[t('lang')]}</span>
                </div>
              </li>
            ))}
            <div className="indicator" />
          </ul>
        </section>
        <Box>
          {isLoading || isCardLoading || tests?.length > 0 ? (
            <ul className="skills-list">
              {isLoading ? (
                <></>
              ) : (
                (_tests || []).map((test, idx) => (
                  <li key={idx} className="skills-list-item">
                    <SkillCard
                      isCardLoading={isCardLoading}
                      title={test?.[`title_${t('en')}`]}
                      contains={Array.from(
                        new Set(
                          (test?.test || []).map((it) => {
                            const detailTest = detailTests?.[it];
                            return detailTest?.[`skill_${t('lang')}`];
                          })
                        )
                      )}
                      duration={test?.duration}
                      test={test}
                      detailTests={detailTests}
                      plan={userInfo?.plan}
                      enabledSkillList={enabledSkillList}
                    />
                  </li>
                ))
              )}
            </ul>
          ) : (
            <div className="empty">
              <div className="thumbnail" />
              <Trans
                t={t}
                parent="p"
                className="fs28"
                i18nKey="select-test.desc.empty"
              />
            </div>
          )}
        </Box>
      </section>
    </main>
  );
};

export default List;
