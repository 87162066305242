import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from 'components/header/AppHeader';
import AppFooter from 'components/footer/AppFooter';
import { useAuth } from 'utils/auth';
import { useDispatch } from 'react-redux';
import { getGucciUserList } from 'store/slices/recruitments/membersSlice';

const AppLayout = (props) => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGucciUserList());
  }, []);

  return (
    <>
      <AppHeader />
      {!user && <main />}
      <Outlet />
      <AppFooter />
    </>
  );
};

export default AppLayout;
