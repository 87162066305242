import React from 'react';
import { useModal } from 'utils/modal';
import { convertGradeToScore, nameConversionLanguage } from 'utils/converter';
import { useTranslation } from 'react-i18next';
import { formatDateWithTime } from 'utils/string';
import Tag from 'components/common/Tag';
import { useAverageScore } from 'utils/hooks';
import {
  convertChannel,convertSubChannel,
} from 'utils/convertKey';
import { getDaysElapsed } from 'utils/dateFormat';
import { CandidateDetailMoreMenu } from 'components/recruitment/detail/CandidateDetailMoreMenu';
import { candidateActionMenu } from 'constants/recruitments/candidateActionMenu';
import { Button } from 'components/common/Button';
import { executeAction } from 'utils/redux';
import {
  sendAiInterviewRemindMail,
} from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';
import * as Sentry from '@sentry/react';
import BodyTableRowSkeleton from '../BodyTableRowSkeleton';

const RecruitmentTableBody = ({ candidates, recruitment }) => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();

  const viewDetailModal = (candidate) => {
    displayModal(
      'recruitments/CandidateDetail',
      {
        candidates,
        candidate,
        recruitment,
        processName: 'aiInterview',
      },
      { frame: 'full' }
    );
  };
  const getTotalScore = (candidate) => {
    if (!recruitment || !Object.keys(recruitment).length) return;

    const { results } = candidate;

    const languageTests = [];

    recruitment?.tests?.forEach((test) => {
      if (typeof test?.question === 'string') return;

      test?.question?.forEach((ques) => {
        if (ques?.tid === '601000000') {
          if (results?.english) {
            languageTests.push({
              category_skill: 'English Test',
              section: 'language',
              question: [
                {
                  name: 'english',
                  ...results.english,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.chinese) {
            languageTests.push({
              category_skill: 'Chinese Test',
              section: 'language',
              question: [
                {
                  name: 'chinese',
                  ...results.chinese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.japanese) {
            languageTests.push({
              category_skill: 'Japanese Test',
              section: 'language',
              question: [
                {
                  name: 'japanese',
                  ...results.japanese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.korean) {
            languageTests.push({
              category_skill: 'Korean Test',
              section: 'language',
              question: [
                {
                  name: 'korean',
                  ...results.korean,
                  section: 'language',
                },
              ],
            });
          }
        }
      });
    });

    const newTests = [...recruitment?.tests, ...languageTests];

    let testsArr;

    if (!results) {
      testsArr = [];
    } else {
      testsArr = newTests.map((test, idx) => {
        if (
          test?.id === '6010' ||
          test?.section === 'file-upload' ||
          test?.section === 'multiple-choice'
        )
          return;

        const testArr = [];
        const { question } = test;

        // 커스텀 질문이고 language 테스트가 아닐 때
        if (test?.section && test?.section !== 'language') {
          testArr.push(results[test?.uuid]);
        } else if (test?.question_type === 'tag.multiple-choice') {
          testArr.push(results[test?.uuid]);
        } else {
          for (const q of question) {
            let count = 0;

            if (q?.section === 'language') {
              Object.keys(results[q?.name])?.forEach((item) => {
                testArr.push({
                  score:
                    q?.score && count < 1
                      ? q?.score
                      : q?.preset_score && count < 1
                      ? q?.preset_score
                      : Math.abs(
                          convertGradeToScore(
                            results[q?.name][item]?.judged_difficulty
                          ) / 10
                        ),
                });
                count += 1;
              });
            } else if (q?.id !== '99101000006') {
              if (!results) return;

              testArr.push(results[q?.id]);
            }
          }
        }

        const removeUndefined = testArr?.filter(
          (item) => item?.preset_score || item?.score
        );

        const scoreArr = removeUndefined?.map((item) => {
          return !item?.score ? item?.preset_score : item?.score;
        });

        const average = useAverageScore(scoreArr);

        return {
          id: idx,
          title: test?.category_skill || test?.title_en || test?.question,
          average: average || 0,
        };
      });
    }

    const filtered = testsArr?.filter((item) => item);
    const sortedData = filtered.sort((a, b) => b.average - a.average);

    const avgScore =
      sortedData.reduce((acc, cur) => acc + cur.average, 0) / sortedData.length;

    return avgScore || 0;
  };

  const convertStatusToText = (data, type) => {
    const { condition } = data;

    if (type === 'text') {
      let name;

      switch (condition) {
        case 0:
          name = 'status.invited';
          break;
        case 1:
          name = 'status.in-progress';
          break;
        case 2:
          name = 'status.completed';
          break;
        default:
          name = 'Invited';
          break;
      }

      return name;
    }
    let color;

    switch (condition) {
      case 0:
        color = 'orange500';
        break;
      case 1:
        color = 'grey300';
        break;
      case 2:
        color = 'green500';
        break;
      default:
        color = 'grey300';
        break;
    }

    return color;
  };

  const onClickRemindSendEmail = (e, candidate) => {
    e.stopPropagation();
    executeAction(
      sendAiInterviewRemindMail({
        aid: recruitment.rid,
        cid: candidate.email,
        firstName: candidate?.firstName,
        lastName: candidate?.lastName,
        job: recruitment?.recruitmentName,
        lang: recruitment.language,
        phoneNumber: candidate?.progressSubmit[1]?.phoneNumber,
      })
    )
      .then(() => {
        toast(t('toast.interviewer-resend-email'));
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  };

  return (
    <>
    <tbody>
      {candidates === undefined ? (
        <>
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
        </>
      ) : candidates?.length === 0 ? (
        <tr>
          <td>{t('recruitment.desc.empty-no-applicant')}</td>
        </tr>
      ) : (
        candidates?.map((candidate) => (
          <tr key={candidate?.email} onClick={() => viewDetailModal(candidate)}>
            <td>
              {nameConversionLanguage(
                candidate?.firstName,
                candidate?.lastName,
                t('lang')
              )}{' '}
              {candidate?.progressSubmit[2]?.KIV ? (
                <span className="tag-kiv">KIV</span>
              ) : (
                ''
              )}
            </td>
            <td>{candidate?.progressSubmit[1]?.phoneNumber}</td>
            <td>{`${t(convertChannel(candidate?.progressSubmit[1]?.channel))} - ${t(convertSubChannel(candidate?.progressSubmit[1]))
            }`}</td>
            <td>{candidate?.progressSubmit[1]?.applyLocation?.join(', ')}</td>
            <td>
              <Tag
                label={t(convertStatusToText(candidate, 'text'))}
                color={convertStatusToText(candidate, 'color')}
                fontSize="fs12"
                width={'80%'}
              />
            </td>
            <td>
              {formatDateWithTime(
                new Date(
                  candidate?.progressSubmit[2]?.invitedAt?.seconds * 1000
                ),
                t('lang')
              )}
            </td>
            <td className="remind-button-wrapper">
              {!candidate.submitedAt
                ? getDaysElapsed(
                  candidate.progressSubmit[2]?.invitedAt?.seconds * 1000
                ) === 0
                  ? t('lead-time.today')
                  : `${getDaysElapsed(
                    candidate.progressSubmit[2]?.invitedAt?.seconds * 1000
                  )} ${t('lead-time.days')}`
                : '-'}
              <Button
                className="resend-email-button"
                theme="borderGrey"
                label={t('btn.resend-email')}
                argument
                onClick={(e) => onClickRemindSendEmail(e, candidate)}
              />
            </td>
            <td>
              <CandidateDetailMoreMenu
                candidate={candidate}
                rid={recruitment?.rid}
                menuList={[candidateActionMenu.deleteCandidate, candidateActionMenu.moveToPrevStep]}
              />
            </td>
          </tr>
        ))
      )}
    </tbody>
    </>
  );
};

export default RecruitmentTableBody;
