import React, { useEffect, useState } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { useModal } from 'utils/modal';
import { ReactComponent as GradeS } from 'assets/images/icon/GradeS.svg';
import { ReactComponent as GradeA } from 'assets/images/icon/GradeA.svg';
import { ReactComponent as GradeB } from 'assets/images/icon/GradeB.svg';
import { ReactComponent as GradeC } from 'assets/images/icon/GradeC.svg';
import { ReactComponent as GradeD } from 'assets/images/icon/GradeD.svg';
import { ReactComponent as PlayIcon } from 'assets/images/icon/play-circle-filled.svg';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';

const LanguageResultBox = ({ test }) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();
  const [resultValue, setResultValue] = useState({});
  const [resultKey, setResultKey] = useState({});
  const [resultGrade, setResultGrade] = useState([]);
  const [grade, setGrade] = useState();

  /** ==========
   * @function i18nKeyReturn 
   * key값에 따라 return되는 key값 변환 함수
   * @param {*} obj (object): 언어 테스트 별 정보
   * @param  {...any} args (array): 변환 시킬 key값
   * @returns 
  ============== */
  const i18nKeyReturn = (obj, ...args) => {
    const [titleEng, titleChi, titleJap] = args;
    const language = Object.keys(obj)[0];
    let key;

    switch (language) {
      case 'english':
        key = titleEng;
        break;
      case 'chinese':
        key = titleChi;
        break;
      case 'japanese':
        key = titleJap;
        break;
      default:
        key = '';
        break;
    }

    return key;
  };

  /** ==========
   * @function colorChangeByGrade
   * 등급에 따른 색상 변경 함수
   * @param {*} grade (string): 등급
   * @returns
  ============== */
  const colorChangeByGrade = (grade) => {
    let color;

    switch (grade) {
      case 'Advanced':
        color = 'blue';
        break;
      case 'Intermediate':
        color = 'yellow';
        break;
      case 'Beginner':
        color = 'red';
        break;
      default:
        color = '';
        break;
    }

    return color;
  };

  /** ==========
   * @function viewAnswerClickHandler
   * speaking answer modal click event
   * @param {*} video (string)
  ============== */
  const viewAnswerClickHandler = (video) => {
    displayModal('assessments/viewAnswer/Speaking', {
      video,
      event: {
        message: 'Detail Modal Open',
      },
    });
  };

  /** ==========
   * 각 문항(독해, 듣기, 말하기) value 추출
   * 추출한 value의 grade들을 setResultGrade에 저장
  ============== */
  useEffect(() => {
    if (!test) return;

    const values = Object.values(test);
    const keys = Object.keys(test);
    setResultValue(...values);
    setResultKey(...keys);

    const gradeArr = [];

    Object.values(test[keys[0]]).map((val) => {
      gradeArr.push(val.grade);
    });

    setResultGrade(gradeArr);
  }, [test]);

  /** ==========
   * 등급에 따른 점수 계산 / 저장
  ============== */
  useEffect(() => {
    if (resultGrade.length === 0) return;

    const gradeScore = [];

    (resultGrade || []).map((grade) => {
      if (grade === 'Advanced') {
        gradeScore.push(3);
      } else if (grade === 'Intermediate') {
        gradeScore.push(2);
      } else if (grade === 'Beginner') {
        gradeScore.push(1);
      }
    });

    const gradeAverage = gradeScore.reduce((a, b) => a + b, 0);

    // if (gradeAverage !== undefined) {
    //   if (gradeAverage >= 9) {
    //     setGrade(<GradeS />);
    //   } else if (gradeAverage <= 8 && gradeAverage >= 7) {
    //     setGrade(<GradeA />);
    //   } else if (gradeAverage <= 6 && gradeAverage >= 5) {
    //     setGrade(<GradeB />);
    //   } else if (gradeAverage <= 4 && gradeAverage >= 3) {
    //     setGrade(<GradeC />);
    //   } else {
    //     setGrade(<GradeD />);
    //   }

    if (gradeAverage !== undefined) {
      if (gradeAverage >= 6) {
        setGrade(<GradeA />);
      } else if (gradeAverage <= 5 && gradeAverage >= 4) {
        setGrade(<GradeB />);
      } else if (gradeAverage <= 3 && gradeAverage >= 2) {
        setGrade(<GradeC />);
      } else if (gradeAverage <= 1 && gradeAverage >= 0) {
        setGrade(<GradeD />);
      } else {
        setGrade(<GradeD />);
      }
    }
  }, [resultGrade]);

  return (
    <TableRow className="body-tr">
      <TableCell className="title body-td fs16">
        {i18nKeyReturn(
          test,
          t('title.english-test'),
          t('title.chinese-test'),
          t('title.japanese-test')
        )}
      </TableCell>
      <TableCell className="total-result body-td">{grade && grade}</TableCell>
      <TableCell
        className={`grade body-td fs16 ${colorChangeByGrade(
          resultValue?.reading?.grade
        )}`}
      >
        {resultValue && resultValue?.reading?.grade}
      </TableCell>
      <TableCell
        className={`grade body-td fs16 ${colorChangeByGrade(
          resultValue?.listening?.grade
        )}`}
      >
        {resultValue && resultValue?.listening?.grade}
      </TableCell>
      <TableCell
        className={`grade body-td fs16 ${colorChangeByGrade(
          resultValue?.speaking?.grade
        )}`}
      >
        <div className="flex">
          {resultValue && resultValue?.speaking?.grade}
          <Button
            label={t('btn.language-video-answer')}
            size="32"
            theme="borderGrey"
            frontIcon={<PlayIcon />}
            onClick={() =>
              viewAnswerClickHandler(
                resultValue && resultValue?.speaking?.[resultKey]
              )
            }
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default LanguageResultBox;
