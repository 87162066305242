import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const KhaiPContentsRight = ({ interviewData }) => {
  const { t } = useTranslation();
  const [mainQuestion, setMainQuestion] = useState('');
  const [subQuestion, setSubQuestion] = useState([]);
  const [subQuestionItem, setSubQuestionItem] = useState([]);

  useEffect(() => {
    if (interviewData === '') return;

    const questionSplit = interviewData?.split('||');
    setMainQuestion(questionSplit[0]);
    setSubQuestion(questionSplit[1]);
  }, [interviewData]);

  useEffect(() => {
    if (subQuestion.length === 0) return;

    const subItem = subQuestion
      .split('|')
      .map((item) => item.replace('- ', ''));
    setSubQuestionItem(subItem);
  }, [subQuestion]);

  return (
    <aside className="interview-question-wrap">
      <h6 className="interview-question-title">
        {t('title.interview-questions')}
      </h6>
      <div className="interview-question">
        <p className="interview-main-quesetion">
          {mainQuestion && mainQuestion}
        </p>
        <ul className="interview-sub-question">
          {(subQuestionItem || []).map((question, idx) => (
            <li key={`item_${idx}`} className="interview-sub-question-item">
              {question}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default KhaiPContentsRight;
