import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { Button } from 'components/common/Button';
import { Timestamp } from 'firebase/firestore';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-modal.svg';
import { executeAction } from 'utils/redux';
import {
  setCandidateOfRecruitment,
  updateRecruitmentCandidate,
} from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';
import { dateToDateFormat } from 'utils/string';
import { useTranslation } from 'react-i18next';
import BasicSelect from '../../components/select/BasicSelect';
import { gucciPosition } from '../../constants/gucci/position';
import { gucciStore } from '../../constants/gucci/storeList';

const SuccessfulStore = ({ open, close, recruitment, currentCandidate }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [store, setStore] = useState('');
  const [job, setJob] = useState('');
  const [dueDate, setDueDate] = useState(0);

  const jobHandleInput = (e, menu, type) => {
    setJob(menu);
  };

  const storeHandleInput = (e, menu, type) => {
    setStore(menu);
  };

  useEffect(() => {
    const today = new Date();
    const threeDaysLater = new Date(today.setDate(today.getDate() + 3));
    setDueDate(
      dateToDateFormat(new Date(threeDaysLater), recruitment?.language)
    );
  }, []);

  useEffect(() => {
    let flag = true;

    if (!store) {
      flag = false;
    }

    if (!job) {
      flag = false;
    }

    setCanSubmit(flag);
  }, [store, job]);

  const sendPassedEmail = () => {
    if (!canSubmit) return;

    setIsLoading(true);

    if (currentCandidate.recruitProgress === 3) {
      executeAction(
        setCandidateOfRecruitment({
          aid: recruitment?.rid,
          cid: currentCandidate?.email,
          candidateFirstName: currentCandidate?.firstName,
          candidateLastName: currentCandidate?.lastName,
          type: 'passedStore',
          lang: 'ko',
          companyAddr: t(store.i18nKey),
          phoneNumber: currentCandidate?.progressSubmit[1].phoneNumber,
          dueDate,
          job: job.key,
          step: 4,
          progressSubmit: {
            4: {
              companyAddr: store.key,
              job: job.key,
              invitedAt: Timestamp.now(),
              state: 'waiting',
            },
          },
        })
      )
        .unwrap()
        .then(() => {
          close(false);
          executeAction(
            updateRecruitmentCandidate({
              aid: recruitment?.rid,
              cid: currentCandidate?.email,
              recruitProgress: 4,
            })
          )
            .unwrap()
            .then(() => {
              toast(t('dialog.title.invite-email-sent'));
              setIsLoading(false);
            })
            .catch((e) => {
              console.error(e);
              setIsLoading(false);
            });
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    } else if (currentCandidate.recruitProgress === 4) {
      executeAction(
        setCandidateOfRecruitment({
          aid: recruitment?.rid,
          cid: currentCandidate?.email,
          type: 'passedStore',
          lang: 'ko',
          candidateFirstName: currentCandidate?.firstName,
          candidateLastName: currentCandidate?.lastName,
          companyAddr: store.key,
          phoneNumber: currentCandidate?.progressSubmit[1].phoneNumber,
          dueDate,
          job: job.key,
          step: 5,
          progressSubmit: {
            5: {
              companyAddr: store.key,
              job: job.key,
              invitedAt: Timestamp.now(),
              state: 'waiting',
            },
          },
        })
      )
        .unwrap()
        .then(() => {
          close(false);
          executeAction(
            updateRecruitmentCandidate({
              aid: recruitment?.rid,
              cid: currentCandidate?.email,
              recruitProgress: 5,
            })
          )
            .unwrap()
            .then(() => {
              toast(t('dialog.title.invite-email-sent'));
              setIsLoading(false);
            })
            .catch((e) => {
              console.error(e);
              setIsLoading(false);
            });
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  };

  return (
    <Modal open={open}>
      <div className="modal-invited-member modal-successful-modal">
        <section className="invited-member-container">
          <article className="invited-header-wrapper">
            <h3 className="fs16">{t('title.send-compensation-email')}</h3>
            <span onClick={() => close(false)}>
              <CloseIcon />
            </span>
          </article>
          <article className="invited-form-wrapper">
            <BasicSelect
              label={t('text-field.label.store')}
              className={'currency-form'}
              value={store}
              name={'store'}
              required
              onChange={storeHandleInput}
              menus={gucciStore}
            />
            <BasicSelect
              label={t('text-field.label.job-position')}
              className={'currency-form'}
              value={job}
              name={'job'}
              required
              onChange={jobHandleInput}
              menus={gucciPosition}
            />
          </article>
          <article className="invited-button">
            <Button
              theme={'grey'}
              size={56}
              label={t('btn.invite-email')}
              isDisabled={!canSubmit && true}
              isLoading={isLoading}
              fontSize={'fs16'}
              onClick={!isLoading && canSubmit ? sendPassedEmail : null}
            />
          </article>
        </section>
      </div>
    </Modal>
  );
};

export default SuccessfulStore;
