import React, { useState } from 'react';
import { convertHtmlForDisplay } from 'utils/string';
import UpdateScoreModal from 'modals/assessments/UpdateScoreModal';
import ResultScoreBox from './ResultScoreBox';

const EssayResult = ({
  visibilityDropdown,
  question,
  candidate,
  assessment,
  setRefreshing,
  test,
  idx,
  type,
}) => {
  const [isOpenScoreModal, setIsOpenScoreModal] = useState(false);

  const onUpdateScoreModal = () => {
    setIsOpenScoreModal(true);
  };

  const scoreDisplay = (score) => {
    let tuuid;

    if (type === 'custom') {
      tuuid = question?.uuid;
    } else {
      tuuid = question?.id;
    }

    if (type === 'current') {
      tuuid = test?.uuid || question?.id;
    }

    if (score || score === 0) {
      return Math.trunc(candidate?.results[tuuid]?.score * 100);
    }
    if (score === undefined || score === null) {
      return Math.trunc(candidate?.results[tuuid]?.preset_score * 100);
    }
  };

  return (
    <aside
      className={`toggle-body-content page-break ${
        visibilityDropdown ? 'slide-in' : 'slide-out'
      }`}
    >
      <div className="toggle-body-title-box">
        <div className="print-toggle-body-question">
          <span className="question-number">Q{idx + 1}.</span>
          <pre
            className="question"
            dangerouslySetInnerHTML={{
              __html: convertHtmlForDisplay(question?.question),
            }}
          />
        </div>
      </div>
      <div className="toggle-body-answer-box">
        <aside className="answer-box">
          {question?.id !== '99101000006' && (
            <ResultScoreBox
              score={
                scoreDisplay(
                  candidate?.results[question?.uuid || question?.id]?.score
                ) || 0
              }
              onClick={onUpdateScoreModal}
              isHideButton={
                type === 'custom'
                  ? false
                  : type === 'print' || type !== 'current'
              }
            />
          )}
          <div className="result-stt-box">
            <p
              className="results-desc"
              dangerouslySetInnerHTML={{
                __html: convertHtmlForDisplay(
                  type === 'custom'
                    ? candidate?.results[test?.uuid]?.text
                    : candidate?.results[question?.id]?.text
                ),
              }}
            />
          </div>
        </aside>
      </div>

      {isOpenScoreModal && (
        <UpdateScoreModal
          open={isOpenScoreModal}
          close={setIsOpenScoreModal}
          setRefreshing={setRefreshing}
          category_skill={test?.category_skill}
          assessment={assessment}
          candidate={candidate}
          question={question}
          type="essay"
        />
      )}
    </aside>
  );
};

export default EssayResult;
