import DetailContainer from 'components/recruitment/detail/DetailContainer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SettingContainer from '../../../../../components/recruitment/detail/SettingContainer';

const DashboardDetail = () => {
  const { t } = useTranslation();
  const { rid } = useParams();
  const { getRecruitmentsSuccess } = useSelector((state) => state.recruitments);
  const { userInfo } = useSelector((state) => state.auth);

  const [recruitment, setRecruitment] = useState({});

  const [selectedMenu, setSelectedMenu] = useState('status');

  useEffect(() => {
    if (!rid || !getRecruitmentsSuccess) return;
    const recruit = getRecruitmentsSuccess?.find((item) => item.rid === rid);

    setRecruitment(recruit);
  }, [rid, getRecruitmentsSuccess]);

  return (
    <main id="pages-recruitment-detail">
      <section className="recruitment-tab-container">
        <ul>
          <li
            className={`status fs14 bold ${
              selectedMenu === 'status' ? 'active' : ''
            }`}
            onClick={() => setSelectedMenu('status')}
          >
            {t('tab.status')}
          </li>
          {userInfo?.memberType !== 15 && (
            <li
              className={`setting fs14 bold ${
                selectedMenu === 'setting' ? 'active' : ''
              }`}
              onClick={() => setSelectedMenu('setting')}
            >
              {t('tab.setting')}
            </li>
          )}
        </ul>
      </section>
      <section className="dashboard-detail-container">
        {selectedMenu === 'status' ? (
          <DetailContainer recruitment={recruitment} userInfo={userInfo} />
        ) : (
          <SettingContainer recruitment={recruitment} />
        )}
      </section>
    </main>
  );
};

export default DashboardDetail;
