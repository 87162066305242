export const setInitialPeriod = (isPeriod, setStartPeriod, setEndPeriod) => {
  if (isPeriod) {
    const now = new Date();

    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    let oneMonthLaterMonth = currentMonth + 1;
    let oneMonthLaterYear = currentYear;

    if (oneMonthLaterMonth > 11) {
      oneMonthLaterMonth = 0;
      oneMonthLaterYear += 1;
    }

    const oneMonthLater = new Date(oneMonthLaterYear, oneMonthLaterMonth, now.getDate(), now.getHours(), now.getMinutes());

    setStartPeriod(now.getTime());
    setEndPeriod(oneMonthLater.getTime());
  }
};

export const checkValidationPeriod = (isPeriod, startPeriod, endPeriod) => {
  if (isPeriod && (!startPeriod || !endPeriod))
    return 'none period';
  if (
    (isPeriod && startPeriod >= endPeriod) ||
    (isPeriod && endPeriod < new Date().getTime())
  ) {
    return 'end period error';
  }
    return 'success';
};
