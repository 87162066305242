import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  _createAssessment,
  _updateAssessment,
  _updatePeriod,
  _getAssessmentsOfCurrentUser,
  _getAssessment,
  _setAssessment,
  _createLibraryDoc,
  _getLibraryDocs,
  _deleteLibraryDoc,
  _setCandidateDoc,
  _addCandidate,
  _getDefaultTemplate,
  _editEmailContent,
  _getEditText,
  _getCandidatesOfAssessment,
  _updateCandidate,
  _deleteCandidateOfAssesssment,
  _getPositions,
  _getSkills,
  _getLibSkills,
  _getLibTests,
  _gradeResult,
  _getTestInfo,
  _getLibTestInfo,
  _getAttributes,
  _getCultureAttributes,
  _createSpreadsheets,
  _getDefLibraryDocs,
  _setOBPIBenchmarkToAssessment,
  _setConditionOfCandidate,
  _getScoreResult,
  _stylingGradeResult,
  _addEvaluator,
  _setEvaluatorDoc,
  _updateEvaluator,
  _getEvaluatorsOfAssessment,
  _deleteEvaluatorOfAssesssment,
  _copySpreadSheet,
  _presetGradeResult,
  _setKHAIRetryResults,
} from 'utils/firebase/assessments';
import { _getUserInfo } from 'utils/firebase/auth';
import { serializeError } from 'serialize-error';
import { isSameLowerCaseString } from 'utils/string';
import axios from 'axios';
import { useAverageScore } from 'utils/hooks';
import { convertGradeToScore } from 'utils/converter';

const initialState = {
  testsAverageLoading: false,
  testsAverageError: null,
  testsAverageSuccess: null,
};

export const createAssessment = createAsyncThunk(
  'assessments/createAssessmen',
  async (payload, { getState, rejectWithValue }) => {
    // const {
    //   name,
    //   position,
    //   tests,
    //   groupTests,
    //   objectiveTests,
    //   subjectiveTests,
    // } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _createAssessment({
        uid,
        ...payload,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateAssessment = createAsyncThunk(
  'assessments/updateAssessment',
  async (payload = {}, { rejectWithValue, getState }) => {
    // const {
    //   aid,
    //   name,
    //   language,
    //   tests,
    //   groupTests,
    //   objectiveTests,
    //   subjectiveTests,
    // } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _updateAssessment({
        uid,
        ...payload,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updatePeriod = createAsyncThunk(
  'assessments/updatePeriod',
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _updatePeriod({
        uid,
        ...payload,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getAssessmentsOfCurrentUser = createAsyncThunk(
  'assessments/getAssessmentsOfCurrentUser',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;

      if(!uid) return;

      const result = await _getAssessmentsOfCurrentUser({
        uid,
      });
      const tasks = new Array();
      for (const doc of result) {
        const aid = doc?.id;
        if (doc.periodOnOff && doc.endPeriodDateTime < new Date().getTime()) {
          tasks.push(dispatch(changeStateOfAssessment({ aid })).unwrap());
          tasks.push(
            dispatch(
              updatePeriod({
                aid,
                periodOnOff: false,
                startPeriodDateTime: doc.startPeriodDateTime,
                endPeriodDateTime: doc.endPeriodDateTime,
              })
            ).unwrap()
          );
        }
      }
      await Promise.all(tasks);

      const docs = await _getAssessmentsOfCurrentUser({ uid });
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getAssessmentOfCurrentUser = createAsyncThunk(
  'assessments/getAssessmentOfCurrentUser',
  async (payload = {}, { getState, rejectWithValue, dispatch }) => {
    const { aid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;

      const result = await _getAssessment({
        uid,
        aid,
      });

      const tasks = new Array();
      if (
        result.periodOnOff &&
        result.endPeriodDateTime < new Date().getTime()
      ) {
        tasks.push(dispatch(changeStateOfAssessment({ aid })).unwrap());
        tasks.push(
          dispatch(
            updatePeriod({
              aid,
              periodOnOff: false,
              startPeriodDateTime: result.startPeriodDateTime,
              endPeriodDateTime: result.endPeriodDateTime,
            })
          ).unwrap()
        );
      }

      await Promise.all(tasks);

      const doc = await _getAssessment({ uid, aid });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

/** ================
 * @function getAssessmentOfEvaluatorUser
 * assessment의 정보를 불러온다
==================== */
export const getAssessmentOfEvaluatorUser = createAsyncThunk(
  'assessments/getAssessmentOfEvaluatorUser',
  async (payload = {}, { getState, rejectWithValue }) => {
    const { aid } = payload;

    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;

      const doc = await _getAssessment({
        uid,
        aid,
      });

      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const changeStateOfAssessment = createAsyncThunk(
  'assessments/changeStateOfAssessment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;

      const { archived: isArchived } = await _getAssessment({ uid, aid });

      return await _setAssessment({
        uid,
        aid,
        data: {
          archived: !isArchived,
        },
      });
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const changeConditionOfCandidate = createAsyncThunk(
  'assessments/changeConditionOfCandidate',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, cid, condition } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      return await _setConditionOfCandidate({
        uid,
        aid,
        cid,
        condition,
      });
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const createLibraryDoc = createAsyncThunk(
  'assessments/createLibraryDoc',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { subjectiveTests } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const docs = await _getLibraryDocs({ uid });
      const _subjectiveTests = (subjectiveTests || []).filter((x) => {
        if (
          (docs || []).findIndex((y) => isSameLowerCaseString(y.uuid, x.uuid)) >
          -1
        ) {
          return false;
        }
        return true;
      });

      await _createLibraryDoc({
        uid,
        subjectiveTests: _subjectiveTests || [],
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getLibraryDocs = createAsyncThunk(
  'assessments/getLibraryDocs',
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const docs = await _getLibraryDocs({ uid });

      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getDefLibraryDocs = createAsyncThunk(
  'assessments/getDefLibraryDocs',
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { lang } = payload;
      const docs = await _getDefLibraryDocs({ lang });

      return {
        defdocs: docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const deleteLibraryDoc = createAsyncThunk(
  'assessments/deleteLibraryDoc',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { lid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _deleteLibraryDoc({ uid, lid });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

/**
 * 한명씩 email 초대
 */
export const sendEmailToCandidate = createAsyncThunk(
  'assessments/sendEmailToCandidate',
  async (payload = {}, { rejectWithValue, getState }) => {
    const {
      type,
      aid,
      firstName,
      lastName,
      email,
      testNumber,
      birth,
      objective,
      lang,
    } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const { logoURL, corporation } = await _getUserInfo({
        uid,
      });
      if (!type || type === 'send') {
        await _addCandidate({
          uid,
          aid,
          firstName,
          lastName,
          email,
          testNumber,
          birth,
          objective,
        });

        await _setCandidateDoc({
          uid,
          aid,
          firstName,
          lastName,
          email,
          testNumber,
          birth,
          objective,
        });
      } else if (type === 'resend') {
        await _updateCandidate({
          uid: uid || '',
          aid: aid || '',
          email: email || '',
          testNumber,
          birth,
          objective,
        });
        await _setCandidateDoc({
          uid,
          aid,
          firstName,
          lastName,
          email,
          testNumber,
          birth,
          objective,
        });
      }
      let editText = await _getEditText({
        uid,
        aid,
        key: 'edit_invite_email',
        lang: lang || getState().variable.lang.slice(0, 2),
      });

      var candidate_name = '';
      switch (lang || getState().variable.lang.slice(0, 2)) {
        case 'en':
          candidate_name = `${firstName} ${lastName}`;
          break;
        case 'ko':
          candidate_name = `${lastName} ${firstName}`;
          break;
        default:
          candidate_name = `${firstName} ${lastName}`;
          break;
      }
      editText = editText.replaceAll('{candidate_name}', candidate_name);
      editText = editText.replaceAll('{company_name}', corporation);
      await axios.post(
        `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/assessment/invite/email`,
        {
          email,
          logoURL,
          companyName: corporation,
          editText,
          uid,
          aid,
          firstName,
          lastName,
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
            'User-Lang': lang || getState().variable.lang.slice(0, 2),
          },
        }
      );
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

/**
 * bulk email 초대
 */
export const sendEmailToCandidates = createAsyncThunk(
  'assessments/sendEmailToCandidates',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { aid, candidates, lang } = payload;
    try {
      for (const row of candidates) {
        const [firstName, lastName, email, testNumber, birth, objective] =
          row || [];
        try {
          await dispatch(
            sendEmailToCandidate({
              aid,
              firstName,
              lastName,
              email,
              testNumber,
              birth,
              objective,
              lang,
            })
          ).unwrap();
        } catch (e) {
          Sentry.captureException(e);
        }
      }
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

/** ================
 * @function sendEmailToEvaluator
 * 1. 특정 assessment에 evaluators를 생성하고 이메일을 발송한다.
 * 2. type에 따라 신규생성, 업데이트/이메일 재발송으로 분기처리 된다.
==================== */
export const sendEmailToEvaluator = createAsyncThunk(
  'assessments/sendEmailToEvaluator',
  async (payload = {}, { rejectWithValue, getState }) => {
    const {
      type,
      aid,
      firstName,
      lastName,
      email,
      lang,
      title,
      subjectiveTests,
      objectiveTests,
      color,
      adminEmail,
    } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const { logoURL, corporation } = await _getUserInfo({
        uid,
      });
      if (!type || type === 'send') {
        await _addEvaluator({
          uid,
          aid,
          email,
          title,
          subjectiveTests,
          objectiveTests,
          color,
        });
        await _setEvaluatorDoc({
          uid,
          aid,
          email,
        });
      } else if (type === 'resend') {
        await _updateEvaluator({
          uid: uid || '',
          aid: aid || '',
          email: email || '',
        });
        await _setEvaluatorDoc({
          uid,
          aid,
          email,
        });
      }

      await axios.post(
        `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/evaluation/invite/email`,
        {
          email,
          adminEmail,
          title,
          logoURL,
          companyName: corporation,
          uid,
          aid,
          firstName,
          lastName,
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
            'User-Lang': lang || getState().variable.lang.slice(0, 2),
          },
        }
      );
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateCandidate = createAsyncThunk(
  'assessments/updateCandidate',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, email, data } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _updateCandidate({
        uid: uid || '',
        aid: aid || '',
        email: email || '',
        data,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const sendEmailReview = createAsyncThunk(
  'assessments/sendEmailReview',
  async (payload = {}, { rejectWithValue, getState, dispatch }) => {
    const { aid, email, firstName, lastName, lang } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const { logoURL, corporation } = await _getUserInfo({
        uid,
      });
      await dispatch(
        updateCandidate({
          aid,
          email,
          data: {
            condition: 2,
            progress: 2,
          },
        })
      ).unwrap();

      let editText = await _getEditText({
        uid,
        aid,
        key: 'edit_review_email',
        lang: lang || getState().variable.lang,
      });
      var candidate_name = '';
      switch (lang || getState().variable.lang.slice(0, 2)) {
        case 'en':
          candidate_name = `${firstName} ${lastName}`;
          break;
        case 'ko':
          candidate_name = `${lastName} ${firstName}`;
          break;
        default:
          candidate_name = `${firstName} ${lastName}`;
          break;
      }
      editText = editText.replaceAll('{candidate_name}', candidate_name);
      editText = editText.replaceAll('{company_name}', corporation);

      await axios.post(
        `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/assessment/send/email/review`,
        {
          email,
          logoURL,
          companyName: corporation,
          editText,
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
            'User-Lang': lang || getState().variable.lang.slice(0, 2),
          },
        }
      );

      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const sendEmailReject = createAsyncThunk(
  'assessments/sendEmailReject',
  async (payload = {}, { rejectWithValue, dispatch, getState }) => {
    const { aid, email, firstName, lastName, lang } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const { logoURL, corporation } = await _getUserInfo({
        uid,
      });
      await dispatch(
        updateCandidate({
          aid,
          email,
          data: {
            condition: 2,
            progress: 3,
          },
        })
      ).unwrap();

      let editText = await _getEditText({
        uid,
        aid,
        key: 'edit_reject_email',
        lang: lang || getState().variable.lang,
      });
      var candidate_name = '';
      switch (lang || getState().variable.lang.slice(0, 2)) {
        case 'en':
          candidate_name = `${firstName} ${lastName}`;
          break;
        case 'ko':
          candidate_name = `${lastName} ${firstName}`;
          break;
        default:
          candidate_name = `${firstName} ${lastName}`;
          break;
      }
      editText = editText.replaceAll('{candidate_name}', candidate_name);
      editText = editText.replaceAll('{company_name}', corporation);

      await axios.post(
        `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/assessment/send/email/reject`,
        {
          email,
          logoURL,
          companyName: corporation,
          editText,
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
            'User-Lang': lang || getState().variable.lang.slice(0, 2),
          },
        }
      );
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const sendEmailPass = createAsyncThunk(
  'assessments/sendEmailPass',
  async (payload = {}, { rejectWithValue, dispatch, getState }) => {
    const { aid, email, firstName, lastName, lang } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const { logoURL, corporation } = await _getUserInfo({
        uid,
      });
      await dispatch(
        updateCandidate({
          aid,
          email,
          data: {
            condition: 2,
            progress: 4,
          },
        })
      ).unwrap();

      let editText = await _getEditText({
        uid,
        aid,
        key: 'edit_pass_email',
        lang: lang || getState().variable.lang,
      });
      var candidate_name = '';
      switch (lang || getState().variable.lang.slice(0, 2)) {
        case 'en':
          candidate_name = `${firstName} ${lastName}`;
          break;
        case 'ko':
          candidate_name = `${lastName} ${firstName}`;
          break;
        default:
          candidate_name = `${firstName} ${lastName}`;
          break;
      }
      editText = editText.replaceAll('{candidate_name}', candidate_name);
      editText = editText.replaceAll('{company_name}', corporation);

      await axios.post(
        `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/assessment/send/email/pass`,
        {
          email,
          logoURL,
          companyName: corporation,
          editText,
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
            'User-Lang': lang || getState().variable.lang.slice(0, 2),
          },
        }
      );

      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const sendEmailRemind = createAsyncThunk(
  'assessments/sendEmailRemind',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { type, aid, firstName, lastName, email, lang } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const { logoURL, corporation } = await _getUserInfo({
        uid,
      });
      await _updateCandidate({
        uid: uid || '',
        aid: aid || '',
        email: email || '',
      });
      await _setCandidateDoc({
        uid,
        aid,
        firstName,
        lastName,
        email,
      });
      let editText = await _getEditText({
        uid,
        aid,
        key: 'edit_remind_email',
        lang: lang || getState().variable.lang.slice(0, 2),
      });
      var candidate_name = '';
      switch (lang || getState().variable.lang.slice(0, 2)) {
        case 'en':
          candidate_name = `${firstName} ${lastName}`;
          break;
        case 'ko':
          candidate_name = `${lastName} ${firstName}`;
          break;
        default:
          candidate_name = `${firstName} ${lastName}`;
          break;
      }
      editText = editText.replaceAll('{candidate_name}', candidate_name);
      editText = editText.replaceAll('{company_name}', corporation);
      await axios.post(
        `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/assessment/send/email/remind`,
        {
          email,
          logoURL,
          companyName: corporation,
          editText,
          uid,
          aid,
          firstName,
          lastName,
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
            'User-Lang': lang || getState().variable.lang.slice(0, 2),
          },
        }
      );
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const pass = createAsyncThunk(
  'assessments/pass',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { aid, email } = payload;
    try {
      await dispatch(
        updateCandidate({
          aid,
          email,
          data: {
            condition: 2,
            progress: 4,
          },
        })
      ).unwrap();

      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const review = createAsyncThunk(
  'assessments/review',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { aid, email } = payload;
    try {
      await dispatch(
        updateCandidate({
          aid,
          email,
          data: {
            condition: 2,
            progress: 2,
          },
        })
      ).unwrap();
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const reject = createAsyncThunk(
  'assessments/reject',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { aid, email } = payload;
    try {
      await dispatch(
        updateCandidate({
          aid,
          email,
          data: {
            condition: 2,
            progress: 3,
          },
        })
      ).unwrap();
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const deleteCandidateOfAssesssment = createAsyncThunk(
  'assessments/deleteCandidateOfAssesssment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, email } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _deleteCandidateOfAssesssment({
        uid,
        aid,
        email,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

/** ===================
 * @function deleteEvaluatorOfAssesssment
 * 초대한 평가자 삭제 slice
======================= */
export const deleteEvaluatorOfAssesssment = createAsyncThunk(
  'assessments/deleteEvaluatorOfAssesssment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, email } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _deleteEvaluatorOfAssesssment({
        uid,
        aid,
        email,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getDefaultTemplate = createAsyncThunk(
  'assessments/getDefaultTemplate',
  async (payload = {}, { rejectWithValue }) => {
    const { tid } = payload;
    try {
      const doc = await _getDefaultTemplate({ tid });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const editEmailContent = createAsyncThunk(
  'assessments/editEmailContent',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, key, value } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _editEmailContent({
        uid,
        aid,
        key,
        value,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getEditText = createAsyncThunk(
  'assessments/getEditText',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, key, lang } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const editText = await _getEditText({
        uid,
        aid,
        key,
        lang: lang || getState().variable.lang,
      });
      return {
        editText,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

/**
 * @function getAssessment 평가 리스트를 불러옴
 */
export const getAssessment = createAsyncThunk(
  'assessments/getAssessment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const doc = await _getAssessment({ uid, aid });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getAssessments = createAsyncThunk(
  'assessments/getAssessments',
  async (payload = {}, { rejectWithValue, getState, dispatch }) => {
    const { aids } = payload;
    try {
      const tasks = new Array();
      for (const aid of aids) {
        tasks.push(dispatch(getAssessment({ aid })).unwrap());
      }
      const resultPromise = await Promise.all(tasks || []);
      const docs = (resultPromise || []).map((it) => it.doc);
      return {
        docs,
      };
    } catch (e) {
      console.dir(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidatesOfAssessment = createAsyncThunk(
  'assessments/getCandidatesOfAssessment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const docs = await _getCandidatesOfAssessment({ uid, aid });
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

/** ===================
 * @function getEvaluatorsOfAssessment
 * 특정 assessment 안에 있는 평가자 목록 불러오는 slice
======================= */
export const getEvaluatorsOfAssessment = createAsyncThunk(
  'assessments/getEvaluatorsOfAssessment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const docs = await _getEvaluatorsOfAssessment({ uid, aid });
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidatesOfAssessments = createAsyncThunk(
  'assessments/getCandidatesOfAssessments',
  async (payload = {}, { rejectWithValue, getState, dispatch }) => {
    const { assessments } = payload;
    try {
      const tasks = new Array();
      for (const assessment of assessments) {
        const aid = assessment?.id;
        tasks.push(dispatch(getCandidatesOfAssessment({ aid })).unwrap());
      }
      const tasksResult = await Promise.all(tasks);
      const candidates = (tasksResult || []).map((it) => it.docs);
      return {
        candidates,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

/** =================
 * @function getEvaluatorsOfAssessments
 * 여러 assessments를 배열로 돌아 getEvaluatorsOfAssessment에 각각의 aid를 전달한다.
===================== */
export const getEvaluatorsOfAssessments = createAsyncThunk(
  'assessments/getEvaluatorsOfAssessments',
  async (payload = {}, { rejectWithValue, getState, dispatch }) => {
    const { assessments } = payload;
    try {
      const tasks = new Array();
      for (const assessment of assessments) {
        const aid = assessment?.id;
        tasks.push(dispatch(getEvaluatorsOfAssessment({ aid })).unwrap());
      }
      const tasksResult = await Promise.all(tasks);
      const evaluators = (tasksResult || []).map((it) => it.docs);
      return {
        evaluators,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getPositions = createAsyncThunk(
  'assessments/getPositions',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const docs = await _getPositions();
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getSkills = createAsyncThunk(
  'assessments/getSkills',
  async (payload = {}, { rejectWithValue }) => {
    const lang = payload;
    try {
      const docs = await _getSkills(lang);
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getLibSkills = createAsyncThunk(
  'assessments/getLibSkills',
  async (payload = {}, { rejectWithValue }) => {
    const lang = payload;
    try {
      const docs = await _getLibSkills(lang);
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getLibTests = createAsyncThunk(
  'assessments/getLibTests',
  async (payload = {}, { rejectWithValue }) => {
    const lang = payload;
    try {
      const docs = await _getLibTests(lang);
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const gradeResult = createAsyncThunk(
  'assessments/gradeResult',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, cid, tuuid, score, comment } = payload;

    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _gradeResult({
        uid,
        aid,
        cid,
        tuuid,
        score,
        comment,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const stylingGradeResult = createAsyncThunk(
  'assessments/gradeResult',
  async (payload = {}, { rejectWithValue, getState }) => {
    const {
      aid,
      cid,
      tuuid,
      originalityScore,
      fashionScore,
      fitScore,
      score,
      communicationScore,
      comment,
    } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _stylingGradeResult({
        uid,
        aid,
        cid,
        tuuid,
        originalityScore,
        fashionScore,
        fitScore,
        communicationScore,
        score,
        comment,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const presetGradeResult = createAsyncThunk(
  'assessments/presetGradeResult',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, cid, tuuid, preset_score, text } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _presetGradeResult({
        uid,
        aid,
        cid,
        tuuid,
        preset_score,
        text,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getScoreResult = createAsyncThunk(
  'assessments/getScoreResult',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, cid, tuuid, score } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _getScoreResult({
        uid,
        aid,
        cid,
        tuuid,
        score,
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getTestInfos = createAsyncThunk(
  'assessments/getTestInfo',
  async (payload = {}, { rejectWithValue }) => {
    const { tids, lang } = payload;
    try {
      const tasks = new Array();
      for (const tid of tids) {
        tasks.push(
          _getTestInfo({
            tid,
            lang,
          })
        );
      }
      const promiseResult = await Promise.all(tasks);
      return {
        docs: promiseResult,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getLibTestInfos = createAsyncThunk(
  'assessments/getLibTestInfo',
  async (payload = {}, { rejectWithValue }) => {
    const { tids, lang } = payload;
    try {
      const tasks = new Array();
      for (const tid of tids) {
        tasks.push(
          _getLibTestInfo({
            tid,
            lang,
          })
        );
      }
      const docs = await Promise.all(tasks);
      return docs;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getAttributes = createAsyncThunk(
  'assessments/getAttributes',
  async (payload = {}, { rejectWithValue }) => {
    const { sid } = payload;
    try {
      const docs = await _getAttributes({
        sid,
      });
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCultureAttributes = createAsyncThunk(
  'assessments/getCultureAttributes',
  async (payload = {}, { rejectWithValue }) => {
    const { sid } = payload;
    try {
      const docs = await _getCultureAttributes({
        sid,
      });
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const createSpreadsheets = createAsyncThunk(
  'assessments/createSpreadsheets',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { spreadsheetsUrl, spreadsheetsId } = await _createSpreadsheets();
      return {
        spreadsheetsUrl,
        spreadsheetsId,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const copySpreadsheet = createAsyncThunk(
  'assessments/copySpreadsheet',
  async (payload = {}, { rejectWithValue }) => {
    const { spreadsheetId } = payload;
    try {
      const { data, status } = await _copySpreadSheet({
        spreadsheetId,
        uid: 'uid',
        aid: 'aid',
        cid: 'cid',
      });
      const spreadsheetsId = data?.id;
      const spreadsheetsUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetsId}/edit#gid=0`;
      return {
        spreadsheetsUrl,
        spreadsheetsId,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const setOBPIBenchmarkToAssessment = createAsyncThunk(
  'assessments/setOBPIBenchmarkToAssessment',
  async (payload = {}, { getState, rejectWithValue }) => {
    const { aid, obpiBenchmark } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      await _setOBPIBenchmarkToAssessment({ uid, aid, obpiBenchmark });
      return {
        success: true,
      };
    } catch (e) {
      console.dir(e);
      return rejectWithValue(serializeError(e));
    }
  }
);

export const testsAverage = createAsyncThunk(
  'assessments/testsAverage',
  async (payload = {}, { dispatch, rejectWithValue }) => {
    const { tests, candidate } = payload;

    try {
      const { results } = candidate;

      const testsArr = tests?.map((test, idx) => {
        if (
          test?.id === '6010' ||
          test?.section === 'file-upload' ||
          test?.section === 'multiple-choice'
        )
          return;

        const testArr = [];
        const { question } = test;

        if (test?.section && test?.section !== 'language') {
          // 커스텀 질문(비디오, 주관식) 일 경우
          testArr.push(results[test?.uuid]);
        } else if (test?.question_type === 'tag.multiple-choice') {
          testArr.push(results[test?.uuid]);
        } else {
          for (const q of question) {
            let count = 0;

            if (q?.section === 'language') {
              Object.keys(results[q?.name])?.forEach((item) => {
                testArr.push({
                  score:
                    q?.score && count < 1
                      ? q?.score
                      : q?.preset_score && count < 1
                      ? q?.preset_score
                      : Math.abs(
                          convertGradeToScore(
                            results[q?.name][item]?.judged_difficulty
                          ) / 10
                        ),
                });
                count += 1;
              });
            } else if (q?.id !== '99101000006') {
              testArr.push(results[q?.id]);
            }
          }
        }

        const removeUndefined = testArr?.filter(
          (item) => item?.preset_score || item?.score
        );

        const scoreArr = removeUndefined?.map((item) => {
          return !item?.score ? item?.preset_score : item?.score;
        });

        const average = useAverageScore(scoreArr);

        return {
          id: idx,
          title: test?.category_skill || test?.title_en || test?.question,
          average: average || 0,
        };
      });

      const filtered = testsArr?.filter((item) => item);
      const sortedData = filtered.sort((a, b) => b.average - a.average);
      return {
        results: sortedData,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getKHAITestResult = createAsyncThunk(
  'testtaker/getKHAITestResult',
  async (payload = {}, { rejectWithValue }) => {
    const { khaitype, lang, item, id } = payload;
    try {
      const khaitestres = await axios.post(
        `${process.env.REACT_APP_SWAGGER_PATH}/munchskill/khai/result`,
        item,
        {
          params: { khaitype, lang, id },
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      return khaitestres.data;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const setKHAIRetryResults = createAsyncThunk(
  'assessments/setKHAIRetryResults',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, cid, sid, result } = payload;

    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;

      return await _setKHAIRetryResults({
        uid,
        aid,
        cid,
        sid,
        result,
      });
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(testsAverage.pending, (state) => {
        state.testsAverageLoading = true;
      })
      .addCase(testsAverage.fulfilled, (state, action) => {
        state.testsAverageLoading = false;
        state.testsAverageSuccess = action.payload?.results;
      })
      .addCase(testsAverage.rejected, (state, action) => {
        state.testsAverageLoading = false;
        state.testsAverageError = action.error.message;
      });
  },
});

export const {} = assessmentsSlice.actions;

export default assessmentsSlice.reducer;
