import React from 'react';

const InterviewScore = ({
  idx,
  type,
  standard,
  standardDesc,
  setScore,
  scoreList,
  score,
}) => {
  const pointArr =
    type === 'language'
      ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      : [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const onHandleScore = (e) => {
    const newArr = [...scoreList];

    newArr[idx] =
      e.target.textContent === 'N/A' ? 'N/A' : parseInt(e.target.textContent);
    setScore(newArr);
  };

  return (
    <div className="score-container">
      {type !== 'language' && (
        <div className="left">
          <div className="standard-title">
            {idx + 1}. {standard}
          </div>
        </div>
      )}
      <div className="right">
        {type !== 'language' && (
          <div className="standard-desc">{standardDesc}</div>
        )}
        <div className="score-container">
          {type !== 'language' && (
            <div
              className={`NA-box ${score === 'N/A' ? 'selected' : ''}`}
              onClick={setScore && ((e) => onHandleScore(e))}
            >
              N/A
            </div>
          )}
          <div className="score-box">
            {pointArr?.map((item) => {
              const level = score < 4 ? 'low' : score < 7 ? 'middle' : 'high';

              return (
                <div
                  className={`middle ${score === item && `${level}-selected`}`}
                  key={item}
                  onClick={setScore && ((e) => onHandleScore(e))}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewScore;
