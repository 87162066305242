import React, { useRef, useState, useEffect } from 'react';
import { useOnClickOutside } from 'utils/hooks';
import { ReactComponent as ChevronBottomIcon } from 'assets/images/icon/chevron-bottom-dropdown.svg';
import { useTranslation } from 'react-i18next';

const BasicSelect = ({
  className,
  value,
  onChange,
  menus,
  label,
  name,
  required,
  caution,
  disabled,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [visibilityDropdown, setVisibilityDropdown] = useState(false);
  const [dropdownAnimation, setDropdownAnimation] = useState(false);
  const [repeat, setRepeat] = useState(null);

  useOnClickOutside(containerRef, () => setVisibilityDropdown(false));

  useEffect(() => {
    if (visibilityDropdown) {
      clearTimeout(repeat);
      setDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setDropdownAnimation(false), 150));
    }
  }, [visibilityDropdown]);

  const onClickToggleButton = () => {
    setVisibilityDropdown(!visibilityDropdown);
  };

  const onClickSelectedMenu = (e, menu) => {
    const type = 'select';

    onChange(e.target.getAttribute('name'), menu, type);
    setVisibilityDropdown(false);
  };

  return (
    <div
      className={`basic-select ${className && className}`}
      ref={containerRef}
    >
      <label className="input-label">
        {label || ''} {required && <i>*</i>}
      </label>
      <div
        className={`toggle ${visibilityDropdown} ${disabled && 'disabled'}`}
        onClick={!disabled ? onClickToggleButton : null}
      >
        <span>{t(value.i18nKey) || t('menu.input.please-select')}</span>
        <ChevronBottomIcon />
      </div>
      {dropdownAnimation && (
        <div className="dropdown-box">
          <ul
            className={`dropdown-list ${
              visibilityDropdown ? 'slide-in' : 'slide-out'
            }`}
          >
            {menus &&
              (menus || []).map((menu) => (
                <li
                  className="dropdown-list-item"
                  key={menu.key}
                  name={name}
                  onClick={(e) => onClickSelectedMenu(e, menu)}
                >
                  {t(menu?.i18nKey)}
                </li>
              ))}
          </ul>
        </div>
      )}
      {caution && <span className="input-caution">{caution}</span>}
    </div>
  );
};

export default BasicSelect;
