import React from 'react';
import { useStatus } from 'utils/status';
import { dateToDateFormat, displayName } from 'utils/string';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MaterialInput from 'components/input/MaterialInput';
import { ReactComponent as TagIcon } from 'assets/images/icon/tag-Icon.svg';
import { ReactComponent as SearchIcon } from 'assets/images/icon/search.svg';
import { useAuth } from 'utils/auth';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Box,
} from '@mui/material';
import { executeAction } from 'utils/redux';
import { getAllCandidates } from 'store/slices/candidatesSlice';
import { CircularProgress } from '@mui/material';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'save-as';
import { MaterialSelectSmall } from 'components/select/MaterialSelect';
import { useOnClickOutside } from 'utils/hooks';
import { utils, writeFileXLSX } from 'xlsx';
import { getCandidatesOfAssessment } from 'store/slices/assessmentsSlice';

const __TAG_LIST__ = [
  {
    key: 'all',
    i18nKey: 'btn.all-tag',
  },
  {
    key: 'singapore',
    i18nKey: 'menu.tag.select.singapore',
  },
  {
    key: 'korea',
    i18nKey: 'menu.tag.select.korea',
  },
];

const GucciList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isEntireLoading, setIsEntireLoading } = useStatus();
  const { userInfo } = useAuth();
  const [isRefreshing, setIsRefreshing] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [filterAssessment, setFilterAssessment] = React.useState({});
  const [filterSkill, setFilterSkill] = React.useState({});
  const [_candidates, _setCandidates] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [assessments, setAssessments] = React.useState({});
  const [skills, setSkills] = React.useState({});
  const [applyCount, setApplyCount] = React.useState(0);
  const [addColumnApplyData, setAddColumnApplyData] = React.useState([]);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [repeat, setRepeat] = React.useState(null);
  const [visibilityDropdownAnimation, setVisibilityDropdownAnimation] =
    React.useState(false);
  const [selectedTagItem, setSelectedTagItem] = React.useState(
    __TAG_LIST__[0] || ''
  );
  const containerRef = React.useRef(null);
  // candidates 신규 state
  const [allCandidates, setAllCandidates] = React.useState([]);
  const [newAllCandidates, setNewAllCandidates] = React.useState([]);
  const [removeDuplicateCandidates, setRemoveDuplicateCandidates] =
    React.useState([]);
  // (삭제 X) gucci stt data 값 추출 할 때 사용 하는 state
  // const [textArray, setTextArray] = React.useState([]);
  // const [finalArray, setFinalArray] = React.useState([]);
  // select menu 이벤트 hooks
  useOnClickOutside(containerRef, () => setDropdownOpen(false));

  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // (삭제 X) gucci stt data 값 추출 할 때 사용 하는 로직
  // React.useEffect(() => {
  //   executeAction(
  //     getCandidatesOfAssessment({
  //       aid: 'sIPgrxPE5C337AxBnXIr',
  //     })
  //   )
  //     .unwrap()
  //     .then(({ docs }) => {
  //       setTextArray(docs);
  //     });
  // }, []);

  // React.useEffect(() => {
  //   const array = [];

  //   textArray?.map((text) => {
  //     if (text?.results) {
  //       Object.entries(text?.results)?.map(([key, value]) => {
  //         if (key === 'survey' || key === 'detect' || !value?.text) return;
  //         array.push({ [key]: value?.text });
  //       });
  //     } else {
  //       return;
  //     }
  //   });

  //   setFinalArray(array);
  // }, [textArray]);

  React.useEffect(() => {
    setIsLoading(true);
    executeAction(getAllCandidates())
      .unwrap()
      .then((res) => {
        setAllCandidates(res);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (allCandidates?.length === 0) return;

    const Array = [];

    (allCandidates || []).map((candidate) => {
      let flag = false;

      Array.map((item, idx) => {
        if (item.id === candidate.id) {
          Array[idx].assessment_name += `,${candidate.assessment_name}`;
          flag = true;
          return;
        }
      });

      if (!flag) {
        Array.push(candidate);
      }
    });
    setIsLoading(false);
    setRemoveDuplicateCandidates(Array);
  }, [allCandidates]);

  React.useEffect(() => {
    let newCandidates = [];

    if (selectedTagItem?.key === 'all') {
      newCandidates = removeDuplicateCandidates;
    } else if (selectedTagItem?.key === 'korea') {
      const filteredCandidate = (removeDuplicateCandidates || []).filter(
        (candidate) => candidate?.event_id === 3010100
      );
      newCandidates = filteredCandidate;
    } else if (selectedTagItem?.key === 'singapore') {
      const filteredCandidate = (removeDuplicateCandidates || []).filter(
        (candidate) => candidate?.event_id === 3010200
      );
      newCandidates = filteredCandidate;
    }

    setNewAllCandidates(newCandidates);
  }, [removeDuplicateCandidates, selectedTagItem]);

  /** =================
     * object를 CSV형태로 변환해주는 함수
     * @param {*} obj (object)
     * @returns 
    ===================== */
  const convertToCSV = (obj) => {
    const array = typeof obj !== 'object' ? JSON.parse(obj) : obj;
    let str = '';

    const headers = Object.keys(array[0]);
    str += `${headers.join(',')}\r\n`;

    for (let i = 0; i < array.length; i += 1) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';
        line += array[i][index];
      }
      str += `${line}\r\n`;
    }

    return str;
  };

  /** =================
     * link를 생성하여 CSV로 변환된 파일을 다운로드 하는 함수
     * @param {*} obj 
     * @param {*} filename 
    ===================== */
  const downloadCSV = (obj, filename) => {
    const csv = convertToCSV(obj);
    const exportedFilename = `${filename}.csv` || 'export.csv';

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const downloadExcel = (obj, filename) => {
    const worksheet = utils.json_to_sheet(obj);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFileXLSX(workbook, `${filename}.xlsx`);
  };

  /** =================
     * CSV다운로드 버튼 클릭 핸들러
     * 나라별 태그에 알맞은 응시자 리스트를 해시형태로 저장한다.
    ===================== */
  const handleDownload = () => {
    const dataToCSV = newAllCandidates?.map((candidate) => ({
      '참여 방법': changeParticipation(
        candidate?.personal?.participation_way,
        candidate?.event_id
      ),
      평가명: t(candidate?.assessment_name),
      성: t(candidate?.personal?.last_name),
      이름: t(candidate?.personal?.first_name),
      이메일: t(candidate?.personal?.email_address[0]),
      휴대전화번호:
        t(candidate?.personal?.phone?.country_code) +
        t(candidate?.personal?.phone?.number),
      '지원 분야': t(candidate?.applied_field),
      '지원 채널': mergeArray(candidate?.applying_channel),
      '지원 지역': mergeArray(candidate?.applied_region),
      전공: t(candidate?.academic_major),
      외국어: mergeArray(candidate?.foreign_languages),
      '직무관련 특별 경력': t(candidate?.distinct_exprience),
      '최근 근무 회사/경력 분야': t(candidate?.latest_company),
      '기타 판매 경험': t(candidate?.other_sales),
      '럭셔리 브랜드 판매 경험': t(candidate?.luxury_sales),
      최종학력: t(candidate?.education),
      '무엇을 전공하였나요?': t(candidate?.academic_major_gucci),
      '총 경력': changeYearsExperience(candidate?.career),
      나이: changeAgeWord(candidate?.age, candidate?.event_id),
      성별: changeSexWord(candidate?.gender_code),
      '최종 연봉':
        t(candidate?.annual_salary) + t(candidate?.annual_salary_unit),
    }));

    // (삭제 X) gucci stt data 뽑을 때 사용하는 로직
    // const dataToCSV = [];
    // finalArray?.map((item) =>
    //   Object.entries(item)?.map(([key, value]) => {
    //     dataToCSV.push({ 테스트아이디: key, 답변: value });
    //   })
    // );

    let filenameByContry;

    if (selectedTagItem?.key === 'all') {
      filenameByContry = 'GUCCI_ALL';
    } else if (selectedTagItem?.key === 'korea') {
      filenameByContry = 'GUCCI_KOREA';
    } else if (selectedTagItem?.key === 'singapore') {
      filenameByContry = 'GUCCI_SINGAPORE';
    }

    downloadExcel(dataToCSV, filenameByContry);
  };

  const changeParticipation = (text, id) => {
    let word = text;

    if (id === 3010200) {
      if (text === 'web_form') {
        word = '사전등록';
      } else if (text === 'munchskill') {
        word = '현장등록';
      }
    }
    return word;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resumeDownload = (key) => {
    const zip = new JSZip();

    let zipFileName;

    if (selectedTagItem?.key === 'all') {
      zipFileName = 'GUCCI_지원자_이력서(전체).zip';
    } else if (selectedTagItem?.key === 'korea') {
      zipFileName = 'GUCCI_지원자_이력서(한국).zip';
    } else if (selectedTagItem?.key === 'singapore') {
      zipFileName = 'GUCCI_지원자_이력서(싱가폴).zip';
    }

    const filteredResumeArr = newAllCandidates?.filter(
      (el) => el?.personal?.resume_url
    );

    let count = 0;
    setIsEntireLoading(true);
    filteredResumeArr?.forEach((url) => {
      let ext =
        url?.personal?.resume_url.split('.')[2] ||
        url?.personal?.resume_url.split('.')[1];
      let filename = `${url?.personal?.last_name + url?.personal?.first_name}_${
        url?.personal?.email_address[0]
      }_이력서.${ext}`;

      JSZipUtils.getBinaryContent(url?.personal?.resume_url, (err, data) => {
        if (err) {
          throw err;
        }
        zip.file(filename, data, { binary: true });
        count += 1;

        if (count === filteredResumeArr.length) {
          zip
            .generateAsync({ type: 'blob' })
            .then((content) => {
              saveAs(content, zipFileName);
              setIsEntireLoading(false);
            })
            .catch((e) => {
              console.error(e);
              setIsEntireLoading(false);
            });
        }
      });
    });
  };

  const changeSexWord = (data) => {
    let word;

    switch (data?.results?.survey?.sex) {
      case 'male':
        word = t('menu.label.male');
        break;
      case 'female':
        word = t('menu.label.female');
        break;
      case 'non-disclosure':
        word = t('menu.label.non-disclosure');
        break;
      default:
        word = '';
        break;
    }

    return word;
  };

  function mergeArray(data) {
    if (data === undefined || data === null || data === '') return '';

    let text = [];

    if (data.length === 1) {
      return t(data[0]);
    }
    data?.map((item) => {
      text.push(t(item));
    });
    return text.join(',');
  }

  const changeEducationWord = (data) => {
    let word;

    switch (data?.results?.survey?.educationLevel) {
      case 'high-school-graduation':
        word = t('menu.input.high-school-graduation');
        break;
      case 'college-graduation':
        word = t('menu.input.college-graduation');
        break;
      case 'while-in-university':
        word = t('menu.input.while-in-university');
        break;
      case 'university-graduation':
        word = t('menu.input.university-graduation');
        break;
      case 'finish-graduate-school':
        word = t('menu.input.finish-graduate-school');
        break;
      default:
        word = '';
        break;
    }

    return word;
  };

  const changeYearsExperience = (data) => {
    let word;

    switch (data) {
      case '0-year':
        word = t('menu.input.0-year');
        break;
      case '1-year':
        word = t('menu.input.1-year');
        break;
      case '2-years':
        word = t('menu.input.2-years');
        break;
      case '3-years':
        word = t('menu.input.3-years');
        break;
      case '4-years':
        word = t('menu.input.4-years');
        break;
      case '5-years':
        word = t('menu.input.5-years');
        break;
      case '6-years':
        word = t('menu.input.6-years');
        break;
      case '7-years':
        word = t('menu.input.7-years');
        break;
      case '8-years':
        word = t('menu.input.8-years');
        break;
      case '9-years':
        word = t('menu.input.9-years');
        break;
      case '10-years':
        word = t('menu.input.10-years');
        break;
      case '11-years':
        word = t('menu.input.11-years');
        break;
      case '12-years':
        word = t('menu.input.12-years');
        break;
      case '13-years':
        word = t('menu.input.13-years');
        break;
      case '14-years':
        word = t('menu.input.14-years');
        break;
      case '15-years':
        word = t('menu.input.15-years');
        break;
      case '16-years':
        word = t('menu.input.16-years');
        break;
      case '17-years':
        word = t('menu.input.17-years');
        break;
      case '18-years':
        word = t('menu.input.18-years');
        break;
      case '19-years':
        word = t('menu.input.19-years');
        break;
      case '20-years':
        word = t('menu.input.20-years');
        break;
      case 'for-over-21-years':
        word = t('menu.input.for-over-21-years');
        break;
      default:
        word = '';
        break;
    }

    return word;
  };

  const changeAgeWord = (data, id) => {
    let word;

    if (id === 3010200) {
      word = t(data?.replaceAll('-', '.'));
    } else {
      switch (data) {
        case 'under-18-years-old':
          word = t('menu.input.under-18-years-old');
          break;
        case '18-24-years-old':
          word = t('menu.input.18-24-years-old');
          break;
        case '25-34-years-old':
          word = t('menu.input.25-34-years-old');
          break;
        case '35-44-years-old':
          word = t('menu.input.35-44-years-old');
          break;
        case '45-54-years-old':
          word = t('menu.input.45-54-years-old');
          break;
        case '55-64-years-old':
          word = t('menu.input.55-64-years-old');
          break;
        case '64-years-old-and-above':
          word = t('menu.input.64-years-old-and-above');
          break;
        default:
          word = '';
          break;
      }
    }

    return word;
  };

  /** ======================
     * @function changeEvantIdOfTagname
     * api로 넘어온 응시자들의 event_id 키값을 i18키값으로 변환하는 함수
     * @param {*} id 
     * @returns 
    ========================= */
  const changeEvantIdOfTagname = (id) => {
    switch (id) {
      case 3010100:
        return t('menu.tag.select.korea');
      case 3010200:
        return t('menu.tag.select.singapore');
    }
  };

  React.useEffect(() => {
    let candidatesCopy = newAllCandidates.slice(0);

    (candidatesCopy || []).sort((a, b) => b.created_at_gmt - a.created_at_gmt);

    _setCandidates(
      (candidatesCopy || []).filter((x) => {
        const fullName1 =
          (x.personal.first_name || '') + (x.personal.last_name || '');
        const fullName2 =
          (x.personal.last_name || '') + (x.personal.first_name || '');
        const email = x.personal.email_address[0];
        const assessmentName = x.assessment_name?.split(',');

        let flag = false;
        const convertedSearch = (search || '')
          .replaceAll(' ', '')
          .trim()
          .toLowerCase();
        if (
          fullName1
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .includes(convertedSearch)
        ) {
          flag = true;
        }
        if (
          fullName2
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .includes(convertedSearch)
        ) {
          flag = true;
        }

        if (email.includes(convertedSearch)) {
          flag = true;
        }

        (assessmentName || []).map((assessment) => {
          if (assessment === undefined) return;

          if (assessment.toLowerCase().includes(convertedSearch)) {
            flag = true;
            return;
          }
        });

        if (flag) {
          return x;
        }
      })
    );
  }, [newAllCandidates, search]);

  React.useEffect(() => {
    const startIdx = page * rowsPerPage;

    setCandidates(_candidates?.slice(startIdx, startIdx + rowsPerPage));
    setApplyCount(_candidates?.length);
  }, [_candidates, page, rowsPerPage]);

  const onRowClickHandler = (candidate) => {
    if (candidate?.assessment_id !== null) {
      navigate(
        `${encodeURI(
          `/customer/candidates/${candidate?.personal?.email_address}`
        )}`
      );
    } else {
      navigate(
        `${encodeURI(
          `/customer/candidates/${candidate?.personal?.email_address}`
        )}`,
        {
          state: {
            candidateInfo: candidate,
          },
        }
      );
    }
  };

  const getAssessmentList = (_assessments) => {
    const convertTextToArray = _assessments?.split(',');
    if (convertTextToArray === undefined) return;

    const cnt = convertTextToArray?.length;

    if (cnt <= 0) {
      return <div />;
    }
    if (cnt === 1) {
      return (
        <div>
          <span>{convertTextToArray[0]}</span>
        </div>
      );
    }
    if (cnt === 2) {
      return (
        <div>
          <span>{convertTextToArray[0]}</span>
          <span>{convertTextToArray[1]}</span>
        </div>
      );
    }
    return (
      <div>
        <span>{convertTextToArray[0]}</span>
        <span>{convertTextToArray[1]}</span>
        <span>+ {cnt - 2}</span>
      </div>
    );
  };

  /** ======================
     * @function goStatistic() : 통계페이지로 이동
     * @param {*} blobData 
     * 통계 페이지에서 기존 엑셀 정보와 응시자 정보를 통합해서 그래프로 표시한다.
    ========================== */
  const goStatistic = (blobData) => {
    navigate('/customer/statistic', {
      state: {
        blobData,
        addData: addColumnApplyData,
      },
    });
  };

  React.useEffect(() => {
    if (dropdownOpen) {
      clearTimeout(repeat);
      setVisibilityDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setVisibilityDropdownAnimation(false), 150));
    }
  }, [dropdownOpen]);

  const resumeButtonClickHandler = () => {
    resumeDownload();
    setDropdownOpen(!dropdownOpen);
  };

  const resumeDownloadClickHandler = (key) => {
    setDropdownOpen(!dropdownOpen);
  };

  /** ========================
     * @function DownloadDropbox
     * select dropbox component
     * @param {*} itemList (array)
     * @returns 
    =========================== */
  const DownloadDropbox = ({ itemList }) => (
    <div className="option-wrap">
      <ul className={`option-list ${dropdownOpen ? 'slide-in' : 'slide-out'}`}>
        {(itemList || []).map((list, idx) => (
          <li
            className="option-list-item fs16"
            key={`tagList-${idx}`}
            onClick={() => resumeDownloadClickHandler(list?.key)}
          >
            {t(list?.i18nKey)}
          </li>
        ))}
      </ul>
    </div>
  );

  const tagSelectClickHandler = (i18nKey) => {
    setSelectedTagItem(i18nKey);
  };

  const splitEmailId = (email) => {
    const splitEmail = email.split('|');
    return splitEmail[0];
  };

  return (
    <main className="pages-protected-candidates-list">
      <header className="header">
        <h2 className="header-title fs28">{t('header.menu.my-candidates')}</h2>
        <div className="header-right">
          {userInfo &&
          (userInfo?.plan === undefined || userInfo?.plan < 100) ? (
            <></>
          ) : (
            <div className="header-right-download">
              <div className="header-rigth-button-wrap" ref={containerRef}>
                <button
                  className="header-right-resume common-button fsbtn16 gray active"
                  onClick={() => resumeButtonClickHandler()}
                >
                  <span className="fsbtn16">{t('btn.cv-download')}</span>
                </button>
              </div>
              <button
                className="header-right-csv common-button fsbtn16 gray active"
                onClick={handleDownload}
              >
                <span className="fsbtn16">{t('btn.csv-download')}</span>
              </button>
            </div>
          )}
          <div className="header-right-search">
            <MaterialInput
              placeholder={t('text-field.label.search')}
              className="header-right-search-input"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              icon={<SearchIcon />}
            />
          </div>
        </div>
      </header>
      {userInfo && (userInfo?.plan === undefined || userInfo?.plan < 100) ? (
        <></>
      ) : (
        <section className="tag">
          <div className="tag-wrap">
            <MaterialSelectSmall
              width="100"
              frontIcon={<TagIcon />}
              selectedLabel={t(selectedTagItem?.i18nKey)}
              menuList={__TAG_LIST__}
              onClick={tagSelectClickHandler}
            />
          </div>
        </section>
      )}
      <section className="table">
        <Box>
          {!isEntireLoading || candidates?.length > 0 ? (
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('column.name')}</TableCell>
                      <TableCell>{t('column.email')}</TableCell>
                      <TableCell className="align-left">
                        {t('column.assessments')}
                      </TableCell>
                      <TableCell className="align-left">
                        {t('column.tag')}
                      </TableCell>
                      <TableCell>{t('column.last-activity')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading || candidates?.length <= 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <CircularProgress color="primary" size={25} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      (candidates || []).map((candidate, idx) => (
                        <TableRow
                          key={idx}
                          hover
                          onClick={() => onRowClickHandler(candidate)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className="name tbody" width={200}>
                            {displayName(
                              candidate?.personal?.first_name,
                              candidate?.personal?.last_name,
                              t('lang')
                            )}
                          </TableCell>
                          <TableCell className="email tbody" width={350}>
                            {splitEmailId(candidate?.id)}
                          </TableCell>
                          <TableCell
                            className="assessment tbody align-left"
                            width={350}
                          >
                            {getAssessmentList(candidate?.assessment_name)}
                          </TableCell>
                          <TableCell
                            className="assessment tbody align-left"
                            width={300}
                          >
                            <div className="tag-box">
                              <p className="tag-name fs14">
                                {changeEvantIdOfTagname(candidate?.event_id)}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell className="created-at tbody" width={160}>
                            {dateToDateFormat(
                              new Date(candidate?.created_at_gmt * 1000),
                              t('lang')
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={_candidates?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          ) : (
            <section className="empty">
              <div className="thumbnail" />
              <Trans
                t={t}
                parent="p"
                className="fs28"
                i18nKey="my-candidates.desc.empty"
              />
            </section>
          )}
        </Box>
      </section>
    </main>
  );
};

export default GucciList;
