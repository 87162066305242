import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  orderBy,
} from 'firebase/firestore';
import { app } from './index';

const db = getFirestore(app);

export const _getCandidates = ({ uid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, 'users', uid, 'candidates');
      const q = query(collectionRef, orderBy('updatedAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const newDocs = new Array();
      querySnapshot.forEach((doc) => {
        newDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getCandidate = ({ uid, cid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'candidates', cid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _getAssessment = ({ uid, aid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _getCandidateInAssessment = ({ uid, aid, cid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _gucciApplyInfo = ({ cid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = doc(db, 'gucciapply', cid);
      const docSnap = await getDoc(collectionRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });
