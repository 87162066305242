import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronLeftIcon } from 'assets/images/icon/chevron-left-pager.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/images/icon/chevron-right-pager.svg';
import { useOnClickOutside } from 'utils/hooks';

const __OFFSETS__ = [10, 20, 30];

const TablePager = ({ startIdx, setStartIdx, offset, setOffset, total }) => {
  const { t } = useTranslation();
  const [visibilityDropdown, setVisibilityDropdown] = React.useState(false);
  const offsetRef = React.useRef(null);

  useOnClickOutside(offsetRef, () => setVisibilityDropdown(false));

  const onOffsetClickHandler = (value) => {
    setOffset(value);
    setVisibilityDropdown(false);
  };

  const onPrevClickHandler = () => {
    if (startIdx - offset < 0) setStartIdx(0);
    else setStartIdx(startIdx - offset);
  };

  const onNextClickHandler = () => {
    if (startIdx + offset > total - 1) return;
    setStartIdx(startIdx + offset);
  };

  return (
    <article className="components-pager-table-pager">
      <span className="title fs16">{t('menu.title.pagination')}</span>
      <article ref={offsetRef} className="offset">
        <button
          className="offset-toggle"
          onClick={() => setVisibilityDropdown(!visibilityDropdown)}
        >
          <span>{offset}</span>
        </button>
        {visibilityDropdown && (
          <ul className="offset-list">
            {__OFFSETS__.map((offset) => (
              <li
                key={offset}
                className="offset-list-item"
                onClick={() => onOffsetClickHandler(offset)}
              >
                {offset}
              </li>
            ))}
          </ul>
        )}
      </article>
      <div className="current fs16">
        {`${startIdx + 1}~${
          startIdx + offset <= total ? startIdx + offset : total
        }`}
        <span>/</span>
        {`${total}`}
      </div>
      <div className="prev" onClick={onPrevClickHandler}>
        <ChevronLeftIcon />
      </div>
      <div className="next" onClick={onNextClickHandler}>
        <ChevronRightIcon />
      </div>
    </article>
  );
};

export default TablePager;
