import React, { useEffect, useState } from 'react';
import MaterialDateTimePicker from 'components/common/MaterialDateTimePicker';
import BasicInput from 'components/input/BasicInput';
import BasicSelect from 'components/select/BasicSelect';
import { Modal } from '@mui/material';
import { Button } from 'components/common/Button';
import { Timestamp } from 'firebase/firestore';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-modal.svg';
import { executeAction } from 'utils/redux';
import {
  setCandidateOfRecruitment,
  updateRecruitmentCandidate,
} from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';
import { dateToDateFormat } from 'utils/string';
import { sumSalary } from 'utils/converter';
import { useTranslation } from 'react-i18next';
import { monetaryUnit } from '../../constants/monetaryUnit';

const OfferInformation = ({ open, close, recruitment, currentCandidate }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [startDate, setStateDate] = useState(0);
  const [dueDate, setDueDate] = useState(0);
  const [etcText, setEtcText] = useState('');
  const [totalFixed, setTotalFixed] = useState({
    salary: '',
    currency: { key: 'KRW', i18nKey: 'menu.input.won' },
  });
  const [bonus, setBonus] = useState({
    salary: '',
    currency: { key: 'KRW', i18nKey: 'menu.input.won' },
  });

  useEffect(() => {
    const today = new Date();
    const threeDaysLater = new Date(today.setDate(today.getDate() + 3));
    setDueDate(
      dateToDateFormat(new Date(threeDaysLater), recruitment?.language)
    );
  }, []);

  useEffect(() => {
    let flag = true;

    if (!startDate) {
      flag = false;
    }

    if (!totalFixed.salary) {
      flag = false;
    }

    setCanSubmit(flag);
  }, [startDate, etcText, totalFixed]);

  const fixedHandleInput = (e, menu, type) => {
    if (type === 'select') {
      setTotalFixed((prev) => ({
        ...prev,
        [e]: menu,
      }));
      return;
    }

    const { value, name } = e.target;
    const number = value.replace(/[^0-9]/g, '');

    setTotalFixed((prevInput) => ({
      ...prevInput,
      [name]: Number(number).toLocaleString('ko-KR'),
    }));
  };

  // const bonusHandleInput = (e, menu, type) => {
  //   if (type === 'select') {
  //     setBonus((prev) => ({
  //       ...prev,
  //       [e]: menu,
  //     }));
  //     return;
  //   }

  //   const { value, name } = e.target;
  //   const number = value.replace(/[^0-9]/g, '');

  //   setBonus((prevInput) => ({
  //     ...prevInput,
  //     [name]: Number(number).toLocaleString('ko-KR'),
  //   }));
  // };

  const submitHandler = () => {
    setIsLoading(true);

    if (currentCandidate.recruitProgress === 4) {
      executeAction(
        setCandidateOfRecruitment({
          aid: recruitment?.rid,
          cid: currentCandidate?.email,
          candidateFirstName: currentCandidate?.firstName,
          candidateLastName: currentCandidate?.lastName,
          phoneNumber: currentCandidate?.progressSubmit[1].phoneNumber,
          type: 'offer',
          job: recruitment?.recruitmentName,
          dueDate,
          step: 5,
          lang: 'ko',
          progressSubmit: {
            5: {
              startDate,
              etcText,
              totalFixed: {
                salary: totalFixed.salary,
                currency: totalFixed.currency.key,
              },
              bonus: {
                salary: bonus.salary,
                currency: bonus.currency.key,
              },
              status: 'extended',
              invitedAt: Timestamp.now(),
            },
          },
        })
      )
        .unwrap()
        .then(() => {
          close(false);
          executeAction(
            updateRecruitmentCandidate({
              aid: recruitment?.rid,
              cid: currentCandidate?.email,
              recruitProgress: 5,
            })
          )
            .unwrap()
            .then(() => {
              toast('오퍼레터 발송이 완료되었습니다.');
              setIsLoading(false);
            })
            .catch((e) => {
              console.error(e);
              setIsLoading(false);
            });
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    } else if (currentCandidate.recruitProgress === 5) {
      executeAction(
        setCandidateOfRecruitment({
          aid: recruitment?.rid,
          cid: currentCandidate?.email,
          candidateFirstName: currentCandidate?.firstName,
          candidateLastName: currentCandidate?.lastName,
          phoneNumber: currentCandidate?.progressSubmit[1].phoneNumber,
          type: 'offer',
          job: recruitment?.recruitmentName,
          dueDate,
          step: 6,
          lang: 'ko',
          progressSubmit: {
            6: {
              startDate,
              etcText,
              totalFixed: {
                salary: totalFixed.salary,
                currency: totalFixed.currency.key,
              },
              bonus: {
                salary: bonus.salary,
                currency: bonus.currency.key,
              },
              status: 'extended',
              invitedAt: Timestamp.now(),
            },
          },
        })
      )
        .unwrap()
        .then(() => {
          close(false);
          executeAction(
            updateRecruitmentCandidate({
              aid: recruitment?.rid,
              cid: currentCandidate?.email,
              recruitProgress: 6,
            })
          )
            .unwrap()
            .then(() => {
              toast('오퍼레터 발송이 완료되었습니다.');
              setIsLoading(false);
            })
            .catch((e) => {
              console.error(e);
              setIsLoading(false);
            });
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  };

  return (
    <Modal open={open}>
      <div className="modal-invited-member modal-offer-modal">
        <section className="invited-member-container">
          <article className="invited-header-wrapper">
            <h3 className="fs16">{t('title.process.offer-letter')}</h3>
            <span onClick={() => close(false)}>
              <CloseIcon />
            </span>
          </article>
          <article className="form-contents-wrapper">
            <aside className="start-date-box">
              <span className="fs14 grey-title">
                {t('title.starting-date')}
              </span>
              <MaterialDateTimePicker
                periodDateTime={startDate}
                setPeriodDateTime={setStateDate}
                withoutTime
              />
              {/* <span className="fs14 grey-title">
                {`지원자가 희망하는 입사일은 ${dateToDateFormat(
                  new Date(
                    currentCandidate?.progressSubmit[
                      currentCandidate?.recruitProgress
                    ]?.startDate
                  ),
                  t('lang')
                )} 입니다.`}
              </span> */}
            </aside>
            <aside className="flex">
              <BasicInput
                label={t('title.base-salary')}
                inputType={'text'}
                placeholder={'50,000,000'}
                caution={t('title.total.fixed.compensation')}
                name={'salary'}
                value={totalFixed.salary}
                onChange={fixedHandleInput}
              />
              <BasicSelect
                label={'blank'}
                className={'currency-form'}
                value={totalFixed.currency}
                name={'currency'}
                onChange={fixedHandleInput}
                menus={monetaryUnit}
              />
            </aside>
            {/* <aside className="flex">
              <BasicInput
                label={t('title.bonus')}
                inputType={'text'}
                placeholder={'50,000,000'}
                caution={t('title.performance-bonus')}
                name={'salary'}
                value={bonus.salary}
                onChange={bonusHandleInput}
              />
              <BasicSelect
                label={'blank'}
                className={'currency-form'}
                value={bonus.currency}
                name={'currency'}
                onChange={bonusHandleInput}
                menus={monetaryUnit}
              />
            </aside> */}
            <aside>
              <span className="fs14 grey-title">
                {t('title.store-incentive')}
              </span>
              <textarea
                className="etc-text-box"
                placeholder={t('text-field.placeholder.etc-text')}
                value={etcText}
                onChange={(e) => setEtcText(e.target.value)}
              />
            </aside>
          </article>
          <article className="total-salary-wrapper">
            <span className="fs20">{t('text-field.label.base-salary')}</span>
            <p className="fs24">{`${totalFixed.salary} ${t(
              totalFixed.currency.i18nKey
            )}`}</p>
          </article>
          <article className="invited-button">
            <Button
              theme={'grey'}
              size={56}
              label={t('btn.submit')}
              isDisabled={!canSubmit && true}
              isLoading={isLoading}
              fontSize={'fs16'}
              onClick={!isLoading && canSubmit ? submitHandler : null}
            />
          </article>
        </section>
      </div>
    </Modal>
  );
};

export default OfferInformation;
