import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { ReactComponent as TimerIcon } from 'assets/images/icon/timer.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/images/icon/chevron-right.svg';
import { useAuth } from 'utils/auth';
import { useModal } from 'utils/modal';
import { useStatus, divideSkillScope } from 'utils/status';
import { useOutletContext } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  getCandidatesOfAssessment,
  getTestInfos,
  getAssessment,
} from 'store/slices/assessmentsSlice';
import { convertSkillTypeToI18nKey } from 'utils/string';
import { useSelector } from 'react-redux';

const OBPI_TESTS = [
  {
    category: 'fellowship',
    title: 'title.fellowship',
    left: 'desc.fellowship-left',
    right: 'desc.fellowship-right',
  },
  {
    category: 'authority',
    title: 'title.authority',
    left: 'desc.authority-left',
    right: 'desc.authority-right',
  },
  {
    category: 'conformity',
    title: 'title.conformity',
    left: 'desc.conformity-left',
    right: 'desc.conformity-right',
  },
  {
    category: 'emotion',
    title: 'title.emotion',
    left: 'desc.emotion-left',
    right: 'desc.emotion-right',
  },
  {
    category: 'detail',
    title: 'title.detail',
    left: 'desc.detail-left',
    right: 'desc.detail-right',
  },
  {
    category: 'proficiency',
    title: 'title.proficiency',
    left: 'desc.proficiency-left',
    right: 'desc.proficiency-right',
  },
  {
    category: 'work-orientation',
    title: 'title.work-orientation',
    left: 'desc.work-orientation-left',
    right: 'desc.work-orientation-right',
  },
  {
    category: 'patience',
    title: 'title.patience',
    left: 'desc.patience-left',
    right: 'desc.patience-right',
  },
  {
    category: 'fair-mindedness',
    title: 'title.fair-mindedness',
    left: 'desc.fair-mindedness-left',
    right: 'desc.fair-mindedness-right',
  },
  {
    category: 'loyalty',
    title: 'title.loyalty',
    left: 'desc.loyalty-left',
    right: 'desc.loyalty-right',
  },
  {
    category: 'disclosure',
    title: 'title.disclosure',
    left: 'desc.disclosure-left',
    right: 'desc.disclosure-right',
  },
  {
    category: 'initiative',
    title: 'title.initiative',
    left: 'desc.initiative-left',
    right: 'desc.initiative-right',
  },
];

const __OBPI_RESULT_NUM__ = 9;
const __OBPI_LIST_NUM__ = 12;
const __MAX_OBPI_MATCH_SCORE = 8;

const __GROUP_TEST_TIME__ = 10;

const Configure = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userInfo } = useSelector((state) => state.auth);
  const { displayModal } = useModal();
  const { setIsEntireLoading } = useStatus();
  const [aid] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [isRefreshing, setIsRefreshing] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [hiringStage, setHiringStage] = React.useState('');
  const [showDropdown, setShowDropdown] = React.useState(-1);
  const [assessmentInfo, setAssessmentInfo] = React.useState({});
  const [__candidates, __setCandidates] = React.useState([]);
  const [_candidates, _setCandidates] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [obpiBenchmark, setOBPIBenchmark] = React.useState({});
  const [objectiveScores, setObjectiveScores] = React.useState({});
  const [hasOBPI, setHasOBPI] = React.useState(false);
  const [hasObjectivetest, setHasObjectivtests] = React.useState(false);
  const [testType, setTestType] = React.useState(null);

  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //

  const lang = t('lang');

  const dropdownRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const rowRefs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];

  // get assessment info
  React.useEffect(() => {
    if (!aid || Object.keys(userInfo).length === 0) return;

    executeAction(
      getAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        (doc.tests || []).forEach((test, idx) => {
          if (divideSkillScope(test) === 'objective') {
            if (!test.question_type) {
              test.question_type = 'tag.multiple-choice';
            }
          }

          if (typeof test.question !== 'string') {
            if (
              test?.question_type?.indexOf('tag.subjective') >= 0 ||
              test?.question_type?.indexOf('tag.code') >= 0 ||
              test?.question_type?.indexOf('tag.video') >= 0
            ) {
              const skillset = Array.from(
                new Set(
                  (test?.question || []).map((it) => it?.[`skill_${t('lang')}`])
                )
              );
              test.skillset = skillset;
            } else {
              const skillset = Array.from(
                new Set(
                  (test?.question || []).map(
                    (it) => it?.testInfo?.[`skill_${t('lang')}`]
                  )
                )
              );
              test.skillset = skillset;
            }
          }
        });
        setAssessmentInfo(doc || {});
        if (doc.obpiBenchmark) {
          setOBPIBenchmark(doc.obpiBenchmark);
        }
      })
      .catch((e) => {
        console.dir(e);
        setAssessmentInfo({});
      });
  }, [aid, userInfo]);

  React.useEffect(() => {
    if (isRefreshing) {
      __setCandidates([]);
      return setIsRefreshing(false);
    }

    setIsLoading(true);
    executeAction(
      getCandidatesOfAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ docs }) => {
        // console.log(docs);
        (docs || []).sort((a, b) => {
          if (a.updatedAt < b.updatedAt) {
            return 1;
          }
          if (a.updatedAt > b.updatedAt) {
            return -1;
          }

          return 0;
        });
        __setCandidates(docs || []);
        setIsLoading(false);
      })
      .catch((e) => {
        __setCandidates([]);
        setIsLoading(false);
        console.dir(e);
      });
  }, [aid, isRefreshing]);

  // display datas change.
  React.useEffect(() => {
    const startIdx = page * rowsPerPage;

    const docs = _candidates.slice(startIdx, startIdx + rowsPerPage);

    (docs || []).forEach((doc, idx) => {
      const cid = doc?.id;

      const { tests } = assessmentInfo.tests || {};
      const newObjectiveTests = new Array();
      const newSubjectiveTests = new Array();
      var newOBPIResult;
      var obpiMatchScore = -1;
      (assessmentInfo.tests || []).forEach((test, idx) => {
        if (test?.testcnt > 0 || test?.skills?.length > 0) {
          (test?.skills || []).forEach((skill) => {
            if (doc?.results?.[skill.uuid]) {
              newObjectiveTests.push(skill);
              setHasObjectivtests(true);
            }
          });
          return;
        }
        const { section } = test;
        if (
          section === 'video' ||
          section === 'essay' ||
          section === 'multiple-choice' ||
          section === 'file-upload' ||
          section === 'spreadsheets' ||
          section === 'code'
        ) {
          newSubjectiveTests.push(test);
        } else {
          // if(test?.title_en?.indexOf('인성/심리검사') >= 0) {
          // if(test?.title_en?.indexOf('OBPI') >= 0) {
          // tid가 1310이고 lang가 ko일 때만 OBPI이다.
          if (test?.id === '1310' && lang === 'ko') {
            newOBPIResult = doc?.results?.obpi;
            setHasOBPI(true);
            if (obpiBenchmark?.obpiResult) {
              var scores = 0;
              OBPI_TESTS.forEach((category) => {
                const benchmark = obpiBenchmark?.obpiResult?.traits?.find(
                  (x) => x.scale === category.category
                );
                const curOBPI = newOBPIResult?.traits?.find(
                  (x) => x.scale === category.category
                );
                scores +=
                  __MAX_OBPI_MATCH_SCORE -
                  Math.abs(benchmark?.stanine - curOBPI?.stanine);
              });
              obpiMatchScore = (
                scores /
                (__MAX_OBPI_MATCH_SCORE * __OBPI_LIST_NUM__)
              ).toFixed?.(2);
            }
            return;
          }
          newObjectiveTests.push(test);
          setHasObjectivtests(true);
        }
      });
      const results = doc?.results;

      const _objectiveScores = {};
      const tasks = new Array();
      let score = 0;
      let cnt = 0;
      for (const skill of newObjectiveTests) {
        const suuid = skill?.uuid;
        const result = results?.[suuid];
        const lang2 = assessmentInfo?.language;
        tasks.push(
          executeAction(
            getTestInfos({
              tids: skill?.test || [],
              lang: lang2,
            })
          )
            .unwrap()
            .then(({ docs: answers }) => {
              const temp = new Array();
              for (const [key, val] of Object.entries(result || {})) {
                const tid = key;
                const answer = answers.find((x) => x.id === tid);
                if (answer?.correct.length > 1) {
                  let isCorrect = true;
                  answer?.correct.forEach((cor, index) => {
                    isCorrect = isCorrect && cor === val?.[0][index];
                  });
                  temp.push(true);
                } else {
                  temp.push(answer?.correct?.[0] === val?.[0]);
                }
              }
              let _score = 0;
              if (temp.length)
                _score = temp.filter((x) => x).length / temp.length;
              _objectiveScores[suuid] = _score;

              if (skill?.id !== '71') {
                if (_score >= 0 && _score <= 1) {
                  score += _score;
                  cnt += 1;
                }
              }
            })
            .catch((e) => {
              console.dir(e);
            })
        );
      }
      docs[idx].AverageObjectiveScore = (score / cnt)?.toFixed?.(2);
      docs[idx].objectiveScores = _objectiveScores;
      docs[idx].subjectiveTests = newSubjectiveTests;
      docs[idx].objectiveTests = newObjectiveTests;
      docs[idx].obpiResult = newOBPIResult;
      docs[idx].obpiMatchScore = obpiMatchScore;

      Promise.allSettled(tasks)
        .then(() => {
          setObjectiveScores(_objectiveScores);
        })
        .catch((e) => {
          setObjectiveScores({});
        });
    });
    setCandidates(_candidates.slice(startIdx, startIdx + rowsPerPage));
  }, [_candidates, page, rowsPerPage, obpiBenchmark]);

  React.useEffect(() => {
    const handler = (event) => {
      for (const rowRef of rowRefs) {
        if (rowRef.current) {
          const moreElem = rowRef.current.querySelector('.more-icon');
          if (moreElem.contains(event.target)) return;
        }
      }

      if (!dropdownRef.current || dropdownRef.current.contains(event.target))
        return;

      setShowDropdown(-1);
    };

    window.document.addEventListener('mousedown', handler);
    window.document.addEventListener('touchstart', handler);

    return () => {
      window.document.removeEventListener('mousedown', handler);
      window.document.removeEventListener('touchstart', handler);
    };
  }, [dropdownRef, rowRefs]);

  React.useEffect(() => {
    let candidatesCopy = (__candidates || []).slice(0);

    candidatesCopy = candidatesCopy.filter((x) => {
      const fullNameConverted1 = (x.firstName + x.lastName)
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      const fullNameConverted2 = (x.lastName + x.firstName)
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();
      const searchConverted = (search || '')
        .replaceAll(' ', '')
        .trim()
        .toLowerCase();

      if (fullNameConverted1.includes(searchConverted)) return true;
      if (fullNameConverted2.includes(searchConverted)) return true;
      return false;
    });

    if (!hiringStage) {
      return _setCandidates(candidatesCopy || []);
    }
    let hiringStep = -1;
    switch (hiringStage) {
      case 'all':
        return _setCandidates(candidatesCopy || []);
      case 'sended-email':
        hiringStep = 0;
        break;
      case 'wirte-done':
        hiringStep = 1;
        break;
      case 'under-investigation':
        hiringStep = 2;
        break;
      case 'reject':
        hiringStep = 3;
        break;
      case 'accept':
        hiringStep = 4;
        break;
    }
    _setCandidates(
      candidatesCopy.filter((x) => x?.progress === hiringStep) || []
    );
    // TODO: add the initialStep.
  }, [__candidates, search, hiringStage]);

  // modal에 lang를 넘겨 OBPI, KHAI-P등 인적성검사의 경우 예외처리에 사용한다.
  const onViewTestDetailClickHander = (test) => {
    const eventHandler = (event) => {
      if (event.detail?.message !== 'Detail Modal Open') return;
      window.document.querySelector('body').style.position = 'relative';
      window.document.removeEventListener('dismissModal', eventHandler);
    };

    window.document.querySelector('body').style.position = 'fixed';
    window.document.addEventListener('dismissModal', eventHandler);

    displayModal(
      'assessments/viewDetail/LibraryDetail',
      {
        test,
        lang,
        event: {
          message: 'Detail Modal Open',
        },
      },
      {
        frame: 'integration',
      }
    );
  };

  return (
    <section className="pages-protected-detail-content">
      <section className="included-tests">
        <section className="included-tests-table">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="47%">{t('column.test')}</TableCell>
                    <TableCell width="14%">{t('column.type')}</TableCell>
                    <TableCell width="24%">{t('column.skill')}</TableCell>
                    <TableCell align="right" width="10%">
                      {t('column.duration')}
                    </TableCell>
                    <TableCell align="right" width="5%" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    // TODO: this
                    (assessmentInfo?.tests || []).map((test, idx) => {
                      if (divideSkillScope(test) === 'objective') {
                        return (
                          <TableRow
                            key={idx}
                            hover
                            sx={{ cursor: 'pointer' }}
                            onClick={() => onViewTestDetailClickHander(test)}
                          >
                            <TableCell>
                              {test?.[`title_${t('en')}`] ||
                                test?.title ||
                                test?.category_skill}
                            </TableCell>
                            <TableCell>
                              <div className="types">
                                {test?.question_type
                                  .replaceAll(' ', '')
                                  .split(',')
                                  .map((type, idx) => (
                                    <span
                                      className={`skill-type fs12 ${type.replaceAll(
                                        '.',
                                        '_'
                                      )}`}
                                      key={idx}
                                    >
                                      {t(type)}
                                    </span>
                                  ))}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="skillsets">
                                {(test?.skillset).map((skill, idx) => {
                                  if (idx < 2) {
                                    return (
                                      <div className="skillset" key={idx}>
                                        <span>{skill}</span>
                                      </div>
                                    );
                                  }
                                })}
                                {test?.skillset?.length > 2 ? (
                                  <div className="skillset">
                                    <span>+ {test?.skillset.length - 2}</span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              <TimerIcon
                                style={{
                                  verticalAlign: 'middle',
                                  marginRight: '2px',
                                }}
                              />
                              {`${test.limit || 15} ${t('desc.minute')}`}
                            </TableCell>
                            <TableCell align="right">
                              <ChevronRightIcon />
                            </TableCell>
                          </TableRow>
                        );
                      }
                      if (divideSkillScope(test) === 'grouptest') {
                        return (
                          <TableRow key={idx}>
                            <TableCell>{test?.group}</TableCell>
                            <TableCell>
                              <div className="types">
                                <span
                                  className="skill-type fs12 tag_multiple-choice"
                                  key={idx}
                                >
                                  {t('tag.multiple-choice')}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell />
                            <TableCell align="right">
                              <TimerIcon
                                style={{
                                  verticalAlign: 'middle',
                                  marginRight: '2px',
                                }}
                              />
                              {`${test.testcnt * __GROUP_TEST_TIME__ || 15} ${t(
                                'desc.minute'
                              )}`}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </section>
      </section>
      <section className="included-questions">
        <section className="included-questions-table">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="70%">{t('column.question')}</TableCell>
                    <TableCell>{t('column.type')}</TableCell>
                    <TableCell align="right">{t('column.duration')}</TableCell>
                    <TableCell align="right" width="5%" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(assessmentInfo?.tests || []).map((test, idx) => {
                    if (divideSkillScope(test) === 'subjective') {
                      return (
                        <TableRow
                          key={idx}
                          sx={{ cursor: 'pointer' }}
                          hover
                          onClick={() => onViewTestDetailClickHander(test)}
                        >
                          <TableCell
                            width="70%"
                            className="title"
                            dangerouslySetInnerHTML={{ __html: test?.question }}
                          />
                          <TableCell>
                            <span
                              className={`skill-type fs12 ${convertSkillTypeToI18nKey(
                                test?.section
                              ).replaceAll('.', '_')}`}
                            >
                              {t(convertSkillTypeToI18nKey(test?.section))}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <TimerIcon
                              style={{
                                verticalAlign: 'middle',
                                marginRight: '2px',
                              }}
                            />
                            {t(`text-field.input.${test?.limit}`) ||
                              test?.limit}
                          </TableCell>
                          <TableCell align="right">
                            <ChevronRightIcon />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </section>
      </section>
    </section>
  );
};

export default Configure;
