import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { useModal } from 'utils/modal';
import { useTranslation } from 'react-i18next';
import { convertHtmlForDisplay } from 'utils/string';
import { CircularProgress, TextareaAutosize } from '@mui/material';
import { executeAction } from 'utils/redux';
import { getCandidateInAssessment } from 'store/slices/candidatesSlice';
import { gradeResult } from 'store/slices/assessmentsSlice';
import { CustomOnclickEstimateScore } from 'components/progress/Score';

const FileUpload = ({ aid, cid, test, setRefreshing }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();
  const [isLoading, setIsLoading] = React.useState(false);
  const [candidate, setCandidate] = React.useState(null);
  const [answer, setAnswer] = React.useState(null);
  const [score, setScore] = React.useState(0);
  const [presetScore, setPresetScore] = React.useState(0);
  const [comment, setComment] = React.useState('');

  React.useEffect(() => {
    if (!aid || !cid) return;

    executeAction(
      getCandidateInAssessment({
        aid,
        cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setCandidate(doc);
        setAnswer(doc?.results?.[test?.uuid || test?.id]);
        setComment(doc?.results?.[test?.uuid || test?.id]?.comment);
        setPresetScore(doc?.results?.[test?.uuid || test?.id]?.preset_score);

        if (doc?.results?.[test?.uuid || test?.id]?.score === undefined) {
          setScore(0);
        } else {
          setScore(doc?.results?.[test?.uuid || test?.id]?.score);
        }
      })
      .catch((e) => {
        console.dir(e);
        setCandidate(null);
        setAnswer(null);
      });
  }, [aid, cid]);

  const onDismissHandler = () => {
    dismissModal();
  };

  const onFileDownloadHandler = () => {
    window.open(answer?.file, '_blank');
  };

  const onSubmitHandler = () => {
    setIsLoading(true);
    executeAction(
      gradeResult({
        aid,
        cid,
        tuuid: test?.uuid || test?.id,
        score,
        comment,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setRefreshing(true);
        dismissModal();
      })
      .catch((e) => {
        console.dir(e);
        setIsLoading(false);
      });
  };

  return (
    <article className="modals-assessments-view-answer-file-upload">
      <header className="header">
        <h3 className="header-title fs20">
          {t('title.view-custom-question-answer')}
        </h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <div className="form">
        <section className="question">
          <h4 className="question-title fs18">{t('title.question')}</h4>
          <div className="question-description fs16">
            <p
              dangerouslySetInnerHTML={{
                __html: convertHtmlForDisplay(test?.question),
              }}
            />
          </div>
          {test?.videoUrl !== '' && (
            <div className="video">
              <video
                className="video-question"
                src={`${process.env.REACT_APP_MEDIA_URL}/${test?.videoUrl}`}
                controls
                playsInline
                autoPlay
              />
            </div>
          )}
        </section>
        <section className="answer">
          <h4 className="answer-title fs18">{t('title.answer')}</h4>
          <div className="answer-description file-upload">
            <button
              className="answer-description-download common-button fsbtn16 active"
              onClick={onFileDownloadHandler}
            >
              {t('btn.file-download')}
            </button>
          </div>
        </section>

        <section className="estimate">
          <h4 className="estimate-title fs18">{t('title.rate-answer')}</h4>
          <div className="estimate-score-flex">
            <div className="estimate-score">
              <CustomOnclickEstimateScore
                score={score}
                onClick={setScore}
                presetScore={presetScore}
              />
            </div>
            <div className="estimate-score-number">
              <div className="user-score">
                <span className="score-title">{t('column.score')}:</span>
                {score && score > 0 ? (
                  <span className="score-number">{score * 100}</span>
                ) : (
                  <span className="score-number preset">
                    {t('desc.need-grading')}
                  </span>
                )}
                <i>%</i>
              </div>
            </div>
          </div>
          <div className="estimate-comment">
            <TextareaAutosize
              placeholder={t('title.comment')}
              onChange={(e) => setComment(e.target.value)}
              defaultValue={comment}
              className="fs16"
            />
          </div>
        </section>
        <button
          className={`submit common-button fsbtn16 primary ${
            !isLoading && score > 0 && 'active'
          } ${isLoading && 'pending'}`}
          onClick={!isLoading && score > 0 ? onSubmitHandler : null}
        >
          {t('btn.save')}
          {isLoading && <CircularProgress color="grey" size={25} />}
        </button>
      </div>
    </article>
  );
};

export default FileUpload;
