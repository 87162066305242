import React from 'react';
import { nameConversionLanguage } from 'utils/converter';
import { formatDateWithTime } from 'utils/string';
import { useTranslation } from 'react-i18next';
import {
  convertGender,
  convertCareer,
  convertChannel,convertSubChannel,
} from 'utils/convertKey';
import { useModal } from 'utils/modal';
import { CandidateDetailMoreMenu } from 'components/recruitment/detail/CandidateDetailMoreMenu';
import { candidateActionMenu } from 'constants/recruitments/candidateActionMenu';
import BodyTableRowSkeleton from '../BodyTableRowSkeleton';

const RecruitmentTableBody = ({ candidates, recruitment }) => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();

  const viewDetailModal = (candidate) => {
    displayModal(
      'recruitments/CandidateDetail',
      {
        candidates,
        candidate,
        recruitment,
        processName: 'application',
      },
      { frame: 'full' }
    );
  };

  const countDuplicateEmails = (count) => {
    if (count > 1) {
      return 'Y';
    }
    return 'N';
  };

  return (
    <>
    <tbody>
      {candidates === undefined ? (
        <>
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
        </>
      ) : candidates?.length === 0 ? (
        <tr>
          <td>{t('recruitment.desc.empty-no-applicant')}</td>
        </tr>
      ) : (
        candidates?.map((candidate) => (
          <tr key={candidate?.email} onClick={() => viewDetailModal(candidate)}>
            <td>
              {nameConversionLanguage(
                candidate?.firstName,
                candidate?.lastName,
                t('lang')
              )}{' '}
              {candidate?.progressSubmit[1].KIV ? (
                <span className="tag-kiv">KIV</span>
              ) : (
                ''
              )}
            </td>
            <td>{candidate?.progressSubmit[1]?.phoneNumber}</td>
            <td className="resume">{candidate?.progressSubmit[1]?.birth}</td>
            <td>{t(convertGender(candidate?.progressSubmit[1]?.gender))}</td>
            <td>{`${t(convertChannel(candidate?.progressSubmit[1]?.channel))} - ${t(convertSubChannel(candidate?.progressSubmit[1]))
            }`}</td>
            <td>{candidate?.progressSubmit[1]?.applyLocation?.join(', ')}</td>
            <td>{t(convertCareer(candidate?.progressSubmit[1]?.career))}</td>
            <td>{countDuplicateEmails(candidate?.applyCount)}</td>
            <td>
              {formatDateWithTime(new Date(candidate?.createdAt?.seconds * 1000))}
            </td>
              <td>
                <CandidateDetailMoreMenu
                  candidate={candidate}
                  rid={recruitment?.rid}
                  menuList={[candidateActionMenu.deleteCandidate, candidateActionMenu.editCandidateEmail]}
                />
              </td>
          </tr>
        ))
      )}
    </tbody>
    </>
);
};

export default RecruitmentTableBody;
