import React, { useEffect, useState } from 'react';
import InterviewScore from 'components/recruitment/detail/interview/InterviewScore';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import UpdateScoreModal from 'modals/assessments/UpdateScoreModal';
import VideoResultDetail from './VideoResultDetail';
import PrintVideoResultDetail from './PrintVideoResultDetail';
import PrintLanguageScore from './PrintLanguageScore';
import ResultScoreBox from './ResultScoreBox';

const LanguageResult = ({
  visibilityDropdown,
  question,
  assessment,
  candidate,
  scoreList,
  test,
  setRefreshing,
  type,
  getAnswer,
}) => {
  const { t } = useTranslation();

  const [isOpenScoreModal, setIsOpenScoreModal] = useState(false);

  const onUpdateScoreModal = () => {
    setIsOpenScoreModal(true);
  };

  const scoreDisplay = (score) => {
    if (score || score === 0) {
      return Math.trunc(question?.score * 100);
    }
    if (score === undefined || score === null) {
      return Math.trunc(question?.preset_score * 100);
    }
  };

  return (
    <aside
      className={`toggle-body-content language page-break ${
        visibilityDropdown ? 'slide-in' : 'slide-out'
      }`}
    >
      <div className="category-section">
        {type === 'print' ? (
          <div className="print-language-flex">
            <PrintLanguageScore
              title={t('title.reading')}
              score={scoreList[0]}
            />
            <PrintLanguageScore
              title={t('title.listening')}
              score={scoreList[1]}
            />
            <PrintLanguageScore
              title={t('title.speaking')}
              score={Math.trunc(scoreList[2])}
            />
          </div>
        ) : (
          <>
            <div className="category-score-box">
              <span className="category-title fs18">{t('title.reading')}</span>
              <div className="score-wrapper">
                <InterviewScore
                  type={'language'}
                  idx={0}
                  score={scoreList[0]}
                />
                <div className="measurement-box">
                  <p>{t('desc.below-expectation')}</p>
                  <p>{t('desc.keep-in-view')}</p>
                  <p>{t('desc.strong-hire')}</p>
                </div>
              </div>
            </div>
            <div className="category-score-box">
              <span className="category-title fs18">
                {t('title.listening')}
              </span>
              <div className="score-wrapper">
                <InterviewScore
                  type={'language'}
                  idx={1}
                  score={scoreList[1]}
                />
                <div className="measurement-box">
                  <p>{t('desc.below-expectation')}</p>
                  <p>{t('desc.keep-in-view')}</p>
                  <p>{t('desc.strong-hire')}</p>
                </div>
              </div>
            </div>
            <div className="category-score-box">
              <span className="category-title fs18">{t('title.speaking')}</span>
              <div className="score-wrapper">
                <InterviewScore
                  type={'language'}
                  idx={2}
                  score={Math.trunc(scoreList[2])}
                />
                <div className="measurement-box">
                  <p>{t('desc.below-expectation')}</p>
                  <p>{t('desc.keep-in-view')}</p>
                  <p>{t('desc.strong-hire')}</p>
                </div>
              </div>
            </div>
          </>
        )}
        {type === 'print' ? (
          <div className="toggle-body-answer-box print">
            <div className="speaking-video-answer-box">
              <div className="speaking-video-score">
                <h6>Speaking Video</h6>
                <ResultScoreBox
                  score={scoreDisplay(question?.score) || 0}
                  isHideButton
                />
              </div>
              <PrintVideoResultDetail
                result={{
                  video: question?.speaking[question?.name],
                  score: question?.score,
                  preset_score: question?.preset_score,
                  text: question?.text,
                }}
                link={getAnswer(question)}
              />
            </div>
          </div>
        ) : (
          <div className="toggle-body-answer-box">
            <VideoResultDetail
              result={{
                video: question?.speaking[question?.name],
                score: question?.score,
                preset_score: question?.preset_score,
                text: question?.text,
              }}
              onClick={onUpdateScoreModal}
              scoreDisplay={scoreDisplay}
            />
          </div>
        )}
      </div>

      {isOpenScoreModal && (
        <UpdateScoreModal
          open={isOpenScoreModal}
          close={setIsOpenScoreModal}
          setRefreshing={setRefreshing}
          category_skill={test?.category_skill}
          assessment={assessment}
          candidate={candidate}
          question={question}
          type="language"
        />
      )}
    </aside>
  );
};

export default LanguageResult;
