import React from 'react';
import { useTranslation } from 'react-i18next';

const RadioGroupBox = ({ type, value, setValue, position }) => {
  const { t } = useTranslation();

  const handleRadioChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className={`radio-group-box ${!setValue && 'disabled'}`}>
      {position &&
        position.map((pos) => (
          <div key={pos?.key} className="recruitment-radio-wrapper">
            <input
              className="recruitment-radio-input"
              type="radio"
              id={`${pos?.id}_${type}`}
              name={`${pos?.id}_${type}`}
              value={pos?.key}
              checked={value === pos?.key}
              onChange={handleRadioChange}
              disabled={!setValue}
            />
            <label
              className="recruitment-radio-label fs12"
              htmlFor={`${pos?.id}_${type}`}
            >
              <span>{t(pos?.i18key)}</span>
            </label>
          </div>
        ))}
    </div>
  );
};

export default RadioGroupBox;
