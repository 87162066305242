import React from 'react';
import { EstimateScore } from 'components/progress/Score';

const EstimateList = ({ score, onClick, questionId, title }) => (
  <div className="estimate-score-flex">
    <div className="estimate-rate-title">
      <h5 className="fs16">{title}</h5>
    </div>
    <div className="estimate-score">
      <EstimateScore
        score={score}
        onClick={onClick}
        questionId={questionId}
        isTooltipShow="styling"
      />
    </div>
  </div>
);

export default EstimateList;
