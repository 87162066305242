import React from 'react';

const FinalResults = ({ t, user }) => {
  return (
    <ul>
      <li>
        <span className="sub-title fs12">{t('column.final-position')}</span>
        <p className="desc fs16">{user.position}</p>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('column.job-store')}
        </span>
        <p className="desc fs16">{user.joinStore}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.date-of-hire')}</span>
        <p className="desc fs16">{user.dateOfHire}</p>
      </li>
    </ul>
  );
};

export default FinalResults;
