import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const KhaiOInterviewRight = ({ interview, type }) => {
  const { t } = useTranslation();
  const [mainQuestion, setMainQuestion] = useState('');
  const [subQuestion, setSubQuestion] = useState([]);
  const [subQuestionItem, setSubQuestionItem] = useState([]);

  useEffect(() => {
    if (!interview) return;

    const questionSplit = interview?.split('\n\n');
    setMainQuestion(questionSplit[0]);
    setSubQuestion(questionSplit[1]);
  }, [interview]);

  useEffect(() => {
    if (subQuestion.length === 0) return;

    const subItem = subQuestion
      .split('\n')
      .map((item) => item.replace('- ', ''));
    setSubQuestionItem(subItem);
  }, [subQuestion]);

  return (
    <aside className={`interview-question-wrap ${type}`}>
      <h6 className={`interview-question-title ${type} fs14`}>
        {t('title.interview-questions')}
      </h6>
      <div className={`interview-question ${type}`}>
        <p
          className={`interview-main-quesetion ${
            type === 'print' ? 'fs9' : 'fs16'
          }`}
        >
          {mainQuestion && mainQuestion}
        </p>
        <ul className="interview-sub-question">
          {subQuestionItem &&
            (subQuestionItem || []).map((sub, idx) => (
              <li
                className={`interview-sub-question-item ${
                  type === 'print' ? 'fs9' : 'fs16'
                }`}
                key={`sub_${idx}`}
              >
                {sub}
              </li>
            ))}
        </ul>
      </div>
    </aside>
  );
};

export default KhaiOInterviewRight;
