import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { executeAction } from 'utils/redux';
import { useTranslation } from 'react-i18next';
import TabButtonSkeleton from './TabButtonSkeleton';

const DetailProcess = ({
  processSetting,
  onClick,
  countCandidates,
  userInfo,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { rid } = useParams();

  const [currentTab, setCurrentTab] = useState('');
  const [onlyCurrentStage, setOnlyCurrentStage] = useState('');

  useEffect(() => {
    const pathArr = (location?.pathname || '').split('/');

    setCurrentTab(pathArr[4] || '');
    setOnlyCurrentStage(pathArr[4] || '');
  }, [location?.pathname]);

  useEffect(() => {
    if (userInfo?.memberType === 15) {
      executeAction(getRecruitment({ aid: rid, type: 'interviewer' }))
        .unwrap()
        .then(({ doc }) => {
          setOnlyCurrentStage(doc.stage);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [rid]);

  const activeStyle = (key) => {
    switch (key) {
      case 'application':
        return '';
      case 'aiInterview':
        return 'virtual-interview';
      case 'firstInterview':
        return 'first-interview';
      case 'secondInterview':
        return 'second-interview';
      case 'evidence':
        return 'evidence';
      case 'pass':
        return 'pass';
      case 'submission':
        return 'submission';
      case 'reject':
        return 'reject';
    }
  };

  return (
    <aside className="dashboard-detail-process-setting">
      <ul>
        {Object.keys(processSetting).length === 0 ? (
          <>
            <TabButtonSkeleton />
            <TabButtonSkeleton />
            <TabButtonSkeleton />
            <TabButtonSkeleton />
            <TabButtonSkeleton />
            <TabButtonSkeleton />
          </>
        ) : (
          Object.keys(processSetting).map((process, idx) => (
            <li
              key={process}
              className={`fs14 tab ${process} ${
                userInfo?.memberType !== 15 ||
                process === 'firstInterview' ||
                process === 'secondInterview'
                  ? activeStyle(process) === currentTab && 'active'
                  : 'disabled'
              } ${
                idx === Object.keys(processSetting).length - 2 && 'division'
              }`}
              onClick={() =>
                onClick(
                  userInfo?.memberType !== 15 ||
                    process === 'firstInterview' ||
                    process === 'secondInterview'
                    ? process
                    : 'none'
                )
              }
            >
              {t(processSetting[process]?.label)}
              <br />
              <span>
                {`${
                  countCandidates.length !== 0 && countCandidates?.counts[idx]
                }`}
              </span>
            </li>
          ))
        )}
      </ul>
    </aside>
  );
};

export default DetailProcess;
