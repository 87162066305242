import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { useModal } from 'utils/modal';
import { useTranslation } from 'react-i18next';
import { executeAction } from 'utils/redux';
import { getCandidateInAssessment } from 'store/slices/candidatesSlice';
import { gradeResult } from 'store/slices/assessmentsSlice';
import { ReactComponent as VideoIcon } from 'assets/images/icon/video.svg';
import { convertHtmlForDisplay } from 'utils/string';
import { CircularProgress, TextareaAutosize } from '@mui/material';
import { CustomOnclickEstimateScore } from 'components/progress/Score';

const Video = ({ aid, cid, test, setRefreshing }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();
  // const { setIsEntireLoading } = useStatus();
  const [isLoading, setIsLoading] = React.useState(false);
  const [candidate, setCandidate] = React.useState({});
  const [answer, setAnswer] = React.useState(null);
  const [score, setScore] = React.useState(0);
  const [presetScore, setPresetScore] = React.useState(0);
  const [comment, setComment] = React.useState('');

  React.useEffect(() => {
    if (!aid || !cid) return;

    executeAction(
      getCandidateInAssessment({
        aid,
        cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setCandidate(doc || {});
        setAnswer(doc?.results?.[test?.uuid || test.id]);
        setPresetScore(doc?.results?.[test?.uuid || test?.id]?.preset_score);
        setComment(doc?.results?.[test?.uuid || test?.id]?.comment);

        if (doc?.results?.[test?.uuid || test?.id]?.score === undefined) {
          setScore(0);
        } else {
          setScore(doc?.results?.[test?.uuid || test?.id]?.score);
        }
      })
      .catch((e) => {
        console.dir(e);
        setCandidate({});
        setAnswer(null);
      });
  }, [aid, cid]);

  const onDismissHandler = () => {
    dismissModal();
  };

  const onSubmitHandler = () => {
    setIsLoading(true);
    executeAction(
      gradeResult({
        aid,
        cid,
        tuuid: test?.uuid || test.id,
        score,
        comment,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setRefreshing(true);
        dismissModal();
      })
      .catch((e) => {
        console.dir(e);
        setIsLoading(false);
      });
  };

  return (
    <article className="modals-assessments-view-answer-video">
      <header className="header">
        <h3 className="header-title fs20">
          {t('title.view-custom-question-answer')}
        </h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <section className="form">
        <div className="form-container">
          <div className="form-left">
            <section className="question">
              <h4 className="question-title fs18">{t('title.question')}</h4>
              <div className="question-description fs16">
                <p
                  dangerouslySetInnerHTML={{
                    __html: convertHtmlForDisplay(test?.question),
                  }}
                />
              </div>
              {test?.videoUrl !== '' && (
                <div className="video">
                  <video
                    className="video-question"
                    src={`${process.env.REACT_APP_MEDIA_URL}/${test?.videoUrl}`}
                    controls
                    playsInline
                  />
                </div>
              )}
            </section>
            <section className="answer">
              <h4 className="answer-title fs18">{t('title.answer')}</h4>
              <div className="answer-description video">
                {answer ? (
                  <video src={answer?.video} controls="controls" />
                ) : (
                  <VideoIcon />
                )}
              </div>
            </section>
          </div>
          <div className="form-right">
            <section className="estimate">
              <h4 className="estimate-title fs18">{t('title.rate-answer')}</h4>
              <div className="estimate-score-flex">
                <div className="estimate-score">
                  <CustomOnclickEstimateScore
                    score={score}
                    onClick={setScore}
                    presetScore={presetScore}
                  />
                </div>
                <div className="estimate-score-number">
                  <div className="user-score">
                    <span className="score-title">{t('column.score')}:</span>
                    {score && score > 0 ? (
                      <span className="score-number">{score * 100}</span>
                    ) : (
                      <span className="score-number preset">
                        {presetScore === undefined
                          ? t('desc.in-progress')
                          : presetScore < 0.2
                          ? t('desc.need-grading')
                          : Math.trunc(presetScore * 100)}
                      </span>
                    )}
                    <i>%</i>
                  </div>
                </div>
              </div>
              <div className="estimate-comment">
                <TextareaAutosize
                  placeholder={t('title.comment')}
                  onChange={(e) => setComment(e.target.value)}
                  defaultValue={comment}
                  className="fs16"
                />
              </div>
            </section>
          </div>
        </div>
        <button
          className={`submit common-button fsbtn16 primary ${
            !isLoading && score > 0 && 'active'
          } ${isLoading && 'pending'}`}
          onClick={!isLoading && score > 0 ? onSubmitHandler : null}
        >
          {t('btn.save')}
          {isLoading && <CircularProgress color="grey" size={25} />}
        </button>
      </section>
    </article>
  );
};

export default Video;
