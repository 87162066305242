import React from 'react';
import ObpiQuestion from './ObpiQuestion';
import MultipleQuestion from './MultipleQuestion';
import CommonQuestion from './CommonQuestion';
import CustomQuestion from './CustomQuestion';

const QuestionContent = ({ test, detail, lang }) => {
  const questionType =
    test?.question_type !== undefined
      ? test?.question_type.split(',')[0]
      : test?.section;

  switch (questionType) {
    case 'tag.multiple-choice':
      return (
        <article className="question-contents">
          {/* 한글과 영문의 skill id가 같은경우가 있으니 언어 설정까지 추가해서 구분한다. */}
          {(test?.id === '1310' ||
            test?.id === '1321' ||
            test?.id === '1322') &&
          lang === 'ko' ? (
            <ObpiQuestion questions={test?.question} />
          ) : (
            <MultipleQuestion questions={test?.question} />
          )}
        </article>
      );
    case 'file-upload':
      return (
        <article className="question-contents">
          <CustomQuestion question={test} />
        </article>
      );
    case 'video':
      return (
        <article className="question-contents">
          <CustomQuestion question={test} />
        </article>
      );
    case 'multiple-choice':
      return (
        <article className="question-contents">
          <CustomQuestion question={test} />
        </article>
      );
    case 'essay':
      return (
        <article className="question-contents">
          <CustomQuestion question={test} />
        </article>
      );
    default:
      return (
        <article className="question-contents">
          <CommonQuestion questions={test?.question} />
        </article>
      );
  }
};

export default QuestionContent;
