import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { languageOptions } from 'components/codeEditor/languageOptions';

const InputEditor = ({ code, id, onChange, isLeadonly, isLine }) => {
  const [value, setValue] = useState(code || '');
  const [language, setLanguage] = useState();

  useEffect(() => {
    const temp = languageOptions.find((perLang) => perLang.id === id);
    setLanguage(temp.value);
  }, [id]);

  useEffect(() => {
    setValue(code);
  }, [code]);

  const handleEditorChange = (value) => {
    setValue(value);
    onChange('code', value);
  };

  return (
    <>
      <Editor
        height="100%"
        width="100%"
        language={language || 'javascript'}
        value={value}
        theme="vs-dark"
        defaultValue="Please wait"
        onChange={handleEditorChange}
        options={{
          readOnly: isLeadonly,
          lineNumbers: isLine,
          fontSize: '14px',
          formatOnPaste: true,
          autoIndent: true,
          formatOnType: true,
          scrollBeyondLastLine: false,
          minimap: { enabled: false },
          renderWhitespace: 'all',
          automaticLayout: true,
        }}
      />
    </>
  );
};

export default InputEditor;
