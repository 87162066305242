import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import { useNavigate } from 'react-router-dom';

const ConfirmCancel = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dismissModal } = useModal();

  return (
    <article className="modals-assessments-confirm-cancel">
      <section className="title">
        <h3 className="title-main fs16">{t('dialog.title.stop-editing')}</h3>
        <div className="title-sub fs14">{t('dialog.desc.stop-editing')}</div>
      </section>
      <section className="buttons">
        <button
          className="common-button fsbtn16 cancel active"
          onClick={() => dismissModal()}
        >
          {t('btn.no')}
        </button>
        <button
          className="common-button fsbtn16 primary active"
          onClick={() => {
            navigate('/customer/assessments');
            dismissModal({
              message: 'dismiss',
            });
          }}
        >
          {t('btn.yes')}
        </button>
      </section>
    </article>
  );
};

export default ConfirmCancel;
