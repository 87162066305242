import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { useModal } from 'utils/modal';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import { Checkbox, FormControlLabel } from '@mui/material';
import MaterialDateTimePicker from 'components/common/MaterialDateTimePicker';
import { MaterialSelectSmall } from 'components/select/MaterialSelect';
import { executeAction } from 'utils/redux';
import { setCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';
import { setMemberInfo } from 'store/slices/recruitments/membersSlice';
import { useSelector } from 'react-redux';
import { dateToDateFormat } from '../../utils/string';
import OutLookCalendar from '../../components/common/OutLookCalendar';

const InterviewSchedule = ({
  recruitment,
  currentCandidate,
  processName,
  candidates,
  userInfo,
  event: { message },
}) => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();
  const { user } = useSelector((state) => state.auth);
  const [refreshToken, setRefreshToken] = useState('');
  const [standardTime, setStandardTime] = useState('');
  const [takenTime, setTakenTime] = useState({
    key: '45',
    i18nKey: 'menu.input.45-minutes',
  });
  const [interviewPlace, setInterviewPlace] = useState(
    userInfo?.interviewInfo && userInfo?.interviewInfo?.rememberPlace
      ? userInfo?.interviewInfo?.interviewPlace
      : ''
  );
  const [rememberPlace, setRememberPlace] = useState(
    Boolean(userInfo?.interviewInfo && userInfo?.interviewInfo?.rememberPlace)
  );
  const [precautions, setPrecautions] = useState('');
  const [isContact, setIsContact] = useState(false);
  const [startSchedules1, setStartSchedules1] = useState(0);
  const [startSchedules2, setStartSchedules2] = useState(0);
  const [startSchedules3, setStartSchedules3] = useState(0);
  const [dueDate, setDueDate] = useState(0);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const today = new Date();
    const oneDaysLater = new Date(today.setDate(today.getDate() + 1));
    setDueDate(new Date(oneDaysLater));

    if (!currentCandidate?.progressSubmit[1].interviewSchedule) return;
    const data = currentCandidate?.progressSubmit[1].interviewSchedule;

    setRememberPlace(data?.rememberPlace);

    if (data?.rememberPlace) {
      setInterviewPlace(data?.interviewPlace);
    } else {
      setInterviewPlace(data?.interviewPlace);
    }
  }, []);

  useEffect(() => {
    const storedTokenData = localStorage.getItem('tokenData');
    if (storedTokenData) {
      const parsedTokenData = JSON.parse(storedTokenData);
      const storedToken = parsedTokenData.outlook_refreshToken;
      const expirationTime = new Date(parsedTokenData.expirationDate);

      if (new Date() <= expirationTime) {
        setRefreshToken(storedToken);
      } else {
        localStorage.removeItem('tokenData');
      }
    }
  }, []);

  useEffect(() => {
    let flag = true;

    if (!startSchedules1 && !startSchedules2 && !startSchedules3) {
      flag = false;
    }

    if (!takenTime) {
      flag = false;
    }

    if (!interviewPlace) {
      flag = false;
    }

    setCanSubmit(flag);
  }, [
    startSchedules1,
    startSchedules2,
    startSchedules3,
    takenTime,
    interviewPlace,
  ]);

  const onDismissHandler = () => {
    dismissModal({
      message,
    });
    sessionStorage.removeItem('access_token');
  };

  const onChangeTextHandler = (e, type) => {
    const { value } = e.target;

    if (type === 'place') {
      setInterviewPlace(value);
    } else if (type === 'emergency') {
      setPrecautions(value);
    }
  };

  const onCheckedHandler = (e, type) => {
    const { checked } = e.target;

    if (type === 'remember') {
      setRememberPlace(checked);
    } else if (type === 'contact') {
      setIsContact(checked);
    }
  };

  const endScheduleSetting = (date) => {
    let endTime;

    if (takenTime.key === '30') {
      endTime = date + 1800000;
    }
    if (takenTime.key === '45') {
      endTime = date + 2700000;
    }
    if (takenTime.key === '60') {
      endTime = date + 3600000;
    }

    return endTime;
  };

  const openDetailModal = () => {
    // 디테일 페이지 오픈
    displayModal(
      'recruitments/CandidateDetail',
      {
        candidates,
        candidate: currentCandidate,
        recruitment,
        processName,
      },
      { frame: 'full' }
    );
  };

  // user의 필드 안에 인터뷰정보 저장(장소기억하기 기능을 위함)
  const saveUser = () => {
    executeAction(
      setMemberInfo({
        uid: user?.uid,
        interviewInfo: {
          interviewPlace,
          rememberPlace,
        },
      })
    )
      .then(() => {
        toast(t('toast.desc.complete-select-date'));
        onDismissHandler();
        openDetailModal();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onSubmitHandler = () => {
    setIsLoading(true);

    executeAction(
      setCandidateOfRecruitment({
        aid: recruitment?.rid,
        cid: currentCandidate?.email,
        candidateFirstName: currentCandidate?.firstName,
        candidateLastName: currentCandidate?.lastName,
        step: currentCandidate?.recruitProgress,
        type: 'toCandidate',
        lang: 'ko',
        phoneNumber: currentCandidate?.progressSubmit[1].phoneNumber,
        dueDate: dateToDateFormat(dueDate, recruitment?.language),
        refreshToken,
        precautions,
        interviewLocation: interviewPlace,
        progressSubmit: {
          [currentCandidate.recruitProgress]: {
            interviewSchedule: {
              standardTime: standardTime.key || '',
              takenTime: takenTime.key || '',
              interviewPlace,
              rememberPlace,
              precautions,
              // isContact,
              isDone: false,
              interviewDate: [
                {
                  id: 0,
                  start: startSchedules1 || 0,
                  end: endScheduleSetting(startSchedules1) || 0,
                },
                {
                  id: 1,
                  start: startSchedules2 || 0,
                  end: endScheduleSetting(startSchedules2) || 0,
                },
                {
                  id: 2,
                  start: startSchedules3 || 0,
                  end: endScheduleSetting(startSchedules3) || 0,
                },
              ],
            },
          },
        },
      })
    )
      .then(() => {
        saveUser();
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  return (
    <article className="modals-interview-schedule">
      <header className="header">
        <h3 className="header-title fs20">
          {t('title.request-interview-schedule')}
        </h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <main className="main">
        <section className="schedule-container">
          <article className="contents">
            <aside className="left-contents">
              <div>
                <div className="flex-column-gap-8">
                  <h4 className="sub-title fs18">
                    {t('title.setting-date-place')}
                  </h4>
                  <p className="fs16">{t('desc.candidate-booking')}</p>
                </div>
                <div className="flex-row-gap-20 border">
                  <div className="flex-column-gap-8 flex-3">
                    <h4 className="sub-title fs18">
                      {t('title.default-time')}
                    </h4>
                    <div className="select-box-wrap flex">
                      <MaterialSelectSmall
                        width={'width: 100%'}
                        // selectedLabel={standardTime?.i18nKey}
                        selectedLabel={t('menu.timezone.kr')}
                        onClick={setStandardTime}
                        className={'select-time'}
                        // menuList={[
                        //   { key: 'kor', i18nKey: '대한민국 (+9)' },
                        //   { key: 'kor', i18nKey: '대한민국 (+9)' },
                        // ]}
                      />
                    </div>
                  </div>
                  <div className="flex-column-gap-8 flex-7">
                    <h4 className="sub-title fs18">{t('title.taken-time')}</h4>
                    <div className="select-box-wrap">
                      <MaterialSelectSmall
                        width={'width: 100%'}
                        selectedLabel={takenTime?.i18nKey}
                        onClick={setTakenTime}
                        className={'select-time'}
                        menuList={[
                          { key: '30', i18nKey: 'menu.input.30-minutes' },
                          { key: '45', i18nKey: 'menu.input.45-minutes' },
                          { key: '60', i18nKey: 'menu.input.60-minutes' },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-column-gap-8">
                  <h4 className="sub-title fs18">
                    {t('title.interview-place')}
                  </h4>
                  <div className="textarea-wrap">
                    <textarea
                      value={interviewPlace}
                      onChange={(e) => onChangeTextHandler(e, 'place')}
                      placeholder={t('text-field.placeholder-place')}
                    />
                  </div>
                  <div className="checkbox-wrap">
                    <FormControlLabel
                      className="custom-checkbox"
                      sx={{ gap: '4px', marginLeft: '0' }}
                      label={t('checkbox.label.place-remember')}
                      control={
                        <Checkbox
                          className="custom"
                          sx={{ padding: '0' }}
                          checked={rememberPlace}
                          onChange={(e) => onCheckedHandler(e, 'remember')}
                        />
                      }
                    />
                  </div>
                </div>
                <div className="flex-column-gap-8">
                  <h4 className="sub-title fs18">
                    {t('title.other-precautions')}
                  </h4>
                  <div className="input-text-wrap">
                    <textarea
                      placeholder={
                        '주요 참고 사항 및 후보자가 인터뷰 장소까지 어려움 없이 도착할 수 있도록 상세한 설명 작성 \ne.g. 반드시 매장 후문으로 출입 부탁드립니다. 후문 출입에 어려움이 있으신 경우, 아래 번호로 연락 주세요. 플래그십 건너편에 위치한 커피빈 0 0 0 0 점 입구에 도착 후 아래 번호로 전화 주세요.'
                      }
                      value={precautions}
                      onChange={(e) => onChangeTextHandler(e, 'emergency')}
                    />
                  </div>
                  {/* <div className="checkbox-wrap">
                    <FormControlLabel
                      className="custom-checkbox"
                      sx={{
                        gap: '4px',
                        marginLeft: '0',
                        alignItems: 'flex-start',
                      }}
                      label={t('checkbox.label.emergency-display-number')}
                      control={
                        <Checkbox
                          className="custom"
                          sx={{ padding: '0' }}
                          checked={isContact}
                          onChange={(e) => onCheckedHandler(e, 'contact')}
                        />
                      }
                    />
                  </div> */}
                </div>
                <div>
                  <div className="setting-date-wrapper">
                    <ul>
                      <li>
                        <span>
                          <Trans
                            t={t}
                            parent="p"
                            i18nKey="text-field.label.start"
                            values={{
                              number: 1,
                            }}
                          />
                        </span>
                        <div className="date-picker">
                          <MaterialDateTimePicker
                            periodDateTime={startSchedules1}
                            setPeriodDateTime={setStartSchedules1}
                          />
                        </div>
                      </li>
                      <li>
                        <span>
                          <Trans
                            t={t}
                            parent="p"
                            i18nKey="text-field.label.start"
                            values={{
                              number: 2,
                            }}
                          />
                        </span>
                        <div className="date-picker">
                          <MaterialDateTimePicker
                            periodDateTime={startSchedules2}
                            setPeriodDateTime={setStartSchedules2}
                          />
                        </div>
                      </li>
                      <li>
                        <span>
                          <Trans
                            t={t}
                            parent="p"
                            i18nKey="text-field.label.start"
                            values={{
                              number: 3,
                            }}
                          />
                        </span>
                        <div className="date-picker">
                          <MaterialDateTimePicker
                            periodDateTime={startSchedules3}
                            setPeriodDateTime={setStartSchedules3}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </aside>
            {/* <aside className="right-contents">
               <div className="flex-column-gap-8">
                <h4 className="sub-title fs18">
                  {t('title.setting-enable-date')}
                </h4>
                <p className="fs16">{t('desc.setting-enable-date')}</p>
              </div>
               <div className="outlook-calendar">
                <OutLookCalendar
                  recruitment={recruitment}
                  currentCandidate={currentCandidate}
                  processName={processName}
                  refreshToken={refreshToken}
                />
              </div> 
            </aside> */}
          </article>
          <article className="button-contents">
            <Button
              theme={'blue'}
              size={56}
              label={t('btn.send-email-interview-date')}
              className={'send-button'}
              fontSize={'fs16'}
              onClick={onSubmitHandler}
              isDisabled={!canSubmit}
              isLoading={isLoading}
            />
          </article>
        </section>
      </main>
    </article>
  );
};

export default InterviewSchedule;
