export const gucciStore = [
  { key: 'SD', i18nKey: 'menu.input.shinsegae-daegu' },
  { key: 'GT', i18nKey: 'menu.input.galleria-timeworld' },
  { key: 'LB', i18nKey: 'menu.input.lotte-busan' },
  { key: 'HT', i18nKey: 'menu.input.hyundai-daegu' },
  { key: 'HU', i18nKey: 'menu.input.hyundai-ulsan' },
  { key: 'LD', i18nKey: 'menu.input.lotte-daegu' },
  { key: 'LK', i18nKey: 'menu.input.lotte-gwangbok' },
  { key: 'SDJ', i18nKey: 'menu.input.shinsegae-daejeon' },
  { key: 'HS', i18nKey: 'menu.input.the-hyundai-seoul' },
  { key: 'SGJ', i18nKey: 'menu.input.shinsegae-gwangju' },
  { key: 'LI', i18nKey: 'menu.input.lotte-incheon' },
  {
    key: 'ST',
    i18nKey: 'menu.input.shinsegae-times-square',
  },
  { key: 'HI', i18nKey: 'menu.input.hyundai-kintex' },
  { key: 'LG', i18nKey: 'menu.input.lotte-gwangju' },
  {
    key: 'HSH',
    i18nKey: 'menu.input.The-hyundai-seoul-high-end',
  },
  { key: 'GG', i18nKey: 'menu.input.galleria-gwanggyo' },
  { key: 'LW', i18nKey: 'menu.input.lotte-world' },
  { key: 'SJ', i18nKey: 'menu.input.shinsegae-jukjeon' },
  { key: 'SU', i18nKey: 'menu.input.shinsegae-uijeongbu' },
  { key: 'SH', i18nKey: 'menu.input.shinsegae-hanam' },
  { key: 'SM', i18nKey: 'menu.input.shinsegae-main' },
  { key: 'HD', i18nKey: 'menu.input.hyundai-mokdong' },
  { key: 'LS', i18nKey: 'menu.input.lotte-suwon' },
  { key: 'FL', i18nKey: 'menu.input.flagship-shop' },
  { key: 'Gaok', i18nKey: 'menu.input.gaok' },
  { key: 'HM', i18nKey: 'menu.input.hyundai-main' },
  { key: 'GL', i18nKey: 'menu.input.galleria' },
  {
    key: 'SGW',
    i18nKey: 'menu.input.shinsegae-gangnam-women',
  },
  { key: 'HP', i18nKey: 'menu.input.hyundai-pangyo' },
  { key: 'GLW', i18nKey: 'menu.input.galleria-women' },
  { key: 'LMW', i18nKey: 'menu.input.lotte-main-women' },
  { key: 'LWT', i18nKey: 'menu.input.lotte-world-tower' },
  { key: 'HMW', i18nKey: 'menu.input.hyundai-main-women' },
  { key: 'SC', i18nKey: 'menu.input.shinsegae-centumcity' },
  { key: 'LM', i18nKey: 'menu.input.lotte-main' },
  { key: 'LWW', i18nKey: 'menu.input.lotte-world-c2-women' },
  { key: 'SG', i18nKey: 'menu.input.shinsegae-gangnam' },
  { key: 'HC', i18nKey: 'menu.input.hyundai-coex' },
  { key: 'SMM', i18nKey: 'menu.input.shinsegae-main-men' },
  {
    key: 'SJM',
    i18nKey: 'menu.input.shinsegae-jukjeon-men',
  },
  { key: 'LBM', i18nKey: 'menu.input.lotte-busan-men' },
  {
    key: 'SDJM',
    i18nKey: 'menu.input.shinsegae-daejeon-men',
  },
  {
    key: 'SCM',
    i18nKey: 'menu.input.shinsegae-centumcity-men',
  },
  { key: 'GLM', i18nKey: 'menu.input.galleria-men' },
  { key: 'LWM', i18nKey: 'menu.input.lotte-world-c2-men' },
  { key: 'HMM', i18nKey: 'menu.input.hyundai-main-men' },
  { key: 'HPM', i18nKey: 'menu.input.hyundai-pangyo-men' },
  {
    key: 'SGJM',
    i18nKey: 'menu.input.shinsegae-gwangju-men',
  },
  { key: 'LMM', i18nKey: 'menu.input.lotte-main-men' },
  { key: 'HCM', i18nKey: 'menu.input.hyundai-coex-men' },
  {
    key: 'SGM',
    i18nKey: 'menu.input.shinsegae-gangnam-men',
  },
  { key: 'HGO', i18nKey: 'menu.input.hyundai-gimpo-outlet' },
  { key: 'YJO', i18nKey: 'menu.input.yeoju-premium-outlet' },
  { key: 'LBO', i18nKey: 'menu.input.lotte-busan-outlet' },
  {
    key: 'SL1DF',
    i18nKey: 'menu.input.shinsegae-incheon-t1(D)',
  },
  {
    key: 'SI2DF',
    i18nKey: 'menu.input.shinsegae-incheon-t2(D)',
  },
  { key: 'SSDF', i18nKey: 'menu.input.shilla-seoul(D)' },
  { key: 'LWDF', i18nKey: 'menu.input.lotte-world(D)' },
  { key: 'SMDF', i18nKey: 'menu.input.shinsegae-main(D)' },
  { key: 'HCDF', i18nKey: 'menu.input.hyundai-coex(D)' },
  { key: 'LMDF', i18nKey: 'menu.input.lotte-main(D)' },

  // {
  //   key: 'The Hyundai Seoul Shoes',
  //   i18nKey: 'menu.input.the-hyundai-seoul-shoes',
  // },
  // { key: 'Shinsegae Gyeonggi', i18nKey: 'menu.input.shinsegae-gyeonggi' },
  // {
  //   key: 'Shinsegae Gyeonggi Men',
  //   i18nKey: 'menu.input.shinsegae-gyeonggi-men',
  // },
  // { key: 'Lotte World Tower Men', i18nKey: 'menu.input.lotte-world-tower-men' },
  // {
  //   key: 'Shinsegae Gangnam Shoes',
  //   i18nKey: 'menu.input.shinsegae-gangnam-shoes',
  // },
  // {
  //   key: 'Lotte World Tower Women',
  //   i18nKey: 'menu.input.lotte-world-tower-women',
  // },
  // {
  //   key: 'Hyundai Coex(D)-Timepiece',
  //   i18nKey: 'menu.input.hyundai-coex(D)-timepiece',
  // },
  // { key: 'Lotte Busan(D)', i18nKey: 'menu.input.lotte-busan(D)' },
  // { key: 'Lotte Jeju(D)', i18nKey: 'menu.input.lotte-jeju(D)' },
  // {
  //   key: 'Lotte Main(D)-Timepiece',
  //   i18nKey: 'menu.input.lotte-main(D)-timepiece',
  // },
  // {
  //   key: 'Lotte World(D)-Timepiece',
  //   i18nKey: 'menu.input.lotte-world(D)-timepiece',
  // },
  // { key: 'Shilla I Park(D)', i18nKey: 'menu.input.shilla-i-park(D)' },
  // {
  //   key: 'Shilla Incheon(D)-Timepiece',
  //   i18nKey: 'menu.input.shilla-incheon(D)-timepiece',
  // },
  // { key: 'Shilla Jeju(D)', i18nKey: 'menu.input.shilla-jeju(D)' },
  // {
  //   key: 'Shilla Jeju(D)-Timepiece',
  //   i18nKey: 'menu.input.shilla-jeju(D)-timepiece',
  // },
  // {
  //   key: 'Shilla Seoul(D)-Timepiece',
  //   i18nKey: 'menu.input.shilla-seoul(D)-timepiece',
  // },
  // { key: 'Shinsegae Busan(D)', i18nKey: 'menu.input.shinsegae-busan(D)' },
  // { key: 'Shinsegae Gangnam(D)', i18nKey: 'menu.input.shinsegae-gangnam(D)' },
  // {
  //   key: 'Shinsegae Gangnam(D)-Timepiece',
  //   i18nKey: 'menu.input.shinsegae-gangnam(D)-timepiece',
  // },
  // {
  //   key: 'Shinsegae Incheon T2(D)-Timepiece',
  //   i18nKey: 'menu.input.shinsegae-incheon-t2(D)-timepiece',
  // },
  // {
  //   key: 'Shinsegae Main(D)-Timepiece',
  //   i18nKey: 'menu.input.shinsegae-main(D)-timepiece',
  // },
];
