import { ReactComponent as MoreIcon } from 'assets/images/icon/more.svg';
import React, { useCallback, useEffect, useRef } from 'react';
import { MoveToPrevStepButton } from 'components/recruitment/detail/MoveToPrevStepButton';
import { DeleteCandidateButton } from 'components/recruitment/detail/DeleteCandidateButton';
import { EditCandidateEmailButton } from 'components/recruitment/detail/EditCandidateEmailButton';
import { candidateActionMenu } from 'constants/recruitments/candidateActionMenu';
import { useSelector } from 'react-redux';

export const CandidateDetailMoreMenu = ({candidate, rid, menuList}) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const dropdownRef = useRef(null);
  const { userInfo } = useSelector((state) => state.auth);

  const handleOnClickButton = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowDropdown(true);
  },[setShowDropdown]);

  useEffect(() => {
    const handler = event => {
      if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
        return;
      }

      setShowDropdown(false);
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdownRef, setShowDropdown]);

  if(userInfo?.memberType >= 15) return;

  return (
    <div className="recruitment-table-dropdown" onClick={handleOnClickButton} ref={dropdownRef}>
      <div className="recruitment-table-dropdown-wrapper"><MoreIcon/></div>
          {showDropdown && (
            <div className="recruitment-table-dropdown-box">
              {menuList.map((item) => {
                if(item === candidateActionMenu.moveToPrevStep)
                  return <MoveToPrevStepButton  candidate={candidate} rid={rid} targetStageNumber={candidate.recruitProgress - 1}/>;
                if(item === candidateActionMenu.editCandidateEmail)
                  return <EditCandidateEmailButton candidate={candidate} rid={rid}/>;
                if(item === candidateActionMenu.deleteCandidate)
                  return <DeleteCandidateButton  candidateEmail={candidate.email} rid={rid} />;
              })}
            </div>
          )}
    </div>
  );
};