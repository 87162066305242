/*eslint-disable*/
import React from 'react';
import { useStatus } from 'utils/status';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { read, utils } from 'xlsx';
import { useAuth } from 'utils/auth';
import {
  PieChart,
  Pie,
  Cell,
  LabelList,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
} from 'recharts';

const column = [
  '참여 방법',
  '지원 분야',
  '지원 채널',
  '지원 지역',
  '전공',
  '외국어',
  '직무관련 특별 경력',
  '최근 근무 회사/경력 분야',
  '기타 판매 경험',
  '럭셔리 브랜드 판매 경험',
  '최종학력',
  '무엇을 전공하였나요?',
  '총 경력',
  '나이',
  '성별',
  '최종 연봉',
  '희망 포지션',
  '유입 경로',
  '산학 협력 소속 대학교명',
];

const Statistic = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsEntireLoading } = useStatus();

  const { blobData, addData } = location.state || {};

  const { userInfo } = useAuth();
  const [isRefreshing, setIsRefreshing] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [filterAssessment, setFilterAssessment] = React.useState({});
  const [filterSkill, setFilterSkill] = React.useState({});
  const [__candidates, __setCandidates] = React.useState([]);
  const [_candidates, _setCandidates] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [assessments, setAssessments] = React.useState({});
  const [skills, setSkills] = React.useState({});
  const [gucciExcelCandidateList, setGucciExcelCandidateList] = React.useState(
    []
  );
  const [gucciResumeCandidateList, setGucciResumeCandidateList] =
    React.useState([]);
  const [gucciCandidateList, setGucciCandidateList] = React.useState([]);
  const [isApplyLoading, setIsApplyLoading] = React.useState(false);
  const [resumeSave, setResumeSave] = React.useState([]);
  const [TotalData, setTotalData] = React.useState({});
  const [AllData, setAllData] = React.useState({});

  React.useEffect(() => {
    const tmpStatistic = {};
    const reader = new FileReader();

    reader.onload = function () {
      const data = reader.result;
      const workbook = read(data, { type: 'binary' });
      let perSheet;
      workbook.SheetNames.forEach((sheetName) => {
        if (sheetName !== '지원 신청 리스트') return;
        perSheet = utils.sheet_to_json(workbook.Sheets[sheetName]).map((x) => ({
          ...x,
        }));
        perSheet.forEach((row) => {
          if (row['유입 경로']) {
            row['유입 경로'] = ko2eng(row['유입 경로']);
          }
        });

        addData?.map((data) => {
          let foundItem = perSheet.find(
            (item) => item['이메일'] === data.email
          );
          const isGucciSurvey = Object.keys(data.results).find(
            (item) => item === '99111100000'
          );

          if (foundItem !== undefined) {
            foundItem['지원 분야'] = ko2eng(data?.results?.survey?.applyGucci);
            foundItem['지원 채널'] = mergeArray(
              data?.results?.survey?.channelGucci
            );
            foundItem['지원 지역'] = mergeArray(
              data?.results?.survey?.areaGucci
            );
            foundItem['전공'] = ko2eng(data?.results?.survey?.majorGucci);
            foundItem['외국어'] = mergeArray(
              data?.results?.survey?.languageGucci
            );
            foundItem['직무관련 특별 경력'] = ko2eng(
              data?.results?.survey?.specialExperienceGucci
            );
            foundItem['최근 근무 회사/경력 분야'] = ko2eng(
              data?.results?.survey?.careerGucci
            );
            foundItem['기타 판매 경험'] = ko2eng(
              data?.results?.survey?.saleLuxryExperienceGucci
            );
            foundItem['럭셔리 브랜드 판매 경험'] = ko2eng(
              data?.results?.survey?.saleExperienceGucci
            );
            foundItem['최종학력'] = changeEducationWord(data);
            foundItem['무엇을 전공하였나요?'] = ko2eng(
              data?.results?.survey?.major
            );
            foundItem['총 경력'] = changeYearsExperience(data);
            foundItem['나이'] = changeAgeWord(data);
            foundItem['성별'] = changeSexWord(data);
            foundItem['최종 연봉'] = ko2eng(
              data?.results?.survey?.salary + data?.results?.survey?.currency
            );
          } else if (sheetName === '지원 신청 리스트') {
            let newEmail = {};
            newEmail['참여 방법'] = '현장참여';
            newEmail['성'] = data.lastName;
            newEmail['이름'] = data.firstName;
            newEmail['이메일'] = data.email;
            newEmail['지원 분야'] = ko2eng(data?.results?.survey?.applyGucci);
            newEmail['지원 채널'] = mergeArray(
              data?.results?.survey?.channelGucci
            );
            newEmail['지원 지역'] = mergeArray(
              data?.results?.survey?.areaGucci
            );
            newEmail['전공'] = ko2eng(data?.results?.survey?.majorGucci);
            newEmail['외국어'] = mergeArray(
              data?.results?.survey?.languageGucci
            );
            newEmail['직무관련 특별 경력'] = ko2eng(
              data?.results?.survey?.specialExperienceGucci
            );
            newEmail['최근 근무 회사/경력 분야'] = ko2eng(
              data?.results?.survey?.careerGucci
            );
            newEmail['기타 판매 경험'] = ko2eng(
              data?.results?.survey?.saleLuxryExperienceGucci
            );
            newEmail['럭셔리 브랜드 판매 경험'] = ko2eng(
              data?.results?.survey?.saleExperienceGucci
            );
            newEmail['최종학력'] = changeEducationWord(data);
            newEmail['무엇을 전공하였나요?'] = ko2eng(
              data?.results?.survey?.major
            );
            newEmail['총 경력'] = changeYearsExperience(data);
            newEmail['나이'] = changeAgeWord(data);
            newEmail['성별'] = changeSexWord(data);
            newEmail['최종 연봉'] = ko2eng(
              data?.results?.survey?.salary + data?.results?.survey?.currency
            );

            perSheet.push(newEmail);
          }
        });
        getStatistic(perSheet);
      });
    };
    reader.readAsBinaryString(blobData);
  }, [blobData, addData]);

  const getStatistic = (allRows) => {
    const allData = {};
    const totalData = {};
    allRows.forEach((row) => {
      column.forEach((col) => {
        if (row[col] === undefined) {
          return;
        }
        if (!allData[col]) {
          allData[col] = {};
        }
        if (col === '외국어' || col === '지원 채널' || col === '지원 지역') {
          const vals = row[col].split(',');
          vals.forEach((val) => {
            if (!totalData[col]) {
              totalData[col] = 1;
            } else {
              totalData[col] += 1;
            }
            if (!allData[col][val]) {
              allData[col][val] = 1;
            } else {
              allData[col][val] += 1;
            }
          });
          return;
        }
        if (!totalData[col]) {
          totalData[col] = 1;
        } else {
          totalData[col] += 1;
        }
        if (col === '희망 포지션') {
          if (!allData[`${col}1`]) {
            allData[`${col}1`] = {};
          }
          const position = row[col].split(' - ');
          if (!allData[`${col}1`][position[0]]) {
            allData[`${col}1`][position[0]] = 1;
          } else {
            allData[`${col}1`][position[0]] += 1;
          }
          if (!allData[col][position[1]]) {
            allData[col][position[1]] = 1;
          } else {
            allData[col][position[1]] += 1;
          }
          if (!allData[position[0]]) {
            allData[position[0]] = {};
            if (!allData[position[0]][position[1]]) {
              allData[position[0]][position[1]] = 1;
            } else {
              allData[position[0]][position[1]] += 1;
            }
          } else if (!allData[position[0]][position[1]]) {
            allData[position[0]][position[1]] = 1;
          } else {
            allData[position[0]][position[1]] += 1;
          }
          return;
        }
        if (col === '성별' && row[col] === '') {
          if (!allData[col]['Prefer not to disclose']) {
            allData[col]['Prefer not to disclose'] = 1;
          } else {
            allData[col]['Prefer not to disclose'] += 1;
          }
          return;
        }
        if (!allData[col][row[col]]) {
          allData[col][row[col]] = 1;
        } else {
          allData[col][row[col]] += 1;
        }
      });
    });
    setTotalData(totalData);
    hash2Array(allData);
  };

  const hash2Array = (hashdata) => {
    const arrayedData = {};
    Object.keys(hashdata || {}).forEach((key) => {
      arrayedData[key] = new Array();
      Object.keys(hashdata[key] || {}).forEach((key2) => {
        const tmpvar = {};
        tmpvar.name = key2;
        tmpvar.cnt = hashdata[key][key2];
        arrayedData[key].push(tmpvar);
      });
    });
    setAllData(arrayedData);
  };

  const changeSexWord = (data) => {
    let word;
    switch (data?.results?.survey?.sex) {
      case 'male':
        word = 'Male';
        break;
      case 'female':
        word = 'Female';
        break;
      case 'non-disclosure':
        word = 'Prefer not to disclose';
        break;
      default:
        word = undefined;
        break;
    }

    return word;
  };

  function mergeArray(data) {
    if (data === undefined) return;

    if (Object.values(data).length === 1) {
      return ko2eng(Object.values(data)[0]);
    }
    let tmparray = new Array();
    Object.values(data).forEach((area) => {
      tmparray.push(ko2eng(area));
    });
    return tmparray.join(',');
  }

  const changeEducationWord = (data) => {
    let word;

    switch (data?.results?.survey?.educationLevel) {
      case 'high-school-graduation':
        word = t('menu.input.high-school-graduation');
        break;
      case 'college-graduation':
        word = t('menu.input.college-graduation');
        break;
      case 'while-in-university':
        word = t('menu.input.while-in-university');
        break;
      case 'university-graduation':
        word = t('menu.input.university-graduation');
        break;
      case 'finish-graduate-school':
        word = t('menu.input.finish-graduate-school');
        break;
      default:
        word = undefined;
        break;
    }

    return ko2eng(word);
  };

  const changeYearsExperience = (data) => {
    let word;

    switch (data?.results?.survey?.yearsExperience) {
      case '0-year':
        word = '<1 year';
        break;
      case '1-year':
      case '2-years':
      case '3-years':
      case '4-years':
      case '5-years':
        word = '1 to 5 year';
        break;
      case '6-years':
      case '7-years':
      case '8-years':
      case '9-years':
      case '10-years':
        word = '6 to 10 year';
        break;
      case '11-years':
      case '12-years':
      case '13-years':
      case '14-years':
      case '15-years':
      case '16-years':
      case '17-years':
      case '18-years':
      case '19-years':
      case '20-years':
        word = '10 years <';
        break;
      default:
        word = undefined;
        break;
    }

    return word;
  };

  const changeAgeWord = (data) => {
    let word;

    switch (data?.results?.survey?.age) {
      case 'under-18-years-old':
        word = 'Under 18';
        break;
      case '18-24-years-old':
        word = 'Age 18-24';
        break;
      case '25-34-years-old':
        word = 'Age 25-34';
        break;
      case '35-44-years-old':
        word = 'Age 35-44';
        break;
      case '45-54-years-old':
        word = 'Age 45-54';
        break;
      case '55-64-years-old':
        word = 'Age 55-64';
        break;
      case '64-years-old-and-above':
        word = '65 Above';
        break;
      default:
        word = undefined;
        break;
    }

    return word;
  };

  const ko2eng = (inputword) => {
    let word = '';
    switch (inputword) {
      case '현장참여':
        word = '';
        break;
      case '고등학교 졸업':
        word = '';
        break;
      case '전문대 졸업':
        word = '';
        break;
      case '대학교 재학 중':
        word = '';
        break;
      case '대학교 졸업':
        word = '';
        break;
      case '대학원 졸업 이상':
        word = '';
        break;
      case '서울':
        word = 'Seoul';
        break;
      case '경기/인천':
        word = 'Gyeongi/Incheon';
        break;
      case '대전':
        word = 'Daejeon';
        break;
      case '대구':
        word = 'Daegu';
        break;
      case '광주':
        word = 'Gwangju';
        break;
      case '부산':
        word = 'Busan';
        break;
      case '패션/디자인계열':
      case '패션/다자인계열':
        word = 'Fashion/Design';
        break;
      case '외국어계열':
        word = 'Languages';
        break;
      case '서비스 및 유통':
        word = 'Service & Retail';
        break;
      case '기타':
        word = 'Other';
        break;
      case '영어':
        word = 'English';
        break;
      case '중국어':
        word = 'Chinese';
        break;
      case '일본어':
        word = 'Japanese';
        break;
      case '해당사항 없음':
        word = 'None';
        break;
      case '명품 패션':
        word = 'Luxury fashion';
        break;
      case '국내 패션':
        word = 'Domestic fashion';
        break;
      case '비공개':
        word = 'Prefer not to disclose';
        break;
      case '남성':
        word = 'Male';
        break;
      case '여성':
        word = 'Female';
        break;
      case '예':
        word = 'Yes';
        break;
      case '아니오':
        word = 'No';
        break;
      case '백화점 및 플래그십 스토어':
        word = 'Department Store & Flagship Store';
        break;
      case '아울렛':
        word = 'Outlet';
        break;
      case '면세점':
        word = 'Duty-free';
        break;
      case '취업 포털 사이트':
        word = 'Job Portal';
        break;
      case '학교/학과 취업정보 페이지':
      case '학교/학과 취업 정보 페이지':
      case '산학 협력 학교':
      case 'Job Center Unit':
      case "College's Course Information Unit":
        word = 'University';
        break;
      case '지인':
        word = 'Friend';
        break;
      case '링크드인':
      case 'Linkedin':
        word = 'LinkedIn';
        break;
      case '직무 관련 동아리/커뮤니티':
        word = 'Other';
        break;
      case '인스타그램':
      case 'Facebook':
      case 'SNS (Facebook,Instagram etc.)':
        word = 'SNS';
        break;
      default:
        word = inputword;
        break;
    }

    return word;
  };

  const getNumOfTests = (candidate) => {
    const { assessments } = candidate;
    let cnt = 0;
    Object.entries(assessments || {}).forEach(([key, value]) => {
      cnt += value?.tests?.length || 0;
    });
    return cnt;
  };

  const onRowClickHandler = (candidate) => {
    if (candidate?.assessments !== undefined) {
      navigate(`${encodeURI(candidate?.id)}`);
    } else {
      return;
    }
  };

  const getAssessmentList = (_assessments) => {
    const cnt = Object.keys(_assessments || {}).length;
    const values = Object.values(_assessments || {});
    if (cnt <= 0) {
      return <div />;
    }
    if (cnt === 1) {
      return (
        <div>
          <span>{values[0].name}</span>
        </div>
      );
    }
    if (cnt === 2) {
      return (
        <div>
          <span>{values[0].name}</span>
          <span>{values[1].name}</span>
        </div>
      );
    }
    return (
      <div>
        <span>{values[0].name}</span>
        <span>{values[1].name}</span>
        <span>+ {cnt - 2}</span>
      </div>
    );
  };

  /** ======================
     * @function goStatistic() : 통계페이지로 이동
     * @param {*} blobData 
     * 통계 페이지에서 기존 엑셀 정보와 응시자 정보를 통합해서 그래프로 표시한다.
    ========================== */
  const goStatistic = (blobData) => {
    navigate('/customer/statistic', {
      state: {
        blobData,
        addData: addColumnApplyData,
      },
    });
  };

  const COLORS = [
    '#8C165D',
    '#713430',
    '#71461E',
    '#095236',
    '#002766',
    '#263361',
    '#523166',
    '#B7297E',
    '#B44B44',
    '#B66C27',
    '#3A876A',
    '#0050B3',
    '#354B9A',
    '#7F47A1',
    '#EF33A3',
    '#FB5E54',
    '#FF912B',
    '#24C185',
    '#3294EE',
    '#4162D7',
    '#AD5AE0',
    '#FF84CD',
    '#F99892',
    '#FBB778',
    '#49E0A6',
    '#7CB9F0',
    '#859AE2',
    '#C995E8',
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    data,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <main className="pages-protected-candidates-statistic">
      <header className="header">
        <h2 className="header-title fs28">Applicant Statistics</h2>
      </header>
      <div className="graph">
        <div className="row">
          <div className="col">
            <span className="graph-title fs18">
              How did you hear about this event?
            </span>
            <PieChart width={400} height={400}>
              <Pie
                data={AllData['유입 경로']}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={190}
                innerRadius={60}
                fill="#8884d8"
                dataKey="cnt"
              >
                <LabelList dataKey="cnt" position="inside" />
                {AllData['유입 경로']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="legend">
              {(AllData['유입 경로'] || []).map(
                (title, idx) =>
                  idx < 3 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['유입 경로'] || []).map(
                (title, idx) =>
                  idx >= 3 &&
                  idx < 6 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['유입 경로'] || []).map(
                (title, idx) =>
                  idx >= 6 &&
                  idx < 9 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['유입 경로'] || []).map(
                (title, idx) =>
                  idx >= 9 &&
                  idx < 12 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['유입 경로'] || []).map(
                (title, idx) =>
                  idx >= 12 &&
                  idx < 15 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['유입 경로'] || []).map(
                (title, idx) =>
                  idx >= 15 &&
                  idx < 18 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
          </div>
          <div className="col">
            <span className="graph-title fs18">Store - Position Applying</span>
            <PieChart width={400} height={400}>
              <Pie
                data={AllData.Store}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={190}
                innerRadius={60}
                fill="#8884d8"
                dataKey="cnt"
              >
                <LabelList dataKey="cnt" position="inside" />
                {AllData.Store?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="legend">
              {(AllData.Store || []).map((title, idx) => (
                <div key={idx} className="legend-cell">
                  <span
                    className="bullet"
                    style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                  >
                    ⦁
                  </span>
                  <span className="fs12">
                    {title?.name}:{title?.cnt}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span className="graph-title fs18">Year of Work Experience</span>
            <PieChart width={400} height={400}>
              <Pie
                data={AllData['총 경력']}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={190}
                innerRadius={60}
                fill="#8884d8"
                dataKey="cnt"
              >
                <LabelList dataKey="cnt" position="inside" />
                {AllData['총 경력']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="legend">
              {(AllData['총 경력'] || []).map(
                (title, idx) =>
                  idx < 3 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['총 경력'] || []).map(
                (title, idx) =>
                  idx >= 3 &&
                  idx < 6 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['총 경력'] || []).map(
                (title, idx) =>
                  idx >= 6 &&
                  idx < 9 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['총 경력'] || []).map(
                (title, idx) =>
                  idx >= 9 &&
                  idx < 12 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['총 경력'] || []).map(
                (title, idx) =>
                  idx >= 12 &&
                  idx < 15 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['총 경력'] || []).map(
                (title, idx) =>
                  idx >= 15 &&
                  idx < 18 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
          </div>
          <div className="col">
            <span className="graph-title fs18">
              Retail - Preferred Work Location
            </span>
            <PieChart width={400} height={400}>
              <Pie
                data={AllData['지원 지역']}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={190}
                innerRadius={60}
                fill="#8884d8"
                dataKey="cnt"
              >
                <LabelList dataKey="cnt" position="inside" />
                {AllData['지원 지역']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="legend">
              {(AllData['지원 지역'] || []).map((title, idx) => (
                <div key={idx} className="legend-cell">
                  <span
                    className="bullet"
                    style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                  >
                    ⦁
                  </span>
                  <span className="fs12">
                    {title?.name}:{title?.cnt}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span className="graph-title fs18">Store Preference</span>
            <BarChart
              width={400}
              height={400}
              data={AllData['지원 채널']}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <Bar dataKey="cnt">
                {AllData['지원 채널']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Bar>
              <YAxis />
            </BarChart>
            <div className="legend">
              {(AllData['지원 채널'] || []).map((title, idx) => (
                <div key={idx} className="legend-cell">
                  <span
                    className="bullet"
                    style={{ color: `${COLORS[idx]}`, fontSize: '30px' }}
                  >
                    ⦁
                  </span>
                  <span className="fs12">
                    {title?.name}:{title?.cnt}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="col">
            <span className="graph-title fs18">Language</span>
            <BarChart
              width={400}
              height={400}
              data={AllData['외국어']}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <Bar dataKey="cnt">
                {AllData['외국어']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Bar>
              <YAxis />
            </BarChart>
            <div className="legend">
              {(AllData['외국어'] || []).map((title, idx) => (
                <div key={idx} className="legend-cell">
                  <span
                    className="bullet"
                    style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                  >
                    ⦁
                  </span>
                  <span className="fs12">
                    {title?.name}:{title?.cnt}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span className="graph-title fs18">Educational Major</span>
            <PieChart width={400} height={400}>
              <Pie
                data={AllData['전공']}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={190}
                innerRadius={60}
                fill="#8884d8"
                dataKey="cnt"
              >
                <LabelList dataKey="cnt" position="inside" />
                {AllData['전공']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="legend">
              {(AllData['전공'] || []).map((title, idx) => (
                <div key={idx} className="legend-cell">
                  <span
                    className="bullet"
                    style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                  >
                    ⦁
                  </span>
                  <span className="fs12">
                    {title?.name}:{title?.cnt}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="col">
            <span className="graph-title fs18">
              Which job function are you applying?
            </span>
            <ComposedChart
              layout="vertical"
              width={400}
              height={400}
              data={AllData['희망 포지션']}
              margin={{
                top: 40,
                right: 20,
                bottom: 40,
                left: 60,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" strokeDasharray="3" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" scale="band" />
              <Tooltip dataKey="cnt" />
              <Bar dataKey="cnt" barSize={20} fill="#413ea0">
                <LabelList dataKey="cnt" position="right" />
                {AllData['희망 포지션']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Bar>
            </ComposedChart>
            <div className="legend">
              {(AllData['희망 포지션'] || []).map(
                (title, idx) =>
                  idx < 3 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['희망 포지션'] || []).map(
                (title, idx) =>
                  idx >= 3 &&
                  idx < 6 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['희망 포지션'] || []).map(
                (title, idx) =>
                  idx >= 6 &&
                  idx < 9 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className="legend">
              {(AllData['희망 포지션'] || []).map(
                (title, idx) =>
                  idx >= 9 &&
                  idx < 12 && (
                    <div key={idx} className="legend-cell">
                      <span
                        className="bullet"
                        style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                      >
                        ⦁
                      </span>
                      <span className="fs12">
                        {title?.name}:{title?.cnt}
                      </span>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span className="graph-title fs18">
              Do you have previous sales experience?
            </span>
            <PieChart width={400} height={400}>
              <Pie
                data={AllData['기타 판매 경험']}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={190}
                innerRadius={60}
                fill="#8884d8"
                dataKey="cnt"
              >
                <LabelList dataKey="cnt" position="inside" />
                {AllData['기타 판매 경험']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="legend">
              {(AllData['기타 판매 경험'] || []).map((title, idx) => (
                <div key={idx} className="legend-cell">
                  <span
                    className="bullet"
                    style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                  >
                    ⦁
                  </span>
                  <span className="fs12">
                    {title?.name}:{title?.cnt}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="col">
            <span className="graph-title fs18">Gender</span>
            <PieChart width={400} height={400}>
              <Pie
                data={AllData['성별']}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={190}
                innerRadius={60}
                fill="#8884d8"
                dataKey="cnt"
              >
                <LabelList dataKey="cnt" position="inside" />
                {AllData['성별']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="legend">
              {(AllData['성별'] || []).map((title, idx) => (
                <div key={idx} className="legend-cell">
                  <span
                    className="bullet"
                    style={{ color: `${COLORS[idx]}`, fontSize: '40px' }}
                  >
                    ⦁
                  </span>
                  <span className="fs12">
                    {title?.name}:{title?.cnt}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span className="graph-title fs18">Applicant's Age Group</span>
            <PieChart width={400} height={400}>
              <Pie
                data={AllData['나이']}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={190}
                innerRadius={60}
                fill="#8884d8"
                dataKey="cnt"
              >
                <LabelList dataKey="cnt" position="inside" />
                {AllData['나이']?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className="legend">
              {(AllData['나이'] || []).map((title, idx) => (
                <div key={idx} className="legend-cell">
                  <span
                    className="bullet"
                    style={{ color: `${COLORS[idx]}`, fontSize: '30px' }}
                  >
                    ⦁
                  </span>
                  <span className="fs12">
                    {title?.name}:{title?.cnt}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="col" />
        </div>
      </div>
    </main>
  );
};

export default Statistic;
