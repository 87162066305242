import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { languageOptions } from 'components/codeEditor/languageOptions';
import { useModal } from 'utils/modal';
import { useStatus } from 'utils/status';
import { toast } from 'utils/notify';
import {
  createAssessment,
  updateAssessment,
  createLibraryDoc,
  getSkills,
  getAssessmentOfCurrentUser,
  getLibSkills,
} from 'store/slices/assessmentsSlice';
import { getUserInfo } from 'store/slices/authSlice';
import { executeAction } from 'utils/redux';
import { convertTextToSec } from 'utils/string';
import Step1_1 from './create/Step1_1';
import Step4 from './create/Step4';
import Step3 from './create/Step3';
import Step2 from './create/Step2';
import Step1 from './create/Step1';
import Step0 from './create/Step0';

const __GROUP_TEST_TIME__ = 10;

const Create = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayModal } = useModal();
  const { setIsEntireLoading } = useStatus();
  const [searchParams] = useSearchParams();
  const aid = searchParams.get('aid');
  const [init, setInit] = React.useState({
    step0: false,
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step1_1: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [curStep, setCurStep] = React.useState(0);
  const [_curStep, _setCurStep] = React.useState(0);
  const [assessment, setAssessment] = React.useState(null);
  const [name, setName] = React.useState('');
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');
  const [position, setPosition] = React.useState({});
  const [positionErrorMessage, setPositionErrorMessage] = React.useState('');
  const [language, setLanguage] = React.useState('');
  const [languageErrorMessage, setLanguageErrorMessage] = React.useState('');
  const [objectiveTests, setObjectiveTests] = React.useState([]);
  const [groupTests, setGroupTests] = React.useState({});
  const [subjectiveTests, setSubjectiveTests] = React.useState([]);
  const [tests, setTests] = React.useState([]);
  const [isPositionsLoading, setIsPositionsLoading] = React.useState(false);
  const [positionList, setPositionList] = React.useState([]);
  const [isSkillsLoading, setIsSkillsLoading] = React.useState(false);
  const [skillList, setSkillList] = React.useState({});
  const [objskillList, setObjSkillList] = React.useState([]);
  const [libskillList, setLibSkillList] = React.useState([]);
  const [duration, setDuration] = React.useState(0);
  const [testsCnt, setTestsCnt] = React.useState(0);
  const [testCategory, setTestCategory] = React.useState(
    'evaluator-select-test'
  );
  const [defaultCategory, setDefaultCategory] = React.useState('');
  const [maxStepCnt, setMaxStepCnt] = React.useState(4);
  const [canGoStep2, setCanGoStep2] = React.useState(true);
  const [AntiCheating, setAntiCheating] = React.useState(true);
  const [introVideoUrl, setIntroVideoUrl] = React.useState('');
  const [codeLanguage, setCodeLanguage] = React.useState(languageOptions[0].id);
  const [fileName, setFileName] = React.useState('');
  const [videoQuestion, setVideoQestion] = React.useState('');
  const [videoQuestionFileName, setVideoQuestionFileName] = React.useState('');
  const [surveyOnOff, setSurveyOnOff] = React.useState('');
  const [periodOnOff, setPeriodOnOff] = React.useState(false);
  const [startPeriodDateTime, setStartPeriodDateTime] = React.useState();
  const [endPeriodDateTime, setEndPeriodDateTime] = React.useState();

  // 가입자의 등급 정보를 저장한다.
  const [plan, setPlan] = React.useState(1);
  const [enabledSkillList, setEnabledSkillList] = React.useState([]);
  // 임직원 평가와 채용 평가로 구분하고 기본을 채용평가로 한다.
  const [purpose, setPurpose] = React.useState('recruitment-assessment');

  const prevStepRef = React.useRef();
  const prevTestsRef = React.useRef();

  const lang = t('lang');
  // 사용자 정보에서 plan을 가져와서 어떤 plan에 가입되어 있는지 확인한다.
  // plan별로 다양한 제한을 걸도록 각각 필요한 부분에 제한을 추가한다.
  // plan은 0부터 숫자를 증가 시켜 설정한다.
  // 0이 가장낮은 단계이고 숫자가 클수록 높은단계
  React.useEffect(() => {
    executeAction(getUserInfo())
      .unwrap()
      .then(({ doc, logoURL }) => {
        if (doc.plan !== undefined) {
          setPlan(doc.plan);
        }
        setEnabledSkillList(doc[`enabledSkill_${lang}`] || []);
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });
  }, []);

  // 아래 소스로 인해서 에러가 발생하여 일단 제거한다.
  // 추후 해결한 후 원복이 필요함
  React.useEffect(() => {
    if (testCategory === 'candidate-select-test') {
      setMaxStepCnt(3);
    } else {
      setMaxStepCnt(4);
    }
  }, [testCategory]);

  React.useEffect(() => {
    var duration = getDuration();
    var cnts = getTestsCnt();
    setDuration(duration);
    setTestsCnt(cnts);
  }, [objectiveTests, subjectiveTests, groupTests]);

  React.useEffect(() => {
    prevStepRef.current = curStep;
  }, [curStep]);

  React.useEffect(() => {
    // if (!Boolean(name) || isEmptyObject(position)) {
    if (!name || !language) {
      if (_curStep !== prevStepRef.current) {
        toast(t('text-field.error.create-step-0'));
        if (!name) {
          setNameErrorMessage(t('text-field.error.assessment-name'));
        }

        if (!language) {
          setLanguageErrorMessage('언어를 선택해주세요.');
        }
      }
      _setCurStep(prevStepRef.current);
      return;
    }
    if (curStep === 1 && _curStep > 1) {
    }
    setCurStep(_curStep);
  }, [_curStep, canGoStep2]);

  React.useEffect(() => {
    if (name) setNameErrorMessage('');
    if (language) setLanguageErrorMessage('');
  }, [name]);

  // on create done event listen.
  React.useEffect(() => {
    const onCreateDoneHandler = (e) => {
      if (e.detail?.message === 'createAssessmentDone') {
        setIsEntireLoading(true);
        if (aid) {
          executeAction(
            updateAssessment({
              aid,
              name,
              language:
                language ||
                window.localStorage.getItem('i18nextLng').slice(0, 2),
              groupTests,
              objectiveTests,
              subjectiveTests,
              tests,
              obpiBenchmark: {},
              AntiCheating,
              introVideoUrl,
              purpose,
              codeLanguage,
              surveyOnOff,
              periodOnOff,
              startPeriodDateTime: periodOnOff
                ? startPeriodDateTime
                : undefined,
              endPeriodDateTime: periodOnOff ? endPeriodDateTime : undefined,
            })
          )
            .unwrap()
            .then(() => {
              executeAction(
                createLibraryDoc({
                  subjectiveTests,
                })
              )
                .unwrap()
                .then(() => {
                  setIsEntireLoading(false);
                  navigate('/customer/assessments', {
                    state: {
                      message: 'createAssessmentDone',
                    },
                  });
                })
                .catch((e) => {
                  console.dir(e);
                  setIsEntireLoading(false);
                });
            })
            .catch((e) => {
              console.dir(e);
              setIsEntireLoading(false);
            });
        } else {
          executeAction(
            createAssessment({
              name,
              // position,
              language:
                language ||
                window.localStorage.getItem('i18nextLng').slice(0, 2),
              groupTests,
              objectiveTests,
              subjectiveTests,
              tests,
              AntiCheating,
              introVideoUrl,
              purpose,
              codeLanguage,
              surveyOnOff,
              periodOnOff,
              startPeriodDateTime: periodOnOff
                ? startPeriodDateTime
                : undefined,
              endPeriodDateTime: periodOnOff ? endPeriodDateTime : undefined,
            })
          )
            .unwrap()
            .then(() => {
              executeAction(
                createLibraryDoc({
                  subjectiveTests,
                })
              )
                .unwrap()
                .then(() => {
                  setIsEntireLoading(false);
                  navigate('/customer/assessments', {
                    state: {
                      message: 'createAssessmentDone',
                    },
                  });
                })
                .catch((e) => {
                  setIsEntireLoading(false);
                });
            })
            .catch((e) => {
              setIsEntireLoading(false);
            });
        }

        window.document.removeEventListener(
          'dismissModal',
          onCreateDoneHandler
        );
      }
    };
    window.document.addEventListener('dismissModal', onCreateDoneHandler);

    return () =>
      window.document.removeEventListener('dismissModal', onCreateDoneHandler);
  }, [
    name,
    position,
    language,
    objectiveTests,
    subjectiveTests,
    tests,
    AntiCheating,
    codeLanguage,
    introVideoUrl,
    surveyOnOff,
    periodOnOff,
    startPeriodDateTime,
    endPeriodDateTime,
  ]);

  // step3 -> change the tests order
  React.useEffect(() => {
    if (prevTestsRef.current === tests) return;

    let _tests = new Array();
    if (
      groupTests?.group?.length > 0 &&
      groupTests?.testcnt > 0 &&
      testCategory === 'candidate-select-test'
    ) {
      _tests.push(groupTests);
    }
    _tests = [..._tests, ...tests];
    if (
      _tests[0]?.group?.length > 0 &&
      _tests[0]?.testcnt > 0 &&
      testCategory === 'candidate-select-test'
    ) {
      setTestCategory('candidate-select-test');
      setGroupTests(_tests[0]);
    }

    _tests = (_tests || []).filter((x) => {
      let uuids = [];
      uuids.push(Object.values(objectiveTests || []).map((test) => test.uuid));
      uuids.push(Object.values(subjectiveTests || []).map((test) => test.uuid));
      return (uuids || []).includes(x.uuid);
    });

    if (groupTests?.group?.length > 0 && groupTests?.testcnt > 0) {
      _tests.push(groupTests);
    }

    const _objectiveTests = (objectiveTests || []).filter((x) => {
      const uuids = Object.values(_tests || []).map((test) => test.uuid);
      return !(uuids || []).includes(x.uuid);
    });
    _tests = [..._tests, ..._objectiveTests];

    _tests = [..._tests, ...subjectiveTests];
    prevTestsRef.current = tests;
    setTests(_tests);
  }, [groupTests, objectiveTests, subjectiveTests]);

  // get position, skill, test data from database.
  // skillList를 가져오는 방식 변경
  // 객관식 테스트와 라이브러리를 동시에 가져와서 step1에서 보여준다.
  React.useEffect(() => {
    setIsEntireLoading(true);
    const tasks = [];

    tasks.push(
      executeAction(getSkills(language || lang))
        .unwrap()
        .then(({ docs: _docs }) => {
          setObjSkillList(_docs || []);
        })
        .catch((e) => {
          console.dir(e);
          setObjSkillList([]);
        })
    );
    tasks.push(
      executeAction(getLibSkills(language || lang))
        .unwrap()
        .then(({ docs: defdocs }) => {
          setLibSkillList(defdocs || []);
        })
        .catch((e) => {
          console.dir(e);
          setLibSkillList([]);
        })
    );
    tasks.push(
      executeAction(
        getAssessmentOfCurrentUser({
          aid,
        })
      )
        .unwrap()
        .then(({ doc }) => {
          if (doc?.groupTests && doc?.groupTests?.skills?.length > 0) {
            setDefaultCategory('candidate-select-test');
            setTestCategory('candidate-select-test');
          } else {
            setDefaultCategory('evaluator-select-test');
            setTestCategory('evaluator-select-test');
          }
          setAssessment(doc);
        })
        .catch((e) => {
          setAssessment(null);
        })
    );
    Promise.allSettled(tasks).then(() => {
      setIsEntireLoading(false);
    });
  }, [aid, language]);

  // [aid, language]가 있는 함수에서 처리 순서상 문제가 있는 것 같아서
  // objskillList, libskillList의 변화를 감지하여 skillset을 만든다.
  // 객관식 테스트 시간을 15분에서 8분으로 변경한다.
  // DB에서 읽어온 객관식 테스트 시간이 15분이면 8분으로 변경한다.
  React.useEffect(() => {
    const docs = {};
    (objskillList || []).forEach((doc) => {
      if (doc.duration === 15) {
        // 영어, 중국어, 일본어 영어테스트
        if (doc.id !== '6010' && doc.id !== '6011' && doc.id !== '6012') {
          doc.duration = 8;
        }
      }
      docs[doc?.id] = doc;
    });

    (libskillList || []).forEach((doc) => {
      // 라이브러리 문제의 경우 각 10분으로 생각했는데 전체를 10분으로 변경한다.
      doc.duration = 10;
      if (doc?.question_type?.indexOf('tag.code') >= 0) {
        doc.duration = 20;
      }
      docs[doc?.id] = doc;
    });

    setSkillList(docs);
  }, [objskillList, libskillList]);

  // Modify assessment.
  React.useEffect(() => {
    if (!assessment) {
      setLanguage(lang);
      return;
    }
    setName(assessment?.name !== name && name !== '' ? name : assessment?.name);
    setLanguage(
      language !== undefined || language !== ''
        ? assessment?.language
        : language
    );
    setObjectiveTests(assessment?.objectiveTests);
    setSubjectiveTests(assessment?.subjectiveTests);
    setAntiCheating(assessment?.AntiCheating);
    setIntroVideoUrl(assessment?.introVideoUrl || '');
    setTests(assessment?.tests);
    setSurveyOnOff(assessment?.surveyOnOff);
    setPeriodOnOff(assessment?.periodOnOff);
    setStartPeriodDateTime(assessment?.startPeriodDateTime);
    setEndPeriodDateTime(assessment?.endPeriodDateTime);
  }, [assessment]);

  const getStepTitle = (step) => {
    if (testCategory === 'candidate-select-test') {
      switch (step) {
        case 0:
          return 'step.name-assessment';
        case 1:
          return 'step.select-test-category';
        case 2:
          return 'step.add-questions';
        case 3:
          return 'step.review-configure';
      }
    } else {
      switch (step) {
        case 0:
          return 'step.name-assessment';
        case 1:
          return 'step.select-test';
        case 2:
          return 'step.test-review';
        case 3:
          return 'step.add-questions';
        case 4:
          return 'step.review-configure';
      }
    }
  };

  const getDuration = () => {
    let duration = 0;
    if (testCategory === 'candidate-select-test') {
      if (groupTests?.testcnt) {
        duration += groupTests?.testcnt * __GROUP_TEST_TIME__;
      }
    } else if (objectiveTests?.length > 0) {
      Object.values(objectiveTests || {}).forEach((test, index) => {
        duration += test?.limit;
      });
    }
    (subjectiveTests || []).forEach((test) => {
      const sec = convertTextToSec(test.limit);
      duration += sec / 60;
    });
    return duration;
  };

  const getTestsCnt = () => {
    let cnt = 0;
    if (testCategory === 'candidate-select-test' && groupTests?.group) {
      cnt += 1;
    } else if (objectiveTests?.length > 0) {
      cnt += objectiveTests?.length;
    }
    cnt += subjectiveTests?.length;
    return cnt;
  };

  const getskills = () => {
    const tasks = [];

    tasks.push(
      executeAction(getSkills(language || lang))
        .unwrap()
        .then(({ docs: _docs }) => {
          const docs = {};

          (_docs || []).forEach((doc) => {
            docs[doc?.id] = doc;
          });
          setSkillList(docs || {});
        })
        .catch((e) => {
          console.dir(e);
          setSkillList({});
        })
    );

    Promise.allSettled(tasks).then(() => setIsEntireLoading(false));
  };

  const onGoBackClickHandler = () => {
    window.scrollTo(0, 0);
    if (curStep > 0) _setCurStep(curStep - 1);
  };

  const onGoNextClickHandler = () => {
    // console.log(curStep);
    // console.log(testCategory);
    // 이미 로딩 될 때 skill를 가져왔는데 다음 단계로 넘어갈 때 다시 skill을 가져올 필요가 없어서 주석 처리
    window.scrollTo(0, 0);
    let isNext = true;
    if (curStep === 0 && testCategory === 'evaluator-select-test') {
      setGroupTests({});
    } else if (curStep === 2 && testCategory === 'evaluator-select-test') {
      objectiveTests.forEach((test) => {
        if (test.questionNum === 0) {
          isNext = false;
          toast(t('column.select-question'));
          return;
        }
      });

      if (isNext === false) return;
    }
    if (curStep + 1 < 5) _setCurStep(curStep + 1);
  };

  const onDoneClickHandler = () => {
    if (curStep < maxStepCnt) return;

    if (
      objectiveTests?.length + subjectiveTests?.length > 0 ||
      Boolean(groupTests.skills)
    ) {
      if (periodOnOff && (!startPeriodDateTime || !endPeriodDateTime))
        toast(t('toast.desc.add-assessment-period'));
      else if (
        (periodOnOff && startPeriodDateTime >= endPeriodDateTime) ||
        (periodOnOff && endPeriodDateTime < new Date().getTime())
      ) {
        toast(t('dialog.title.error-assessment-end-time'));
      } else {
        displayModal(
          'assessments/CreateAssessmentDone',
          {
            event: {
              message: 'createAssessmentDone',
            },
            title: {
              main: t('dialog.title.complete'),
            },
          },
          {
            frame: 'part',
            hasButton: true,
          }
        );
      }
    } else {
      toast(t('toast.desc.empty-test'));
    }
  };

  const cleartest = (key) => {
    if (key === undefined || language === undefined || key === language) return;
    setObjectiveTests([]);
    setSubjectiveTests([]);
    setGroupTests({});
    setTests([]);
    prevTestsRef.current = tests;
  };

  const onSelectChange = (sl, idx) => {
    const temp = objectiveTests;
    temp[idx].codeLanguage = sl;
    objectiveTests[idx].codeLanguage = sl;

    setObjectiveTests(temp);
    return setCodeLanguage(sl);
  };

  const getStepValue = (step, category) => {
    if (step === 0) {
      return (
        <Step0
          init={init}
          setInit={setInit}
          curStep={curStep}
          name={name}
          setName={setName}
          nameErrorMessage={nameErrorMessage}
          positionList={positionList}
          skillList={skillList}
          position={position}
          setPosition={setPosition}
          positionErrorMessage={positionErrorMessage}
          language={language}
          setLanguage={setLanguage}
          testCategory={testCategory}
          setTestCategory={setTestCategory}
          defaultCategory={defaultCategory}
          cleartest={cleartest}
          languageErrorMessage={languageErrorMessage}
          setPurpose={setPurpose}
          introVideoUrl={introVideoUrl}
          setIntroVideoUrl={setIntroVideoUrl}
          fileName={fileName}
          setFileName={setFileName}
        />
      );
    }
    if (step === 1) {
      if (category === 'candidate-select-test') {
        return (
          <Step1_1
            init={init}
            setInit={setInit}
            curStep={curStep}
            positionList={positionList}
            skillList={skillList}
            position={position}
            objectiveTests={objectiveTests}
            setObjectiveTests={setObjectiveTests}
            testCategory={testCategory}
            setTestCategory={setTestCategory}
            groupTests={groupTests}
            setGroupTests={setGroupTests}
            setCanGoStep2={setCanGoStep2}
            language={language}
          />
        );
      }
      if (category === 'evaluator-select-test') {
        return (
          <Step1
            init={init}
            setInit={setInit}
            curStep={curStep}
            positionList={positionList}
            skillList={skillList}
            position={position}
            objectiveTests={objectiveTests}
            setObjectiveTests={setObjectiveTests}
            testCategory={testCategory}
            setTestCategory={setTestCategory}
            setGroupTests={setGroupTests}
            language={language}
            plan={plan}
            enabledSkillList={enabledSkillList}
          />
        );
      }
    } else if (step === 2) {
      if (category === 'candidate-select-test') {
        return (
          <Step3
            init={init}
            setInit={setInit}
            curStep={curStep}
            positionList={positionList}
            skillList={skillList}
            subjectiveTests={subjectiveTests}
            setSubjectiveTests={setSubjectiveTests}
            language={language}
            videoQuestion={videoQuestion}
            setVideoQuestion={setVideoQestion}
            videoQuestionFileName={videoQuestionFileName}
            setVideoQuestionFileName={setVideoQuestionFileName}
          />
        );
      }
      if (category === 'evaluator-select-test') {
        return (
          <Step2
            init={init}
            setInit={setInit}
            curStep={curStep}
            positionList={positionList}
            skillList={skillList}
            objectiveTests={objectiveTests}
            setObjectiveTests={setObjectiveTests}
            setDuration={setDuration}
            language={language}
            codeLanguage={codeLanguage}
          />
        );
      }
    } else if (step === 3) {
      if (category === 'candidate-select-test') {
        return (
          <Step4
            init={init}
            setInit={setInit}
            curStep={curStep}
            positionList={positionList}
            skillList={skillList}
            tests={tests}
            setTests={setTests}
            objectiveTests={objectiveTests}
            subjectiveTests={subjectiveTests}
            groupTests={groupTests}
            AntiCheating={AntiCheating}
            setAntiCheating={setAntiCheating}
            setSurveyOnOff={setSurveyOnOff}
            setPeriodOnOff={setPeriodOnOff}
            periodOnOff={periodOnOff}
            setStartPeriodDateTime={setStartPeriodDateTime}
            setEndPeriodDateTime={setEndPeriodDateTime}
          />
        );
      }
      if (category === 'evaluator-select-test') {
        return (
          <Step3
            init={init}
            setInit={setInit}
            curStep={curStep}
            positionList={positionList}
            skillList={skillList}
            subjectiveTests={subjectiveTests}
            setSubjectiveTests={setSubjectiveTests}
            language={language}
            videoQuestion={videoQuestion}
            setVideoQuestion={setVideoQestion}
            videoQuestionFileName={videoQuestionFileName}
            setVideoQuestionFileName={setVideoQuestionFileName}
          />
        );
      }
    } else if (step === 4) {
      return (
        <Step4
          init={init}
          setInit={setInit}
          curStep={curStep}
          positionList={positionList}
          skillList={skillList}
          tests={tests}
          setTests={setTests}
          objectiveTests={objectiveTests}
          subjectiveTests={subjectiveTests}
          groupTests={groupTests}
          AntiCheating={AntiCheating}
          setPurpose={setPurpose}
          setAntiCheating={setAntiCheating}
          setSurveyOnOff={setSurveyOnOff}
          setPeriodOnOff={setPeriodOnOff}
          periodOnOff={periodOnOff}
          setStartPeriodDateTime={setStartPeriodDateTime}
          setEndPeriodDateTime={setEndPeriodDateTime}
        />
      );
    }
  };

  /** =================================
     * 평가 생성 다음 이전 버튼 스크롤 시 상단 고정
     =================================== */
  React.useEffect(() => {
    const offsetHeader = window.document.getElementById('create-header');
    const offsetFixedHeader = window.document.getElementById('fixed-header');
    const offsetHeaderY = offsetHeader.offsetTop;

    function onScollFixed(e) {
      let scrollY = window.pageYOffset;

      if (offsetHeaderY + 94 <= scrollY) {
        offsetFixedHeader.classList.add('fixed');
      } else {
        offsetFixedHeader.classList.remove('fixed');
      }
    }

    window.document.addEventListener('scroll', onScollFixed);
  }, []);

  return (
    <main className="pages-protected-assessments-create">
      <header className="header" id="create-header">
        <section className="header-left">
          <div className="header-left-title">
            <h2 className="header-left-title-main fs28">
              {t('title.create-new-assesment')}
            </h2>
            <div className="header-left-title-sub">
              <span>{`${testsCnt} ${t('column.test')}`}</span>
              <span>{`${duration} ${t('desc.minute')}`}</span>
            </div>
          </div>
        </section>
        <section className="header-right">
          {curStep > 0 && (
            <button
              className="header-right-go-back common-button fsbtn16 gray active"
              onClick={onGoBackClickHandler}
            >
              <span className="fsbtn16">{t('btn.previous')}</span>
            </button>
          )}
          {curStep < maxStepCnt ? (
            <button
              className="header-right-go-next common-button fsbtn16 primary active"
              onClick={onGoNextClickHandler}
            >
              <span className="fsbtn16">{t('btn.next')}</span>
            </button>
          ) : (
            <button
              className="header-right-done common-button fsbtn16 primary active"
              onClick={onDoneClickHandler}
            >
              <span className="fsbtn16">{t('btn.next')}</span>
            </button>
          )}
        </section>
      </header>
      <header className="header" id="fixed-header">
        <section className="header-left">
          <div className="header-left-title">
            <h2 className="header-left-title-main fs28">
              {t('title.create-new-assesment')}
            </h2>
            <div className="header-left-title-sub">
              <span>{`${testsCnt} ${t('column.test')}`}</span>
              <span>{`${duration} ${t('desc.minute')}`}</span>
            </div>
          </div>
        </section>
        <section className="header-right">
          {curStep > 0 && (
            <button
              className="header-right-go-back common-button fsbtn16 gray active"
              onClick={onGoBackClickHandler}
            >
              <span className="fsbtn16">{t('btn.previous')}</span>
            </button>
          )}
          {curStep < maxStepCnt ? (
            <button
              className="header-right-go-next common-button fsbtn16 primary active"
              onClick={onGoNextClickHandler}
            >
              <span className="fsbtn16">{t('btn.next')}</span>
            </button>
          ) : (
            <button
              className="header-right-done common-button fsbtn16 primary active"
              onClick={onDoneClickHandler}
            >
              <span className="fsbtn16">{t('btn.next')}</span>
            </button>
          )}
        </section>
      </header>
      <section className="new-steps container">
        <span className="left fs48">{`0${curStep + 1}`}</span>
        <div className="right">
          <h2 className="title fs24">{t(getStepTitle(curStep))}</h2>
          <div className="progress-bar">
            <div
              className="progress-bar-fill"
              style={{
                width: `${(curStep / maxStepCnt) * 100}%`,
              }}
            />
          </div>
        </div>
      </section>

      {getStepValue(curStep, testCategory)}
    </main>
  );
};

Create.propTypes = {};

export default Create;
