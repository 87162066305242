import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  getCandidateOfRecruitment,
  getRecruitment,
} from 'store/slices/recruitments/recruitmentsSlice';

const CompensationRouter = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('uid');
  const rid = searchParams.get('aid');
  const email = searchParams.get('email');
  const step = searchParams.get('step');

  const [recruitment, setRecruitment] = useState({});
  const [candidate, setCandidate] = useState({});
  const [redirectFlag, setRedirectFlag] = useState(false);

  useEffect(() => {
    if (!rid || !uid || !email) return;

    executeAction(
      getCandidateOfRecruitment({
        uid,
        rid,
        cid: email,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setCandidate(doc);
      })
      .catch((e) => {
        console.error(e);
        setRedirectFlag(false);
      });
  }, [rid, uid, email]);

  useEffect(() => {
    if (Object.keys(candidate)?.length === 0) return;

    executeAction(getRecruitment({ ouid: uid, aid: rid }))
      .unwrap()
      .then(({ doc }) => {
        setRecruitment(doc);
        setRedirectFlag(true);
      })
      .catch((e) => {
        console.error(e);
        setRedirectFlag(false);
      });
  }, [candidate]);

  useEffect(() => {
    if (!redirectFlag) return;
    const statusFlag = candidate?.progressSubmit[step]?.state;

    if (statusFlag === 'submit') {
      return navigate('/compensation/completed');
    }

    return navigate('/compensation/form', {
      state: { uid, rid, email, step, candidate, recruitment },
      replace: true,
    });
  }, [redirectFlag]);

  return <main />;
};

export default CompensationRouter;
