import React, { useEffect, useState } from 'react';
import { convertHtmlForDisplay } from 'utils/string';
import UpdateScoreModal from 'modals/assessments/UpdateScoreModal';
import VideoResultDetail from './VideoResultDetail';
import PrintVideoResultDetail from './PrintVideoResultDetail';
import ResultScoreBox from './ResultScoreBox';

const VideoResult = ({
  visibilityDropdown,
  question,
  assessment,
  candidate,
  idx,
  test,
  setRefreshing,
  getAnswer,
  type,
}) => {
  const [result, setResult] = useState({});
  const [isOpenScoreModal, setIsOpenScoreModal] = useState(false);

  useEffect(() => {
    if (Object.keys(candidate)?.length === 0) return;
    const { results } = candidate;

    if (type === 'history') {
      return setResult(results[test?.uuid] || results[question?.id]);
    }

    if (type === 'custom') {
      setResult(results[test?.uuid]);
    } else {
      setResult(results[question?.id] || results[test?.uuid]);
    }
  }, [candidate, question]);

  const onUpdateScoreModal = () => {
    setIsOpenScoreModal(true);
  };

  const scoreDisplay = (score) => {
    if (score || score === 0) {
      return Math.trunc(result?.score * 100);
    }
    if (score === undefined || score === null) {
      return Math.trunc(result?.preset_score * 100);
    }
  };

  return (
    <aside
      className={`toggle-body-content page-break ${
        visibilityDropdown ? 'slide-in' : 'slide-out'
      }`}
    >
      <div className="toggle-body-title-box">
        {type === 'print' ? (
          <>
            <div className="print-toggle-body-question">
              <span className="question-number">Q{idx + 1}.</span>
              <pre
                className="question"
                dangerouslySetInnerHTML={{
                  __html: convertHtmlForDisplay(question?.question),
                }}
              />
            </div>
            <div className="print-toggle-body-score">
              <ResultScoreBox
                score={scoreDisplay(result?.score) || 0}
                onClick={onUpdateScoreModal}
                isHideButton
              />
            </div>
          </>
        ) : (
          <>
            <span className="question-number">Q{idx + 1}.</span>
            <pre
              className="question"
              dangerouslySetInnerHTML={{
                __html: convertHtmlForDisplay(question?.question),
              }}
            />
          </>
        )}
      </div>
      {type === 'print' ? (
        <PrintVideoResultDetail result={result} link={getAnswer(question)} />
      ) : (
        <VideoResultDetail
          result={result}
          onClick={onUpdateScoreModal}
          scoreDisplay={scoreDisplay}
          type={type}
        />
      )}

      {isOpenScoreModal && (
        <UpdateScoreModal
          open={isOpenScoreModal}
          close={setIsOpenScoreModal}
          setRefreshing={setRefreshing}
          category_skill={test?.category_skill}
          assessment={assessment}
          candidate={candidate}
          question={question}
          type="video"
        />
      )}
    </aside>
  );
};

export default VideoResult;
