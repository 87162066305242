import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialInput from 'components/input/MaterialInput';
import { useAuth } from 'utils/auth';
import { CircularProgress } from '@mui/material';
import { convertFileToBlob } from 'utils/converter';
import { toast } from 'utils/notify';

const Company = () => {
  const { t } = useTranslation();
  const { user, userInfo, updateCompanyInfo } = useAuth();

  const [isLoading, setIsLoading] = React.useState(false);
  const [canDone, setCanDone] = React.useState(false);
  const [corporation, setCorporation] = React.useState('');
  const [isLogoURLLoading, setIsLogoURLLoading] = React.useState(false);
  const [logoURL, setLogoURL] = React.useState('');
  const [file, setFile] = React.useState(null);

  const initLogoURL = React.useRef(logoURL);
  const initCorporation = React.useRef(corporation);
  const inputFileRef = React.useRef(null);

  React.useEffect(() => {
    if (
      initLogoURL.current !== logoURL ||
      initCorporation.current !== corporation
    ) {
      return setCanDone(true);
    }

    return setCanDone(false);
  }, [logoURL, corporation, initLogoURL.current, initCorporation.current]);

  React.useEffect(() => {
    setCorporation(userInfo?.corporation || '');
    initCorporation.current = userInfo?.corporation;
    setLogoURL(userInfo?.logoURL || '');
    initLogoURL.current = userInfo?.logoURL;
  }, [userInfo]);

  const onImageChangeHandler = (event) => {
    const _file = event.target.files?.[0];
    setFile(_file);
    setIsLogoURLLoading(true);
    convertFileToBlob(_file).then((blob) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        setLogoURL(reader.result);
        setIsLogoURLLoading(false);
      };
      reader.onerror = (e) => {
        setFile(null);
        setIsLogoURLLoading(false);
      };
      reader.readAsDataURL(blob);
    });
  };

  const onSubmitHandler = () => {
    setIsLoading(true);
    updateCompanyInfo({
      corporation,
      logo: file,
    })
      .then(() => {
        setIsLoading(false);
        toast(t('toast.desc.save-complete'));
        initCorporation.current = corporation;
        initLogoURL.current = logoURL;
      })
      .catch((e) => {
        console.dir(e);
        setIsLoading(false);
      });
  };

  return (
    <section className="pages-protected-profile-company">
      <div className="separater">
        <section className="separater-left">
          <div
            className="company-logo"
            onClick={() => {
              inputFileRef.current?.click?.();
            }}
          >
            {logoURL ? (
              <img src={logoURL} alt="company logo" />
            ) : (
              <div className="company-logo-empty">
                <div className="company-logo-empty-thumbnail-icon" />
                <span>{t('btn.upload-company-logo')}</span>
              </div>
            )}
            <input
              ref={inputFileRef}
              type="file"
              accept="image/*"
              onChange={onImageChangeHandler}
              hidden
            />
          </div>
        </section>
        <section className="separater-right">
          <MaterialInput
            placeholder={t('text-field.label.company-name')}
            value={corporation}
            onChange={(event) => setCorporation(event.target.value)}
          />
        </section>
      </div>
      <button
        className={`submit common-button fsbtn16 primary ${
          !isLoading && canDone && 'active'
        } ${isLoading && 'pending'}`}
        onClick={!isLoading && canDone ? onSubmitHandler : null}
      >
        {t('btn.save-change')}
        {isLoading && <CircularProgress color="grey" size={25} />}
      </button>
    </section>
  );
};

export default Company;
