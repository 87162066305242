import React from 'react';
import TinyEditor from 'components/editor/TinyEditor';
import MaterialInput from 'components/input/MaterialInput';
import MaterialSelect from 'components/select/MaterialSelect';
import IntroFileInput from 'components/input/IntroFileInput';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { useModal } from 'utils/modal';
import { Radio } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { v4 as uuidV4 } from 'uuid';

const MultipleChoiceTest = ({ idx, subjectiveTests, setSubjectiveTests }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [limitTime, setLimitTime] = React.useState('');
  const [answerList, setAnswerList] = React.useState(['', '', '', '']);
  const [answerCorrect, setAnswerCorrect] = React.useState([]);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [fileName, setFileName] = React.useState('');
  const [videoUrl, setVideoUrl] = React.useState('');

  const editorRef = React.useRef(null);
  const guideEditorRef = React.useRef(null);
  const ulRef = React.useRef(null);

  const { dismissModal } = useModal();

  React.useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
      if (idx >= 0 && editorRef.current && guideEditorRef.current) {
        const subjectiveTest = subjectiveTests[idx];
        if(!subjectiveTest) {
          return;
        }

        setLimitTime(subjectiveTest?.limit);
        editorRef.current.setContent(subjectiveTest?.question);
        guideEditorRef.current.setContent(subjectiveTest?.answer_guide);
        setAnswerList(subjectiveTest?.answers?.list);
        setAnswerCorrect(subjectiveTest?.answers?.correct);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [idx]);

  React.useEffect(() => {
    if (Boolean(limitTime) && (answerCorrect || []).length > 0) {
      if (!(answerList || []).filter((answer) => !answer).length) {
        return setCanSubmit(true);
      }
    }
    setCanSubmit(false);
  }, [limitTime, answerCorrect, answerList]);

  const onDismissHandler = () => {
    dismissModal();
  };

  React.useEffect(() => {}, [answerList]);

  const onSubmitHandler = () => {
    setIsLoading(true);
    if (idx >= 0) {
      setSubjectiveTests([
        ...subjectiveTests.slice(0, idx),
        {
          uuid: uuidV4(),
          section: 'multiple-choice',
          limit: limitTime,
          question: editorRef.current?.getContent(),
          answers: {
            list: answerList,
            correct: answerCorrect,
          },
          videoUrl,
          answer_guide: guideEditorRef.current?.getContent(),
        },
        ...subjectiveTests.slice(idx + 1, subjectiveTests?.length),
      ]);
    } else {
      setSubjectiveTests([
        ...subjectiveTests,
        {
          uuid: uuidV4(),
          section: 'multiple-choice',
          limit: limitTime,
          question: editorRef.current?.getContent(),
          answers: {
            list: answerList,
            correct: answerCorrect,
          },
          videoUrl,
          answer_guide: guideEditorRef.current?.getContent(),
        },
      ]);
    }

    setTimeout(() => {
      setIsLoading(false);
      dismissModal();
    }, 1000);
  };

  return (
    <article className="modals-assessments-multiple-choice-test">
      <header className="header">
        <h3 className="header-title fs20">
          {t('title.header.assessments-test')}
        </h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <section className="form">
        <h4 className="form-title fs28">
          {t('title.multiple-choice-question')}
        </h4>
        <MaterialSelect
          className="form-limit-time"
          title={t('text-field.label.time-answer-question')}
          value={limitTime}
          onChange={(item) => setLimitTime(item?.key)}
          items={[
            {
              key: '30secs',
              i18nKey: 'text-field.input.30secs',
            },
            {
              key: '45secs',
              i18nKey: 'text-field.input.45secs',
            },
            {
              key: '1min',
              i18nKey: 'text-field.input.1min',
            },
            {
              key: '2mins',
              i18nKey: 'text-field.input.2mins',
            },
            {
              key: '3mins',
              i18nKey: 'text-field.input.3mins',
            },
            {
              key: '4mins',
              i18nKey: 'text-field.input.4mins',
            },
            {
              key: '5mins',
              i18nKey: 'text-field.input.5mins',
            },
            {
              key: '6mins',
              i18nKey: 'text-field.input.6mins',
            },
            {
              key: '7mins',
              i18nKey: 'text-field.input.7mins',
            },
            {
              key: '8mins',
              i18nKey: 'text-field.input.8mins',
            },
            {
              key: '9mins',
              i18nKey: 'text-field.input.9mins',
            },
            {
              key: '10mins',
              i18nKey: 'text-field.input.10mins',
            },
          ]}
        />
        <TinyEditor ref={editorRef} className="form-editor" />
        <input
          id="my-file"
          type="file"
          name="my-file"
          style={{ display: 'none' }}
        />

        <section className="form-answers">
          <h5 className="form-answers-title fs18">
            {t('text-field.title.question-example')}
          </h5>
          <ul ref={ulRef} className="form-answers-list">
            {(answerList || []).map((answer, idx) => (
              <li key={idx} className="form-answers-list-item">
                <div className="form-answers-list-item-container">
                  <Radio
                    checked={answerCorrect?.[0] === idx}
                    onChange={(event) =>
                      setAnswerCorrect([Number(event.target.value)])
                    }
                    value={idx}
                    name="radio-button"
                  />

                  <MaterialInput
                    className="form-answers-list-item-container-input"
                    placeholder={t(
                      'placeholder.input.assessment-multiple-choice'
                    )}
                    value={answerList?.[idx]}
                    onChange={(event) => {
                      setAnswerList([
                        ...answerList.slice(0, idx),
                        event.target.value,
                        ...answerList.slice(idx + 1, answerList.length),
                      ]);
                    }}
                    multiple
                  />
                  <div
                    className={`form-answers-list-item-container-close ${
                      answerList.length <= 1 && 'disabled'
                    }`}
                    onClick={
                      answerList.length > 1
                        ? () =>
                            setAnswerList([
                              ...answerList.slice(0, idx),
                              ...answerList.slice(idx + 1, answerList.length),
                            ])
                        : null
                    }
                  />
                </div>
              </li>
            ))}
          </ul>
          <button
            className={`form-answers-add common-button fsbtn16 ${
              answerList.length < 8 && 'active'
            }`}
            onClick={
              answerList.length < 8
                ? () => setAnswerList([...answerList, ''])
                : null
            }
          >
            {t('btn.add-another-question')}
          </button>
        </section>

        <article className="form-guide-contents">
          <h4 className="form-guide-title fs18">{t('title.scoring-guide')}</h4>
          <TinyEditor ref={guideEditorRef} className="form-guide-editor" />
        </article>

        <article className="modal-video-box">
          <div className="sub">
            <div className="sub-title fs18">
              <h6>{t('title.add-question-video')}</h6>
            </div>
            <IntroFileInput
              videoUrl={videoUrl}
              setVideoUrl={setVideoUrl}
              fileValue={fileName}
              setFileValue={setFileName}
            />
          </div>
        </article>

        <button
          onClick={!isLoading && canSubmit ? onSubmitHandler : null}
          className={`form-submit common-button fsbtn16 primary ${
            !isLoading && canSubmit && 'active'
          } ${isLoading && 'pending'}`}
        >
          {t('btn.save')}
          {isLoading && <CircularProgress color="grey" size={25} />}
        </button>
      </section>
    </article>
  );
};

export default MultipleChoiceTest;
