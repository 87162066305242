import React from 'react';
import MainLogo from 'components/logo/MainLogo';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { toast } from 'utils/notify';
import { executeAction } from 'utils/redux';
import { updateUserInfoAgree } from 'store/slices/authSlice';
import { convertHtmlForDisplay } from 'utils/string';

const Agree = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [canGoNext, setCanGoNext] = React.useState(false);
  const [checked, setChecked] = React.useState({
    term: false,
    privacy: false,
  });

  React.useEffect(() => {
    if (checked.term && checked.privacy) {
      setCanGoNext(true);
    } else {
      setCanGoNext(false);
    }
  }, [checked]);

  const handleChange = (event) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const onSubmitHandler = () => {
    if (!checked.term) {
      toast(t('toast.desc.accept-term'));
      return;
    }
    if (!checked.privacy) {
      toast(t('toast.desc.accept-term'));
      return;
    }

    if (canGoNext) {
      executeAction(
        updateUserInfoAgree({
          termAgree: checked?.term,
          privacyAgree: checked?.privacy,
        })
      )
        .unwrap()
        .then(() => {
          navigate('/customer/assessments', { replace: true });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  return (
    <main className="pages-auth-agree">
      <section className="header-logo">
        <MainLogo className="logo" />
      </section>

      <section className="check-form">
        <article className="check-form-title">
          <h2
            className="fs24"
            dangerouslySetInnerHTML={{
              __html: convertHtmlForDisplay(t('title.accept-term-at-login')),
            }}
          />
        </article>
        <article className="check-form-wrap term">
          <FormControl className="control">
            <FormControlLabel
              className="check-label"
              value="term"
              name="term"
              control={<Checkbox />}
              onChange={handleChange}
              label={
                <Trans
                  t={t}
                  i18nKey="check-box.label.accept-term"
                  components={{
                    href_accept_term: (
                      <span
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          window.open(
                            `https://munchskill.com/${
                              t('lang') === 'ko' ? 'ko/' : ''
                            }terms-of-use/`,
                            '_blank'
                          );
                        }}
                      />
                    ),
                  }}
                />
              }
            />
            <span className="check-required-tag">({t('desc.required')})</span>
          </FormControl>
        </article>
        <article className="check-form-wrap privacy">
          <FormControl className="control">
            <FormControlLabel
              className="check-label"
              value="privacy"
              name="privacy"
              control={<Checkbox />}
              onChange={handleChange}
              label={
                <Trans
                  t={t}
                  i18nKey="check-box.label.privacy-policy"
                  components={{
                    href_privacy_policy: (
                      <span
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          window.open(
                            `https://munchskill.com/${
                              t('lang') === 'ko' ? 'ko/' : ''
                            }privacy-policy/`,
                            '_blank'
                          );
                        }}
                      />
                    ),
                  }}
                />
              }
            />
            <span className="check-required-tag">({t('desc.required')})</span>
          </FormControl>
        </article>
        <button
          className={`form-submit fsbtn16 common-button primary submit fs16  ${
            canGoNext && 'active'
          }`}
          onClick={onSubmitHandler}
        >
          {t('btn.ok')}
        </button>
      </section>
    </main>
  );
};

export default Agree;
