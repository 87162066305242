import { Button } from 'components/common/Button';
import { ReactComponent as PlusIcon } from 'assets/images/icon/plus.svg';
import React from 'react';

const ApplyAddButton = ({ onClick, disabled }) => {
  return (
    <div className="plus-button-wrapper">
      <Button
        theme="borderGrey"
        frontIcon={<PlusIcon />}
        size="40"
        onClick={onClick}
        isDisabled={disabled}
      />
    </div>
  );
};

export default ApplyAddButton;
