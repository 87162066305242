import React from 'react';
import MultipleChoiceTableHead from './MultipleChoiceTableHead';
import MultipleChoiceTableBody from './MultipleChoiceTableBody';

const MultipleChoiceResult = ({
  visibilityDropdown,
  objectiveTests,
  objectiveCats,
  objectiveScores,
  candidate,
  assessment,
  type,
}) => {
  const HEADS = ['Test', 'Score'];

  return (
    <aside
      className={`toggle-body-content multiple-choice ${
        visibilityDropdown ? 'slide-in' : 'slide-out'
      } ${type === 'print' ? 'print' : ''}`}
    >
      <div className="toggle-body-answer-box">
        <aside className="answer-box">
          <div className="table-border">
            <table className="recruitment-table multiple-table">
              <caption>Multiple Choice list</caption>
              <colgroup>
                <col width="30%" />
                <col width="*" />
              </colgroup>
              <MultipleChoiceTableHead heads={HEADS} />
              <MultipleChoiceTableBody
                objectiveTests={objectiveTests}
                objectiveCats={objectiveCats}
                objectiveScores={objectiveScores}
                assessment={candidate}
                candidate={assessment}
              />
            </table>
          </div>
        </aside>
      </div>
    </aside>
  );
};

export default MultipleChoiceResult;
