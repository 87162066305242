import RecruitmentTableHead from 'components/recruitment/detail/RecruitmentTableHead';
import RecruitmentTableBody from 'components/recruitment/detail/interview/RecruitmentTableBody';
import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';

const HEADS = [
  'column.candidate-name',
  'field.preferred-channel',
  'title.interviewer',
  'column.interview-date',
  'column.total-score',
  'column.opinion',
  'column.lead-time',
  '-'
];

const Interview = () => {
  const location = useLocation();
  const recruitmentProps = useOutletContext();
  const { recruitment, candidates, userInfo } = recruitmentProps;

  const [interviewCandidates, setInterviewCandidates] = useState([]);
  const [interviewType, setInterviewType] = useState('');

  useEffect(() => {
    if (location.state) {
      const { outlook } = location.state;
      if (outlook) {
        sessionStorage.removeItem('rid');
        sessionStorage.removeItem('processName');
        sessionStorage.removeItem('current_candidate');
      }
    }
  }, []);

  useEffect(() => {
    const path = location?.pathname;
    const splitPath = path.split('/').filter((item) => item !== '');

    setInterviewType(splitPath[3]);

    let filterd;
    if (splitPath[3] === 'first-interview') {
      filterd = candidates?.filter((item) => item?.recruitProgress === 3);
    } else {
      filterd = candidates?.filter((item) => item?.recruitProgress === 4);
    }

    setInterviewCandidates(filterd);
  }, [candidates, location?.pathname]);

  return (
    <section className="dashboard-interview-contents table-border">
      <table className="recruitment-table">
        <caption>candidates in-person Interview list</caption>
        <RecruitmentTableHead heads={HEADS} />
        <RecruitmentTableBody
          interviewType={interviewType}
          candidates={interviewCandidates}
          recruitment={recruitment}
        />
      </table>
    </section>
  );
};

export default Interview;
