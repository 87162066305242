import React, { useState } from 'react';
import { convertHtmlForDisplay } from 'utils/string';
import { ReactComponent as UploadFileIcon } from 'assets/images/icon/cloud_upload.svg';
import UpdateScoreModal from 'modals/assessments/UpdateScoreModal';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import ResultScoreBox from './ResultScoreBox';

const FileUploadResult = ({
  visibilityDropdown,
  question,
  candidate,
  assessment,
  setRefreshing,
  test,
  idx,
  type,
}) => {
  const { t } = useTranslation();

  const [isOpenScoreModal, setIsOpenScoreModal] = useState(false);

  const onFileDownloadHandler = () => {
    window.open(candidate?.results[test?.uuid]?.file, '_blank');
  };

  const onUpdateScoreModal = () => {
    setIsOpenScoreModal(true);
  };

  const scoreDisplay = (score) => {
    if (score || score === 0) {
      return Math.trunc(candidate?.results[question?.uuid]?.score * 100);
    }
    if (score === undefined || score === null) {
      return Math.trunc(candidate?.results[question?.uuid]?.preset_score * 100);
    }
  };

  return (
    <aside
      className={`toggle-body-content custom-file-upload page-break ${
        visibilityDropdown ? 'slide-in' : 'slide-out'
      }`}
    >
      <div className="toggle-body-title-box">
        <div className="print-toggle-body-question">
          <span className="question-number">Q{idx + 1}.</span>
          <pre
            className="question"
            dangerouslySetInnerHTML={{
              __html: convertHtmlForDisplay(question?.question),
            }}
          />
        </div>
      </div>
      <div className="toggle-body-answer-box">
        <aside className="answer-box">
          <div className="result-file-upload-box">
            <span>{t('title.answer')}</span>
            <div className="file-download-button">
              <Button
                theme="grey"
                size={46}
                label={t('btn.file-download')}
                onClick={onFileDownloadHandler}
                radius={100}
                frontIcon={<UploadFileIcon />}
              />
            </div>
          </div>

          <div>
            <ResultScoreBox
              score={
                scoreDisplay(candidate?.results[question?.uuid]?.score) || 0
              }
              onClick={onUpdateScoreModal}
              isHideButton={type !== 'current'}
            />
          </div>
        </aside>
      </div>

      {isOpenScoreModal && (
        <UpdateScoreModal
          open={isOpenScoreModal}
          close={setIsOpenScoreModal}
          setRefreshing={setRefreshing}
          category_skill={test?.category_skill}
          assessment={assessment}
          candidate={candidate}
          question={question}
          type="essay"
        />
      )}
    </aside>
  );
};

export default FileUploadResult;
