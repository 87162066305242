import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { executeAction } from 'utils/redux';

const RecruitmentListItem = ({ icon, userInfo, recruit }) => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const { countStageLoading } = useSelector((state) => state.recruitments);

  const moveToDetailPage = (id) => {
    if (countStageLoading) return;

    if (userInfo?.memberType === 15) {
      executeAction(getRecruitment({ aid: id, type: 'interviewer' }))
        .unwrap()
        .then(({ doc }) => {
          navigate(`/customer/recruitments/${id}/${doc?.stage}`);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      navigate(`/customer/recruitments/${id}`);
    }
  };

  return (
    <li
      className={`list-item ${rid === recruit?.rid && 'active'}`}
      onClick={() => moveToDetailPage(recruit?.rid)}
    >
      {icon && icon}
      <h6 className="fs14">{recruit && recruit?.recruitmentName}</h6>
    </li>
  );
};

export default RecruitmentListItem;
