import React, { useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/images/icon/ico-search-normal.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icon/download-icon-blue-18x18.svg';
import { useTranslation } from 'react-i18next';
import SelectAndSearchInput from 'components/input/SelectAndSearchInput';
import CandidateTableHead from 'components/recruitment/candidates/CandidateTableHead';
import CandidateTableBody from 'components/recruitment/candidates/CandidateTableBody';
import { TablePagination } from '@mui/material';
import { executeAction } from 'utils/redux';
import { utils, writeFileXLSX } from 'xlsx';
import { getCandidatesList, postSyncWithRDB } from 'store/slices/recruitments/recruitmentsSlice';
import { useSelector } from 'react-redux';
import { Button } from 'components/common/Button';
import { useStatus } from 'utils/status';
import * as Sentry from '@sentry/react';
import {
  convertCareer,
  convertChannel,
  convertFinalEducation,
  convertGender,
  convertJobCenter,
  convertMajor,
  convertPositionInfo,
  convertSchool,
  convertSearchFirm,
  convertSNS,
} from 'utils/convertKey';
import { toast } from 'utils/notify';
import { useModal } from 'utils/modal';

const SEARCH_OPTIONS = [
  {
    key: 'candidate',
    i18nKey: 'column.candidate-name',
  },
  {
    key: 'phone',
    i18nKey: 'text-field.label.phone-number',
  },
  {
    key: 'email',
    i18nKey: 'text-field.label.email',
  },
];

const HEADS = [
  'column.candidate-name',
  'column.gender',
  'text-field.label.phone-number',
  'text-field.label.email',
  'title.application-history',
  'menu.year-of-birth',
];

const CandidateList = () => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();

  const { setIsEntireLoading } = useStatus();
  const { getGucciCandidateListSuccess, getGucciCandidateListLoading } =
    useSelector((state) => state.recruitments);

  const [isExcelLoading, setIsExcelLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [searchEmail, setSearchEmail] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [searchPhone, setSearchPhone] = useState(null);
  const [excelList, setExcelList] = useState([]);
  const [page, setPage] = useState(
    getGucciCandidateListSuccess?.paging.pages - 1 || 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    getGucciCandidateListSuccess?.paging.per_page || 10
  );

  useEffect(() => {
    executeAction(
      getCandidatesList({
        is_excel: false,
        pages: page + 1,
        per_page: rowsPerPage,
        name: searchName,
        email: searchEmail,
        phone: searchPhone,
      })
    );
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (!selectValue) return;

    if (selectValue.key === 'candidate') {
      setSearchName(searchValue);
      setSearchPhone(null);
      setSearchEmail(null);
    } else if (selectValue.key === 'phone') {
      setSearchPhone(searchValue);
      setSearchName(null);
      setSearchEmail(null);
    } else {
      setSearchEmail(searchValue);
      setSearchName(null);
      setSearchPhone(null);
    }
  }, [selectValue, searchValue]);

  useEffect(() => {
    if (!excelList.length) return;

    const channelFunction = (channel, path) => {
      if (channel === 'job') {
        return convertJobCenter(path);
      }
      if (channel === 'sns') {
        return convertSNS(path);
      }
      if (channel === 'school') {
        return convertSchool(path);
      }
      if (channel === 'friend') {
        return path;
      }
      if (channel === 'searchFirm') {
        return convertSearchFirm(path);
      }
      if (channel === 'others') {
        return path;
      }
    };

    const dataToCSV = excelList?.map((candidate) => ({
      채널: t(convertChannel(candidate.channel)) || '',
      경로: t(channelFunction(candidate.channel, candidate.channelPath)) || '',
      추천인: candidate.recommender || '',
      추천인매장: candidate.recommenderStore || '',
      지원일: candidate.applyDate || '',
      지원분야: t(convertPositionInfo(candidate.applyField)) || '',
      지원포지션: candidate.applyPosition || '',
      생년월일: candidate.birth || '',
      '이름(국문)': `${candidate.lastName} ${candidate.firstName}` || '',
      '이름(영문)': `${candidate.engFirstName} ${candidate.engLastName}` || '',
      연락처: candidate.phone || '',
      이메일: candidate.email || '',
      성별: t(convertGender(candidate.gender)) || '',
      경력: t(convertCareer(candidate.career)) || '',
      '총 경력': t(convertCareer(candidate.totalCareer)) || '',
      '이전 직장': candidate.finalCompany || '',
      학력: t(convertFinalEducation(candidate.finalEducation)) || '',
      전공: t(convertMajor(candidate.major)) || '',
      '졸업 여부': t(convertFinalEducation(candidate.isGraduated)) || '',
      '서류 평가자': candidate.docStepReviewer || '',
      '서류 결과': candidate.docStepResult || '',
      'AI 인터뷰 응시일': candidate.aiInterviewStepDate || '',
      'AI 인터뷰 결과': candidate.aiInterviewStepResult || '',
      'AI 인터뷰 평가자': candidate.aiInterviewStepReviewer || '',
      '대면 면접 날짜': candidate.interviewStepDate || '',
      '대면 면접 결과': candidate.interviewStepResult || '',
      '대면 면접 평가자': candidate.interviewStepReviewer || '',
      '오퍼레터 제안일': candidate.offerDate || '',
      '오퍼레터 결과': candidate.offerResult || '',
      '오퍼레터 수락일': candidate.offerAcceptDate || '',
      '입사 예정일': candidate.dateOfHire || '',
      '입사 매장': candidate.joinStore || '',
      '입사 직무': candidate.position || '',
    }));

    const worksheet = utils.json_to_sheet(dataToCSV);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFileXLSX(workbook, 'GUCCI_Candidate_list.xlsx');

    setIsEntireLoading(false);
  }, [excelList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 응시자 검색 fetch 함수
  const onClickSearchCandidate = () => {
    setPage(0);
    setRowsPerPage(10);

    executeAction(
      getCandidatesList({
        is_excel: false,
        pages: 1,
        per_page: 10,
        name: searchName,
        email: searchEmail,
        phone: searchPhone,
      })
    );
  };

  // 엑셀 sync 및 download 함수
  const onClickExcelDownload = async () => {
    setIsExcelLoading(true);
    let excelData = [];

    try {
      await executeAction(postSyncWithRDB());

      const parallelCount = 1;
      const rowsPerPage = 150;
      const pageCount = Math.ceil(getGucciCandidateListSuccess?.totalCount / rowsPerPage);

      for (let page = 1; page <= pageCount; page += parallelCount) {
        const pagePromises = [];

        for (let i = 0; i < parallelCount; i+=1) {
          const currentPage = page + i;
          if (currentPage <= pageCount) {
            pagePromises.push(
              executeAction(getCandidatesList({
                is_excel: false,
                pages: currentPage,
                per_page: rowsPerPage,
              }))
            );
          }
        }

        const pageResults = await Promise.all(pagePromises);
        pageResults.forEach(result => {
          if (result) {
            excelData = excelData.concat(result.payload?.data.data);
          }
        });
      }

      setExcelList(excelData);

      // 다시 기존에 보고 있던 리스트 불러오기
      executeAction(
        getCandidatesList({
          is_excel: false,
          pages: page + 1,
          per_page: rowsPerPage,
          name: searchName,
          email: searchEmail,
          phone: searchPhone,
        })
      );
      toast(t('toast.success-excel-download'));
    } catch (error) {
      Sentry.captureException(error);
      toast(t('toast.fail-excel-download'));
    } finally {
      setIsExcelLoading(false);
    }
  };

  React.useEffect(() => {
    if (isExcelLoading) {
      displayModal(
        'recruitments/ExcelDownloadLoading',
        {},
        {
          frame: 'part',
          hasCloseButton: false,
        }
      );
    } else {
      dismissModal();
    }
  }, [isExcelLoading]);

  return (
    <main id="candidates">
      <section className="recruitment-candidate-list-container">
        <article className="candidate-list-title-box">
          <h2 className="main-title">{t('title.applicants-within-3years')}</h2>
        </article>
        <article className="candidate-list-search-box">
          <Button
            theme="borderBlue"
            size={26}
            label={t('btn.excel')}
            fontSize="fs14"
            frontIcon={<DownloadIcon />}
            flexGap={4}
            isDisabled={getGucciCandidateListLoading}
            onClick={onClickExcelDownload}
          />
          <SelectAndSearchInput
            icon={<SearchIcon />}
            value={searchValue}
            setValue={setSearchValue}
            placeHolder={t('text-field.label.search')}
            menus={SEARCH_OPTIONS}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            onClick={onClickSearchCandidate}
          />
        </article>
        <article className="candidate-list-box">
          <div className="table-border candidate-list-contents">
            <table className="recruitment-table">
              <caption>Gucci candidate list</caption>
              <CandidateTableHead heads={HEADS} />
              <CandidateTableBody
                users={getGucciCandidateListSuccess}
                loading={getGucciCandidateListLoading}
              />
            </table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={getGucciCandidateListSuccess?.totalCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </article>
      </section>
    </main>
  );
};

export default CandidateList;
