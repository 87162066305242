import RecruitmentTableHead from 'components/recruitment/detail/RecruitmentTableHead';
import RecruitmentTableBody from 'components/recruitment/detail/reviewing/RecruitmentTableBody';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

const HEADS = [
  'column.candidate-name',
  'column.mobile-number',
  'column.age',
  'column.gender',
  'field.preferred-channel',
  'field.preferred-location',
  'column.career',
  'column.within-3-years',
  'column.date',
  '-',
];

const Reviewing = () => {
  const recruitmentProps = useOutletContext();
  const { recruitment, candidates, userInfo } = recruitmentProps;
  const [reviewingCandidates, setReviewingCandidates] = useState([]);

  useEffect(() => {
    const filterd = candidates?.filter((item) => item?.recruitProgress === 1);
    setReviewingCandidates(filterd);
  }, [candidates]);

  return (
    <section className="dashboard-reviewing-contents table-border">
      <table className="recruitment-table">
        <caption>candidates reviewing list</caption>
        <RecruitmentTableHead heads={HEADS} />
        <RecruitmentTableBody
          candidates={reviewingCandidates}
          recruitment={recruitment}
        />
      </table>
    </section>
  );
};

export default Reviewing;
