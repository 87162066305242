export const sortCopy = (arr, callback) => arr.slice(0).sort(callback);

export const calculatePercentageAverage = (arr) => {
  const numbers = arr.filter((item) => typeof item === 'number');
  if (numbers.length === 0) {
    return 0;
  }

  const sum = numbers.reduce((total, num) => total + num, 0);
  const average = sum / numbers.length;

  return Math.floor((average / 9) * 100);
};

export const removeNull = (arr) =>
arr.filter(it => it !== null && it !== undefined);