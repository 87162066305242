import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  _createAuthToken,
  _createUserWithEmailAndPassword,
  _signInWithEmailAndPassword,
  _setPassword,
  _getInfoFromAuthToken,
  _signOut,
  _uploadCorporationLogo,
  _createUserInfo,
  _getUserInfo,
  _isAccountWithEmail,
  _resetPassword,
  _getAuthTokenFromEmail,
  _isAvailableAuthToken,
  _createAllowanceReceipt,
  _getAllowanceReceipt,
  _deleteAllowanceReceipt,
  _refreshAuthToken,
  _updatePassword,
  _updateUserInfo,
  _updateUserLimit,
  _updateCompanyInfoWithoutLogo,
  _uploadCompanyLogo,
  _updateCompanyInfoWithLogo,
} from 'utils/firebase/auth';
import { serializeError } from 'serialize-error';
import axios from 'axios';
import {
  validateEmailDomain,
  encryptString,
  validatePassword,
} from 'utils/auth';

const initialState = {
  user: {},
  userInfo: {},
};

export const sendValidateMail = createAsyncThunk(
  'auth/sendValidateMail',
  async (payload, { rejectWithValue }) => {
    let { authToken, email, type, allowanceReceipt, account } = payload;
    try {
      if (type === 'set' || type === 'expired') {
        if (type === 'expired') {
          email = (await _getInfoFromAuthToken({ authToken }))?.email;
          allowanceReceipt = (await _getAllowanceReceipt({ authToken }))
            ?.allowanceReceipt;
          await _deleteAllowanceReceipt({ authToken });
          type = 'set';
        }

        const isAccount = await _isAccountWithEmail({ email });
        if (isAccount) {
          throw new Error('already_joined');
        }
        await validateEmailDomain(email);

        authToken = await _createAuthToken({ email });
        await _createAllowanceReceipt({ authToken, allowanceReceipt });
      } else if (type === 'reset') {
        authToken = await _getAuthTokenFromEmail({ email });
        await _refreshAuthToken({ authToken });
        const isAccount = await _isAccountWithEmail({ email });
        if (!isAccount) {
          throw new Error('no_user');
        }
      } else throw new Error('no_type');

      const result = await axios.post(
        `${process.env.REACT_APP_POST_BOX}/auth/send/email`,
        {
          email,
          authToken,
          param: type,
          account,
        },
        {
          headers: {
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
          },
        }
      );

      return {
        authToken: result?.data,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const setPassword = createAsyncThunk(
  'auth/setPassword',
  async (payload, { rejectWithValue }) => {
    const { authToken, password: _password } = payload;
    try {
      const password = encryptString(_password);
      await _isAvailableAuthToken({ authToken });
      const { email } = await _setPassword({ authToken, password });

      return {
        email,
        password,
        authToken,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (payload, { rejectWithValue }) => {
    const { authToken, password: _password } = payload;
    try {
      const password = encryptString(_password);
      await _isAvailableAuthToken({ authToken });
      const { email } = await _resetPassword({ authToken, password });

      return {
        email,
        password,
        authToken,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (payload = {}, { rejectWithValue, getState }) => {
    const {
      oldPassword: _oldPassword,
      newPassword: _newPassword,
      newPasswordConfirm: _newPasswordConfirm,
    } = payload;
    try {
      const oldPassword = encryptString(_oldPassword);
      const newPassword = encryptString(_newPassword);
      const uid = getState().auth.user?.uid;
      const { authToken } = await _getUserInfo({ uid });
      const { email, password } = await _getInfoFromAuthToken({
        authToken,
      });

      if (oldPassword !== password) {
        throw new Error('wrong_password');
      }

      const resultOfValidatePassword = validatePassword(_newPassword);
      if (!resultOfValidatePassword?.success) {
        throw new Error(resultOfValidatePassword?.payload?.error?.message);
      }

      if (_newPassword !== _newPasswordConfirm) {
        throw new Error('no_accordance');
      }

      await _updatePassword({
        authToken,
        password: newPassword,
      });

      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  'auth/updateUserInfo',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { firstName, lastName } = payload;
    try {
      const uid = getState().auth.user?.uid;
      await _updateUserInfo({
        uid,
        data: {
          firstName,
          lastName,
        },
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateUserLimit = createAsyncThunk(
  'auth/updateUserLimit',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { candidateLimit, assessmentLimit } = payload;
    try {
      const uid = getState().auth.user?.uid;
      await _updateUserLimit({
        uid,
        data: {
          candidateLimit,
          assessmentLimit,
        },
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateCompanyInfo = createAsyncThunk(
  'auth/updateCompanyInfo',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { corporation, logo } = payload;
    try {
      const uid = getState().auth.user?.uid;

      if (logo) {
        const downloadURL = await _uploadCompanyLogo({
          uid,
          logo,
        });

        await _updateCompanyInfoWithLogo({
          uid,
          logoURL: downloadURL,
          corporation,
        });
      } else {
        await _updateCompanyInfoWithoutLogo({
          uid,
          corporation,
        });
      }
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const createUserWithEmailAndPassword = createAsyncThunk(
  'auth/createUserWithEmailAndPassword',
  async (payload, { rejectWithValue }) => {
    const { authToken } = payload;

    try {
      const { email, password } = await _getInfoFromAuthToken({
        authToken,
      });
      const result = await _createUserWithEmailAndPassword({
        email,
        password,
      });
      return {
        user: result?.user,
        email,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const uploadCorporationLogo = createAsyncThunk(
  'auth/uploadCorporationLogo',
  async (payload, { getState, rejectWithValue }) => {
    const { file } = payload;
    try {
      if (!file) throw new Error('no_file');
      const uid = getState().auth?.user?.uid;
      const downloadURL = _uploadCorporationLogo({ uid, file });
      return {
        downloadURL,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const signInWithEmailAndPassword = createAsyncThunk(
  'auth/signInWithEmailAndPassword',
  async (payload, { rejectWithValue }) => {
    const { email, password: _password } = payload;
    try {
      const password = encryptString(_password);
      const result = await _signInWithEmailAndPassword({
        email,
        password,
      });
      const uid = result?.user?.uid;
      const { authToken } = await _getUserInfo({ uid });
      return {
        user: result?.user,
        authToken,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const signInWithAuthToken = createAsyncThunk(
  'auth/signInWithAuthToken',
  async (payload, { rejectWithValue }) => {
    const { authToken } = payload;
    try {
      const { email, password } = await _getInfoFromAuthToken({
        authToken,
      });
      const { user } = await _signInWithEmailAndPassword({
        email,
        password,
      });
      return {
        user,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (payload, { rejectWithValue }) => {
    try {
      await _signOut();
      return true;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const createUserInfo = createAsyncThunk(
  'auth/createUserInfo',
  async (payload, { getState, rejectWithValue }) => {
    const { authToken } = payload;
    try {
      const uid = getState().auth.user?.uid;
      const { allowanceReceipt } = await _getAllowanceReceipt({
        authToken,
      });
      await _createUserInfo({
        ...payload,
        uid,
        allowanceReceipt,
      });
      await _deleteAllowanceReceipt({ authToken });
      const { email } = await _getInfoFromAuthToken({ authToken });
      await axios.post(
        `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/auth/intercom/contact/create`,
        {
          uid,
          email,
        }
      );
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const updateUserInfoAgree = createAsyncThunk(
  'auth/updateUserInfoAgree',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { termAgree, privacyAgree } = payload;
    try {
      const uid = getState().auth.user?.uid;
      await _updateUserInfo({
        uid,
        data: {
          termAgree,
          privacyAgree,
        },
      });
      return {
        success: true,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getUserInfo = createAsyncThunk(
  'auth/getUserInfo',
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const uid = getState().auth.user?.uid;
      const doc = await _getUserInfo({ uid });
      const { logoURL } = doc || {};
      return {
        doc,
        logoURL,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const isAvailableAuthToken = createAsyncThunk(
  'auth/isAvailableAuthToken',
  async (payload, { rejectWithValue }) => {
    const { authToken } = payload;
    try {
      return await _isAvailableAuthToken({ authToken });
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserWithEmailAndPassword.fulfilled, (state, action) => {
        state.user = action.payload?.user;
      })
      .addCase(createUserWithEmailAndPassword.rejected, (state) => {
        state.user = initialState.user;
      })
      .addCase(signInWithEmailAndPassword.fulfilled, (state, action) => {
        state.user = action.payload?.user;
      })
      .addCase(signInWithEmailAndPassword.rejected, (state) => {
        state.user = initialState.user;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.user = initialState.user;
      })
      .addCase(signInWithAuthToken.fulfilled, (state, action) => {
        state.user = action.payload?.user;
      })
      .addCase(signInWithAuthToken.rejected, (state) => {
        state.user = initialState.user;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload?.doc;
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.userInfo = initialState.userInfo;
      });
  },
});

export const {} = authSlice.actions;

export default authSlice.reducer;
