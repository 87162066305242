import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronBottomIcon } from 'assets/images/icon/chevron-bottom-dropdown.svg';
import { ReactComponent as DropdownIcon } from 'assets/images/icon/arrow-drop-down.svg';
import { useOnClickOutside } from 'utils/hooks';

const MaterialSelect = ({
  className,
  title,
  value,
  onChange,
  items,
  color,
  message,
  type,
}) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = React.useState(value);
  const [visibilityDropdown, setVisibilityDropdown] = React.useState(false);
  const [visibilityDropdownAnimation, setVisibilityDropdownAnimation] =
    React.useState(false);
  const [repeat, setRepeat] = React.useState(null);

  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const eventListener = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setVisibilityDropdown(false);
      }
    };

    window.document.addEventListener('click', eventListener);

    return () => {
      window.document.removeEventListener('click', eventListener);
    };
  }, []);

  React.useEffect(() => {
    const item = (items || []).find((it) => it.key === value);
    if (item) setSelectedItem(item);
  }, [value, items]);

  React.useEffect(() => {
    onChange && onChange(selectedItem);
  }, [selectedItem]);

  React.useEffect(() => {
    if (visibilityDropdown) {
      clearTimeout(repeat);
      setVisibilityDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setVisibilityDropdownAnimation(false), 150));
    }
  }, [visibilityDropdown]);

  const onItemClickHandler = (item) => {
    setSelectedItem(item);
    setVisibilityDropdown(false);
  };

  return (
    <article
      ref={containerRef}
      className={`components-select-auth-select-box ${color} ${className}`}
    >
      <div
        className={`toggle ${visibilityDropdown && 'active'} ${
          selectedItem?.key && 'selected'
        }`}
        onClick={() => {
          setVisibilityDropdown(!visibilityDropdown);
        }}
      >
        {selectedItem?.i18nKey
          ? t(selectedItem?.i18nKey)
          : selectedItem?.display?.[t('lang')] || selectedItem?.name || title}
        <div className="toggle-placeholder fs16">{title}</div>
      </div>
      {Boolean(message) && <p className="message fs12">{message}</p>}
      {visibilityDropdownAnimation && (
        <div className="dropdown">
          <ul
            className={`dropdown-list ${
              visibilityDropdown ? 'slide-in' : 'slide-out'
            }`}
          >
            {(items || []).map((item, idx) => (
              <li
                key={idx}
                className="dropdown-list-item"
                onClick={() => onItemClickHandler(item)}
              >
                {type !== 'assessments'
                  ? item?.i18nKey
                    ? t(item?.i18nKey)
                    : item?.display?.[t('lang')]
                  : item?.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </article>
  );
};

export default MaterialSelect;

export const MaterialSelectSmall = ({
  frontIcon,
  selectedLabel,
  menuList,
  onClick,
  radius,
  width,
  className,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [repeat, setRepeat] = React.useState(null);
  const [visibilityDropdownAnimation, setVisibilityDropdownAnimation] =
    React.useState(false);
  const menuRef = React.useRef(null);
  // select menu 이벤트 hooks
  useOnClickOutside(menuRef, () => setIsOpen(false));

  React.useEffect(() => {
    if (isOpen) {
      clearTimeout(repeat);
      setVisibilityDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setVisibilityDropdownAnimation(false), 150));
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (menu) => {
    onClick(menu);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container" style={{ width }} ref={menuRef}>
      <button
        className={`dropdown-toggle-button ${className || ''}`}
        onClick={toggleMenu}
        style={{ borderRadius: radius }}
      >
        <span>
          {frontIcon !== undefined ? frontIcon : <></>}
          {t(selectedLabel) || t('survey.none')}
        </span>
        <i>
          <ChevronBottomIcon />
        </i>
      </button>
      {selectedLabel !== '' &&
        visibilityDropdownAnimation &&
        menuList?.length > 0 && (
          <div className="dropdown-list-box">
            <ul
              className={`dropdown-list ${isOpen ? 'slide-in' : 'slide-out'}`}
            >
              {(menuList || []).map((menu, idx) => (
                <li
                  className="dropdown-list-item"
                  key={`menu_${idx}`}
                  onClick={() => handleMenuClick(menu)}
                >
                  {t(menu?.i18nKey)}
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
};

export const MaterialSelectRecruitment = ({
  frontIcon,
  selectedLabel,
  menuList,
  onClick,
  width,
  className,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [repeat, setRepeat] = React.useState(null);
  const [visibilityDropdownAnimation, setVisibilityDropdownAnimation] =
    React.useState(false);
  const menuRef = React.useRef(null);
  // select menu 이벤트 hooks
  useOnClickOutside(menuRef, () => setIsOpen(false));

  React.useEffect(() => {
    if (isOpen) {
      clearTimeout(repeat);
      setVisibilityDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setVisibilityDropdownAnimation(false), 150));
    }
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (menu) => {
    onClick(menu);
    setIsOpen(false);
  };

  return (
    <div
      className={`recruitment-dropdown-container ${className}`}
      style={{ width }}
      ref={menuRef}
    >
      <button className="dropdown-toggle-button" onClick={toggleMenu}>
        <span>
          {frontIcon !== undefined ? frontIcon : <></>}
          <span className="text">
            {selectedLabel !== undefined && selectedLabel !== ''
              ? selectedLabel
              : defaultValue}
          </span>
        </span>
        <DropdownIcon />
      </button>
      {selectedLabel !== '' &&
        visibilityDropdownAnimation &&
        menuList?.length > 0 && (
          <div className="dropdown-list-box">
            <ul
              className={`dropdown-list ${isOpen ? 'slide-in' : 'slide-out'}`}
            >
              {(menuList || []).map((menu, idx) => (
                <li
                  className="dropdown-list-item"
                  key={`menu_${idx}`}
                  onClick={() => handleMenuClick(menu)}
                >
                  {t(menu?.name)}
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
};
