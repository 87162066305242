import React, { useState, useEffect } from 'react';
import VideoResult from 'components/assessment/testResult/VideoResult';
import CustomProgressChart from 'components/assessment/chart/CustomProgressChart';
import LanguageResult from 'components/assessment/testResult/LanguageResult';
import StylingResult from 'components/assessment/testResult/StylingResult';
import EssayResult from 'components/assessment/testResult/EssayResult';
import { ReactComponent as ArrowBottom } from 'assets/images/icon/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { useAverageScore } from 'utils/hooks';
import { convertGradeToScore } from 'utils/converter';
import { executeAction } from 'utils/redux';
import { getTestInfos, gradeResult } from 'store/slices/assessmentsSlice';
// import FeedbackScoreComponent from './FeedbackScoreComponent';
import CodingTestResult from './CodingTestResult';
import MultipleChoiceResult from './MultipleChoiceResult';

const TestResultToggleContainer = ({
  type,
  test,
  candidate,
  assessment,
  setRefreshing,
  getAnswer,
  objectiveTests,
}) => {
  const { t } = useTranslation();
  const [objectiveScores, setObjectiveScores] = useState(0);
  const [objectiveCats, setObjectiveCats] = useState(0);
  const lang = t('lang');

  const [visibilityDropdown, setVisibilityDropdown] = useState(
    type === 'print'
  );
  const [dropdownAnimation, setDropdownAnimation] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [scoreList, setScoreList] = useState([]);
  const [averageScore, setAverageScore] = useState(0);
  const [filteredQuestion, setFilteredQuestion] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (visibilityDropdown) {
      clearTimeout(repeat);
      setDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setDropdownAnimation(false), 150));
    }
  }, [visibilityDropdown]);

  useEffect(() => {
    if (Object.keys(test)?.length === 0 || typeof test?.question === 'string')
      return;

    if (Object.keys(candidate)?.length !== 0) {
      if (!candidate?.results || !Object.keys(candidate?.results).length)
        return;

      if (test?.question_type === 'tag.multiple-choice') {
        const { uuid } = test;
        const tasks = [];
        const _objectiveScores = {};
        const _objectiveCats = {};
        const result = candidate?.results?.[uuid];

        if (!result) return;

        const filterResult = Object.entries(result)?.filter(([key, value]) => {
          if (key === 'score' || key === 'comment') {
            return;
          }
          return {
            [key]: value,
          };
        });
        const changeObj = filterResult.reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

        tasks.push(
          executeAction(
            getTestInfos({ tids: test.test || [], lang: assessment?.language })
          )
            .unwrap()
            .then(({ docs: answers }) => {
              const temp = new Array();
              const category = {};
              const array = new Array();

              for (const [key, val] of Object.entries(changeObj || {})) {
                const tid = key;
                const answer = answers.find((x) => x.id === tid);

                if (!answer) return;

                if (!category[answer.skill_en]) category[answer.skill_en] = {};

                if (!category[answer.skill_en].cnt)
                  category[answer.skill_en].cnt = 0;

                if (!category[answer.skill_en].correct)
                  category[answer.skill_en].correct = 0;

                category[answer.skill_en].cnt += 1;

                if (answer?.correct.length > 1) {
                  let isCorrect = true;
                  answer?.correct.forEach((cor, idx) => {
                    isCorrect = isCorrect && cor === val?.[0][idx];
                  });
                  temp.push(true);
                  category[answer.skill_en].correct += 1;
                } else {
                  if (answer?.correct?.[0] === val?.[0]) {
                    category[answer.skill_en].correct += 1;
                  }
                  temp.push(answer?.correct?.[0] === val?.[0]);
                }
              }

              let _score = 0;
              if (temp.length)
                _score = temp.filter((x) => x).length / temp.length;
              const correct = temp.filter((x) => x).length;

              _objectiveScores[uuid] = new Array();
              _objectiveScores[uuid].push(_score);
              _objectiveScores[uuid].push(correct);
              _objectiveScores[uuid].push(temp.length);

              _objectiveCats[uuid] = new Array();
              for (const [key, val] of Object.entries(category || {})) {
                const tmp = {
                  category: key,
                  val,
                };
                array.push(tmp);
              }
              if (array.length !== 0) {
                array.sort((a, b) =>
                  String(a.category).localeCompare(String(b.category))
                );
                _objectiveCats[uuid] = array;
              }
            })
            .catch((e) => {
              console.error(e);
            })
        );

        Promise.allSettled(tasks)
          .then(() => {
            setObjectiveScores(_objectiveScores);
            setObjectiveCats(_objectiveCats);

            // 객관식 score set function
            if (
              candidate?.results?.[uuid]?.score === undefined ||
              candidate?.results?.[uuid]?.score === null
            ) {
              if (test?.uuid === Object.keys(_objectiveScores)[0]) {
                executeAction(
                  gradeResult({
                    aid: assessment?.rid || assessment?.id,
                    cid: candidate?.id || candidate?.email,
                    tuuid: uuid,
                    score: Object.values(_objectiveScores)[0][0],
                  })
                )
                  .unwrap()
                  .then(() => {
                    setRefreshing(true);
                  })
                  .catch((e) => {
                    console.dir(e);
                    setRefreshing(false);
                  });
              }
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }

      const filterQuestion = test?.question?.map((item) => {
        if (!candidate?.results || !candidate?.results[item.id || item.name])
          return;

        return item;
      });

      const removeUndefined = filterQuestion?.filter((item) => item);
      setFilteredQuestion(removeUndefined);

      test?.question?.map((question) => {
        if (!question?.name) return;
        // reading, listening 은 judged_difficulty로 고정값이 들어옴 5,10,15,20,25
        // speaking은 preset_score로 소수점 단위로 들어옴
        setScoreList([
          convertGradeToScore(
            candidate?.results[question?.name]?.reading?.judged_difficulty
          ) || 0,
          convertGradeToScore(
            candidate?.results[question?.name]?.listening?.judged_difficulty
          ) || 0,
          question?.score * 10 || question?.preset_score * 10 || 3,
        ]);
      });
    } else {
      setScoreList([]);
    }
  }, [test, candidate]);

  useEffect(() => {
    if (
      Object.keys(candidate)?.length === 0 ||
      Object.keys(test)?.length === 0 ||
      typeof test?.question === 'string'
    )
      return;

    if (!candidate?.results) {
      return setAverageScore([0]);
    }

    const { results } = candidate;
    const scoreArr = [];

    test?.question?.map((q) => {
      // 유저가 직접 채점을 하지 않았을 때, 해당 문제만 preset_score로 평균
      if (q?.id === '99101000006') return;

      scoreArr.push(
        results[q?.id]?.score === undefined || results[q?.id]?.score === null
          ? results[q?.id]?.preset_score
          : results[q?.id]?.score
      );
    });

    const filterScore = scoreArr.filter((item) => item);

    const average = useAverageScore(filterScore);
    setAverageScore(average);
  }, [candidate, test]);

  const onClickToggleButton = () => {
    setVisibilityDropdown(!visibilityDropdown);
  };

  const splitText = (text) => {
    const split = text?.split(':');

    return split[1] || split[0];
  };

  const tabClickHandler = (index) => {
    setActiveIndex(index);
  };

  const getAverageObjectiveScore = () => {
    let score = 0;
    let cnt = 0;
    Object.entries(objectiveScores || {}).forEach(([key, val], idx) => {
      if (val?.[0] >= 0 && val?.[0] <= 1) {
        score += val?.[0];
        cnt += 1;
      }
    });

    if (cnt === 0) return 0;

    return (score / cnt)?.toFixed?.(2);
  };

  const getSectionComponentBySectionName = (question, idx) => {
    const { section } = question;

    if (section === 'language') {
      return (
        <LanguageResult
          type={!type ? 'current' : type}
          visibilityDropdown={visibilityDropdown}
          question={question}
          test={test}
          assessment={assessment}
          candidate={candidate}
          scoreList={scoreList}
          setRefreshing={setRefreshing}
          getAnswer={getAnswer}
        />
      );
    }
    if (section === 'video') {
      return (
        <VideoResult
          type={!type ? 'current' : type}
          visibilityDropdown={visibilityDropdown}
          question={question}
          assessment={assessment}
          candidate={candidate}
          test={test}
          idx={idx}
          getAnswer={getAnswer}
          setRefreshing={setRefreshing}
        />
      );
    }

    if (section === 'essay') {
      return (
        <EssayResult
          type={!type ? 'current' : type}
          visibilityDropdown={visibilityDropdown}
          question={question}
          assessment={assessment}
          candidate={candidate}
          test={test}
          idx={idx}
          setRefreshing={setRefreshing}
        />
      );
    }

    if (section === 'styling') {
      return (
        <StylingResult
          visibilityDropdown={visibilityDropdown}
          question={question}
          assessment={assessment}
          candidate={candidate}
          test={test}
          setRefreshing={setRefreshing}
          type={!type ? 'current' : type}
        />
      );
    }

    if (section === 'code' || section === 'front-code') {
      return (
        <CodingTestResult
          visibilityDropdown={visibilityDropdown}
          question={question}
          assessment={assessment}
          candidate={candidate}
          test={test}
          setRefreshing={setRefreshing}
          type={!type ? 'current' : type}
          idx={idx}
          activeIndex={activeIndex}
        />
      );
    }
  };

  return (
    <>
      <aside className="test-toggle-box">
        <div className="toggle-header page-break">
          <div
            className={`toggle-header-title ${
              type === 'print' ? 'disabled' : ''
            }`}
            onClick={type !== 'print' ? onClickToggleButton : null}
          >
            <h4 className="results-sub-title">
              {test?.category_skill || test?.title_en}
            </h4>
            <div
              className={`toggle-button ${
                visibilityDropdown ? 'active' : 'disabled'
              }`}
            >
              <ArrowBottom />
            </div>
          </div>
          <div className="toggle-header-score-box page-break">
            <div className="toggle-header-score">
              <CustomProgressChart
                score={
                  Math.trunc(averageScore * 100) ||
                  Math.trunc(useAverageScore(scoreList) * 10) ||
                  parseInt(getAverageObjectiveScore() * 100) ||
                  0
                }
              />
            </div>
            {/* {test?.question_type === 'tag.code' ||
            test?.question_type === 'tag.front-code' ||
            test?.question_type === 'tag.multiple-choice' ? (
              <></>
            ) : (
              <div className="toggle-header-feedback page-break">
                {
                  // if(응시자에 대한 정보가 있고, 응시자 정보가 있으면서 결과 값이 있을 때)
                  //  {화면 표시}
                  Object.keys(candidate)?.length !== 0 && candidate?.results ? (
                    <div className="feedback-container">
                      {test?.section === 'language' ? (
                        // if(언어테스트의 question에 정보가 배열로 있을 때)
                        // {언어테스트 question 반복문 실행}
                        test?.question?.length !== 0 &&
                        test?.question?.map((que, index) => (
                          // if(응시자 결과중에 언어테스트 종류(english, chinese, japanese)객체 안에 total_feedback 이 undefined || null이 아니고,
                          //    total_feedback안에 data가 들어 있을 때)
                          // {total_feedback 반복문 실행}
                          <React.Fragment key={index}>
                            {candidate?.results[que?.name]?.total_feedback &&
                            Object.keys(
                              candidate?.results[que?.name]?.total_feedback
                            ).length !== 0 ? (
                              Object.keys(
                                candidate?.results[que?.name]?.total_feedback[
                                  lang
                                ]
                              )?.map((feedback, idx) => (
                                <FeedbackScoreComponent
                                  key={idx}
                                  objKey={que?.name}
                                  candidate={candidate}
                                  feedback={feedback}
                                  splitText={splitText}
                                />
                              ))
                            ) : (
                              <p className="toggle-header-feedback-desc">
                                {t('desc.wating-feedback')}
                              </p>
                            )}
                          </React.Fragment>
                        ))
                      ) : // if(test 객체 안에 test 키가 존재하고 응시자 결과 데이터 안에 test[1].total_feedback 값이 있다면)
                      // {total_feedback 반복문 실행}
                      test?.test &&
                        candidate?.results[
                          test?.id === '990001' ? test?.test[1] : test?.test[0]
                        ]?.total_feedback ? (
                        (
                          Object.keys(
                            candidate?.results[
                              test?.id === '990001'
                                ? test?.test[1]
                                : test?.test[0]
                            ]?.total_feedback[lang]
                          ) || []
                        )?.map((feedback, idx) => (
                          <FeedbackScoreComponent
                            key={idx}
                            objKey={
                              test?.id === '990001'
                                ? test?.test[1]
                                : test?.test[0]
                            }
                            candidate={candidate}
                            feedback={feedback}
                            splitText={splitText}
                          />
                        ))
                      ) : (
                        <p className="toggle-header-feedback-desc">
                          {t('desc.wating-feedback')}
                        </p>
                      )}
                    </div>
                  ) : (
                    <></>
                  )
                }
              </div>
            )} */}
          </div>
        </div>

        {dropdownAnimation &&
          (candidate?.results ? (
            <div className="toggle-body-wrapper">
              {(test?.question_type === 'tag.code' ||
                test?.question_type === 'tag.front-code') && (
                <aside className="coding-tab-container">
                  <ul className="coding-tab-list">
                    {test?.question
                      ?.filter((item) => item.selected)
                      ?.map((_, idx) => (
                        <li
                          className={`coding-tab-list-item ${
                            activeIndex === idx ? 'selected' : ''
                          }`}
                          key={idx}
                          onClick={() => tabClickHandler(idx)}
                        >
                          {`Q${idx + 1}`}
                        </li>
                      ))}
                  </ul>
                </aside>
              )}

              {test?.question_type === 'tag.multiple-choice' && (
                <MultipleChoiceResult
                  type={!type ? 'current' : type}
                  visibilityDropdown={visibilityDropdown}
                  objectiveCats={objectiveCats}
                  objectiveScores={objectiveScores}
                  objectiveTests={objectiveTests}
                  candidate={candidate}
                  assessment={assessment}
                />
              )}

              {filteredQuestion.length !== 0 &&
                filteredQuestion?.map((que, _idx) => {
                  if (que?.id === '99000100001') return null;

                  return (
                    <React.Fragment key={_idx}>
                      {/* 하나의 테스트 안에 문제 유형에 따라 다른 결과화면을 보여줌 */}
                      {getSectionComponentBySectionName(que, _idx)}
                    </React.Fragment>
                  );
                })}
            </div>
          ) : (
            <h4 className="results-semi-sub-title">
              {t('desc.not-yet-assessment')}
            </h4>
          ))}
      </aside>
    </>
  );
};

export default TestResultToggleContainer;
