import {
  getFirestore,
  collection,
  doc,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
  Timestamp,
  getDoc,
  query,
  orderBy,
  where,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../index';

const db = getFirestore(app);
const functions = getFunctions(app, 'asia-northeast1');

export const _createMemberUserInfo = ({ uid, authToken, payload }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);
      authToken = authToken || '';

      await setDoc(docRef, {
        authToken,
        createdAt: Timestamp.now(),
        ...payload,
      });
      return resolve(uid);
    } catch (e) {
      return reject(e);
    }
  });

/**
 * gucci users
 */
export const _getGucciUserList = () =>
  new Promise(async (resolve, reject) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('isATS', '==', true));
      const docSnap = await getDocs(q);
      const newDocs = [];

      docSnap.forEach((doc) => {
        newDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      if (newDocs.length !== 0) {
        return resolve(newDocs);
      }
      throw new Error('empty');
    } catch (e) {
      return reject(e);
    }
  });

export const _memberInvitedCandidate = ({ uid, rid, isRecruitment, stage }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', rid);
      await setDoc(
        docRef,
        {
          isRecruitment,
          stage,
          createdAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );

      return resolve(docRef);
    } catch (e) {
      return reject(e);
    }
  });

export const _addCandidateOfMember = ({ uid, rid, email, recruitProgress }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        rid,
        'candidates',
        email
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        throw new Error('already_exists_email');
      }

      await setDoc(
        docRef,
        {
          recruitProgress,
          createdAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _setMemberInfo = ({ uid, interviewInfo, memberType }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);

      if (interviewInfo) {
        await setDoc(
          docRef,
          {
            interviewInfo,
          },
          {
            merge: true,
          }
        );
      } else if (memberType) {
        await setDoc(
          docRef,
          {
            memberType,
          },
          {
            merge: true,
          }
        );
      }
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _setMemberDisabled = ({ uid, disabled }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid);

      await setDoc(
        docRef,
        {
          disabled,
          disabledAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );

      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });
