import React from 'react';
import { Trans } from 'react-i18next';

const EmptyTable = () => (
  <div className="components-logo-empty-table">
    <img src="/images/thumbnail/empty-table.svg" alt="empty table thumbnail" />
    <Trans parent="p" className="fs20" i18nKey="select-test.desc.empty" />
  </div>
);

export default EmptyTable;
