import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';

const ConfirmToggleAssessment = ({ event: { message }, title: { main, sub } }) => {
    const { t } = useTranslation();
    const { dismissModal } = useModal();

    return (
        <article className="modals-common-alert-modal">
            <section className="title">
                <h3 className="title-main fs16">{main}</h3>
                <div className="title-sub fs14">{sub}</div>
            </section>
            <section className="buttons">
                <button
                    className="common-button fsbtn16 cancel active"
                    onClick={() => dismissModal()}
                >
                    {t('btn.no')}
                </button>
                <button
                    className="common-button fsbtn16 primary active"
                    onClick={() =>
                        dismissModal({ message: message || 'dismiss' })
                    }
                >
                    {t('btn.yes')}
                </button>
            </section>
        </article>
    );
};

export default ConfirmToggleAssessment;
