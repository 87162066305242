import React, { useEffect, useState } from 'react';
import { ReactComponent as ActiveIcon } from 'assets/images/icon/table-item-icon.svg';
import { ReactComponent as InactiveIcon } from 'assets/images/icon/table-item-icon-inactive.svg';
import { ReactComponent as HamburgerIcon } from 'assets/images/icon/hamburger-menu.svg';
import { ReactComponent as AccountIcon } from 'assets/images/icon/supervisor-account-grey-24x24.svg';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RecruitmentListItem from './RecruitmentListItem';
import RecruitmentCreateButton from './RecruitmentCreateButton';
import RecruitmentListSkeleton from './RecruitmentListSkeleton';

const RecruitmentList = ({ assessments, filteredRecruitments }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { rid } = useParams();
  const location = useLocation();

  const [isDashboardListActive, setIsDashboardListActive] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    const split = pathname?.split('/')[3];

    if (split === 'dashboard') {
      setIsDashboardListActive(true);
    } else {
      setIsDashboardListActive(false);
    }
  }, [location.pathname]);

  const moveToDashboardPage = () => {
    navigate('/customer/recruitments/dashboard');
  };

  const moveToCandidateListPage = () => {
    navigate('/customer/recruitments');
  };

  return (
    <ul className="recruitment-list">
      {userInfo.memberType < 15 && (
        <RecruitmentCreateButton
          userInfo={userInfo}
          assessments={assessments}
          disabled={userInfo && userInfo.memberType === 20}
        />
      )}
      {userInfo.memberType !== 15 && (
        <li
          className={`dashboard-button ${
            rid === undefined && !isDashboardListActive && 'active'
          }`}
          onClick={() => moveToCandidateListPage()}
        >
          <i>
            <AccountIcon />
          </i>
          <h6>{t('btn.candidates')}</h6>
        </li>
      )}
      <li
        className={`dashboard-button ${isDashboardListActive && 'active'}`}
        onClick={() => moveToDashboardPage()}
      >
        <i>
          <HamburgerIcon />
        </i>
        <h6>{t('btn.dashboard')}</h6>
      </li>

      {!filteredRecruitments ? (
        <>
          <RecruitmentListSkeleton />
          <RecruitmentListSkeleton />
          <RecruitmentListSkeleton />
        </>
      ) : (
        filteredRecruitments?.map((recruit, idx) => (
          <RecruitmentListItem
            userInfo={userInfo}
            recruit={recruit}
            key={`recruit_${idx}`}
            icon={
              !recruit?.isRecruitmentArchived ? (
                <ActiveIcon />
              ) : (
                <InactiveIcon />
              )
            }
          />
        ))
      )}
    </ul>
  );
};

export default RecruitmentList;
