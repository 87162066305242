import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'utils/auth';
import { isSameLowerCaseString } from 'utils/string';

const AppNavigator = ({ setDisplay, callback }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const [curTab, setCurTab] = useState(null);
  const [TABS, setIsTABS] = useState([]);
  const [isHideTabs, setIsHideTabs] = useState(false);

  const ulRef = useRef(null);

  useEffect(() => {
    if (!auth?.userInfo) return;
    const userInfo = auth?.userInfo;

    if (userInfo?.isATS) {
      setIsTABS([
        {
          key: 'recruitments',
          i18nKey: 'header.menu.my-recruitments',
          className: 'all',
          onClick: ({ navigate, setCurTab }, callback) => {
            navigate('/customer/recruitments');
            setCurTab('recruitments');
            callback && callback();
          },
        },
        {
          key: 'assessments',
          i18nKey: 'header.menu.my-assessments',
          className: 'all',
          onClick: ({ navigate, setCurTab }, callback) => {
            navigate('/customer/assessments');
            setCurTab('assessments');
            callback && callback();
          },
        },
        {
          key: 'candidates',
          i18nKey: 'header.menu.my-candidates',
          className: 'all',
          onClick: ({ navigate, setCurTab }, callback) => {
            navigate('/customer/candidates');
            setCurTab('candidates');
            callback && callback();
          },
        },
        {
          key: 'tests',
          i18nKey: 'header.menu.tests',
          className: 'all',
          onClick: ({ navigate, setCurTab }, callback) => {
            navigate('/customer/tests');
            setCurTab('tests');
            callback && callback();
          },
        },
        {
          key: 'profile',
          i18nKey: 'header.menu.settings',
          className: 'mobile',
          onClick: ({ navigate, setCurTab }, callback) => {
            navigate('/customer/profile');
            setCurTab('profile');
            callback && callback();
          },
        },
        {
          key: 'signout',
          i18nKey: 'header.menu.logout',
          className: 'mobile',
          onClick: ({ auth }, callback) => {
            auth?.signout?.();
            callback && callback();
          },
        },
      ]);
    } else {
      setIsTABS([
        {
          key: 'assessments',
          i18nKey: 'header.menu.my-assessments',
          className: 'all',
          onClick: ({ navigate, setCurTab }, callback) => {
            navigate('/customer/assessments');
            setCurTab('assessments');
            callback && callback();
          },
        },
        {
          key: 'candidates',
          i18nKey: 'header.menu.my-candidates',
          className: 'all',
          onClick: ({ navigate, setCurTab }, callback) => {
            navigate('/customer/candidates');
            setCurTab('candidates');
            callback && callback();
          },
        },
        {
          key: 'tests',
          i18nKey: 'header.menu.tests',
          className: 'all',
          onClick: ({ navigate, setCurTab }, callback) => {
            navigate('/customer/tests');
            setCurTab('tests');
            callback && callback();
          },
        },
        {
          key: 'profile',
          i18nKey: 'header.menu.settings',
          className: 'mobile',
          onClick: ({ navigate, setCurTab }, callback) => {
            navigate('/customer/profile');
            setCurTab('profile');
            callback && callback();
          },
        },
        {
          key: 'signout',
          i18nKey: 'header.menu.logout',
          className: 'mobile',
          onClick: ({ auth }, callback) => {
            auth?.signout?.();
            callback && callback();
          },
        },
      ]);
    }
  }, [auth]);

  useEffect(() => {
    if (!auth?.userInfo?.memberType) return;
    const userInfo = auth?.userInfo;

    if (userInfo?.memberType <= 10) {
      setIsHideTabs(true);
    }
  }, [auth?.userInfo]);

  useEffect(() => {
    const keys = TABS.map((tab) => tab.key);
    const pathArr = (location?.pathname || '').split('/');
    setCurTab((keys || []).filter((it) => pathArr.includes(it))?.[0]);
  }, [TABS, location?.pathname]);

  // indicator styling.
  // useEffect(() => {
  //   const liElems = ulRef.current.getElementsByTagName('li');
  //   const indicatorElem =
  //     ulRef.current.getElementsByClassName('indicator')?.[0];
  //   const curIdx = (TABS || []).findIndex((tab) =>
  //     isSameLowerCaseString(tab.key, curTab)
  //   );

  //   if (curIdx < 0) return;
  //   if (isSameLowerCaseString(curTab, 'profile')) {
  //     indicatorElem.style.width = '0px';
  //     return;
  //   }

  //   let leftSize = 0;
  //   for (let i = 0; i < curIdx; i += 1) {
  //     const liElem = liElems?.[i];
  //     leftSize += liElem.offsetWidth;
  //   }

  //   const paddingHorizontalValue =
  //     parseInt(
  //       window
  //         .getComputedStyle(
  //           liElems?.[curIdx]?.getElementsByClassName(
  //             'tabs-list-item-container'
  //           )?.[0],
  //           ''
  //         )
  //         .getPropertyValue('padding-left')
  //     ) +
  //     parseInt(
  //       window
  //         .getComputedStyle(
  //           liElems?.[curIdx]?.getElementsByClassName(
  //             'tabs-list-item-container'
  //           )?.[0],
  //           ''
  //         )
  //         .getPropertyValue('padding-right')
  //     );

  //   indicatorElem.style.width = `${
  //     liElems?.[curIdx].offsetWidth - paddingHorizontalValue
  //   }px`;
  //   indicatorElem.style.transform = `translateX(${leftSize}px)`;
  // }, [curTab]);

  return (
    <nav className="components-navigator-app-navigator">
      <ul ref={ulRef} className="tabs-list">
        {(TABS || []).map((tab) => {
          if (!auth?.userInfo?.isATS) {
            return (
              <li
                className={`tabs-list-item ${curTab === tab.key && 'selected'}`}
                key={tab.key}
              >
                <div
                  className={`tabs-list-item-container ${tab.className} ${
                    curTab === tab.key && 'selected'
                  }`}
                  onClick={() =>
                    tab.onClick({ navigate, setCurTab, auth }, () => {
                      setDisplay?.(false);
                      callback && callback();
                    })
                  }
                >
                  <span className="fs16">{t(tab.i18nKey)}</span>
                </div>
              </li>
            );
          }
          if (
            (tab.key === 'assessments' ||
              tab.key === 'candidates' ||
              tab.key === 'tests') &&
            !isHideTabs
          ) {
            return null;
          }
          return (
            <li
              className={`tabs-list-item ${curTab === tab.key && 'selected'}`}
              key={tab.key}
            >
              <div
                className={`tabs-list-item-container ${tab.className} ${
                  curTab === tab.key && 'selected'
                }`}
                onClick={() =>
                  tab.onClick({ navigate, setCurTab, auth }, () => {
                    setDisplay?.(false);
                    callback && callback();
                  })
                }
              >
                <span className="fs16">{t(tab.i18nKey)}</span>
              </div>
            </li>
          );
        })}
        <div className="indicator" />
      </ul>
    </nav>
  );
};

export default AppNavigator;
