import React from 'react';

const Tag = ({
  label,
  color,
  fontSize,
  fontColor,
  padding,
  borderRadius,
  width,
}) => {
  return (
    <i
      className={`common-tag ${color} ${fontSize} ${fontColor}`}
      style={{
        padding: `${padding}px`,
        borderRadius: `${borderRadius}px`,
        width,
      }}
    >
      {label && label}
    </i>
  );
};

export default Tag;
