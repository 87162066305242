import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialSelect from 'components/select/MaterialSelect';
import MaterialDateTimePicker from 'components/common/MaterialDateTimePicker';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Box,
  Checkbox,
} from '@mui/material';
import { ReactComponent as TimerIcon } from 'assets/images/icon/timer.svg';
import { ReactComponent as MoreIcon } from 'assets/images/icon/more.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icon/error_24px.svg';

const __GROUP_TEST_TIME__ = 10;

const __DROPDOWN_ITEMS__ = [
  {
    key: 'move-up',
    i18nKey: 'actions.menu.move-up',
    onClick: ({ idx, setShowDropdown, tests, setTests }) => {
      if (idx > 0) {
        setTests([
          ...tests.slice(0, idx - 1),
          tests[idx],
          tests[idx - 1],
          ...tests.slice(idx + 1, tests.length),
        ]);
      }
      setShowDropdown(-1);
    },
  },
  {
    key: 'move-down',
    i18nKey: 'actions.menu.move-down',
    onClick: ({ idx, setShowDropdown, tests, setTests }) => {
      if (idx < tests.length - 1) {
        setTests([
          ...tests.slice(0, idx),
          tests[idx + 1],
          tests[idx],
          ...tests.slice(idx + 2, tests.length),
        ]);
      }
      setShowDropdown(-1);
    },
  },
  {
    key: 'delete',
    i18nKey: 'actions.menu.delete',
    onClick: ({ idx, setShowDropdown, tests, setTests }) => {
      if (tests[idx]?.group?.length > 0) {
        return;
      }
      setTests([...tests.slice(0, idx), ...tests.slice(idx + 1, tests.length)]);
      setShowDropdown(-1);
    },
  },
];

const surveyOnOffOptions = [
  {
    key: 'surveyRequired',
    i18nKey: 'actions.menu.survey-required',
  },
  {
    key: 'surveyOptional',
    i18nKey: 'actions.menu.survey-optional',
  },
  {
    key: 'noSurvey',
    i18nKey: 'actions.menu.no-survey',
  },
];

function Step4({
  tests,
  setTests,
  AntiCheating,
  setAntiCheating,
  setSurveyOnOff,
  setPeriodOnOff,
  periodOnOff,
  setStartPeriodDateTime,
  setEndPeriodDateTime,
}) {
  const { t } = useTranslation();

  const [showDropdown, setShowDropdown] = React.useState(-1);
  const [dragStartIdx, setDragStartIdx] = React.useState(-1);
  const [questionBoolean, setQuestionBoolean] = React.useState();

  const rowRefs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];
  const resultRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  React.useEffect(() => {
    const handler = (event) => {
      for (const rowRef of rowRefs) {
        if (rowRef.current) {
          const moreElem = rowRef.current.querySelector('.more-icon');
          if (moreElem.contains(event.target)) return;
        }
      }

      if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
        return;
      }

      setShowDropdown(-1);
    };

    window.document.addEventListener('mousedown', handler);
    window.document.addEventListener('touchstart', handler);

    return () => {
      window.document.removeEventListener('mousedown', handler);
      window.document.removeEventListener('touchstart', handler);
    };
  }, [dropdownRef, rowRefs]);

  const onMoreClickHandler = (idx) => {
    if (resultRef?.current && rowRefs?.[idx]?.current)
      var resultRect = resultRef.current.getBoundingClientRect(),
        elemRect = rowRefs?.[idx].current.getBoundingClientRect(),
        offset = elemRect.top - resultRect.top,
        offsetRight = resultRect.right - elemRect.right;

    resultRef.current.style.setProperty('--dropdown-top', `${offset}px`);
    resultRef.current.style.setProperty('--dropdown-right', `${offsetRight}px`);
    setShowDropdown(idx);
  };
  const getTestDuration = (test) => {
    if (test?.group?.length > 0 && test?.testcnt > 0) {
      return test.testcnt * __GROUP_TEST_TIME__ + t('desc.minute');
    }
    if (typeof test?.limit === 'number') {
      return test?.limit + t('desc.minute');
    }

    if (test?.limit && typeof test?.limit === 'string') {
      return t(`text-field.input.${test?.limit}`);
    }
    if (test?.duration) {
      return test?.duration + t('desc.minute');
    }
    return t('15mins');
  };
  const onCheatingChangeHandler = (event) => {
    event.preventDefault();
    const antCheating = !AntiCheating;
    setAntiCheating(antCheating);
  };
  const onPeriodChangeHandler = (event) => {
    event.preventDefault();
    const newPeriod = !periodOnOff;
    setPeriodOnOff(newPeriod);
  };

  React.useEffect(() => {
    const testArr = [];
    tests?.map((test) => {
      testArr.push(test.question_type);
    });

    testArr.find((test) => {
      if (test === 'tag.code') {
        setQuestionBoolean(true);
      }
    });
  }, []);

  return (
    <main className="pages-protected-assessments-create-step-4">
      <section ref={resultRef} className="result">
        <section className="result-title">
          <h2 className="result-title-main fs20">
            {t('title.review-configure')}
          </h2>
          <div className="result-title-sub">{t('desc.changes-assessment')}</div>

          {questionBoolean && (
            <div className="alert code-alert">
              <ErrorIcon width="24px" height="24px" />
              <span className="desc fs12">
                {t('desc.new-assessment-no-mobile-support')}
              </span>
            </div>
          )}
        </section>
        <div className="result-box">
          <Box className="result-table-box">
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="70%">{t('column.test-name')}</TableCell>
                      <TableCell className="duration">
                        <div>{t('column.duration')}</div>
                      </TableCell>
                      <TableCell align="right">{t('column.actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(tests || []).map((test, idx) => (
                      <TableRow
                        ref={rowRefs[idx]}
                        key={idx}
                        data-idx={idx}
                        hover
                        draggable
                        onDragStart={(e) => {
                          setDragStartIdx(idx);
                        }}
                        onDragEnter={(e) => {
                          const newIdx = dragStartIdx < idx ? idx - 1 : idx;
                          const newTests = [
                            ...tests.slice(0, dragStartIdx),
                            ...tests.slice(dragStartIdx + 1, tests.length),
                          ];
                        }}
                      >
                        <TableCell
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html:
                              test?.[`title_${t('en')}`] ||
                              test?.group ||
                              test?.category_skill ||
                              test?.question,
                          }}
                        />
                        <TableCell className="duration">
                          <div className="container duration">
                            <TimerIcon />
                            <span>{getTestDuration(test)}</span>
                          </div>
                        </TableCell>
                        <TableCell className="more" align="right">
                          <div
                            className="more-icon"
                            onClick={() =>
                              onMoreClickHandler(
                                showDropdown === idx ? -1 : idx
                              )
                            }
                          >
                            <MoreIcon />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {tests.length <= 0 && (
                      <TableRow>
                        <TableCell className="title empty-test" colSpan={3}>
                          <div className="container empty">
                            {t('desc.no-test-selected')}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
          {showDropdown > -1 && (
            <div ref={dropdownRef} className="result-dropdown">
              <ul className="result-dropdown-list">
                {(__DROPDOWN_ITEMS__ || []).map((item) => (
                  <li
                    key={item.key}
                    className="result-dropdown-list-item"
                    onClick={() =>
                      item.onClick({
                        idx: showDropdown,
                        setShowDropdown,
                        tests,
                        setTests,
                      })
                    }
                  >
                    {t(item.i18nKey)}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <section className="advanced-option">
            <div className="title fs20">{t('title.advanced-options')}</div>
            <div className="sub">
              <div className="sub-title fs18">
                {t('title.anti-cheating-settings')}
              </div>
              <div
                className={`select ${AntiCheating === true ? 'selected' : ''}`}
                onClick={(e) => onCheatingChangeHandler(e)}
              >
                <Checkbox
                  className={`radio-button ${
                    AntiCheating === true ? 'selected' : ''
                  }`}
                  checked={AntiCheating}
                  onChange={(e) => onCheatingChangeHandler(e)}
                  name="radio-buttons"
                />
                <p className="fs16">
                  {t('btn.anti-cheating-camera')} {AntiCheating}
                </p>
              </div>
              <div className="alert">
                <ErrorIcon width="24px" height="24px" />
                <span className="desc fs12">
                  {t('desc.anti-cheating-camera')}
                </span>
              </div>
              <div className="sub-title fs18">{t('title.survey-settings')}</div>
              <MaterialSelect
                value={surveyOnOffOptions[0]}
                onChange={(item) => {
                  setSurveyOnOff(item?.key);
                }}
                items={surveyOnOffOptions}
                color="$grey300"
              />
              <div className="sub-title fs18">
                {t('title.assessment-period')}
              </div>
              <div
                className={`select ${periodOnOff === true ? 'selected' : ''}`}
                onClick={(e) => onPeriodChangeHandler(e)}
              >
                <Checkbox
                  className={`radio-button ${
                    periodOnOff === true ? 'selected' : ''
                  }`}
                  checked={periodOnOff}
                  onChange={(e) => onPeriodChangeHandler(e)}
                  name="radio-buttons"
                />
                <p className="fs16">{t('btn.assessment-period')}</p>
              </div>
              <div className="time-picker-box">
                <div className="time-label">
                  <div className="fs14 fcgrey700">
                    {t('text-field.label.assessment-start-time')}
                  </div>
                  <MaterialDateTimePicker
                    period={periodOnOff}
                    setPeriodDateTime={setStartPeriodDateTime}
                  />
                </div>
                <div className="time-label">
                  <div className="fs14 fcgrey700">
                    {t('text-field.label.assessment-end-time')}
                  </div>
                  <MaterialDateTimePicker
                    period={periodOnOff}
                    setPeriodDateTime={setEndPeriodDateTime}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </main>
  );
}

// Step4.propTypes = {
//   curStep: PropTypes.number,
//   objectiveTests: PropTypes.array,
//   subjectiveTests: PropTypes.array,
//   AntiCheating: PropTypes.bool,
//   setAntiCheating: PropTypes.func,
// };

export default Step4;
