import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';
import { useModal } from 'utils/modal';

const ObjectiveTestDetail = ({ test, contains }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();
  const [testType, setTestType] = React.useState(null);

  React.useEffect(() => {
    const test_type = test?.question_type?.replaceAll(' ', '').split(',');
    setTestType(test_type);
  }, [test]);

  return (
    <article className="modals-assessments-objective-test-detail">
      <header className="header">
        <h3 className="header-title fs16">{t('title.test-detail')}</h3>
        <button className="close" onClick={dismissModal}>
          <CloseIcon />
        </button>
      </header>
      <section className="info">
        <div className="header">
          <div className="title fs24">
            {test?.title_en || test?.category_skill}
          </div>
          <div className="desc fs16">{test?.description}</div>
        </div>
        <div className="desc">
          <div className="type">
            <div className="title fs16">{t('column.type')}</div>
            <div className="types">
              {(testType || []).map((type, idx) => (
                <div
                  className={`skill-type fs12 ${type.replaceAll('.', '_')}`}
                  key={idx}
                >
                  {t(type)}
                </div>
              ))}
            </div>
          </div>
          <div className="category">
            <div className="title fs16">{t('column.category')}</div>
            <div className="name fs16">{test?.category}</div>
          </div>
          <div className="duration">
            <div className="title fs16">{t('column.duration')}</div>
            <div className="duration fs16">
              {`${test?.duration || '10'} ${t('desc.minute')}`}
            </div>
          </div>
        </div>
        <div className="skillset">
          <div className="title fs16">{t('column.skill')}</div>
          <ul>
            {(contains || []).map((contain, idx) =>
              contain?.length > 0 ? (
                <li className="fs14" key={idx}>
                  {contain}
                </li>
              ) : (
                <></>
              )
            )}
          </ul>
        </div>
      </section>
    </article>
  );
};

export default ObjectiveTestDetail;
