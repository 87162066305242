import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';

export const EditSelectBox = ({ menu, name, value, onChange }) => {
  const {t} = useTranslation();
  return (
    <Select
      sx={{ fontSize: 12, width: '100%'}}
      name={name}
      classes={{ outlined: 'candidate-info-edit-select'}}
      value={value}
      onChange={onChange}>
      {menu.map((item) => <MenuItem sx={{fontSize: 12}} value={item.key}>{t(item.i18nKey)}</MenuItem>)}
    </Select>
  );
};