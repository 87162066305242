import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useModal } from 'utils/modal';
import TinyEditor from 'components/editor/TinyEditor';
import { executeAction } from 'utils/redux';
import { getUserInfo } from 'store/slices/authSlice';
import {
  getDefaultTemplate,
  editEmailContent,
  getEditText,
} from 'store/slices/assessmentsSlice';
import { useSelector } from 'react-redux';
import ProgressCircle from 'components/progress/ProgressCircle';
import { CircularProgress } from '@mui/material';

const __LOGO_URL_DEFAULT__ =
  'https://user-images.githubusercontent.com/106224686/177253066-4fc6c4ff-bf36-4a89-b474-8ba6c20cf625.png';

const InviteCandidate = ({ aid, lang }) => {
  const { t } = useTranslation();
  const curLang = useSelector((state) => state.variable?.lang.slice(0, 2));
  const { dismissModal } = useModal();
  const [logoURL, setLogoURL] = React.useState(__LOGO_URL_DEFAULT__);
  const [editText, setEditText] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaveLoading, setIsSaveLoading] = React.useState(false);

  const editorRef = React.useRef(null);

  React.useEffect(() => {
    setIsLoading(true);
    executeAction(getUserInfo())
      .unwrap()
      .then(({ logoURL }) => {
        setLogoURL(logoURL || __LOGO_URL_DEFAULT__);
        executeAction(
          getEditText({
            aid,
            key: 'edit_invite_email',
            lang,
          })
        )
          .unwrap()
          .then(({ editText }) => {
            editorRef.current?.setContent(editText);
            setEditText(editText);
            setIsLoading(false);
          })
          .catch((e) => {
            console.dir(e);
            setIsLoading(false);
          });
      })
      .catch((e) => {
        console.dir(e);
        setLogoURL(__LOGO_URL_DEFAULT__);
        setIsLoading(false);
      });
  }, [aid]);

  const onSaveHandler = () => {
    setIsSaveLoading(true);
    executeAction(
      editEmailContent({
        aid,
        key: 'edit_invite_email',
        value: editText,
      })
    )
      .unwrap()
      .then(() => {
        setIsSaveLoading(false);
        dismissModal();
      })
      .catch((e) => {
        console.dir(e);
        setIsSaveLoading(false);
      });
  };

  const onResetButtonClickHandler = () => {
    executeAction(
      getDefaultTemplate({
        tid: 'edit_invite_email',
      })
    )
      .unwrap()
      .then(({ doc }) => {
        const resetText = doc?.[curLang] || '';
        editorRef.current.setContent(resetText);
        setEditText(resetText);
      })
      .catch((e) => {
        console.dir(e);
      });
  };

  return (
    <article className="modals-assessments-edit-invite-candidate">
      <header className="header">
        <h3 className="header-title fs20">{t('title.edit-email')}</h3>
        <button className="header-close" onClick={() => dismissModal()} />
      </header>
      <h4 className="title fs28">{t('title.edit-invite-email')}</h4>
      <section className="form">
        <section className="form-editor">
          <h5 className="form-editor-title">{t('title.email-contents')}</h5>
          <TinyEditor
            ref={editorRef}
            className="form-editor-section"
            onKeyPress={() => setEditText(editorRef.current?.getContent())}
            onKeyUp={() => setEditText(editorRef.current?.getContent())}
          />
        </section>

        <section className="form-preview">
          <h5 className="form-preview-title fs20">{t('title.preview')}</h5>
          <article className="form-preview-section">
            <img src={logoURL} alt="email-logo" />
            <Trans parent="div" className="edit-text">
              {editText}
            </Trans>
            <p className="fs16">
              <Trans i18nKey="desc.start-assessment" tOptions={{ lng: lang }} />
            </p>
            <button className="go-to-assessment fs16">
              <Trans i18nKey="btn.go-assessment" tOptions={{ lng: lang }} />
            </button>
            <p className="fs16">
              <Trans
                i18nKey="desc.link-does-not-work"
                tOptions={{ lng: lang }}
              />
            </p>
            <a className="public-link" href="#">
              http://example.com
            </a>
            <div className="small fs12">
              <Trans i18nKey="desc.any-issue-help" tOptions={{ lng: lang }} />
            </div>
            <p className="fs16">
              <Trans
                i18nKey="desc.all-the-best-munchskill-team"
                tOptions={{ lng: lang }}
              />
            </p>
            <div className="small fs12">© Promenade AI Inc.</div>
          </article>
        </section>
      </section>
      <button
        className={`save common-button fsbtn16 primary ${
          !isSaveLoading && 'active'
        } ${isSaveLoading && 'pending'}`}
        onClick={!isSaveLoading ? onSaveHandler : null}
      >
        {t('btn.save')}
        {isSaveLoading && <CircularProgress color="grey" size={25} />}
      </button>
      <button
        className="reset common-button fsbtn16 gray active"
        onClick={onResetButtonClickHandler}
      >
        {t('btn.reset-email-form')}
      </button>
      {isLoading && <ProgressCircle className="progress-circle" size={45} />}
    </article>
  );
};

export default InviteCandidate;
