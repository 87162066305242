import RecruitmentTableHead from 'components/recruitment/detail/RecruitmentTableHead';
import RecruitmentTableBody from 'components/recruitment/detail/reject/RecruitmentTableBody';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFilterOfRecruitmentProcess } from 'utils/hooks';

const HEADS = [
  'column.candidate-name',
  'column.channel',
  'column.final-hiring-steps',
  'column.lead-time',
  'tab.reject',
  '-'
];

const Reject = () => {
  const recruitmentProps = useOutletContext();
  const { recruitment, candidates, userInfo } = recruitmentProps;
  const [, processLength] = useFilterOfRecruitmentProcess(recruitment);

  const [rejectCandidates, setRejectCandidates] = useState([]);

  useEffect(() => {
    const filtered = candidates?.filter((item) => item?.recruitProgress === -1);

    setRejectCandidates(filtered);
  }, [candidates]);

  return (
    <section className="dashboard-reject-contents table-border">
      <table className="recruitment-table">
        <caption>candidates reject list</caption>
        <RecruitmentTableHead heads={HEADS} />
        <RecruitmentTableBody
          processLength={processLength}
          candidates={rejectCandidates}
          recruitment={recruitment}
        />
      </table>
    </section>
  );
};

export default Reject;
