import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import { nameConversionLanguage } from 'utils/converter';
import { convertGender } from 'utils/convertKey';
import BodyTableRowSkeleton from '../detail/BodyTableRowSkeleton';

const CandidateTableBody = ({ users, loading }) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();

  const viewDetailModal = (candidate) => {
    displayModal(
      'recruitments/CandidateListDetail',
      {
        candidates: users,
        candidate,
      },
      { frame: 'full' }
    );
  };

  const countDuplicateEmails = (email) => {
    const findEmail = users.count.find((item) => item.email === email);

    if (findEmail && findEmail.count > 1) {
      return 'Y';
    }
    return 'N';
  };

  return (
    <tbody>
      {!users || loading === true ? (
        <>
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
        </>
      ) : users.data.length === 0 ? (
        <tr onClick={() => viewDetailModal()}>
          <td>{t('recruitment.desc.empty-no-applicant')}</td>
        </tr>
      ) : (
        (users.data || [])?.map((user, idx) => (
          <tr
            key={`${user.email}_${idx}`}
            onClick={() => viewDetailModal(user)}
          >
            <td>
              {nameConversionLanguage(user.firstName, user.lastName, t('lang'))}
            </td>
            <td>{t(convertGender(user.gender))}</td>
            <td>{user.phone}</td>
            <td>{user.email}</td>
            <td>{countDuplicateEmails(user.email)}</td>
            <td>{user.birth}</td>
          </tr>
        ))
      )}
    </tbody>
  );
};

export default CandidateTableBody;
