import React from 'react';
import BasicInput from 'components/input/BasicInput';
import BasicSelect from 'components/select/BasicSelect';
import BasicFile from 'components/input/BasicFile';
import ApplyAgreeRadio from './ApplyAgreeRadio';

const ApplyInputBox = ({
  inputType,
  placeholder,
  label,
  required,
  name,
  value,
  isSelectBox,
  handleInput,
  menus,
  icon,
  className,
  isFileInput,
  caution,
  isRadio,
  notAllow,
  disabled,
  defaultText,
}) => {
  return (
    <>
      {isSelectBox ? (
        <BasicSelect
          menus={menus}
          value={value}
          label={label}
          name={name}
          onChange={handleInput}
          required={required}
          className={className && className}
          caution={caution}
          disabled={disabled}
        />
      ) : isFileInput ? (
        <BasicFile
          label={label}
          required={required}
          name={name}
          value={value}
          onChange={handleInput}
          icon={icon}
          placeholder={placeholder}
          caution={caution}
          disabled={disabled}
        />
      ) : isRadio ? (
        <ApplyAgreeRadio
          value={value}
          onChange={handleInput}
          label={label}
          name={name}
          required={required}
        />
      ) : (
        <BasicInput
          inputType={inputType}
          placeholder={placeholder}
          label={label}
          required={required}
          name={name}
          value={value}
          onChange={handleInput}
          icon={icon}
          notAllow={notAllow}
          caution={caution}
          defaultText={defaultText}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default ApplyInputBox;
