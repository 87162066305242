import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon/arrow-right.svg';
import { ReactComponent as EyeIcon } from 'assets/images/icon/eye.svg';
import { useAuth, validatePassword } from 'utils/auth';
import { FILTER_ITEMS } from 'constants/members/filter';
import { toast } from 'utils/notify';
import { CircularProgress } from '@mui/material';
import MaterialInput from 'components/input/MaterialInput';
import MaterialSelect from 'components/select/MaterialSelect';
import MainLogo from 'components/logo/MainLogo';
import * as Sentry from '@sentry/react';

const ProcessInfo = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('authToken');
  const email = searchParams.get('email');
  const ouid = searchParams.get('ouid');
  const memberType = searchParams.get('memberType');
  const account = window.localStorage.getItem('munch_skill_account') || 'main';

  const colorArr = [
    'light-green',
    'orange',
    'indigo',
    'purple',
    'pink',
    'black',
    'green',
    'blue',
  ];
  const randomColor = colorArr[Math.floor(Math.random() * colorArr.length)];

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [workEmail, setWorkEmail] = useState(email || '');
  const [jobFunction, setJobFunction] = useState('');
  const [team, setTeam] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [workspace, setWorkspace] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let flag = true;

    if (!firstName) {
      flag = false;
    }
    if (!lastName) {
      flag = false;
    }
    if (!jobFunction) {
      flag = false;
    }
    if (!team) {
      flag = false;
    }
    if (!workEmail) {
      flag = false;
    }
    if (!phoneNumber) {
      flag = false;
    }
    if (!password) {
      flag = false;
    }
    if (!passwordConfirm) {
      flag = false;
    }
    if (!workspace) {
      flag = false;
    }

    setCanSubmit(flag);
  }, [
    firstName,
    lastName,
    jobFunction,
    team,
    workEmail,
    phoneNumber,
    password,
    passwordConfirm,
    workspace,
  ]);

  const onDoneClickHander = () => {
    if (!authToken) return;

    if (password.length < 8) {
      return toast(t('toast.desc.pw-condition'));
    }

    if (!validatePassword(password)?.success) {
      switch (validatePassword(password).payload?.error?.message) {
        case 'no_lower_case':
          return toast(t('toast.desc.no-lower-case'));
        case 'no_upper_case':
          return toast(t('toast.desc.no-upper-case'));
        case 'no_number':
          return toast(t('toast.desc.no-number'));
        case 'no_special_character':
          return toast(t('toast.desc.no-spacial-character'));
        case 'has_no_allowance_special_character':
          return toast(t('toast.desc.no-allowance-character'));
      }
      return;
    }

    if (password !== passwordConfirm) {
      return toast(t('toast.desc.unmatched-pw'));
    }

    setIsLoading(true);

    auth.memberSignup(
      {
        authToken,
        firstName,
        lastName,
        jobFunction: jobFunction.key,
        team: team.key,
        ownerUid: ouid,
        memberType: Number(memberType),
        phoneNumber,
        password,
        workspace: workspace.key,
        account,
        randomColor,
        disabled: false,
      },
      () => {
        setIsLoading(false);
        toast('회원가입이 완료되었습니다.');
        navigate(`/${account}/auth/signin`);
      },
      (e) => {
        Sentry.captureException(e);
        setIsLoading(false);
      }
    );
  };

  return (
    <main className="pages-auth-signup-process-info">
      <section className="logo-container">
        <MainLogo className="logo" type="signup" />
      </section>
      <div className="explain">
        <h2 className="explain-main fs24">
          {t('귀하의 정보를 등록해주세요.')}
        </h2>
      </div>
      <section className="form">
        <MaterialInput
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder={`${t('First name')} *`}
        />
        <MaterialInput
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder={`${t('Last name')} *`}
        />
        <MaterialInput
          value={workEmail}
          onChange={(e) => setWorkEmail(e.target.value)}
          placeholder={`${t('Work Email')} *`}
          disabled
        />
        <MaterialSelect
          className="form-job"
          title="Job level"
          value={jobFunction}
          onChange={setJobFunction}
          items={FILTER_ITEMS.jobFunction}
        />
        <MaterialSelect
          className="form-team"
          title="Team"
          value={team}
          onChange={setTeam}
          items={FILTER_ITEMS.team}
        />
        <MaterialInput
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder={`${t('Phone number')} *`}
        />
        <MaterialInput
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder={`${t('Password')} *`}
          icon={<EyeIcon />}
        />
        <MaterialInput
          value={passwordConfirm}
          type="password"
          onChange={(e) => setPasswordConfirm(e.target.value)}
          placeholder={`${t('Confirm password')} *`}
          icon={<EyeIcon />}
        />
        <MaterialSelect
          className="form-work-place"
          title="Location"
          value={workspace}
          onChange={setWorkspace}
          items={FILTER_ITEMS.workspace}
        />
        <section className="form-buttons">
          <button
            className={`form-buttons-go-next common-button fsbtn16 ${
              canSubmit && 'active'
            }`}
            onClick={canSubmit ? onDoneClickHander : null}
          >
            {t('btn.ok')}
            {isLoading ? (
              <CircularProgress size={25} color="grey" />
            ) : (
              <ArrowRightIcon />
            )}
          </button>
        </section>
      </section>
    </main>
  );
};

ProcessInfo.propTypes = {};

export default ProcessInfo;
