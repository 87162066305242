import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import { useOutletContext } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { getAssessment } from 'store/slices/assessmentsSlice';

const Edit = () => {
  const { t } = useTranslation();
  const [aid] = useOutletContext();
  const { displayModal } = useModal();
  const [assessment, setAssessmentInfo] = React.useState({});
  const [lang, setLanguage] = React.useState('');

  React.useEffect(() => {
    if (!aid) return;

    executeAction(
      getAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setAssessmentInfo(doc || {});
        setLanguage(doc?.language);
      })
      .catch((e) => {
        console.dir(e);
        setAssessmentInfo({});
      });
  }, [aid]);

  return (
    <section className="pages-protected-assessments-detail-edit">
      <section className="invite-email section">
        <article className="invite-email-title title">
          <h3 className="invite-email-title-main fs20">
            {t('title.edit-invite-email')}
          </h3>
          <p className="invite-email-title-sub fs16">
            {t('desc.edit-invite-email')}
          </p>
        </article>
        <button
          className="common-button fsbtn16 primary active"
          onClick={() =>
            displayModal(
              'assessments/edit/InviteCandidate',
              {
                aid,
                lang,
              },
              {
                frame: 'integration',
              }
            )
          }
        >
          {t('btn.edit')}
        </button>
      </section>
      <section className="remind-email section">
        <article className="remind-email-title title">
          <h3 className="remind-email-title-main fs20">
            {t('title.edit-remind-email')}
          </h3>
          <p className="remind-email-title-sub fs16">
            {t('desc.edit-remind-email')}
          </p>
        </article>
        <button
          className="common-button fsbtn16 primary active"
          onClick={() =>
            displayModal(
              'assessments/edit/RemindCandidate',
              {
                aid,
                lang,
              },
              {
                frame: 'integration',
              }
            )
          }
        >
          {t('btn.edit')}
        </button>
      </section>
      <section className="review-email section">
        <article className="review-email-title title">
          <h3 className="review-email-title-main fs20">
            {t('title.edit-review-email')}
          </h3>
          <p className="review-email-title-sub fs16">
            {t('desc.edit-review-email')}
          </p>
        </article>
        <button
          className="common-button fsbtn16 primary active"
          onClick={() =>
            displayModal(
              'assessments/edit/NoticeUnderReview',
              {
                aid,
                lang,
              },
              {
                frame: 'integration',
              }
            )
          }
        >
          {t('btn.edit')}
        </button>
      </section>
      <section className="reject-email section">
        <article className="reject-email-title title">
          <h3 className="reject-email-title-main fs20">
            {t('title.edit-reject-email')}
          </h3>
          <p className="reject-email-title-sub fs16">
            {t('desc.edit-reject-email')}
          </p>
        </article>
        <button
          className="common-button fsbtn16 primary active"
          onClick={() =>
            displayModal(
              'assessments/edit/NoticeRecruitmentRejection',
              {
                aid,
                lang,
              },
              {
                frame: 'integration',
              }
            )
          }
        >
          {t('btn.edit')}
        </button>
      </section>
      <section className="pass-email section">
        <article className="pass-email-title title">
          <h3 className="pass-email-title-main fs20">
            {t('title.edit-pass-email')}
          </h3>
          <p className="pass-email-title-sub fs16">
            {t('desc.edit-pass-email')}
          </p>
        </article>
        <button
          className="common-button fsbtn16 primary active"
          onClick={() =>
            displayModal(
              'assessments/edit/NoticePassAssessment',
              {
                aid,
                lang,
              },
              {
                frame: 'integration',
              }
            )
          }
        >
          {t('btn.edit')}
        </button>
      </section>
    </section>
  );
};

export default Edit;
