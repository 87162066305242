import { Box, Skeleton } from '@mui/material';
import React from 'react';

const BodyTableRowSkeleton = () => {
  return (
    <tr>
      <td colSpan="7">
        <Box sx={{ width: '100%' }}>
          <Skeleton animation="wave" sx={{ height: 20 }} />
        </Box>
      </td>
    </tr>
  );
};

export default BodyTableRowSkeleton;
