import { Modal } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-modal.svg';
import MembersSlideBox from 'components/recruitment/detail/virtualInterview/MembersSlideBox';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Timestamp } from 'firebase/firestore';
import { memberInvitedCandidate } from 'store/slices/recruitments/membersSlice';
import {
  deleteCandidateAtPreInterviewer,
  setCandidateOfRecruitment,
  updateRecruitmentCandidate,
} from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';
import { executeAction } from 'utils/redux';

const InvitedInterviewer = ({
  open,
  close,
  recruitment,
  currentCandidate,
  setStatus,
  isResetting,
  clickStatus,
  retryInvitedModal,
  setRetryInvitedModal,
}) => {
  const { t } = useTranslation();
  const { getGucciUsersSuccess } = useSelector((state) => state.members);
  const { userInfo } = useSelector((state) => state.auth);

  const [selectedMember, setSelectedMember] = useState({});
  const [stage, setStage] = useState('');
  const [isNotyet, setIsNotyet] = useState(false);
  const [userListWithoutHiringManager, setUserListWithoutHiringManager] =
    useState([]);

  useEffect(() => {
    if (!getGucciUsersSuccess) return;

    const filteredList = getGucciUsersSuccess?.filter(
      (item) => item.memberType !== 20
    );

    const results = filteredList?.filter(
      (item) => item?.ownerUid === userInfo?.ownerUid
    );
    setUserListWithoutHiringManager(results);
  }, [getGucciUsersSuccess]);

  useEffect(() => {
    let endpoint;

    if (
      currentCandidate.recruitProgress === 2 ||
      (currentCandidate.recruitProgress === 3 &&
        currentCandidate.progressSubmit[3].interviewer.invitationPoint)
    ) {
      endpoint = 'first-interview';

      if (
        currentCandidate.recruitProgress === 3 &&
        currentCandidate.progressSubmit[3].interviewer.invitationPoint ===
          'not yet'
      ) {
        setIsNotyet(true);
      }
    } else if (
      currentCandidate.recruitProgress === 3 ||
      (currentCandidate.recruitProgress === 4 &&
        currentCandidate.progressSubmit[4].interviewer.invitationPoint)
    ) {
      endpoint = 'second-interview';

      if (
        currentCandidate.recruitProgress === 4 &&
        currentCandidate.progressSubmit[4].interviewer.invitationPoint ===
          'not yet'
      ) {
        setIsNotyet(true);
      }
    }

    setStage(endpoint);
  }, [currentCandidate]);

  const sendInvitationEmail = () => {
    const progress =
      currentCandidate.recruitProgress === 3 ||
      currentCandidate.recruitProgress === 4
        ? currentCandidate.recruitProgress
        : currentCandidate.recruitProgress + 1;
    const isMoveToPosition = currentCandidate.movePosition || false;

    let interviewHistoryArr = [];
    // 다음 단계 이동, 타직무이동 x, 면접 재진행 x
    // 대면면접 옵션으로 처음 넘어갈 때
    if (
      (clickStatus === 'next' && !isMoveToPosition && !retryInvitedModal) ||
      (currentCandidate.recruitProgress === 3 && progress === 3)
    ) {
      interviewHistoryArr = null;
    }

    // 다음 단계 이동, 면접 재진행 o
    if (clickStatus === 'next' && retryInvitedModal === 'retry') {
      interviewHistoryArr = currentCandidate.progressSubmit[progress]
        .interviewHistory
        ? Object.keys(currentCandidate.progressSubmit[progress].interviewer)
            ?.length <= 0 ||
          !currentCandidate.progressSubmit[progress].interviewer
          ? [...currentCandidate.progressSubmit[progress].interviewHistory]
          : [
              ...currentCandidate.progressSubmit[progress].interviewHistory,
              currentCandidate.progressSubmit[progress],
            ]
        : [currentCandidate.progressSubmit[progress]];
      // 다음 단계 이동, 타직무이동 o, optional로 단계 이동 x
    } else if (
      clickStatus === 'next' &&
      isMoveToPosition &&
      currentCandidate.recruitProgress < 3
    ) {
      interviewHistoryArr =
        currentCandidate.progressSubmit[progress]?.interviewHistory ?? null;
    }

    // 면접 재진행 o
    if (clickStatus === 'retry') {
      interviewHistoryArr = currentCandidate.progressSubmit[progress]
        .interviewHistory
        ? [
            ...currentCandidate.progressSubmit[progress].interviewHistory,
            currentCandidate.progressSubmit[progress],
          ]
        : [currentCandidate.progressSubmit[progress]];
    }

    // 추후에 지정 o
    if (clickStatus === 'notYet') {
      interviewHistoryArr = currentCandidate.progressSubmit[progress]
        .interviewHistory
        ? [...currentCandidate.progressSubmit[progress].interviewHistory]
        : null;
    }

    if (isResetting) {
      executeAction(
        deleteCandidateAtPreInterviewer({
          interviewerId:
            currentCandidate.progressSubmit[currentCandidate.recruitProgress]
              .interviewer.id,
          aid: recruitment?.rid,
          email: currentCandidate?.email,
        })
      ).catch((e) => {
        console.error(e);
      });
    }

    executeAction(
      memberInvitedCandidate({
        uid: selectedMember?.id,
        rid: recruitment?.rid,
        isRecruitment: true,
        email: currentCandidate?.email,
        recruitProgress:
          isNotyet || isResetting || retryInvitedModal === 'retry'
            ? currentCandidate.recruitProgress
            : currentCandidate.recruitProgress + 1,
        stage,
      })
    )
      .unwrap()
      .then(() => {
        // AI인터뷰에서 면접단계로 이동할 때
        // 면접단계로 이동했지만 면접관 미지정일 때
        // 면접단계로 이동한 후에 면접관을 변경할 때
        // 면접 진행 후에 면접 재진행 할 때
        if (
          currentCandidate.recruitProgress === 2 ||
          (currentCandidate.recruitProgress === 3 &&
            currentCandidate.progressSubmit[3].interviewer.invitationPoint ===
              'not yet' &&
            !isResetting) ||
          (currentCandidate.recruitProgress === 3 && isResetting) ||
          (currentCandidate.recruitProgress === 3 &&
            retryInvitedModal === 'retry')
        ) {
          executeAction(
            setCandidateOfRecruitment({
              aid: recruitment?.rid,
              cid: currentCandidate?.email,
              interviewerEmail: selectedMember?.email,
              interviewerFirstName: selectedMember?.firstName,
              interviewerLastName: selectedMember?.lastName,
              candidateFirstName: currentCandidate?.firstName,
              candidateLastName: currentCandidate?.lastName,
              type: 'toInterviewer',
              stage,
              lang: 'ko',
              progressSubmit: {
                2: {
                  reviewer: userInfo.lastName + userInfo.firstName,
                },
                3: {
                  interviewHistory: interviewHistoryArr,
                  interviewSchedule: {},
                  isSubmit: false,
                  comment: '',
                  opinion: '',
                  scoreList: [],
                  interviewer: {
                    id: selectedMember?.id,
                    email: selectedMember?.email,
                    firstName: selectedMember?.firstName,
                    lastName: selectedMember?.lastName,
                    invitationPoint: 'now',
                    sendEmailAt: Timestamp.now(),
                    updatedAt: Timestamp.now(),
                  },
                },
              },
            })
          )
            .then(() => {
              close(false);
              executeAction(
                updateRecruitmentCandidate({
                  aid: recruitment.rid,
                  cid: currentCandidate.email,
                  recruitProgress: 3,
                })
              )
                .unwrap()
                .then(() => {
                  toast('초대를 완료하였습니다.');
                  setStatus('');
                })
                .catch((e) => {
                  console.dir(e);
                });
            })
            .catch((e) => {
              console.error(e);
            });
        } else if (
          // 면접단계에서 면접단계(optional)로 이동할 때
          // 면접단계(optional)로 이동했지만 면접관 미지정일 때
          // 면접단계(optional)로 이동한 후에 면접관을 변경할 때
          // 면접단계(optional) 진행 후에 면접단계(optional) 재진행 할 때
          (currentCandidate.recruitProgress === 3 &&
            retryInvitedModal !== 'retry') ||
          (currentCandidate.recruitProgress === 4 &&
            currentCandidate.progressSubmit[4].interviewer.invitationPoint ===
              'not yet' &&
            !isResetting) ||
          (currentCandidate.recruitProgress === 4 && isResetting) ||
          (currentCandidate.recruitProgress === 4 &&
            retryInvitedModal === 'retry')
        ) {
          executeAction(
            setCandidateOfRecruitment({
              aid: recruitment?.rid,
              cid: currentCandidate?.email,
              interviewerEmail: selectedMember?.email,
              interviewerFirstName: selectedMember?.firstName,
              interviewerLastName: selectedMember?.lastName,
              candidateFirstName: currentCandidate?.firstName,
              candidateLastName: currentCandidate?.lastName,
              type: 'toInterviewer',
              stage,
              lang: 'ko',
              progressSubmit: {
                4: {
                  comment: '',
                  interviewHistory: interviewHistoryArr,
                  isSubmit: false,
                  opinion: '',
                  scoreList: [],
                  interviewSchedule: {},
                  interviewer: {
                    id: selectedMember?.id,
                    email: selectedMember?.email,
                    firstName: selectedMember?.firstName,
                    lastName: selectedMember?.lastName,
                    invitationPoint: 'now',
                    sendEmailAt: Timestamp.now(),
                    updatedAt: Timestamp.now(),
                  },
                },
              },
            })
          )
            .then(() => {
              close(false);
              executeAction(
                updateRecruitmentCandidate({
                  aid: recruitment.rid,
                  cid: currentCandidate.email,
                  recruitProgress: 4,
                })
              )
                .unwrap()
                .then(() => {
                  toast('초대를 완료하였습니다.');
                  setStatus('');
                })
                .catch((e) => {
                  console.dir(e);
                });
            })
            .catch((e) => {
              console.error(e);
            });
        }
      })
      .catch((e) => {
        console.error(e);

        switch (e?.message) {
          case 'already_exists_email':
            toast(t('toast.desc.already-invite'));
            break;
          default:
            break;
        }
      });
  };

  const onPassInvited = () => {
    if (currentCandidate.recruitProgress === 2) {
      executeAction(
        setCandidateOfRecruitment({
          aid: recruitment?.rid,
          cid: currentCandidate?.email,
          progressSubmit: {
            2: {
              reviewer: userInfo.lastName + userInfo.firstName,
            },
            3: {
              interviewer: {
                email: 'Not designated',
                invitationPoint: 'not yet',
              },
            },
          },
        })
      )
        .then(() => {
          close(false);
          executeAction(
            updateRecruitmentCandidate({
              aid: recruitment.rid,
              cid: currentCandidate.email,
              recruitProgress: 3,
            })
          )
            .unwrap()
            .then(() => {
              toast('면접관을 추후 지정합니다.');
              setStatus('');
            })
            .catch((e) => {
              console.dir(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (currentCandidate.recruitProgress === 3) {
      executeAction(
        setCandidateOfRecruitment({
          aid: recruitment?.rid,
          cid: currentCandidate?.email,
          progressSubmit: {
            4: {
              interviewer: {
                email: 'Not designated',
                invitationPoint: 'not yet',
              },
            },
          },
        })
      )
        .then(() => {
          close(false);
          executeAction(
            updateRecruitmentCandidate({
              aid: recruitment.rid,
              cid: currentCandidate.email,
              recruitProgress: 4,
            })
          )
            .unwrap()
            .then(() => {
              toast('면접관을 추후 지정합니다.');
              setStatus('');
            })
            .catch((e) => {
              console.dir(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const closeClickHandler = () => {
    if (setRetryInvitedModal) {
      setRetryInvitedModal('');
    }

    close(false);
  };

  return (
    <Modal open={open}>
      <div className="modal-invited-interviewer">
        <section className="invited-interviewer-container">
          <article className="invited-header-wrapper">
            <h3 className="fs16">{t('title.assign-evaluator')}</h3>
            <span onClick={closeClickHandler}>
              <CloseIcon />
            </span>
          </article>
          <article className="invited-title-wrapper">
            <h2 className="fs20">{recruitment?.recruitmentName}</h2>
          </article>
          <article className="members-wrapper">
            <MembersSlideBox
              label={t('title.select-member')}
              value={selectedMember}
              onChange={setSelectedMember}
              items={userListWithoutHiringManager}
              onClick={sendInvitationEmail}
              onPassInvited={onPassInvited}
              currentCandidate={currentCandidate}
              isResetting={isResetting}
              retryInvitedModal={retryInvitedModal}
            />
          </article>
        </section>
      </div>
    </Modal>
  );
};

export default InvitedInterviewer;
