import {
  getFirestore,
  collection,
  doc,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
  Timestamp,
  deleteField,
  getDoc,
  query,
  orderBy,
  where,
  updateDoc,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as Sentry from '@sentry/react';
import { app } from '../index';

const db = getFirestore(app);
const functions = getFunctions(app, 'asia-northeast1');

export const _createRecruitment = ({ payload }) =>
  new Promise(async (resolve, reject) => {
    const { uid } = payload;
    let { createdAt } = payload;
    createdAt = Timestamp.now();

    try {
      const docRef = await addDoc(collection(db, 'users', uid, 'assessments'), {
        ...payload,
        createdAt,
      });

      return resolve(docRef);
    } catch (e) {
      return reject(e);
    }
  });

/**
 * main user
 *  - Existing assessments are added when you create a job posting.
 */
export const _updateRecruitment = ({
  uid,
  rid,
  isSchedule,
  startPeriodSchedule,
  endPeriodSchedule,
  isRecruitmentArchived,
}) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    rid = rid || '';
    isSchedule = isSchedule;
    startPeriodSchedule = startPeriodSchedule || 0;
    endPeriodSchedule = endPeriodSchedule || 0;
    isRecruitmentArchived = isRecruitmentArchived || false;

    try {
      if (!uid || !rid) throw new Error('empty_field');
      const docRef = doc(db, 'users', uid, 'assessments', rid);
      await setDoc(
        docRef,
        {
          isSchedule,
          startPeriodSchedule,
          endPeriodSchedule,
          isRecruitmentArchived,
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

/**
 * main user
 *  - get recruitments list
 *  - query whereEqualTo('isRecruitment', true)
 */
export const _getRecruitments = ({ uid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const assessmentsRef = collection(db, 'users', uid, 'assessments');
      const q = query(assessmentsRef, where('isRecruitment', '==', true));
      const querySnapshot = await getDocs(q);
      const newDocs = new Array();
      querySnapshot.forEach((doc) => {
        newDocs.push({
          rid: doc.id,
          ...doc.data(),
        });
      });
      newDocs.sort((a, b) => b.createdAt - a.createdAt);

      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getRecruitment = ({ uid, aid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          rid: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('no_data');
    } catch (e) {
      return reject(e);
    }
  });

export const _getCandidatesOfRecruitment = ({ uid, aid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates'
      );
      const q = query(collectionRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const docs = new Array();
      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, ...doc.data() });
      });
      return resolve(docs);
    } catch (e) {
      Sentry.captureException(e, {extra: {uid, aid}});
      return reject(e);
    }
  });

export const _setRecruitment = ({ uid, aid, data }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      await setDoc(
        docRef,
        {
          ...data,
          updatedAt: Timestamp.now(),
        },
        { merge: true }
      );
      return resolve(true);
    } catch (e) {
      Sentry.captureException(e);
      return reject(e);
    }
  });

export const _updateSchedule = ({
  uid,
  aid,
  isSchedule,
  startPeriodSchedule,
  endPeriodSchedule,
}) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    aid = aid || '';
    isSchedule = isSchedule;
    startPeriodSchedule = startPeriodSchedule || '';
    endPeriodSchedule = endPeriodSchedule || '';
    try {
      if (!uid || !aid) throw new Error('empty_field');
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      await setDoc(
        docRef,
        {
          isSchedule,
          startPeriodSchedule,
          endPeriodSchedule,
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      Sentry.captureException(e);
      return reject(e);
    }
  });

/**
 * main user
 *  - update recruitment in candidate
 */
export const _updateRecruitmentCandidate = ({
  uid,
  aid,
  cid,
  recruitProgress,
  finalHiringStep,
  movePosition,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );

      if (movePosition) {
        await setDoc(
          docRef,
          {
            movePosition,
            recruitProgress,
            updatedAt: Timestamp.now(),
          },
          {
            merge: true,
          }
        );
      }

      if (finalHiringStep) {
        await setDoc(
          docRef,
          {
            recruitProgress,
            finalHiringStep,
            updatedAt: Timestamp.now(),
          },
          {
            merge: true,
          }
        );
      } else {
        await setDoc(
          docRef,
          {
            recruitProgress,
            updatedAt: Timestamp.now(),
          },
          {
            merge: true,
          }
        );
      }
      return resolve(true);
    } catch (e) {
      Sentry.captureException(e);
      return reject(e);
    }
  });

export const _addRecruitmentCandidate = ({
  uid,
  rid,
  email,
  firstName,
  lastName,
  payload,
}) =>
  new Promise(async (resolve, reject) => {
    const { progressSubmit, movePosition, otherPositionList, recruitProgress } =
      payload;

    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        rid,
        'candidates',
        email
      );

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        throw new Error('already_exists_email');
      }

      await setDoc(
        docRef,
        {
          progress: -1,
          recruitProgress,
          condition: 0,
          firstName,
          lastName,
          email,
          movePosition: movePosition || false,
          otherPositionList: otherPositionList || [],
          createdAt: Timestamp.now(),
          progressSubmit,
        },
        {
          merge: true,
        }
      );

      return resolve(true);
    } catch (e) {
      Sentry.captureException(e);
      return reject(e);
    }
  });

export const _deleteFieldRecruitmentCandidate = ({ ouid, rid, email, newCandidateData}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        ouid,
        'assessments',
        rid,
        'candidates',
        email
      );

      await setDoc(docRef, {
          ...newCandidateData
      });

      return resolve(true);
    } catch (e) {
      Sentry.captureException(e);
      return reject(e);
    }
  });

export const _deleteAssessmentResultRecruitmentCandidate = ({ ouid, rid, email}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        ouid,
        'assessments',
        rid,
        'candidates',
        email
      );

      await updateDoc(docRef, {
        answeredAt: deleteField(),
        invitedAt: deleteField(),
        updatedAt: deleteField(),
        results: deleteField(),
        testNumber: deleteField()
      });

      return resolve(true);
    } catch (e) {
      Sentry.captureException(e);
      return reject(e);
    }
  });

export const _deleteInterviewerRecruitmentCandidate = ({ uid, rid, email }) =>
  new Promise(async (resolve, reject) => {
    try {
      const interviewerDocRef = doc(
        db,
        'users',
        uid,
        'assessments',
        rid,
        'candidates',
        email
      );

      await deleteDoc(interviewerDocRef);

      return resolve(true);
    } catch (e) {
      Sentry.captureException(e);
      return reject(e);
    }
  });

export const _editRecruitmentCandidateEmail = ({ uid, rid, email, candidateInfo
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        rid,
        'candidates',
        email
      );

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        throw new Error('already_exists_email');
      }

      await setDoc(
        docRef,
        {
          ...candidateInfo,
          email,
          id: email
        },
        {
          merge: true,
        }
      );

      return resolve(true);
    } catch (e) {
      Sentry.captureException(e);
      return reject(e);
    }
  });

export const _setCandidateOfRecruitment = ({ uid, aid, cid, progressSubmit }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          progressSubmit,
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      Sentry.captureException(e);
      return reject(e);
    }
  });

export const _deleteCandidateOfRecruitment = ({ uid, aid, email }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        email
      );
      await deleteDoc(docRef);
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });


export const _getCandidateOfRecruitment = ({ uid, rid, cid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        rid,
        'candidates',
        cid
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _deleteCandidateAtPreInterviewer = ({
  interviewerId,
  aid,
  email,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        interviewerId,
        'assessments',
        aid,
        'candidates',
        email
      );
      await deleteDoc(docRef);
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });
