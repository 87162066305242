import React from 'react';
/*
    KHAI-P 세부 결과 그래프
    myscore와 bigdatascore를 이용해서 그래프를 그리고 타이틀과 점수를 표시한다.
*/

const KHAIPDetailGraph = ({
  myscore,
  bigdatascore,
  tooltip,
  title,
  title_class,
  score_class,
}) => (
  <div className="graph">
    <div className="inner">
      <div className="graph">
        <div className="my-score" style={{ height: `${myscore}%` }} />
        <div className="bigdata-score" style={{ height: `${bigdatascore}%` }} />
        <div className="tooltip fs12">
          <ul style={{ marginLeft: '10px' }}>
            {(Object.keys(tooltip) || []).map((tip, index) => (
              <li key={index} className="fs12">
                {tooltip?.[tip]}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    <div className="desc">
      <div className={`title ${title_class}`}>{title}</div>
      <div className={`score ${score_class}`}>{myscore}</div>
    </div>
  </div>
);

export default KHAIPDetailGraph;
