export const dateFormat = (date) => {
  if (!date) return '';

  const dateTime = new Date(date);
  let month = dateTime.getMonth() + 1;
  let day = dateTime.getDate();
  let hour = dateTime.getHours();
  let minute = dateTime.getMinutes();

  month = month >= 10 ? month : `0${month}`;
  day = day >= 10 ? day : `0${day}`;
  hour = hour >= 10 ? hour : `0${hour}`;
  minute = minute >= 10 ? minute : `0${minute}`;

  return `${dateTime.getFullYear()}-${month}-${day} ${hour}:${minute}`;
};

export const getDaysElapsed = (specificTime) => {
  const now = new Date();
  const specificDate = new Date(specificTime);
  const diffTime = Math.abs(now - specificDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays - 1;
};
