import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialInput from 'components/input/MaterialInput';
import { useAuth } from 'utils/auth';
import { useModal } from 'utils/modal';
import { validateEmail } from 'utils/auth';
import { executeAction } from 'utils/redux';
import {
  sendEmailToCandidate,
  getAssessmentOfCurrentUser,
  getCandidatesOfAssessments,
} from 'store/slices/assessmentsSlice';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'utils/notify';
import { writeTextToClipboard } from 'utils/string';
import { CircularProgress } from '@mui/material';
import { getAssessment } from 'store/slices/assessmentsSlice';
import ApplyInputBox from '../../../../components/apply/ApplyInputBox';

const Invite = () => {
  const { t } = useTranslation();
  const [aid] = useOutletContext();
  const { user, updateUserLimit, userInfo } = useAuth();
  const [doc, setDoc] = React.useState({});
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [testNumber, setTestNumber] = React.useState('');
  const [birth, setBirth] = React.useState('');
  const [objective, setObjective] = React.useState('');
  const [canSend, setCanSend] = React.useState(false);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = React.useState(false);
  const [bulkEmailUpdate, setBulkEmailUpdate] = React.useState(false);
  const [assessment, setAssessmentInfo] = React.useState();
  const [_assessment, _setAssessment] = React.useState();
  const [_candidates, _setCandidates] = React.useState();
  const [lang, setLanguage] = React.useState('');
  const { displayModal } = useModal();

  const birthHandleInput = (value) => {
      const number = value.replace(/[^0-9]/g, '');
      const regexValue = number.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

      if (regexValue.length > 10) {
        return toast(t('toast.desc.10-characters'));
      }
      setBirth(regexValue);
  };

  React.useEffect(() => {
    if (!aid || !userInfo) return;

    executeAction(
      getAssessment({
        aid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setAssessmentInfo(doc || {});
        setLanguage(doc?.language);
      })
      .catch((e) => {
        console.dir(e);
        setAssessmentInfo({});
      });
  }, [aid, userInfo]);

  React.useEffect(() => {
    if (!firstName) {
      return setCanSend(false);
    }

    if (!lastName) {
      return setCanSend(false);
    }

    if (!validateEmail(email)) {
      return setCanSend(false);
    }

    setCanSend(true);
  }, [firstName, lastName, email]);

  /** ======================================
     * 해당 assessment의 candidates를 불러오는 로직
     ======================================== */
  React.useEffect(() => {
    if (!userInfo) return;

    executeAction(
      getAssessmentOfCurrentUser({
        aid,
      })
    )
      .unwrap()
      .then((result) => {
        if (!result?.doc) throw new Error();
        setDoc(result?.doc);

        executeAction(
          getCandidatesOfAssessments({
            assessments: [result?.doc],
          })
        )
          .unwrap()
          .then((doc) => {
            let candidates = doc?.candidates?.[0] || 0;
            _setCandidates([...candidates]);
          });
      })
      .catch((e) => {
        console.dir(e);
      });
  }, [isLoadingSendEmail, bulkEmailUpdate, userInfo]);

  const onInviteEmailBulkButtonClickHandler = () => {
    if (userInfo?.candidateLimit <= 0) {
      displayModal(
        'assessments/ConfirmCreateLimit',
        {
          event: {
            message: 'CreateLimit',
          },
          title: {
            main: t('dialog.title.exceeded-candidate'),
            sub: t('dialog.desc.exceeded-candidate'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    } else {
      displayModal(
        'assessments/InviteEmailBulk',
        {
          aid,
          userInfo,
          updateUserLimit,
          candidates: _candidates,
          setBulkEmailUpdate,
        },
        {
          frame: 'integration',
        }
      );
    }
  };

  const onInviteEmailButtonKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!isLoadingSendEmail && canSend) {
        onInviteEmailButtonClickHandler(event);
      }
    }
  };

  const onInviteEmailButtonClickHandler = () => {
    if (userInfo === null) return;

    if (userInfo?.candidateLimit < 0) {
      displayModal(
        'assessments/ConfirmCreateLimit',
        {
          event: {
            message: 'CreateLimit',
          },
          title: {
            main: t('dialog.title.exceeded-candidate'),
            sub: t('dialog.desc.exceeded-candidate'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    } else {
      setIsLoadingSendEmail(true);
      executeAction(
        sendEmailToCandidate({
          type: 'send',
          aid,
          firstName,
          lastName,
          email,
          testNumber,
          birth,
          objective,
          lang,
        })
      )
        .unwrap()
        .then((result) => {
          updateUserLimit({
            assessmentLimit: userInfo.assessmentLimit,
            candidateLimit: Math.abs(userInfo.candidateLimit - 1),
          }).catch((e) => {
            console.dir(e);
          });

          setIsLoadingSendEmail(false);
          setFirstName('');
          setLastName('');
          setEmail('');
          setObjective('');
          setBirth('');
          setTestNumber('');
          toast(t('toast.desc.success-invite'));
        })
        .catch((e) => {
          console.dir(e);
          setIsLoadingSendEmail(false);
          switch (e?.message) {
            case 'already_exists_email':
              toast(t('toast.desc.already-invite'));
              break;
            default:
              break;
          }
        });
    }
  };

  const onCopyLinkClickHandler = () => {
    const text = `${
      process.env.REACT_APP_ASSESSMENT_HOST
    }/testtaker/auth/redirect?type=publicinvitation&uid=${
      userInfo?.ownerUid ? userInfo?.ownerUid : user?.uid
    }&aid=${aid}`;
    writeTextToClipboard(text)
      .then(() => {
        toast(t('toast.desc.copy-link'));
      })
      .catch(console.dir);
  };

  return (
    <section className="pages-protected-detail-invite">
      <section className="email">
        <div className="email-title fs20">{t('title.invite-email')}</div>
        <p className="email-description fs16">{t('desc.invite-email')}</p>
        <MaterialInput
          className="firstname-input"
          placeholder={`${t('text-field.label.first-name')} *`}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          onKeyPress={onInviteEmailButtonKeyPress}
        />
        <MaterialInput
          className="lastname-input"
          placeholder={`${t('text-field.label.last-name')} *`}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onKeyPress={onInviteEmailButtonKeyPress}
        />
        <MaterialInput
          className="email-input"
          placeholder={`${t('text-field.label.email')} *`}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={onInviteEmailButtonKeyPress}
        />
        {userInfo?.account === 'khai' && <><MaterialInput
          className='email-input'
          placeholder={`${t('text-field.label.test-number')}`}
          value={testNumber}
          onChange={(e) => setTestNumber(e.target.value)}
          onKeyPress={onInviteEmailButtonKeyPress}
        />
          <MaterialInput
          className="email-input"
          placeholder={`${t('field.birth')} (${t('text-field.placeholder.birth')})`}
        value={birth}
        onChange={(e) => birthHandleInput(e.target.value)}
        onKeyPress={onInviteEmailButtonKeyPress}
      />
      <MaterialInput
        className='email-input'
        placeholder={`${t('text-field.label.objective')}`}
        value={objective}
        onChange={(e) => setObjective(e.target.value)}
        onKeyPress={onInviteEmailButtonKeyPress}
      /></>}
        <button
          className={`email-invite common-button fsbtn16 primary ${
            !isLoadingSendEmail && canSend && 'active'
          } ${isLoadingSendEmail && 'pending'}`}
          onClick={
            !isLoadingSendEmail && canSend
              ? onInviteEmailButtonClickHandler
              : null
          }
        >
          {isLoadingSendEmail ? (
            <CircularProgress color="grey" size={25} />
          ) : (
            t('btn.invite-email')
          )}
        </button>
        <p className="email-bulk-description fs16">
          {t('desc.invite-email-bulk')}
        </p>
        <button
          onClick={onInviteEmailBulkButtonClickHandler}
          className="email-invite-bulk common-button fsbtn16 gray active"
        >
          {t('btn.invite-email-bulk')}
        </button>
      </section>
      <section className="link">
        <h3 className="link-title fs20">{t('title.invite-link')}</h3>
        <p className="link-description fs16">{t('desc.invite-link')}</p>
        <button
          className="link-copy common-button fsbtn16 primary active"
          onClick={onCopyLinkClickHandler}
        >
          {t('btn.invite-link')}
        </button>
      </section>
    </section>
  );
};

export default Invite;
