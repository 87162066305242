import React from 'react';

const MembersTableHead = ({ heads }) => {
  return (
    <thead>
      <tr>{heads && heads?.map((head) => <th key={head}>{head}</th>)}</tr>
    </thead>
  );
};

export default MembersTableHead;
