import React from 'react';
import { useTranslation } from 'react-i18next';

const ObpiQuestion = ({ questions }) => {
  const { t } = useTranslation();

  return (
    <article className="obpi-question-contents-list">
      {questions &&
        questions?.map((question, index) => (
          <aside className="obpi-question-contents-list-items" key={index}>
            <div className="obpi-question-contents-list-title">
              <h6 className="fs18">
                {question?.testInfo?.[`question_${t('lang')}`]}
              </h6>
            </div>
            <div className="obpi-question-contents-list-choice">
              <ul>
                {question?.testInfo?.[`answer_${t('lang')}`]?.map(
                  (answer, idx) =>
                    answer !== '' ? (
                      <li className="fs16" key={idx}>
                        {answer}
                      </li>
                    ) : (
                      <></>
                    )
                )}
              </ul>
            </div>
          </aside>
        ))}
    </article>
  );
};

export default ObpiQuestion;
