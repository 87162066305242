export const FILTER_ITEMS = {
  membersType: [
    {
      key: 5,
      i18nKey: 'menu.workspace-owner',
    },
    {
      key: 10,
      i18nKey: 'menu.recruiter',
    },
    {
      key: 15,
      i18nKey: 'menu.interviewer',
    },
    {
      key: 20,
      i18nKey: 'menu.hiring-manager',
    },
  ],
  team: [
    {
      key: 'hr',
      i18nKey: 'menu.hr-manager',
    },
    {
      key: 'finance',
      i18nKey: 'menu.finance',
    },
    {
      key: 'gucci9',
      i18nKey: 'menu.gucci-9',
    },
    {
      key: 'store',
      i18nKey: 'menu.store',
    },
  ],
  workspace: [
    {
      key: 'seoul',
      i18nKey: 'menu.seoul',
    },
    {
      key: 'gyeonggi_incheon',
      i18nKey: 'menu.gyeonggi-incheon',
    },
    {
      key: 'daejeon',
      i18nKey: 'menu.daejeon',
    },
    {
      key: 'daegu',
      i18nKey: 'menu.daegu',
    },
    {
      key: 'gwangju',
      i18nKey: 'menu.gwangju',
    },
    {
      key: 'busan',
      i18nKey: 'menu.busan',
    },
  ],
  status: [
    {
      key: 'active',
      i18nKey: 'menu.active',
    },
    {
      key: 'suspended',
      i18nKey: 'menu.suspended',
    },
    // {
    //   key: 'requested',
    //   i18nKey: 'menu.requested',
    // },
  ],
  jobFunction: [
    {
      key: 'junior',
      i18nKey: 'menu.input.junior',
    },
    {
      key: 'senior',
      i18nKey: 'menu.input.senior',
    },
    {
      key: 'manager',
      i18nKey: 'menu.input.manager',
    },
    {
      key: 'director',
      i18nKey: 'menu.input.director',
    },
    {
      key: 'c-level',
      i18nKey: 'menu.input.c-level',
    },
  ],
};
