import React from 'react';
import RecruitmentListBox from '../recruitmentLeft/RecruitmentListBox';

const RecruitmentLeftContents = ({ assessments, filteredRecruitments }) => {
  return (
    <article className="recruit-left-container">
      <RecruitmentListBox
        assessments={assessments}
        filteredRecruitments={filteredRecruitments}
      />
    </article>
  );
};

export default RecruitmentLeftContents;
