import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCandidatesOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { executeAction } from 'utils/redux';
import RecruitmentStatusTab from '../RecruitmentStatusTab';
import VirtualResult from './VirtualResult';

const VirturalDetail = ({ recruitment, currentCandidate, processLength }) => {
  const { userInfo } = useSelector((state) => state.auth);

  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) return;

    if (refreshing) {
      const { rid } = recruitment;
      const { memberType } = userInfo;

      if(!rid || !memberType) return;

      executeAction(getCandidatesOfRecruitment({ aid: rid, memberType }))
        .then(({ doc }) => {
          setRefreshing(false);
        })
        .catch((e) => {
          console.dir(e);
          setRefreshing(false);
        });
    }
  }, [recruitment, refreshing, userInfo]);

  return (
    <div className="wrapper virtual-detail">
      <section className="results-section">
        {currentCandidate?.recruitProgress === 2 &&
          userInfo?.memberType !== 20 && (
            <article className="button-container">
              <RecruitmentStatusTab
                recruitment={recruitment}
                currentCandidate={currentCandidate}
                processLength={processLength}
              />
            </article>
          )}
        <VirtualResult
          cid={currentCandidate?.email}
          rid={recruitment?.rid}
          userInfo={userInfo}
          processLength={processLength}
          currentRecruitment={recruitment}
          setRefreshing={setRefreshing}
          currentCandidate={currentCandidate}
          reportType="current"
        />

        {currentCandidate?.otherPositionList.length > 0 &&
          currentCandidate?.otherPositionList.map((rid) => {
            return (
              <VirtualResult
                key={rid}
                cid={currentCandidate.email}
                rid={rid}
                userInfo={userInfo}
                processLength={processLength}
                setRefreshing={setRefreshing}
                reportType="history"
              />
            );
          })}
      </section>
    </div>
  );
};

export default VirturalDetail;
