import React, { useState } from 'react';
import SuccessfulStore from 'modals/recruitments/SuccessfulStore';
import OfferInformation from 'modals/recruitments/OfferInformation';
import { Trans, useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import { executeAction } from '../../../utils/redux';
import {
  setCandidateOfRecruitment,
  updateRecruitmentCandidate,
} from '../../../store/slices/recruitments/recruitmentsSlice';
import InvitedInterviewer from '../../../modals/recruitments/InvitedInterviewer';
import { toast } from '../../../utils/notify';
import ChangeStatusModal from './ChangeStatusModal';
import OtherPositionModal from '../../../modals/recruitments/OtherPositionModal';

const RecruitmentStatusTab = ({
  recruitment,
  currentCandidate,
  processLength,
  isInterview,
}) => {
  const modalCommentArr = [
    {
      key: 'btn.move-to-next-stage',
      i18nKey: 'dialog.title.move-to-next-stage',
    },
    { key: 'btn.kiv', i18nKey: 'dialog.title.kiv' },
    {
      key: 'btn.suggest-other-position',
      i18nKey: 'dialog.title.suggess-other-position',
    },
    { key: 'btn.interview-retry', i18nKey: 'dialog.title.interview-retry' },
    { key: 'btn.giveup', i18nKey: 'dialog.title.giveup' },
    { key: 'btn.reject', i18nKey: 'dialog.title.reject' },
  ];
  const detailStateList =
    processLength - currentCandidate.recruitProgress < 4
      ? ['btn.move-to-next-stage', 'btn.reject']
      : isInterview
      ? [
          'btn.move-to-next-stage',
          'btn.kiv',
          'btn.suggest-other-position',
          'btn.interview-retry',
          'btn.giveup',
          'btn.reject',
        ]
      : [
          'btn.move-to-next-stage',
          'btn.kiv',
          'btn.suggest-other-position',
          'btn.reject',
        ];

  const { t } = useTranslation();
  const [status, setStatus] = useState('');
  const [open, setOpen] = React.useState(false);
  const [invitedInterviewer, setInvitedInterviewer] = useState(false);
  const [changeRecruitment, setChangeRecruitment] = useState(false);
  const [successfulStore, setSuccessfulStore] = useState(false);
  const [offerInformation, setOfferInformation] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [retryInvitedModal, setRetryInvitedModal] = useState('');
  const onClickDisagree = () => {
    setOpen(false);
    setStatus('');
  };

  const onClickAgree = () => {
    setOpen(false);
    let newProgress;
    let finalHiringStep = 0;
    let rejectType = '';

    if (status === t('btn.move-to-next-stage')) {
      newProgress = currentCandidate.recruitProgress + 1;
    }

    if (status === t('btn.reject')) {
      rejectType = 'reject';
      newProgress = -1;
      finalHiringStep = currentCandidate?.recruitProgress;
    }

    if (status === t('btn.giveup')) {
      rejectType = 'giveup';
      newProgress = -1;
      finalHiringStep = currentCandidate?.recruitProgress;
    }

    if (status === t('btn.kiv')) {
      executeAction(
        setCandidateOfRecruitment({
          aid: recruitment?.rid,
          cid: currentCandidate?.email,
          progressSubmit: {
            [currentCandidate.recruitProgress]: {
              KIV: true,
            },
          },
        })
      ).catch((error) => {
        console.error(error);
      });

      return;
    }

    if (status === t('btn.interview-retry')) {
      setInvitedInterviewer(true);
      setRetryInvitedModal('retry');
      return;
    }

    if (status === t('btn.suggest-other-position')) {
      setChangeRecruitment(true);
      return;
    }

    if (newProgress !== -1 && currentCandidate?.recruitProgress === 2) {
      setInvitedInterviewer(true);
      return;
    }

    if (newProgress !== -1 && processLength === 8) {
      if (currentCandidate.recruitProgress === 3) {
        setInvitedInterviewer(true);
        return;
      }
    }

    if (
      (processLength === 8 && newProgress === 5) ||
      (processLength === 7 && newProgress === 4)
    ) {
      setSuccessfulStore(true);
      return;
    }

    if (
      (processLength === 8 && newProgress === 6) ||
      (processLength === 7 && newProgress === 5)
    ) {
      setOfferInformation(true);
      return;
    }

    if (
      (processLength === 7 && newProgress === 3) ||
      (processLength === 8 && newProgress === 4)
    )
      return;

    executeAction(
      updateRecruitmentCandidate({
        aid: recruitment.rid,
        cid: currentCandidate.email,
        recruitProgress: newProgress,
        firstName: currentCandidate?.firstName,
        lastName: currentCandidate?.lastName,
        job: recruitment?.recruitmentName,
        lang: 'ko',
        phoneNumber: currentCandidate?.progressSubmit[1].phoneNumber,
        processLength,
        finalHiringStep,
        rejectType,
      })
    )
      .unwrap()
      .then(() => {
        if (newProgress === -1)
          toast(
            <Trans
              t={t}
              parent="p"
              i18nKey="toast.desc.reject"
              values={{
                firstName: currentCandidate?.firstName,
                lastName: currentCandidate?.lastName,
              }}
            />
          );
        else
          toast(
            <Trans
              t={t}
              parent="p"
              i18nKey="toast.desc.movo-to-next"
              values={{
                firstName: currentCandidate?.firstName,
                lastName: currentCandidate?.lastName,
              }}
            />
          );
        setStatus('');
      })
      .catch((e) => {
        console.dir(e);
      });
  };

  const onClickStatusTab = (e) => {
    const { textContent } = e.target;

    setStatus(textContent);
    setOpen(true);
  };

  return (
    <>
      <div className="tab-container">
        {detailStateList.map((item, idx) => {
          return (
            <div
              key={idx}
              onClick={onClickStatusTab}
              className={status === item ? 'active' : ''}
              style={
                isInterview
                  ? item === 'btn.reject' || item === 'btn.giveup'
                    ? {
                        backgroundColor: '#fb5e54',
                      }
                    : {
                        backgroundColor: '#3294ee',
                      }
                  : null
              }
            >
              {t(item)}
            </div>
          );
        })}
      </div>
      <ChangeStatusModal
        open={open}
        onClickDisagree={onClickDisagree}
        onClickAgree={onClickAgree}
        main={
          status !== '' &&
          modalCommentArr.find((item) => t(item.key) === status)?.i18nKey
        }
        sub={
          status === 'KIV'
            ? t('dialog.desc.not-changed-state')
            : t('dialog.desc.no-turning-back')
        }
      />
      {/* 면접관 초대 모달 */}
      {invitedInterviewer && (
        <InvitedInterviewer
          open={invitedInterviewer}
          close={setInvitedInterviewer}
          recruitment={recruitment}
          currentCandidate={currentCandidate}
          setStatus={setStatus}
          isResetting={isResetting}
          retryInvitedModal={retryInvitedModal}
          setRetryInvitedModal={setRetryInvitedModal}
          clickStatus="next"
        />
      )}
      {/* 타직무 제안 모달 */}
      {changeRecruitment && (
        <OtherPositionModal
          open={changeRecruitment}
          close={setChangeRecruitment}
          recruitment={recruitment}
          currentCandidate={currentCandidate}
        />
      )}
      {/* 증빙서류 제출 모달 */}
      {successfulStore && (
        <SuccessfulStore
          open={successfulStore}
          close={setSuccessfulStore}
          recruitment={recruitment}
          currentCandidate={currentCandidate}
        />
      )}
      {/* offer 제안 모달 */}
      {offerInformation && (
        <OfferInformation
          open={offerInformation}
          close={setOfferInformation}
          recruitment={recruitment}
          currentCandidate={currentCandidate}
        />
      )}
    </>
  );
};

export default RecruitmentStatusTab;
