import { Switch } from '@mui/material';
import MaterialDateTimePicker from 'components/common/MaterialDateTimePicker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const DatePickerContainer = ({
  isSchedule,
  setIsSchedule,
  startPeriodSchedule,
  setStartPeriodSchedule,
  endPeriodSchedule,
  setEndPeriodSchedule,
}) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);

  const onPeriodChangeHandler = (event) => {
    event.preventDefault();
    const newPeriod = !isSchedule;
    setIsSchedule(newPeriod);
  };

  return (
    <div className="date-picker-contents">
      <div className={`select ${isSchedule === true ? 'selected' : ''}`}>
        <p className="fs16">{t('title.turn-on-dates')}</p>
        <Switch
          className="date-switch"
          checked={isSchedule}
          inputProps={{ 'aria-label': 'ant design' }}
          onClick={(e) =>
            userInfo?.memberType !== 20 ? onPeriodChangeHandler(e) : null
          }
        />
      </div>
      <div className="time-picker-box">
        <div className="time-label">
          <div className="fs14 time-title">{t('text-field.rabel.start')}</div>
          <div className="time-picker-wrapper">
            <MaterialDateTimePicker
              periodDateTime={startPeriodSchedule}
              period={isSchedule}
              setPeriodDateTime={setStartPeriodSchedule}
              disabled={userInfo?.memberType === 20}
            />
          </div>
        </div>
        <div className="time-label">
          <div className="fs14 time-title">{t('text-field.rabel.end')}</div>
          <div className="time-picker-wrapper">
            <MaterialDateTimePicker
              period={isSchedule}
              periodDateTime={endPeriodSchedule}
              setPeriodDateTime={setEndPeriodSchedule}
              disabled={userInfo?.memberType === 20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePickerContainer;
