export const POSITION_DEPTH_1 = [
  {
    id: 'retail_1',
    key: 'retail',
    i18key: 'radio.input.retail',
  },
  {
    id: 'office_1',
    key: 'office',
    i18key: 'radio.input.office',
  },
];

export const POSITION_DEPTH_2 = [
  {
    id: 'junior_2',
    key: 'junior',
    i18key: 'radio.input.junior',
  },
  {
    id: 'senior_2',
    key: 'senior',
    i18key: 'radio.input.senior',
  },
  {
    id: 'team_manager_2',
    key: 'team_manager',
    i18key: 'radio.input.team-manager',
  },
  {
    id: 'director_2',
    key: 'director',
    i18key: 'radio.input.director',
  },
  {
    id: 'other_2',
    key: 'other',
    i18key: 'radio.input.other',
  },
];

export const POSITION_DEPTH_3 = [
  {
    id: 'client_advisor_3',
    key: 'client_advisor',
    i18key: 'radio.input.client-advisor',
  },
  {
    id: 'store_management_3',
    key: 'store_management',
    i18key: 'radio.input.store-management',
  },
  {
    id: 'operation_specialist_3',
    key: 'operation_specialist',
    i18key: 'radio.input.operation-specialist',
  },
  {
    id: 'area_client_engagement_3',
    key: 'area_client_engagement',
    i18key: 'radio.input.area-client-engagement',
  },
  {
    id: 'hr_3',
    key: 'hr',
    i18key: 'radio.input.hr',
  },
  {
    id: 'finance_3',
    key: 'finance',
    i18key: 'radio.input.finance',
  },
  {
    id: 'customer_support_3',
    key: 'customer_support',
    i18key: 'radio.input.customer-support',
  },
  {
    id: 'retail_3',
    key: 'retail',
    i18key: 'radio.input.detail-retail',
  },
  {
    id: 'other_3',
    key: 'other',
    i18key: 'radio.input.detail-other',
  },
];

export const recruitmentProcess = {
  application: {
    isUsed: true,
    label: 'title.process.reviewing-application',
  },
  aiInterview: {
    isUsed: true,
    label: 'title.process.digital-interview',
  },
  firstInterview: {
    isUsed: true,
    label: 'title.process.in-person-interview',
  },
  secondInterview: {
    isUsed: false,
    label: 'title.process.in-person-interview-optional',
  },
  evidence: {
    isUsed: true,
    label: 'title.process.compensation',
  },
  pass: {
    isUsed: true,
    label: 'title.process.offer-letter',
  },
  submission: {
    isUsed: true,
    label: 'title.process.hired',
  },
};
