import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThumbnailIcon } from 'assets/images/icon/thumbnail.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'utils/auth';
import { CircularProgress } from '@mui/material';
import MainLogo from 'components/logo/MainLogo';
import PropTypes from 'prop-types';

const ProcessThumbnail = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    authToken,
    firstName,
    lastName,
    corporation,
    position,
    numEmployee,
    trackingSystem,
    account,
  } = location.state || {};

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLogoLoading, setIsLogoLoading] = React.useState(false);
  const [canDone, setCanDone] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [fileUri, setFileUri] = React.useState('');

  const inputFileRef = React.useRef(null);

  React.useEffect(() => {
    let flag = true;
    if (!fileUri) flag = false;
    setCanDone(flag);
  }, [fileUri]);

  React.useEffect(() => {
    if (!file) return;
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setFileUri(reader?.result);
      setIsLogoLoading(false);
    });
    setIsLogoLoading(true);
    reader.readAsDataURL(file);
  }, [file]);

  const onImageChangeHandler = (event) => {
    setFile(event.target.files[0]);
  };

  const onSkipClickHandler = () => {
    setIsLoading(true);

    auth.signup(
      {
        authToken,
        firstName,
        lastName,
        corporation,
        position,
        numEmployee,
        trackingSystem,
        account,
      },
      () => {
        setIsLoading(false);
        navigate('/');
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const onDoneClickHandler = () => {
    setIsLoading(true);
    auth.signup(
      {
        authToken,
        firstName,
        lastName,
        corporation,
        position,
        numEmployee,
        trackingSystem,
        file,
        account,
      },
      () => {
        setIsLoading(false);
        navigate('/');
      },
      (e) => {
        setIsLoading(false);
      }
    );
  };

  return (
    <main className="pages-auth-signup-process-thumbnail">
      <section className="logo-container">
        <MainLogo className="logo" type="signup" />
      </section>
      <div className="explain">
        <h2 className="explain-main fs24">{t('btn.upload-company-logo')}</h2>
        <h3 className="explain-sub fs16">
          {t('signup-customer-logo.desc.logo')}
        </h3>
      </div>
      <section className="form">
        <article
          className={`form-upload ${fileUri && 'selected'}`}
          onClick={() => {
            inputFileRef?.current?.click();
          }}
        >
          {fileUri ? (
            <img src={fileUri} alt="" />
          ) : (
            <>
              <ThumbnailIcon />
              <span>{t('signup-customer-logo.title.logo')}</span>
            </>
          )}
          <input
            ref={inputFileRef}
            type="file"
            accept="image/*"
            onChange={onImageChangeHandler}
            hidden
          />
        </article>
        <section className="form-buttons">
          <button
            className={`form-buttons-skip common-button fsbtn16 ${
              !isLoading && 'active'
            }`}
            onClick={!isLoading ? onSkipClickHandler : null}
          >
            {t('btn.skip')}
          </button>
          <button
            className={`form-buttons-done common-button fsbtn16 ${
              canDone && !isLoading && 'active'
            } ${isLoading && 'pending'}`}
            onClick={canDone && !isLoading ? onDoneClickHandler : null}
          >
            {t('btn.complete')}
            {isLoading && <CircularProgress color="grey" size={25} />}
          </button>
        </section>
      </section>
    </main>
  );
};

ProcessThumbnail.propTypes = {};

export default ProcessThumbnail;
