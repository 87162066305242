import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import { ReactComponent as LockIcon } from 'assets/images/icon/lock.svg';

const SkillCard = ({
  isCardLoading,
  title,
  contains,
  duration,
  button,
  test,
  isSelected,
  plan,
  enabledSkillList,
}) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();
  const [testType, setTestType] = React.useState(null);
  const [canAdd, setCanAdd] = React.useState(false);

  React.useEffect(() => {
    const test_type = test?.question_type?.replaceAll(' ', '').split(',');
    setTestType(test_type);
  }, [test]);

  React.useEffect(() => {
    const enabled = (enabledSkillList || []).find((x) => x === test.id);

    if (enabledSkillList[0] === 'all') return setCanAdd(true);

    if (enabled !== undefined) {
      setCanAdd(true);
    } else {
      setCanAdd(false);
    }
  }, [enabledSkillList, test, plan]);

  const onActionsClickHandler = (skill) => {
    displayModal(
      'assessments/ObjectiveTestDetail',
      {
        test: skill,
        contains,
      },
      {
        frame: 'full',
        padding: '40px',
      }
    );
  };

  const onTestUserClickHandler = () => {
    displayModal(
      'common/ContactToHelpDesk',
      {},
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  return (
    <article
      className={`components-card-skill-card card ${
        isCardLoading && 'pending'
      } ${isSelected && 'selected'}`}
    >
      <div className="header">
        <div className="types">
          {(testType || []).map((type, idx) => (
            <div
              className={`skill-type fs12 ${type.replaceAll('.', '_')}`}
              key={idx}
            >
              {t(type)}
            </div>
          ))}
        </div>
        <LockIcon
          className="lock"
          onClick={onTestUserClickHandler}
          style={{ display: canAdd === false ? 'block' : 'none' }}
        />
      </div>
      <h3 className="title fs18">{title || test?.category_skill}</h3>
      <ul>
        {(contains || []).slice(0, 4).map((contain, idx) =>
          contain?.length > 0 ? (
            <li className="fs14" key={idx}>
              {contain}
            </li>
          ) : (
            <></>
          )
        )}
      </ul>
      <footer>
        <div className="duration fs14">{`${duration || '10'} ${t(
          'desc.minute'
        )}`}</div>
        <button className="fsbtn16" onClick={() => onActionsClickHandler(test)}>
          {t('btn.details')}
        </button>
        {canAdd === false ? (
          <button
            className="common-button fsbtn16 help"
            onClick={onTestUserClickHandler}
          >
            {t('btn.add')}
          </button>
        ) : (
          button
        )}
      </footer>
    </article>
  );
};

export default SkillCard;
