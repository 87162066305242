import React from 'react';
import RecruitmentList from './RecruitmentList';

const RecruitmentListBox = ({ assessments, filteredRecruitments }) => {
  return (
    <aside className="recruit-left-list-box">
      <RecruitmentList
        assessments={assessments}
        filteredRecruitments={filteredRecruitments}
      />
    </aside>
  );
};

export default RecruitmentListBox;
