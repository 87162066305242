/*eslint-disable */

import CustomTestResultToggleContainer from 'components/assessment/testResult/CustomTestResultToggleContainer';
import TestResultToggleContainer from 'components/assessment/testResult/TestResultToggleContainer';
import CustomBarChart from 'components/assessment/chart/CustomBarChart';
import React, { useEffect, useState } from 'react';
import {
  getAssessment,
  getCandidateInAssessment,
} from 'store/slices/candidatesSlice';
import { executeAction } from 'utils/redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProctoringSuccess } from 'assets/images/icon/chip-category-check.svg';
import { ReactComponent as ProctoringFailed } from 'assets/images/icon/chip-category.svg';
import {
  getRecruitment,
  setCandidateOfRecruitment,
} from 'store/slices/recruitments/recruitmentsSlice';
import { convertGradeToScore } from 'utils/converter';
import { useAverageScore } from 'utils/hooks';
import { toast } from 'utils/notify';
import { dateToDateFormat } from 'utils/string';

const VirtualResult = ({
  cid,
  rid,
  userInfo,
  processLength,
  setRefreshing,
  reportType,
  currentCandidate,
}) => {
  const { t } = useTranslation();

  const [candidate, setCandidate] = useState({});
  const [recruitment, setRecruitment] = useState({});
  const [objectiveTests, setObjectiveTests] = useState([]);
  const [noResultLanguage, setNoResultLanguage] = useState(false);
  const [testsAverage, setTestsAverage] = useState(null);
  const [newTests, setNewTests] = useState([]);
  const [totalAverage, setTotalAverage] = useState(0);
  const [comment, setComment] = useState('');
  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    executeAction(getRecruitment({ aid: rid }))
      .then(({ payload }) => {
        setRecruitment(payload.doc);

        executeAction(getCandidateInAssessment({ aid: rid, cid }))
          .then(({ payload: _payload }) => {
            setCandidate(_payload.doc);
          })
          .catch((e) => {
            console.dir(e);
            setCandidate({});
          });
      })
      .catch((e) => {
        console.dir(e);
      });
  }, [cid]);

  useEffect(() => {
    if (!recruitment || !Object.keys(recruitment).length) return;

    const { tests } = recruitment;
    const newObjectiveTests = new Array();
    const newSubjectiveTests = new Array();
    const newLibraryTests = new Array();

    (tests || []).forEach((test, idx) => {
      if (test?.testcnt > 0 || test?.skills?.length > 0) {
        (test?.skills || []).forEach((skill) => {
          if (candidate?.results?.[skill.uuid]) {
            newObjectiveTests.push(skill);
          }
        });
        return;
      }

      const { section } = test;
      if (
        section === 'video' ||
        section === 'essay' ||
        section === 'multiple-choice' ||
        section === 'file-upload' ||
        section === 'spreadsheets' ||
        section === 'code'
      ) {
        newSubjectiveTests.push(test);
      } else if (
        test?.question_type?.indexOf('tag.subjective') >= 0 ||
        test?.question_type?.indexOf('tag.code') >= 0 ||
        test?.question_type?.indexOf('tag.video') >= 0
      ) {
        newLibraryTests.push(test);
      } else {
        if (test?.question_type === 'tag.language') return;
        newObjectiveTests.push(test);
      }
    });

    setObjectiveTests(newObjectiveTests || []);
  }, [recruitment]);

  useEffect(() => {
    if (!recruitment || Object.keys(recruitment)?.length === 0) return;

    const languageTests = [];
    const results =
      reportType === 'current' ? currentCandidate?.results : candidate?.results;

    recruitment?.tests?.forEach((test) => {
      if (typeof test?.question === 'string') return;

      test?.question?.forEach((ques) => {
        if (ques?.tid === '601000000') {
          if (results?.english) {
            languageTests.push({
              category_skill: 'English Test',
              section: 'language',
              question: [
                {
                  name: 'english',
                  ...results.english,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.chinese) {
            languageTests.push({
              category_skill: 'Chinese Test',
              section: 'language',
              question: [
                {
                  name: 'chinese',
                  ...results.chinese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.japanese) {
            languageTests.push({
              category_skill: 'Japanese Test',
              section: 'language',
              question: [
                {
                  name: 'japanese',
                  ...results.japanese,
                  section: 'language',
                },
              ],
            });
          }
          if (results?.korean) {
            languageTests.push({
              category_skill: 'Korean Test',
              section: 'language',
              question: [
                {
                  name: 'korean',
                  ...results.korean,
                  section: 'language',
                },
              ],
            });
          }
        }
      });
    });

    const newTests = recruitment?.tests;

    setNewTests([...languageTests, ...newTests]);
  }, [recruitment, candidate, currentCandidate]);

  useEffect(() => {
    const { results } = reportType === 'current' ? currentCandidate : candidate;

    let testsArr;

    if (!results) {
      testsArr = [];
    } else {
      testsArr = newTests?.map((test, idx) => {
        if (
          test?.id === '6010' ||
          test?.section === 'file-upload' ||
          test?.section === 'multiple-choice'
        )
          return;

        const testArr = [];
        const { question } = test;

        // 커스텀 질문이고 language 테스트가 아닐 때
        if (test?.section && test?.section !== 'language') {
          testArr.push(results[test?.uuid]);
        } else if (test?.question_type === 'tag.multiple-choice') {
          testArr.push(results[test?.uuid]);
        } else {
          for (const q of question) {
            let count = 0;

            if (q?.section === 'language' && q?.name && results[q?.name]) {
              Object.keys(results[q?.name])?.forEach((item) => {
                testArr.push({
                  score:
                    q?.score && count < 1
                      ? q?.score
                      : q?.preset_score && count < 1
                      ? q?.preset_score
                      : Math.abs(
                          convertGradeToScore(
                            results[q?.name][item]?.judged_difficulty
                          ) / 10
                        ),
                });
                count += 1;
              });
            } else if (q?.id !== '99101000006') {
              if (!results) return;

              testArr.push(results[q?.id]);
            }
          }
        }

        const removeUndefined = testArr?.filter(
          (item) => item?.preset_score || item?.score
        );

        const scoreArr = removeUndefined?.map((item) => {
          return !item?.score ? item?.preset_score : item?.score;
        });

        const average = useAverageScore(scoreArr);

        return {
          id: idx,
          title: test?.category_skill || test?.title_en || test?.question,
          average: average || 0,
        };
      });
    }

    const filtered = testsArr?.filter((item) => item);
    const sortedData = filtered.sort((a, b) => b.average - a.average);
    setTestsAverage(sortedData);
  }, [newTests]);

  useEffect(() => {
    if (!testsAverage) return;

    if (recruitment.tests) {
      setNoResultLanguage(false);
      if (
        recruitment.tests.some((item) => item.id === '6010') &&
        !testsAverage.some((item) => item.title === 'Chinese Test') &&
        !testsAverage.some((item) => item.title === 'Japanese Test') &&
        !testsAverage.some((item) => item.title === 'English Test') &&
        !testsAverage.some((item) => item.title === 'Korean Test')
      ) {
        setNoResultLanguage(true);
      }
    }
    const avgScore =
      testsAverage.reduce((acc, cur) => acc + cur.average, 0) /
      testsAverage.length;

    setTotalAverage(avgScore);
  }, [testsAverage, candidate]);

  useEffect(() => {
    setComment(candidate?.progressSubmit?.[2]?.comment || '');
  }, [candidate]);

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const onSubmitHandler = () => {
    const { rid } = recruitment;
    const { email } = candidate;

    executeAction(
      setCandidateOfRecruitment({
        aid: rid,
        cid: email,
        progressSubmit: {
          2: {
            comment,
          },
        },
        processLength,
      })
    )
      .unwrap()
      .then(() => {
        toast(t('toast.desc.has-been-saved'));
      })
      .catch((e) => console.error(e));
  };

  const convertDeviceCheck = (bool) => {
    if (bool) {
      return <ProctoringSuccess />;
    }

    return <ProctoringFailed />;
  };

  return (
    <>
      {/* 서베이 */}
      <header className="header">
        <section className="header-title">
          <h4 className="header-title-main">
            <span className="fs28">{t('header.menu.my-assessments')}</span>
            <span className="fs28">{recruitment?.recruitmentName}</span>
          </h4>
          <div className="header-title-sub fs14">
            {`${t('desc.completed-date')}: ${dateToDateFormat(
              recruitment?.createdAt?.toDate(),
              t('lang')
            )}`}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
        </section>
        <button
          className={`header-fold common-button fsbtn16 gray active ${
            showDetail ? 'block' : 'none'
          }`}
          onClick={() => setShowDetail(!showDetail)}
        />
      </header>
      <section style={{ display: showDetail ? 'block' : 'none' }}>
        <article className="gucci survey">
          <div className="title fs20">{t('title.gucci-store-survey')}</div>
          {candidate?.results?.survey?.gucciNewSurvey ? (
            <>
              <div className="row">
                <div className="cell">
                  <div className="title fs14">{t('title.career')}</div>
                  <div className="content fs16">
                    {Object.values(
                      candidate?.results?.survey?.gucciNewSurvey
                        .newCareerGucci || ''
                    )?.join(', ')}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">{t('title.retail-career')}</div>
                  <div className="content fs16">
                    {Object.values(
                      candidate?.results?.survey?.gucciNewSurvey
                        .retailCareerGucci || ''
                    )?.join(', ')}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('title.reason-for-job-change')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey?.gucciNewSurvey.changingGucci}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('title.overseas-experience')}
                  </div>
                  <div className="content fs16">
                    {
                      candidate?.results?.survey?.gucciNewSurvey
                        .overSeasYesOrNoGucci
                    }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  <div className="title fs14">
                    {t('title.foreign-language-level')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey?.gucciNewSurvey.langLevelGucci}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('title.main-values -choosing-company')}
                  </div>
                  <div className="content fs16">
                    {
                      candidate?.results?.survey?.gucciNewSurvey
                        .importantValueGucci
                    }
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('title.competencies-job-applied')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey?.gucciNewSurvey.competencyGucci}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">{t('title.share-feedback')}</div>
                  <div className="content fs16">
                    {candidate?.results?.survey?.gucciNewSurvey.feedbackGucci}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="cell">
                  <div className="title fs14">
                    {t('survey.position-applying')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey &&
                      candidate?.results?.survey?.applyGucci}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('survey.preferred-channel')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey &&
                      Object.values(
                        candidate?.results?.survey?.channelGucci || ''
                      )?.join(',')}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('survey.preferred-location')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey &&
                      Object.values(
                        candidate?.results?.survey?.areaGucci || ''
                      )?.join(',')}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('survey.major-of-studying')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey &&
                      candidate?.results?.survey?.majorGucci}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="cell">
                  <div className="title fs14">
                    {t('survey.sales-experience')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey &&
                      candidate?.results?.survey?.specialExperienceGucci}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('survey.industry-background')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey &&
                      candidate?.results?.survey?.careerGucci}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('survey.sales-other-industry')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey &&
                      candidate?.results?.survey?.saleLuxryExperienceGucci}
                  </div>
                </div>
                <div className="cell">
                  <div className="title fs14">
                    {t('survey.sales-experience-luxury')}
                  </div>
                  <div className="content fs16">
                    {candidate?.results?.survey?.saleExperienceGucci}
                  </div>
                </div>
              </div>
            </>
          )}
        </article>

        {/* 종합 평가 */}
        <article className="summary-container">
          <aside className="summary-title">
            <h3 className="results-main-title">{t('title.summary')}</h3>
            {noResultLanguage && (
              <div className="tests-no-lang-result">
                {t('tag.no-lang-result')}
              </div>
            )}
          </aside>
          <aside className="summary-contents">
            <aside className="summary-average">
              {/* 평균 점수 */}
              <h4 className="results-sub-title">
                {t('title.summary.average-score')}
              </h4>
              <div className="summary-total-chart">
                <div className="single-chart">
                  <svg viewBox="0 0 36 36" className={'circular-chart'}>
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${
                        Math.trunc(totalAverage * 100) || 0
                      } , 100`}
                      d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                </div>
                <div className="percentage">
                  <span className="number fs56">{`${
                    Math.trunc(totalAverage * 100) || 0
                  }`}</span>
                  <span className="unit fs36">%</span>
                </div>
              </div>
            </aside>
            <aside className="summary-detail-score">
              {/* 상세 점수 */}
              <h4 className="results-sub-title">
                {t('title.summary.detail-score')}
              </h4>
              <div className="summary-bar-chart">
                {testsAverage &&
                  testsAverage?.map((test) => (
                    <CustomBarChart
                      key={test?.id}
                      score={Math.trunc(test?.average * 100) || 0}
                      title={test?.title}
                    />
                  ))}
              </div>
            </aside>
            <aside className="summary-proctoring">
              {/* 부정행위 */}
              <h4 className="results-sub-title">
                {t('title.summary.proctoring')}
              </h4>
              <div className="summary-proctoring-list">
                <ul>
                  <li>
                    {convertDeviceCheck(
                      currentCandidate?.results?.detect?.ipOnce ||
                        candidate?.results?.detect?.ipOnce
                    )}
                    {t('title.ip')}
                    <span className="tooltip">
                      {t('label.filled-out-only-once-from-ip-address')}
                    </span>
                  </li>
                  <li>
                    {convertDeviceCheck(
                      currentCandidate?.results?.detect?.onWebcam ||
                        candidate?.results?.detect?.onWebcam
                    )}
                    {t('title.webcam')}
                    <span className="tooltip">{t('label.webcam-enabled')}</span>
                  </li>
                  <li>
                    {convertDeviceCheck(
                      currentCandidate?.results?.detect?.onFullscreen ||
                        candidate?.results?.detect?.onFullscreen
                    )}
                    {t('title.full-screen')}
                    <span className="tooltip">
                      {t('label.full-screen-mode-always-active')}
                    </span>
                  </li>
                  <li>
                    {convertDeviceCheck(
                      currentCandidate?.results?.detect?.isMouseLeave ||
                        candidate?.results?.detect?.isMouseLeave
                    )}
                    {t('title.mouse')}
                    <span className="tooltip">
                      {t('label.mouse-always-in-assessment-window')}
                    </span>
                  </li>
                </ul>
              </div>
            </aside>
          </aside>
        </article>

        {/* 테스트 결과 */}
        <article className="tests-container">
          <aside className="tests-title">
            <h3 className="results-semi-main-title">{t('title.test')}</h3>
          </aside>
          <aside className="tests-contents">
            {/* 반복문을 통해 하나의 테스트를 props로 넘겨줌 */}
            {newTests?.length !== 0 &&
              newTests?.map((test, idx) => {
                if (test?.id === '6010') return null;

                if (test?.section && test?.section !== 'language') {
                  return (
                    <CustomTestResultToggleContainer
                      type={
                        reportType === 'current'
                          ? reportType === 'history'
                            ? 'custom'
                            : reportType
                          : reportType
                      }
                      test={test}
                      key={idx}
                      candidate={
                        reportType === 'current' ? currentCandidate : candidate
                      }
                      assessment={recruitment}
                      setRefreshing={setRefreshing}
                    />
                  );
                }

                return (
                  <TestResultToggleContainer
                    test={test}
                    key={idx}
                    candidate={
                      reportType === 'current' ? currentCandidate : candidate
                    }
                    assessment={recruitment}
                    setRefreshing={setRefreshing}
                    objectiveTests={objectiveTests}
                    type={reportType}
                  />
                );
              })}
          </aside>
        </article>

        {/* comment */}
        <article className="comment-container">
          <div className="right">
            <section className="right-wrapper">
              <div className="right-sticky">
                <div className="comment-container">
                  <textarea
                    value={comment}
                    onChange={onChangeHandler}
                    disabled={
                      userInfo?.memberType === 15 ||
                      userInfo?.memberType === 20 ||
                      candidate?.recruitProgress !== 2
                    }
                  />
                </div>
                <div className="submit-button">
                  <button
                    className={`common-button primary fs16 ${
                      userInfo?.memberType <= 10 &&
                      candidate?.recruitProgress === 2
                        ? 'active'
                        : ''
                    }`}
                    onClick={() => onSubmitHandler()}
                    disabled={
                      userInfo?.memberType === 15 ||
                      userInfo?.memberType === 20 ||
                      candidate?.recruitProgress !== 2
                    }
                  >
                    {t('btn.save-comment')}
                  </button>
                </div>
              </div>
            </section>
          </div>
        </article>
      </section>
    </>
  );
};

export default VirtualResult;
