import { ReactComponent as CloseIcon } from 'assets/images/icon/close-modal.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { invitedValidateMail } from 'store/slices/recruitments/membersSlice';
import { toast } from 'utils/notify';
import { useModal } from 'utils/modal';
import { executeAction } from 'utils/redux';
import { validateEmail } from 'utils/auth';
import { useSelector } from 'react-redux';
import BasicInput from 'components/input/BasicInput';
import BasicSelect from 'components/select/BasicSelect';
import { Button } from 'components/common/Button';

const InviteMember = ({ menuItems, userInfo }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();
  const { user } = useSelector((state) => state.auth);

  const [firstName, setFirstName] = useState('dummy');
  const [lastName, setLastName] = useState('dummy');
  const [email, setEmail] = useState('');
  const [memberType, setMemberType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [memberMenu, setMemberMenu] = useState([]);
  const account = window.localStorage.getItem('munch_skill_account') || 'main';

  useEffect(() => {
    if (Object.keys(menuItems).length === 0) return;
    let newMenu;

    if (userInfo?.memberType === 5 || userInfo?.memberType === 1) {
      newMenu = menuItems.membersType.filter((item) => item.key);
    } else {
      newMenu = menuItems.membersType.filter((item) => item.key >= 15);
    }

    setMemberMenu(newMenu);
  }, [menuItems]);

  useEffect(() => {
    if (memberType === 0) {
      return setCanSubmit(false);
    }

    if (!validateEmail(email)) {
      return setCanSubmit(false);
    }
    setCanSubmit(true);
  }, [memberType, email]);

  const onSelectChangeHandler = (_, menu) => {
    setMemberType(menu);
  };

  const onInviteButtonClickHandler = () => {
    if (!canSubmit || !validateEmail(email)) return;

    setIsLoading(true);
    executeAction(
      invitedValidateMail({
        email,
        type: 'set',
        firstName,
        lastName,
        account,
        memberType: memberType?.key,
        ouid: userInfo?.ownerUid || user?.uid,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
        toast(t('toast.desc.send-email'));
        setFirstName('');
        setLastName('');
        setEmail('');
      })
      .catch((e) => {
        setIsLoading(false);
        console.dir(e);
        switch (e?.message) {
          case 'invalid-email':
            toast(t('toast.desc.invalid-email'));
            break;
          case 'invalid-job-email':
            toast(t('toast.desc.invalid-job-email'));
            break;
          case 'already_joined':
            toast(t('toast.desc.already-joined'));
            break;
          default:
            break;
        }
      });
  };

  return (
    <div className="modal-invited-member">
      <section className="invited-member-container">
        <article className="invited-header-wrapper">
          <h3 className="fs16">Invite Member</h3>
          <span onClick={() => dismissModal()}>
            <CloseIcon />
          </span>
        </article>
        <article className="invited-form-wrapper">
          <BasicInput
            inputType={'text'}
            placeholder={'xxx@gmail.com'}
            label={'Email'}
            required
            name={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <BasicSelect
            value={memberType}
            required
            label={'Member Type'}
            name={'memberType'}
            onChange={onSelectChangeHandler}
            menus={memberMenu}
          />
        </article>
        <article className="invited-button">
          <Button
            theme={'grey'}
            size={56}
            label={'Invite'}
            isDisabled={!canSubmit && true}
            isLoading={isLoading}
            fontSize={'fs16'}
            onClick={
              !isLoading && canSubmit ? onInviteButtonClickHandler : null
            }
          />
        </article>
      </section>
    </div>
  );
};

export default InviteMember;
