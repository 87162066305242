import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';
import { ReactComponent as ChevronBottomIcon } from 'assets/images/icon/chevron-bottom-dropdown.svg';
import { ReactComponent as DoneIcon } from 'assets/images/icon/done.svg';
import { ExtractFirstEmailChar } from 'utils/hooks';
import { useModal } from 'utils/modal';
import { validateEmail } from 'utils/auth';
import {
  sendEmailToEvaluator,
  getAssessmentOfEvaluatorUser,
  getEvaluatorsOfAssessments,
  deleteEvaluatorOfAssesssment,
} from 'store/slices/assessmentsSlice';
import { executeAction } from 'utils/redux';
import { toast } from 'utils/notify';
import MaterialInput from 'components/input/MaterialInput';
import { CircularProgress } from '@mui/material';

const __INVITE_STATE__ = [
  {
    key: 'evaluator',
    i18nKey: 'btn.evaluator',
    onClick: ({}, callback) => {},
  },
  {
    key: 'resend-invitation-email',
    i18nKey: 'btn.resend-invitation-email',
    onClick: ({
      t,
      showDropdown,
      setShowDropdown,
      aid,
      evaluators,
      lang,
      userInfo,
      adminEmail,
      assessment,
    }) => {
      const evaluator = evaluators?.[showDropdown];
      setShowDropdown(-1);
      const eventHandler = () => {
        executeAction(
          sendEmailToEvaluator({
            type: 'resend',
            aid,
            email: evaluator?.email,
            lang,
            subjectiveTests: assessment?.subjectiveTests,
            objectiveTests: assessment?.objectiveTests,
            title: evaluator?.title,
            adminEmail,
          })
        )
          .unwrap()
          .then(() => {
            toast(t('toast.desc.evaluator-email-sent'));
          })
          .catch((e) => {
            console.dir(e);
          });
      };
      eventHandler();
    },
  },
  {
    key: 'delete',
    i18nKey: 'btn.delete',
    onClick: ({
      t,
      showDropdown,
      setShowDropdown,
      aid,
      evaluators,
      displayModal,
      setLoading,
    }) => {
      const evaluator = evaluators?.[showDropdown];
      const eventHandler = (event) => {
        if (event.detail?.message === 'delete-evaluator') {
          setLoading(true);
          executeAction(
            deleteEvaluatorOfAssesssment({
              aid,
              email: evaluator?.email,
            })
          )
            .unwrap()
            .then(() => {
              setLoading(false);
            })
            .catch((e) => {
              console.dir(e);
              setLoading(false);
            });
        }
      };
      setShowDropdown(-1);
      window.document.addEventListener('dismissModal', eventHandler);
      displayModal(
        'common/AlertModal',
        {
          event: {
            message: 'delete-evaluator',
          },
          title: {
            main: t('dialog.title.delete-evaluator'),
            sub: t('dialog.desc.delete-evaluator'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    },
  },
];

const InviteEvaluator = ({
  assessmentTitle,
  setLoading,
  aid,
  candidates,
  assessment,
  userInfo,
}) => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();
  const [email, setEmail] = React.useState('');
  const [isBtnActive, setIsBtnActive] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(-1);
  const [evaluatorsList, setEvaluatorsList] = React.useState([]);

  const colorArr = [
    'light-green',
    'orange',
    'indigo',
    'purple',
    'pink',
    'black',
    'green',
    'blue',
  ];
  const randomColor = colorArr[Math.floor(Math.random() * colorArr.length)];

  const dropdownRef = React.useRef(null);
  const listRef = React.useRef(null);
  const rowRefs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];

  /** ============================
   * 1. 작성한 이메일 validate 체크 로직
  ============================== */
  React.useEffect(() => {
    let flag = true;
    if (!validateEmail(email)) flag = false;

    setIsBtnActive(flag);
  }, [email]);

  /** ============================
   * 1. 평가자를 초대하면 rowRef에 순서대로 저장되는 로직
   * 2. dropdown외 다른 곳 클릭 시 dropdown 숨김처리
  ============================== */
  React.useEffect(() => {
    const handler = (event) => {
      for (const rowRef of rowRefs) {
        if (rowRef.current) {
          const stateElem = rowRef.current.querySelector('.assessor-position');
          if (stateElem.contains(event.target)) return;
        }
      }
      if (!dropdownRef.current || dropdownRef.current.contains(event.target))
        return;

      setShowDropdown(-1);
    };

    window.document.addEventListener('mousedown', handler);
    window.document.addEventListener('touchstart', handler);

    return () => {
      window.document.removeEventListener('mousedown', handler);
      window.document.removeEventListener('touchstart', handler);
    };
  }, [dropdownRef, rowRefs]);

  /** ============================
   * 각 assessment에 초대된 평가자 리스트를 불러온다.
  ============================== */
  React.useEffect(() => {
    executeAction(getAssessmentOfEvaluatorUser({ aid }))
      .unwrap()
      .then((result) => {
        if (!result?.doc) throw new Error();
        executeAction(
          getEvaluatorsOfAssessments({
            assessments: [result?.doc],
          })
        )
          .unwrap()
          .then((doc) => {
            let evaluators = doc?.evaluators?.[0];
            setEvaluatorsList(evaluators);
          })
          .catch((e) => {
            console.dir(e);
          });
      })
      .catch((e) => {
        console.dir(e);
      });
  }, [refreshing]);

  /** ============================
   * @function onStateClickHandler : 평가자들 클릭 시 클릭한 평가자의 dropdown 생성 / 위치조정
   * @param {*} idx(number) : 클릭한 평자가의 index 값
   * @param {*} event : 클릭한 평가자의 event 값
  ============================== */
  const onStateClickHandler = (idx, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (listRef?.current && rowRefs?.[idx]?.current)
      var resultRect = listRef.current.getBoundingClientRect(),
        elemRect = rowRefs?.[idx].current.getBoundingClientRect(),
        offset = elemRect.top - resultRect.top;

    dropdownRef.current.style.top = `${offset + 30}px`;
    listRef.current.style.setProperty('--dropdown-top', `${offset}px`);
    setShowDropdown(idx);
  };

  /** ============================
   * @function onSendEmailInviteHandler : 평가자 초대 버튼 클릭 시 평가자 신규 생성 / 이메일 발송 함수
  ============================== */
  const onSendEmailInviteHandler = () => {
    setRefreshing(true);
    setLoading(true);
    executeAction(
      sendEmailToEvaluator({
        type: 'send',
        aid,
        email,
        adminEmail: userInfo?.email,
        lang: assessment?.language,
        subjectiveTests: assessment?.subjectiveTests,
        objectiveTests: assessment?.objectiveTests,
        title: assessment?.name,
        color: randomColor,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
      })
    )
      .unwrap()
      .then(() => {
        setEmail('');
        toast(t('toast.desc.evaluator-email-sent'));
        setRefreshing(false);
        setLoading(false);
      })
      .catch((e) => {
        console.dir(e);
        setLoading(false);
        setRefreshing(false);
        switch (e?.message) {
          case 'already_exists_email':
            toast(t('toast.desc.already-invite'));
            break;
          default:
            break;
        }
      });
  };

  /** ============================
   * @function evaluationStatus : 평가 상태값에 따라 텍스트 변경 함수
   * @param {*} state(number)
   * @returns 
   * 0 : 초대 이메일 보냄
   * 1 : 초대 수락 완료
   * 2 : 채점 완료
  ============================== */
  const evaluationStatus = (state) => {
    switch (state) {
      case 0:
        return 'desc.invitation-email-sent';
      case 1:
        return 'desc.invitation-accepted';
      case 2:
        return 'desc.scoring-completed';
      default:
        return '';
    }
  };

  /** ============================
   * @function onKeyPress : 엔터키 누를 시 평가자 초대 실행 함수
   * @param {*} e : 이벤트
  ============================== */
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (isBtnActive && evaluatorsList.length < 4) onSendEmailInviteHandler();
    }
  };

  /** ============================
   * @function nameConversionLanguage : 언어 설정에 따른 이름 위치 변경
   * @param {*} firstName(string)
   * @param {*} lastName(string) 
   * @returns 
  ============================== */
  const nameConversionLanguage = (firstName, lastName) => {
    const lang = t('lang');

    switch (lang) {
      case 'ko':
        return `${lastName} ${firstName}`;
      case 'en':
        return `${firstName} ${lastName}`;
      default:
        return `${lastName} ${firstName}`;
    }
  };

  return (
    <>
      <article ref={listRef} className="modals-assessments-invite-detail">
        <header className="header">
          <h3 className="header-title fs16">{`${assessmentTitle} ${t(
            'btn.invite-evaluator'
          )}`}</h3>
          <button className="close" onClick={dismissModal}>
            <CloseIcon />
          </button>
        </header>

        {/** --------------------
         * 이메일 초대 input section
        ------------------------ */}
        <section className="invite-email-form">
          <MaterialInput
            className="form-input email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={onKeyPress}
            placeholder={t('text-field.label.email')}
          />
          <button
            className={`common-button fsbtn16 primary ${
              isBtnActive && evaluatorsList.length < 4 ? 'active' : 'disabled'
            }`}
            disabled={!(isBtnActive && evaluatorsList.length < 4)}
            onClick={() => onSendEmailInviteHandler()}
          >
            {refreshing ? (
              <CircularProgress color="grey" size={20} />
            ) : (
              t('btn.invite')
            )}
          </button>
        </section>

        {/** --------------------
         * 평가자 초대 경고 section
        ------------------------ */}
        <section className="invite-alert">
          <div className="invite-alert-gray">
            <h6 className="fs14">{t('desc.invite-evaluator')}</h6>
          </div>
        </section>

        {/** --------------------
         * 평가자 초대 리스트 section
        ------------------------ */}
        <section className="invite-list">
          <ul className="invite-list-wrapper">
            <li ref={rowRefs[0]} className="invite-list-item">
              <aside className="assessor-info">
                <div className="assessor-round">
                  <span className="assessor-init fs16">
                    {ExtractFirstEmailChar(userInfo?.email)}
                  </span>
                </div>
                <div className="assessor-name">
                  <h6 className="assessor fs14">
                    {nameConversionLanguage(
                      userInfo?.firstName,
                      userInfo.lastName
                    )}
                  </h6>
                  <span className="assessor-email fs12">{userInfo?.email}</span>
                </div>
              </aside>
              <aside className="grading-status">
                <span className="fs14">-</span>
              </aside>
              <aside className="assessor-position owner">
                <span className="fs14">{t('btn.owner')}</span>
              </aside>
            </li>
            {evaluatorsList &&
              (evaluatorsList || []).map(
                (list, idx) =>
                  idx < 4 && (
                    <li
                      key={`list_${idx}`}
                      ref={rowRefs[idx]}
                      className="invite-list-item"
                    >
                      <aside className="assessor-info">
                        <div
                          className={`assessor-round ${
                            list?.name === undefined ? 'grey' : list?.color
                          }`}
                        >
                          <span className="assessor-init fs16">
                            {ExtractFirstEmailChar(list?.name)}
                          </span>
                        </div>
                        <div className="assessor-name">
                          <h6 className="assessor fs14">
                            {list?.name === undefined || list?.name === ''
                              ? '-'
                              : list?.name}
                          </h6>
                          <span className="assessor-email fs12">
                            {list?.email}
                          </span>
                        </div>
                      </aside>
                      <aside className="grading-status">
                        <span className="fs14">
                          {t(evaluationStatus(list?.evaluation_status))}
                        </span>
                      </aside>
                      <aside
                        className="assessor-position"
                        onClick={(event) =>
                          onStateClickHandler(
                            showDropdown === idx ? -1 : idx,
                            event
                          )
                        }
                      >
                        <span className="fs14">{t('btn.evaluator')}</span>
                        <i>
                          <ChevronBottomIcon />
                        </i>
                      </aside>
                    </li>
                  )
              )}
          </ul>
        </section>
      </article>

      {/** ---------------
       * Dropdown section
      ------------------- */}
      <div ref={dropdownRef} className="evaluator-table-dropdown">
        {showDropdown > -1 && (
          <ul className="evaluator-table-dropdown-list">
            {(__INVITE_STATE__ || []).map((tab, idx) => (
              <li
                key={idx}
                className="evaluator-table-dropdown-list-item"
                onClick={() => {
                  tab?.onClick({
                    t,
                    aid,
                    evaluators: evaluatorsList,
                    showDropdown,
                    setShowDropdown,
                    email,
                    lang: assessment?.language,
                    displayModal,
                    setLoading,
                    adminEmail: userInfo?.email,
                    assessment,
                  });
                }}
              >
                {tab.key === 'evaluator' && <DoneIcon />}
                {t(tab.i18nKey)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default InviteEvaluator;
