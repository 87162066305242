import React from 'react';
import { Button } from 'components/common/Button';
import { ReactComponent as PencilIcon } from 'assets/images/icon/settings-icon.svg';

const ResultScoreBox = ({ score, onClick, isHideButton }) => {
  return (
    <div className="result-score-box">
      <p>Score : {score || '0'}%</p>
      {!isHideButton && (
        <Button
          size={26}
          theme={'borderGrey'}
          frontIcon={<PencilIcon />}
          radius={50}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default ResultScoreBox;
