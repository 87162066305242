import React from 'react';
import MainLogo from 'components/logo/MainLogo';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';
import { useModal } from 'utils/modal';
import AppNavigator from 'components/navigator/AppNavigator';

const MoreMenu = (props) => {
  const navigate = useNavigate();
  let { dismissModal } = useModal();

  const onDismissHandler = () => {
    dismissModal();
  };

  return (
    <article className="modals-header-more-menu">
      <header className="modal-header">
        <MainLogo
          type="appHeader"
          className="header-logo header"
          onClick={() => navigate('/customer/assessments')}
        />
        <CloseIcon onClick={onDismissHandler} />
      </header>
      <main className="modal-main">
        <AppNavigator callback={onDismissHandler} />
      </main>
    </article>
  );
};

export default MoreMenu;
