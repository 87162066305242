import { Box, Skeleton } from '@mui/material';
import React from 'react';

const RecruitmentListSkeleton = () => {
  return (
    <li>
      <Box sx={{ width: '100%', padding: '0 35px' }}>
        <Skeleton animation="wave" sx={{ height: 50 }} />
      </Box>
    </li>
  );
};

export default RecruitmentListSkeleton;
