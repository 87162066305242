import React from 'react';
import { CircularProgress } from '@mui/material';
import { colors } from 'config/theme';

const ProgressCircle = ({ className, size }) => (
  <div
    className={`components-progress-progress-circle ${className}`}
    style={{
      backgroundColor: colors.modalOverlay,
      zIndex: 1000,
    }}
  >
    <div className="container">
      <CircularProgress size={size} color="primary" />
    </div>
  </div>
);

export default ProgressCircle;
