import React, { useCallback, useEffect, useState } from 'react';
import { dateToDateFormat } from 'utils/string';
import { useTranslation } from 'react-i18next';
import {
  areaGucciList,
  careerOptions, channelGucciList, channelOptions,
  countryCodeOptions, finalEducationOptions,
  genderOptions, jobCenterOptions, languageLevelOptions, languageOptions,
  locationOptions, majorOptions, nationalCurrencyOptions, schoolOptions, searchFirmOptions, snsOptions,
} from 'constants/recruitments/applySelectOptions';
import { EditSelectBox } from 'components/recruitment/detail/reviewing/EditSelectBox';
import { EditMultiSelectBox } from 'components/recruitment/detail/reviewing/EditMultiSelectBox';
import { toast } from 'utils/notify';

export const ReviewingFieldEdit = ({ editData, handleChangeEditData, email, createdAt, count }) => {
  const { t } = useTranslation();
  const [subChannelOptions, setSubChannelOptions] = useState([]);
  const [subChannel, setSubChannel] = useState('');
  const [subChannelName, setSubChannelName] = useState('');

  const countDuplicateEmails = (count) => {
    if (count > 1) {
      return 'Y';
    }
    return 'N';
  };

  const handleChangeInput = useCallback((e) => {

    if (e.target.name === 'phoneNumber' ||
      e.target.name === 'salary' ||
      e.target.name === 'variableSalary' ||
      e.target.name === 'monetaryCompensation') {
      const number = e.target.value.replace(/[^0-9]/g, '');

      handleChangeEditData((prev) => ({
        ...prev,
        [e.target.name]: number,
      }));
      return;
    }

    if (e.target.name === 'birth') {
      const number = e.target.value.replace(/[^0-9]/g, '');
      const regexValue = number.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

      if (regexValue.length > 10) {
        return toast(t('toast.desc.10-characters'));
      }

      handleChangeEditData((prev) => ({
        ...prev,
        [e.target.name]: regexValue,
      }));
      return;
    }

    handleChangeEditData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  },[handleChangeEditData]);

  useEffect(() => {
    switch(editData.channel){
      case 'sns':
        return setSubChannelOptions(snsOptions);
      case 'job':
        return setSubChannelOptions(jobCenterOptions);
      case 'school':
        return setSubChannelOptions(schoolOptions);
      case 'searchFirm':
        return setSubChannelOptions(searchFirmOptions);
      case 'others':
        return setSubChannelOptions([]);
      case 'friend':
        return setSubChannelOptions([]);
      default:
        return setSubChannelOptions([]);
  }
  },[editData.channel]);

  useEffect(()=>    {
    switch (editData.channel) {
    case 'sns':
      return setSubChannel(editData.sns);
    case 'job':
      return setSubChannel(editData.jobCenter);
    case 'school':
      return setSubChannel(editData.school);
    case 'searchFirm':
      return setSubChannel(editData.searchFirm);
    case 'others':
      return setSubChannel(editData.otherChannel);
    case 'friend':
      return setSubChannel(editData.nameOfEmployee);
    default:
      return '';
  }
},[editData.channel, editData.sns, editData.jobCenter, editData.school, editData.searchFirm, editData.otherChannel, editData.nameOfEmployee]);

  useEffect(()=>    {
    switch (editData.channel) {
      case 'sns':
        return setSubChannelName('sns');
      case 'job':
        return setSubChannelName('jobCenter');
      case 'school':
        return setSubChannelName('school');
      case 'searchFirm':
        return setSubChannelName('searchFirm');
      case 'others':
        return setSubChannelName('otherChannel');
      case 'friend':
        return setSubChannelName('nameOfEmployee');
      default:
        return '';
    }
  },[editData.channel]);

  return (
    <ul>
      <li>
        <span className="sub-title fs12">{t('field.mobile-number')}</span>
        <EditSelectBox name="countryCode" value={editData.countryCode} onChange={handleChangeInput} menu={countryCodeOptions} />
        <input className="fs12 candidate-edit-input" name="phoneNumber" value={`${editData.phoneNumber}`} onChange={handleChangeInput} />
      </li>
      <li>
        <span className="sub-title fs12">{t('field.email')}</span>
        <p className="desc fs12">{email}</p>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.gender')}</span>
        <EditSelectBox name="gender" onChange={handleChangeInput} value={editData.gender} menu={genderOptions}/>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.birth')}</span>
        <input className="fs12 candidate-edit-input" name="birth" onChange={handleChangeInput} value={editData.birth}/>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.other-link')}</span>
        <input className="fs12 candidate-edit-input" name="etcLink1" onChange={handleChangeInput} value={editData.etcLink1}/>
      </li>
      {/* 국적은 여권상 국적(country) */}
      <li>
        <span className="sub-title fs12">{t('field.nationality')}</span>
        <EditSelectBox name="country" onChange={handleChangeInput} value={editData.country} menu={locationOptions}/>
      </li>
      {/* 지역은 살고 있는 국가(location) */}
      <li>
        <span className="sub-title fs12">{t('field.region')}</span>
        <EditSelectBox name="location" onChange={handleChangeInput} value={editData.location} menu={locationOptions}/>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('field.highest-education')}
        </span>
        <EditSelectBox name="finalEducation" onChange={handleChangeInput} value={editData.finalEducation} menu={finalEducationOptions}/>
      </li>
      <li>
        <span className="sub-title fs12">{t('field.major')}</span>
        <EditSelectBox name="major" onChange={handleChangeInput} value={editData.major} menu={majorOptions}/>
      </li>
      <li>
        <span className="sub-title fs12">{t('column.within-3-years')}</span>
        <p className="desc fs12">{countDuplicateEmails(count)}</p>
      </li>
      <li>
            <span className="sub-title fs12">
              {t('field.total-work-experience')}
            </span>
        <EditSelectBox name="career" onChange={handleChangeInput} value={editData.career} menu={careerOptions}/>
      </li>
      <li>
              <span className="sub-title fs12">
                {t('field.work-experience-related')}
              </span>
        <EditSelectBox name="relatedCareer" onChange={handleChangeInput} value={editData.relatedCareer} menu={careerOptions}/>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('field.previous-employer')}
        </span>
        <input className="fs12 candidate-edit-input" name="companyName" onChange={handleChangeInput} value={editData?.companyName} />
      </li>
      <li>
        <span className="sub-title fs12">{t('field.available-languages')}</span>
        <EditSelectBox name="language" onChange={handleChangeInput} value={editData.language} menu={languageOptions}/>
        <EditSelectBox name="languageLevel" onChange={handleChangeInput} value={editData.languageLevel} menu={languageLevelOptions}/>
      </li>
      <li/>
      <li>
        <span className="sub-title fs12">
          {t('column.submission-date')}
        </span>
        <p className="desc fs12" value={dateToDateFormat(new Date(createdAt?.seconds * 1000), t('lang'))} />
      </li>
      <li>
        <span className="sub-title fs12">{t('field.channel')}</span>
        <EditSelectBox name="channel" onChange={handleChangeInput} value={editData.channel} menu={channelOptions}/>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('field.channel-detail')}
        </span>
        {subChannelOptions.length === 0 ? <input className="fs12 candidate-edit-input"
                                                 onChange={handleChangeInput}
                                                 name={editData.channel === 'other' ? 'otherChannel' : 'nameOfEmployee'}
                                                 value={editData.channel === 'other' ? editData.otherChannel : editData.nameOfEmployee}/>
        : <EditSelectBox name={subChannelName} onChange={handleChangeInput} value={subChannel} menu={subChannelOptions}/>
        }
      </li>
      <li>
        <span className="sub-title fs12">
          {t('field.preferred-channel')}
        </span>
        <EditMultiSelectBox name="applyChannel" onChange={handleChangeInput} value={editData.applyChannel} menu={channelGucciList}/>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('field.preferred-location')}
        </span>
        <EditMultiSelectBox name="applyLocation" onChange={handleChangeInput} value={editData.applyLocation} menu={areaGucciList}/>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('text-field.label.recommended-employee-name')}
        </span>
        <input className="fs12 candidate-edit-input" name="nameOfEmployee" onChange={handleChangeInput} value={editData.nameOfEmployee}/>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('text-field.label.employee-department')}
        </span>
        <input className="fs12 candidate-edit-input" name="nameOfStore" onChange={handleChangeInput} value={editData.nameOfStore}/>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('field.previous-compensation')}
        </span>
        <EditSelectBox name="currency" value={editData.currency} onChange={handleChangeInput} menu={nationalCurrencyOptions}/>
        <input className="fs12 candidate-edit-input" name="salary" onChange={handleChangeInput} value={editData.salary}/>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('text-field.label.variable-salary')}
        </span>
        <input className="fs12 candidate-edit-input" name="variableSalary" onChange={handleChangeInput} value={editData.variableSalary}/>
      </li>
      <li>
        <span className="sub-title fs12">
          {t('text-field.label.monetary-compensation')}
        </span>
        <input className="fs12 candidate-edit-input" name="monetaryCompensation" onChange={handleChangeInput} value={editData.monetaryCompensation}/>
      </li>
    </ul>
  );
};
