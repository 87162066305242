import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as EmailIcon } from 'assets/images/icon/email.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { sendValidateMail } from 'store/slices/authSlice';
import MaterialInput from 'components/input/MaterialInput';
import { validateEmail } from 'utils/auth';
import { toast } from 'utils/notify';
import { CircularProgress } from '@mui/material';
import MainLogo from 'components/logo/MainLogo';
import PropTypes from 'prop-types';

const ProcessEmail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { account } = useParams();
  const [email, setEmail] = React.useState('');
  const [checked, setChecked] = React.useState({
    term: true,
    privacy: true,
    receive: false,
  });
  const [canGoNext, setCanGoNext] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    let flag = true;
    if (isLoading) {
      flag = false;
    }
    if (!validateEmail(email)) {
      flag = false;
    }
    setCanGoNext(flag);
  }, [isLoading, email]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (canGoNext && !isLoading) onSubmitHandler();
    }
  };

  const onSubmitHandler = () => {
    if (!validateEmail(email)) return;

    if (!checked.term) {
      toast(t('toast.desc.accept-term'));
      return;
    }
    if (!checked.privacy) {
      toast(t('toast.desc.privacy-policy'));
      return;
    }

    // onSuccess
    setIsLoading(true);
    executeAction(
      sendValidateMail({
        email,
        type: 'set',
        allowanceReceipt: checked.receive,
        account,
      })
    )
      .unwrap()
      .then((authToken) => {
        setIsLoading(false);
        toast(t('toast.desc.send-email'));
        setEmail('');
      })
      .catch((e) => {
        setIsLoading(false);
        console.dir(e);
        switch (e?.message) {
          case 'invalid-email':
            toast(t('toast.desc.invalid-email'));
            break;
          case 'invalid-job-email':
            toast(t('toast.desc.invalid-job-email'));
            break;
          case 'already_joined':
            toast(t('toast.desc.already-joined'));
            break;
          default:
            break;
        }
      });
  };

  const signinClickHandler = () => {
    navigate(`/${account}/auth/signin`);
  };

  return (
    <main className="pages-auth-signup-process-email">
      <section className="logo-container">
        <MainLogo className="logo" type="signup" />
      </section>
      <div className="explain fs24">
        {t('signup-customer.title.plan-start')}
      </div>
      <section className="form">
        <MaterialInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={onKeyPress}
          placeholder={`${t('text-field.label.job-email')} *`}
          icon={<EmailIcon />}
        />
        <div className="form-checkboxes">
          {/* <div className="label-container">
            <label
              className={`common-checkbox fs16 ${checked?.term && 'checked'}`}
              onClick={() => {
                setChecked({ ...checked, term: !checked.term });
              }}
            >
              <Trans
                t={t}
                i18nKey="check-box.label.accept-term"
                components={{
                  href_accept_term: (
                    <span
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        window.open(
                          'https://munchskill.com/terms-of-use/',
                          '_blank'
                        );
                      }}
                    />
                  ),
                }}
              />
            </label>
          </div>
          <div className="label-container">
            <label
              className={`common-checkbox ${checked?.privacy && 'checked'}`}
              onClick={() => {
                setChecked({
                  ...checked,
                  privacy: !checked.privacy,
                });
              }}
            >
              <Trans
                t={t}
                i18nKey="check-box.label.privacy-policy"
                components={{
                  href_privacy_policy: (
                    <span
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        window.open(
                          'https://munchskill.com/privacy-policy/',
                          '_blank'
                        );
                      }}
                    />
                  ),
                }}
              />
            </label>
          </div> */}
          <div className="label-container">
            <label
              className={`common-checkbox ${checked?.receive && 'checked'}`}
              onClick={() => {
                setChecked({
                  ...checked,
                  receive: !checked.receive,
                });
              }}
            >
              {t('check-box.label.accept-email')}
            </label>
          </div>
        </div>
        <button
          className={`form-submit fsbtn16 common-button ${
            canGoNext && !isLoading && 'active'
          } ${isLoading && 'pending'}`}
          onClick={canGoNext && !isLoading ? onSubmitHandler : null}
        >
          {isLoading ? t('progress.creating-account') : t('btn.create-account')}
          {isLoading && <CircularProgress color="grey" size={25} />}
        </button>
      </section>
      <p className="fs16">
        {t('signup-customer.desc.have-account')}
        <button
          className="login text-button fsbtn16 primary"
          onClick={signinClickHandler}
        >
          {t('btn.login')}
        </button>
      </p>
    </main>
  );
};

ProcessEmail.propTypes = {};

export default ProcessEmail;
