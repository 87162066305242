import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'components/common/Button';
import { ReactComponent as ChevronRightIcon } from 'assets/images/icon/chevron-right.svg';
import { ReactComponent as ExclamationIcon } from 'assets/images/icon/exclamation-blue.svg';
import { ReactComponent as EarthIcon } from 'assets/images/icon/earth.svg';
import Tag from 'components/common/Tag';
import { useLocation, useNavigate } from 'react-router-dom';
import { dateToDateFormat } from 'utils/string';
import { executeAction } from 'utils/redux';
import { setCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import { Timestamp } from 'firebase/firestore';
import { useOnClickOutside } from 'utils/hooks';
import { setLang } from 'store/slices/variableSlice';
import { supportedLangs } from 'i18n/index';
import { dateFormat } from '../../utils/dateFormat';

const SelectedDate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const { uid, rid, cid, step, candidate } = location?.state;
  const { interviewSchedule, interviewer } = candidate?.progressSubmit[step];
  const { interviewDate, takenTime, interviewPlace, precautions } =
    interviewSchedule;

  const [selected, setSelected] = useState({});
  const [newInterviewDate, setNewInterviewDate] = useState([]);
  const [visibilityDropdown, setVisibilityDropdown] = useState(false);

  useOnClickOutside(dropdownRef, () => setVisibilityDropdown(false));

  const langSelectedHandler = (key) => {
    executeAction(setLang(key));
    setVisibilityDropdown(false);
  };

  useEffect(() => {
    const filteredDate = interviewDate?.filter((item) => item.start);
    setNewInterviewDate(filteredDate);
  }, [interviewDate]);

  const getHoursHandler = (date) => {
    const newDate = new Date(date);
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  };

  const selectedDate = (select) => {
    setSelected(select);
  };

  const onSubmitHandler = () => {
    if (Object.keys(selected).length === 0) {
      return toast(t('btn.selected-schedules'));
    }

    executeAction(
      setCandidateOfRecruitment({
        ouid: uid,
        aid: rid,
        cid,
        lang: 'ko',
        type: 'confirmDate',
        interviewerEmail: interviewer?.email,
        interviewerFirstName: interviewer?.firstName,
        interviewerLastName: interviewer?.lastName,
        candidateFirstName: candidate?.firstName,
        candidateLastName: candidate?.lastName,
        phoneNumber: candidate?.progressSubmit[1].phoneNumber,
        interviewDate: dateToDateFormat(new Date(selected.start), t('lang')),
        interviewLocation: interviewPlace,
        interviewTime: `${getHoursHandler(selected?.start)} ~ ${getHoursHandler(
          selected?.end
        )}`,
        progressSubmit: {
          [step]: {
            interviewSchedule: {
              interviewDate: selected.start,
              isDone: true,
              submitedAt: Timestamp.now(),
            },
          },
        },
      })
    )
      .then(() => {
        const accessToken = sessionStorage.getItem('access_token');

        if (accessToken) {
          axios.post(
            `${process.env.REACT_APP_POST_BOX}/outlook/calendar/create`,
            {
              access_token: accessToken,
              subject: `${candidate?.firstName} ${candidate?.lastName} 대면 면접`,
              start: dateFormat(selected.start),
              end: dateFormat(selected.end),
              location: interviewPlace,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'Publish-Type': process.env.REACT_APP_NODE_ENV,
              },
            }
          );
        }

        toast('인터뷰가 예약되었습니다!');
        sessionStorage.removeItem('access_token');
        navigate('/interview/completed');
      })
      .catch((e) => console.error(e));
  };

  return (
    <main id="pages-selected-interview-date">
      <div className="container">
        <section className="title-section">
          <h2 className="fs28">{t('title.selected-interview-date')}</h2>
          <section className="language-section">
            <article ref={dropdownRef} className="language-selector">
              <div
                className={`language-selector-toggle ${
                  visibilityDropdown && 'show'
                }`}
                onClick={() => setVisibilityDropdown(!visibilityDropdown)}
              >
                <EarthIcon />
                {window.localStorage.getItem('i18nextLng') && t(
                  `label.lang-${window.localStorage
                    .getItem('i18nextLng')
                    .slice(0, 2)}`
                )}
              </div>
              {visibilityDropdown && (
                <div className="language-selector-dropdown">
                  <ul className="language-selector-dropdown-list">
                    {(supportedLangs || []).map((lang, idx) => (
                      <li
                        key={lang.key}
                        className={`language-selector-dropdown-list-item ${lang.key}`}
                        onClick={() => langSelectedHandler(lang.key)}
                      >
                        {t(`label.lang-${lang.key}`)}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </article>
          </section>
        </section>
        <section className="contents-section">
          <article className="schedule-list">
            {newInterviewDate?.length !== 0 &&
              newInterviewDate?.map((date, idx) => (
                <aside
                  key={date?.id}
                  onClick={() => selectedDate(date)}
                  className={`${selected.id === date.id && 'selected'}`}
                >
                  <div className="information">
                    <Tag
                      label={`Option ${idx + 1}`}
                      fontSize={'fs12'}
                      color={'blue500'}
                      padding={'4px 6px'}
                    />
                    <div className="date">
                      <h5 className="fs24">
                        {dateToDateFormat(new Date(date?.start), t('lang'))}
                      </h5>
                    </div>
                    <div className="time">
                      <aside className="time-box start">
                        <span className="sub-title fs12">
                          {t('title.start-time')}
                        </span>
                        <div className="time-box fs20">
                          {getHoursHandler(date?.start)}
                        </div>
                      </aside>
                      <aside className="time-box end">
                        <span className="sub-title fs12">
                          {t('title.end-time')}
                        </span>
                        <div className="time-box fs20">
                          {getHoursHandler(date?.end)}
                        </div>
                      </aside>
                    </div>
                  </div>
                  <div className="taken-time">
                    <p className="fs14">
                      <Trans
                        t={t}
                        i18nKey="desc.taken-time"
                        components={{
                          takenTime,
                        }}
                      />
                    </p>
                  </div>
                </aside>
              ))}
          </article>
          <article className="caution">
            <div className="blue-box">
              <i>
                <ExclamationIcon />
              </i>
              <p className="fs16">
                <Trans
                  t={t}
                  i18nKey="desc.contact-mail"
                  components={{
                    link:
                      // <a href={`mailto:${interviewer?.email}`}>
                      interviewer?.email,
                    // </a>
                  }}
                />
              </p>
            </div>
          </article>
          {precautions && (
            <article className="helper">
              <pre>{precautions}</pre>
            </article>
          )}
        </section>
        <section className="button-section">
          <Button
            theme={'blue'}
            fontSize={'fs16'}
            size={40}
            label={t('btn.comfirm-interview-date')}
            backIcon={<ChevronRightIcon />}
            onClick={onSubmitHandler}
          />
        </section>
      </div>
    </main>
  );
};

export default SelectedDate;
