import React, { useEffect, useState } from 'react';
import { ReactComponent as FileIcon } from 'assets/images/icon/attach-file.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';
import { useTranslation } from 'react-i18next';
import {
  careerOptions,
  finalEducationOptions, languageLevelOptions,
  languageOptions,
  majorOptions,
  minorOptions, nationalCurrencyOptions,
} from 'constants/recruitments/applySelectOptions';
import ApplyInputBox from './ApplyInputBox';
import ApplyAddButton from './ApplyAddButton';

const ApplyFormCareerAcademic = ({
  type,
  input,
  handleInput,
  handleInputFile,
  handleInputCV,
  applyCV,
  setApplyCV,
  applyFile,
  setApplyFile,
  applyFileType,
  handleFileType,
  linkIndex,
  addLinkHandler,
}) => {
  const { t } = useTranslation();
  const maxFileCount = 2;

  return (
    <>
      {/* career */}
      <aside className="apply-input-box flex-strech">
        <div className="grey-box">
          <label className="fs16">
            {t('text-field.label.total-experience')} <i className="fs16">*</i>
          </label>
          <ApplyInputBox
            isSelectBox
            name="career"
            value={input.career}
            handleInput={handleInput}
            menus={careerOptions}
          />
          <pre className="input-caution fs12">
            {t('text-field.notify.total-experience')}
          </pre>
        </div>
        <div className="grey-box">
          <label className="fs16">
            {t('text-field.label.related-experience')}{' '}
            {input.career.key !== 'none' && <i className="fs16">*</i>}
          </label>
          <ApplyInputBox
            isSelectBox
            name="relatedCareer"
            value={input.relatedCareer}
            handleInput={handleInput}
            disabled={input.career.key === 'none'}
            menus={careerOptions}
          />
          <pre className="input-caution fs12">
            {t('text-field.notify.related-experience')}
          </pre>
        </div>
      </aside>
      {/* company name */}
      <aside className="apply-input-box flex">
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.company-name')}
          required={input.career.key !== 'none'}
          label={t('text-field.label.company-name')}
          name="companyName"
          value={input.companyName}
          handleInput={handleInput}
          disabled={input.career.key === 'none'}
        />
      </aside>
      {/* currency & salary */}
      <aside className="apply-input-box currency-wrapper">
        <span className="label">
          {t('text-field.label.total-compensation')} <i>*</i>
        </span>
        <div className="grey-box">
          <pre>{t('text-field.notify.total-compensation')}</pre>
        </div>
        <div className="flex">
          <ApplyInputBox
            isSelectBox
            name="nationalCurrency"
            value={input.nationalCurrency}
            handleInput={handleInput}
            disabled={input.career.key === 'none'}
            menus={nationalCurrencyOptions}
          />
          <ApplyInputBox
            inputType="text"
            placeholder="50,000,000"
            required
            name="salary"
            value={input.salary}
            handleInput={handleInput}
            disabled={input.career.key === 'none'}
          />
        </div>
        <div className="flex" />
      </aside>
      {/* 기타 변동급 & 금전보상 */}
      <aside className="apply-input-box etc-currency-wrapper flex">
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.variable-salary')}
          name="variableSalary"
          value={input.variableSalary}
          handleInput={handleInput}
          disabled={input.career.key === 'none'}
        />
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.monetary-compensation')}
          name="monetaryCompensation"
          value={input.monetaryCompensation}
          handleInput={handleInput}
          disabled={input.career.key === 'none'}
        />
      </aside>
      {/* aducation & major */}
      {type !== 'add' && (
        <aside className="apply-input-box flex">
          <ApplyInputBox
            label={t('text-field.label.final-education-level')}
            isSelectBox
            name="finalEducation"
            value={input.finalEducation}
            handleInput={handleInput}
            menus={finalEducationOptions}
          />
          <ApplyInputBox
            label={t('field.major')}
            isSelectBox
            name="major"
            value={input.major}
            handleInput={handleInput}
            menus={majorOptions}
          />
          <ApplyInputBox
            label={t('text-field.label.minor')}
            isSelectBox
            name="minor"
            value={input.minor}
            handleInput={handleInput}
            menus={minorOptions}
          />
        </aside>
      )}
      {/* foreign language & language level */}
      <aside className="apply-input-box flex">
        <ApplyInputBox
          label={t('text-field.label.foreign-language')}
          isSelectBox
          name="language"
          value={input.language}
          handleInput={handleInput}
          menus={languageOptions}
        />
        <ApplyInputBox
          label={t('text-field.label.language-level')}
          isSelectBox
          name="languageLevel"
          value={input.languageLevel}
          handleInput={handleInput}
          menus={languageLevelOptions}
        />
      </aside>
      {/* resume */}
      <ApplyInputBox
        key={applyCV.key ?? undefined}
        placeholder={t('text-field.label.file-upload')}
        label={t('menu.input.resume')}
        value={applyCV.name}
        required
        name={'cv'}
        isFileInput
        disabled={Object.values(applyCV).length !==0}
        icon={<FileIcon />}
        handleInput={handleInputCV}
      />
      {applyCV && Object.values(applyCV).length > 0 && (
        <aside className="apply-input-box resume-list-wrapper">
            <div key={applyCV.name} className="resume-grey-box">
              <p className="fs12">{`cv | ${applyCV.name}`}</p>
              <CloseIcon onClick={() => {
                setApplyCV({});
              }}/>
            </div>
        </aside>
      )}
      <aside className="apply-input-box resume-wrapper flex">
        <ApplyInputBox
          label={t('text-field.label.file-type')}
          isSelectBox
          name="fileType"
          disabled={applyFile.length >= maxFileCount}
          value={applyFileType}
          handleInput={handleFileType}
          menus={[
            { key: 'work', i18nKey: 'menu.input.work-experience' },
            { key: 'portfolio', i18nKey: 'menu.input.portfolio' },
            { key: 'other', i18nKey: 'menu.input.other' },
          ]}
        />
        <div className="align-wrapper">
          <ApplyInputBox
            key={applyFileType.key ?? undefined}
            placeholder={t('text-field.label.file-upload')}
            label={t('text-field.label.file-upload')}
            name={applyFileType ? applyFileType.key : 'work'}
            isFileInput
            disabled={!applyFileType || applyFile.length >= maxFileCount}
            caution={`${t('text-field.notify.file-upload')}\n${t(
              'text-field.notify.file-upload-example'
            )}`}
            icon={<FileIcon />}
            handleInput={handleInputFile}
          />
        </div>
      </aside>
      {applyFile.length !== 0 && (
        <aside className="apply-input-box resume-list-wrapper">
          {applyFile?.map((file, idx) => (
            <div key={file?.name + idx} className="resume-grey-box">
              <p className="fs12">{`${idx + 1}. ${file.key} | ${file.name}`}</p>
              <CloseIcon onClick={() => {
                setApplyFile(applyFile.filter((_, i) => i !== idx));
              }}/>
            </div>
          ))}
        </aside>
      )}
      <aside className="apply-input-box">
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.linked-in')}
          label={t('text-field.label.linked-in')}
          name="linkedIn"
          value={input.linkedIn}
          handleInput={handleInput}
          defaultText="https://"
        />
      </aside>
      <aside className="apply-input-box etc-link-wrapper flex">
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.etc-link')}
          label={t('text-field.label.etc-link')}
          name="etcLink1"
          value={input.etcLink1}
          handleInput={handleInput}
          defaultText="https://"
          caution={t('text-field.notify.etc-link')}
        />
        <ApplyAddButton
          onClick={() => addLinkHandler()}
          disabled={linkIndex !== 0}
        />
      </aside>
      {linkIndex >= 1 && (
        <aside className="apply-input-box etc-link-wrapper flex">
          <ApplyInputBox
            inputType="text"
            placeholder={t('text-field.label.etc-link')}
            label={t('text-field.label.etc-link')}
            name="etcLink2"
            value={input.etcLink2}
            handleInput={handleInput}
            defaultText="https://"
            caution={t('text-field.notify.etc-link')}
          />
          <ApplyAddButton
            onClick={() => addLinkHandler()}
            disabled={linkIndex !== 1}
          />
        </aside>
      )}
      {linkIndex >= 2 && (
        <aside className="apply-input-box etc-link-wrapper flex">
          <ApplyInputBox
            inputType="text"
            placeholder={t('text-field.label.etc-link')}
            label={t('text-field.label.etc-link')}
            name="etcLink3"
            value={input.etcLink3}
            handleInput={handleInput}
            defaultText="https://"
            caution={t('text-field.notify.etc-link')}
          />
          <ApplyAddButton onClick={() => addLinkHandler()} />
        </aside>
      )}
    </>
  );
};

export default ApplyFormCareerAcademic;
