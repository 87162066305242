import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import { ReviewingFieldEdit } from 'components/recruitment/detail/reviewing/ReviewingFieldEdit';
import { ReviewingFieldView } from 'components/recruitment/detail/reviewing/ReviewingFieldView';
import { setCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import { executeAction } from 'utils/redux';
import { toast } from 'utils/notify';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

const ReviewingFieldBox = ({ rid, currentProgress, email, createdAt, count }) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = React.useState(false);
  const [editData, setEditData] = React.useState(currentProgress);
  const [canSubmit, setCanSubmit] = useState(false);

  const { userInfo } = useSelector((state) => state.auth);


  const handleClickEditButton = useCallback(() => {
    if(isEdit){
      executeAction(
        setCandidateOfRecruitment({
          aid: rid,
          cid : email,
          progressSubmit: {
            1: {
              ...editData
            },
          },
        })
      ).unwrap()
        .then(() => {
          toast(t('toast.desc.submission-complete'));
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
    setIsEdit(prev => !prev);
  },[isEdit, editData]);

  useEffect(() => {
    const { countryCode, phoneNumber, birth, gender, country, location, career, channel, applyChannel, applyLocation } = editData;

    const allPropertiesExist = countryCode && phoneNumber && birth && gender && country && location && career && channel && applyChannel.length !== 0 && applyLocation.length !== 0;

    setCanSubmit(allPropertiesExist);
  }, [editData]);

  return (
    <section className="information-container">
      <article className="information-wrapper">
        {userInfo?.memberType <= 5 &&
        <div className="information-edit-button-wrapper">
            <Button label={isEdit ? t('btn.save') : t('btn.edit')} theme='grey' isDisabled={!canSubmit} size={26}
                   onClick={handleClickEditButton} />
        </div>}
        {isEdit ? <ReviewingFieldEdit editData={editData} handleChangeEditData={setEditData} email={email} createdAt={createdAt} count={count}/> :
          <ReviewingFieldView currentProgress={currentProgress} email={email} createdAt={createdAt} count={count}/>}
      </article>
    </section>
  );
};

export default ReviewingFieldBox;
