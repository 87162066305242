import React, { useEffect, useState } from 'react';
import { ReactComponent as ChevronBottomIcon } from 'assets/images/icon/chevron-bottom-dropdown.svg';
import { ExtractFirstEmailChar } from 'utils/hooks';
import { nameConversionLanguage } from 'utils/converter';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import { useSelector } from 'react-redux';

const MembersSlideBox = ({
  label,
  value,
  onChange,
  items,
  onClick,
  onPassInvited,
  currentCandidate,
  isResetting,
  retryInvitedModal,
}) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);

  const [visibilityDropdown, setVisibilityDropdown] = useState(true);
  const [dropdownAnimation, setDropdownAnimation] = useState(true);
  const [repeat, setRepeat] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    if (visibilityDropdown) {
      clearTimeout(repeat);
      setDropdownAnimation(true);
    } else {
      setRepeat(setTimeout(() => setDropdownAnimation(false), 150));
    }
  }, [visibilityDropdown]);

  const onClickToggleButton = () => {
    setVisibilityDropdown(!visibilityDropdown);
  };

  const onSelectMemberEvent = (member) => {
    setId(member?.id);
    onChange(member);
  };

  return (
    <div className="member-list-slide-box">
      <div
        className={`members-title-box ${visibilityDropdown}`}
        onClick={onClickToggleButton}
      >
        <aside className="title-box">
          <span className="apply-agree-title fs16">
            {Object.keys(value).length === 0 ? label : value.email}
          </span>
        </aside>
        <aside className="icon">
          <ChevronBottomIcon />
        </aside>
      </div>
      {dropdownAnimation && (
        <div className="member-list-container">
          <div
            className={`member-list-wrapper ${
              visibilityDropdown ? 'slide-in' : 'slide-out'
            }`}
          >
            <ul>
              {items &&
                items?.map((item) => (
                  <li
                    key={item?.id}
                    className={item?.id === id && 'selected'}
                    onClick={() => onSelectMemberEvent(item)}
                  >
                    <aside className="initial-box">
                      <div className={`round fs16 ${item?.randomColor}`}>
                        {ExtractFirstEmailChar(item?.email)}
                      </div>
                    </aside>
                    <aside className="info-box">
                      <h6 className="fs14">
                        {nameConversionLanguage(
                          item?.firstName,
                          item?.lastName,
                          t('lang')
                        )}
                      </h6>
                      <p className="fs12">{`${item?.workspace} | ${item?.team}`}</p>
                    </aside>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      <div className="submit-button">
        <div>
          <Button
            label={t('title.intive')}
            theme="grey"
            size="48"
            fontSize="fs16"
            onClick={onClick}
          />
        </div>
        {(currentCandidate.recruitProgress === 2 ||
          (retryInvitedModal !== 'retry' &&
            currentCandidate.progressSubmit[currentCandidate.recruitProgress]
              .interviewer?.invitationPoint !== 'not yet' &&
            !isResetting)) && (
          <div>
            <Button
              label={t('title.not-yet')}
              theme="grey"
              size="48"
              fontSize="fs16"
              onClick={onPassInvited}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MembersSlideBox;
