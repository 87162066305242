import React from 'react';

const InvalidApply = () => {
  return (
    <main id="pages-expired-apply">
      <section className="expired-apply-container">
        <article className="expired-text-wrapper">
          <h2 className="fs28">유효하지 않은 링크 입니다.</h2>
          <p className="fs16">링크 주소를 다시 확인해주세요.</p>
        </article>
      </section>
    </main>
  );
};

export default InvalidApply;
