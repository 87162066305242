export const convertFileToBlob = async (file) =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

export const convertMemberTypeToTitle = (type) => {
  let title;

  switch (type) {
    case 1:
    case 5:
      title = 'menu.workspace-owner';
      break;
    case 10:
      title = 'menu.recruiter';
      break;
    case 15:
      title = 'menu.interviewer';
      break;
    case 20:
      title = 'hiring-manager';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const nameConversionLanguage = (firstName, lastName, lang) => {
  switch (lang) {
    case 'ko':
      return `${lastName} ${firstName}`;
    case 'en':
      return `${firstName} ${lastName}`;
    default:
      return `${lastName} ${firstName}`;
  }
};

export const convertQuestionType = (type) => {
  const splitType = type?.split(',');

  switch (splitType[0] || type) {
    case 'tag.video':
    case 'video':
      return 'tag.video';
    case 'tag.multiple-choice':
    case 'multiple-choice':
      return 'tag.multiple-choice';
    case 'tag.subjective':
    case 'tag.essay':
    case 'essay':
      return 'tag.essay';
    case 'tag.code':
      return 'tag.code';
    case 'tag.front-code':
      return 'tag.code';
  }
};

export const sumSalary = (sal1, sal2) => {
  if (!sal1 || !sal2) return;

  const num1 = sal1.replace(/[^0-9]/g, '');
  const num2 = sal2.replace(/[^0-9]/g, '');

  const number = Number(num1) + Number(num2);

  return number.toLocaleString('ko-KR');
};

export const convertGradeToScore = (grade) => {
  let score;

  switch (grade) {
    case 5:
      score = 3;
      break;
    case 10:
      score = 4;
      break;
    case 15:
      score = 5;
      break;
    case 20:
      score = 6;
      break;
    case 25:
      score = 8;
      break;
  }

  return score;
};
