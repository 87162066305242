import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import { useSelector } from 'react-redux';

import { ReactComponent as Graph0 } from 'assets/images/icon/graph-sample0.svg';
import { ReactComponent as Graph1 } from 'assets/images/icon/graph-sample1.svg';
import { ReactComponent as Graph2 } from 'assets/images/icon/graph-sample2.svg';
import { ReactComponent as Graph3 } from 'assets/images/icon/graph-sample3.svg';

const OBPIGuide = () => {
  const { t } = useTranslation();
  const curLang = useSelector((state) => state.variable?.lang.slice(0, 2));
  const { dismissModal } = useModal();

  const audit_block = [
    { title: 'title.dissimulation', desc: 'dsec.dissimulation' },
    { title: 'title.contradiction', desc: 'dsec.contradiction' },
    { title: 'title.despondency', desc: 'dsec.despondency' },
    { title: 'title.inattention', desc: 'dsec.inattention' },
  ];

  return (
    <article className="modals-assessments-obpi-guide">
      <section className="obpi-guide-modal-container">
        <header className="obpi-guide-modal-header">
          <div className="obpi-guide-modal-header-title fs16">
            {t('title.response-audit')}
          </div>
          <button
            className="obpi-guide-modal-header-close"
            onClick={dismissModal}
          />
        </header>
        <section className="obpi-guide-modal-section">
          <div className="obpi-guide-modal-section-desc fs16">
            {t('desc.the-audit')}
          </div>
          <div className="obpi-guide-modal-section-graph">
            <Graph0 className="graph" />
            <Graph1 className="graph" />
            <Graph2 className="graph" />
            <Graph3 className="graph" />
          </div>
          {audit_block.map((audit, idx) => (
            <div className="obpi-guide-modal-section-block" key={idx}>
              <div className="obpi-guide-modal-section-block-title fs16">
                {t(audit.title)}
              </div>
              <div className="obpi-guide-modal-section-block-desc fs16">
                {t(audit.desc)}
              </div>
            </div>
          ))}
        </section>
      </section>
    </article>
  );
};

export default OBPIGuide;
