import React from 'react';
import { Outlet } from 'react-router-dom';

const DetailContents = ({ recruitment, candidates, userInfo }) => {
  return (
    <aside>
      <Outlet context={{ recruitment, candidates, userInfo }} />
    </aside>
  );
};

export default DetailContents;
