export const convertResumeType = (type) => {
  let title;

  switch (type) {
    case 'cv':
      title = 'Resume/CV';
      break;
    case 'portfolio':
      title = 'Portfolio';
      break;
    case 'work':
      title = 'Work Experience';
      break;
    case 'other':
      title = 'Other';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertGender = (gender) => {
  let title;

  switch (gender) {
    case 'male':
      title = 'menu.label.male';
      break;
    case 'female':
      title = 'menu.label.female';
      break;
    case 'non-disclosure':
      title = 'menu.label.non-disclosure';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertLanguage = (lang) => {
  let title;

  switch (lang) {
    case 'korean':
      title = 'menu.input.korean';
      break;
    case 'english':
      title = 'menu.input.english';
      break;
    case 'mandarinChinese':
      title = 'menu.input.mandarin-chinese';
      break;
    case 'japanese':
      title = 'menu.input.japanese';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertCareer = (career) => {
  let title;

  switch (career) {
    case 'none':
      title = 'menu.input.none';
      break;
    case '1year':
      title = 'menu.input.1-year';
      break;
    case '2years':
      title = 'menu.input.2-years';
      break;
    case '3years':
      title = 'menu.input.3-years';
      break;
    case '4years':
      title = 'menu.input.4-years';
      break;
    case '5years':
      title = 'menu.input.5-years';
      break;
    case '6years':
      title = 'menu.input.6-years';
      break;
    case '7years':
      title = 'menu.input.7-years';
      break;
    case '8years':
      title = 'menu.input.8-years';
      break;
    case '9years':
      title = 'menu.input.9-years';
      break;
    case '10years':
      title = 'menu.input.10-years';
      break;
    case '11years':
      title = 'menu.input.11-years';
      break;
    case '12years':
      title = 'menu.input.12-years';
      break;
    case '13years':
      title = 'menu.input.13-years';
      break;
    case '14years':
      title = 'menu.input.14-years';
      break;
    case '15years':
      title = 'menu.input.15-years';
      break;
    case '16years':
      title = 'menu.input.16-years';
      break;
    case '17years':
      title = 'menu.input.17-years';
      break;
    case '18years':
      title = 'menu.input.18-years';
      break;
    case 'for more than 19 years':
      title = 'menu.input.for-over-19-years';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertFinalEducation = (edu) => {
  let title;

  switch (edu) {
    case 'highSchool':
      title = 'menu.input.high-school-graduation';
      break;
    case 'college':
      title = 'menu.input.college-graduation';
      break;
    case 'whileInUniversity':
      title = 'menu.input.while-in-university';
      break;
    case 'university':
      title = 'menu.input.university-graduation';
      break;
    case 'master':
      title = 'menu.input.finish-graduate-school';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertCountry = (country) => {
  let title;

  switch (country) {
    case 'korea':
      title = 'menu.input.korea';
      break;
    case 'usa':
      title = 'menu.input.america';
      break;
    case 'japan':
      title = 'menu.input.japan';
      break;
    case 'china':
      title = 'menu.input.china';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertMajor = (major) => {
  let title;

  switch (major) {
    case 'fashionDesign':
      title = 'menu.input.art-fashion-design';
      break;
    case 'foreignLang':
      title = 'menu.input.foreign-language';
      break;
    case 'retailServices':
      title = 'menu.input.retail-services';
      break;
    case 'university':
      title = 'menu.input.university';
      break;
    case 'other':
      title = 'menu.input.other';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertChannel = (channel) => {
  let title;

  switch (channel) {
    case 'job':
      title = 'menu.input.job-center-unit';
      break;
    case 'sns':
      title = 'menu.input.sns';
      break;
    case 'school':
      title = 'menu.input.school-industry-academic';
      break;
    case 'friend':
      title = 'menu.input.friend';
      break;
    case 'searchFirm':
      title = 'menu.input.search-firm';
      break;
    case 'others':
      title = 'menu.input.other';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertSubChannel = (applicationInfo) => {
  switch (applicationInfo.channel) {
    case 'sns':
      return convertSNS(applicationInfo.sns);
    case 'job':
      return convertJobCenter(applicationInfo.jobCenter);
    case 'school':
      return convertSchool(applicationInfo.school);
    case 'searchFirm':
      return convertSearchFirm(applicationInfo.searchFirm);
    case 'others':
      return applicationInfo.otherChannel;
    case 'friend':
      return applicationInfo.nameOfEmployee;
    default:
      return '';
  }
};

export const convertJobCenter = (job) => {
  let title;

  switch (job) {
    case 'saramin':
      title = 'menu.input.saramin';
      break;
    case 'linkedin':
      title = 'menu.input.linkedin';
      break;
    case 'jobkorea':
      title = 'menu.input.jobkorea';
      break;
    case 'shopmanet':
      title = 'menu.input.shopmanet';
      break;
    case 'sns':
      title = 'menu.input.job-center-sns';
      break;
    case 'others':
      title = 'menu.input.other';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertSearchFirm = (search) => {
  let title;

  switch (search) {
    case 'ascentask-korea':
      title = 'menu.input.ascentask-korea';
      break;
    case 'humanest':
      title = 'menu.input.humanest';
      break;
    case 'nterway':
      title = 'menu.input.nterway';
      break;
    case 'henny-mccoy':
      title = 'menu.input.henny-mccoy';
      break;
    case 'unico-search':
      title = 'menu.input.unico-search';
      break;
    case 'new-life-consultancy':
      title = 'menu.input.new-life-consultancy';
      break;
    case 'lina':
      title = 'menu.input.lina-consulting';
      break;
    case 'hr-agent':
      title = 'menu.input.hr-agent';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertSchool = (school) => {
  let title;
  /**
   * @caution options 받으면 수정해야 함
   */
  switch (school) {
    case 'jeonghwa':
      title = 'menu.input.jeonghwa';
      break;
    case 'sookmyung':
      title = 'menu.input.sookmyung';
      break;
    case 'jeonju':
      title = 'menu.input.jeonju';
      break;
    case 'keimyung':
      title = 'menu.input.keimyung';
      break;
    case 'yeungnam':
      title = 'menu.input.yeungnam';
      break;
    case 'jangan':
      title = 'menu.input.jangan';
      break;
    case 'suwon-women':
      title = 'menu.input.suwon-women';
      break;
    case 'woosong':
      title = 'menu.input.woosong';
      break;
    case 'woosong-college':
      title = 'menu.input.woosong-college';
      break;
    case 'yongin-art-science':
      title = 'menu.input.yongin-art-science';
      break;
    case 'jei':
      title = 'menu.input.jei';
      break;
    case 'kyungbok':
      title = 'menu.input.kyungbok';
      break;
    case 'pai-chai':
      title = 'menu.input.pai-chai';
      break;
    case 'yuhan':
      title = 'menu.input.yuhan';
      break;
    case 'dong-seo':
      title = 'menu.input.dong-seo';
      break;
    case 'hoseo':
      title = 'menu.input.hoseo';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertSNS = (sns) => {
  let title;

  switch (sns) {
    case 'linkedIn':
      title = 'LinkedIn';
      break;
    case 'facebook':
      title = 'Facebook';
      break;
    case 'meta':
      title = 'Meta';
      break;
    case 'instagram':
      title = 'Instagram';
      break;
    case 'other':
      title = 'Other';
      break;
    default:
      title = '';
      break;
  }

  return title;
};

export const convertDisabled = (bool) => {
  let title;

  if (bool) {
    title = 'menu.suspended';
  } else {
    title = 'menu.active';
  }

  return title;
};

export const convertTeam = (team) => {
  let title;

  switch (team) {
    case 'hr':
      title = 'menu.hr-manager';
      break;
    case 'finance':
      title = 'menu.finance';
      break;
    case 'gucci9':
      title = 'menu.gucci-9';
      break;
    case 'store':
      title = 'menu.store';
      break;
  }

  return title;
};

export const convertJobFunction = (job) => {
  let title;

  switch (job) {
    case 'junior':
      title = 'menu.input.junior';
      break;
    case 'senior':
      title = 'menu.input.senior';
      break;
    case 'manager':
      title = 'menu.input.manager';
      break;
    case 'director':
      title = 'menu.input.director';
      break;
    case 'c-level':
      title = 'menu.input.c-level';
      break;
  }

  return title;
};

export const convertWorkspace = (location) => {
  let title;

  switch (location) {
    case 'seoul':
      title = 'menu.seoul';
      break;
    case 'gyeonggi_incheon':
      title = 'menu.gyeonggi-incheon';
      break;
    case 'daejeon':
      title = 'menu.daejeon';
      break;
    case 'daegu':
      title = 'menu.daegu';
      break;
    case 'gwangju':
      title = 'menu.gwangju';
      break;
    case 'busan':
      title = 'menu.busan';
      break;
  }

  return title;
};

export const convertPositionInfo = (job) => {
  let value;

  switch (job) {
    case 'retail':
      value = 'radio.input.retail';
      break;
    case 'office':
      value = 'radio.input.office';
      break;
    default:
      value = '';
      break;
  }

  return value;
};

export const covertJobPosition = (job) => {
  let value = '';

  switch(job) {
    case 'SD':
      value = 'menu.input.shinsegae-daegu';
      break;
    case 'GT':
      value = 'menu.input.galleria-timeworld';
      break;
    case 'LB':
      value = 'menu.input.lotte-busan';
      break;
    case 'HT':
      value = 'menu.input.hyundai-daegu';
      break;
    case 'HU':
      value = 'menu.input.hyundai-ulsan';
      break;
    case 'LD':
      value = 'menu.input.lotte-daegu';
      break;
    case 'LK':
      value = 'menu.input.lotte-gwangbok';
      break;
    case 'SDJ':
      value = 'menu.input.shinsegae-daejeon';
      break;
    case 'HS':
      value = 'menu.input.the-hyundai-seoul';
      break;
    case 'SGJ':
      value = 'menu.input.shinsegae-gwangju';
      break;
    case 'LI':
      value = 'menu.input.lotte-incheon';
      break;
    case 'ST':
      value = 'menu.input.shinsegae-times-square';
      break;
    case 'HI':
      value = 'menu.input.hyundai-kintex';
      break;
    case 'LG':
      value = 'menu.input.lotte-gwangju';
      break;
    case 'HSH':
      value = 'menu.input.The-hyundai-seoul-high-end';
      break;
    case 'GG':
      value = 'menu.input.galleria-gwanggyo';
      break;
    case 'LW':
      value = 'menu.input.lotte-world';
      break;
    case 'SJ':
      value = 'menu.input.shinsegae-jukjeon';
      break;
    case 'SU':
      value = 'menu.input.shinsegae-uijeongbu';
      break;
    case 'SH':
      value = 'menu.input.shinsegae-hanam';
      break;
    case 'SM':
      value = 'menu.input.shinsegae-main';
      break;
    case 'HD':
      value = 'menu.input.hyundai-mokdong';
      break;
    case 'LS':
      value = 'menu.input.lotte-suwon';
      break;
    case 'FL':
      value = 'menu.input.flagship-shop';
      break;
    case 'Gaok':
      value = 'menu.input.gaok';
      break;
    case 'HM':
      value = 'menu.input.hyundai-main';
      break;
    case 'GL':
      value = 'menu.input.galleria';
      break;
    case 'SGW':
      value = 'menu.input.shinsegae-gangnam-women';
      break;
    case 'HP':
      value = 'menu.input.hyundai-pangyo';
      break;
    case 'GLW':
      value = 'menu.input.galleria-women';
      break;
    case 'LMW':
      value = 'menu.input.lotte-main-women';
      break;
    case 'LWT':
      value = 'menu.input.lotte-world-tower';
      break;
    case 'HMW':
      value = 'menu.input.hyundai-main-women';
      break;
    case 'SC':
      value = 'menu.input.shinsegae-centumcity';
      break;
    case 'LM':
      value = 'menu.input.lotte-main';
      break;
    case 'LWW':
      value = 'menu.input.lotte-world-c2-women';
      break;
    case 'SG':
      value = 'menu.input.shinsegae-gangnam';
      break;
    case 'HC':
      value = 'menu.input.hyundai-coex';
      break;
    case 'SMM':
      value = 'menu.input.shinsegae-main-men';
      break;
    case 'SJM':
      value = 'menu.input.shinsegae-jukjeon-men';
      break;
    case 'LBM':
      value = 'menu.input.lotte-busan-men';
      break;
    case 'SDJM':
      value = 'menu.input.shinsegae-daejeon-men';
      break;
    case 'SCM':
      value = 'menu.input.shinsegae-centumcity-men';
      break;
    case 'GLM':
      value = 'menu.input.galleria-men';
      break;
    case 'LWM':
      value = 'menu.input.lotte-world-c2-men';
      break;
    case 'HMM':
      value = 'menu.input.hyundai-main-men';
      break;
    case 'HPM':
      value = 'menu.input.hyundai-pangyo-men';
      break;
    case 'SGJM':
      value = 'menu.input.shinsegae-gwangju-men';
      break;
    case 'LMM':
      value = 'menu.input.lotte-main-men';
      break;
    case 'HCM':
      value = 'menu.input.hyundai-coex-men';
      break;
    case 'SGM':
      value = 'menu.input.shinsegae-gangnam-men';
      break;
    case 'HGO':
      value = 'menu.input.hyundai-gimpo-outlet';
      break;
    case 'YJO':
      value = 'menu.input.yeoju-premium-outlet';
      break;
    case 'LBO':
      value = 'menu.input.lotte-busan-outlet';
      break;
    case 'SL1DF':
      value = 'menu.input.shinsegae-incheon-t1(D)';
      break;
    case 'SI2DF':
      value = 'menu.input.shinsegae-incheon-t2(D)';
      break;
    case 'SSDF':
      value = 'menu.input.shilla-seoul(D)';
      break;
    case 'LWDF':
      value = 'menu.input.lotte-world(D)';
      break;
    case 'SMDF':
      value = 'menu.input.shinsegae-main(D)';
      break;
    case 'HCDF':
      value = 'menu.input.hyundai-coex(D)';
      break;
    case 'LMDF':
      value = 'menu.input.lotte-main(D)';
      break;
  }

  return value;
};

export const convertLanguageLevel = (level) => {
  let value = '';

  switch (level) {
    case 'native':
      value = 'menu.input.native';
      break;
    case 'fluent':
      value = 'menu.input.fluent';
      break;
    case 'conversational':
      value = 'menu.input.conversational';
      break;
    default:
      value = '';
      break;
  }

  return value;
};

export const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};