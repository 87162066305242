import React from 'react';
import { useTranslation } from 'react-i18next';

const FeedbackScoreComponent = ({ objKey, candidate, feedback }) => {
  const { t } = useTranslation();
  const lang = t('lang');

  return (
    <div className="feedback-wrapper score-bar">
      <div className="feedback-score">
        <h6 className="feedback-title">{feedback}</h6>
        <span className="feedback-score">
          {candidate?.results[objKey]?.total_feedback[lang][feedback]?.score *
            100 || 0}
          %
        </span>
      </div>
    </div>
  );
};

export default FeedbackScoreComponent;
