import React from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  getAssessmentsOfCurrentUser,
  sendEmailToCandidate,
} from 'store/slices/assessmentsSlice';
import { useTranslation } from 'react-i18next';
import { useStatus } from 'utils/status';
import { toast } from 'utils/notify';
import { getUserInfo } from 'store/slices/authSlice';
import Assessment from './detail/AssessmentPrint';
import NewAssessmentPrint from './detail/NewAssessmentPrint';

const Print = ({}) => {
  const { t } = useTranslation();
  const { cid } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const aid = searchParams.get('aid');
  const entry = searchParams.get('entry');
  const location = useLocation();
  const [otherAssessments, setOtherAssessments] = React.useState([]);
  const [otherAssessment, setOtherAssessment] = React.useState(null);
  const { setIsEntireLoading } = useStatus();
  const { assessment, candidate } = location.state || {};

  const [logoURL, setLogoURL] = React.useState('');

  React.useEffect(() => {
    executeAction(getAssessmentsOfCurrentUser())
      .unwrap()
      .then(({ docs: _docs }) => {
        const docs = (_docs || []).map((x) => ({
          ...x,
          display: {
            ko: x?.name,
            en: x?.name,
          },
        }));
        setOtherAssessments(docs || []);
      })
      .catch((e) => {
        console.dir(e);
        setOtherAssessments([]);
      });
  }, []);

  React.useEffect(() => {
    executeAction(getUserInfo())
      .unwrap()
      .then(({ logoURL }) => {
        setLogoURL(logoURL || '');
      })
      .catch((e) => {
        setLogoURL('');
      });
  }, [aid]);

  const inviteOtherAssessmentHandler = () => {
    setIsEntireLoading(true);
    executeAction(
      sendEmailToCandidate({
        type: 'resend',
        aid: otherAssessment?.id || '',
        firstName: candidate?.firstName || '',
        lastName: candidate?.lastName || '',
        email: candidate?.email || '',
      })
    )
      .unwrap()
      .then(() => {
        setIsEntireLoading(false);
        toast(t('toast.desc.success-invite'));
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });
  };

  return (
    <main className="pages-protected-candidates-print">
      <header className="header">
        {logoURL !== '' && (
          <div className="logo">
            <img alt="" src={logoURL} />
          </div>
        )}
      </header>
      {aid ? <NewAssessmentPrint idx={0} cid={cid} aid={aid} /> : <></>}
    </main>
  );
};

export default Print;
