import React, { useEffect, useState } from 'react';
import BasicFile from 'components/input/BasicFile';
import { ReactComponent as AddIcon } from 'assets/images/icon/add-circle-outline_grey_20x20.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-16x16.svg';
import { toast } from 'utils/notify';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';

const DetailRightForm = ({ fetchFile }) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [dragActive, setDragActive] = useState(false);

  useEffect(() => {
    const limitedBytes = 1024 * 1024 * 10; // 10 MB
    // const totalSize = newFiles.reduce((totalSize, file) => totalSize + file.size, 0);
    files?.map((file) => {
      if (file?.size > limitedBytes) {
        toast('Please upload files no larger than 10 MB.');
        setFiles([]);
      }
    });

    fetchFile(files);
  }, [files]);

  const handleInput = (e, type) => {
    let files;

    if (type === 'drag') {
      files = e?.dataTransfer?.files;
    } else {
      files = e?.target?.files;
    }

    const newFiles = Array.from(files);

    setFiles((prev) => [...prev, ...newFiles]);
  };

  const handleInputDelete = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  // drag & drop type
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // drag & drop input file
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleInput(e, 'drag');
    }
  };

  return (
    <>
      <div className="form-box">
        <BasicFile
          label={t('file.input.label.payslip')}
          icon={<AddIcon />}
          name={'paye'}
          className={`document-file ${dragActive ? 'active' : ''}`}
          onChange={handleInput}
          required
          multiple={'multiple'}
          caution={t('text-field.notify.file-upload')}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          placeholder={t('text-field.placeholder.file-drag-and-drop')}
        />
      </div>
      {files?.length !== 0 && (
        <div className="files-list">
          <ul className="list-wrapper">
            {files?.map((file, idx) => (
              <li key={idx}>
                <div className="file-info">
                  <p>{`${idx + 1}. ${file.name}`}</p>
                </div>
                <div className="file-delete">
                  <Button
                    label={<CloseIcon />}
                    theme={'borderGrey'}
                    padding={6}
                    radius={8}
                    onClick={() => handleInputDelete(idx)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default DetailRightForm;
