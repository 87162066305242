import React from 'react';

const ExpiredApply = () => {
  return (
    <main id="pages-expired-apply">
      <section className="expired-apply-container">
        <article className="expired-text-wrapper">
          <h2 className="fs28">공고 접수 기간이 아닙니다.</h2>
          <p className="fs16">공고 일정을 확인해주세요.</p>
        </article>
      </section>
    </main>
  );
};

export default ExpiredApply;
