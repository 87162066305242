import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  _getCandidates,
  _getCandidate,
  _getAssessment,
  _getCandidateInAssessment,
  _gucciApplyInfo,
} from 'utils/firebase/candidates';
import { serializeError } from 'serialize-error';
import axios from 'axios';

const initialState = {};

export const getCandidates = createAsyncThunk(
  'candidates/getCandidates',
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const docs = await _getCandidates({
        uid,
      });
      return {
        docs,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidate = createAsyncThunk(
  'candidates/getCandidate',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { cid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const doc = await _getCandidate({ uid, cid });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getAssessment = createAsyncThunk(
  'candidates/getAssessment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const doc = await _getAssessment({
        uid,
        aid,
      });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getAssessments = createAsyncThunk(
  'candidates/getAssessments',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { assessmentsObj } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const newAssessmentsObj = {};
      for (const aid of Object.keys(assessmentsObj || [])) {
        const doc = await _getAssessment({ uid, aid });
        newAssessmentsObj[aid] = doc;
      }
      return {
        assessments: newAssessmentsObj,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidateInAssessment = createAsyncThunk(
  'candidates/getCandidateInAssessment',
  async (payload = {}, { rejectWithValue, getState }) => {
    const { aid, cid } = payload;
    try {
      const uid =
        getState().auth.userInfo?.ownerUid || getState().auth.user?.uid;
      const doc = await _getCandidateInAssessment({
        uid,
        aid,
        cid,
      });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const gucciApplyInfo = createAsyncThunk(
  'candidates/gucciApplyInfo',
  async (payload = {}, { rejectWithValue }) => {
    const { cid } = payload;
    try {
      const doc = await _gucciApplyInfo({
        cid,
      });
      return {
        doc,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const getAllCandidates = createAsyncThunk(
  'candidates/getAllCandidates',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const allCandidates = await axios.get(
        `${process.env.REACT_APP_SWAGGER_PATH}/munchskill/gucci_applicant`,
        {
          headers: {
            accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': true,
          },
        }
      );
      return allCandidates.data;
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const candidatesSlice = createSlice({
  name: 'candidates',
  initialState,
  reducers: {},
  extraReducers: {},
});

export const {} = candidatesSlice.actions;

export default candidatesSlice.reducer;
