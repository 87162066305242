import React from 'react';
import { useTranslation } from 'react-i18next';

const MultipleChoiceTableHead = ({ heads }) => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>{heads && heads?.map((head) => <th key={head}>{t(head)}</th>)}</tr>
    </thead>
  );
};

export default MultipleChoiceTableHead;
