import React from 'react';
import { convertHtmlForDisplay } from 'utils/string';
import { useTranslation } from 'react-i18next';
import { Radio } from '@mui/material';

const CustomQuestion = ({ question }) => {
  const { t } = useTranslation();

  /** =======================
   * @function convertSection : 문제들의 종류별로 다른 안내 문구를 리턴하는 함수
   * @param {*} question(string)
   * @returns (string)
  =========================== */
  const convertSection = (question) => {
    switch (question) {
      case 'essay':
        return t('desc.this-is-a-essay-test');
      case 'video':
        return t('desc.this-is-a-video-test');
      case 'code':
        return t('desc.this-is-a-coding-test');
      case 'file-upload':
        return t('desc.this-is-a-file-upload-test');
      default:
        return '';
    }
  };

  return (
    <article className="question-contents-list">
      <aside className="question-contents-list-items">
        <div className="question-contents-list-title">
          <h6 className="fs24">Q1</h6>
          <pre
            className="fs16"
            dangerouslySetInnerHTML={{
              __html: convertHtmlForDisplay(question?.question),
            }}
          />
        </div>
        <div className="question-contents-list-choice">
          {question?.section !== 'multiple-choice' ? (
            // 비디오, 주관식, 파일업로드 커스텀 질문
            <div className="grey-box">
              <p className="fs18">{convertSection(question?.section)}</p>
            </div>
          ) : (
            // 객관식 커스텀 질문
            <ul>
              {(question?.answers?.list || []).map((answer) => (
                <li className="fs16">
                  <div className="container">
                    <Radio
                      className="radio-button"
                      checked={false}
                      disabled
                      name="radio-buttons"
                      inputProps={{ 'aria-label': answer }}
                    />
                    <p className="fs16">
                      {typeof answer === 'boolean'
                        ? JSON.stringify(answer)
                        : answer}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </aside>
    </article>
  );
};

export default CustomQuestion;
