import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { useModal } from 'utils/modal';
import MaterialSelect from 'components/select/MaterialSelect';
import MaterialInput from 'components/input/MaterialInput';
import { CircularProgress } from '@mui/material';
import Iframe from 'react-iframe';
import { executeAction } from 'utils/redux';
import { copySpreadsheet } from 'store/slices/assessmentsSlice';
import { v4 as uuidV4 } from 'uuid';

const ORGSHEETID = '1NbKNWn8dIjHd1eo1GB_V6HzgOpuymWBzgkVgr5SupVQ';

const Spreadsheet = ({ idx, subjectiveTests, setSubjectiveTests }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSpreadsheetLoading, setIsSpreadsheetLoading] = React.useState(false);
  const [limitTime, setLimitTime] = React.useState('');
  const [question, setQuestion] = React.useState('');
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [spreadsheetsUrl, setSpreadsheetsUrl] = React.useState('');
  const [spreadsheetsId, setSpreadsheetsId] = React.useState('');
  const { dismissModal } = useModal();

  React.useEffect(() => {
    setIsSpreadsheetLoading(true);
    executeAction(copySpreadsheet({ spreadsheetId: ORGSHEETID }))
      .unwrap()
      .then(({ spreadsheetsUrl, spreadsheetsId }) => {
        setSpreadsheetsUrl(spreadsheetsUrl);
        setSpreadsheetsId(spreadsheetsId);
        setIsSpreadsheetLoading(false);
      })
      .catch((e) => {
        console.dir(e);
        setIsSpreadsheetLoading(false);
        setSpreadsheetsUrl('');
        setSpreadsheetsId('');
      });
  }, []);

  React.useEffect(() => {
    if (Boolean(limitTime) && Boolean(question)) {
      return setCanSubmit(true);
    }
    setCanSubmit(false);
  }, [limitTime, question]);

  const onDismissHandler = () => {
    dismissModal();
  };

  const onSubmitHandler = () => {
    setIsLoading(true);
    if (idx >= 0) {
      setSubjectiveTests([
        ...subjectiveTests.slice(0, idx),
        {
          uuid: uuidV4(),
          section: 'spreadsheets',
          limit: limitTime,
          question,
          spreadsheetsUrl,
          spreadsheetsId,
        },
        ...subjectiveTests.slice(idx + 1, subjectiveTests?.length),
      ]);
    } else {
      setSubjectiveTests([
        ...subjectiveTests,
        {
          uuid: uuidV4(),
          section: 'spreadsheets',
          limit: limitTime,
          question,
          spreadsheetsUrl,
          spreadsheetsId,
        },
      ]);
    }

    setTimeout(() => {
      setIsLoading(false);
      dismissModal();
    }, 1000);
  };

  return (
    <article className="modals-assessments-subjective-tests-spreadsheet">
      <header className="header">
        <h3 className="header-title fs20">
          {t('title.header.assessments-test')}
        </h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <section className="form spreadsheet">
        <header className="form-header">
          <h4 className="form-header-title fs28">
            {t('title.spreadsheet-question')}
          </h4>
          <button
            className={`form-header-submit common-button fsbtn16 primary ${
              !isLoading && canSubmit && 'active'
            } ${isLoading && 'pending'}`}
            onClick={!isLoading && canSubmit ? onSubmitHandler : null}
          >
            {t('btn.save')}
            {isLoading && <CircularProgress color="grey" size={25} />}
          </button>
        </header>
        <div className="form-setting">
          <MaterialSelect
            className="form-limit-time"
            title={t('text-field.label.time-answer-question')}
            value={limitTime}
            onChange={(item) => setLimitTime(item?.key)}
            items={[
              {
                key: 'no-limit',
                i18nKey: 'text-field.input.no-limit',
              },
              {
                key: '10mins',
                i18nKey: 'text-field.input.10mins',
              },
              {
                key: '20mins',
                i18nKey: 'text-field.input.20mins',
              },
              {
                key: '30mins',
                i18nKey: 'text-field.input.30mins',
              },
              {
                key: '40mins',
                i18nKey: 'text-field.input.40mins',
              },
              {
                key: '50mins',
                i18nKey: 'text-field.input.50mins',
              },
              {
                key: '1hour',
                i18nKey: 'text-field.input.1hour',
              },
            ]}
          />
          <MaterialInput
            placeholder={t('text-field.label.question')}
            value={question}
            onChange={(event) => setQuestion(event.target.value)}
          />
        </div>
        <div
          className="spreadsheet form-spreadsheet"
          style={{
            display: 'flex',
            width: '100%',
            height: '810px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="cover" />
          {isSpreadsheetLoading ? (
            <CircularProgress color="primary" size={45} />
          ) : (
            <Iframe
              url={spreadsheetsUrl}
              width="100%"
              height="100%"
              position="relative"
            />
          )}
        </div>
      </section>
    </article>
  );
};

export default Spreadsheet;
