import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'utils/hooks';
import { executeAction } from 'utils/redux';
import { setLang } from 'store/slices/variableSlice';
import { useLocation } from 'react-router-dom';

const LANGS = [
  {
    key: 'en',
  },
  {
    key: 'ko',
  },
];

const AppFooter = (props) => {
  const { t } = useTranslation();
  const [visibilityDropdown, setVisibilityDropdown] = React.useState(false);
  const [hideFooter, setHideFooter] = React.useState(false);
  const location = useLocation();
  const dropdownRef = React.useRef(null);

  useOnClickOutside(dropdownRef, () => setVisibilityDropdown(false));

  React.useEffect(() => {
    const path = location?.pathname;
    const splitPath = path.split('/');

    if (splitPath[2] === 'recruitments') {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }
  }, [location?.pathname]);

  const langSelectedHandler = (key) => {
    executeAction(setLang(key));
    setVisibilityDropdown(false);
  };

  return (
    <>
      {!hideFooter && (
        <footer className="components-footer-app-footer">
          <div className="container">
            <div className="corporation-title fs14">© Promenade AI Inc.</div>
          </div>
        </footer>
      )}
    </>
  );
};

export default AppFooter;
