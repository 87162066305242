import {
  getFirestore,
  collection,
  doc,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
  Timestamp,
  getDoc,
  query,
  orderBy,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './index';

const db = getFirestore(app);
const functions = getFunctions(app, 'asia-northeast1');

export const _getAllObjectiveTests = () =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, 'tests');
      const testsSnapshot = await getDocs(collectionRef);

      const datas = new Array();
      testsSnapshot.forEach((docSnapshot) => {
        if (docSnapshot.exists()) {
        }
      });
    } catch (e) {
      return reject(e);
    }
  });

export const _createAssessment = ({
  uid,
  name,
  position,
  language,
  tests,
  groupTests,
  objectiveTests,
  subjectiveTests,
  AntiCheating,
  purpose,
  codeLanguage,
  surveyOnOff,
  introVideoUrl,
  periodOnOff,
  startPeriodDateTime,
  endPeriodDateTime,
}) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    name = name || '';
    position = position || {};
    language = language || '';
    tests = tests || [];
    objectiveTests = objectiveTests || [];
    subjectiveTests = subjectiveTests || [];
    codeLanguage = codeLanguage || '';
    surveyOnOff = surveyOnOff || '';
    periodOnOff = periodOnOff || '';
    startPeriodDateTime = startPeriodDateTime || '';
    endPeriodDateTime = endPeriodDateTime || '';
    try {
      const docRef = await addDoc(collection(db, 'users', uid, 'assessments'), {
        uid,
        name,
        position,
        language,
        tests,
        groupTests,
        objectiveTests,
        subjectiveTests,
        AntiCheating,
        introVideoUrl,
        purpose,
        codeLanguage,
        candidates: [],
        archived: false,
        createdAt: Timestamp.now(),
        surveyOnOff,
        periodOnOff,
        startPeriodDateTime,
        endPeriodDateTime,
      });

      return resolve(docRef);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateAssessment = ({
  uid,
  aid,
  name,
  language,
  tests,
  groupTests,
  objectiveTests,
  subjectiveTests,
  AntiCheating,
  purpose,
  obpiBenchemark,
  codeLanguage,
  introVideoUrl,
  surveyOnOff,
  periodOnOff,
  startPeriodDateTime,
  endPeriodDateTime,
}) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    aid = aid || '';
    name = name || '';
    language = language || '';
    tests = tests || [];
    objectiveTests = objectiveTests || [];
    subjectiveTests = subjectiveTests || [];
    (AntiCheating = AntiCheating), (introVideoUrl = introVideoUrl || '');
    groupTests = groupTests || {};
    obpiBenchemark = obpiBenchemark || {};
    codeLanguage = codeLanguage || '';
    surveyOnOff = surveyOnOff || '';
    periodOnOff = periodOnOff || '';
    startPeriodDateTime = startPeriodDateTime || '';
    endPeriodDateTime = endPeriodDateTime || '';

    try {
      if (!uid || !aid) throw new Error('empty_field');
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      await setDoc(
        docRef,
        {
          name,
          language,
          tests,
          groupTests,
          objectiveTests,
          subjectiveTests,
          AntiCheating,
          introVideoUrl,
          purpose,
          obpiBenchemark,
          codeLanguage,
          surveyOnOff,
          periodOnOff,
          startPeriodDateTime,
          endPeriodDateTime,
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _updatePeriod = ({
  uid,
  aid,
  periodOnOff,
  startPeriodDateTime,
  endPeriodDateTime,
}) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    aid = aid || '';
    periodOnOff = periodOnOff || '';
    startPeriodDateTime = startPeriodDateTime || '';
    endPeriodDateTime = endPeriodDateTime || '';

    try {
      if (!uid || !aid) throw new Error('empty_field');
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      await setDoc(
        docRef,
        {
          periodOnOff,
          startPeriodDateTime,
          endPeriodDateTime,
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getAssessmentsOfCurrentUser = ({ uid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const assessmentsRef = collection(db, 'users', uid, 'assessments');
      const q = query(assessmentsRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const newDocs = new Array();
      querySnapshot.forEach((doc) => {
        if (
          !Object.prototype.hasOwnProperty.call(doc.data(), 'isRecruitment')
        ) {
          newDocs.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });

      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getAssessment = ({ uid, aid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('no_data');
    } catch (e) {
      return reject(e);
    }
  });

export const _setAssessment = ({ uid, aid, data = {} }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      await setDoc(
        docRef,
        {
          ...data,
          updatedAt: Timestamp.now(),
        },
        { merge: true }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _setConditionOfCandidate = ({ uid, aid, cid, condition }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      await setDoc(
        docRef,
        {
          ...data,
          updatedAt: Timestamp.now(),
        },
        { merge: true }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _addCandidate = ({
  uid,
  aid,
  firstName,
  lastName,
  email,
  testNumber,
  birth,
  objective,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        email
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        throw new Error('already_exists_email');
      }

      /*
                progress
                0: text.hiring-stage.sended-email
                1: text.hiring-stage.wirte-done
                2: text.hiring-stage.under-investigation
                3: text.hiring-stage.reject
                4: text.hiring-stage.accept
            */
      /*
                condition
                0: ready
                1: in-progress
                2: complete
                3: cancel
           */
      await setDoc(
        docRef,
        {
          firstName: firstName || '',
          lastName: lastName || '',
          email: email || '',
          testNumber: testNumber || '',
          birthDate: birth || '',
          objective: objective || '',
          progress: -1,
          condition: 0,
          createdAt: Timestamp.now(),
          invitedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

/** ======================
 * @function _addEvaluator : firebase에 slice에서 전달받은 payload값으로 추가하는 로직
 * @param {*} param0 
 * @returns 
========================= */
export const _addEvaluator = ({
  uid,
  aid,
  firstName,
  lastName,
  email,
  title,
  candidates,
  color,
  subjectiveTests,
  objectiveTests,
}) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    aid = aid || '';
    email = email || '';
    title = title || '';
    color = color || '';
    subjectiveTests = subjectiveTests || [];
    objectiveTests = objectiveTests || [];

    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'evaluators',
        email
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        throw new Error('already_exists_email');
      }

      await setDoc(
        docRef,
        {
          uid,
          email,
          title,
          objectiveTests,
          subjectiveTests,
          evaluation_status: 0,
          color,
          createdAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _updateCandidate = ({
  uid,
  aid,
  email,
  testNumber,
  birth,
  objective,
  data,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        email
      );
      await setDoc(
        docRef,
        {
          testNumber: testNumber || '',
          birth: birth || '',
          objective: objective || '',
          ...data,
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

/** ================
 * @function _updateEvaluator : 평가자의 정보를 수정하는 로직
 * @param {*} param0 
 * @returns 
==================== */
export const _updateEvaluator = ({ uid, aid, email, data }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'evaluators',
        email
      );
      await setDoc(
        docRef,
        {
          ...data,
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _createLibraryDoc = ({ uid, subjectiveTests }) =>
  new Promise(async (resolve, reject) => {
    try {
      for (const subjectiveTest of subjectiveTests) {
        // console.log(subjectiveTest);
        if (subjectiveTest.uuid) {
          await addDoc(collection(db, 'users', uid, 'library'), {
            uid,
            ...subjectiveTest,
            createdAt: Timestamp.now(),
          });
        }
      }

      return resolve(true);
    } catch (e) {
      console.dir(e);
      return reject(e);
    }
  });

export const _getLibraryDocs = ({ uid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const libraryDocsRef = collection(db, 'users', uid, 'library');
      const q = query(libraryDocsRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const newDocs = new Array();
      querySnapshot.forEach((doc) => {
        newDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getDefLibraryDocs = ({ lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const libraryDocsRef = collection(db, `library_${lang}`);
      const querySnapshot = await getDocs(libraryDocsRef);
      const newDocs = new Array();
      querySnapshot.forEach((doc) => {
        newDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      newDocs.sort((a, b) => a.id - b.id);

      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _deleteLibraryDoc = ({ uid, lid }) =>
  new Promise(async (resolve, reject) => {
    try {
      await deleteDoc(doc(db, 'users', uid, 'library', lid));
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _createCandidateDoc = ({ uid, email, firstName, lastName }) =>
  new Promise(async (resolve, reject) => {
    try {
      await addDoc(collection(db, 'users', uid, 'candidates'), {
        email,
        firstName,
        lastName,
        createdAt: Timestamp.now(),
      });
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _setCandidateDoc = ({
  uid,
  email,
  firstName,
  lastName,
  aid,
  testNumber,
  birth,
  objective,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'candidates', email);
      await setDoc(
        docRef,
        {
          email: email || '',
          firstName: firstName || '',
          lastName: lastName || '',
          testNumber: testNumber || '',
          birth: birth || '',
          objective: objective || '',
          assessments: {
            [aid]: null,
          },
          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

/**
 * @function _setEvaluatorDoc
 * @param {*} param0
 * @returns
 */
export const _setEvaluatorDoc = ({ uid, email, firstName, lastName, aid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'evaluators', email);
      await setDoc(
        docRef,
        {
          email: email || '',
          firstName: firstName || '',
          lastName: lastName || '',

          updatedAt: Timestamp.now(),
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getDefaultTemplate = ({ tid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'templates', tid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _editEmailContent = ({ uid, aid, key, value }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      await setDoc(
        docRef,
        {
          edits: {
            [key]: value || null,
          },
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getEditText = ({ uid, aid, key, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const { edits } = docSnap.data() || {};
        if (edits && Boolean(edits?.[key])) {
          return resolve(edits?.[key]);
        }
        const template = await _getDefaultTemplate({
          tid: key,
        });
        lang = (lang || '').slice(0, 2);
        const editText = template?.[lang];
        await setDoc(
          doc(db, 'users', uid, 'assessments', aid),
          {
            edits: {
              [key]: editText || '',
            },
          },
          {
            merge: true,
          }
        );
        return resolve(editText);
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _getCandidatesOfAssessment = ({ uid, aid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates'
      );
      const q = query(collectionRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const docs = new Array();
      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, ...doc.data() });
      });
      return resolve(docs);
    } catch (e) {
      return reject(e);
    }
  });

/** =================
 * @function _getEvaluatorsOfAssessment
 * @param {*} param0 
 * @returns 
===================== */
export const _getEvaluatorsOfAssessment = ({ uid, aid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'evaluators'
      );
      const q = query(collectionRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const docs = new Array();
      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, ...doc.data() });
      });
      return resolve(docs);
    } catch (e) {
      return reject(e);
    }
  });

export const _deleteCandidateOfAssesssment = ({ uid, aid, email }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        email
      );
      await deleteDoc(docRef);
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

/** =================
 * @function _deleteEvaluatorOfAssesssment
 * @param {*} param0 
 * @returns 
===================== */
export const _deleteEvaluatorOfAssesssment = ({ uid, aid, email }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'evaluators',
        email
      );
      await deleteDoc(docRef);
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getPositions = () =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, 'positions');
      const q = query(collectionRef, orderBy('title_en'));
      const querySnap = await getDocs(q);

      const newDocs = new Array();
      querySnap.forEach((doc) => {
        newDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getSkills = (lang) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `skill_${lang}`);
      const querySnap = await getDocs(collectionRef);

      const newDocs = new Array();
      querySnap.forEach((doc) => {
        newDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getLibSkills = (lang) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `lib_skill_${lang}`);
      const querySnap = await getDocs(collectionRef);

      const newDocs = new Array();
      querySnap.forEach((doc) => {
        newDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(newDocs);
    } catch (e) {
      console.dir(e);
      return reject(e);
    }
  });

export const _getLibTests = (lang) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, `library_${lang}`);
      const querySnap = await getDocs(collectionRef);
      const newDocs = new Array();
      querySnap.forEach((docSnap) => {
        newDocs.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      });
      return resolve(newDocs);
    } catch (e) {
      return reject(e);
    }
  });

export const _gradeResult = ({ uid, aid, cid, tuuid, score, comment }) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    aid = aid || '';
    cid = cid || '';
    tuuid = tuuid || '';
    score = score || 0;
    comment = comment || null;
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          results: {
            [tuuid]: {
              score,
              comment,
            },
          },
        },
        {
          merge: true,
        }
      );

      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _stylingGradeResult = ({
  uid,
  aid,
  cid,
  tuuid,
  originalityScore,
  fashionScore,
  communicationScore,
  fitScore,
  score,
  comment,
}) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    aid = aid || '';
    cid = cid || '';
    tuuid = tuuid || '';
    originalityScore = originalityScore || 0;
    fashionScore = fashionScore || 0;
    fitScore = fitScore || 0;
    communicationScore = communicationScore || 0;
    score = score || 0;
    comment = comment || null;
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          results: {
            [tuuid]: {
              originalityScore,
              fashionScore,
              fitScore,
              communicationScore,
              score,
              comment,
            },
          },
        },
        {
          merge: true,
        }
      );

      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _presetGradeResult = ({
  uid,
  aid,
  cid,
  tuuid,
  preset_score,
  text,
}) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    aid = aid || '';
    cid = cid || '';
    tuuid = tuuid || '';
    text = text || '';
    preset_score = preset_score || 0;
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          results: {
            [tuuid]: {
              preset_score,
              text,
            },
          },
        },
        {
          merge: true,
        }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getScoreResult = ({ uid, aid, cid, tuuid, score }) =>
  new Promise(async (resolve, reject) => {
    uid = uid || '';
    aid = aid || '';
    cid = cid || '';
    tuuid = tuuid || '';
    score = score || 0;

    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          results: {
            [tuuid]: {
              score,
            },
          },
        },
        {
          merge: true,
        }
      );

      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _getTestInfo = ({ tid, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, `test_${lang}`, tid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _getLibTestInfo = ({ tid, lang }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, `library_${lang}`, tid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return resolve({
          id: docSnap.id,
          ...docSnap.data(),
        });
      }
      throw new Error('empty_doc');
    } catch (e) {
      return reject(e);
    }
  });

export const _getAttributes = ({ sid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, 'skill_en', sid, 'values');
      const querySnapshot = await getDocs(collectionRef);

      const docs = new Array();
      querySnapshot.forEach((doc) => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(docs);
    } catch (e) {
      return reject(e);
    }
  });

export const _getCultureAttributes = ({ sid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = collection(db, 'skill_en', sid, 'cultures');
      const querySnapshot = await getDocs(collectionRef);

      const docs = new Array();
      querySnapshot.forEach((doc) => {
        docs.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return resolve(docs);
    } catch (e) {
      return reject(e);
    }
  });

export const _createSpreadsheets = () =>
  new Promise(async (resolve, reject) => {
    try {
      const createSpreadsheets = httpsCallable(functions, 'createSpreadsheets');
      const result = await createSpreadsheets({});
      return resolve(result?.data);
    } catch (e) {
      return reject(e);
    }
  });

export const _setOBPIBenchmarkToAssessment = ({ uid, aid, obpiBenchmark }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, 'users', uid, 'assessments', aid);
      await setDoc(
        docRef,
        {
          obpiBenchmark,
          updatedAt: Timestamp.now(),
        },
        { merge: true }
      );
      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const _copySpreadSheet = ({ spreadsheetId, uid, aid, cid }) =>
  new Promise(async (resolve, reject) => {
    try {
      const copySpreadsheets = httpsCallable(functions, 'copySpreadSheet');
      const result = await copySpreadsheets({ spreadsheetId, uid, aid, cid });
      return resolve(result?.data.response);
    } catch (e) {
      return reject(e);
    }
  });

export const _setKHAIRetryResults = ({ uid, aid, cid, sid, result }) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(
        db,
        'users',
        uid,
        'assessments',
        aid,
        'candidates',
        cid
      );
      await setDoc(
        docRef,
        {
          results: {
            [sid]: {
              result,
            },
          },
        },
        {
          merge: true,
        }
      );

      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });
