import React from 'react';
import { ReactComponent as UploadIcon } from 'assets/images/icon/upload.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icon/delete.svg';
import AWS from 'aws-sdk';
import { v1 } from 'uuid';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const IntroFileInput = ({ videoUrl, setVideoUrl, fileValue, setFileValue }) => {
  const { t } = useTranslation();
  const region = 'us-west-2';
  const bucket = `${process.env.REACT_APP_AWS_BUCKET}`;
  const [isUpload, setIsUpload] = React.useState(false);
  const dateTime = new Date().getTime();

  /**
   *
   * @param {*} e : file Input event
   * aws 비디오 저장 코드
   * 1. videoFileName : 파일 이름의 일관성을 위해 해당 state에 저장
   * 2. setVideoUrl : aws에 저장되는 경로와 저장되는 파일명 지정하여 assessment에 저장
   * 3. setFileValue : 업로드 된 파일의 이름을 저장
   *
   */
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region,
  });

  const handleFileInput = async (e) => {
    const file = e.target.files[0];
    const videoFileName = `${v1().toString().replace('-', '')}.${
      file.type.split('/')[1]
    }`;

    setIsUpload(true);
    setFileValue(file.name);
    setVideoUrl(`videos/intro/intro_${videoFileName}?dummy=${dateTime}`);

    const uploadParams = {
      Bucket: bucket,
      Body: file,
      Key: `videos/intro/intro_${videoFileName}`,
      ContentType: file.type,
      ACL: 'public-read',
      CacheControl: 'max-age=31536000',
    };

    s3.putObject(uploadParams, (err, data) => {
      setIsUpload(false);
      // 파일 업로드 성공 후 file value 값 초기화
      e.target.value = '';
      if (err) {
        console.log(err);
      }
    });
  };

  const deleteFileHandler = async (e) => {
    const deleteUrl = videoUrl.split('?');

    setVideoUrl('');
    setFileValue('');

    const deleteParams = {
      Bucket: bucket,
      Key: `${deleteUrl[0]}`,
    };

    s3.deleteObject(deleteParams, (err, data) => {
      if (err) {
        console.log('aws delete error');
        console.log(err, err.stack);
      } else {
        console.log('delete success');
      }
    });
  };

  return (
    <div className="intro-input">
      <div className="input-box">
        <label
          className={`input-video-label ${videoUrl !== '' ? 'disable' : ''}`}
          id="video-label"
          htmlFor="input-video"
        >
          <div className="label-inner">
            <UploadIcon />
            <p className="fs16">
              {fileValue === ''
                ? t('btn.video-file-upload')
                : `${t('btn.uploaded-file-name')} \n ${fileValue}`}
            </p>
          </div>
        </label>
        <input
          id="input-video"
          className="input-video-file"
          accept="video/mp4"
          type="file"
          multiple={false}
          hidden
          onChange={handleFileInput}
          disabled={videoUrl !== ''}
        />
        {videoUrl !== '' && (
          <button
            className={`delete-box ${isUpload ? 'disable' : ''}`}
            disabled={!!isUpload}
            onClick={deleteFileHandler}
          >
            <DeleteIcon />
            <span className="fs16 delete-btn">{t('actions.menu.delete')}</span>
          </button>
        )}
      </div>
      <div className={`video-preview-box ${isUpload && 'background-grey100'}`}>
        {videoUrl !== '' ? (
          !isUpload ? (
            <video
              id="preview-video"
              src={`${process.env.REACT_APP_MEDIA_URL}/${videoUrl}`}
              controls
              playsInline
              autoPlay
            />
          ) : (
            <div>
              <CircularProgress size={25} color="grey" />
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default IntroFileInput;
