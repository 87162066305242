import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useModal } from 'utils/modal';
import { ReactComponent as UploadIcon } from 'assets/images/icon/upload.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icon/error-icon.svg';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
} from '@mui/material';
import { read, utils } from 'xlsx';
import { toast } from 'utils/notify';
import { sendEmailToCandidates } from 'store/slices/assessmentsSlice';
import { executeAction } from 'utils/redux';
import { useStatus } from 'utils/status';

const InviteEmailBulk = ({
  aid,
  userInfo,
  updateUserLimit,
  candidates,
  setBulkEmailUpdate,
}) => {
  const { t } = useTranslation();
  const { dismissModal, displayModal } = useModal();
  const { setIsEntireLoading } = useStatus();
  const uploadFileRef = React.useRef(null);
  const [file, setFile] = React.useState(null);
  const [fileText, setFileText] = React.useState('');
  const [candidatesList, setCandidatesList] = React.useState([]);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [dataFormatValid, setDataFormatValid] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rowList =
    userInfo?.account === 'khai'
      ? 'First name,Last name,Email,Test number,Birth,Objective'
      : 'First name,Last name,Email';
  const rowCount = userInfo?.account === 'khai' ? 6 : 3;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    if (!file) return;
    const reader = new FileReader();

    if (file?.name?.substr(file?.name.length - 4).toLowerCase() === 'xlsx') {
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = read(data, {
          type: 'binary',
        });
        const newList = new Array();

        workbook.SheetNames.forEach((sheetName) => {
          const rows = (
            utils.sheet_to_csv(workbook.Sheets[sheetName]) || ''
          ).split('\n');
          for (const row of rows) {
            if (row === rowList) continue;
            const cols = (row || '').split(',');

            if ((cols || []).length !== rowCount) continue;
            newList.push(cols);
          }
        });
        /** =============
                 * 중복된 초대자 선별
                 ================ */
        const newListEmail = new Array();
        const completedRemove = new Array();

        newList.map((list, idx) => {
          newListEmail.push(list[2]);
        });

        /** ------------------------
                 * 대용량으로 등록된 이메일들과 
                 * candidate의 id값으로 중복 체크 
                 ------------------------- */
        const duplicatedId = newListEmail?.filter(
          (item) => !candidates?.some((candidate) => candidate.id === item)
        );

        if (duplicatedId.length < 0) {
          setCandidatesList(newList || []);
        } else {
          duplicatedId.map((id) => {
            const removeDuplicated = newList.find((list) => list[2] === id);
            completedRemove.push(removeDuplicated);
          });

          setCandidatesList(completedRemove || []);
        }
      };
      reader.readAsBinaryString(file);
    } else if (
      file?.name?.substr(file?.name.length - 3).toLowerCase() === 'csv'
    ) {
      reader.addEventListener('load', () => {
        const newList = new Array();
        const result = reader?.result;
        setFileText(reader?.result);
        const rows = (result || '').split('\n');
        for (const row of rows) {
          if (row === rowList) continue;
          const cols = (row || '').split(',');
          if ((cols || []).length !== rowCount) continue;
          newList.push(cols);
        }

        /** =============
                 * 중복된 초대자 선별
                 ================ */
        const newListEmail = new Array();
        const completedRemove = new Array();

        newList.map((list, idx) => {
          newListEmail.push(list[2]);
        });

        /** ------------------------
                 * 대용량으로 등록된 이메일들과 
                 * candidate의 id값으로 중복 체크 
                 ------------------------- */
        const duplicatedId = newListEmail?.filter(
          (item) => !candidates?.some((candidate) => candidate.id === item)
        );

        if (duplicatedId.length < 0) {
          setCandidatesList(newList || []);
        } else {
          duplicatedId.map((id) => {
            const removeDuplicated = newList.find((list) => list[2] === id);
            completedRemove.push(removeDuplicated);
          });
          setCandidatesList(completedRemove || []);
        }
      });

      reader.readAsText(file);
    } else {
      toast(t('toast.desc.invalid-format-file'));
      setCandidatesList([]);
    }
  }, [file]);

  React.useEffect(() => {
    if (candidatesList?.length) setCanSubmit(true);
    else setCanSubmit(false);
  }, [candidatesList]);

  const onCsvTemplateDownloadClickHandler = () => {
    if (userInfo?.account === 'khai')
      window.open('/assets/files/khai_email_bulk_template.csv');
    else window.open('/assets/files/email_bulk_template.csv');
  };

  const onXlsxTemplateDownloadClickHandler = () => {
    if (userInfo?.account === 'khai')
      window.open('/assets/files/khai_email_bulk_template.xlsx');
    else window.open('/assets/files/email_bulk_template.xlsx');
  };

  const onUploadFileHandler = (event) => {
    setFile(event.target.files?.[0]);
  };

  const onSubmitHandler = () => {
    setBulkEmailUpdate(true);
    if (userInfo?.candidateLimit < candidatesList?.length) {
      displayModal(
        'assessments/ConfirmCreateLimit',
        {
          event: {
            message: 'CreateLimit',
          },
          title: {
            main: t('dialog.title.exceeded-candidate'),
            sub: t('dialog.desc.exceeded-candidate'),
          },
        },
        {
          frame: 'part',
          hasButton: true,
        }
      );
    } else {
      setIsEntireLoading(true);
      executeAction(
        sendEmailToCandidates({
          aid,
          candidates: candidatesList,
        })
      )
        .unwrap()
        .then(() => {
          setIsEntireLoading(false);

          updateUserLimit({
            assessmentLimit: userInfo.assessmentLimit,
            candidateLimit: Math.abs(
              userInfo.candidateLimit - candidatesList.length
            ),
          })
            .then(() => {
              setBulkEmailUpdate(false);
            })
            .catch((e) => {
              console.dir(e);
            });

          toast(t('toast.desc.success-invite-bulk'));
          dismissModal();
        })
        .catch((e) => {
          console.dir(e);
          setIsEntireLoading(false);
          toast(t('toast.desc.invalid-format-file '));
        });
    }
  };

  const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!regex.test(dateString)) {
      return false;
    }

    const [year, month, day] = dateString.split('-').map(Number);

    const date = new Date(year, month - 1, day);

    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  };

  useEffect(() => {
    if (!candidatesList) return;
    if (userInfo?.account === 'khai') {
      candidatesList.forEach((candidate) => {
        if (isValidDate(candidate?.[4]) === false) {
          setDataFormatValid(false);
          return;
        }
      });
    }
  }, [candidatesList]);
  return (
    <article className="modals-assessments-invite-email-bulk">
      <header className="header">
        <h3 className="header-title fs20">{t('btn.invite-email-bulk')}</h3>
        <button className="header-close" onClick={() => dismissModal()} />
      </header>
      <section className="form">
        <section className="form-guide">
          <h4 className="form-guide-title fs28">{t('title.file-upload')}</h4>
          <p className="form-guide-description fs16">
            <Trans i18nKey="desc.csv-file-upload" />
          </p>
          <div className="form-guide-buttons">
            <button
              className="common-button fsbtn16 active"
              onClick={onCsvTemplateDownloadClickHandler}
            >
              {t('btn.csv-template-download')}
            </button>
            <button
              className="common-button fsbtn16 active"
              onClick={onXlsxTemplateDownloadClickHandler}
            >
              {t('btn.xlsx-template-download')}
            </button>
          </div>
        </section>
        <section
          className="form-upload"
          onClick={() => uploadFileRef?.current?.click()}
        >
          <UploadIcon />
          <span>
            {file ? t('btn.uploaded-file-name') : t('btn.upload-file')}
          </span>
          <input
            ref={uploadFileRef}
            type="file"
            accept=".csv,.xlsx"
            onChange={onUploadFileHandler}
            hidden
          />
          <span>{file?.name}</span>
        </section>
      </section>

      <section className="candidates">
        <h4 className="candidates-title fs28">
          {t('title.invite-email-list')}
        </h4>
        {!dataFormatValid && (
          <div className="error-msg">
            <ErrorIcon /> {t('desc.data-form-valid')}
          </div>
        )}
        <Paper className="table">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="10%">#</TableCell>
                  <TableCell width="13%">{t('column.name')}</TableCell>
                  <TableCell width="23%">{t('column.email')}</TableCell>
                  {userInfo?.account === 'khai' && (
                    <>
                      <TableCell width="18%">
                        {t('text-field.label.test-number')}
                      </TableCell>
                      <TableCell width="18%">{t('field.birth')}</TableCell>
                      <TableCell width="18%">
                        {t('text-field.label.objective')}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(candidatesList || []).length ? (
                  candidatesList.map((it, idx) => (
                    <TableRow key={idx + 1}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{`${it?.[0]} ${it?.[1]}`}</TableCell>
                      <TableCell>{it?.[2]}</TableCell>
                      {userInfo?.account === 'khai' && (
                        <>
                          <TableCell>{it?.[3]}</TableCell>
                          <TableCell
                            className={isValidDate(it?.[4]) ? '' : 'warning'}
                          >
                            {it?.[4]}
                          </TableCell>
                          <TableCell>{it?.[5]}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="empty-table fs16" colSpan={7}>
                      {t('desc.invite-email-list-empty')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={candidatesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
        <button
          className={`submit common-button fsbtn16 gray ${
            canSubmit && dataFormatValid && 'active'
          }`}
          onClick={canSubmit && dataFormatValid ? onSubmitHandler : null}
        >
          {t('btn.invite-email-bulk')}
        </button>
      </section>
    </article>
  );
};

export default InviteEmailBulk;
