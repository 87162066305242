import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateToDateFormat } from 'utils/string';
import {
  convertChannel,convertSubChannel, covertJobPosition,
} from 'utils/convertKey';
import { CandidateDetailMoreMenu } from 'components/recruitment/detail/CandidateDetailMoreMenu';
import { candidateActionMenu } from 'constants/recruitments/candidateActionMenu';
import BodyTableRowSkeleton from '../BodyTableRowSkeleton';
import { useModal } from '../../../../utils/modal';
import { nameConversionLanguage } from '../../../../utils/converter';

const RecruitmentTableBody = ({ candidates, recruitment, step }) => {
  const { t } = useTranslation();
  const { displayModal, dismissModal } = useModal();
  const viewDetailModal = (candidate) => {
    displayModal(
      'recruitments/CandidateDetail',
      {
        candidates,
        candidate,
        recruitment,
        processName: 'submission',
      },
      { frame: 'full' }
    );
  };

  return (
    <>
    <tbody>
      {candidates === null ? (
        <>
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
          <BodyTableRowSkeleton />
        </>
      ) : candidates?.length === 0 ? (
        <tr>
          <td>{t('recruitment.desc.empty-no-applicant')}</td>
        </tr>
      ) : (
        candidates?.map((candidate) => (
          <tr key={candidate?.email} onClick={() => viewDetailModal(candidate)}>
            <td>
              {nameConversionLanguage(
                candidate?.firstName,
                candidate?.lastName,
                t('lang')
              )}
            </td>
            <td>{`${t(convertChannel(candidate?.progressSubmit[1]?.channel))} - ${t(convertSubChannel(candidate?.progressSubmit[1]))
            }`}</td>
            <td>
              {candidate?.progressSubmit[step - 1]?.submitedAt
                ? dateToDateFormat(
                    new Date(
                      candidate?.progressSubmit[step - 1]?.submitedAt?.seconds *
                        1000
                    ),
                    t('lang')
                  )
                : '-'}
            </td>
            <td>{t(covertJobPosition(candidate?.progressSubmit[step - 2]?.companyAddr))}</td>
            <td>{candidate?.progressSubmit[step - 2]?.job}</td>
            <td>
              {dateToDateFormat(
                new Date(candidate?.progressSubmit[step - 1]?.startDate),
                t('lang')
              )}
            </td>
            <td>
              <CandidateDetailMoreMenu
                candidate={candidate}
                menuList={[candidateActionMenu.deleteCandidate]}
                rid={recruitment.rid}
              />
            </td>
          </tr>
        ))
      )}
    </tbody>
    </>
);
};

export default RecruitmentTableBody;
