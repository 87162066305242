import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'utils/auth';

const CreateAssessmentDone = ({ event: { message }, title: { main } }) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();
  const navigate = useNavigate();
  const { updateUserLimit, userInfo } = useAuth();

  /** ------------------------------------------------------------------
     * updateUserLimit : assessmentLimit, candidateLimit값을 업데이트(비동기)
     ------------------------------------------------------------------- */
  const onSubmitHandler = () => {
    if (userInfo === null) return;

    updateUserLimit({
      assessmentLimit: Math.abs(userInfo.assessmentLimit - 1),
      candidateLimit: userInfo.candidateLimit,
    }).catch((e) => {
      console.dir(e);
    });

    dismissModal({ message: message || 'dismiss' });
  };

  return (
    <article className="modals-assessments-create-assessment-done">
      <section className="title">
        <h3 className="title-main">{main}</h3>
      </section>
      <section className="buttons">
        <button
          className="common-button fsbtn16 cancel active"
          onClick={() => dismissModal()}
        >
          {t('btn.no')}
        </button>
        <button
          className="common-button fsbtn16 primary active"
          onClick={() => {
            onSubmitHandler();
          }}
        >
          {t('btn.yes')}
        </button>
      </section>
    </article>
  );
};

export default CreateAssessmentDone;
