import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import MaterialInput from 'components/input/MaterialInput';
import { ReactComponent as EyeIcon } from 'assets/images/icon/eye.svg';
import { executeAction } from 'utils/redux';
import { updatePassword } from 'store/slices/authSlice';
import { toast } from 'utils/notify';
import { CircularProgress } from '@mui/material';

const ChangePassword = () => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();

  const [isLoading, setIsLoading] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState('');

  React.useEffect(() => {
    if (
      Boolean(currentPassword) &&
      Boolean(newPassword) &&
      Boolean(newPasswordConfirm)
    ) {
      return setCanSubmit(true);
    }
    return setCanSubmit(false);
  }, [currentPassword, newPassword, newPasswordConfirm]);

  const onSubmitHandler = () => {
    setIsLoading(true);
    executeAction(
      updatePassword({
        oldPassword: currentPassword,
        newPassword,
        newPasswordConfirm,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
        toast(t('toast.desc.save-complete'));
        dismissModal();
      })
      .catch((e) => {
        console.dir(e);
        setIsLoading(false);
        switch (e?.message) {
          case 'wrong_password':
            toast(t('toast.desc.invalid-pw'));
            break;
          case 'string_length_fail':
            toast(t('toast.desc.pw-condition'));
            break;
          case 'no_special_character':
            toast(t('toast.desc.special-characters'));
            break;
          case 'no_accordance':
            toast(t('toast.desc.unmatched-pw'));
            break;
          default:
            toast(t('text-field.helper.pw-condition'));
            break;
        }
      });
  };

  return (
    <article className="modals-profile-change-password">
      <header className="header">
        <h2 className="header-title fs20">{t('title.change-pw')}</h2>
        <div className="header-close" onClick={() => dismissModal()} />
      </header>
      <div className="container">
        <div className="title">
          <h3 className="title-main fs18">{t('title.pw-condition')}</h3>
          <div className="title-sub fs16">{t('desc.secure-pw')}</div>
        </div>
        <MaterialInput
          className="old-password input"
          placeholder={t('text-field.label.old-pw')}
          type="password"
          value={currentPassword}
          onChange={(event) => setCurrentPassword(event.target.value)}
          icon={<EyeIcon />}
        />
        <MaterialInput
          className="new-password input"
          placeholder={t('text-field.label.new-pw')}
          type="password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          icon={<EyeIcon />}
        />
        <MaterialInput
          className="new-password-confirm input"
          placeholder={t('text-field.label.confirm-pw')}
          type="password"
          value={newPasswordConfirm}
          onChange={(event) => setNewPasswordConfirm(event.target.value)}
          icon={<EyeIcon />}
        />
        <button
          className={`submit common-button fsbtn16 primary ${
            !isLoading && canSubmit && 'active'
          } ${isLoading && 'pending'}`}
          onClick={canSubmit ? onSubmitHandler : null}
        >
          {t('btn.save')}
          {isLoading && <CircularProgress color="grey" size={25} />}
        </button>
      </div>
    </article>
  );
};

export default ChangePassword;
