import React from 'react';
import TinyEditor from 'components/editor/TinyEditor';
import MaterialSelect from 'components/select/MaterialSelect';
import IntroFileInput from 'components/input/IntroFileInput';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-assessments-test.svg';
import { useModal } from 'utils/modal';
import { CircularProgress } from '@mui/material';
import { v4 as uuidV4 } from 'uuid';

const FileUploadTest = ({ idx, subjectiveTests, setSubjectiveTests }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [limitTime, setLimitTime] = React.useState('');
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [fileName, setFileName] = React.useState('');
  const [videoUrl, setVideoUrl] = React.useState('');

  const editorRef = React.useRef(null);
  const guideEditorRef = React.useRef(null);

  const { dismissModal } = useModal();

  React.useEffect(() => {
    // TODO: change to entire loading.
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
      if (idx >= 0 && editorRef.current && guideEditorRef.current) {
        const subjectiveTest = subjectiveTests[idx];
        if(!subjectiveTest) {
          return;
        }

        setLimitTime(subjectiveTest.limit);
        editorRef.current.setContent(subjectiveTest.question);
        guideEditorRef.current.setContent(subjectiveTest.answer_guide);

      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [idx]);

  React.useEffect(() => {
    if (limitTime) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [limitTime]);

  const onDismissHandler = () => {
    dismissModal();
  };

  const onSubmitHandler = () => {
    setIsLoading(true);
    if (idx >= 0) {
      setSubjectiveTests([
        ...subjectiveTests.slice(0, idx),
        {
          uuid: uuidV4(),
          section: 'file-upload',
          limit: limitTime,
          question: editorRef.current?.getContent(),
          videoUrl,
          answer_guide: guideEditorRef.current?.getContent(),
        },
        ...subjectiveTests.slice(idx + 1, subjectiveTests?.length),
      ]);
    } else {
      setSubjectiveTests([
        ...subjectiveTests,
        {
          uuid: uuidV4(),
          section: 'file-upload',
          limit: limitTime,
          question: editorRef.current?.getContent(),
          videoUrl,
          answer_guide: guideEditorRef.current?.getContent(),
        },
      ]);
    }

    setTimeout(() => {
      setIsLoading(false);
      dismissModal();
    }, 1000);
  };

  return (
    <article className="modals-assessments-file-upload-test">
      <header className="header">
        <h3 className="header-title fs20">
          {t('title.header.assessments-test')}
        </h3>
        <article className="header-close" onClick={onDismissHandler}>
          <CloseIcon />
        </article>
      </header>
      <section className="form">
        <h4 className="form-title fs28">{t('title.file-upload-question')}</h4>
        <MaterialSelect
          className="form-limit-time"
          title={t('text-field.label.time-answer-question')}
          value={limitTime}
          onChange={(item) => setLimitTime(item?.key)}
          items={[
            {
              key: 'no-limit',
              i18nKey: 'text-field.input.no-limit',
            },
            {
              key: '10mins',
              i18nKey: 'text-field.input.10mins',
            },
            {
              key: '20mins',
              i18nKey: 'text-field.input.20mins',
            },
            {
              key: '30mins',
              i18nKey: 'text-field.input.30mins',
            },
            {
              key: '40mins',
              i18nKey: 'text-field.input.40mins',
            },
            {
              key: '50mins',
              i18nKey: 'text-field.input.50mins',
            },
            {
              key: '1hour',
              i18nKey: 'text-field.input.1hour',
            },
            {
              key: '2hours',
              i18nKey: 'text-field.input.2hours',
            },
            {
              key: '4hours',
              i18nKey: 'text-field.input.4hours',
            },
            {
              key: '12hours',
              i18nKey: 'text-field.input.12hours',
            },
            {
              key: '1day',
              i18nKey: 'text-field.input.1day',
            },
            {
              key: '2days',
              i18nKey: 'text-field.input.2days',
            },
            {
              key: '3days',
              i18nKey: 'text-field.input.3days',
            },
            {
              key: '7days',
              i18nKey: 'text-field.input.7days',
            },
          ]}
        />
        <TinyEditor ref={editorRef} className="form-editor" />

        <article className="form-guide-contents">
          <h4 className="form-guide-title fs18">{t('title.scoring-guide')}</h4>
          <TinyEditor ref={guideEditorRef} className="form-guide-editor" />
          <input
            id="my-file"
            type="file"
            name="my-file"
            style={{ display: 'none' }}
          />
        </article>

        <article className="modal-video-box">
          <div className="sub">
            <div className="sub-title fs18">
              <h6>{t('title.add-question-video')}</h6>
            </div>
            <IntroFileInput
              videoUrl={videoUrl}
              setVideoUrl={setVideoUrl}
              fileValue={fileName}
              setFileValue={setFileName}
            />
          </div>
        </article>

        <button
          onClick={!isLoading && canSubmit ? onSubmitHandler : null}
          className={`form-submit common-button fsbtn16 primary ${
            !isLoading && canSubmit && 'active'
          } ${isLoading && 'pending'}`}
        >
          {t('btn.save')}
          {isLoading && <CircularProgress color="grey" size={25} />}
        </button>
      </section>
    </article>
  );
};

export default FileUploadTest;
