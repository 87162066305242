import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import { getCandidateOfRecruitment } from 'store/slices/recruitments/recruitmentsSlice';
import axios from 'axios';

const DateRouter = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('uid');
  const rid = searchParams.get('aid');
  const cid = searchParams.get('cid');
  const step = searchParams.get('step');
  const refreshToken = searchParams.get('refresh_token');

  const [candidate, setCandidate] = useState({});
  const [redirectFlag, setRedirectFlag] = useState(false);

  useEffect(() => {
    if (!rid || !uid || !cid) return;

    executeAction(
      getCandidateOfRecruitment({
        uid,
        rid,
        cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setCandidate(doc);
        setRedirectFlag(true);
      })
      .catch((e) => {
        console.error(e);
        setRedirectFlag(false);
      });
  }, [rid, uid, cid]);

  useEffect(() => {
    if (!redirectFlag) return;
    const { isDone } = candidate?.progressSubmit[step]?.interviewSchedule;

    if (isDone) {
      return navigate('/interview/completed');
    }

    return navigate('/interview/date', {
      state: { uid, rid, cid, step, candidate },
      replace: true,
    });
  }, [redirectFlag]);

  useEffect(() => {
    if (refreshToken === 'none' || !refreshToken) return;

    axios
      .post(
        `${process.env.REACT_APP_POST_BOX}/outlook/refresh`,
        { refresh_token: refreshToken },
        {
          headers: {
            'Content-Type': 'application/json',
            'Publish-Type': process.env.REACT_APP_NODE_ENV,
          },
        }
      )
      .then((response) => {
        sessionStorage.setItem('access_token', response.data.access_token);
      });
  }, []);

  return <main />;
};

export default DateRouter;
