import React, { useState } from 'react';
import UpdateScoreModal from 'modals/assessments/UpdateScoreModal';
import InputEditor from 'components/codeEditor/InputEditor';
import { useTranslation } from 'react-i18next';
import ResultScoreBox from './ResultScoreBox';

const CodingTestResult = ({
  visibilityDropdown,
  question,
  candidate,
  assessment,
  setRefreshing,
  test,
  idx,
  type,
  activeIndex,
}) => {
  const { t } = useTranslation();
  const [isOpenScoreModal, setIsOpenScoreModal] = useState(false);

  const onUpdateScoreModal = () => {
    setIsOpenScoreModal(true);
  };

  const scoreDisplay = (score) => {
    if (score || score === 0) {
      return Math.trunc(
        candidate?.results[question?.uuid || question?.id]?.score * 100
      );
    }
    if (score === undefined || score === null) {
      return Math.trunc(
        candidate?.results[question?.uuid || question?.id]?.preset_score * 100
      );
    }
  };

  return (
    <>
      {activeIndex === idx && (
        <aside
          className={`toggle-body-content coding page-break ${
            visibilityDropdown ? 'slide-in' : 'slide-out'
          }`}
        >
          <div className="toggle-body-answer-box">
            <aside className="answer-box">
              <div className="question-box">
                <div className="flex">
                  <h5 className="coding-title">Question</h5>
                  <ResultScoreBox
                    score={
                      scoreDisplay(
                        candidate?.results[question?.uuid || question?.id]
                          ?.score
                      ) || 0
                    }
                    onClick={onUpdateScoreModal}
                    isHideButton={type === 'print'}
                  />
                </div>
                <pre className="coding-question">{question?.question}</pre>
              </div>
              <article className="coding-answer-content">
                <aside className="coding-answer-left-container">
                  <InputEditor
                    code={candidate?.results?.[question?.id].sourceCode}
                    id={
                      test?.codeLanguage !== undefined
                        ? test?.codeLanguage
                        : candidate?.results[question?.id]?.codeLanguage
                    }
                    isLeadonly
                    isLine
                  />
                </aside>
                <aside className="coding-answer-right-container">
                  <div className="coding-answer-result-title">
                    <h6>{t('code.result-title')}</h6>
                  </div>
                  <div className="coding-answer-box">
                    <pre>{candidate?.results?.[question?.id].result}</pre>
                  </div>
                </aside>
              </article>
            </aside>
          </div>

          {isOpenScoreModal && (
            <UpdateScoreModal
              open={isOpenScoreModal}
              close={setIsOpenScoreModal}
              setRefreshing={setRefreshing}
              category_skill={test?.category_skill}
              assessment={assessment}
              candidate={candidate}
              question={question}
              type="code"
            />
          )}
        </aside>
      )}
    </>
  );
};

export default CodingTestResult;
