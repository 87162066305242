import { createSlice } from '@reduxjs/toolkit';
import { getI18n } from 'react-i18next';

const initialState = {
  lang: window.localStorage.getItem('i18nextLng') || null,
};

export const variableSlice = createSlice({
  name: 'variable',
  initialState,
  reducers: {
    setLang(state, action) {
      state.lang = action.payload;

      switch (action.payload) {
        case 'en':
          break;
        case 'ko':
          break;
      }
      getI18n().changeLanguage(action.payload);
    },
  },
  extraReducers: {},
});

export const { setLang } = variableSlice.actions;

export default variableSlice.reducer;
