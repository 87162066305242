import React, { useEffect, useState } from 'react';
import { Button } from 'components/common/Button';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-modal.svg';
import { ReactComponent as PlusBlack24 } from 'assets/images/icon/plus-black-24x24.svg';
import { ReactComponent as MinusBlack24 } from 'assets/images/icon/remove-black-24x24.svg';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { executeAction } from 'utils/redux';
import { gradeResult } from 'store/slices/assessmentsSlice';
import { Modal } from '@mui/material';

const UpdateScoreModal = ({
  setRefreshing,
  category_skill,
  assessment,
  candidate,
  question,
  type,
  open,
  close,
}) => {
  const { t } = useTranslation();

  const [canSubmit, setCanSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateScore, setUpdateScore] = useState(
    type === 'styling'
      ? Math.trunc(
          candidate?.results[`${Number(question?.id) + 1}`]?.score * 100
        ) ||
          Math.trunc(
            candidate?.results[`${Number(question?.id) + 1}`]?.preset_score *
              100
          ) ||
          0
      : type === 'language'
      ? Math.trunc(question?.score * 100) ||
        Math.trunc(question?.preset_score * 100) ||
        0
      : Math.trunc(
          candidate?.results[question?.uuid || question?.id]?.score * 100
        ) ||
        Math.trunc(
          candidate?.results[question?.uuid || question?.id]?.preset_score * 100
        ) ||
        0
  );

  useEffect(() => {
    let flag = true;

    if (!updateScore) {
      flag = false;
    }

    setCanSubmit(flag);
  }, [updateScore]);

  // updateScore validate function
  const updateScoreHandler = (e) => {
    const { value } = e.target;
    const numberRegex = /^[0-9]*$/;

    if (value === '') {
      setUpdateScore(0);
    } else if (numberRegex.test(value)) {
      const score = parseInt(value);

      if (value > 100) {
        toast(t('toast.less-then-100-points'));
        setUpdateScore(100);
      } else {
        setUpdateScore(score);
      }
    } else {
      setUpdateScore(0);
    }
  };

  const decrementScore = () => {
    setUpdateScore((prev) => parseInt(prev) - 10);
  };

  const incrementScore = () => {
    setUpdateScore((prev) => parseInt(prev) + 10);
  };

  const updateScoreSubmit = () => {
    setIsLoading(true);

    const { rid, id } = assessment;
    const { email } = candidate;

    executeAction(
      gradeResult({
        aid: rid || id,
        cid: email || candidate?.id,
        tuuid:
          type !== 'styling'
            ? type !== 'language'
              ? question?.uuid || question?.id
              : question?.name
            : `${Number(question?.id) + 1}`,
        score: updateScore / 100,
      })
    )
      .then(() => {
        setIsLoading(false);
        setRefreshing(true);
        toast(t('toast.has-been-corrected'));
        close(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  return (
    <Modal open={open}>
      <div className="modal-invited-member modal-update-score">
        <section className="invited-member-container">
          <article className="invited-header-wrapper">
            <h3 className="fs16">{t('title.enter-score')}</h3>
            <span onClick={() => close(false)}>
              <CloseIcon />
            </span>
          </article>
          <article className="invited-form-wrapper">
            <aside className="update-form-title-box">
              <h6 className="fs14">{category_skill || ''}</h6>
            </aside>
            <aside className="update-score-box">
              <div className="minus-box">
                <Button
                  frontIcon={<MinusBlack24 />}
                  theme={'borderGrey'}
                  size={52}
                  radius={'200'}
                  padding={'14'}
                  onClick={decrementScore}
                  isDisabled={updateScore < 10}
                />
              </div>
              <div className="form-box">
                <input
                  type="text"
                  className="score-input"
                  value={updateScore}
                  onChange={updateScoreHandler}
                  minLength={1}
                  maxLength={3}
                />
                <span className="fs30">%</span>
              </div>
              <div className="plus-box">
                <Button
                  frontIcon={<PlusBlack24 />}
                  theme={'borderGrey'}
                  size={52}
                  radius={'200'}
                  padding={'14'}
                  onClick={incrementScore}
                  isDisabled={updateScore > 90}
                />
              </div>
            </aside>
          </article>
          <article className="invited-button">
            <Button
              theme={'grey'}
              size={56}
              label={t('btn.save')}
              isDisabled={!canSubmit && true}
              isLoading={isLoading}
              fontSize={'fs16'}
              onClick={!isLoading && canSubmit ? updateScoreSubmit : null}
            />
          </article>
        </section>
      </div>
    </Modal>
  );
};

export default UpdateScoreModal;
