import React, { useEffect, useState } from 'react';
import MemberInvitedButton from 'components/customer/members/MemberInvitedButton';
import MembersList from 'components/customer/members/MembersList';
import RecruitmentInput from 'components/input/RecruitmentInput';
import MembersFilter from 'components/customer/members/MembersFilter';
import { useSelector } from 'react-redux';
import { FILTER_ITEMS } from 'constants/members/filter';
import { ReactComponent as SearchIcon } from 'assets/images/icon/ico-search-normal.svg';
import { convertMemberTypeToTitle } from 'utils/converter';
import { useTranslation } from 'react-i18next';
import { convertDisabled } from 'utils/convertKey';

const Members = () => {
  const { t } = useTranslation();
  const { getGucciUsersSuccess } = useSelector((state) => state.members);
  const { userInfo } = useSelector((state) => state.auth);

  const [search, setSearch] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [filter, setFilter] = useState({
    memberType: '',
    team: '',
    workPlace: '',
    status: '',
  });

  useEffect(() => {
    if (!getGucciUsersSuccess) return;

    setFilteredList(getGucciUsersSuccess);
  }, [getGucciUsersSuccess]);

  useEffect(() => {
    // 검색 기능 filter 로직
    let usersCopy = filteredList;

    setSearchFilter(
      (usersCopy || []).filter((x) => {
        const { email, team, jobFunction, workspace, disabled } = x;
        const fullName1 = (x.firstName || '') + (x.lastName || '');
        const fullName2 = (x.lastName || '') + (x.firstName || '');
        const type = t(convertMemberTypeToTitle(x.memberType));

        if (!team || !jobFunction || !workspace) return;

        let flag = false;
        const convertedSearch = (search || '')
          .replaceAll(' ', '')
          .trim()
          .toLowerCase();

        if (
          fullName1
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .includes(convertedSearch)
        ) {
          flag = true;
        }

        if (
          fullName2
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .includes(convertedSearch)
        ) {
          flag = true;
        }

        if (
          type
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .includes(convertedSearch)
        ) {
          flag = true;
        }

        if (convertDisabled(disabled).includes(convertedSearch)) {
          flag = true;
        }
        if (email.includes(convertedSearch)) {
          flag = true;
        }
        if (team.toLowerCase().includes(convertedSearch)) {
          flag = true;
        }
        if (jobFunction.includes(convertedSearch)) {
          flag = true;
        }
        if (workspace.includes(convertedSearch)) {
          flag = true;
        }

        if (flag) {
          return x;
        }
      })
    );
  }, [search, filteredList]);

  const resetClickEvent = () => {
    setFilter({
      memberType: '',
      team: '',
      workPlace: '',
      status: '',
    });
  };

  return (
    <section className="pages-protected-profile-members">
      <h3 className="members-title fs16">
        사용자를 추가하거나 삭제할 수 있습니다.
      </h3>
      <article className="members-form-wrapper">
        <aside className="filter-wrapper">
          <button className="reset-button" onClick={resetClickEvent}>
            Reset Filter
          </button>
          <span className="hr">|</span>
          <MembersFilter
            menuItems={FILTER_ITEMS}
            users={getGucciUsersSuccess}
            setFilteredList={setFilteredList}
            userInfo={userInfo}
            filter={filter}
            setFilter={setFilter}
          />
        </aside>
        <aside className="form-wrapper">
          <RecruitmentInput
            icon={<SearchIcon />}
            value={search}
            setValue={setSearch}
            placeHolder={'Search'}
          />
          <MemberInvitedButton menuItems={FILTER_ITEMS} userInfo={userInfo} />
        </aside>
      </article>
      <MembersList users={searchFilter} />
    </section>
  );
};

export default Members;
