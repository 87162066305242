import React from 'react';
import { ReactComponent as ArrowUpIcon } from 'assets/images/icon/arrow-upward.svg';
import { Button } from 'components/common/Button';

const CommentInput = ({
  hasButton,
  placeholder,
  height,
  onClick,
  value,
  onChange,
}) => {
  return (
    <div className="comment-input-box">
      <div className="input-wrapper">
        <textarea
          value={value}
          onChange={onChange}
          className="comment-input fs16"
          placeholder={placeholder}
          style={{ height: `${height}px` }}
        />
        {hasButton && (
          <Button
            size={40}
            theme="blue"
            frontIcon={<ArrowUpIcon />}
            radius={100}
            padding={8}
            className={'submit-button'}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};

export default CommentInput;
