import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFilterOfRecruitmentProcess } from 'utils/hooks';
import {
  countStage,
  getCandidatesOfRecruitment,
} from 'store/slices/recruitments/recruitmentsSlice';
import { executeAction } from 'utils/redux';
import { useSelector } from 'react-redux';
import { recruitmentSearchFilterHandler } from 'utils/filters';
import DetailProcess from './DetailProcess';
import DetailForm from './DetailForm';
import DetailContents from './DetailContents';

const DetailContainer = ({ recruitment, userInfo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [processSetting, processLength] =
    useFilterOfRecruitmentProcess(recruitment);
  const { getCandidatesSuccess } = useSelector((state) => state.recruitments);

  const [searchValue, setSearchValue] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [path, setPath] = useState('');
  const [countCandidates, setCountCandidates] = useState([]);
  const [render, setRender] = useState(0);

  useEffect(() => {
    if (Object.keys(recruitment).length === 0 || !userInfo) return;
    if(!recruitment?.rid || !userInfo?.memberType) return;

    executeAction(
      getCandidatesOfRecruitment({
        aid: recruitment.rid,
        memberType: userInfo.memberType,
      })
    )
      .unwrap()
      .catch((e) => {
        console.error(e);
      });
  }, [recruitment, userInfo, render]);

  useEffect(() => {
    if (!recruitment || Object.keys(processSetting).length === 0) return;

    const count = Object.keys(processSetting).length;

    executeAction(countStage({ candidates: getCandidatesSuccess, count }))
      .unwrap()
      .then(({ result }) => {
        setCountCandidates(result);
      })
      .catch((e) => console.error(e));
  }, [getCandidatesSuccess, processSetting]);

  useEffect(() => {
    const pathArr = location?.pathname.split('/').filter((item) => item !== '');

    setPath(pathArr[3]);
  }, [location?.pathname]);

  useEffect(() => {
    if (!getCandidatesSuccess) return;

    let candidatesCopy = getCandidatesSuccess;
    let filtered;

    if (!path) {
      filtered = candidatesCopy.filter((x) => x?.recruitProgress === 1);
    } else if (path === 'virtual-interview') {
      filtered = candidatesCopy.filter((x) => x?.recruitProgress === 2);
    } else if (path === 'first-interview') {
      filtered = candidatesCopy.filter((x) => x?.recruitProgress === 3);
    } else if (path === 'second-interview') {
      filtered = candidatesCopy.filter((x) => x?.recruitProgress === 4);
    } else if (path === 'evidence') {
      const step = processLength === 7 ? 4 : 5;
      filtered = candidatesCopy.filter((x) => x?.recruitProgress === step);
    } else if (path === 'pass') {
      const step = processLength === 7 ? 5 : 6;
      filtered = candidatesCopy.filter((x) => x?.recruitProgress === step);
    } else if (path === 'submission') {
      const step = processLength === 7 ? 6 : 7;
      filtered = candidatesCopy.filter((x) => x?.recruitProgress === step);
    } else {
      filtered = candidatesCopy.filter((x) => x?.recruitProgress === -1);
    }
    setSearchFilter(
      recruitmentSearchFilterHandler(filtered, path, searchValue, processLength)
    );
  }, [path, searchValue, getCandidatesSuccess]);

  const onNavigateClickHandler = (key) => {
    if (key === 'none') return;

    setRender(render + 1);

    switch (key) {
      case 'application':
        navigate('');
        break;
      case 'aiInterview':
        navigate('virtual-interview');
        break;
      case 'firstInterview':
        navigate('first-interview');
        break;
      case 'secondInterview':
        navigate('second-interview');
        break;
      case 'evidence':
        navigate('evidence');
        break;
      case 'pass':
        navigate('pass');
        break;
      case 'submission':
        navigate('submission');
        break;
      case 'reject':
        navigate('reject');
        break;
    }
  };

  return (
    <article className="dashboard-detail-wrapper">
      <DetailProcess
        countCandidates={countCandidates}
        processSetting={processSetting}
        onClick={onNavigateClickHandler}
        userInfo={userInfo}
      />
      <DetailForm
        location={location}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        userInfo={userInfo}
        recruitment={recruitment}
        render={render}
        setRender={setRender}
      />
      <DetailContents
        recruitment={recruitment}
        candidates={searchFilter}
        userInfo={userInfo}
      />
    </article>
  );
};

export default DetailContainer;
