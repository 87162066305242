import React from 'react';
import { useTranslation } from 'react-i18next';
import TablePager from 'components/pager/TablePager';
import PropTypes from 'prop-types';

const Archived = (props) => {
  const { t } = useTranslation();

  const [startIdx, setStartIdx] = React.useState(0);
  const [offset, setOffset] = React.useState(10);
  const [docs, setDocs] = React.useState([]);

  return (
    <section className="pages-protected-assessments-archived">
      {(docs || []).length > 0 ? (
        <table>
          <thead>
            <tr>
              <th className="name">
                <div className="container name">{t('label.table.name')}</div>
              </th>
              <th className="num-of-candidates">
                <div className="container num-of-candidates">
                  {t('label.table.num-of-candidates')}
                </div>
              </th>
              <th className="status">
                <div className="container status">
                  {t('label.table.status')}
                </div>
              </th>
              <th className="created-at">
                <div className="container created-at">
                  {t('label.table.created-at')}
                </div>
              </th>
              <th className="more">
                <div className="container more">{t('label.table.more')}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {(docs || []).map((doc, idx) => (
              <tr>
                <td>
                  <div className="container name">
                    <span>{doc.name}</span>
                  </div>
                </td>
                <td>
                  <div className="container num-of-candidates">
                    <span>{(doc.candidates || []).length}</span>
                  </div>
                </td>
                <td>
                  <div className="container status">
                    <div className="ready" />
                    <div className="start" />
                    <div className="in-progress" />
                    <div className="done" />
                    <div className="cancel" />
                  </div>
                </td>
                <td>
                  <div className="container created-at">
                    {doc.createdAt.getFullYear()}
                  </div>
                </td>
                <td>
                  <div className="container more">
                    <div className="more-button" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <div className="container">
                  <TablePager
                    startIdx={startIdx}
                    setStartIdx={setStartIdx}
                    offset={offset}
                    setOffset={setOffset}
                    total={docs.length}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <div className="empty">
          <span>{t('my-assessments.desc.empty-archived')}</span>
        </div>
      )}
    </section>
  );
};

Archived.propTypes = {};

export default Archived;
