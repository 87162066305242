import React from 'react';
import { executeAction } from 'utils/redux';
import { setLang } from 'store/slices/variableSlice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EarthIcon } from 'assets/images/icon/earth.svg';
import { useOnClickOutside } from 'utils/hooks';
import { supportedLangs } from 'i18n/index';

const AuthFooter = (props) => {
  const { t } = useTranslation();
  const [visibilityDropdown, setVisibilityDropdown] = React.useState(false);

  const dropdownRef = React.useRef(null);

  useOnClickOutside(dropdownRef, () => setVisibilityDropdown(false));

  const langSelectedHandler = (key) => {
    executeAction(setLang(key));
    setVisibilityDropdown(false);
  };

  return (
    <footer className="components-footer-auth-footer">
      <div className="container">
        <div className="corporation-title fs14">© Promenade AI Inc.</div>
        <article ref={dropdownRef} className="language-selector">
          <div
            className={`language-selector-toggle ${
              visibilityDropdown && 'show'
            }`}
            onClick={() => setVisibilityDropdown(!visibilityDropdown)}
          >
            <EarthIcon />
            {window.localStorage.getItem('i18nextLng') &&
              t(
              `label.lang-${window.localStorage
                .getItem('i18nextLng')
                .slice(0, 2)}`
            )}
          </div>
          {visibilityDropdown && (
            <div className="language-selector-dropdown">
              <ul className="language-selector-dropdown-list">
                {(supportedLangs || []).map((lang, idx) => (
                  <li
                    key={lang.key}
                    className={`language-selector-dropdown-list-item ${lang.key}`}
                    onClick={() => langSelectedHandler(lang.key)}
                  >
                    {t(`label.lang-${lang.key}`)}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </article>
      </div>
    </footer>
  );
};

export default AuthFooter;
