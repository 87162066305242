import React from 'react';

const RecruitmentInputText = ({ type, placeholder, value, onChange }) => {
  const onChangeHandler = (e) => {
    const { value } = e?.target;
    onChange(value);
  };

  return (
    <input
      className="recruitment-input-text fs14"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChangeHandler}
    />
  );
};

export default RecruitmentInputText;
