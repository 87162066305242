export const countryCodeOptions = [
  { i18nKey: 'menu.country-code-us', key: '+1 US' },
  { i18nKey: 'menu.country-code-ca', key: '+1 CA' },
  { i18nKey: 'menu.country-code-ru', key: '+7' },
  { i18nKey: 'menu.country-code-fr', key: '+33' },
  { i18nKey: 'menu.country-code-es', key: '+34' },
  { i18nKey: 'menu.country-code-uk', key: '+44' },
  { i18nKey: 'menu.country-code-de', key: '+49' },
  { i18nKey: 'menu.country-code-au', key: '+61' },
  { i18nKey: 'menu.country-code-ph', key: '+63' },
  { i18nKey: 'menu.country-code-nz', key: '+64' },
  { i18nKey: 'menu.country-code-sg', key: '+65' },
  { i18nKey: 'menu.country-code-th', key: '+66' },
  { i18nKey: 'menu.country-code-jp', key: '+81' },
  { i18nKey: 'menu.country-code-kr', key: '+82' },
  { i18nKey: 'menu.country-code-vn', key: '+84' },
  { i18nKey: 'menu.country-code-cn', key: '+86' },
  { i18nKey: 'menu.country-code-hk', key: '+852' },
  { i18nKey: 'menu.country-code-tw', key: '+886' },
  { i18nKey: 'menu.country-code-in', key: '+91' },
  { i18nKey: 'menu.country-code-ae', key: '+971' },
];

export const genderOptions = [
  { key: 'male', i18nKey: 'menu.label.male' },
  { key: 'female', i18nKey: 'menu.label.female' },
  { key: 'non-disclosure', i18nKey: 'menu.label.non-disclosure' },
];


export const locationOptions = [
  { key: 'korea', i18nKey: 'menu.input.korea' },
  { key: 'usa', i18nKey: 'menu.input.america' },
  { key: 'japan', i18nKey: 'menu.input.japan' },
  { key: 'china', i18nKey: 'menu.input.china' },
];

export const careerOptions = [
  { key: 'none', i18nKey: 'menu.input.none' },
  { key: '1year', i18nKey: 'menu.input.1-year' },
  { key: '2years', i18nKey: 'menu.input.2-years' },
  { key: '3years', i18nKey: 'menu.input.3-years' },
  { key: '4years', i18nKey: 'menu.input.4-years' },
  { key: '5years', i18nKey: 'menu.input.5-years' },
  { key: '6years', i18nKey: 'menu.input.6-years' },
  { key: '7years', i18nKey: 'menu.input.7-years' },
  { key: '8years', i18nKey: 'menu.input.8-years' },
  { key: '9years', i18nKey: 'menu.input.9-years' },
  { key: '10years', i18nKey: 'menu.input.10-years' },
  { key: '11years', i18nKey: 'menu.input.11-years' },
  { key: '12years', i18nKey: 'menu.input.12-years' },
  { key: '13years', i18nKey: 'menu.input.13-years' },
  { key: '14years', i18nKey: 'menu.input.14-years' },
  { key: '15years', i18nKey: 'menu.input.15-years' },
  { key: '16years', i18nKey: 'menu.input.16-years' },
  { key: '17years', i18nKey: 'menu.input.17-years' },
  { key: '18years', i18nKey: 'menu.input.18-years' },
  {
    key: 'for more than 19 years',
    i18nKey: 'menu.input.for-over-19-years',
  },
];

export const nationalCurrencyOptions = [
  { key: 'KRW', i18nKey: 'KRW' },
  { key: 'USD', i18nKey: 'USD' },
];

export const finalEducationOptions = [
  {
    key: 'highSchool',
    i18nKey: 'menu.input.high-school-graduation',
  },
  { key: 'college', i18nKey: 'menu.input.college-graduation' },
  {
    key: 'university',
    i18nKey: 'menu.input.university-graduation',
  },
  { key: 'master', i18nKey: 'menu.input.finish-graduate-school' },
];

export const majorOptions = [
  {
    key: 'fashionDesign',
    i18nKey: 'menu.input.art-fashion-design',
  },
  { key: 'foreignLang', i18nKey: 'menu.input.foreign-language' },
  { key: 'retailServices', i18nKey: 'menu.input.retail-services' },
  { key: 'other', i18nKey: 'menu.input.other' },
];

export const minorOptions = [
  {
    key: 'fashionDesign',
    i18nKey: 'menu.input.art-fashion-design',
  },
  { key: 'foreignLang', i18nKey: 'menu.input.foreign-language' },
  { key: 'retailServices', i18nKey: 'menu.input.retail-services' },
  { key: 'other', i18nKey: 'menu.input.other' },
];

export const languageOptions = [
  { key: 'korean', i18nKey: 'menu.input.korean' },
  { key: 'english', i18nKey: 'menu.input.english' },
  { key: 'mandarinChinese', i18nKey: 'menu.input.mandarin-chinese' },
  { key: 'japanese', i18nKey: 'menu.input.japanese' },
];

export const languageLevelOptions = [
  { key: 'native', i18nKey: 'menu.input.native' },
  { key: 'fluent', i18nKey: 'menu.input.fluent' },
  { key: 'conversational', i18nKey: 'menu.input.conversational' },
];

export const channelOptions = [
  { key: 'job', i18nKey: 'menu.input.job-center-unit' },
  { key: 'sns', i18nKey: 'menu.input.sns' },
  {
    key: 'school',
    i18nKey: 'menu.input.school-industry-academic',
  },
  { key: 'friend', i18nKey: 'menu.input.friend' },
  { key: 'searchFirm', i18nKey: 'menu.input.search-firm' },
  { key: 'others', i18nKey: 'menu.input.other' },
];

export const searchFirmOptions = [
  { key: 'ascentask-korea', i18nKey: 'menu.input.ascentask-korea' },
  { key: 'humanest', i18nKey: 'menu.input.humanest' },
  { key: 'nterway', i18nKey: 'menu.input.nterway' },
  { key: 'henny-mccoy', i18nKey: 'menu.input.henny-mccoy' },
  {
    key: 'unico-search',
    i18nKey: 'menu.input.unico-search',
  },
  {
    key: 'new-life-consultancy',
    i18nKey: 'menu.input.new-life-consultancy',
  },
  {
    key: 'lina',
    i18nKey: 'menu.input.lina-consulting',
  },
  {
    key: 'hr-agent',
    i18nKey: 'menu.input.hr-agent',
  },
];

export const snsOptions = [
  { key: 'facebook', i18nKey: 'menu.input.facebook' },
  { key: 'instagram', i18nKey: 'menu.input.instagram' },
  { key: 'other', i18nKey: 'menu.input.other' },
];

export const schoolOptions = [
  { key: 'jeonghwa', i18nKey: 'menu.input.jeonghwa' },
  { key: 'sookmyung', i18nKey: 'menu.input.sookmyung' },
  { key: 'jeonju', i18nKey: 'menu.input.jeonju' },
  { key: 'keimyung', i18nKey: 'menu.input.keimyung' },
  { key: 'yeungnam', i18nKey: 'menu.input.yeungnam' },
  { key: 'jangan', i18nKey: 'menu.input.jangan' },
  { key: 'suwon-women', i18nKey: 'menu.input.suwon-women' },
  { key: 'woosong', i18nKey: 'menu.input.woosong' },
  { key: 'woosong-college', i18nKey: 'menu.input.woosong-college' },
  {
    key: 'yongin-art-science',
    i18nKey: 'menu.input.yongin-art-science',
  },
  { key: 'jei', i18nKey: 'menu.input.jei' },
  { key: 'kyungbok', i18nKey: 'menu.input.kyungbok' },
  { key: 'pai-chai', i18nKey: 'menu.input.pai-chai' },
  { key: 'yuhan', i18nKey: 'menu.input.yuhan' },
  { key: 'dong-seo', i18nKey: 'menu.input.dong-seo' },
  { key: 'hoseo', i18nKey: 'menu.input.hoseo' },
];

export const jobCenterOptions =[
  { key: 'saramin', i18nKey: 'menu.input.saramin' },
  { key: 'linkedin', i18nKey: 'menu.input.linkedin' },
  { key: 'jobkorea', i18nKey: 'menu.input.jobkorea' },
  { key: 'shopmanet', i18nKey: 'menu.input.shopmanet' },
  { key: 'others', i18nKey: 'menu.input.other' },
];

export const channelGucciList = [
  {key: 'survey.flagship', i18nKey: 'survey.flagship'},
  {key: 'survey.outlet', i18nKey: 'survey.outlet'},
  {key: 'survey.duty-free-shops', i18nKey: 'survey.duty-free-shops'},
  ];

export const areaGucciList = [
  { key: 'survey.seoul', i18nKey: 'survey.seoul', },
  { key: 'survey.gyeonggi', i18nKey: 'survey.gyeonggi', },
  { key: 'survey.yeoju', i18nKey: 'survey.yeoju', },
  { key: 'survey.incheon', i18nKey: 'survey.incheon', },
  { key: 'survey.daejeon', i18nKey: 'survey.daejeon', },
  { key: 'survey.gwangju', i18nKey: 'survey.gwangju', },
  { key: 'survey.daegu', i18nKey: 'survey.daegu', },
  { key: 'survey.busan', i18nKey: 'survey.busan', },
  { key: 'survey.ulsan', i18nKey: 'survey.ulsan', },
];