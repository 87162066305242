import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

window.onmousemove = function (e) {
  const tooltips = document.querySelectorAll('.tooltip');

  let x = `${e.clientX + 20}px`;
  let y = `${e.clientY + 20}px`;
  for (let i = 0; i < tooltips.length; i += 1) {
    tooltips[i].style.top = y;
    tooltips[i].style.left = x;
  }
};

export const Score = ({ score, presetScore, isTooltipShow }) => {
  const { t } = useTranslation();

  return (
    <>
      {score !== 0 ? (
        <div className={`stars ${score > 0 && 'preset-hide'}`}>
          <div
            className={`star 
                        ${score >= 0.2 ? 'fill' : 'empty'} 
                        ${presetScore >= 0.2 ? 'preset-fill' : 'preset-empty'}`}
          />
          <div
            className={`star 
                        ${score >= 0.4 ? 'fill' : 'empty'} 
                        ${presetScore >= 0.4 ? 'preset-fill' : 'preset-empty'}`}
          />
          <div
            className={`star 
                        ${score >= 0.6 ? 'fill' : 'empty'} 
                        ${presetScore >= 0.6 ? 'preset-fill' : 'preset-empty'}`}
          />
          <div
            className={`star 
                        ${score >= 0.8 ? 'fill' : 'empty'} 
                        ${presetScore >= 0.8 ? 'preset-fill' : 'preset-empty'}`}
          />
          <div
            className={`star 
                        ${score >= 1 ? 'fill' : 'empty'} 
                        ${presetScore >= 1 ? 'preset-fill' : 'preset-empty'}`}
          />
        </div>
      ) : (
        <div className="stars">
          <div
            className={`star empty ${
              presetScore >= 0.2 ? 'preset-fill' : 'preset-empty'
            }`}
          />
          <div
            className={`star empty ${
              presetScore >= 0.4 ? 'preset-fill' : 'preset-empty'
            }`}
          />
          <div
            className={`star empty ${
              presetScore >= 0.6 ? 'preset-fill' : 'preset-empty'
            }`}
          />
          <div
            className={`star empty ${
              presetScore >= 0.8 ? 'preset-fill' : 'preset-empty'
            }`}
          />
          <div
            className={`star empty ${
              presetScore >= 1 ? 'preset-fill' : 'preset-empty'
            }`}
          />
        </div>
      )}
      {isTooltipShow !== 'styling' ? (
        <span className="tooltip fs12 obj">{t('desc.auto-score-star')}</span>
      ) : (
        <></>
      )}
    </>
  );
};

export const EstimateScore = ({ score, presetScore, onClick, questionId }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`estimate-score-contents ${score > 0 && 'preset-hide'}`}>
        <div
          className={`star  
                        ${score >= 0.2 ? 'fill' : 'empty'}
                        ${presetScore >= 0.2 ? 'preset-fill' : 'preset-empty'}`}
          onClick={() => onClick(questionId, 0.2)}
        >
          <div />
        </div>
        <div
          className={`star 
                        ${score >= 0.4 ? 'fill' : 'empty'}
                        ${presetScore >= 0.4 ? 'preset-fill' : 'preset-empty'}`}
          onClick={() => onClick(questionId, 0.4)}
        >
          <div />
        </div>
        <div
          className={`star 
                        ${score >= 0.6 ? 'fill' : 'empty'}
                        ${presetScore >= 0.6 ? 'preset-fill' : 'preset-empty'}`}
          onClick={() => onClick(questionId, 0.6)}
        >
          <div />
        </div>
        <div
          className={`star 
                        ${score >= 0.8 ? 'fill' : 'empty'}
                        ${presetScore >= 0.8 ? 'preset-fill' : 'preset-empty'}
                    `}
          onClick={() => onClick(questionId, 0.8)}
        >
          <div />
        </div>
        <div
          className={`star 
                        ${score >= 1 ? 'fill' : 'empty'}
                        ${presetScore >= 1 ? 'preset-fill' : 'preset-empty'}
                    `}
          onClick={() => onClick(questionId, 1)}
        >
          <div />
        </div>
      </div>
      {questionId !== '99000100000' && questionId !== '99000100001' ? (
        presetScore !== 'none' ? (
          <span className="tooltip fs12 obj">{t('desc.auto-score-star')}</span>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export const MultipleEstimateScore = ({ score }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`estimate-score-contents ${score > 0 && 'preset-hide'}`}>
        <div className={`star ${score >= 0.2 ? 'fill' : 'empty'}`}>
          <div />
        </div>
        <div className={`star ${score >= 0.4 ? 'fill' : 'empty'}`}>
          <div />
        </div>
        <div className={`star ${score >= 0.6 ? 'fill' : 'empty'}`}>
          <div />
        </div>
        <div className={`star ${score >= 0.8 ? 'fill' : 'empty'}`}>
          <div />
        </div>
        <div className={`star ${score >= 1 ? 'fill' : 'empty'}`}>
          <div />
        </div>
      </div>
    </>
  );
};

export const CustomOnclickEstimateScore = ({
  score,
  presetScore,
  onClick,
  isTooltipShow,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`estimate-score-contents ${score > 0 && 'preset-hide'}`}>
        <div
          className={`star ${score >= 0.2 ? 'fill' : 'empty'} ${
            presetScore >= 0.2 ? 'preset-fill' : 'preset-empty'
          }`}
          onClick={() => onClick(0.2)}
        >
          <div />
        </div>
        <div
          className={`star ${score >= 0.4 ? 'fill' : 'empty'} ${
            presetScore >= 0.4 ? 'preset-fill' : 'preset-empty'
          }`}
          onClick={() => onClick(0.4)}
        >
          <div />
        </div>
        <div
          className={`star ${score >= 0.6 ? 'fill' : 'empty'} ${
            presetScore >= 0.6 ? 'preset-fill' : 'preset-empty'
          }`}
          onClick={() => onClick(0.6)}
        >
          <div />
        </div>
        <div
          className={`star ${score >= 0.8 ? 'fill' : 'empty'} ${
            presetScore >= 0.8 ? 'preset-fill' : 'preset-empty'
          }`}
          onClick={() => onClick(0.8)}
        >
          <div />
        </div>
        <div
          className={`star ${score >= 1 ? 'fill' : 'empty'} ${
            presetScore >= 1 ? 'preset-fill' : 'preset-empty'
          }`}
          onClick={() => onClick(1)}
        >
          <div />
        </div>
      </div>
      {isTooltipShow !== 'styling' ? (
        <span className="tooltip fs12 obj">{t('desc.auto-score-star')}</span>
      ) : (
        <></>
      )}
    </>
  );
};

export const ProgressScore = ({ score, presetScore }) => {
  const { t } = useTranslation();
  return (
    <>
      {score !== 0 ? (
        score !== undefined ? (
          <span className="fs14">
            {`${score <= 0 ? 0 : Math.trunc(score * 100)}`}%
          </span>
        ) : (
          <span className="fs14">0%</span>
        )
      ) : presetScore !== undefined ? (
        <span className="fs14 grey600">
          {`${presetScore <= 0 ? 0 : Math.trunc(presetScore * 100)}`}%
        </span>
      ) : (
        <span className="fs14 grey600">0%</span>
      )}
      <div className={`progress-bar ${score !== 0 && 'preset-hide'}`}>
        {score !== 0 ? (
          <div
            className={`fill ${
              score <= 0.5
                ? score <= 0.25
                  ? 'background-red'
                  : 'background-yellow'
                : 'background-blue'
            }`}
            style={{ width: `${score <= 0 ? 0 : score * 100}%` }}
          />
        ) : (
          <div
            className="preset-fill"
            style={{
              width: `${
                presetScore <= 0 || presetScore === undefined
                  ? 0
                  : presetScore * 100
              }%`,
            }}
          />
        )}
      </div>
      <span className="tooltip fs12 obj">{t('desc.auto-score')}</span>
    </>
  );
};

Score.propTypes = {
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  presetScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EstimateScore.propTypes = {
  score: PropTypes.number,
  presetScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  questionId: PropTypes.string,
};

MultipleEstimateScore.propTypes = {
  score: PropTypes.number,
  onClick: PropTypes.func,
};

CustomOnclickEstimateScore.propTypes = {
  score: PropTypes.number,
  onClick: PropTypes.func,
};

ProgressScore.propTypes = {
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  presetScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
