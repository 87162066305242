import React from 'react';
import { CircularProgress } from '@mui/material';

const Loading = () => (
  <article className="modals-common-loading">
    <CircularProgress color="primary" size={50} />
  </article>
);

export default Loading;
