import { createBrowserHistory } from 'history';

export const setEventListener = (callback, event = {}) => {
  const { type, message } = event;

  const _callback = (e) => {
    if (e.detail?.message === message) {
      callback && callback();
    }
    window.document.removeEventListener(type, _callback);
  };

  window.document.addEventListener(type, _callback);
};

export const history = createBrowserHistory();
