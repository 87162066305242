import React from 'react';

const MaterialInput = ({
  className,
  icon,
  value,
  onChange,
  onKeyPress,
  placeholder,
  type: _type,
  color,
  message,
  multiple,
  disabled,
  placeColor,
  name,
}) => {
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [type, setType] = React.useState(_type);
  const [visibilityPassword, setVisibilityPassword] = React.useState(false);

  const containerRef = React.useRef(null);
  const textareaRef = React.useRef(null);

  React.useEffect(() => {
    if (multiple) {
      if (textareaRef.current && containerRef.current) {
        const textarea = textareaRef.current;
        const container = containerRef.current;

        textarea.style.height = 'auto';
        const height = textarea.scrollHeight;
        textarea.style.height = `${height}px`;
        container.style.height = `${height + 32}px`;
      }
    }
  }, [multiple, value]);

  React.useEffect(() => {
    if (!value) {
      return setIsEmpty(true);
    }
    return setIsEmpty(false);
  }, [value]);

  React.useEffect(() => {
    if (visibilityPassword) {
      setType('text');
    } else {
      setType(_type);
    }
  }, [visibilityPassword]);

  const toggleVisibilityOfPassword = () => {
    setVisibilityPassword(!visibilityPassword);
  };

  return (
    <div
      className={`components-input-auth-input ${className} ${color} ${
        multiple ? 'textarea' : 'input'
      }`}
    >
      <div
        ref={containerRef}
        className={`auth-input ${!isEmpty && 'fill'} ${
          multiple ? 'textarea' : 'input'
        } ${disabled && 'disabled'}`}
      >
        {multiple ? (
          <textarea
            type={type}
            ref={textareaRef}
            rows="1"
            maxLength="300"
            onKeyPress={onKeyPress}
            value={value}
            onChange={onChange}
            disabled={disabled}
            className="fs16"
          />
        ) : (
          <input
            type={type}
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
            disabled={disabled}
            autoComplete={type === 'password' ? 'off' : 'on'}
            className="fs16"
            name={name}
          />
        )}
        <div className={`auth-input-placeholder fs16 ${placeColor}`}>
          {placeholder}
        </div>
        <div
          className={`auth-input-icon ${_type === 'password' && 'active'}`}
          onClick={_type === 'password' ? toggleVisibilityOfPassword : null}
        >
          {icon}
        </div>
      </div>
      {Boolean(message) && <p className="message fs12">{message}</p>}
    </div>
  );
};

export default MaterialInput;
