import React from 'react';
import { useStatus } from 'utils/status';
import { dateToDateFormat, displayName } from 'utils/string';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MaterialInput from 'components/input/MaterialInput';
import { ReactComponent as SearchIcon } from 'assets/images/icon/search.svg';
import { useAuth } from 'utils/auth';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Box,
} from '@mui/material';
import { executeAction } from 'utils/redux';
import { getCandidates, getAssessments } from 'store/slices/candidatesSlice';
import { CircularProgress } from '@mui/material';

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsEntireLoading } = useStatus();
  const { userInfo } = useAuth();
  const [isRefreshing, setIsRefreshing] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [filterAssessment, setFilterAssessment] = React.useState({});
  const [filterSkill, setFilterSkill] = React.useState({});
  const [__candidates, __setCandidates] = React.useState([]);
  const [_candidates, _setCandidates] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [assessments, setAssessments] = React.useState({});
  const [skills, setSkills] = React.useState({});

  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    if (userInfo?.plan === 100) {
      navigate('/customer/candidates/list');
    }
  }, []);

  React.useEffect(() => {
    if (isRefreshing) {
      __setCandidates([]);
      return setIsRefreshing(false);
    }

    setIsLoading(true);
    setIsEntireLoading(true);
    executeAction(getCandidates({}))
      .unwrap()
      .then(({ docs }) => {
        const idxs = [];
        const tasks = [];
        for (const [idx, doc] of docs.entries()) {
          idxs.push(idx);
          tasks.push(
            executeAction(
              getAssessments({
                assessmentsObj: doc?.assessments,
              })
            ).unwrap()
          );
        }
        Promise.allSettled(tasks || []).then((result) => {
          const infos = (docs || []).map((it, jdx) => {
            const docIdx = idxs.findIndex((x) => x === jdx);
            return {
              ...it,
              assessments: result?.[docIdx]?.value?.assessments,
            };
          });

          let assessmentsTemp = {};
          let skillsTemp = {};
          (infos || []).forEach((it) => {
            Object.entries(it?.assessments || {}).forEach(([key, value]) => {
              assessmentsTemp[value?.name] = {
                ...value,
                display: {
                  en: value?.name,
                  ko: value?.name,
                },
              };

              Object.entries(value?.objectiveTests || {}).forEach(
                ([key2, value2]) => {
                  skillsTemp[value2?.id] = {
                    ...value2,
                    display: {
                      en: value2?.[`title_${t('lang')}`] || value2?.title,
                      ko: value2?.[`title_${t('lang')}`] || value2?.title,
                    },
                  };
                }
              );
            });
          });

          setAssessments(assessmentsTemp);
          setSkills(skillsTemp);
          __setCandidates(infos || []);
          setIsLoading(false);
          setIsEntireLoading(false);
        });
      })
      .catch((e) => {
        console.dir(e);
        __setCandidates([]);
        setIsLoading(false);
        setIsEntireLoading(false);
      });
  }, [isRefreshing]);

  React.useEffect(() => {
    let candidatesCopy = __candidates.slice(0);

    if (Object.keys(filterAssessment || {}).length > 0) {
      candidatesCopy = (candidatesCopy || []).filter((x) => {
        const _assessments = Object.values(x.assessments || {});
        const idx = _assessments.findIndex(
          (y) => y.name === filterAssessment.name
        );

        if (idx >= 0) return true;
        return false;
      });
    }

    if (Object.keys(filterSkill || {}).length > 0) {
      candidatesCopy = (candidatesCopy || []).filter((x) => {
        const _assessments = Object.values(x.assessments || {});

        for (const _assessment of _assessments) {
          const _skills = _assessment?.objectiveTests || [];

          let idx = _skills.findIndex((y) => {
            if (
              y?.[`title_${t('lang')}`] === filterSkill?.[`title_${t('lang')}`]
            )
              return true;
          });
          if (idx >= 0) return true;

          return false;
        }
      });
    }

    _setCandidates(
      (candidatesCopy || []).filter((x) => {
        const fullName1 = (x.firstName || '') + (x.lastName || '');
        const fullName2 = (x.lastName || '') + (x.firstName || '');
        const { email } = x;

        let flag = false;
        const convertedSearch = (search || '')
          .replaceAll(' ', '')
          .trim()
          .toLowerCase();
        if (
          fullName1
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .includes(convertedSearch)
        ) {
          flag = true;
        }
        if (
          fullName2
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .includes(convertedSearch)
        ) {
          flag = true;
        }

        if (email.includes(convertedSearch)) {
          flag = true;
        }

        Object.values(x.assessments || {}).map((assessment) => {
          if (assessment?.name.toLowerCase().includes(convertedSearch)) {
            flag = true;
            return;
          }
        });

        if (flag) {
          return x;
        }
      })
    );
  }, [__candidates, search, filterAssessment, filterSkill]);

  React.useEffect(() => {
    const startIdx = page * rowsPerPage;

    setCandidates([..._candidates].slice(startIdx, startIdx + rowsPerPage));
  }, [_candidates, page, rowsPerPage]);

  const getNumOfTests = (candidate) => {
    const { assessments } = candidate;
    let cnt = 0;
    Object.entries(assessments || {}).forEach(([key, value]) => {
      cnt += value?.tests?.length || 0;
    });
    return cnt;
  };

  const onRowClickHandler = (candidate) => {
    if (candidate?.assessments !== undefined) {
      navigate(`${encodeURI(candidate?.id)}`);
    } else {
      return;
    }
  };

  const getAssessmentList = (_assessments) => {
    const cnt = Object.keys(_assessments || {}).length;
    const values = Object.values(_assessments || {});
    if (cnt <= 0) {
      return <div />;
    }
    if (cnt === 1) {
      return (
        <div>
          <span>{values[0].name}</span>
        </div>
      );
    }
    if (cnt === 2) {
      return (
        <div>
          <span>{values[0].name}</span>
          <span>{values[1].name}</span>
        </div>
      );
    }
    return (
      <div>
        <span>{values[0].name}</span>
        <span>{values[1].name}</span>
        <span>+ {cnt - 2}</span>
      </div>
    );
  };

  return (
    <main className="pages-protected-candidates-list">
      <header className="header">
        <h2 className="header-title fs28">{t('header.menu.my-candidates')}</h2>
        <div className="header-right">
          <div className="header-right-search">
            <MaterialInput
              placeholder={t('text-field.label.search')}
              className="header-right-search-input"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              icon={<SearchIcon />}
            />
          </div>
        </div>
      </header>
      <section className="table">
        <Box>
          {isLoading || candidates?.length > 0 ? (
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('column.name')}</TableCell>
                      <TableCell>{t('column.email')}</TableCell>
                      <TableCell className="align-left">
                        {t('column.assessments')}
                      </TableCell>

                      <TableCell>{t('column.last-activity')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <CircularProgress color="primary" size={25} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      (candidates || []).map((candidate, idx) => (
                        <TableRow
                          key={idx}
                          hover
                          onClick={() => onRowClickHandler(candidate)}
                          sx={{
                            cursor:
                              candidate?.assessments !== undefined
                                ? 'pointer'
                                : 'default',
                          }}
                        >
                          <TableCell className="name tbody" width={200}>
                            {displayName(
                              candidate?.firstName,
                              candidate?.lastName,
                              t('lang')
                            )}
                          </TableCell>
                          <TableCell className="email tbody" width={350}>
                            {candidate?.email}
                          </TableCell>
                          <TableCell
                            className="assessment tbody align-left"
                            width={350}
                          >
                            {getAssessmentList(candidate?.assessments)}
                          </TableCell>

                          <TableCell className="created-at tbody" width={160}>
                            {dateToDateFormat(
                              candidate?.updatedAt?.toDate(),
                              t('lang')
                            ) ||
                              dateToDateFormat(
                                new Date(
                                  ExcelDateToJSDate(candidate?.apply_date)
                                ),
                                t('lang')
                              ) ||
                              dateToDateFormat(
                                new Date(candidate?.apply_date),
                                t('lang')
                              )}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                // count={applyCount}
                count={_candidates.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          ) : (
            <section className="empty">
              <div className="thumbnail" />
              <Trans
                t={t}
                parent="p"
                className="fs28"
                i18nKey="my-candidates.desc.empty"
              />
            </section>
          )}
        </Box>
      </section>
    </main>
  );
};

export default List;
