import React from 'react';

const __OBPI_RESULT_NUM__ = 9;

const OBPIResult = ({
  title,
  title_class,
  selected,
  disc_left,
  disc_right,
  disc_class,
  disc,
  interpretation,
  printmode,
  interp_class,
}) => {
  const Selected = new Array(__OBPI_RESULT_NUM__).fill(false);
  Selected[selected - 1] = true;
  return (
    <div className="obpi-detail-result-list">
      <div className={`obpi-detail-result-title ${title_class}`}>
        {title}
        {printmode === false && (
          <>
            <div className="obpi-detail-result-questionmark" />
            <span className="tooltip fs12">{disc}</span>
          </>
        )}
      </div>
      <div className="obpi-detail-results">
        <ul className="obpi-detail-result-answer">
          {(Selected || []).map((_selected, idx) => (
            <li
              key={idx}
              className={`obpi-detail-result-answer-item ${
                _selected && 'selected'
              }`}
            >
              {_selected}
            </li>
          ))}
        </ul>
      </div>
      <div className="opbi-detail-result-discription-box">
        <div className={`discription ${disc_class} left`}>{disc_left}</div>
        <div className={`discription ${disc_class} right`}>{disc_right}</div>
      </div>
      {printmode === true && (
        <div className="obpi-detail-result-interpretation fs14">{disc}</div>
      )}
      <div className={`obpi-detail-result-interpretation ${interp_class}`}>
        {interpretation}
      </div>
    </div>
  );
};

export default OBPIResult;
