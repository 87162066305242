import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  setPassword,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithAuthToken,
  signOut,
  getUserInfo,
  uploadCorporationLogo,
  createUserInfo,
  updateUserInfo as updateUserInfoThunk,
  updateCompanyInfo as updateCompanyInfoThunk,
  updateUserLimit as updateUserLimitThunk,
} from 'store/slices/authSlice';
import { toast } from 'utils/notify';
import CompanyEmailValidator from 'company-email-validator';
import axios from 'axios';
import i18next from 'i18n';
import sha512 from 'crypto-js/sha512';
import { useStatus } from 'utils/status';
import { createMemberUserInfo } from 'store/slices/recruitments/membersSlice';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(null);

  React.useEffect(() => {
    if (!user?.uid) {
      return setUserInfo(null);
    }

    executeAction(getUserInfo())
      .unwrap()
      .then(({ doc }) => {
        setUserInfo(doc || null);
      })
      .catch((e) => {
        Sentry.captureException(e);
        console.dir(e);
        setUserInfo(null);
      });
  }, [user?.uid]);

  const signup = (
    {
      authToken,
      firstName,
      lastName,
      corporation,
      position,
      numEmployee,
      trackingSystem,
      file,
      account,
    },
    callback,
    errorCallback
  ) =>
    new Promise(async (resolve, reject) => {
      executeAction(
        createUserWithEmailAndPassword({
          authToken,
        })
      )
        .unwrap()
        .then(({ user, email }) => {
          executeAction(uploadCorporationLogo({ file }))
            .unwrap()
            .then(async (result) => {
              const logoURL = await result?.downloadURL;
              executeAction(
                createUserInfo({
                  authToken,
                  email,
                  firstName,
                  lastName,
                  corporation,
                  position,
                  numEmployee,
                  trackingSystem,
                  logoURL,
                  account,
                  enabledSkill_en: ['1010', '1110', '1210', '1310', '1410', '1510', '1610', '1710', '1810', '011010', '011110', '011210', '011310', '011410' ],
                  enabledSkill_ko: ['1010', '1110', '1210', '1321', '1322', '1410', '1510', '1610', '1710', '1810', '6010', '011010', '011110', '011210', '011310', '011410', '011510', '011610'],
                })
              )
                .unwrap()
                .then((result) => {
                  setUser(user);
                  window.sessionStorage.setItem(
                    'munch_skill_auth_token',
                    authToken
                  );
                  // window.sessionStorage.setItem('munch_skill_user_info', JSON.stringify(user));
                  callback && callback();
                  return resolve(result);
                }).catch((e) => {
                Sentry.captureException(e);
              });
            })
            .catch((e) => {
              executeAction(
                createUserInfo({
                  authToken,
                  email,
                  firstName,
                  lastName,
                  corporation,
                  position,
                  numEmployee,
                  trackingSystem,
                  account,
                  enabledSkill_en: ['1010', '1110', '1210', '1310', '1410', '1510', '1610', '1710', '1810', '011010', '011110', '011210', '011310', '011410' ],
                  enabledSkill_ko: ['1010', '1110', '1210', '1321', '1322', '1410', '1510', '1610', '1710', '1810', '6010', '011010', '011110', '011210', '011310', '011410', '011510', '011610'],
                })
              )
                .unwrap()
                .then((result) => {
                  setUser(user);
                  window.sessionStorage.setItem(
                    'munch_skill_auth_token',
                    authToken
                  );
                  callback && callback();
                  return resolve(result);
                }).catch((e) =>           Sentry.captureException(e));
            });
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.dir(e);
          switch (e?.code) {
            default:
              toast(e.message);
              break;
          }
          errorCallback && errorCallback(e);
          return reject(e);
        });
    });

  const memberSignup = (
    {
      authToken,
      firstName,
      lastName,
      jobFunction,
      team,
      ownerUid,
      phoneNumber,
      password,
      workspace,
      memberType,
      account,
      randomColor,
      disabled,
    },
    callback,
    errorCallback
  ) =>
    new Promise(async (resolve, reject) => {
      executeAction(setPassword({ authToken, password }))
        .unwrap()
        .then(() => {
          executeAction(
            createUserWithEmailAndPassword({
              authToken,
            })
          )
            .unwrap()
            .then(({ user, email }) => {
              executeAction(
                createMemberUserInfo({
                  authToken,
                  email,
                  firstName,
                  lastName,
                  jobFunction,
                  team,
                  ownerUid,
                  phoneNumber,
                  workspace,
                  memberType,
                  isATS: true,
                  plan: 100,
                  account,
                  randomColor,
                  disabled,
                  enabledSkill_en: ['990001', '991010', '991111'],
                  enabledSkill_ko: ['990001', '991010', '991111'],
                })
              )
                .unwrap()
                .then((result) => {
                  setUser(user);
                  window.sessionStorage.setItem(
                    'munch_skill_auth_token',
                    authToken
                  );
                  callback && callback();
                  return resolve(result);
                })
                .catch((e) => {
                  Sentry.captureException(e);
                  console.error(e);
                });
            })
            .catch((e) => {
              switch (e?.code) {
                default:
                  toast(e.message);
                  break;
              }
              errorCallback && errorCallback(e);
              return reject(e);
            });
        })
        .catch((e) => {
          switch (e.message) {
            case 'expired_token':
              toast(t('set-pw-expired.title.set-pw-expired'));
              break;
            default:
              Sentry.captureException(e);
              toast(t(e.message));
              break;
          }
        });
    });

  const _signinWithAuthToken = ({ authToken }, callback, errorCallback) =>
    new Promise(async (resolve, reject) => {
      executeAction(signInWithAuthToken({ authToken }))
        .unwrap()
        .then(({ user }) => {
          executeAction(getUserInfo({ uid: user?.id }))
            .unwrap()
            .then(({ doc }) => {
              if (doc?.disabled) {
                signout();
                return toast('비활성화 된 계정입니다.');
              }

              return resolve(true);
            })
            .catch((e) => reject(e));

          setUser(user);
          window.sessionStorage.setItem('munch_skill_auth_token', authToken);
          callback && callback(authToken);
          return resolve(user);
        })
        .catch((e) => {
          switch (e?.code) {
            case 'auth/user-not-found':
              toast(i18next.t('toast.desc.check-id-pw'));
              break;
            default:
              Sentry.captureException(e);
              toast(i18next.t('toast.desc.check-id-pw'));
              break;
          }
          errorCallback && errorCallback(e);
          return reject(e);
        });
    });

  const _signinWithEmailAndPassword = (
    { email, password },
    callback,
    errorCallback
  ) =>
    new Promise(async (resolve, reject) => {
      executeAction(signInWithEmailAndPassword({ email, password }))
        .unwrap()
        .then(({ user, authToken }) => {
          executeAction(getUserInfo({ uid: user?.id }))
            .unwrap()
            .then(({ doc }) => {
              if (doc?.disabled) {
                signout();
                return toast('비활성화 된 계정입니다.');
              }

              return resolve(true);
            })
            .catch((e) => reject(e));

          setUser(user);
          window.sessionStorage.setItem('munch_skill_auth_token', authToken);
          callback && callback(authToken);
          return resolve(user);
        })
        .catch((e) => {
          switch (e?.code) {
            case 'auth/user-not-found':
              toast(i18next.t('toast.desc.check-id-pw'));
              break;
            default:
              Sentry.captureException(e);
              toast(i18next.t('toast.desc.check-id-pw'));
              break;
          }
          errorCallback && errorCallback(e);
          return reject(e);
        });
    });

  const signin = ({ email, password, authToken }, callback, errorCallback) =>
    new Promise(async (resolve, reject) => {
      if (Boolean(email) && Boolean(password)) {
        try {
          await _signinWithEmailAndPassword(
            { email, password },
            callback,
            errorCallback
          );
          return resolve(true);
        } catch (e) {
          return reject(e);
        }
      } else if (authToken) {
        try {
          await _signinWithAuthToken({ authToken }, callback, errorCallback);
          return resolve(true);
        } catch (e) {
          return reject(e);
        }
      }
    });

  const signout = (callback, errorCallback) =>
    new Promise(async (resolve, reject) => {
      executeAction(signOut())
        .unwrap()
        .then(() => {
          // Do action.
          setUser(null);
          window.sessionStorage.removeItem('munch_skill_auth_token');
          window.localStorage.removeItem('munch_skill_auth_token');
          callback && callback();
          return resolve(null);
        })
        .catch((e) => {
          Sentry.captureException(e);
          console.dir(e);
          switch (e?.code) {
            default:
              toast(e.message);
              break;
          }
          errorCallback && errorCallback(e);
          return reject(e);
        });
    });

  const updateAccountInfo = (
    { firstName, lastName },
    callback,
    errorCallback
  ) =>
    new Promise(async (resolve, reject) => {
      executeAction(
        updateUserInfoThunk({
          firstName,
          lastName,
        })
      )
        .unwrap()
        .then(() => {
          executeAction(getUserInfo())
            .unwrap()
            .then(({ doc }) => {
              setUserInfo(doc);
              return resolve(true);
            })
            .catch((e) => reject(e));
        })
        .catch((e) => reject(e));
    });

  const updateUserLimit = (
    { assessmentLimit, candidateLimit },
    callback,
    errorCallback
  ) =>
    new Promise(async (resolve, reject) => {
      executeAction(
        updateUserLimitThunk({
          assessmentLimit,
          candidateLimit,
        })
      )
        .unwrap()
        .then(() => {
          executeAction(getUserInfo())
            .unwrap()
            .then(({ doc }) => {
              setUserInfo(doc);
              return resolve(true);
            })
            .catch((e) => reject(e));
        })
        .catch((e) => reject(e));
    });

  const updateCompanyInfo = ({ corporation, logo }, callback, errorCallback) =>
    new Promise(async (resolve, reject) => {
      executeAction(
        updateCompanyInfoThunk({
          corporation,
          logo,
        })
      )
        .unwrap()
        .then(() => {
          executeAction(getUserInfo())
            .unwrap()
            .then(({ doc }) => {
              setUserInfo(doc);
              return resolve(true);
            })
            .catch((e) => reject(e));
        })
        .catch((e) => reject(e));
    });

  const value = {
    user,
    userInfo,
    signup,
    signin,
    signout,
    updateAccountInfo,
    updateUserLimit,
    updateCompanyInfo,
    memberSignup,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);

export const RequireAuth = ({ children, requireAuth, setRequireAuth }) => {
  const auth = useAuth();
  const { isAuthLoading, setIsAuthLoading } = useStatus();
  const location = useLocation();
  const account = window.localStorage.getItem('munch_skill_account') || 'main';

  if (isAuthLoading) {
    const _timeout = window.setTimeout(() => {
      setIsAuthLoading(false);
      window.clearTimeout(_timeout);
    }, 10000);
    return;
  }
  setIsAuthLoading(true);
  const authToken =
    window.localStorage.getItem('munch_skill_auth_token') ||
    window.sessionStorage.getItem('munch_skill_auth_token');

  if (!auth.user) {
    if (authToken) {
      auth
        .signin({ authToken })
        .then(() => {
          setRequireAuth(false);
          setIsAuthLoading(false);
          return children;
        })
        .catch((e) => {
          console.dir(e);
          Sentry.captureException(e);
          window.localStorage.removeItem('munch_skill_auth_token');
          window.sessionStorage.removeItem('munch_skill_auth_token');
          setRequireAuth(false);
          setIsAuthLoading(false);
        });
    }
    if (!authToken) {
      setRequireAuth(true);
      setIsAuthLoading(false);
      return (
        <Navigate
          to={`/${account}/auth/signin`}
          // state={{ from: location }}
          state={{ from: '/' }}
          replace
        />
      );
    }
  } else {
    setRequireAuth(false);
    setIsAuthLoading(false);
    return children;
  }
};

export const validateEmail = (email) => {
  try {
    const emailRegexp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
    return emailRegexp.test(email);
  } catch (e) {
    return false;
  }
};

export const validateEmailDomain = (email) =>
  new Promise(async (resolve, reject) => {
    try {
      if (!CompanyEmailValidator.isCompanyEmail(email)) {
        throw new Error('invalid-job-email');
      }
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_PUBLIC_PATH}/auth/validate/email`,
        {
          email,
        }
      );

      return resolve(true);
    } catch (e) {
      return reject(e);
    }
  });

export const validatePassword = (password) => {
  try {
    let hasLowerCase = false;
    let hasUpperCase = false;
    let hasNumber = false;
    let hasSpecial = false;
    let hasNoAllowanceSpecial = false;

    if (password.length < 8 || password.length > 16) {
      throw new Error('string_length_fail');
    }
    for (const c of password) {
      const ascCode = c.charCodeAt();

      if (ascCode >= 48 && ascCode <= 57) {
        hasNumber = true;
      } else if (ascCode >= 65 && ascCode <= 90) {
        hasUpperCase = true;
      } else if (ascCode >= 97 && ascCode <= 122) {
        hasLowerCase = true;
      } else if (
        ascCode === 33 ||
        ascCode === 64 ||
        ascCode === 35 ||
        ascCode === 36 ||
        ascCode === 37 ||
        ascCode === 94 ||
        ascCode === 38 ||
        ascCode === 42 ||
        ascCode === 40 ||
        ascCode === 41 ||
        ascCode === 95 ||
        ascCode === 43 ||
        ascCode === 61 ||
        ascCode === 126
      ) {
        hasSpecial = true;
      } else {
        hasNoAllowanceSpecial = true;
      }
    }

    if (!hasLowerCase) {
      throw new Error('no_lower_case');
    }
    if (!hasUpperCase) {
      throw new Error('no_upper_case');
    }
    if (!hasNumber) {
      throw new Error('no_number');
    }
    if (!hasSpecial) {
      throw new Error('no_special_character');
    }
    if (hasNoAllowanceSpecial) {
      throw new Error('has_no_allowance_special_character');
    }

    return {
      success: true,
      payload: {
        error: null,
      },
    };
  } catch (e) {
    return {
      success: false,
      payload: {
        error: e,
      },
    };
  }
};

export const encryptString = (str) => {
  try {
    // TODO: make the private.
    const nonce = '930911';
    return sha512(nonce + str).toString();
  } catch (e) {
    return str;
  }
};
