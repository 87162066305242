import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Box,
} from '@mui/material';
import { useModal } from 'utils/modal';
import { ReactComponent as VideoIcon } from 'assets/images/icon/ondemand_video.svg';
import { ReactComponent as EssayIcon } from 'assets/images/icon/text_snippet.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/images/icon/format_list_bulleted.svg';
import { ReactComponent as UploadFileIcon } from 'assets/images/icon/cloud_upload.svg';
import { ReactComponent as SpreadsheetIcon } from 'assets/images/icon/spreadsheet.svg';
import PropTypes from 'prop-types';
import { isSameLowerCaseString } from 'utils/string';
import { executeAction } from 'utils/redux';
import { getLibraryDocs } from 'store/slices/assessmentsSlice';
import { toast } from 'utils/notify';

const __MAX_SIZE_OF_TESTS__ = 5;

const __ITEMS__ = [
  {
    key: 'essay',
    i18nKey: 'btn.essay',
    icon: <EssayIcon />,
    onClick: ({ displayModal, subjectiveTests, setSubjectiveTests }) => {
      displayModal(
        'assessments/EssayTest',
        {
          subjectiveTests,
          setSubjectiveTests,
        },
        { frame: 'integration' }
      );
    },
    class: 'tag-essay',
  },
  {
    key: 'multiple-choice',
    i18nKey: 'btn.multiple-choice',
    icon: <MultipleChoiceIcon />,
    onClick: ({ displayModal, subjectiveTests, setSubjectiveTests }) => {
      displayModal(
        'assessments/MultipleChoiceTest',
        {
          subjectiveTests,
          setSubjectiveTests,
        },
        { frame: 'integration' }
      );
    },
    class: 'tag-multiple-choice',
  },
  {
    key: 'video',
    i18nKey: 'btn.video',
    icon: <VideoIcon />,
    onClick: ({ displayModal, subjectiveTests, setSubjectiveTests }) => {
      displayModal(
        'assessments/VideoTest',
        {
          subjectiveTests,
          setSubjectiveTests,
        },
        {
          frame: 'integration',
        }
      );
    },
    class: 'tag-video',
  },
  {
    key: 'file-upload',
    i18nKey: 'btn.file-upload',
    icon: <UploadFileIcon />,
    onClick: ({ displayModal, subjectiveTests, setSubjectiveTests }) => {
      displayModal(
        'assessments/FileUploadTest',
        {
          subjectiveTests,
          setSubjectiveTests,
        },
        { frame: 'integration' }
      );
    },
    class: 'tag-file-upload',
  },
  // {
  //   key: 'spreadsheet',
  //   i18nKey: 'btn.spreadsheet',
  //   icon: <SpreadsheetIcon />,
  //   onClick: ({ displayModal, subjectiveTests, setSubjectiveTests }) => {
  //     displayModal(
  //       'assessments/subjectiveTests/Spreadsheet',
  //       {
  //         subjectiveTests,
  //         setSubjectiveTests,
  //       },
  //       {
  //         frame: 'integration',
  //       }
  //     );
  //   },
  // },
];

const __DROPDOWN_ITEMS__ = [
  {
    key: 'move-up',
    i18nKey: 'actions.menu.move-up',
    onClick: ({
      idx,
      setShowDropdown,
      subjectiveTests,
      setSubjectiveTests,
    }) => {
      if (idx > 0) {
        setSubjectiveTests([
          ...subjectiveTests.slice(0, idx - 1),
          subjectiveTests[idx],
          subjectiveTests[idx - 1],
          ...subjectiveTests.slice(idx + 1, subjectiveTests.length),
        ]);
      }
      setShowDropdown(-1);
    },
  },
  {
    key: 'move-down',
    i18nKey: 'actions.menu.move-down',
    onClick: ({
      idx,
      setShowDropdown,
      subjectiveTests,
      setSubjectiveTests,
    }) => {
      if (idx < subjectiveTests.length - 1) {
        setSubjectiveTests([
          ...subjectiveTests.slice(0, idx),
          subjectiveTests[idx + 1],
          subjectiveTests[idx],
          ...subjectiveTests.slice(idx + 2, subjectiveTests.length),
        ]);
      }
      setShowDropdown(-1);
    },
  },
  {
    key: 'edit',
    i18nKey: 'actions.menu.edit',
    onClick: ({
      idx,
      displayModal,
      setShowDropdown,
      subjectiveTests,
      setSubjectiveTests,
    }) => {
      const subjectiveTest = subjectiveTests?.[idx];

      switch (subjectiveTest?.section) {
        case 'video':
          displayModal(
            'assessments/VideoTest',
            {
              idx,
              subjectiveTests,
              setSubjectiveTests,
            },
            {
              frame: 'integration',
            }
          );
          break;
        case 'essay':
          displayModal(
            'assessments/EssayTest',
            {
              idx,
              subjectiveTests,
              setSubjectiveTests,
            },
            { frame: 'integration' }
          );
          break;
        case 'multiple-choice':
          displayModal(
            'assessments/MultipleChoiceTest',
            {
              idx,
              subjectiveTests,
              setSubjectiveTests,
            },
            { frame: 'integration' }
          );
          break;
        case 'file-upload':
          displayModal(
            'assessments/FileUploadTest',
            {
              idx,
              subjectiveTests,
              setSubjectiveTests,
            },
            { frame: 'integration' }
          );
          break;
        case 'spreadsheets':
          displayModal(
            'assessments/subjectiveTests/Spreadsheet',
            {
              idx,
              subjectiveTests,
              setSubjectiveTests,
            },
            {
              frame: 'integration',
            }
          );
          break;
      }
      setShowDropdown(-1);
    },
  },
  {
    key: 'delete',
    i18nKey: 'actions.menu.delete',
    onClick: ({
      idx,
      setShowDropdown,
      subjectiveTests,
      setSubjectiveTests,
    }) => {
      setSubjectiveTests([
        ...subjectiveTests.slice(0, idx),
        ...subjectiveTests.slice(idx + 1, subjectiveTests.length),
      ]);
      setShowDropdown(-1);
    },
  },
];

const Step3 = ({ curStep, subjectiveTests, setSubjectiveTests, language }) => {
  const { t } = useTranslation();
  const { displayModal } = useModal();
  const [showDropdown, setShowDropdown] = React.useState(-1);
  const [isLibraryLoading, setIsLibraryLoading] = React.useState(false);
  const [_libraryDocs, _setLibraryDocs] = React.useState([]);
  const [libraryDocs, setLibraryDocs] = React.useState([]);

  // table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rowRefs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];
  const resultRef = React.useRef(null);
  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    setIsLibraryLoading(true);
    executeAction(getLibraryDocs())
      .unwrap()
      .then(({ docs }) => {
        _setLibraryDocs(docs || []);
      })
      .catch((e) => {
        setIsLibraryLoading(false);
      });
  }, []);

  React.useEffect(() => {
    const handler = (event) => {
      for (const rowRef of rowRefs) {
        if (rowRef.current) {
          const moreElem = rowRef.current.querySelector('.more');
          if (moreElem.contains(event.target)) return;
        }
      }
      if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
        return;
      }

      setShowDropdown(-1);
    };

    window.document.addEventListener('mousedown', handler);
    window.document.addEventListener('touchstart', handler);

    return () => {
      window.document.removeEventListener('mousedown', handler);
      window.document.removeEventListener('touchstart', handler);
    };
  }, [dropdownRef, rowRefs]);

  // limit number of tests.
  React.useEffect(() => {
    if ((subjectiveTests || []).length > __MAX_SIZE_OF_TESTS__) {
      toast(t('title.add-up-to-5-custom-questions'));
      setSubjectiveTests([...subjectiveTests.slice(0, __MAX_SIZE_OF_TESTS__)]);
    }
  }, [subjectiveTests]);

  // pagination
  React.useEffect(() => {
    const startIdx = page * rowsPerPage;
    let newLibraryDocs = (_libraryDocs || []).slice(
      startIdx,
      startIdx + rowsPerPage
    );
    setLibraryDocs(newLibraryDocs || []);
  }, [_libraryDocs, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSectionName = React.useCallback((section) => {
    switch (section) {
      case 'video':
        return 'btn.video';
      case 'essay':
        return 'btn.essay';
      case 'multiple-choice':
        return 'btn.multiple-choice';
      case 'file-upload':
        return 'btn.file-upload';
      case 'spreadsheets':
        return 'btn.spreadsheet';
      case 'code':
        return 'btn.code';
    }
  }, []);

  const onMoreClickHandler = (idx) => {
    if (resultRef?.current && rowRefs?.[idx]?.current)
      var resultRect = resultRef.current.getBoundingClientRect(),
        elemRect = rowRefs?.[idx].current.getBoundingClientRect(),
        offset = elemRect.top - resultRect.top;

    resultRef.current.style.setProperty('--dropdown-top', `${offset}px`);
    setShowDropdown(idx);
  };

  const onLibraryDocAddClickHandler = (item) => {
    setSubjectiveTests([...subjectiveTests, item]);
  };

  const onLibraryDocRemoveClickHandler = (item) => {};

  const onLibraryDocDeleteClickHandler = (item) => {
    displayModal(
      'assessments/ConfirmDelete',
      {
        lid: item?.id,
        libraryDocs: _libraryDocs,
        setLibraryDocs: _setLibraryDocs,
      },
      {
        frame: 'part',
        hasButton: true,
      }
    );
  };

  return (
    <main className="pages-protected-assessments-create-step-3">
      <section ref={resultRef} className="result">
        <p className="fs20">{t('title.add-up-to-5-custom-questions')}</p>
        <Box>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%" className="table-type thead">
                      <span>{t('column.type')}</span>
                    </TableCell>
                    <TableCell width="70%" className="table-question thead">
                      <span>{t('column.question')}</span>
                    </TableCell>
                    <TableCell className="table-duration thead">
                      <span>{t('column.duration')}</span>
                    </TableCell>
                    <TableCell align="right" className="table-more thead">
                      <span>{t('column.actions')}</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(subjectiveTests || []).length ? (
                    (subjectiveTests || []).map((test, idx) => (
                      <TableRow key={idx} ref={rowRefs[idx]} hover>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          {t(getSectionName(test?.section))}
                        </TableCell>
                        {/*  */}
                        <TableCell
                          className="title"
                          dangerouslySetInnerHTML={{ __html: test?.question }}
                        />

                        <TableCell className="duration">
                          <div className="duration-icon" />
                          <span>{t(`text-field.input.${test?.limit}`)}</span>
                        </TableCell>
                        <TableCell align="right">
                          <div
                            className="more"
                            onClick={() =>
                              onMoreClickHandler(
                                showDropdown === idx ? -1 : idx
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} className="empty-test">
                        <div className="container empty">
                          {t('add-questions.desc.empty')}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        {showDropdown > -1 && (
          <div ref={dropdownRef} className="result-dropdown">
            <ul className="result-dropdown-list">
              {(__DROPDOWN_ITEMS__ || []).map((item) => (
                <li
                  key={item.key}
                  className="result-dropdown-list-item"
                  onClick={() =>
                    item.onClick({
                      idx: showDropdown,
                      displayModal,
                      setShowDropdown,
                      subjectiveTests,
                      setSubjectiveTests,
                    })
                  }
                >
                  {t(item.i18nKey)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
      <section className="form">
        <section className="form-custom">
          <p className="fs20">{t('title.create-custom-question')}</p>
          <ul className="form-custom-list">
            {(__ITEMS__ || []).map((item, idx) => (
              <li
                key={idx}
                className={`form-custom-list-item ${item.class}`}
                onClick={() =>
                  item.onClick({
                    displayModal,
                    subjectiveTests,
                    setSubjectiveTests,
                  })
                }
              >
                <div className="form-custom-list-item-icon">{item.icon}</div>
                <span className="fs16">{t(item.i18nKey)}</span>
              </li>
            ))}
          </ul>
        </section>
        <section className="form-library">
          <p className="fs20">{t('title.library')}</p>
          <Box>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="10%" className="table-category thead">
                        {t('column.category')}
                      </TableCell>
                      <TableCell width="10%" className="table-type thead">
                        {t('column.type')}
                      </TableCell>
                      <TableCell
                        // width="45%"
                        className="table-question thead"
                      >
                        {t('column.question')}
                      </TableCell>

                      <TableCell
                        width="10%"
                        className="duration table-duration thead"
                      >
                        {t('column.duration')}
                      </TableCell>
                      <TableCell width="15%" className="table-more thead">
                        {t('column.actions')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(libraryDocs || []).map((libraryDoc, idx) => (
                      <TableRow key={idx}>
                        <TableCell
                          className="table-type tbody"
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          {libraryDoc?.category
                            ? libraryDoc?.category
                            : t('title.my-library')}
                        </TableCell>
                        <TableCell
                          className="table-type tbody"
                          sx={{
                            fontWeight: 'bold',
                          }}
                        >
                          {libraryDoc?.section
                            ? t(getSectionName(libraryDoc?.section))
                            : libraryDoc?.type}
                        </TableCell>
                        <TableCell
                          className="table-question tbody title"
                          dangerouslySetInnerHTML={{
                            __html: libraryDoc?.question,
                          }}
                        />

                        <TableCell className="duration table-duration tbody">
                          <div className="duration-icon" />
                          <span>
                            {libraryDoc?.limit
                              ? t(`text-field.input.${libraryDoc?.limit}`)
                              : `${libraryDoc?.duration}min(s)`}
                          </span>
                        </TableCell>
                        <TableCell className="more table-more tbody">
                          <div className="container">
                            <button
                              className={`add common-button fsbtn16 ${
                                (subjectiveTests || []).findIndex((x) =>
                                  isSameLowerCaseString(x.id, libraryDoc.id)
                                ) > -1
                                  ? 'inactive'
                                  : 'active'
                              }`}
                              onClick={() =>
                                (subjectiveTests || []).findIndex((x) =>
                                  isSameLowerCaseString(x.id, libraryDoc.id)
                                ) > -1
                                  ? null
                                  : onLibraryDocAddClickHandler(libraryDoc)
                              }
                            >
                              <span>{t('btn.add')}</span>
                            </button>
                            {libraryDoc?.category ? (
                              <></>
                            ) : (
                              <div
                                className="delete-icon"
                                onClick={() =>
                                  onLibraryDocDeleteClickHandler(libraryDoc)
                                }
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={_libraryDocs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </section>
      </section>
    </main>
  );
};

Step3.propTypes = {
  curStep: PropTypes.number,
  subjectiveTests: PropTypes.array,
  setSubjectiveTests: PropTypes.func,
};

export default Step3;
