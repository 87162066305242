import React from 'react';
import { useTranslation } from 'react-i18next';
import ResultScoreBox from './ResultScoreBox';

const PrintVideoResultDetail = ({ result, link }) => {
  const { t } = useTranslation();

  return (
    <div className="print-toggle-body-answer-box">
      <aside className="video-link-box">
        <a href={link} className="video-link">
          {t('btn.view-file-link')}
        </a>
        <div className="result-stt-box">
          <p className="results-desc">{result?.text && result?.text}</p>
        </div>
      </aside>
    </div>
  );
};

export default PrintVideoResultDetail;
