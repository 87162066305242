import React from 'react';

const RejectedOffer = () => {
  return (
    <main id="pages-completed-apply" className="pages-completed-date">
      <section className="completed-apply-container">
        <article className="completed-text-wrapper">
          <p className="fs16">
            오퍼레터 제안이
            <br />
            거절되었습니다.
          </p>
        </article>
      </section>
    </main>
  );
};

export default RejectedOffer;
