import React from 'react';
import { ReactComponent as EmailIcon } from 'assets/images/icon/email.svg';
import { useTranslation } from 'react-i18next';
import ApplyInputBox from './ApplyInputBox';
import { countryCodeOptions, genderOptions, locationOptions } from '../../constants/recruitments/applySelectOptions';

const ApplyFormContainer = ({ notAllow, input, handleInput }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* firstName, lastName */}
      <aside className="apply-input-box flex">
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.last-name')}
          label={t('text-field.label.last-name')}
          required
          name="lastName"
          value={input.lastName}
          handleInput={handleInput}
        />
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.first-name')}
          label={t('text-field.label.first-name')}
          required
          name="firstName"
          value={input.firstName}
          handleInput={handleInput}
        />
      </aside>
      {/* country code, mobile number */}
      <aside className="apply-input-box flex">
        <ApplyInputBox
          label={t('survey.country-code')}
          required
          isSelectBox
          name="countryCode"
          value={input.countryCode}
          handleInput={handleInput}
          menus={countryCodeOptions}
        />
        <ApplyInputBox
          inputType="text"
          placeholder={t('column.mobile-number')}
          label={t('column.mobile-number')}
          required
          name="phoneNumber"
          value={input.phoneNumber}
          handleInput={handleInput}
        />
      </aside>
      {/* email */}
      <aside className="apply-input-box">
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.label.email')}
          label={t('text-field.label.email')}
          required
          name="email"
          value={input.email}
          handleInput={handleInput}
          notAllow={notAllow}
          icon={<EmailIcon />}
        />
      </aside>
      {/* birth */}
      <aside className="apply-input-box flex">
        <ApplyInputBox
          inputType="text"
          placeholder={t('text-field.placeholder.birth')}
          label={t('field.birth')}
          required
          name="birth"
          value={input.birth}
          handleInput={handleInput}
        />
        <ApplyInputBox
          label={t('menu.label.sex')}
          required
          isSelectBox
          name="gender"
          value={input.gender}
          handleInput={handleInput}
          menus={genderOptions}
        />
      </aside>
      {/* country/region */}
      <aside className="apply-input-box flex">
        <ApplyInputBox
          label={t('text-field.label.country')}
          required
          isSelectBox
          name="country"
          value={input.country}
          handleInput={handleInput}
          menus={locationOptions}
        />
        <ApplyInputBox
          label={t('label.location')}
          required
          isSelectBox
          name="location"
          value={input.location}
          handleInput={handleInput}
          menus={locationOptions}
        />
      </aside>
    </>
  );
};

export default ApplyFormContainer;
