import { Box, Skeleton } from '@mui/material';
import React from 'react';

const TabButtonSkeleton = () => {
  return (
    <li className="tab">
      <Box sx={{ width: '100%' }}>
        <Skeleton animation="wave" sx={{ height: 60 }} />
      </Box>
    </li>
  );
};

export default TabButtonSkeleton;
