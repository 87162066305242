import React from 'react';
import { convertHtmlForDisplay } from 'utils/string';
import { useTranslation } from 'react-i18next';

const CustomMultipleResult = ({
  visibilityDropdown,
  question,
  candidate,
  test,
  idx,
}) => {
  const { t } = useTranslation();

  return (
    <aside
      className={`toggle-body-content custom-multiple page-break ${
        visibilityDropdown ? 'slide-in' : 'slide-out'
      }`}
    >
      <div className="toggle-body-title-box">
        <div className="print-toggle-body-question">
          <span className="question-number">Q{idx + 1}.</span>
          <pre
            className="question"
            dangerouslySetInnerHTML={{
              __html: convertHtmlForDisplay(question?.question),
            }}
          />
        </div>
      </div>
      <div className="toggle-body-answer-box">
        <aside className="question-box">
          <span>{t('title.correct-answer')}</span>
          <ul>
            <li>{test?.answers?.list[Number(test?.answers.correct)]}</li>
          </ul>
        </aside>
        <aside className="answer-box">
          <span>{t('title.answer')}</span>
          <ul>
            {test?.answers?.list.map((item, idx) => (
              <li
                key={idx}
                className={`${
                  Object.values(
                    candidate?.results?.[test?.uuid]?.checks || {}
                  ).findIndex((x) => Number(x) === idx) >= 0 && 'selected'
                }`}
              >
                {item}
              </li>
            ))}
          </ul>
        </aside>
      </div>
    </aside>
  );
};

export default CustomMultipleResult;
