import { Modal } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close-modal.svg';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  addRecruitmentCandidate,
  getRecruitments,
  setCandidateOfRecruitment,
  updateRecruitmentCandidate,
} from 'store/slices/recruitments/recruitmentsSlice';
import { toast } from 'utils/notify';
import { executeAction } from 'utils/redux';
import { useTranslation } from 'react-i18next';
import { Timestamp } from 'firebase/firestore';
import BasicSelect from '../../components/select/BasicSelect';
import { Button } from '../../components/common/Button';
import { getCandidatesOfAssessment } from '../../store/slices/assessmentsSlice';

const OtherPositionModal = ({ open, close, recruitment, currentCandidate }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);
  const [newRecruitment, setNewRecruitment] = useState({
    key: ' ',
    i18nKey: ' ',
  });
  const [menu, setMenu] = useState([
    { key: '이동할 공고가 없습니다.', i18nKey: '이동할 공고가 없습니다.' },
  ]);

  useEffect(() => {
    executeAction(getRecruitments({ memberType: userInfo?.memberType }))
      .unwrap()
      .then((res) => {
        const result = [];
        res.docs?.map((item) => {
          if (
            item.isRecruitment &&
            item.rid !== recruitment.rid &&
            !currentCandidate.otherPositionList?.includes(item.rid) &&
            !item.isRecruitmentArchived
          )
            result.push({
              key: item.recruitmentName,
              i18nKey: item.recruitmentName,
              rid: item.rid,
            });
        });
        result.length !== 0 && setMenu(result);
        result.length !== 0 && setNewRecruitment(result[0]);
      });
  }, []);

  const onHandleRecruitment = (_, menu) => {
    setNewRecruitment(menu);
  };

  const onChangeRecruitment = async () => {
    executeAction(
      getCandidatesOfAssessment({
        aid: newRecruitment?.rid,
      })
    ).then((res) => {
      let duplCandidateFound = false;
      res.payload.docs.forEach(item => {
        if (item.id === currentCandidate.email) {
          toast(t('toast.desc.not-move-position'));
          duplCandidateFound = true;
        }
      });

      if(duplCandidateFound) return;

      const ouid = userInfo?.ownerUid;
      const rid = newRecruitment?.rid;

      const progress =
        currentCandidate.recruitProgress === 4
          ? 3
          : currentCandidate.recruitProgress === 1
            ? 1
            : currentCandidate.recruitProgress;

      const interviewHistoryArr = currentCandidate.progressSubmit[
        currentCandidate.recruitProgress
        ].interviewHistory
        ? [
          ...currentCandidate.progressSubmit[currentCandidate.recruitProgress]
            .interviewHistory,
          currentCandidate.progressSubmit[currentCandidate.recruitProgress],
        ]
        : [currentCandidate.progressSubmit[currentCandidate.recruitProgress]];

      const progressSubmit = {
        1: currentCandidate.progressSubmit[1],
        2: { invitedAt: Timestamp.now() },
      };

      if (progress > 2) {
        progressSubmit[progress] = {
          comment: '',
          interviewHistory: interviewHistoryArr,
          interviewSchedule: {},
          interviewer: {},
          isSubmit: false,
          opinion: '',
          scoreList: [],
          updatedAt: Timestamp.now(),
        };
      }

      executeAction(
        addRecruitmentCandidate({
          uid: ouid,
          rid,
          email: currentCandidate.email,
          firstName: currentCandidate.firstName,
          lastName: currentCandidate.lastName,
          lang: 'ko',
          job: recruitment?.recruitmentName,
          movePosition: true,
          recruitProgress: 2,
          phoneNumber: currentCandidate?.progressSubmit[1].phoneNumber,
          otherPositionList: [
            ...currentCandidate.otherPositionList,
            recruitment.rid,
          ],
          progressSubmit,
        })
      )
        .then(() =>
          executeAction(
            updateRecruitmentCandidate({
              aid: recruitment.rid,
              cid: currentCandidate.email,
              recruitProgress: -1,
              firstName: currentCandidate?.firstName,
              lastName: currentCandidate?.lastName,
              job: recruitment?.recruitmentName,
              lang: recruitment?.language,
              phoneNumber: currentCandidate?.progressSubmit[1].phoneNumber,
              movePosition: true,
              finalHiringStep: currentCandidate?.recruitProgress,
              rejectType: 'other position',
            })
          )
            .unwrap()
            .then(() => {
              executeAction(
                setCandidateOfRecruitment({
                  aid: rid,
                  cid: currentCandidate.email,
                  progressSubmit: {
                    1: {
                      KIV: false,
                    },
                  },
                })
              )
                .unwrap()
                .then(() => {
                  toast(
                    `${currentCandidate?.firstName} ${currentCandidate?.lastName}님이 이동되었습니다.`
                  );
                })
                .catch((e) => {
                  console.dir(e);
                });
            })
            .catch((e) => {
              console.dir(e);
            })
        )
        .catch((e) => {
          console.dir(e);
        });
    });
  };

  return (
    <Modal open={open}>
      <div className="modal-change-recruitment">
        <section className="change-recruitment-container">
          <article className="change-recruitment-header-wrapper">
            <h3 className="fs16">{t('title.suggest-other-position')}</h3>
            <span onClick={() => close(false)}>
              <CloseIcon />
            </span>
          </article>
          <BasicSelect
            menus={menu}
            value={newRecruitment}
            label={t('menu.input.select-position')}
            name="otherPosition"
            onChange={onHandleRecruitment}
          />
          <div className="submit-button">
            <div>
              <Button
                isDisabled={menu[0].key === '이동할 공고가 없습니다.'}
                label={t('btn.recruitment-confirm')}
                theme="grey"
                size="48"
                fontSize="fs16"
                onClick={onChangeRecruitment}
              />
            </div>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default OtherPositionModal;
