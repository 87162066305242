import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'assets/images/icon/close.svg';
import { useModal } from 'utils/modal';

const Speaking = (videoUrl) => {
  const { t } = useTranslation();
  const { dismissModal } = useModal();

  const onDismissHandler = () => {
    dismissModal();
  };

  return (
    <article className="modals-speaking">
      <header className="modal-header">
        <span className="title fs16">{t('title.language-speaking-video')}</span>
        <i className="close">
          <CloseIcon onClick={onDismissHandler} />
        </i>
      </header>
      <main className="modal-main">
        <div className="video">
          <iframe
            src={videoUrl && videoUrl?.video}
            width="100%"
            height="288"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
          />
        </div>
      </main>
    </article>
  );
};

export default Speaking;
