import React from 'react';
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { executeAction } from 'utils/redux';
import {
  getCandidate,
  // getCandidateInAssessment,
  gucciApplyInfo,
} from 'store/slices/candidatesSlice';
import { displayName } from 'utils/string';
import {
  getAssessments,
  getAssessmentsOfCurrentUser,
  sendEmailToCandidate,
} from 'store/slices/assessmentsSlice';
import { useTranslation } from 'react-i18next';
import MaterialSelect from 'components/select/MaterialSelect';
import { useStatus } from 'utils/status';
import { useAuth } from 'utils/auth';
import { sortCopy } from 'utils/array';
import { toast } from 'utils/notify';
import Assessment from './detail/Assessment';
import NewAssessment from './detail/NewAssessment';

const Detail = ({}) => {
  const { t } = useTranslation();
  const { cid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const aid = searchParams.get('aid');
  const entry = searchParams.get('entry');
  const [otherAssessments, setOtherAssessments] = React.useState([]);
  const [otherAssessment, setOtherAssessment] = React.useState(null);
  const { setIsEntireLoading } = useStatus();
  const [candidate, setCandidate] = React.useState({});
  const { userInfo } = useAuth();
  const [StoreInterviewAid, setStoreInterviewAid] = React.useState('');
  const [candidateInfo, setCandidateInfo] = React.useState(
    location?.state?.candidateInfo !== null
      ? location?.state?.candidateInfo
      : ''
  );
  const [SIcandidate, setStoreIntervewcandidate] = React.useState({});
  const [gucciApplyStore, setGucciApplyStore] = React.useState({});

  React.useEffect(() => {
    executeAction(getAssessmentsOfCurrentUser())
      .unwrap()
      .then(({ docs: _docs }) => {
        const docs = (_docs || []).map((x) => ({
          ...x,
          display: {
            ko: x?.name,
            en: x?.name,
          },
        }));
        setOtherAssessments(docs || []);
      })
      .catch((e) => {
        console.dir(e);
        setOtherAssessments([]);
      });
  }, []);

  React.useEffect(() => {
    if (!userInfo) return;

    setIsEntireLoading(true);
    executeAction(
      getCandidate({
        cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setCandidate(doc);

        executeAction(
          getAssessments({
            aids: Object.keys(doc?.assessments || {}),
          })
        )
          .unwrap()
          .then(({ docs: assessments }) => {
            (assessments || []).forEach((it) => {
              doc.assessments[it.id] = it;
            });
            setIsEntireLoading(false);
          })
          .catch((e) => {
            console.dir(e);
            setIsEntireLoading(false);
          });
      })
      .catch((e) => {
        console.dir(e);
        setCandidate(null);
        setIsEntireLoading(false);
      });
  }, [cid, userInfo]);

  React.useEffect(() => {
    var hasStoreInterview = false;
    var _StoreInterviewAid = '';
    Object.values(candidate?.assessments || {}).forEach((x) => {
      (x?.tests || []).forEach((test) => {
        if (test.id === '991111') {
          hasStoreInterview = true;
          _StoreInterviewAid = x?.id;
          return;
        }
      });
      if (hasStoreInterview) return;
    });

    setStoreInterviewAid(_StoreInterviewAid);
  }, [candidate]);

  React.useEffect(() => {
    executeAction(
      gucciApplyInfo({
        cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setGucciApplyStore(doc);
      })
      .catch((e) => {
        console.dir(e);
      });
  }, [cid]);

  React.useEffect(() => {
    if (StoreInterviewAid === '') return;

    setIsEntireLoading(true);
    executeAction(
      getCandidateInAssessment({
        aid: StoreInterviewAid,
        cid,
      })
    )
      .unwrap()
      .then(({ doc }) => {
        setStoreIntervewcandidate(doc);
        setIsEntireLoading(false);
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });
  }, [StoreInterviewAid]);

  const inviteOtherAssessmentHandler = () => {
    setIsEntireLoading(true);
    executeAction(
      sendEmailToCandidate({
        type: 'resend',
        aid: otherAssessment?.id || '',
        firstName: candidate?.firstName || '',
        lastName: candidate?.lastName || '',
        email: candidate?.email || '',
        lang: otherAssessment?.language,
      })
    )
      .unwrap()
      .then(() => {
        setIsEntireLoading(false);
        toast(t('toast.desc.success-invite'));
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });
  };

  const onGoBackHandler = () => {
    navigate(-1);
  };

  return (
    <main className="pages-protected-candidates-detail">
      <header className="header">
        <section className="header-left">
          <button
            className="header-left-go-back common-button fsbtn16 white active"
            onClick={onGoBackHandler}
          />
          <article className="header-left-title">
            <h2 className="header-left-title-name fs28">
              {candidateInfo !== undefined
                ? displayName(
                    candidateInfo?.personal?.first_name,
                    candidateInfo?.personal?.last_name,
                    t('lang')
                  )
                : displayName(
                    candidate?.firstName,
                    candidate?.lastName,
                    t('lang')
                  )}
            </h2>
            <div className="header-left-title-email">
              {candidateInfo !== undefined
                ? candidateInfo?.personal?.phone?.number === undefined
                  ? candidateInfo?.personal?.email_address[0].toLowerCase()
                  : candidateInfo?.personal?.phone?.country_code ===
                      undefined ||
                    candidateInfo?.personal?.phone?.country_code === ''
                  ? `${candidateInfo?.personal?.email_address[0]} | ${candidateInfo?.personal?.phone?.number}`
                  : `${candidateInfo?.personal?.email_address[0]} | ${candidateInfo?.personal?.phone?.country_code}  ${candidateInfo?.personal?.phone?.number}`
                : (candidate && candidate?.phoneNumber === '') ||
                  candidate?.phoneNumber === undefined
                ? candidate?.email
                : candidate?.countryCode === '' ||
                  candidate?.countryCode === undefined
                ? `${candidate?.email} | ${candidate?.phoneNumber}`
                : `${candidate?.email} | ${candidate?.countryCode}  ${candidate?.phoneNumber}`}
            </div>
          </article>
        </section>
        <section className="header-right" style={{ display: 'none' }}>
          <MaterialSelect
            className="header-right-select"
            title={t('label.invite-for-an-assessment')}
            value={otherAssessment}
            onChange={(item) => setOtherAssessment(item)}
            items={otherAssessments}
          />
          <button
            className="header-right-invite common-button fsbtn16 primary active"
            onClick={inviteOtherAssessmentHandler}
          >
            {t('btn.invite')}
          </button>
        </section>
      </header>
      {userInfo?.plan >= 100 &&
        userInfo?.email !== 'design@promenade-ai.com' && (
          <section className="gucci resume">
            <div className="title fs20">{t('survey.application')}</div>
            {Object.keys(gucciApplyStore)?.length !== 0 ? (
              <>
                <div className="row">
                  <div className="cell">
                    <div className="title fs14">{t('survey.source')}</div>
                    <div className="content fs16">
                      {gucciApplyStore?.['참여 방법']}
                    </div>
                  </div>
                  <div className="cell">
                    <div className="title fs14">{t('survey.geography')}</div>
                    <div className="content fs16">
                      {gucciApplyStore?.['국가/지역']}
                    </div>
                  </div>
                  <div className="cell">
                    <div className="title fs14">{t('survey.country-code')}</div>
                    <div className="content fs16">
                      {gucciApplyStore?.['국가번호']}
                    </div>
                  </div>
                  <div className="cell">
                    <div className="title fs14">
                      {t('survey.mobile-number')}
                    </div>
                    <div className="content fs16">
                      {gucciApplyStore?.['휴대전화번호']}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cell">
                    <div className="title fs14">{t('survey.resume')}</div>
                    <div className="content">
                      {gucciApplyStore?.이력서 === '' ||
                      gucciApplyStore?.이력서 === undefined ? (
                        <span className="fs16">{t('desc.no-attachment')}</span>
                      ) : (
                        <a
                          href={gucciApplyStore?.이력서}
                          download
                          className="fsbtn16"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('btn.cv-download-pdf')}
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="cell">
                    <div className="title fs14">{t('survey.preferred')}</div>
                    <div className="content fs16">
                      {gucciApplyStore?.['희망 포지션']}
                    </div>
                  </div>
                  <div className="cell">
                    <div className="title fs14">{t('survey.channel')}</div>
                    <div className="content fs16">
                      {gucciApplyStore?.['유입 경로']}
                    </div>
                  </div>
                  <div className="cell">
                    <div className="title fs14">
                      {t('survey.name-of-university')}
                    </div>
                    <div className="content fs16">
                      {gucciApplyStore?.['산학 협력 소속 대학교명']}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              t('desc.no-cv')
            )}
          </section>
        )}

      {/**
       * candidateInfo: GucciList에서 전달받은 응시자 정보(assessment_id가 null일 때만 전달해준다.)
       * candidateInfo.assessment_id: 평가한 정보가 있으면 string, 없으면 null
       * if(candidateInfo === true) {
       *     평가 정보 없음 문구
       * } else {
       *     기존 로직 실행
       * }
       */}
      {candidateInfo ? (
        <section className="no-information-alert">
          <h3 className="fs18">{t('desc.no-assessment')}</h3>
        </section>
      ) : aid ? (
        <NewAssessment
          idx={0}
          cid={cid}
          aid={aid}
          // interviewAid={StoreInterviewAid}
        />
      ) : (
        sortCopy(
          Object.entries(candidate?.assessments || {}),
          (a, b) => b?.[1]?.createdAt - a?.[1]?.createdAt
        ).map(([key, value], idx) => (
          <NewAssessment idx={idx} cid={cid} aid={key} key={idx} />
        ))
      )}
    </main>
  );
};

export default Detail;
