import React from 'react';
import { convertHtmlForDisplay } from 'utils/string';
import { useTranslation } from 'react-i18next';
import { Radio, Checkbox } from '@mui/material';
import SourceCode from 'pages/protected/assessments/create/SourceCode';

const MultipleQuestion = ({ questions }) => {
  const { t } = useTranslation();

  return (
    <article className="question-contents-list">
      {questions &&
        questions?.map(
          (question, index) =>
            question?.selected === true && (
              <aside className="question-contents-list-items" key={index}>
                <div className="question-contents-list-title">
                  <h6 className="fs24">{`Q${index + 1}`}</h6>
                  <pre
                    className="fs16"
                    dangerouslySetInnerHTML={{
                      __html: convertHtmlForDisplay(
                        question?.testInfo?.[`question_${t('lang')}`]?.indexOf(
                          '```'
                        ) >= 0
                          ? question?.testInfo?.[
                              `question_${t('lang')}`
                            ]?.substring(
                              0,
                              question?.testInfo?.[
                                `question_${t('lang')}`
                              ]?.indexOf('```')
                            )
                          : question?.testInfo?.[`question_${t('lang')}`]
                      ),
                    }}
                  />
                  <SourceCode
                    language={
                      question?.testInfo &&
                      question?.testInfo?.[`category_${t('lang')}`]
                    }
                    code={
                      question?.testInfo?.[`question_${t('lang')}`]?.indexOf(
                        '```'
                      ) >= 0
                        ? question?.testInfo?.[
                            `question_${t('lang')}`
                          ]?.substring(
                            question?.testInfo?.[
                              `question_${t('lang')}`
                            ]?.indexOf('```')
                          )
                        : ''
                    }
                    isSub={false}
                  />
                </div>
                <div className="question-contents-list-choice">
                  <ul>
                    {question?.testInfo?.[`answer_${t('lang')}`]
                      ?.filter((x) => {
                        if (typeof x === 'boolean') {
                          return String(x);
                        }
                        return Boolean(x);
                      })
                      .map((answer, idx) => (
                        <li className="fs16" key={idx}>
                          <div className="container">
                            {/* checkbox */}
                            {question?.testInfo?.correct.length > 1 ? (
                              <Checkbox
                                className="radio-button"
                                checked={false}
                                disabled
                                name="radio-buttons"
                                inputProps={{ 'aria-label': answer }}
                              />
                            ) : (
                              <Radio
                                className="radio-button"
                                checked={false}
                                disabled
                                name="radio-buttons"
                                inputProps={{ 'aria-label': answer }}
                              />
                            )}

                            {/* answer */}
                            {answer.indexOf('```') === 0 ? (
                              <SourceCode
                                language={
                                  question?.testInfo &&
                                  question?.testInfo?.[`category_${t('lang')}`]
                                }
                                code={answer}
                                isSub
                              />
                            ) : (
                              <p className="fs16">
                                {typeof answer === 'boolean'
                                  ? JSON.stringify(answer)
                                  : answer}
                              </p>
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </aside>
            )
        )}
    </article>
  );
};

export default MultipleQuestion;
