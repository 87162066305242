import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import { useSelector } from 'react-redux';
import MaterialSelect from 'components/select/MaterialSelect';

const OBPI_TESTS = [
  {
    category: 'fellowship',
    title: 'title.fellowship',
    left: 'desc.fellowship-left',
    right: 'desc.fellowship-right',
  },
  {
    category: 'authority',
    title: 'title.authority',
    left: 'desc.authority-left',
    right: 'desc.authority-right',
  },
  {
    category: 'conformity',
    title: 'title.conformity',
    left: 'desc.conformity-left',
    right: 'desc.conformity-right',
  },
  {
    category: 'emotion',
    title: 'title.emotion',
    left: 'desc.emotion-left',
    right: 'desc.emotion-right',
  },
  {
    category: 'detail',
    title: 'title.detail',
    left: 'desc.detail-left',
    right: 'desc.detail-right',
  },
  {
    category: 'proficiency',
    title: 'title.proficiency',
    left: 'desc.proficiency-left',
    right: 'desc.proficiency-right',
  },
  {
    category: 'work-orientation',
    title: 'title.work-orientation',
    left: 'desc.work-orientation-left',
    right: 'desc.work-orientation-right',
  },
  {
    category: 'patience',
    title: 'title.patience',
    left: 'desc.patience-left',
    right: 'desc.patience-right',
  },
  {
    category: 'fair-mindedness',
    title: 'title.fair-mindedness',
    left: 'desc.fair-mindedness-left',
    right: 'desc.fair-mindedness-right',
  },
  {
    category: 'loyalty',
    title: 'title.loyalty',
    left: 'desc.loyalty-left',
    right: 'desc.loyalty-right',
  },
  {
    category: 'disclosure',
    title: 'title.disclosure',
    left: 'desc.disclosure-left',
    right: 'desc.disclosure-right',
  },
  {
    category: 'initiative',
    title: 'title.initiative',
    left: 'desc.initiative-left',
    right: 'desc.initiative-right',
  },
];

const __OBPI_RESULT_NUM__ = 9;
const __OBPI_LIST_NUM__ = 12;

const SetOBPIFit = ({
  candidates,
  setOBPIBenchmark,
  OBPI_TESTS,
  __OBPI_LIST_NUM__,
  __OBPI_RESULT_NUM__,
  selected,
}) => {
  const { t, i18n } = useTranslation();
  const curLang = useSelector((state) => state.variable?.lang.slice(0, 2));
  const { dismissModal } = useModal();
  const [obpiStanine, setOBPIStanine] = React.useState([]);
  const [__candidates, __setCandidates] = React.useState([]);
  const [canSave, setCanSave] = React.useState(false);
  const [selectedCandidate, setSelectedCandidate] = React.useState(false);

  React.useEffect(() => {
    const _candidates = new Array();
    candidates.map((candidate) => {
      if (!candidate?.obpiResult?.traits) return;
      let tmp = {
        key: candidate.id,
        display: {
          en: `${candidate.firstName} ${candidate.lastName}`,
          ko: `${candidate.lastName} ${candidate.firstName}`,
        },
      };
      if (selected && candidate.id === selected) {
        setStanineCandidate(tmp);
      }
      _candidates.push(tmp);
    });

    __setCandidates(_candidates);

    const Selected = new Array(__OBPI_LIST_NUM__);
    for (let i = 0; i < Selected.length; i += 1) {
      Selected[i] = new Array(__OBPI_RESULT_NUM__).fill(false);
    }
    if (!selected) {
      setOBPIStanine(Selected);
    }
  }, []);

  const onSaveHandler = () => {
    setOBPIBenchmark({
      id: selectedCandidate.id,
      obpiResult: selectedCandidate.obpiResult,
    });
    dismissModal();
  };

  const onCloseBtnClose = () => {
    dismissModal();
  };

  const setStanineCandidate = (candidate) => {
    if (!candidate) return;
    const selectedCandidate = candidates.find((x) => x.id === candidate?.key);

    const Selected = new Array(__OBPI_LIST_NUM__);
    for (let i = 0; i < Selected.length; i += 1) {
      Selected[i] = new Array(__OBPI_RESULT_NUM__).fill(false);
    }

    let i = 0;
    for (const obpis of OBPI_TESTS) {
      const score = selectedCandidate?.obpiResult?.traits?.find(
        (x) => x.scale === obpis.category
      );
      Selected[i][score?.stanine - 1] = true;
      i += 1;
    }

    setOBPIStanine(Selected);
    setCanSave(true);
    setSelectedCandidate(selectedCandidate);
  };

  return (
    <article className="modals-assessments-obpi-set">
      <section className="obpi-set-modal-container">
        <header className="obpi-set-modal-header">
          <div className="obpi-set-modal-header-title">
            {t('title.obpi-benchmark-setting')}
          </div>
          <button
            className="obpi-set-modal-header-close"
            onClick={onCloseBtnClose}
          />
        </header>
        <div className="obpi-set-modal-scroll">
          <div className="obpi-set-modal-content">
            <div className="obpi-set-modal-content-discription fs16">
              {t('desc.obpi-benchmark-setting')}
            </div>
            <div className="obpi-set-modal-content-select-candidate">
              <section className="select-form">
                <MaterialSelect
                  title={t('text-field.label.select-candidate-for-benchmark')}
                  onChange={(item) => {
                    setStanineCandidate(item);
                  }}
                  value={selected}
                  items={__candidates}
                />
              </section>
              <div className="obpi-set-modal-content-select-candidate-discription fs12">
                {t('desc.select-candidate-for-benchmark')}
              </div>
            </div>
            {(OBPI_TESTS || []).map((obpi, idx) => (
              <div className="obpi-detail-result-section" key={idx}>
                {/* <button className="minus"></button> */}
                <div className="obpi-detail-result-list">
                  <div className="obpi-detail-result-title fs14">
                    {t(obpi.title)}
                  </div>
                  <div className="obpi-detail-results">
                    <ul className="obpi-detail-result-answer">
                      {(obpiStanine?.[idx] || []).map((_selected, sidx) => (
                        <li
                          key={sidx}
                          className={`obpi-detail-result-answer-item ${
                            _selected && 'selected'
                          }`}
                        >
                          {_selected}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="opbi-detail-result-discription-box">
                    <div className="discription fs12 left">{t(obpi.left)}</div>
                    <div className="discription fs12 right">
                      {t(obpi.right)}
                    </div>
                  </div>
                </div>
                {/* <button className="plus"></button> */}
              </div>
            ))}
            <button
              className={`save ${canSave === false && ' disabled'} active`}
              onClick={canSave ? onSaveHandler : null}
            >
              {t('btn.save')}
            </button>
          </div>
        </div>
      </section>
    </article>
  );
};

export default SetOBPIFit;
