import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/modal';
import { getTestInfos } from 'store/slices/testsSlice';
import { getLibTestInfos } from 'store/slices/assessmentsSlice';
import { ReactComponent as ErrorIcon } from 'assets/images/icon/error_24px.svg';
import { ReactComponent as ReloadIcon } from 'assets/images/icon/reload.svg';
import PropTypes from 'prop-types';
import { executeAction } from 'utils/redux';
import MaterialInput from 'components/input/MaterialInput';
import { Radio, Checkbox, Switch } from '@mui/material';
import { convertHtmlForDisplay, convertHtmlToText } from 'utils/string';
import { toast } from 'utils/notify';
import SourceCode from './SourceCode';

const __MAX_SIZE_OF_TESTS__ = 5;
const __MAX_QUESTION__ = 30;
const __DEFAULT_QUESTION__ = 30;
const __MAX_BASIC_QUESTION__ = 12;
const __MAX_INTERMEDIATE_QUESTION__ = 9;
const __MAX_ADVANCED_QUESTION__ = 9;
const __MAX_REBUILD_REMAINED__ = 2;

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const numberQuestions = [
  {
    key: '30',
    display: '30',
  },
  {
    key: '25',
    display: '25',
  },
  {
    key: '20',
    display: '20',
  },
  {
    key: '12',
    display: '12',
  },
  {
    key: '10',
    display: '10',
  },
];

const getQuestions = (basics, intermediates, advanceds) => {
  let basicCnt = basics.length;
  let intermediateCnt = intermediates.length;
  let advancedCnt = advanceds.length;
  let ba = new Array();
  let it = new Array();
  let ad = new Array();
  let ret = new Array();

  if (basicCnt + intermediateCnt + advancedCnt <= __MAX_QUESTION__) {
    ret = shuffle([...basics, ...intermediates, ...advanceds]);
  } else if (
    basicCnt >= __MAX_BASIC_QUESTION__ &&
    intermediateCnt >= __MAX_INTERMEDIATE_QUESTION__ &&
    advancedCnt >= __MAX_ADVANCED_QUESTION__
  ) {
    ba = basics.slice(0, __MAX_BASIC_QUESTION__);
    it = intermediates.slice(0, __MAX_INTERMEDIATE_QUESTION__);
    ad = advanceds.slice(0, __MAX_ADVANCED_QUESTION__);
    ret = shuffle([...ba, ...it, ...ad]);
  } else if (
    intermediateCnt + advancedCnt <
    __MAX_QUESTION__ - __MAX_BASIC_QUESTION__
  ) {
    ba = basics.slice(0, __MAX_QUESTION__ - (intermediateCnt + advancedCnt));
    ret = shuffle([...ba, ...intermediates, ...advanceds]);
  } else {
    ba = basics.slice(0, __MAX_QUESTION__ / 2);
    let temp = shuffle([...intermediates, ...advanceds]).slice(
      0,
      __MAX_QUESTION__ / 2
    );
    ret = shuffle([...ba, ...temp]);
  }
  return ret;
};

const Step2 = ({
  curStep,
  objectiveTests,
  setObjectiveTests,
  setDuration,
  language,
  codeLanguage,
}) => {
  const { t } = useTranslation();
  const javascriptDefault = `/* ${t('code.editor-placeholder')} */`;
  const { displayModal, dismissModal } = useModal();
  const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [selectedQuestion, setSelectedQuestion] = React.useState({});
  const [rebuildQuestions, setRebuildQuestions] = React.useState([]);
  const [canRebuild, setCanRebuild] = React.useState(false);
  const [limitTime, setLimitTime] = React.useState(8);
  const [isEntireLoading, setIsEntireLoading] = React.useState(true);
  const [checkedState, setCheckedState] = React.useState([]);
  const [testTitle, setTestTitle] = React.useState('');
  const [code, setCode] = React.useState('');
  // const [maxQuestion, setMaxQuestion] = React.useState(30);
  const [questionCnt, setQuestionCnt] = React.useState(0);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [canModify, setCanModify] = React.useState(false);
  const [codeWrite, setCodeWrite] = React.useState(javascriptDefault);
  const isCode =
    objectiveTests[selectedCategory]?.question_type?.indexOf('tag.code') >= 0;

  React.useEffect(() => {
    if (isEntireLoading) {
      displayModal(
        'common/Loading',
        {},
        {
          frame: 'part',
          hasCloseButton: false,
        }
      );
    } else {
      dismissModal();
    }
  }, [isEntireLoading]);

  React.useEffect(() => {
    setIsEntireLoading(true);
    if (objectiveTests.length > 0) {
      let isOk = true;

      for (let i = 0; i < objectiveTests.length && isOk !== false; i += 1) {
        isOk =
          isOk &&
          (objectiveTests[i]?.question !== undefined ||
            objectiveTests[i]?.questionNum !== undefined);
        if (!isOk) {
          break;
        }
      }
      if (isOk) {
        const selected = new Array();
        if (
          objectiveTests[0]?.question_type.indexOf('tag.subjective') >= 0 ||
          objectiveTests[0]?.question_type.indexOf('tag.code') >= 0 ||
          objectiveTests[0]?.question_type.indexOf('tag.video') >= 0
        ) {
          const initCheckted = new Array();
          const selected = new Array();
          objectiveTests[0].question.forEach((question, idx) => {
            initCheckted.push(
              question.selected === undefined ? true : question.selected
            );
            if (question.selected === true) {
              selected.push(idx);
            }
          });
          setCheckedState(initCheckted);
          setRebuildQuestions(selected);
          objectiveTests[0].questionNum = selected.length;
        } else {
          const initCheckted = new Array();
          objectiveTests[0].question.forEach((question, idx) => {
            initCheckted.push(
              question.selected === undefined ? false : question.selected
            );
            if (question.selected === true) {
              selected.push(idx);
            }
          });
          setCheckedState(initCheckted);
          setRebuildQuestions(selected);
          objectiveTests[0].questionNum = selected.length;
        }

        setLimitTime(objectiveTests[0].limit);
        setIsEntireLoading(false);
        initSelectedQuestion(0);
        setQuestionCnt(objectiveTests[0].questionNum);
        setCanModify(false);
        return;
      }
    } else {
      setIsEntireLoading(false);
      return;
    }
    const testlist = objectiveTests;
    const tasks = new Array();
    Object.values(objectiveTests || {}).forEach((test, idx) => {
      if (
        test?.question_type?.indexOf('tag.subjective') >= 0 ||
        test?.question_type?.indexOf('tag.code') >= 0 ||
        test?.question_type.indexOf('tag.video') >= 0
      ) {
        if (test?.question === undefined || test?.question?.length === 0) {
          tasks.push(
            executeAction(
              getLibTestInfos({
                tids: test?.test || [],
                lang: language,
              })
            )
              .unwrap()
              .then((docs) => {
                testlist[idx].codeLanguage === undefined;
                testlist[idx].question = docs;
                testlist[idx].question.forEach((test) => {
                  if (test.selected === undefined) {
                    test.selected = true;
                  }
                });
                testlist[idx].questionNum = docs.length;

                const initCheckted = new Array();
                const numberArr = new Array();
                const selectedVal = new Array();

                testlist[idx].question.forEach((question, _idx) => {
                  initCheckted.push(
                    question.selected === undefined ? true : question.selected
                  );
                  numberArr.push(_idx);
                });

                const updatedCheckedState = initCheckted.map((item, index) =>
                  index === numberArr ? !item : item
                );

                updatedCheckedState.forEach((item, index) => {
                  if (item) {
                    selectedVal.push(index);
                  }
                  testlist[idx].question[index].selected = item;
                });

                if (
                  testlist[idx]?.question_type.indexOf('tag.subjective') >= 0 ||
                  testlist[idx]?.question_type.indexOf('tag.code') >= 0 ||
                  testlist[idx]?.question_type.indexOf('tag.video') >= 0
                ) {
                  selectedVal.forEach((val) => {
                    testlist[idx].question[val].selected = true;
                  });
                  testlist[idx].limit =
                    testlist[idx].limit * selectedVal.length;
                  setLimitTime(testlist[idx].limit);
                }

                let duration = 0;

                Object.values(objectiveTests || {}).forEach((test) => {
                  duration += test.limit;
                });

                setDuration(duration);
                setCheckedState(updatedCheckedState);
              })
              .catch((e) => {
                console.dir(e);
              })
          );
        }
      } else {
        tasks.push(
          executeAction(
            getTestInfos({
              tids: test?.test || [],
              lang: language,
            })
          )
            .unwrap()
            .then(({ basics, intermediates, advanceds }) => {
              let question;
              let defaultQuestion;

              if (
                (objectiveTests[idx]?.id === '1310' && t('lang') === 'ko') ||
                (objectiveTests[idx]?.id === '1321' && t('lang') === 'ko') ||
                (objectiveTests[idx]?.id === '1322' && t('lang') === 'ko') ||
                objectiveTests[idx]?.id === '6010'
              ) {
                basics?.map((test, idx) => {
                  test.selected = true;
                });
                question = basics;
                defaultQuestion = 30;
              } else {
                question = getQuestions(basics, intermediates, advanceds);
                defaultQuestion = 0;
                (question || []).forEach((test, idx) => {
                  test.selected = false;
                });
              }

              testlist[idx].question = question;
              testlist[idx].questionNum = defaultQuestion;
              testlist[idx].remained = __MAX_REBUILD_REMAINED__;
            })
            .catch((e) => {
              console.dir(e);
            })
        );
      }
    });

    Promise.allSettled(tasks)
      .then(() => {
        setObjectiveTests(testlist);
        setLimitTime(testlist[0]?.limit);
        setSelectedQuestion({ id: 0, test: testlist[0].question[0] });
        const title =
          testlist[0]?.question[0]?.testInfo?.[`question_${language}`] ||
          testlist[0]?.category_skill;
        const codeTxtIdx = title.indexOf('```');
        if (codeTxtIdx >= 0) {
          setTestTitle(title.substring(0, codeTxtIdx));
          setCode(title.substring(codeTxtIdx));
        } else {
          setTestTitle(title);
          setCode('');
        }

        if (
          testlist[0]?.question_type.indexOf('tag.subjective') >= 0 ||
          testlist[0]?.question_type.indexOf('tag.code') >= 0 ||
          testlist[0]?.question_type.indexOf('tag.video') >= 0
        ) {
          const initCheckted = new Array();
          const selected = [];
          testlist[0].question.forEach((question, idx) => {
            initCheckted.push(
              question.selected === undefined ? true : question.selected
            );
            if (question.selected === true) {
              selected.push(idx);
            }
          });

          setRebuildQuestions(selected);
          setCheckedState(initCheckted);
          setTestTitle(testlist[0].question[0].question);
        } else {
          const initCheckted = new Array();
          const selected = [];
          testlist[0].question.forEach((question, idx) => {
            initCheckted.push(
              question.selected === undefined ? false : question.selected
            );
            if (question.selected === true) {
              selected.push(idx);
            }
          });
          setRebuildQuestions(selected);
          setCheckedState(initCheckted);
        }
        setIsEntireLoading(false);
      })
      .catch((e) => {
        console.dir(e);
        setObjectiveTests([]);
      });
  }, [objectiveTests]);

  const rowRefs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];
  const qRefs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];

  const minutes = [t('desc.minute')];

  const setTime = (idx, time) => {
    let limitTime = 0;
    if (time === '') {
      limitTime = 0;
    } else if (parseInt(time)) {
      limitTime = parseInt(time);
    } else {
      limitTime = 0;
    }
    objectiveTests[idx].limit = limitTime;

    let duration = 0;

    Object.values(objectiveTests || {}).forEach((test) => {
      duration += test.limit;
    });

    setDuration(duration);

    setLimitTime(time);
    setObjectiveTests(objectiveTests);

    return;
  };

  const onSelectChangeStep2 = (sl, idx) => {
    const temp = objectiveTests;
    temp[idx].codeLanguage = sl;
    objectiveTests[idx].codeLanguage = sl;

    setObjectiveTests(objectiveTests);
  };

  const onSelectSkillClickHandler = (idx) => {
    const selected = [];
    if (
      objectiveTests[idx]?.question_type.indexOf('tag.subjective') >= 0 ||
      objectiveTests[idx]?.question_type.indexOf('tag.video') >= 0 ||
      objectiveTests[idx]?.question_type.indexOf('tag.code') >= 0
    ) {
      const initCheckted = new Array();
      objectiveTests[idx].question.forEach((question, _idx) => {
        initCheckted.push(
          question.selected === undefined ? true : question.selected
        );
        if (question.selected === true) {
          selected.push(_idx);
        }
      });
      setCheckedState(initCheckted);
    } else {
      const initCheckted = new Array();
      objectiveTests[idx].question.forEach((question, idx) => {
        initCheckted.push(
          question.selected === undefined ? false : question.selected
        );
        if (question.selected === true) {
          selected.push(idx);
        }
      });
      setCheckedState(initCheckted);
    }

    setRebuildQuestions(selected);

    setLimitTime(objectiveTests[idx].limit);
    setSelectedCategory(idx);
    initSelectedQuestion(idx);
    setQuestionCnt(objectiveTests[idx].questionNum);
    setCanModify(false);

    if (selected.length === 30) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }

    if (selected.length > 0 && objectiveTests[idx].remained > 0) {
      setCanRebuild(true);
    } else {
      setCanRebuild(false);
    }

    for (const rowRef of rowRefs) {
      if (rowRef.current !== null) {
        rowRef.current.classList.remove('selected');
      }
    }
    rowRefs[idx].current.classList.add('selected');

    return;
  };

  const initSelectedQuestion = (idx) => {
    if (
      objectiveTests[idx]?.question_type.indexOf('tag.subjective') >= 0 ||
      objectiveTests[idx]?.question_type.indexOf('tag.code') >= 0 ||
      objectiveTests[idx]?.question_type.indexOf('tag.video') >= 0
    ) {
      const initCheckted = new Array();
      objectiveTests[idx].question.forEach((question) => {
        initCheckted.push(
          question.selected === undefined ? true : question.selected
        );
      });
      setCheckedState(initCheckted);
    } else {
      const initCheckted = new Array();
      const selected = new Array();

      objectiveTests[idx].question.forEach((question, idx) => {
        initCheckted.push(
          question.selected === undefined ? false : question.selected
        );

        if (question.selected === true) {
          selected.push(idx);
        }
      });
      setCheckedState(initCheckted);

      if (selected.length > 0) {
        setCanRebuild(true);

        if (selected.length === 30) {
          setIsAllSelected(true);
        } else {
          setIsAllSelected(false);
        }
      } else {
        setCanRebuild(false);
      }
    }

    const title =
      objectiveTests[idx].question[0]?.testInfo?.[`question_${language}`] ||
      objectiveTests[idx].question[0]?.question;
    const codeTxtIdx = title?.indexOf('```');
    if (codeTxtIdx >= 0) {
      setTestTitle(title.substring(0, codeTxtIdx));
      setCode(title.substring(codeTxtIdx));
    } else {
      setTestTitle(title);
      setCode('');
    }

    setSelectedQuestion({ id: 0, test: objectiveTests[idx].question[0] });

    for (const qRef of qRefs) {
      if (qRef.current !== null) {
        qRef.current.classList.remove('selected');
      }
    }
    if (qRefs[0].current !== null) {
      qRefs[0].current.classList.add('selected');
    }
    if (document.getElementsByClassName('question-box')[0]) {
      document.getElementsByClassName('question-box')[0].scrollTop = 0;
    }

    return;
  };

  const onSelectQuestionClickHandler = (idx) => {
    const title =
      objectiveTests[selectedCategory].question[idx].testInfo?.[
        `question_${language}`
      ] || objectiveTests[selectedCategory].question[idx].question;
    const codeTxtIdx = title.indexOf('```');
    if (codeTxtIdx >= 0) {
      setTestTitle(title.substring(0, codeTxtIdx));
      setCode(title.substring(codeTxtIdx));
    } else {
      setTestTitle(title);
      setCode('');
    }

    setSelectedQuestion({
      id: idx,
      test: objectiveTests[selectedCategory].question[idx],
    });

    for (const qRef of qRefs) {
      if (qRef.current !== null) {
        qRef.current.classList.remove('selected');
      }
    }
    qRefs[idx].current.classList.add('selected');
    return;
  };

  const handleOnChange = (position, event) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    const selected = [];
    updatedCheckedState.forEach((item, index) => {
      if (item) {
        selected.push(index);
      }
      objectiveTests[selectedCategory].question[index].selected = item;
    });

    if (
      objectiveTests[selectedCategory]?.question_type.indexOf(
        'tag.subjective'
      ) >= 0 ||
      objectiveTests[selectedCategory]?.question_type.indexOf('tag.code') >=
        0 ||
      objectiveTests[selectedCategory]?.question_type.indexOf('tag.video') >= 0
    ) {
      selected.forEach((val) => {
        objectiveTests[selectedCategory].question[val].selected = true;
      });

      const _selected = new Array();
      updatedCheckedState.forEach((_item, _index) => {
        if (_item) {
          _selected.push(_index);
        }
      });

      objectiveTests[selectedCategory].limit =
        objectiveTests[selectedCategory].duration * _selected.length;

      let duration = 0;
      Object.values(objectiveTests || {}).forEach((test) => {
        duration += test.limit;
      });

      setDuration(duration);
      setLimitTime(objectiveTests[selectedCategory].limit);
    }
    event.stopPropagation();
    setCheckedState(updatedCheckedState);
    setRebuildQuestions(selected);
    objectiveTests[selectedCategory].questionNum = selected.length;
    setQuestionCnt(objectiveTests[selectedCategory].questionNum);

    if (selected.length === 30) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }

    if (selected.length > 0 && objectiveTests[selectedCategory].remained > 0) {
      setCanRebuild(true);
    } else {
      setCanRebuild(false);
    }
  };

  const onChageQuestionCntHandler = (e) => {
    setQuestionCnt(parseInt(e.target.value));
    setCanModify(true);
  };

  const onClickSetQuestionCntHandler = () => {
    const tmpvar = objectiveTests;
    tmpvar[selectedCategory].questionNum = questionCnt;
    setObjectiveTests(tmpvar);
    initSelectedQuestion(selectedCategory);
  };

  const onClickSelectedAll = () => {
    const initCheckted = new Array();
    const selected = [];

    objectiveTests[selectedCategory].question.forEach((question, idx) => {
      question.selected = true;

      initCheckted.push(
        question.selected === undefined ? true : question.selected
      );
      if (question.selected === true) {
        selected.push(idx);
      }
    });

    objectiveTests[selectedCategory].questionNum = selected.length;

    setRebuildQuestions(selected);
    setCheckedState(initCheckted);
    setCanRebuild(true);
    setIsAllSelected(true);
  };

  const onClickSelectedAllCancel = () => {
    const initCheckted = new Array();
    const selected = [];

    objectiveTests[selectedCategory].question.forEach((question, idx) => {
      question.selected = false;

      initCheckted.push(
        question.selected === undefined ? true : question.selected
      );
      if (question.selected === true) {
        selected.push(idx);
      }
    });

    objectiveTests[selectedCategory].questionNum = selected.length;

    setRebuildQuestions(selected);
    setCheckedState(initCheckted);
    setCanRebuild(false);
    setIsAllSelected(false);
  };

  // 선택한 문제의 재생성에서 선택한 문제를 테스트 할 수 있도록 기능을 변경한다.
  //
  const onClickRebuildHandler = () => {
    var request = new Array();
    rebuildQuestions.forEach((re) => {
      request.push(objectiveTests[selectedCategory]?.question[re]?.tid);
    });

    setIsEntireLoading(true);
    executeAction(
      getTestInfos({
        tids: objectiveTests[selectedCategory]?.test || [],
        lang: language,
      })
    )
      .unwrap()
      .then(({ basics, intermediates, advanceds }) => {
        rebuildQuestions.forEach((rebuild) => {
          const tmemp_question =
            objectiveTests[selectedCategory]?.question[rebuild];

          const level = tmemp_question.testInfo?.[`level_${language}`];
          var testSet = new Array();
          if (level === '초급' || level === 'Basic') {
            testSet = [...basics, ...intermediates, ...advanceds];
          } else if (level === '중급' || level === 'Intermediate') {
            testSet = [...intermediates, ...basics, ...advanceds];
          } else if (level === '고급' || level === 'Advanced') {
            testSet = [...advanceds, ...intermediates, ...basics];
          } else {
            testSet = [...basics, ...intermediates, ...advanceds];
          }

          for (var i = 0; i < testSet.length; i += 1) {
            var isNew = true;
            for (
              var j = 0;
              j < objectiveTests[selectedCategory]?.question.length;
              j += 1
            ) {
              isNew =
                isNew &&
                objectiveTests[selectedCategory]?.question[j].tid !==
                  testSet[i].tid;
              if (!isNew && !request.includes(testSet[i].tid)) {
                break;
              }
            }
            if (isNew) {
              objectiveTests[selectedCategory].question[rebuild] = testSet[i];
              break;
            }
          }
        });

        objectiveTests[selectedCategory].remained -= 1;
        objectiveTests[selectedCategory].questionNum = 0;
        const initCheckted = new Array(__MAX_QUESTION__).fill(true);
        setCheckedState(initCheckted);
        setCanRebuild(false);
        setRebuildQuestions([]);
        setObjectiveTests(objectiveTests);
        initSelectedQuestion(selectedCategory);
        setIsAllSelected(false);

        setIsEntireLoading(false);

        toast(t('toast.desc.question-recreate-complete'));
        dismissModal();
      })
      .catch((e) => {
        console.dir(e);
        setIsEntireLoading(false);
      });

    return;
  };

  window.onmousemove = function (e) {
    let tooltips = document.querySelectorAll('.tooltip');

    let x = `${e.clientX + 20}px`,
      y = `${e.clientY + 20}px`;
    for (let i = 0; i < tooltips.length; i += 1) {
      tooltips[i].style.top = y;
      tooltips[i].style.left = x;
    }
  };

  const onChange = (action, data) => {
    switch (action) {
      case 'code': {
        setCodeWrite(data);
        break;
      }
      default: {
        console.warn('case not handled!', action, data);
      }
    }
  };

  return (
    <main className="pages-protected-assessments-create-check">
      <section className="check-area">
        <ul className="category">
          {(objectiveTests || []).map((objtest, idx) => (
            <li
              ref={rowRefs[idx]}
              className={`list ${idx === 0 ? 'selected' : ''}`}
              key={idx}
              onClick={() => {
                onSelectSkillClickHandler(idx);
              }}
              value={idx}
              disabled
            >
              <div className="category-name fs16">
                {objtest?.title_en || objtest?.category_skill}
              </div>
              <div className="icon" />
            </li>
          ))}
        </ul>
        {!(
          objectiveTests[selectedCategory]?.question_type?.indexOf(
            'tag.subjective'
          ) >= 0 ||
          objectiveTests[selectedCategory]?.question_type?.indexOf(
            'tag.code'
          ) >= 0 ||
          objectiveTests[selectedCategory]?.question_type?.indexOf(
            'tag.video'
          ) >= 0
        ) ? (
          // 기존 객관식 문제
          <>
            <section className="question">
              <div className="top-box">
                <div className="time form">
                  <div className="title fs16">{t('column.duration')}</div>
                  <div className="time-box">
                    <div className="input-time">
                      <MaterialInput
                        className="form-input-time fs16"
                        disabled={
                          !!(
                            (objectiveTests[selectedCategory]?.id === '1310' &&
                              t('lang') === 'ko') ||
                            (objectiveTests[selectedCategory]?.id === '1321' &&
                              t('lang') === 'ko') ||
                            (objectiveTests[selectedCategory]?.id === '1322' &&
                              t('lang') === 'ko') ||
                            objectiveTests[selectedCategory]?.id === '6010' ||
                            objectiveTests[selectedCategory] === undefined
                          )
                        }
                        value={
                          objectiveTests[selectedCategory]?.id === '6010'
                            ? 15
                            : limitTime
                        }
                        onChange={(e) =>
                          setTime(selectedCategory, e.target.value)
                        }
                      />
                    </div>
                    <div className="select-time">
                      <span className="form-time-select fs16">
                        {t('desc.minute')}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="question-select-done"
                  style={{
                    display:
                      (objectiveTests[selectedCategory]?.id === '1310' &&
                        t('lang') === 'ko') ||
                      (objectiveTests[selectedCategory]?.id === '1321' &&
                        t('lang') === 'ko') ||
                      (objectiveTests[selectedCategory]?.id === '1322' &&
                        t('lang') === 'ko') ||
                      objectiveTests[selectedCategory]?.id === '6010' ||
                      objectiveTests[selectedCategory] === undefined
                        ? 'none'
                        : 'flex',
                  }}
                >
                  <div className="left">
                    <div className="title fs16">
                      {t('title.select-question')}
                    </div>
                    <div className="content">
                      <div className="fs14">
                        {t('desc.selected-qustion')}:{' '}
                        {rebuildQuestions?.length || 0}
                        {t('unit.number')}
                      </div>
                      <div className="fs14">
                        {t('desc.remained-count')}:{' '}
                        <span>
                          {objectiveTests[selectedCategory]?.remained}
                        </span>
                        {t('unit.count')}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <button
                      className={`common-button fsbtn16 primary ${
                        canRebuild &&
                        objectiveTests[selectedCategory]?.remained > 0
                          ? 'active'
                          : ''
                      }`}
                      onClick={
                        canRebuild &&
                        objectiveTests[selectedCategory]?.remained > 0
                          ? onClickRebuildHandler
                          : null
                      }
                    >
                      <ReloadIcon /> {t('btn.recreate-question')}
                    </button>
                  </div>
                </div>
                <div className="all-selected">
                  <Checkbox
                    className="radio-button"
                    checked={isAllSelected || false}
                    onClick={() => {
                      !isAllSelected
                        ? onClickSelectedAll()
                        : onClickSelectedAllCancel();
                    }}
                  />
                  {!isAllSelected ? (
                    <button onClick={onClickSelectedAll}>
                      {t('label.overall-selection')}
                    </button>
                  ) : (
                    <button onClick={onClickSelectedAllCancel}>
                      {t('label.de-select')}
                    </button>
                  )}
                </div>
              </div>
              <div
                className="question-box"
                style={{
                  display:
                    // objectiveTests[selectedCategory]?.category === 'Soft Skills' ||
                    // objectiveTests[selectedCategory]?.category === '인적성검사' ||
                    objectiveTests[selectedCategory] === undefined
                      ? 'none'
                      : 'block',
                }}
              >
                {(objectiveTests[selectedCategory]?.question || []).map(
                  (test, idx) =>
                    idx < 30 &&
                    idx < objectiveTests[selectedCategory]?.num_of_questions &&
                    idx < objectiveTests[selectedCategory]?.question?.length ? (
                      <article
                        className={`pages-protected-assessments-create-check-test ${
                          idx === 0 && 'selected'
                        }`}
                        ref={qRefs[idx]}
                        onClick={() => {
                          onSelectQuestionClickHandler(idx);
                        }}
                        key={idx}
                      >
                        <section className="title">
                          <div className="title-left">
                            <div className="skill fs16">
                              {test?.testInfo?.[`skill_${language}`]}
                            </div>
                            <div className="qustion-num fs24">Q{idx + 1}</div>
                          </div>
                          <div className="title-right">
                            <Checkbox
                              className="radio-button"
                              checked={checkedState[idx] || false}
                              value={idx || ''}
                              onClick={(event) => {
                                handleOnChange(idx, event);
                              }}
                              name="radio-buttons"
                              inputProps={{ 'aria-label': idx }}
                              disabled={
                                !!(
                                  (objectiveTests[selectedCategory]?.id ===
                                    '1310' &&
                                    t('lang') === 'ko') ||
                                  (objectiveTests[selectedCategory]?.id ===
                                    '1321' &&
                                    t('lang') === 'ko') ||
                                  (objectiveTests[selectedCategory]?.id ===
                                    '1322' &&
                                    t('lang') === 'ko')
                                )
                              }
                            />
                          </div>
                        </section>
                        <section className="question ellipsis fs16">
                          {test?.testInfo?.[`question_${language}`].indexOf(
                            '```'
                          ) > 0 ? (
                            <pre
                              dangerouslySetInnerHTML={{
                                __html: convertHtmlToText(
                                  test?.testInfo?.[
                                    `question_${language}`
                                  ].substring(
                                    0,
                                    test?.testInfo?.[
                                      `question_${language}`
                                    ].indexOf('```')
                                  )
                                ),
                              }}
                            />
                          ) : (
                            <pre
                              dangerouslySetInnerHTML={{
                                __html: convertHtmlToText(
                                  test?.testInfo?.[`question_${language}`]
                                ),
                              }}
                            />
                          )}
                        </section>
                      </article>
                    ) : (
                      <></>
                    )
                )}
              </div>
              <div
                className="settings-locked"
                style={{
                  display:
                    objectiveTests[selectedCategory] === undefined
                      ? 'block'
                      : 'none',
                }}
              >
                {objectiveTests[selectedCategory] !== undefined ? (
                  <div className="discription fs16">
                    <div className="error-icon">
                      <ErrorIcon />
                    </div>
                    {t('desc.test-settings-locked')}
                  </div>
                ) : (
                  <div className="discription fs16">
                    <div className="error-icon">
                      <ErrorIcon />
                    </div>
                    {t('desc.no-test-selected')}
                  </div>
                )}
              </div>
            </section>
            <section
              className="answer"
              style={{
                visibility:
                  objectiveTests[selectedCategory] === undefined
                    ? 'hidden'
                    : 'visible',
              }}
            >
              <div className="skill fs16">
                {selectedQuestion?.test?.testInfo?.[`skill_${language}`]}
              </div>
              <div className="question-num fs24">
                {selectedQuestion?.id !== undefined
                  ? `Q${selectedQuestion?.id + 1}`
                  : ''}
              </div>
              <div className="question">
                <pre
                  className="fs18"
                  dangerouslySetInnerHTML={{
                    __html: convertHtmlForDisplay(testTitle),
                  }}
                />
                {code !== '' && (
                  <SourceCode
                    language={
                      selectedQuestion?.test?.testInfo?.[`category_${language}`]
                    }
                    code={code}
                    isSub={false}
                  />
                )}
              </div>
              <ul className="answer-list">
                {(
                  selectedQuestion?.test?.testInfo?.[`answer_${language}`] || []
                )
                  .filter((x) => {
                    if (typeof x === 'boolean') {
                      return String(x);
                    }
                    return Boolean(x);
                  })
                  .map((answer, idx) => (
                    <li className="answer-list-item" key={idx}>
                      <div className="container">
                        {selectedQuestion?.test?.testInfo?.correct.length >
                        1 ? (
                          <Checkbox
                            className="radio-button"
                            checked={false}
                            disabled
                            name="radio-buttons"
                            inputProps={{ 'aria-label': answer }}
                          />
                        ) : (
                          <Radio
                            className="radio-button"
                            checked={false}
                            disabled
                            name="radio-buttons"
                            inputProps={{ 'aria-label': answer }}
                          />
                        )}
                        {answer.indexOf('```') === 0 ? (
                          <SourceCode
                            language={
                              selectedQuestion?.test?.testInfo?.[
                                `category_${language}`
                              ]
                            }
                            code={answer}
                            isSub
                          />
                        ) : (
                          <p className="fs16">
                            {typeof answer === 'boolean'
                              ? JSON.stringify(answer)
                              : answer}
                          </p>
                        )}
                      </div>
                    </li>
                  ))}
                <span className="tooltip fs12 obj">
                  {t('text-bubble.desc.preview-not-interactive')}
                </span>
              </ul>
            </section>
          </>
        ) : (
          // 라이브러리 테스트
          <>
            <section className="question">
              <div className="top-box">
                <div className="time form">
                  <div className="title fs16">{t('column.duration')}</div>
                  <div className="time-box">
                    <div className="input-time">
                      <MaterialInput
                        className="form-input-time fs16"
                        value={limitTime}
                        onChange={(e) =>
                          setTime(selectedCategory, e.target.value)
                        }
                      />
                    </div>
                    <div className="select-time">
                      <span className="form-time-select fs16">
                        {t('desc.minute')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="question-selected">
                  <div className="title fs16">{t('title.select-question')}</div>
                  <div className="discription fs14">
                    {t('desc.selected-qustion')}:{' '}
                    {rebuildQuestions?.length || 0}
                    {t('unit.number')}
                  </div>
                </div>
              </div>
              {isCode ? (
                <>
                  <div className="question-box">
                    {(objectiveTests[selectedCategory]?.question || []).map(
                      (test, idx) => (
                        <article
                          className={`pages-protected-assessments-create-check-test ${
                            idx === 0 && 'selected'
                          }`}
                          ref={qRefs[idx]}
                          onClick={() => {
                            onSelectQuestionClickHandler(idx);
                          }}
                          key={idx}
                        >
                          <section className="title">
                            <div className="title-left">
                              <div className="skill fs16">
                                {test?.category_skill}
                              </div>
                              <div className="qustion-num fs24">Q{idx + 1}</div>
                            </div>
                            <div className="title-right">
                              <Switch
                                checked={checkedState[idx] || false}
                                value={idx || ''}
                                onClick={(event) => {
                                  handleOnChange(idx, event);
                                }}
                                inputProps={{ 'aria-label': idx }}
                              />
                            </div>
                          </section>
                          <section
                            className="question ellipsis fs16"
                            dangerouslySetInnerHTML={{
                              __html: convertHtmlForDisplay(
                                test?.question?.indexOf('```') > 0
                                  ? test?.question?.substring(
                                      0,
                                      test?.question?.indexOf('```')
                                    )
                                  : test?.question
                              ),
                            }}
                          />
                        </article>
                      )
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="question-box">
                    {(objectiveTests[selectedCategory]?.question || []).map(
                      (test, idx) => (
                        <article
                          className={`pages-protected-assessments-create-check-test ${
                            idx === 0 && 'selected'
                          }`}
                          ref={qRefs[idx]}
                          onClick={() => {
                            onSelectQuestionClickHandler(idx);
                          }}
                          key={idx}
                        >
                          <section className="title">
                            <div className="title-left">
                              <div className="skill fs16">
                                {test?.category_tags}
                              </div>
                              <div className="qustion-num fs24">Q{idx + 1}</div>
                            </div>
                            <div className="title-right">
                              <Switch
                                checked={checkedState[idx] || false}
                                value={idx || ''}
                                onClick={(event) => {
                                  handleOnChange(idx, event);
                                }}
                                inputProps={{ 'aria-label': idx }}
                              />
                            </div>
                          </section>
                          <section className="question ellipsis fs16">
                            {test?.question?.indexOf('```') > 0
                              ? convertHtmlToText(
                                  test?.question?.substring(
                                    0,
                                    test?.question?.indexOf('```')
                                  )
                                )
                              : convertHtmlToText(test?.question)}
                          </section>
                        </article>
                      )
                    )}
                  </div>
                </>
              )}

              <div className="settings-locked">
                <div className="discription fs16">
                  <div className="error-icon">
                    <ErrorIcon />
                  </div>
                  {t('desc.test-settings-locked')}
                </div>
              </div>
            </section>
            <section className="answer">
              {isCode ? (
                <>
                  <div className="skill fs16">{t('code.guide-title')}</div>
                  <div className="question-num fs24">
                    {selectedQuestion?.id !== undefined
                      ? `Q${selectedQuestion?.id + 1}`
                      : ''}
                  </div>
                  <div className="question">
                    <pre
                      className="fs18"
                      dangerouslySetInnerHTML={{
                        __html: convertHtmlForDisplay(
                          selectedQuestion?.test?.question
                        ),
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="skill fs16">
                    {selectedQuestion?.test?.[`skill_${language}`]}
                  </div>
                  <div className="question-num fs24">
                    {selectedQuestion?.id !== undefined
                      ? `Q${selectedQuestion?.id + 1}`
                      : ''}
                  </div>
                  <div className="question">
                    <pre
                      className="fs18"
                      dangerouslySetInnerHTML={{
                        __html: convertHtmlForDisplay(testTitle),
                      }}
                    />

                    {code !== '' && (
                      <SourceCode
                        language={selectedQuestion?.[`category_${language}`]}
                        code={code}
                        isSub={false}
                      />
                    )}
                  </div>
                </>
              )}
            </section>
          </>
        )}
      </section>
    </main>
  );
};

Step2.propTypes = {
  curStep: PropTypes.number,
  objectiveTests: PropTypes.array,
  setObjectiveTests: PropTypes.func,
  setDuration: PropTypes.func,
  language: PropTypes.string,
};

export default Step2;
