import React, { useState } from 'react';
import Thumbnail from 'components/offer/Thumbnail';
import Employment from 'components/offer/Employment';
import Benefit from 'components/offer/Benefit';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ChevronArrowIcon } from 'assets/images/icon/arrow-forward-ios.svg';

const OfferLetter = () => {
  const location = useLocation();

  const { uid, rid, cid, step, candidate, recruitment } = location?.state;
  const [page, setPage] = useState(0);

  const onClickNext = () => {
    if (page < 2) {
      setPage((prev) => prev + 1);
    }
  };

  const onClickPrev = () => {
    if (page) {
      setPage((prev) => prev - 1);
    }
  };

  return (
    <main id="pages-offer-detail">
      <div className="container">
        <div className="slide-wrapper">
          {page === 0 && (
            <section className="intro-section">
              <Thumbnail />
            </section>
          )}
          {page === 1 && (
            <section className="employment-section">
              <Employment
                candidate={candidate}
                recruitment={recruitment}
                step={step}
              />
            </section>
          )}
          {page === 2 && (
            <section className="benefits-section">
              <Benefit
                uid={uid}
                rid={rid}
                cid={cid}
                step={step}
                candidate={candidate}
                recruitment={recruitment}
              />
            </section>
          )}
        </div>
        <div className="button-wrapper">
          <section className="button-box">
            <aside className="arrow prev" onClick={onClickPrev}>
              <ChevronArrowIcon />
            </aside>
            <aside className="page-num">{page + 1}/3</aside>
            <aside className="arrow next" onClick={onClickNext}>
              <ChevronArrowIcon />
            </aside>
          </section>
        </div>
      </div>
    </main>
  );
};

export default OfferLetter;
