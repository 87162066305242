import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditCandidateEmailModal } from 'components/recruitment/detail/reviewing/EditCandidateEmailModal';

export const EditCandidateEmailButton = ({ candidate, rid }) => {
  const [showEmailEditModal, setShowEmailEditModal] = React.useState(false);
  const {t} = useTranslation();

  const onClickMoveToPrevStepButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowEmailEditModal(true);
  };

  return (
    <div>
    <div className="recruitment-table-dropdown-list" onClick={onClickMoveToPrevStepButton}>{t('menu.edit-candidate-email')}</div>
    {showEmailEditModal && <EditCandidateEmailModal candidate={candidate} rid={rid} changeOpenClose={setShowEmailEditModal}/>}
    </div>
  );
};