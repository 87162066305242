import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon/arrow-right.svg';
import MaterialInput from 'components/input/MaterialInput';
import MaterialSelect from 'components/select/MaterialSelect';
import MainLogo from 'components/logo/MainLogo';
import PropTypes from 'prop-types';

const ProcessInfo = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { authToken, account } = location.state || { authToken: null };

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [corporation, setCorporation] = React.useState('');
  const [position, setPosition] = React.useState('');
  const [numEmployee, setNumEmployee] = React.useState('');
  const [isTrackingSystem, setIsTrackingSystem] = React.useState(null);
  const [trackingSystem, setTrackingSystem] = React.useState('');
  const [canGoNext, setCanGoNext] = React.useState(false);

  React.useEffect(() => {
    let flag = true;

    if (!firstName) {
      flag = false;
    }
    if (!lastName) {
      flag = false;
    }
    if (!corporation) {
      flag = false;
    }
    if (!position) {
      flag = false;
    }
    if (!numEmployee) {
      flag = false;
    }
    if (isTrackingSystem === null || isTrackingSystem === undefined) {
      flag = false;
    } else if (isTrackingSystem) {
      if (!trackingSystem) {
        flag = false;
      }
    }

    setCanGoNext(flag);
  }, [
    firstName,
    lastName,
    corporation,
    position,
    numEmployee,
    isTrackingSystem,
    trackingSystem,
  ]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (canGoNext) onNextClickHandler();
    }
  };

  const onNextClickHandler = () => {
    // TODO: check upside's forms.

    navigate(`/${account}/auth/signup/thumbnail`, {
      state: {
        authToken,
        firstName,
        lastName,
        corporation,
        position,
        numEmployee,
        trackingSystem,
        account,
      },
    });
  };

  return (
    <main className="pages-auth-signup-process-info">
      <section className="logo-container">
        <MainLogo className="logo" type="signup" />
      </section>
      <div className="explain">
        <h2 className="explain-main fs24">
          {t('signup-customer-profile.title.profile')}
        </h2>
        <h3 className="explain-sub fs16">
          {t('signup-customer-logo.desc.logo')}
        </h3>
      </div>
      <section className="form">
        <MaterialInput
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          onKeyPress={onKeyPress}
          placeholder={`${t('text-field.label.first-name')} *`}
        />
        <MaterialInput
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onKeyPress={onKeyPress}
          placeholder={`${t('text-field.label.last-name')} *`}
        />
        <MaterialInput
          value={corporation}
          onChange={(e) => setCorporation(e.target.value)}
          onKeyPress={onKeyPress}
          placeholder={`${t('text-field.label.company-name')} *`}
        />
        <MaterialInput
          value={position}
          onChange={(e) => setPosition(e.target.value)}
          onKeyPress={onKeyPress}
          placeholder={t('menu.label.job')}
        />

        <MaterialSelect
          title={`${t('menu.label.number-hire')} *`}
          value={numEmployee}
          onChange={(item) => setNumEmployee(item?.key)}
          items={[
            {
              key: 'num-employee-0',
              i18nKey: 'menu.input.num-employee-0',
            },
            {
              key: 'num-employee-1',
              i18nKey: 'menu.input.num-employee-1',
            },
            {
              key: 'num-employee-2',
              i18nKey: 'menu.input.num-employee-2',
            },
            {
              key: 'num-employee-3',
              i18nKey: 'menu.input.num-employee-3',
            },
            {
              key: 'num-employee-4',
              i18nKey: 'menu.input.num-employee-4',
            },
            {
              key: 'num-employee-5',
              i18nKey: 'menu.input.num-employee-5',
            },
          ]}
        />
        <MaterialSelect
          title={`${t('menu.label.applicant-tracking-system')} *`}
          value={isTrackingSystem}
          onChange={(item) => setIsTrackingSystem(item?.key)}
          items={[
            {
              key: true,
              i18nKey: 'menu.input.ats-yes',
            },
            {
              key: false,
              i18nKey: 'menu.input.ats-no',
            },
          ]}
        />
        {isTrackingSystem && (
          <MaterialInput
            value={trackingSystem}
            onChange={(e) => setTrackingSystem(e.target.value)}
            onKeyPress={onKeyPress}
            placeholder={`${t('menu.label.applicant-tracking-system')} *`}
          />
        )}
        <section className="form-buttons">
          <button
            className={`form-buttons-go-next common-button fsbtn16 ${
              canGoNext && 'active'
            }`}
            onClick={canGoNext ? onNextClickHandler : null}
          >
            {t('btn.next')}
            <ArrowRightIcon />
          </button>
        </section>
      </section>
    </main>
  );
};

ProcessInfo.propTypes = {};

export default ProcessInfo;
